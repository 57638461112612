import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import importedStyles from './Profile.module.sass';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import GoogleIcon from "../../icons/googleLogo.svg";
import PlayStoreIcon from "../../icons/playStore.svg";
import FacebookIcon from "../../icons/facebookLogo.png";
import ProfileIllustration from "../../images/profile-illustration-min.png";
import ProfileIllustrationWebp from "../../images/profile-illustration-min.webp";
import {
    AccountCircleOutlined,
    AlternateEmailOutlined,
    BusinessSharp,
    CreditCardOutlined,
    DescriptionOutlined,
    InfoOutlined,
    PhoneIphoneOutlined,
    Room,
    StarBorderRounded,
} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import UploadVideoButton from "../../components/UploadVideoButton/UploadVideoButton";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import {ReactComponent as ExpandMoreIcon} from "../../icons/desplegar texto.svg";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import history from "../../helpers/history";
import {ReloginRequest, validatePromoCode} from "../../helpers/fetch/auth";
import {login, updateUser} from "../Auth/authActions";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import IconButton from "@material-ui/core/IconButton";
import MyTooltip from "../../components/MyTooltip";
import {isWebpSupported} from "react-image-webp/dist/utils";
import {Helmet} from "react-helmet/es/Helmet";
import PageTitle from "../../components/PageTitle/PageTitle";
import fetch from "node-fetch";
import apiBaseUrl from "../../helpers/apiBaseUrl";


class Profile extends React.Component {

    state = {
        expanded: null,
        promoCode: '',
        openedTooltip: '',
        paymentMethodsDescription: 'Te enviaremos la factura a tu correo electrónico.'
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.relogin();
        // this.getPaymentWay().then(r => {});
        ReactGA.pageview(window.location.pathname);
        ReactPixel.trackCustom('ProfileView', {user: this.props.user.uuid});
    };

    relogin = () => {
        ReloginRequest(this.props.user.uuid, this.props.user.entity).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        if (response.user)
                            this.props.dispatch(login(response.user));
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingResponse: false});
                                break;
                            case 'badPassword':
                                this.setState({authPasswordError: true, waitingResponse: false});
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                break;
                        }
                        this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({waitingResponse: false});
                }
            )
    };

    getPaymentWay = async () => {
        try {
            let response = await fetch(apiBaseUrl + 'app_settings/getPaymentMethodsDescription', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            this.setState({paymentMethodsDescription: response.data});

        } catch (error) {
            console.error('Error en getPaymentMethodsDescription() -> ', error);
        }

    };

    handlePromoCodeInput = (e) => {
        this.setState({promoCode: e.target.value});
    };

    handleTooltipOpen = (tooltipId) => () => {
        this.setState({openedTooltip: tooltipId});
    };
    handleTooltipClose = () => {
        this.setState({openedTooltip: ''});
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    renderContacts = (contacts) => {
        let contactsView = [];

        if (!contacts || contacts.length === 0) {
            contactsView = <p>Aún no has añadido ningún contacto.</p>
        } else {
            Object.keys(contacts).forEach((index) => {
                contactsView.push(
                    <List className={importedStyles.innerList}>
                        <ListItem className={importedStyles.nestedList} key={'name' + index}>
                            <ListItemIcon> <AccountCircleOutlined className={importedStyles.littleIcon}/>
                            </ListItemIcon>
                            <ListItemText className={importedStyles.listItemTextMini} primary={contacts[index].name}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} key={'mail' + index}>
                            <ListItemIcon> <AlternateEmailOutlined className={importedStyles.littleIcon}/>
                            </ListItemIcon>
                            <ListItemText className={importedStyles.listItemTextMini} primary={contacts[index].email}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} key={'phone' + index}>
                            <ListItemIcon> <PhoneIphoneOutlined className={importedStyles.littleIcon}/> </ListItemIcon>
                            <ListItemText className={importedStyles.listItemTextMini}
                                          primary={contacts[index].phone ? contacts[index].phone : '-'}/>
                        </ListItem>
                    </List>
                )
            });
        }

        return (
            <Accordion className={importedStyles.panel} expanded={this.state.expanded === 'panel0'}
                            onChange={this.handleChange('panel0')}>
                <AccordionSummary className={importedStyles.expansionSummary}
                                       expandIcon={<ExpandMoreIcon className={importedStyles.expandIcon}/>}>
                    <p className={importedStyles.titleExpansionSummary}>
                        Contactos
                    </p>
                </AccordionSummary>
                <AccordionDetails className={importedStyles.panelDetails}>
                    {contactsView}
                </AccordionDetails>
            </Accordion>
        );
    };

    renderAddress = (entity) => {
        return (
            <Accordion className={importedStyles.panel} expanded={this.state.expanded === 'panel1'}
                            onChange={this.handleChange('panel1')}>
                <AccordionSummary
                    className={importedStyles.expansionSummary}
                    expandIcon={<ExpandMoreIcon className={importedStyles.expandIcon}/>}>
                    <p className={importedStyles.titleExpansionSummary}>
                        Datos de facturación
                    </p>
                </AccordionSummary>
                <AccordionDetails className={importedStyles.panelDetails}>
                    <List className={importedStyles.innerList}>
                        <ListItem key={'name'+entity.uuid}>
                            <ListItemIcon> <BusinessSharp/> </ListItemIcon>
                            <ListItemText className={importedStyles.listItemTextMini} primary={entity.billing_name}/>
                        </ListItem>
                        <ListItem key={'cif'+entity.uuid}>
                            <ListItemIcon> <DescriptionOutlined/> </ListItemIcon>
                            <ListItemText className={importedStyles.listItemTextMini}
                                          primary={entity.cif ? entity.cif : '-'}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} key={'city'+entity.uuid}>
                            <ListItemIcon> <Room className={importedStyles.littleIcon}/> </ListItemIcon>
                            <ListItemText
                                className={importedStyles.listItemTextMini}
                                primary={
                                    entity.address ?
                                        entity.address : 'Aún no has indicado tu dirección de facturación.'
                                }/>
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    };

    renderPaymentWay = (entity) => {
        return (
            <Accordion className={importedStyles.panel} expanded={this.state.expanded === 'panel2'}
                            onChange={this.handleChange('panel2')}>
                <AccordionSummary className={importedStyles.expansionSummary}
                                       expandIcon={<ExpandMoreIcon className={importedStyles.expandIcon}/>}>
                    <p className={importedStyles.titleExpansionSummary}>
                        Métodos de pago
                    </p>
                </AccordionSummary>
                <AccordionDetails className={importedStyles.panelDetails}>
                    <p>{entity.payment_methods_description}</p>
                </AccordionDetails>
            </Accordion>
        );
    };

    renderProfileEntity = () => {
        const entity = this.props.user;
        return (
            <React.Fragment>
                <List className={importedStyles.list}>
                    <ListItem key={'name'} disableGutters>
                        <ListItemIcon> <AccountCircleOutlined/> </ListItemIcon>
                        <ListItemText className={importedStyles.listItemText} primary={entity.name}/>
                    </ListItem>
                    <ListItem className={importedStyles.nestedList} key={'email'} disableGutters>
                        <ListItemIcon> <AlternateEmailOutlined/> </ListItemIcon>
                        <div>
                            <ListItemText className={importedStyles.listItemText} primary={entity.email.split('@')[0]}/>
                            <ListItemText className={importedStyles.emailDomain}
                                          primary={'@' + entity.email.split('@')[1]}/>
                        </div>
                    </ListItem>
                    {/*{entity.contacts && this.renderContacts(entity.contacts)}*/}
                    {this.renderAddress(entity)}
                    {this.renderPaymentWay(entity)}
                </List>
                <p className={importedStyles.contactUs}>
                    ¿Quieres modificar o añadir algún dato?
                    <p className={importedStyles.linkContact} onClick={this.handleContactUsClick}>
                        Contácta con nosotros
                    </p>
                </p>
            </React.Fragment>);
    };

    getViralCode = () => {
        ReactPixel.trackCustom('compartirCodigoAmigo');
        let user = this.props.user;
        if (window.matchMedia("(max-width: 768px)").matches) {
            return "whatsapp://send?text=¡Hola! Regístrate en *Grábalo* con mi código _" + user.viralCode + "_ y los 2 ganaremos 1€ cuando subas tu primer vídeo. Puedes hacerlo en https%3A%2F%2Fmisitio.grabaloapp.com%2F%3Fregister%3Dtrue%26viralCode%3D" + user.viralCode + "%26utm_source%3DreferalProfileWhats%26utm_medium%3DreferalProfileWhats%26utm_campaign%3DreferalProfileWhats"
        } else {
            return "https://web.whatsapp.com/send?text=¡Hola! Regístrate en *Grábalo* con mi código _" + user.viralCode + "_ y los 2 ganaremos 1€ cuando subas tu primer vídeo. Puedes hacerlo en " +
                "https%3A%2F%2Fmisitio.grabaloapp.com%2F%3Fregister%3Dtrue%26viralCode%3D" + user.viralCode + "%26utm_source%3DreferalProfileWhats%26utm_medium%3DreferalProfileWhats%26utm_campaign%3DreferalProfileWhats";
        }
    };

    handlePromoCodeValidation = () => {
        /*
            *   Send new promoCode to backend to validate it
         */
        this.setState({
            waitingResponse: true
        });

        validatePromoCode(this.props.user.uuid, this.state.promoCode).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        switch (response.status) {
                            case 'success':
                                this.props.dispatch(showSnack('Enhorabuena, tu código amigo es correcto, disfruta de tu premio.', snackTypes.success));
                                break;
                            case 'waitingVideoUpload':
                                this.props.dispatch(showSnack('Enhorabuena, tu código amigo es correcto, sube un vídeo y ambos recibiréis 1€ cuando sea aceptado', snackTypes.success));
                                break;
                            default:
                                break;
                        }
                        this.setState({waitingResponse: false});
                        let updatedUser = this.props.user;
                        updatedUser.promoCode = this.state.promoCode;
                        this.props.dispatch(updateUser(updatedUser));
                        this.relogin();

                    } else {
                        switch (response.errorType) {
                            case 'invalidCode':
                                this.props.dispatch(showSnack('El código introducido no es válido', snackTypes.warning));
                                this.setState({waitingResponse: false});
                                break;
                            default:
                                throw new Error(response.errorMessage);
                        }
                    }
                }, (error) => {
                    this.props.dispatch(showSnack('Ha habido un error, estamos trabajando para solucionarlo', snackTypes.error));
                    console.error('Error: ' + error);
                    this.setState({waitingResponse: false});
                }
            ).catch(error => {
            this.props.dispatch(showSnack('Ha habido un error, estamos trabajando para solucionarlo', snackTypes.error));
            console.error('Error: ' + error);
            this.setState({waitingResponse: false});
        });

    };

    logFriendCodeClick = () => {
        ReactGA.ga('send', 'event', 'click', 'compartirCodigoAmigo');
    };

    trackFbRateButtonClick = () => {
        ReactPixel.trackCustom('valoranosEnFacebookPulsado');
        ReactGA.ga('send', 'event', 'click', 'ValoranosEnFacebookPulsado');
    };
    trackGoogleRateButtonClick = () => {
        ReactPixel.trackCustom('valoranosEnGooglePulsado');
        ReactGA.ga('send', 'event', 'click', 'ValoranosEnGooglePulsado');
    };
    trackGooglePlaystoreRateButtonClick = () => {
        ReactPixel.trackCustom('valoranosEnPlaystorePulsado');
        ReactGA.ga('send', 'event', 'click', 'ValoranosEnPlaystorePulsado');
    };

    renderRateUsSection = () => {
        return (
            <React.Fragment>
                <ListItem className={importedStyles.nestedList} key={'share'}>
                    <ListItemIcon> <StarBorderRounded/> </ListItemIcon>
                    <ListItemText className={importedStyles.listItemText} primary={'Valóranos en:'}/>
                </ListItem>
                <ListItem className={importedStyles.rateUsListItem} key={'share'}>
                    <div className={importedStyles.rateUsContainer}>
                        <IconButton className={importedStyles.rateIcon}
                                    href={'https://www.facebook.com/pg/GrabaloApp/reviews/'} target={'_blank'}
                                    onClick={this.trackFbRateButtonClick}>
                            <img src={FacebookIcon} alt={'facebook icon'} width={30}/>
                        </IconButton>
                        <IconButton className={importedStyles.rateIcon} href={'https://g.page/Grabalo/review?kd'}
                                    target={'_blank'}
                                    onClick={this.trackGoogleRateButtonClick}>
                            <img src={GoogleIcon} alt={'google icon'} width={28}/>
                        </IconButton>
                        <IconButton className={importedStyles.rateIcon}
                                    href={'https://play.google.com/store/apps/details?id=xyz.appmaker.mzjpyt'}
                                    target={'_blank'}
                                    onClick={this.trackGooglePlaystoreRateButtonClick}>
                            <img src={PlayStoreIcon} alt={'play store icon'} width={26}/>
                        </IconButton>
                    </div>
                </ListItem>
            </React.Fragment>
        )
    };

    renderProfileUser = () => {
        const user = this.props.user;

        if (!user) return false;
        return (
            <React.Fragment>
                <List className={importedStyles.list}>
                    <ListItem key={'name'}>
                        <ListItemIcon> <AccountCircleOutlined/> </ListItemIcon>
                        <ListItemText className={importedStyles.listItemText} primary={user.name}/>
                    </ListItem>
                    <ListItem className={importedStyles.nestedList} key={'earn'}>
                        <MyTooltip
                            onClick={this.handleTooltipOpen('earn')}
                            onClose={this.handleTooltipClose}
                            onMouseEnter={this.handleTooltipOpen('earn')}
                            onMouseLeave={this.handleTooltipClose}
                            open={this.state.openedTooltip === 'earn'}
                            disableFocusListener
                            disableTouchListener
                            title="Cuando tu monedero virtual llegue a 10€ podrás retirar tu dinero por Bizum si resides en España o por Paypal para el resto del mundo, te pediremos los datos necesarios cuando llegues a 10€. Los euros se convertirán a tu moneda cuando te enviemos el dinero."
                        >
                            <IconButton className={importedStyles.iconInfoButton} aria-label="delete">
                                <InfoOutlined className={importedStyles.iconInfo}/>
                            </IconButton>
                        </MyTooltip>
                        <ListItemIcon> <CreditCardOutlined/> </ListItemIcon>
                        <ListItemText className={importedStyles.listItemText} primary={user.earned + ' €'}/>
                    </ListItem>
                    <ListItem className={importedStyles.nestedList} key={'email'}>
                        <MyTooltip
                            onClose={this.handleTooltipClose}
                            onClick={this.handleTooltipOpen('email')}
                            onMouseEnter={this.handleTooltipOpen('email')}
                            onMouseLeave={this.handleTooltipClose}
                            open={this.state.openedTooltip === 'email'}
                            disableFocusListener
                            disableTouchListener
                            title="Es muy importante que este sea tu mejor email porque solo por aquí te avisaremos cuando vendas un vídeo.
                            Si has creado una cuenta con Facebook y el email que aparece aquí ya no está activo, deberías crear otra cuenta antes de subir ningún vídeo. No hace falta que el email de tu cuenta en Grábalo sea el mismo que el de tu cuenta de Paypal."
                        >
                            <IconButton className={importedStyles.iconInfoButton} aria-label="delete">
                                <InfoOutlined className={importedStyles.iconInfo}/>
                            </IconButton>
                        </MyTooltip>
                        <ListItemIcon> <AlternateEmailOutlined/> </ListItemIcon>
                        <div>
                            <ListItemText className={importedStyles.listItemText} primary={user.email.split('@')[0]}/>
                            <ListItemText className={importedStyles.emailDomain}
                                          primary={'@' + user.email.split('@')[1]}/>
                        </div>
                    </ListItem>
                    <ListItem className={importedStyles.nestedList} key={'phone'}>
                        <MyTooltip
                            onClose={this.handleTooltipClose}
                            onClick={this.handleTooltipOpen('phone')}
                            onMouseEnter={this.handleTooltipOpen('phone')}
                            onMouseLeave={this.handleTooltipClose}
                            open={this.state.openedTooltip === 'phone'}
                            disableFocusListener
                            disableTouchListener
                            title="Si quieres añadir tu número de teléfono debes de enviar un email a info@grabaloapp.com con el número de teléfono."
                        >
                            <IconButton className={importedStyles.iconInfoButton} aria-label="delete">
                                <InfoOutlined className={importedStyles.iconInfo}/>
                            </IconButton>
                        </MyTooltip>
                        <ListItemIcon> <PhoneIphoneOutlined/> </ListItemIcon>
                        <ListItemText className={importedStyles.listItemText} primary={user.phone ? user.phone : '-'}/>
                    </ListItem>
                    {/*            {user.viralCode &&
                    <ListItem className={importedStyles.nestedList} key={'viralCode'}>
                        <MyTooltip
                            onClose={this.handleTooltipClose}
                            onClick={this.handleTooltipOpen('viralCode')}
                            onMouseEnter={this.handleTooltipOpen('viralCode')}
                            onMouseLeave={this.handleTooltipClose}
                            open={this.state.openedTooltip === 'viralCode'}
                            disableFocusListener
                            disableTouchListener
                            title="Podrás conseguir hasta 5€ invitando a amigos y amigas."
                        >
                            <IconButton className={importedStyles.iconInfoButton} aria-label="delete">
                                <InfoOutlined className={importedStyles.iconInfo}/>
                            </IconButton>
                        </MyTooltip>
                        <ListItemIcon><LoyaltyOutlined/></ListItemIcon>
                        <ListItemText className={importedStyles.listItemText} secondary={
                            <p>{"Haz que tus amigos se registren con el código: " + user.viralCode + " y gana 1€ por cada amigo cuando suban su primer vídeo."}
                                <a className={importedStyles.iconShare} target={'_blank'} href={this.getViralCode()}
                                   onClick={this.logFriendCodeClick}>Compártelo
                                    aquí</a>
                            </p>
                        }/>
                    </ListItem>
                    }
                    <ListItem className={importedStyles.nestedList} key={'friendCode'}>
                        <MyTooltip
                            onClose={this.handleTooltipClose}
                            onClick={this.handleTooltipOpen('friendCode')}
                            onMouseEnter={this.handleTooltipOpen('friendCode')}
                            onMouseLeave={this.handleTooltipClose}
                            open={this.state.openedTooltip === 'friendCode'}
                            disableFocusListener
                            disableTouchListener
                            title="Introduce aquí el código de tu amigo, valídalo y consigue 1€ cuando subas tu primer vídeo y sea aprobado."
                        >
                            <IconButton className={importedStyles.iconInfoButton} aria-label="delete">
                                <InfoOutlined className={importedStyles.iconInfo}/>
                            </IconButton>
                        </MyTooltip>
                        <ListItemIcon> <PeopleRounded/> </ListItemIcon>
                        <ListItemText className={importedStyles.listItemText}
                                      secondary={<p>¿Has sido invitado por un amigo? Valida aquí tu código:</p>}/>
                    </ListItem>
                    <ListItem className={importedStyles.listItemTextTabbed}>
                        <ListItemIcon> <span/></ListItemIcon>
                        <OutlinedInput
                            margin={"dense"}
                            id="promoCode"
                            name="promoCode"
                            type="text"
                            disabled={this.props.user.promoCode}
                            inputProps={{maxLength: 6}}
                            value={this.props.user.promoCode}
                            onChange={this.handlePromoCodeInput}
                            labelWidth={0}/>
                    </ListItem>
                    <ListItem className={importedStyles.listItemTextTabbed}>
                        <ListItemIcon> <span/></ListItemIcon>
                        {!this.props.user.promoCode &&
                        <Fab variant={'extended'}
                             classes={{disabled: importedStyles.validateButtonDisabled}}
                             className={importedStyles.validateButton}
                             size={'small'}
                             disabled={this.state.waitingResponse || this.state.promoCode.length < 6}
                             onClick={this.handlePromoCodeValidation}
                        >
                            Validar {this.state.waitingResponse &&
                        <CircularProgress style={{marginLeft: 5}} size={15}/>}
                        </Fab>
                        }
                        {this.props.user.promoCode &&
                        <small style={{color: '#aeaeae'}}>¡Ya has validado tu código amigo!</small>}
                    </ListItem>*/}

                    {/*
                    {this.renderRateUsSection()}
*/}

                </List>
            </React.Fragment>);
    };

    handleContactUsClick = () => {
        ReactPixel.trackCustom('FromProfileToContactUs', {user: this.props.user.uuid});
        history.push('/contact')
    };

    getSubtitle = () => {
        let subtitle = 'Visualiza tus datos de contacto';

        if (this.props.user && this.props.user.entity){
            subtitle += ' y facturación';
        }

        return subtitle + '.';
    }
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Perfil</title>
                </Helmet>
                <NavBar/>
                <UploadVideoButton/>
                <DesktopDrawer/>
                <div className={importedStyles.Profile}>
                    <PageTitle title={'Perfil'} subtitle={this.getSubtitle()} />

                    <div className={importedStyles.content}>
                        {this.props.user.entity ? this.renderProfileEntity() : this.renderProfileUser()}

                        <img
                            src={isWebpSupported() ? ProfileIllustrationWebp : ProfileIllustration}
                            className={importedStyles.profileIllustration}
                            alt={'profile background illustration'}
                        />
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(Profile);
