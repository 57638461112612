import React, {useEffect, useState} from "react";
import history from "../../../helpers/history";
import importedStyles from './FeaturedEventDetail.module.sass';
import {
    CalendarTodayRounded,
    ChevronLeftSharp,
    ChevronRightSharp,
    CloseSharp, EventRounded, FavoriteBorderOutlined, FavoriteOutlined, FavoriteRounded,
    ScheduleRounded, StarRounded
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Snack from "../../../components/Snack/Snack";
import {Divider, Fade, Hidden, Slide, Tooltip, Typography} from "@material-ui/core";
import ReactGA from "react-ga";
import ShareModal from "../../../components/ShareModal/ShareModal";
import PropTypes from "prop-types";
import FavIconRelleno from "@material-ui/icons/Favorite";
import {ReactComponent as FavIcon} from "../../../icons/heart_v2.svg";
import {generatePath, useParams, useRouteMatch} from "react-router-dom";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Grid from "@material-ui/core/Grid";
import {redColor} from "../../../styles/palette";
import {ReactComponent as ShareArrow} from "../../../icons/share_arrow_v2.svg";
import {sendScheduleAdInteraction} from "../../../helpers/stats/statsAPI";
import AdWithSensorWrapper from "../../../components/AdWithSensorWrapper/AdWithSensorWrapper";
import Interweave, {ALLOWED_TAG_LIST} from "interweave";
import {isMobile} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentAdIndex} from "../Redux/ScheduleActions";
import Box from "@material-ui/core/Box";
import moment from "moment";
import {useUrlQuery} from "../../../helpers/hooks";

//
const isMobileWidth = window.matchMedia("(max-width: 768px)").matches;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} timeout={500}/>;
});

const TransitionMobile = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FeaturedEventDetail = (props) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const {eventIdentifier} = useParams();
    const urlQuery = useUrlQuery();


    const schedule = useSelector((state) => state.ScheduleReducer.schedule);
    const popupAds = useSelector((state) => state.ScheduleReducer.popupAds);
    const currentAdIndex = useSelector((state) => state.ScheduleReducer.currentAdIndex);

    const [liked, setLiked] = useState(false);

    const [open, setOpen] = useState(false);
    const [event, setEvent] = useState({});
    const [currentEventIndex, setCurrentEventIndex] = useState(0);
    const [topAdBanner, setTopAdBanner] = useState(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [openMobileAdPopup, setOpenMobileAdPopup] = useState(false);


    useEffect(()=>{
        let likedCookie = localStorage.getItem('likeEvent' + event.id);
        if (likedCookie) {
            setLiked(true)
        } else {
            localStorage.removeItem('likeEvent' + event.id);
            setLiked(false);
        }
    },[]);

    const handleLike = (e) => {
        e.stopPropagation();

        let eventCopy = {...event};
        if (!liked) {
            localStorage.setItem('likeEvent' + event.id, 'true');
            eventCopy.likes = parseInt(eventCopy.likes) + 1;
        }

        if (liked) {
            localStorage.removeItem('likeEvent' + event.id);
            eventCopy.likes = parseInt(eventCopy.likes) - 1;
        }

        localStorage.setItem('eventLikesCount' + event.id, eventCopy.likes);
        window.dispatchEvent(new Event("storage"));

        setEvent(eventCopy);
        setLiked(!liked);
    }

    useEffect(() => {
        function checkLikesData() {


            let likedCookie = localStorage.getItem('likeEvent' + event.id);
            if (likedCookie) {
                setLiked(true)
            } else {
                localStorage.removeItem('likeEvent' + event.id);
                setLiked(false);
            }

            let likesCounter = localStorage.getItem('eventLikesCount' + event.id);

            if (likesCounter && parseInt(likesCounter) !== event.likes) {
                let eventCopy = {...event};
                eventCopy.likes = parseInt(likesCounter);

                setEvent(eventCopy);
            }
        }

        window.addEventListener('storage', checkLikesData);

        checkLikesData();

        return () => {
            window.removeEventListener('storage', checkLikesData)
        }
    }, [event])


    useEffect(() => {

        let events = props.events;

        if (!eventIdentifier || urlQuery.get('featured') !== 'true') {
            setOpen(false);
            return false;
        }


        if (eventIdentifier && urlQuery.get('featured') === 'true') {
            let index = events.findIndex((event) => event.url === eventIdentifier);

            if (index !== -1) {
                setEvent(events[index]);
                setCurrentEventIndex(index);
                setOpen(true)
            }
        }

    }, [eventIdentifier, schedule]);

    useEffect(() => {
        if (!event || !event.ads || event.ads.length <= 0) {
            return false;
        }

        let ads = event.ads.filter(item => item.type === 'intersection-banner');

        if (!ads && ads.length <= 0) return false;

        let randomIndex = Math.floor(Math.random() * ads.length);

        let ad = ads[randomIndex];
        setTopAdBanner({topAdBanner: ad});

        checkMobileAdCookie();

    }, [event]);


    ///////////////////////////

    const renderMainImage = () => {
        return (
            <React.Fragment>
                <Fade in={true} timeout={600} unmountOnExit>
                    <div className={importedStyles.mainImageWrapper}>
                        <img
                            alt={event.title}
                            src={event.main_image}
                            className={importedStyles.mainImage}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/img/png/thumbnail.png"
                            }}
                        />
                        <Fade in={event.featured} mountOnEnter unmountOnExit>
                            <StarRounded  style={{position: 'absolute', right: '0.25rem', top: '0.25rem', color: '#ffa639', background: '#06232c9e', borderRadius: 255, fontSize: '2.35rem'}}/>
                        </Fade>
                    </div>
                </Fade>
            </React.Fragment>
        )
    };


    const renderShareModal = () => {
        return (
            <ShareModal open={shareModalOpen} onClose={() => setShareModalOpen(false)}/>
        );
    };
    const handleShare = () => {
        if (isMobile && navigator.share) {

            navigator
                .share({
                    url: window.location.origin + generatePath(match.path, {
                        scheduleIdentifier: schedule.url,
                        eventIdentifier: event.url
                    })
                })
                .then(() => {
                    console.log('Compartido con éxito! 🎉')
                })
                .catch(err => {
                    console.error(err)
                });
        } else {
            setShareModalOpen(true);
        }
    }
    const renderShareButton = () => {
        return (
            <Tooltip title={'Compartir'} placement={'bottom'} style={{margin: '0.5rem'}}>
                <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>

                    <IconButton disableRipple onClick={handleShare} className={importedStyles.actionButton}>
                        <div className={importedStyles.actionButtonLabelWrapper}>
                            <ShareArrow
                                style={{fill: '#000', width: 25, height: 25, marginBottom: '0.1rem'}}/>
                            <small className={importedStyles.actionButtonLabel}>compartir</small>
                        </div>

                    </IconButton>

                </div>
            </Tooltip>
        );
    };

    const handlePrev = () => {
        let newPath = generatePath(match.path, {
            scheduleIdentifier: schedule.url,
            eventIdentifier: props.events[currentEventIndex - 1].url
        });
        history.push(newPath + '?featured=true');
    }

    const handleNext = () => {

        let nextEvent = props.events[currentEventIndex + 1];


        let newPath = generatePath(match.path, {
            scheduleIdentifier: schedule.url,
            eventIdentifier: nextEvent.url
        });


        history.push(newPath + '?featured=true');
    }

    const renderActionButtons = () => {
        return (
            <div className={importedStyles.actionsBox}>
                {/*{schedule.withLikes && renderLikeButton()}*/}
                {/*{schedule.withShare && renderShareButton()}  */}

                <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>

                    <IconButton
                        onClick={handleLike}
                        disableRipple
                        className={liked ? importedStyles.actionButtonFilled : importedStyles.actionButton}
                    >
                        <div className={importedStyles.actionButtonLabelWrapper}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {/*{liked ? <FavoriteOutlined style={{color: redColor, fontSize: 25}}/> : <FavoriteRounded style={{height: 20, width: 20, marginRight: 4}}/>}*/}
                                {liked ? <FavoriteOutlined style={{fill: redColor}}/> :
                                    <FavoriteBorderOutlined style={{fill: '#000'}}/>}
                                <span className={importedStyles.actionButtonCounter}>{event.likes}</span>
                            </div>
                            <small className={importedStyles.actionButtonLabel}>me gusta</small>
                        </div>

                    </IconButton>


                </div>
                {renderShareButton()}
            </div>
        )
    }


    const setMobileAdCookie = () => {
        sessionStorage.setItem("mobileAdPopupShowed-EventId:" + event.id, true);
    }

    const checkMobileAdCookie = () => {
        if (!isMobileWidth) return false; //break execution if not mobile


        if (!event || !event.ads || event.ads.length <= 0) {
            return false;
        }

        let ads = event.ads.filter(item => item.type === 'banner_vertical');

        if (ads.length <= 0) {
            setOpenMobileAdPopup(false)
            return false;
        }

        if (sessionStorage.getItem("mobileAdPopupShowed-EventId:" + event.id) === null) {
            setOpenMobileAdPopup(true)
            setMobileAdCookie();
        } else {
            setOpenMobileAdPopup(false)
        }
    }

    const onAdInViewport = (isVisible, interactionType, adId) => {
        if (isVisible) {
            sendScheduleAdInteraction(interactionType, adId)
        }
    }

    const renderAdBanner = () => {


        let ad = topAdBanner

        if (!topAdBanner) return false;


        return (
            <Fade in={true} timeout={500}>
                {/*<ReactVisibilitySensor onChange={isVisible => onAdInViewport(isVisible, 'view', ad.id)}>*/}
                <AdWithSensorWrapper adObject={ad}>
                    <div id={'patrocinador-evento-container' + event.id}
                         className={importedStyles.adTopContainer}>
                        <a href={ad.action_url} target={'_blank'} style={{marginBottom: -5}}
                           onClick={() => sendScheduleAdInteraction('click', ad.id)}>
                            <img src={ad.src_url} alt={'imagen-patrocinador'} className={importedStyles.adTopImg}
                                 onLoad={() => sendScheduleAdInteraction('view', ad.id)}
                                 style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                        </a>
                    </div>
                </AdWithSensorWrapper>

                {/*</ReactVisibilitySensor>*/}
            </Fade>
        );
    }

    const shuffleArray = array => {

        let copyArray = [...array];
        for (let i = copyArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = copyArray[i];
            copyArray[i] = copyArray[j];
            copyArray[j] = temp;
        }
        return copyArray;
    }

    const handlePopupAdClose = () => {
        setOpenMobileAdPopup(false)

        setTimeout(() => {
            let ads = event.ads.filter(item => item.type === 'banner_vertical');
            if ((currentAdIndex + 1) >= ads.length) {
                dispatch(setCurrentAdIndex(0));
            } else {
                dispatch(setCurrentAdIndex(currentAdIndex + 1));
            }
        }, 50)// just to prevent ad change visual bug when leaving

    }

    const renderMobileAdPopupBanner = () => {


        if (!open || !event || !event.ads || event.ads.length <= 0) {
            return false;
        }

        let ads = popupAds;

        if (ads.length > 0) {

            let ad = ads[currentAdIndex];

            if (!ad) return false; //guard clause

            let content = (
                <img
                    src={ad.src_url}
                    // onLoad={() => sendScheduleAdInteraction('click', ad.id)}
                    alt={''}
                    style={{
                        borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 0 : ad.border_radius,
                        // width: '100%',
                        aspectRatio: '9/21',
                        maxHeight: '87vh',
                        marginBottom: -4
                    }}
                />
            );

            if (ad.action_url && ad.action_url.trim().length > 0) {
                content = (
                    <a href={ad.action_url} target={'_blank'} rel={'noopener noreferrer'}
                       className={importedStyles.mobileAdPopupBannerClickableWrapper}
                       onClick={() => sendScheduleAdInteraction('click', ad.id)}>
                        <img
                            src={ad.src_url}
                            // onLoad={() => sendScheduleAdInteraction('click', ad.id)}
                            alt={''}
                            style={{
                                borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 0 : ad.border_radius,
                                // width: '100%',
                                aspectRatio: '9/21',
                                maxHeight: '87vh',
                                marginBottom: -4
                            }}
                        />
                    </a>
                );
            }

            return (
                <React.Fragment>
                    <AdWithSensorWrapper adObject={ad}>
                        {content}
                    </AdWithSensorWrapper>
                </React.Fragment>
            )
        }


    };

    const renderContent = () => {
        return (
            <Fade in={event && event.content} mountOnEnter unmountOnExit>
                <Interweave
                    allowList={ALLOWED_TAG_LIST.concat('iframe')}
                    content={event.content}
                    attributes={{className: importedStyles.descriptionContainer}}
                    containerTagName={'div'}
                />
            </Fade>
        )
    }

    const getBackButtonTitle = () => {

        if (props.backButtonTitle) {
            return props.backButtonTitle;
        }

        return 'Inicio ';
    }

    const handleDetailClose = () => {
        let newPath = generatePath(match.path, {
            scheduleIdentifier: schedule.url
        });
        history.push(newPath);
        setOpen(false);
    }


    return (
        <Dialog
            open={open}
            keepMounted={true}
            onClose={handleDetailClose}
            scroll={"paper"}
            fullWidth
            fullScreen={isMobileWidth || isMobile}
            TransitionComponent={isMobile ? TransitionMobile : Transition}
            classes={{root: importedStyles.dialogRoot, paper: importedStyles.dialog}}
            // key={'eventDetail_' + event.id} // uncomment if wnat to re-render everytime the current event changes
        >
            <Fade in={open} unmountOnExit mountOnEnter>
                <IconButton className={importedStyles.closeModalButton} onClick={handleDetailClose}>
                    <CloseSharp/>
                </IconButton>
            </Fade>

            {/*<Fade in={state.videoLoading} timeout={300} mountOnEnter unmountOnExit>
                    <LinearProgress size={120} classes={{
                        root: importedStyles.linearProgress,
                        bar: importedStyles.linearProgressBar
                    }}/>
                </Fade>*/}


            <React.Fragment>
                <Fade in={open} timeout={0}>
                    <div className={importedStyles.root}>

                        <React.Fragment>
                            <Hidden smDown>
                                <>
                                    <Grid
                                        container
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            minHeight: '80vh'
                                        }}
                                    >
                                        <Grid item xs={5} style={{
                                            background: '#000',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>

                                            {renderMainImage()}

                                        </Grid>
                                        <Grid item xs={7} style={{display: 'flex', flexDirection: 'column'}}>
                                            {props.withAds && renderAdBanner()}

                                            <div className={importedStyles.bodyContainer}>
                                                <div className={importedStyles.titleContainer}>
                                                    <span className={importedStyles.videoTitle}>{event.title}</span>
                                                    {event.description &&
                                                        <p className={importedStyles.videoUserName}>{event.description}</p>}
                                                </div>
                                                {renderContent()}

                                            </div>
                                            <Divider style={{
                                                backgroundColor: '#BDBDBD',
                                                height: 1,
                                                marginBottom: '0.5rem',
                                                marginTop: 'auto',
                                                marginLeft: '1.25rem',
                                                marginRight: '1.25rem'
                                            }} variant={"middle"}/>
                                            <div className={importedStyles.desktopActionsWrapper}>
                                                {renderActionButtons()}
                                                <div className={importedStyles.navigationBox}>
                                                    <Fade in={currentEventIndex > 0}>
                                                        <IconButton
                                                            style={{
                                                                background: '#b5b5b5',
                                                                padding: 0,
                                                                marginRight: 15
                                                            }}
                                                            onClick={handlePrev}>
                                                            <ChevronLeftSharp
                                                                style={{color: '#fff', fontSize: 42}}/>
                                                        </IconButton>
                                                    </Fade>
                                                    <Fade in={currentEventIndex < (props.events.length - 1)}>
                                                        <IconButton style={{background: '#b5b5b5', padding: 0}}
                                                                    onClick={handleNext}>
                                                            <ChevronRightSharp
                                                                style={{color: '#fff', fontSize: 42}}/>
                                                        </IconButton>
                                                    </Fade>
                                                </div>
                                            </div>


                                        </Grid>
                                    </Grid>
                                </>
                            </Hidden>

                            <Hidden smUp>
                                <Fade
                                    in={isMobile}
                                    // in={false}
                                    unmountOnExit mountOnEnter>
                                    <div>
                                        <div className={importedStyles.navigationTopBar}>

                                            <IconButton
                                                style={{padding: 0}}
                                                onClick={handleDetailClose}
                                            >
                                                <ChevronLeftSharp style={{color: '#000', fontSize: 42}}/>
                                            </IconButton>
                                            <span onClick={handleDetailClose}
                                                  className={importedStyles.navigationTopBarTitle}>
                                                Inicio
                                            </span>

                                        </div>
                                        {props.withAds && renderAdBanner()}

                                        {renderMainImage()}
                                        <Box style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            position: 'relative',
                                            background: '#67b8c1',
                                            borderRadius: 12,
                                            marginTop: '-1.35rem',
                                            marginLeft: 16,
                                            zIndex: 10,
                                            width: 'fit-content',
                                            padding: '0.5rem',
                                        }}>
                                            <EventRounded style={{color: '#fff'}}/>
                                            <Typography style={{
                                                marginLeft: 5,
                                                marginTop: 3,
                                                textTransform: 'uppercase',
                                                color: '#fff',
                                                marginRight: 15,
                                                fontWeight: 600
                                            }}>
                                                {moment(event.date).format('ddd DD/MM/YY')}
                                            </Typography>

                                            <ScheduleRounded style={{color: '#fff'}}/>
                                            <Typography
                                                style={{marginLeft: 5, marginTop: 3, color: '#fff', fontWeight: 600}}>
                                                {moment(event.date).format('HH:mm')}h
                                            </Typography>

                                        </Box>

                                        <div className={importedStyles.bodyContainer}>
                                            <div className={importedStyles.desktopActionsWrapper}>
                                                {renderActionButtons()}
                                                <div className={importedStyles.navigationBox}>
                                                    <Fade in={currentEventIndex > 0}>
                                                        <IconButton
                                                            style={{
                                                                background: '#b5b5b5',
                                                                padding: 0,
                                                                marginRight: 15
                                                            }}
                                                            onClick={handlePrev}>
                                                            <ChevronLeftSharp
                                                                style={{color: '#fff', fontSize: 42}}/>
                                                        </IconButton>
                                                    </Fade>
                                                    <Fade in={currentEventIndex < (props.events.length - 1)}>
                                                        <IconButton style={{background: '#b5b5b5', padding: 0}}
                                                                    onClick={handleNext}>
                                                            <ChevronRightSharp
                                                                style={{color: '#fff', fontSize: 42}}/>
                                                        </IconButton>
                                                    </Fade>
                                                </div>
                                            </div>
                                            <div className={importedStyles.titleContainer}>
                                                <span
                                                    className={importedStyles.videoTitle}>{event.title}</span>
                                                {event.description &&
                                                    <p className={importedStyles.videoUserName}>{event.description}</p>}
                                            </div>
                                            {renderContent()}

                                        </div>

                                    </div>

                                </Fade>

                            </Hidden>

                        </React.Fragment>
                    </div>
                </Fade>
            </React.Fragment>

            {/* MODALS, SNACKS AND ALERTS */}
            <React.Fragment>

                {renderShareModal()}

                <Snack/>
            </React.Fragment>


            <Dialog
                open={props.withAds && openMobileAdPopup}
                // open={true}
                onClose={handlePopupAdClose}
                maxWidth={"md"}
                scroll={"body"}
                fullWidth
                // fullScreen={isMobileWidth}
                TransitionProps={{unmountOnExit: true, mountOnEnter: true}}
                transitionDuration={{exit: 0}}
                classes={{
                    root: importedStyles.adMobilePopupDialogRoot,
                    paper: importedStyles.adMobilePopupDialogPaper
                }}

            >

                <div className={importedStyles.adMobilePopupImageWrapper}>
                    <IconButton className={importedStyles.closeAdModalIcon}
                                onClick={handlePopupAdClose}>
                        <CloseIcon/>
                    </IconButton>
                    {renderMobileAdPopupBanner()}
                </div>

            </Dialog>

        </Dialog>
    );
}


FeaturedEventDetail.propTypes = {
    events: PropTypes.array.isRequired,
};

FeaturedEventDetail.defaultProps = {
    events: [],
    withAds: true
};


export default FeaturedEventDetail;
