import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './IdeasView.module.sass';
import UploadVideoButton from "../../../components/UploadVideoButton/UploadVideoButton";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import {CircularProgress} from "@material-ui/core";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import UploadRetoButton from "../../../components/UploadRetoButton/UploadRetoButton";

class IdeasView extends React.Component {

    state = {
        loading: true,
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        ReactGA.pageview(window.location.pathname);
        ReactPixel.trackCustom('IdeasView', {user: this.props.user.uuid});
    };

    hideSpinner = () => {
        this.setState({loading: false});
    };

    render() {

        return (
            <React.Fragment>
                <NavBar/>
                <UploadVideoButton/>
                <DesktopDrawer/>
                <div className={importedStyles.ideas}>
                    {this.state.loading ?
                        <CircularProgress className={importedStyles.circularProgress}/>
                        : null}

                    <iframe className={importedStyles.iframeStyle}
                            title={'iframe ideas para triunfar'}
                            src={this.props.user.entity ? 'https://www.grabaloapp.com/blog/categories/comprar-videos' : 'https://www.grabaloapp.com/blog/categories/vender-videos'}
                            onLoad={this.hideSpinner}
                    />
                </div>
                <UploadRetoButton/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(IdeasView);
