import {CLOSE_SUGGESTED_LOGIN_MODAL_REQUEST, OPEN_SUGGESTED_LOGIN_MODAL_REQUEST} from "../../../../../constants/action-types";

export const openSuggestedLoginModalRequest = nextRoute => {
    return ({
        type: OPEN_SUGGESTED_LOGIN_MODAL_REQUEST,
        nextRoute: nextRoute
    });

};

export const closeSuggestedLoginModalRequest = () => {
    return ({
        type: CLOSE_SUGGESTED_LOGIN_MODAL_REQUEST
    });

};
