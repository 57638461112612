import {Translator, Translate} from "react-auto-translate";
import React, { Component }  from 'react';
import {cleanHtmlEntities} from "./strings";


export const getTranslatedText = (text, withParagraph = true) => {

    if(!text){
        return text;
    }

    let languageKey = localStorage.getItem('grabalo-user-language');
    let initialSearchPattern = "[" + languageKey + "]";
    let endSearchPattern = "[/" + languageKey + "]";

    if (withParagraph){
         initialSearchPattern = "<p>[" + languageKey + "]</p>";
         endSearchPattern = "<p>[/" + languageKey + "]</p>";
    }

    text = text.replace('dir="ltr"', ''); // remove **dir="ltr"** attribute as it causes bad behavior

    // console.info('languageKey -> ', languageKey)

    if (languageKey === 'vlc'){
        // let positionStart = text.indexOf(initialSearchPattern) + initialSearchPattern.length;
        let positionStart = text.indexOf(initialSearchPattern);

        let positionEnd = text.indexOf(endSearchPattern);

        if (positionStart !== -1 && positionEnd !== -1) {
            // console.log('start -> ', positionStart);
            // console.log('end -> ', positionEnd);
            // console.log('text -> ', text.slice(positionStart, positionEnd));
            text = text.slice(positionStart, positionEnd);
        }

    } else {
        let positionStart = text.indexOf('[vlc]');
        let positionEnd = text.indexOf('[/vlc]');
            // + '[/vlc]'.length;

        if (positionStart !== -1 && text.indexOf('[/vlc]') !== -1){
            text = text.replace(text.slice(positionStart, positionEnd), '');

        }
        // console.log('start -> ', positionStart);
        // console.log('end -> ', positionEnd);
        // console.log('text replaced -> ', text.replace(text.slice(positionStart, positionEnd), ''));

    }

    if (languageKey === 'esp'){
        // let positionStart = text.indexOf(initialSearchPattern) + initialSearchPattern.length;
        let positionStart = text.indexOf(initialSearchPattern);
        let positionEnd = text.indexOf(endSearchPattern);

        if (positionStart !== -1 && positionEnd !== -1) {
            // console.log('start -> ', positionStart);
            // console.log('end -> ', positionEnd);
            // console.log('text -> ', text.slice(positionStart, positionEnd));
            text = text.slice(positionStart, positionEnd);
        }
    } else {
            let positionStart = text.indexOf('[esp]');
            let positionEnd = text.indexOf('[/esp]')
                // + '[/esp]'.length;

            if (positionStart !== -1 && text.indexOf('[/esp]') !== -1){
                text = text.replace(text.slice(positionStart, positionEnd), '');

            }

    }

    // console.log('text -> ', text);

    text = text.replace('[vlc]', '');
    text = text.replace('[/vlc]', '');
    text = text.replace('[esp]', '');
    text = text.replace('[/esp]', '');


    return text;

};
export const getAutoTranslatedText = (text) => {

    if(!text){
        return text;
    }

    return (
        <Translate>
            {text}
        </Translate>
    )

};

export const getGoogleTranslatedText = async (value) => {

    let to = localStorage.getItem(window.location.hostname+'SelectedTranslationFlag');

    //clean text from html entities that can cause bad translations
    value = cleanHtmlEntities(value);

    if (!to){
        return value;
    }

    let apiKey = 'AIzaSyDCakP5VBdf7mO5kmu3In5YlSFRVoJdEls';

    try{
        const response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${apiKey}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    target: to,
                    q: value,
                    format: 'text'
                }),
            }
        );


        const {data, error} = await response.json();

        if (error){
            throw new Error(error.code + ' - ' + error.message)
        }

        value = data.translations[0].translatedText;
    } catch (error){
        console.error('Error in getGoogleTranslatedText() -> ', error)
    }


    return value;

}

