import {
    CLOSE_ENTITIES_ONBOARDING,
    OPEN_ENTITIES_ONBOARDING,
} from "../../../constants/action-types";


const initialState = {
    open: false,
};

const onBoardingEntitiesReducer = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_ENTITIES_ONBOARDING:
            return {...state, open: true};
        case CLOSE_ENTITIES_ONBOARDING:
            return {...state, open: false};
        default:
            return state;
    }
};


export default onBoardingEntitiesReducer;