import {LOGIN_SUCCESS, LOGOUT, UPDATE_USER} from "../../constants/action-types";


const initialState = {
    logged: false,
    user: {},
};

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('loggedOnGrabalo', 'true');
            return {...state, user: action.user, logged: true};
        case LOGOUT:
            localStorage.removeItem('loggedOnGrabalo');
            return {logged: false, user: {}};
        case UPDATE_USER:
            return {...state, user: action.updatedUser};
        default:
            return state;
    }
};


export default authReducer;