import apiBaseUrl from "../apiBaseUrl";

export function deleteVideo(videoId){
   return fetch(apiBaseUrl + 'videos/deleteVideo', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({videoId})
    });
}