import React from "react";
import importedStyles from './DesktopDrawer.module.sass';
import connect from "react-redux/es/connect/connect";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import history from "../../helpers/history";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import {
    EmojiEventsOutlined,
    ExitToApp,
    InputRounded,
    MailOutlined,
    PermIdentityOutlined,
    StarBorderSharp,
    VideoLibraryOutlined
} from '@material-ui/icons';
import {logout} from "../../modules/Auth/authActions";
import Drawer from "@material-ui/core/Drawer";
import {Hidden} from "@material-ui/core";
import ReactPixel from "react-facebook-pixel";
import {pushRoute, throwLogin} from "../../helpers/routeMiddleware";
import ReactGA from 'react-ga';
import {removeAuthParams} from "../../helpers/urlParamsUtilities";
import {ReactComponent as WallOutlinedIcon} from "../../icons/wallOutlinedIcon.svg";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import DownloadIcon from '@material-ui/icons/SaveAltRounded';
import Fade from "@material-ui/core/Fade";


class DesktopDrawer extends React.Component {

    handleLogout = async () => {
        ReactPixel.trackCustom('MenuOptionSelected', {option: 'Log Out'});
        ReactPixel.trackCustom('ClosedSession', {});
        ReactGA.ga('send', 'event', 'click', 'ClosedSession');
        await this.props.dispatch(logout());
        //this.props.dispatch(showSnack('Tu sesión se ha cerrado correctamente', 'warning'));
        history.push('/login');
    };

    handleLogin = () => {
        throwLogin(window.location.pathname + history.location.search).then(r => {
        });
    };


    handleMenuClick = option => () => {
        ReactPixel.trackCustom('MenuOptionSelected', {option: option});
        let filteredParams = removeAuthParams();
        history.push(option + filteredParams);
    };

    handleMenuClickRestricted = option => () => {
        ReactPixel.trackCustom('MenuOptionSelected', {option: option});
        let filteredParams = removeAuthParams();

        // console.log(window.location.origin)

        pushRoute(option + filteredParams);
    };

    renderEntityMenu = () => {
        return (
            <React.Fragment>
                <ListItem
                    button
                    className={importedStyles.listItem}
                    classes={{selected: importedStyles.itemSelected}}
                    key={'mis-eventos'}
                    onClick={this.handleMenuClickRestricted('/mis-eventos')}
                    selected={window.location.pathname === '/myRequests' || window.location.pathname === '/mis-concursos' || window.location.pathname === '/mis-eventos'}
                >
                    <ListItemIcon> <StarBorderSharp style={{fontSize: '2.3rem'}}/> </ListItemIcon>
                    <ListItemText primary={'Mis eventos'}/>
                </ListItem>

                <ListItem className={importedStyles.listItem}
                          classes={{selected: importedStyles.itemSelected}}
                          selected={window.location.pathname === '/favourites' || window.location.pathname === '/favoritos' || window.location.pathname === '/mis-favoritos'}
                          button key={'favourites'}
                          onClick={this.handleMenuClickRestricted('/favoritos')}>
                    <ListItemIcon> <BookmarksOutlinedIcon/> </ListItemIcon>
                    <ListItemText primary={'Favoritos'}/>
                </ListItem>
                <Fade in={this.props.user && this.props.user.entity && this.props.user.permissions_level === 'all-included'} mountOnEnter unmountOnExit>

                    <ListItem className={importedStyles.listItem}
                              classes={{selected: importedStyles.itemSelected}}
                              selected={window.location.pathname === '/muro-de-la-confianza' || window.location.pathname === '/confidenceWall'}
                              button key={'confidenceWall'}
                              onClick={this.handleMenuClickRestricted('/muro-de-la-confianza')}>
                        <ListItemIcon>
                            <WallOutlinedIcon alt={'wallIcon'} heigth={32} width={32}
                                              fill={window.location.pathname === '/muro-de-la-confianza' ? '#00a3da' : '#000'}/>
                        </ListItemIcon>
                        <ListItemText primary={'Muro de favoritos'}/>
                </ListItem>
                </Fade>
                <ListItem className={importedStyles.listItem}
                          classes={{selected: importedStyles.itemSelected}}
                          selected={window.location.pathname === '/downloads' || window.location.pathname === '/mis-descargas'}
                          button key={'activar'}
                          onClick={this.handleMenuClickRestricted('/mis-descargas')}
                >
                    <ListItemIcon> <DownloadIcon/> </ListItemIcon>
                    <ListItemText primary={'Archivos descargados'}/>
                </ListItem>

            </React.Fragment>
        )
    }

    renderNormalUserMenu = () => {
        return (
            <React.Fragment>
                <ListItem
                    className={importedStyles.listItem}
                    classes={{selected: importedStyles.itemSelected}}
                    selected={window.location.pathname === '/requests'} button key={'requests'}
                    onClick={this.handleMenuClick('/requests')}>
                    <ListItemIcon> <EmojiEventsOutlined/> </ListItemIcon>
                    <ListItemText primary={'Concursos - Eventos'}/>
                </ListItem>
                {/*<ListItem className={importedStyles.listItem}*/}
                {/*          classes={{selected: importedStyles.itemSelected}}*/}
                {/*          selected={window.location.pathname === '/categories'} button key={'categories'}*/}
                {/*          onClick={this.handleMenuClick('categories')}>*/}
                {/*    <ListItemIcon> <PaletteOutlined/> </ListItemIcon>*/}
                {/*    <ListItemText primary={'¿Necesitas inspiración?'}/>*/}
                {/*</ListItem>*/}
                <ListItem className={importedStyles.listItem}
                          classes={{selected: importedStyles.itemSelected}}
                          selected={window.location.pathname === '/myVideos' || window.location.pathname === '/mis-archivos'}
                          button key={'myVideos'}
                          onClick={this.handleMenuClickRestricted('/mis-archivos')}>
                    <ListItemIcon> <VideoLibraryOutlined/> </ListItemIcon>
                    <ListItemText primary={'Mis archivos'}/>
                </ListItem>
            </React.Fragment>
        )
    }
    renderContact = () => {
        return (
            <ListItem className={importedStyles.listItem}
                      classes={{selected: importedStyles.itemSelected}}
                      selected={window.location.pathname === '/contact'}
                      button onClick={this.handleMenuClick('/contact')}
                      key={'contact'}>
                <ListItemIcon> <MailOutlined/> </ListItemIcon>
                <ListItemText primary={'Contacto'}/>
            </ListItem>
        )
    }


    render() {
        return (
            <Hidden smDown>
                <Drawer variant="permanent" open anchor="left" elevation={18} className={importedStyles.desktopDrawer}>
                    <List className={importedStyles.list}>

                        {this.props.user && this.props.user.entity && this.renderEntityMenu()}
                        {this.props.user && !this.props.user.entity && this.renderNormalUserMenu()}
                        {/*<ListItem className={importedStyles.listItem}*/}
                        {/*          classes={{selected: importedStyles.itemSelected}}*/}
                        {/*          selected={window.location.pathname === '/FAQS'}*/}
                        {/*          button onClick={this.handleMenuClick('FAQS')}*/}
                        {/*          key={'faq'}>*/}
                        {/*    <ListItemIcon> <HelpOutline/> </ListItemIcon>*/}
                        {/*    <ListItemText primary={'Preguntas frecuentes'}/>*/}
                        {/*</ListItem>*/}
                        <ListItem className={importedStyles.listItem}
                                  classes={{selected: importedStyles.itemSelected}}
                                  selected={window.location.pathname === '/profile'} button key={'profile'}
                                  onClick={this.handleMenuClickRestricted('/profile')}>
                            <ListItemIcon> <PermIdentityOutlined/> </ListItemIcon>
                            <ListItemText primary={'Perfil'}/>
                        </ListItem>
                        {this.props.user && this.props.user.entity && this.renderContact()}

                        <hr className={importedStyles.separator}/>
                        {this.props.logged &&
                        <ListItem className={importedStyles.listItem} button onClick={this.handleLogout}
                                  key={'close'}>
                            <ListItemIcon> <ExitToApp/> </ListItemIcon>
                            <ListItemText primary={'Cerrar sesión'}/>
                        </ListItem>
                        }
                        {!this.props.logged &&
                        <ListItem className={importedStyles.loginButton} button onClick={this.handleLogin}
                                  key={'login'}>
                            <ListItemIcon>
                                <InputRounded/>
                            </ListItemIcon>
                            <ListItemText primary={'Iniciar Sesión'}/>
                        </ListItem>
                        }
                    </List>
                </Drawer>
            </Hidden>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)((DesktopDrawer));
