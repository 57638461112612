import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import history from "../../helpers/history";
import importedStyles from './RequestFiles.module.sass';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import Grid from "@material-ui/core/Grid";
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import Snack from "../../components/Snack/Snack";
import {setRequest} from "./Redux/requestFilesActions";
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';
import {openSuggestedLoginModal} from "../../components/SuggestedLoginModal/Redux/SuggestedLoginModalActions";
import SuggestedLoginModal from "../../components/SuggestedLoginModal/SuggestedLoginModal";
import DownloadIcon from '@material-ui/icons/SaveAltRounded';
import Fade from "@material-ui/core/Fade";
import VideoCard from "../../components/VideoCard/VideoCard";
import NoVideosMessage from "../../components/NoVideosMessage/NoVideosMessage";
import {Helmet} from "react-helmet/es/Helmet";
import {Button} from "@material-ui/core";
import {CSVLink} from "react-csv";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageTitle from "../../components/PageTitle/PageTitle";
import Skeleton from "react-loading-skeleton";
import FileSaver from 'file-saver';
import DownloadAllFilesButton from "../../components/DownloadAllFilesButton/DownloadAllFilesButton";


const isMobile = window.matchMedia("(max-width: 768px)").matches;


class RequestFiles extends React.Component {

    state = {
        modal: false,
        currentTab: 0,
        videos: [],
        requestUploadedVideos: [],
        alreadyUploadedUserVideos: [],
        videoUuid: '',
        actualVideoIndex: null,
        videoToShow: null,
        selectedVideo: null,
        selectedVideoUuid: '',
        videoGallery: false,
        windowInitialHeight: 0,
        keyboardOnScreen: false,
        confirmationAssignModal: false,
        sendingVideo: false,
        waitingResponse: true,
        shareModal: false,
        shouldRenderVideos: false,
        openDetailVideoModal: false,
        totalVideosCount: 0,
        currentPage: 1,
        waitingDownloads: false,
        waitingParticipantsInfo: false,
        participantsInfo: [],
        csvHeaders: [
            {label: "Nombre", key: "name"},
            {label: "Contacto", key: "contact"},
            {label: "Título", key: "title"},
            {label: "Fecha de subida", key: "uploadDate"},
        ]
    };
    csvLinkEl = React.createRef();


    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        const {requestId} = this.props.match.params;
        if (!this.props.request || this.props.request.uuid !== requestId) {
            this.getRequestData();
        }
        this.getRequestData();
        //Analytics
        ReactPixel.trackCustom('RequestFilesView', {requestId: requestId ? requestId : this.props.request.uuid});
        ReactGA.pageview(window.location.pathname);
        ///////////

        //check if owner
        if (this.props.logged && this.props.user.entity && this.props.request.entityId === this.props.user.uuid) {
            this.getRequestUploadedVideos();
        } else {
            if (this.props.request.isOpen) {
                this.getRequestUploadedVideos();
            }
        }
        this.setState({windowInitialHeight: window.innerHeight});
        window.addEventListener('resize', this.hideButtonOnKeyboardShowed);

    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.logged && this.props.logged) {
            //check if owner
            (this.props.user.entity && this.props.request.entityId === this.props.user.uuid) && this.getRequestUploadedVideos();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hideButtonOnKeyboardShowed);
    }

    hideButtonOnKeyboardShowed = () => {
        let actualHeight = window.innerHeight;

        if ((this.state.windowInitialHeight * 0.7) > actualHeight) {//if the current viewport height is smaller than 70% of the original size, it means that the keyboard is up
            this.setState({keyboardOnScreen: true});
        } else if ((this.state.windowInitialHeight * 0.7) < actualHeight) {//if the current viewport height is larger than 70% of the original size, it means that the keyboard is down
            this.setState({keyboardOnScreen: false});
        }
    };

    getRequestData() {
        this.setState({
            waitingResponse: true
        });

        const {requestId} = this.props.match.params;
        const userUuid = this.props.user ? this.props.user.uuid : null;

        fetch(apiBaseUrl + 'requests/getUniqueRequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({requestId, userUuid})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.props.dispatch(setRequest(response.request));
                    } else {
                        this.setState({waitingResponse: false});
                        this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };

    getRequestUploadedVideos = () => {
        this.setState({gettingVideos: true});
        const {requestId} = this.props.match.params;

        fetch(apiBaseUrl + 'requests/listRequestVideos', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({requestId: requestId, entityId: this.props.user.uuid}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    shouldRenderVideos: true,
                    gettingVideos: false,
                    requestUploadedVideos: response.videos,
                    waitingResponse: false,
                    totalVideosCount: response.totalVideosCount
                });
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            this.setState({
                shouldRenderVideos: false,
                gettingVideos: false,
                waitingResponse: false,
            });
            console.error('Error: ', error);
        });
    };

    handleModalStatus = () => {
        this.setState({modal: !this.state.modal});
    };


    handleChangeRadioGroup = (e) => {
        this.setState({videoUuid: e.target.value})
    };

    handleVideoSelection = (videoData) => () => {
        this.setState({selectedVideo: videoData, selectedVideoUuid: videoData.uuid});
    };

    handleAssignConfirmationModal = () => {
        this.setState({confirmationAssignModal: !this.state.confirmationAssignModal})
    };
//////////////////
    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.requestUploadedVideos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({videoToShow: nextVideo, actualVideoIndex: nextIndex});
    };
    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.requestUploadedVideos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({videoToShow: nextVideo, actualVideoIndex: nextIndex});
    };
///////////////////////////


    toggleDrawer = (toggle) => {
        if (!this.props.logged) {
            this.props.dispatch(openSuggestedLoginModal(window.location.pathname))
        } else {
            if (toggle) {
                ReactPixel.trackCustom('ClickOnParticipate', {
                    user: this.props.user.uuid,
                    request: this.props.request.uuid
                });
            }

            this.setState({videoGallery: toggle});
        }
    };

    renderUploadedVideos = () => {
        let videos = this.state.requestUploadedVideos;//.slice(0,10);

        if (videos.length <= 0) {
            return (
                <NoVideosMessage/>
            );
        }


        let videoCards = [];

        let i = 1;
        Object.keys(videos).forEach((key) => {

            let index = parseInt(key);

            let prevVideo = index > 0 && videos[index - 1];
            let nextVideo = index < videos.length && videos[index + 1];

            i > 10 && i++; //Increment counter if actual video is below the 10th first

            let video = videos[key];

            videoCards.push(
                <Fade in mountOnEnter unmountOnExit timeout={{enter: i * 200, exit: 0}}>
                    <Grid className={importedStyles.videoCard} key={key} item xs={12} md={4} lg={3} xl={2}>
                        <VideoCard
                            video={video}
                            title={video.title}
                            author={video.user ? video.user.name : video.name}
                            image={video.coverUrl}
                            withFavorite={true}
                            withDownload={true}
                            withConfidenceWall={true}
                            location={this.props.location}
                            match={this.props.match}
                            prevVideo={prevVideo}
                            nextVideo={nextVideo}
                            mediaType={video.media_type}
                            detailModalBackButtonTitle={'Volver'}
                        />
                    </Grid>
                </Fade>
            )
        });


        return (
            <React.Fragment>
                {videoCards}
            </React.Fragment>
        );

    };

    renderSkeletons = () => {

        let skeletons = [];

        for (let i = 0; i < 6; i++) {
            skeletons.push(
                <Grid item xs={12} md={4} lg={3} xl={2} className={importedStyles.skeletonContainer}>
                    <Skeleton className={importedStyles.skeletonBox} height={'100%'} width={'100%'}/>
                </Grid>
            )
        }

        return (
            <React.Fragment>
                {skeletons}
            </React.Fragment>
        );
    };

    downloadParticipantsInfo = async () => {
        try {
            this.setState({waitingParticipantsInfo: true});

            let response = await fetch(apiBaseUrl + 'requests/getParticipantsInfo', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({requestUuid: this.props.request.uuid}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            let participantsInfo = response.info;

            this.setState({waitingParticipantsInfo: false, participantsInfo: participantsInfo}, () => {
                setTimeout(() => {
                    this.csvLinkEl.current.link.click();
                });
            });

        } catch (error) {
            this.setState({waitingParticipantsInfo: false});
            this.props.dispatch(showSnack('No hemos podido recuperar la información de los participantes, espera unos segundos y vuelve a intentarlo', 'error', true));
            console.error('Error 2: ', error);
        }
    };

    downloadAllFiles = async () => {
        this.setState({waitingDownloads: true});

        let files = this.state.requestUploadedVideos;

        if (files.length <= 0) {
            return false;
        }


        Object.keys(files).forEach((key) => {
            let file = files[key];

            setTimeout(() => {
                if (file.media_type === 'video') {
                    this.downloadVideo(file);
                } else if (file.media_type === 'image') {
                    this.downloadImage(file);
                }
            }, 200)


        });


        this.setState({waitingDownloads: false});

    };

    downloadImage = async (file) => {
        try {
            const image = await fetch(apiBaseUrl + 'images/downloadImage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({imageUrl: file.url})

            });
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);

            this.triggerAutoDownloadFile(imageURL, file.title)


        } catch (err) {
            console.error('Error en descarga de imagen: ' + err);
            // this.props.dispatch(showSnack('Error al generar tu descarga', snackTypes.error));
        }

    }
    downloadVideo = async (video) => {

        let entityId = this.props.user.uuid;
        let videoId = video.vimeoId;

        try {
            let response = await fetch(apiBaseUrl + 'downloads/retrieveVideoDownload', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({videoId: videoId, entityId: entityId}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            response.downloadLinks.forEach((videoQuality) => {
                if (videoQuality.quality === 'source') {

                    this.triggerAutoDownloadFile(videoQuality.link, video.title);
                }
            });


        } catch (error) {
            console.error('Error: ', error);
        }
    }

    triggerAutoDownloadFile = (downloadUrl, title) => {
        console.log('download file: ', title)

        // FileSaver.saveAs(downloadUrl, title);
        let link = document.createElement('a');
        link.href = downloadUrl;
        link.download = title;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Archivos - {this.props.request.admin_title}</title>
                </Helmet>
                <NavBar/>
                <Snack/>
                <DesktopDrawer/>
                <SuggestedLoginModal/>
                <div className={importedStyles.RequestFiles}>
                    <PageTitle title={'Archivos subidos'}
                               subtitle={<>Visualiza, añade a favoritos y descarga los archivos subidos al
                                   evento <b>'{this.props.request.admin_title}'.</b></>}/>

                    <div className={importedStyles.csvActions}>

                        <DownloadAllFilesButton from={this.props.request.admin_title} user={this.props.user}
                                                style={{marginRight: !isMobile ? '1rem' : null}}
                                                className={importedStyles.downloadAllFilesButton} fullWidth={isMobile}/>

                        <Fade
                            in={this.props.user && this.props.user.entity && this.props.user.permissions_level === 'all-included'}
                            mountOnEnter unmountOnExit>
                            <Button
                                variant={"contained"}
                                className={importedStyles.downloadButton}
                                // style={{backgroundColor: '#cfeeff', boxShadow: 'none', color: '#00a3da', marginLeft: isMobile ? 16 : 0, borderRadius: 355, textTransform: 'none', fontWeight: "bold"}}
                                onClick={this.downloadParticipantsInfo}
                            >
                                {!this.state.waitingParticipantsInfo &&
                                    <DownloadIcon style={{color: '#00a3da', marginRight: 10}}/>}
                                {this.state.waitingParticipantsInfo &&
                                    <CircularProgress size={20} style={{color: '#00a3da', marginRight: 10}}/>}
                                Descargar contactos
                            </Button>
                        </Fade>
                        <CSVLink
                            headers={this.state.csvHeaders}
                            filename={"grabaloapp_contactos_evento_" + this.props.request.url + "(" + moment(moment.now()).format("DD-MM-YYYY") + ").csv"}
                            data={this.state.participantsInfo}
                            ref={this.csvLinkEl}
                        />
                    </div>
                    <Grid container className={importedStyles.videosContainer} direction={'row'}
                          spacing={!isMobile && 4}>
                        <Fade in={!this.state.gettingVideos} unmountOnExit mountOnEnter>
                            {this.renderUploadedVideos()}
                        </Fade>
                        <Fade in={this.state.gettingVideos} unmountOnExit mountOnEnter
                              timeout={{enter: 400, exit: 200}}>
                            {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
                            {/*    <CircularProgress style={{color: '#10212e' }} size={45}/>*/}
                            {/*</div>*/}
                            {this.renderSkeletons()}
                        </Fade>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, requestFilesReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        request: requestFilesReducer.request,
    });
};

export default connect(mapStateToProps)(RequestFiles);
