import React from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import connect from "react-redux/es/connect/connect";
import Lottie from "react-lottie";
import gettingData3 from "../../animations/gettingData3"
import {DialogActions, LinearProgress} from "@material-ui/core";
import importedStyles from "./GettingMetadataAlert.module.sass";

class GettingMetadataAlert extends React.Component {

    state = {

    };


    componentDidMount() {
    }

    handleModalClose = () => {

    };

    renderAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: gettingData3,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
                <Lottie options={defaultOptions}
                        height={'20rem'}
                        //width={'20rem'}
                        speed={0.5}
                />

        )
    };

    renderProgressBar = () => {
        return (
            <div className={importedStyles.progressBarContainer}>
                <div className={importedStyles.progressBarMask}/>
                <LinearProgress
                    variant="determinate"
                    className={importedStyles.progressBar}
                    classes={{barColorPrimary: importedStyles.currentProgress}}
                    value={this.props.uploadProgress}
                />
                <span className={importedStyles.progressBarPercent}>{this.props.uploadProgress}%</span>
            </div>
        );
    };

    render() {
        return (
            <Dialog
                open={this.props.open}
                disableBackdropClick
                aria-labelledby="Getting video metadata alert"
            >
                <DialogTitle>
                    Validando vídeo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{textAlign: 'center'}}>
                     Parece que tu vídeo está en 4K o con un códec de alta calidad (h265, VP9 ...étc). <br/>
                     Por favor, espera unos instantes mientras validamos tu vídeo.
                    </DialogContentText>
                    {this.renderAnimation()}
                </DialogContent>
                <DialogActions style={{display: "block"}}>
                    {this.renderProgressBar()}
                </DialogActions>
            </Dialog>
        );

    }
}


const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(GettingMetadataAlert);