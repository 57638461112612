import {CLOSE_SUGGESTED_LOGIN_MODAL, OPEN_SUGGESTED_LOGIN_MODAL} from "../../../constants/action-types";

export const openSuggestedLoginModal = nextRoute => {
    return ({
        type: OPEN_SUGGESTED_LOGIN_MODAL,
        nextRoute: nextRoute
    });

};

export const closeSuggestedLoginModal = () => {
    return ({
        type: CLOSE_SUGGESTED_LOGIN_MODAL
    });

};
