import apiBaseUrl from "../../helpers/apiBaseUrl";

export let isVideoLiked = (video, requestUuid) => {
    // Getting vote from localStorage
    return localStorage.getItem("vote" + requestUuid + video.uuid);

    // let videoVotes = video.votes_on_request ? video.votes_on_request : [];
    // return videoVotes.some(vote => {
    //     return (vote.requestUuid === requestUuid && vote.userUuid === userUuid)
    // });
};

export let isVideoFavorite = (video) => {
    return video.favourite_videos && video.favourite_videos.length > 0;
};

export let addVote = (oldVotes, newVote) => {
    return [...oldVotes, newVote];
};

export let removeVote = (oldVotes, vote) => {
    let newVotes = oldVotes.filter(function (obj) {
        return obj.userUuid !== vote.userUuid;
    });
    return newVotes;
};


export let sendPendingToAssociateMediaIds = (user, openThankyouModalFunction) => {

    if (openThankyouModalFunction && typeof openThankyouModalFunction === 'function') {
        openThankyouModalFunction();
    }
    // return false;

    let arrayOfUploadedIds = localStorage.getItem('arrayOfUploadedIdsPending');
    if (arrayOfUploadedIds) {
        arrayOfUploadedIds = JSON.parse(arrayOfUploadedIds);
        fetch(apiBaseUrl + 'users/assignPendingMedia', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({mediaIds: arrayOfUploadedIds, user_id: user.uuid})
        }).then(res => {
            if (!res.ok) {
                const message = `An error has occured: ${res.status}`;
                throw new Error(message);
            }
            return res.json();
        }).then(response => {
                if (response.error) {
                    throw new Error(response.errorMessage)
                }
                // openThankyouModalFunction();
                localStorage.removeItem('arrayOfUploadedIdsPending');
            },
        ).catch(err => {
            console.log('Error: ' + err);
        });
    } else {
        console.info('This user has no pending media to associate')
    }

};
