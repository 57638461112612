import React, {useState} from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import {sendAdInteraction, sendScheduleAdInteraction} from "../../helpers/stats/statsAPI";


const AdWithSensorWrapper = ({adObject, host, children}) => {

    const [hasBeenVisible, setHasBeenVisible] = useState(false);

    const onAdInViewport = (isVisible, interactionType, adId) => {
        if (isVisible) {
            host === 'schedule' ? sendScheduleAdInteraction(interactionType, adId) : sendAdInteraction(interactionType, adId);
            setHasBeenVisible(true);
        }
    }

    return (
        <ReactVisibilitySensor onChange={isVisible => onAdInViewport(isVisible, 'view', adObject.id)} active={!hasBeenVisible} delayedCall={true}>
            {children}
        </ReactVisibilitySensor>
    );
};


export default AdWithSensorWrapper;
