import {CLOSE_FIRST_LOGIN_MODAL, OPEN_FIRST_LOGIN_MODAL} from "../../../constants/action-types";

export const openFirstLoginModal = () => {
    return ({
        type: OPEN_FIRST_LOGIN_MODAL,
    });

};

export const closeFirstLoginModal = () => {
    return ({
        type: CLOSE_FIRST_LOGIN_MODAL
    });

};
