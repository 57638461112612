import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './ConfidenceWall.module.sass';
import history from "../../../helpers/history";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import Snack from "../../../components/Snack/Snack";
import {Fade, Typography} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import ReactPixel from "react-facebook-pixel";
import {setVideo} from "../../DetailVideo/Redux/detailVideoActions";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import NoVideosMessage from "../../../components/NoVideosMessage/NoVideosMessage";
import {Helmet} from "react-helmet/es/Helmet";
import VideoCard from "../../../components/VideoCard/VideoCard";
import {setVideos} from "../../DetailVideoModal/Redux/detailVideoModalActions";
import Fab from "@material-ui/core/Fab";
import StatsIcon from '@material-ui/icons/BarChartRounded';
import VideosIcon from '@material-ui/icons/VideocamRounded';
import {videosPerPage} from "../../../constants/constants";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import {ConfidenceWallStats} from "./ConfidenceWallStats/ConfidenceWallStats";
import PageTitle from "../../../components/PageTitle/PageTitle";

// Flag to check if it's a mobile screen
const isMobile = window.matchMedia("(max-width: 768px)").matches;


class ConfidenceWall extends React.Component {

    state = {
        wall: [],
        videos: [],
        selectedVideo: null,
        actualVideoIndex: null,
        waitingResponse: true,
        nextVideo: null,
        openDetailVideoModal: false,
        currentPage: 1,
        currentTab: 0,
    };


    // LIFECYCLES
    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getConfidenceWallVideos();
        ReactPixel.trackCustom('ConfidenceWall', {user: this.props.user.uuid});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.UrlParamsObserver(prevProps);
    }

    // FETCH
    getConfidenceWallVideos = () => {

        this.setState({
            waitingResponse: true
        });

        let urlSlugVideoWall = this.props.user.videowalls ? this.props.user.videowalls[0].urlSlug : '';

        fetch(apiBaseUrl + 'videowall/get', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({urlSlug: urlSlugVideoWall, currentPage: this.state.currentPage})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            wall: response.data,
                            stats: response.data.stats,
                            videos: response.data.videos,
                            totalDownloadsCount: response.totalDownloadsCount,
                            waitingResponse: false,
                        });
                        this.props.dispatch(setVideos(response.data.videos));
                    } else {
                        this.setState({waitingResponse: false});
                        this.props.dispatch(showSnack('Error al cargar los datos', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };

    // HANDLERS
    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        this.props.dispatch(setVideo(nextVideo));
        ReactPixel.trackCustom('ClickOnVideoFromConfidenceWall', {video: nextVideo.uuid});
    };
    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        this.props.dispatch(setVideo(nextVideo));
        ReactPixel.trackCustom('ClickOnVideoFromConfidenceWall', {video: nextVideo.uuid});
    };

    // RENDERS
    renderMobileSkeletons = () => {

        let skeletons = [];

        for (let i = 0; i < 6; i++) {
            skeletons.push(
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2} className={importedStyles.skeletonContainer}>
                    <Skeleton className={importedStyles.skeletonBox} height={'100%'} width={'100%'}/>
                </Grid>
            )
        }

        return (<React.Fragment>{skeletons}</React.Fragment>);
    };
    renderMobileVideos = () => {
        const videos = this.state.videos;
        if (!videos || videos.length <= 0) {
            return (
                <Grid item xs={12} className={importedStyles.gridItemCenteredContent}>
                    <NoVideosMessage/>
                </Grid>
            );
        } else {
            let videoCards = [];
            Object.keys(videos).forEach((key) => {

                let index = parseInt(key);

                let video = videos[index];
                let prevVideo = index > 0 && videos[index - 1];
                let nextVideo = index < videos.length && videos[index + 1];


                if (video) {
                    let title = video.title.length > 35 ? video.title.substr(0, 35) + '...' : video.title;
                    let author = video.user ? video.user.name : video.name;

                    videoCards.push(
                        <Grid className={importedStyles.gridItemCenteredContent} key={key} item xs={12} sm={6} md={3}
                              lg={3} xl={2}>
                            <VideoCard
                                video={video}
                                title={video.title}
                                author={author}
                                // onClick={this.handleDetailVideoModal(video, index)}
                                image={video.coverUrl}
                                withLikes={false}
                                withShare={false}
                                withFavorite={true}
                                withDownload={true}
                                withConfidenceWall={true}
                                location={this.props.location}
                                match={this.props.match}
                                prevVideo={prevVideo}
                                nextVideo={nextVideo}
                            />
                        </Grid>
                    );
                }

            });
            return (
                <React.Fragment>
                    {videoCards}

                </React.Fragment>
            );
        }
    };


    handlePageChange = (event, page) => {
        this.setState({currentPage: page}, () => {
            this.getConfidenceWallVideos();
        });
    };
    renderPagination = () => {
        let totalPages = Math.ceil(this.state.totalDownloadsCount / videosPerPage).toString();
        let isMobile = window.matchMedia("(max-width: 768px)").matches ? 'small' : 'medium';
        return (
            <Grid item xs={12} className={importedStyles.paginatorContainer}>
                <Pagination
                    count={totalPages}
                    page={this.state.currentPage} onChange={this.handlePageChange}
                    boundaryCount={1}
                    siblingCount={1}
                    size={isMobile}
                    showFirstButton
                    showLastButton
                />
            </Grid>
        )
    };


    renderContent = () => {
        function a11yProps(index) {
            return {
                id: `wall-tab-${index}`,
                'aria-controls': `wall-tabpanel-${index}`,
            };
        }

        function TabPanel(props) {
            const {children, value, index, ...other} = props;

            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`wall-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            <Typography>{children}</Typography>
                        </Box>
                    )}
                </div>
            );
        }

        return (
            <React.Fragment>
                <div id={'contentChangeButtons'} className={importedStyles.contentChangeButtons}>
                    <Fab variant="extended"
                         aria-label="videos"
                         onClick={() => this.setState({currentTab: 0})}
                         className={this.state.currentTab === 0 ? importedStyles.tabButtonSelected : importedStyles.tabButton}
                    >
                        <VideosIcon style={{marginRight: 10}}/>
                        Archivos
                    </Fab>
                    <Fab
                        style={{marginLeft: '1rem'}}
                        variant="extended"
                        disabled={this.state.videos.length <= 0}
                        aria-label="stats"
                        onClick={() => this.setState({currentTab: 1})}
                        className={this.state.currentTab === 1 ? importedStyles.tabButtonSelected : importedStyles.tabButton}
                    >
                        <StatsIcon style={{marginRight: 10}}/>
                        Estadísticas
                    </Fab>
                </div>
                <SwipeableViews
                    id={'contentBoxWithSwipe'}
                    index={this.state.currentTab}
                    onChangeIndex={(index) => {
                        this.setState({currentTab: index})
                    }}
                >
                    <TabPanel value={this.state.currentTab} index={0} id={'VideosPanel'} className={importedStyles.tabPanel}>
                        <Grid container className={importedStyles.gridContainer} spacing={!isMobile && 4}>

                                <Fade direction={'top'} unmountOnExit mountOnEnter in={this.state.waitingResponse}
                                      // timeout={{enter: 400, exit: 200}}
                                >
                                    {this.renderMobileSkeletons()}
                                </Fade>
                                <Fade in={!this.state.waitingResponse} mountOnEnter unmountOnExit>
                                    {this.renderMobileVideos()}
                                </Fade>

                        </Grid>
                    </TabPanel>
                    <TabPanel value={this.state.currentTab} index={1} id={'StatsPanel'}>
                        <ConfidenceWallStats wall={this.state.wall} videos={this.state.videos}
                                             stats={this.state.stats}/>
                    </TabPanel>
                </SwipeableViews>
            </React.Fragment>
        );
    }


    renderPageSubtitle = () => {
        if (this.state.currentTab === 1) return 'Analiza las principales métricas de tu muro'
        else return 'Visualiza todos los archivos que has añadido a tu muro';
    }

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Muro de favoritos</title>
                </Helmet>
                <NavBar/>
                <Snack/>
                <DesktopDrawer/>

                <div className={importedStyles.myWall}>
                    <PageTitle title={'Muro de favoritos'} subtitle={this.renderPageSubtitle()}/>
                    {/*<div className={importedStyles.header}>*/}


                    {/*<Fab*/}
                    {/*    variant="extended"*/}
                    {/*    size="medium"*/}
                    {/*    color="primary"*/}
                    {/*    aria-label="embed"*/}
                    {/*    className={importedStyles.embedCodeButton}*/}
                    {/*>*/}
                    {/*    <CodeIcon className={importedStyles.embedIcon}/> Insertar*/}
                    {/*</Fab>*/}
                    {/*</div>*/}
                    <div id={'TABS'}>
                        {this.renderContent()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(ConfidenceWall);
