import React from "react";
import connect from "react-redux/es/connect/connect";
import {CircularProgress, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import importedStyles from "./RegisterModalRequest.module.sass";
import {showSnack, snackTypes} from "../../../../components/Snack/SnackActions";
import ReactPixel from "react-facebook-pixel";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import apiBaseUrl from "../../../../helpers/apiBaseUrl";
import Snack from "../../../../components/Snack/Snack";
import ReactGA from "react-ga";
import GoogleLogin from "react-google-login";
import GoogleIcon from "../../../../icons/google-icon.png";
import FacebookIcon from "../../../../icons/facebook-icon.png";
import {login} from "../../../Auth/authActions";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {closeRegisterRequestModal} from "./Redux/RegisterModalRequestActions";
import PropTypes from "prop-types";
import {openSuggestedLoginModalRequest} from "../SuggestedLoginModalRequest/Redux/SuggestedLoginModalRequestActions";
import {IncompatibleBrowserModal} from "../../../../components/IncompatibleBrowserModal/IncompatibleBrowserModal";
import {isInAppBrowser} from "../../../../helpers/checkers";
import TextField from "@material-ui/core/TextField";
import {sendPendingToAssociateMediaIds} from "../../helpers";
/* END OF IMPORTS*/

const isMobile = window.matchMedia("(max-width: 768px)").matches;

class RegisterModalRequest extends React.Component {

    state = {
        name: '',
        contactName: '',
        email: '',
        password: '',
        checkTerms: false,
        viralCode: '',
        selectedTab: 0,
        actualStep: 1,
        registeringWithSocial: false,
        rol: ''
    };

    /* LIFECYCLES */
    componentDidMount() {
        if (this.props.open) {
            this.handleModalClose()
            // document.getElementById('root').style.filter = 'blur(8px)';
            // this.handleParamsActions();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.open) {
            document.getElementById('root').style.filter = 'blur(8px)';
        }
        if (this.props.open && !prevProps.open) {
            this.handleParamsActions()
        }
    }

    componentWillUnmount() {
        document.getElementById('root').style.filter = 'unset';
    }

    /*HANDLERS*/
    handleParamsActions = () => {
        ReactGA.event({
            category: 'click',
            action: 'ModalRegistroAbierto',
            label: this.props.request ? this.props.request.uuid : 'notRequest',
        });
        ReactPixel.trackCustom('ModalRegistroAbierto');
        // ReactGA.ga('send', 'event', 'click', 'ModalRegistroAbierto');
    };
    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
    handleCheckChange = (e, checked) => {
        this.setState({[e.target.name]: checked, alertTerms: false});
    };
    handleEmailInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value, authEmailError: !this.checkValidEmail(e.target.value)});
    };
    handleModalClose = () => {
        document.getElementById('root').style.filter = 'unset';
        this.props.dispatch(closeRegisterRequestModal());
    };
    handleChangeTab = (val, e) => {
        this.setState({selectedTab: e})
    };

    checkValidEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    /* API CALLS */
    handleRegister = () => {

        this.setState({
            authPasswordError: false,
            authEmailError: false,
            waitingResponse: true
        });

        let name = this.state.name;
        let role = 'user';
        let email = this.state.email;
        let password = this.state.password;
        let videoUuidUploaded = localStorage.getItem('videoUuidUploaded');

        fetch(apiBaseUrl + 'auth/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, email, password, role, videoUuidUploaded, version: 'v2'})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            name: '',
                            email: '',
                            password: '',
                            viralCode: '',
                            waitingResponse: false,
                            waitingRegisterResponse: false
                        });
                        ReactPixel.trackCustom('RegistradoConExito');
                        ReactGA.event({
                            category: 'register',
                            action: 'RegistradoConExito',
                            label: this.props.request ? this.props.request.uuid : 'notRequest',
                            //Must be INT value: email
                        });

                        if (response.user){
                            this.props.dispatch(login(response.user));
                        }
                        sendPendingToAssociateMediaIds(response.user, this.props.openThankYouModalFunction);

                        // if(videoUuidUploaded){
                        //     this.props.openThankYouModalFunction();
                        // }

                        //this.props.dispatch(showSnack('¡Genial! Revisa el correo que te hemos enviado para activar tu cuenta, échale un vistazo a la bandeja de spam si no lo encuentras', snackTypes.success, true, true));
                        document.getElementById('root').style.filter = 'unset'; //unset backdrop blur
                        localStorage.removeItem('videoUuidUploaded')
                        this.handleModalClose();
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingResponse: false});
                                this.props.dispatch(showSnack('El correo ya está en uso o no es válido', snackTypes.error));
                                break;
                            case 'viralCodeInvalid':
                                this.setState({viralCodeError: true, waitingResponse: false});
                                this.props.dispatch(showSnack('El código amigo no es válido o ha caducado, revísalo e inténtalo de nuevo', snackTypes.error));
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error en el registro, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                        ReactPixel.trackCustom('ErrorEnRegistro', {error: response.errorType});
                        ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + response.errorType + '');
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + error + '');
                    ReactPixel.trackCustom('ErrorEnRegistro', {error: error});
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                }
            )
    };
    registerWithSocialAccount = (name, email, authToken, platform) => {
        this.setState({
            authPasswordError: false,
            authEmailError: false,
            waitingResponse: true,
            registeringWithSocial: platform,
        });
        let videoUuidUploaded = localStorage.getItem('videoUuidUploaded');


        fetch(apiBaseUrl + 'auth/registerWithSocialAccount', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                authToken: authToken,
                authProvider: platform,
                videoUuidUploaded,
                version: 'v2'
            })

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            waitingResponse: false,
                            waitingRegisterResponse: false,
                            registeringWithSocial: false
                        });
                        ReactPixel.trackCustom('RegistradoConExito');
                        // ReactGA.ga('send', 'event', 'register', 'RegistradoConExito', 'user');
                        ReactGA.event({
                            category: 'register',
                            action: 'RegistradoConExito',
                            label: this.props.request ? this.props.request.uuid : 'notRequest',
                            //Must be INT value: email
                        });
                        this.props.dispatch(login(response.user));
                        this.handleModalClose();
                        sendPendingToAssociateMediaIds(response.user, this.props.openThankYouModalFunction);

                        // if (videoUuidUploaded) {
                        //     localStorage.removeItem('videoUuidUploaded');
                        //     this.props.openThankYouModalFunction()
                        // }
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({
                                    waitingResponse: false,
                                    registeringWithSocial: false
                                });
                                this.props.dispatch(showSnack('Ya existe una cuenta con el email proporcionado', snackTypes.error));
                                break;
                            default:
                                this.setState({waitingResponse: false, registeringWithSocial: false});
                                this.props.dispatch(showSnack('Error en el registro, vuelve a intentarlo', snackTypes.error));
                                break;
                        }
                        ReactPixel.trackCustom('ErrorEnRegistro', {error: response.errorType});
                        ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + response.errorType + '');
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        registeringWithSocial: false,
                        isLoaded: true,
                        error
                    });
                    ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + error + '');
                    ReactPixel.trackCustom('ErrorEnRegistro', {error: error});
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                }
            )
    };
    handleLogin = () => {
        this.props.dispatch(closeRegisterRequestModal());
        this.props.dispatch(openSuggestedLoginModalRequest());
        ReactGA.event({
            category: 'click',
            action: 'ModalLoginAbierto',
            label: this.props.request ? this.props.request.uuid : 'notRequest',
        });
    };
    handleGoogleResponse = (response) => {
        //console.log('Response google: ', response);
        let name = response.profileObj.name;
        let email = response.profileObj.email;
        let authToken = response.googleId;

        this.registerWithSocialAccount(name, email, authToken, 'google');
    };
    handleGoogleFailure = (response) => {
        this.props.dispatch(showSnack('Error en el registro con Google, vuelve a intentarlo', snackTypes.error));
        console.error('Auth response FAILED google: ', response);
    };
    handleFBResponse = (response) => {
        let name = response.name;
        let email = response.email;
        let authToken = response.userID;

        this.registerWithSocialAccount(name, email, authToken, 'facebook');

    };



    /* RENDERS */
    renderSocialRegisterButtons = () => {
        return (
            <React.Fragment>
                <div className={importedStyles.socialLoginContainer}>
                    <GoogleLogin
                        render={renderProps => (
                            <Button variant={'contained'} fullWidth onClick={() => {
                                if (this.state.checkTerms)
                                    return renderProps.onClick();
                                else {
                                    this.setState({alertTerms: true});
                                }
                            }}
                                    disabled={this.state.registeringWithSocial === 'google'}
                                    className={importedStyles.googleButton}>
                                <div className={importedStyles.socialLoginPlatformIconContainer}>
                                    <img src={GoogleIcon} alt={'google icon'}
                                         className={importedStyles.socialLoginPlatformIcon}/>
                                </div>
                                <span className={importedStyles.socialLoginPlatformName}>Regístrate con Google</span>
                                {this.state.registeringWithSocial === 'google' &&
                                <CircularProgress className={importedStyles.waitingSocialLoginProgress} size={25}/>}
                            </Button>
                        )}
                        clientId="421190726876-g6j9ppjlort8actathh13k1pnt572iii.apps.googleusercontent.com"
                        onSuccess={this.handleGoogleResponse}
                        onFailure={this.handleGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                    <FacebookLogin
                        appId={"772508366890225"}
                        autoLoad={true}
                        //disableMobileRedirect={true}
                        //isMobile={false}
                        //redirectUri={window.location.href}
                        fields="name,email,picture"
                        render={renderProps => (
                            <Button variant={'contained'} fullWidth onClick={() => {
                                if (this.state.checkTerms)
                                    return renderProps.onClick();
                                else {
                                    this.setState({alertTerms: true});
                                }
                            }}
                                    disabled={this.state.registeringWithSocial === 'facebook'}
                                    className={importedStyles.facebookButton}>
                                <div className={importedStyles.socialLoginPlatformIconContainer}>
                                    <img src={FacebookIcon} alt={'fb icon'}
                                         className={importedStyles.socialLoginPlatformIcon}/>
                                </div>
                                <span className={importedStyles.socialLoginPlatformName}>Regístrate con Facebook</span>
                                {this.state.registeringWithSocial === 'facebook' &&
                                <CircularProgress className={importedStyles.waitingSocialLoginProgress} size={25}/>}
                            </Button>
                        )}
                        callback={this.handleFBResponse}
                    />
                </div>
                {
                    this.state.alertTerms &&
                    <div className={importedStyles.alertTerms}>
                        <p className={importedStyles.alertTermsText}>
                            No puedes continuar si no aceptas la Política de Privacidad de nuestra página. Pincha en el cuadro que hay más abajo para poder continuar.
                        </p>
                    </div>}
                {/*<div className={importedStyles.insetFormDivider}>*/}
                {/*    <span className={importedStyles.dividerSection}/> <span*/}
                {/*    className={importedStyles.dividerText}> o con </span> <span*/}
                {/*    className={importedStyles.dividerSection}/>*/}
                {/*</div>*/}
            </React.Fragment>
        )
    };

    renderForm = () => {
        let disabledQuery = (this.state.waitingResponse || !this.state.email || !this.state.name || this.state.authEmailError ||
            !this.state.password || !this.state.checkTerms);
        return (
            <React.Fragment>
                {this.renderSocialRegisterButtons()}

                <div className={importedStyles.insetFormDivider}>
                    <span className={importedStyles.dividerSection}/> <span
                    className={importedStyles.dividerText}> o con </span> <span
                    className={importedStyles.dividerSection}/>
                </div>
                <TextField
                    margin={"dense"}
                    id="name"
                    name="name"
                    label={'* Nombre'}
                    type="text"
                    inputProps={{maxLength: 50}}
                    value={this.state.name}
                    fullWidth
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />
                <TextField
                    margin={"dense"}
                    id="email"
                    name="email"
                    label="* Correo"
                    type="text"
                    inputProps={{maxLength: 50}}
                    value={this.state.email}
                    error={this.state.authEmailError}
                    helperText={this.state.authEmailError && 'Debes introducir un correo válido'}
                    fullWidth
                    onChange={this.handleEmailInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />
                <TextField
                    margin={"dense"}
                    id="password"
                    name="password"
                    label='* Contraseña'
                    type="password"
                    inputProps={{maxLength: 50}}
                    value={this.state.password}
                    fullWidth
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />
                <div className={importedStyles.actionsContainer}>
                    <Button
                        id={'registerButton'}
                        fullWidth
                        className={importedStyles.submitButton}
                        variant='contained'
                        disabled={disabledQuery}
                        onClick={this.handleRegister}
                    >
                        Regístrate {this.state.waitingResponse &&
                    <CircularProgress className={importedStyles.progressCircle}/>}
                    </Button>
                </div>
                <div className={importedStyles.termsCheck}>
                    <Checkbox
                        style={{color: '#00a3da'}}
                        onChange={this.handleCheckChange}
                        checked={this.state.checkTerms}
                        name='checkTerms'
                    />
                    <p className={importedStyles.linkToTerms}>
                        He leído y acepto la <a rel='noopener noreferrer' target='_blank'
                                                href={'https://grabaloapp.com/politica-de-privacidad'}>política de privacidad</a> y
                        <a rel='noopener noreferrer' target='_blank' href={'https://grabaloapp.com/aviso-legal'}> los términos y
                            condiciones
                            de uso</a>.
                    </p>
                </div>
                <div className={importedStyles.infoLOPDContainer}>
                    <h3>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS</h3>
                    <p>
                        RESPONSABLE: GRABA TU TALENTO, S.L.U. (GRÁBALO)
                        <br/>
                        <br/>
                        FINALIDAD:
                        Gestionar tu alta como usuario registrado en nuestra Plataforma para poder acceder a nuestros servicios, de conformidad con las condiciones de los mismos.
                        {/*Remisión de comunicaciones comerciales por medios electrónicos.*/}
                        <br/>
                        <br/>

                        DERECHOS:
                        Puedes acceder, rectificar y suprimir tus datos personales, así como ejercer otros derechos dirigiéndote a info@grabaloapp.com.
                        <br/>
                        <br/>

                        INFORMACIÓN ADICIONAL:
                        Puede consultar nuestra <a rel='noopener noreferrer' target='_blank' href={'/terms#privacy'}>política de
                        privacidad</a> aquí.
                    </p>

                </div>

            </React.Fragment>
        )
    };


    render() {
        const fullscreen = window.matchMedia("(max-width: 768px)").matches;
        let disabledQuery = (this.state.waitingResponse || !this.state.email || this.state.authEmailError ||
            !this.state.password || !this.state.checkTerms);

        return (
            <React.Fragment>
                <Dialog
                    open={this.props.open}
                    maxWidth={'xs'}
                    onClose={this.handleModalClose}
                    fullWidth={true}
                    fullScreen={fullscreen}
                    scroll={"body"}
                    classes={{paper: importedStyles.dialogMain, paperFullScreen: importedStyles.dialogMobileFullScreen}}
                    BackdropProps={{className: importedStyles.dialogBackdrop}}
                >
                    <IconButton className={importedStyles.closeModalIcon} onClick={this.handleModalClose}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle>
                        <h4 className={importedStyles.dialogTitle}>Paso 2 de 2: regístrate o accede para participar</h4>
                    </DialogTitle>
                    <DialogContent className={importedStyles.dialogContent}
                                   classes={{root: importedStyles.dialogRootFix}}>
                        <form className={importedStyles.RegisterFormCardContent}>
                            {this.renderForm()}
                        </form>
                        <div className={importedStyles.dialogActions}>
                           {/* <div className={importedStyles.actionsContainer}>
                                <Button
                                    id={'registerButton'}
                                    fullWidth
                                    className={importedStyles.submitButton}
                                    variant='contained'
                                    disabled={disabledQuery}
                                    onClick={this.handleRegister}
                                >
                                    Regístrate {this.state.waitingResponse &&
                                <CircularProgress className={importedStyles.progressCircle}/>}
                                </Button>
                            </div>*/}
                            <div className={importedStyles.footerTextContainer}>
                                <p>
                                    <span className={importedStyles.newPasswordLink}
                                          onClick={this.handleLogin}>Ya tengo cuenta</span>
                                </p>
                            </div>
                        </div>
                        <Snack style={{zIndex: '8000'}}/>
                    </DialogContent>
                </Dialog>
                <IncompatibleBrowserModal open={this.props.open && isInAppBrowser()} fullScreen={true}/>

            </React.Fragment>
        );
    }

}

RegisterModalRequest.propTypes = {
    openThankYouModalFunction: PropTypes.func,
};

const mapStateToProps = ({registerModalRequestReducer, ConcursoReducer}) => {
    return ({
        open: registerModalRequestReducer.open,
        nextRoute: registerModalRequestReducer.nextRoute,
        request: ConcursoReducer.request,
    });
};
export default connect(mapStateToProps)(RegisterModalRequest);
