import importedStyles from "./NoVideosMessage.module.sass";
import {isWebpSupported} from "react-image-webp/dist/utils";
import NoVideosImage from "../../images/no-videos-illustration-min.png";
import NoVideosImageWebp from "../../images/no-videos-illustration-min.webp";
import Box from "@material-ui/core/Box";
import React from "react";

const NoVideosMessage = () => {
    return (
        <Box className={importedStyles.noVideosMessageContainer}>
            <img
                src={isWebpSupported() ? NoVideosImage : NoVideosImageWebp}
                className={importedStyles.noVideosImage}
                alt={'no videos'}
            />
            <span className={importedStyles.noVideosMessage}>Todavía no hay archivos</span>
        </Box>
    );
};

export default NoVideosMessage;
