import {SET_CATEGORIES, SET_CATEGORY_ACTIVE} from "../../../constants/action-types";


const initialState = {
    categories: [],
    categoryActive: {name: 'Todas las categorias'}
};

const categoriesReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_CATEGORIES:
            return {...state, categories: action.categories};
        case SET_CATEGORY_ACTIVE:
            return {...state, categoryActive: action.category};
        default:
            return state;
    }
};


export default categoriesReducer;