import React from 'react';
import importedStyles from "./ChipInput.module.sass";
import {connect} from "react-redux";
import Chip from "@material-ui/core/Chip/Chip";
import Grid from "@material-ui/core/Grid/Grid";
import InputBase from "@material-ui/core/InputBase/InputBase";
import Typography from "@material-ui/core/Typography/Typography";
import * as PropTypes from "prop-types";

class ChipInput extends React.Component {

    state = {
        chipString: '',
        chips: this.props.value,
    };

    handleChange(event) {

        let text = event.target.value;
        let lastChar = text.substr(text.length - 1);
        let newChips = this.state.chips;

        if (this.props.chipKeys.indexOf(lastChar) !== -1) {
            let chipText = text.substr(0, text.length - 1);
            if (chipText.length > this.props.maxChipLength){
                return null;
            }

            //Test if the new string is already in chips array, if not push it
            let alreadyExists = newChips.indexOf(chipText) !== -1;

            if (alreadyExists || chipText === '') {
                this.setState({chipString: ''});
            } else {
                //Remove #
                chipText = chipText.replace('#', '');
                newChips.push(chipText);
                this.setState({
                    chipString: '',
                    chips: newChips
                });
            }
        } else {
            if (text.length > this.props.maxChipLength){
                return null;
            } else {
                this.setState({chipString: event.target.value});
            }
        }
        this.props.onChange(newChips);
    }

    handleDelete(chip) {
        let newChips = this.state.chips;
        let index = newChips.indexOf(chip);
        //Delete an array item by it's index
        newChips.splice(index, 1);

        this.setState({chips: newChips});

        //pass new chips array to parent component
        this.props.onChange(newChips);
    }

    renderChips = () => {

        const chips = this.state.chips;
        let chipsItem = [];

        Object.keys(chips).forEach((chip) => {
            chipsItem.push(
                <Chip
                    key={chip}
                    className={importedStyles.chip}
                    label={'#'+chips[chip]}
                    onDelete={() => this.handleDelete(chips[chip])}
                />
            )
        });

        return (
            <div className={importedStyles.chipsContainer}>
                {chipsItem}
            </div>
        );
    };

    addChip = () => {
        if (this.state.chipString !== '') {

            let newChips = this.state.chips;
            let notExistsAlready = newChips.indexOf(this.state.chipString) === -1;

            if (notExistsAlready) {
                //Remove #
                let chipString = this.state.chipString.replace('#', '');
                newChips.push(chipString);
                this.setState({chips: newChips, chipString: ''});
                this.props.onChange(newChips);
            } else {
                this.setState({chipString: ''});
            }

        }
    };


    render() {

        return (

            <Grid container spacing={4} alignItems={"flex-start"} className={importedStyles.gridContainer}>
                <Typography variant="subtitle1" gutterBottom className={importedStyles.label}>
                    {this.props.label}
                </Typography>
                <Grid item xs={12} className={importedStyles.parentGrid}>
                    {this.state.chips.length > 0 && this.renderChips()}
                    <InputBase
                        label={this.props.label}
                        placeholder={this.props.placeholder}
                        className={importedStyles.textField}
                        value={this.state.chipString}
                        onChange={(event) => this.handleChange(event)}
                        onBlur={this.addChip}
                        onKeyPress={(e) => {
                            if (e.which === 13) {
                                this.addChip()
                            }
                        }}
                    />
                </Grid>
                <Typography variant="subtitle2" gutterBottom className={importedStyles.typography}>
                    {this.props.helperText}
                </Typography>

            </Grid>

        );
    }
}

ChipInput.propTypes = {
    helperText: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.array.isRequired,
    chipKeys: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    maxChipLength: PropTypes.string,
};
const mapStateToProps = ({}) => {
    return ({});
};
export default connect(mapStateToProps)((ChipInput));