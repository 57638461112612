import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './MyRequests.module.sass';
import history from "../../../helpers/history";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button/Button";
import Card from "@material-ui/core/es/Card/Card";
import {setRequest} from "../../RequestFiles/Redux/requestFilesActions";
import Snack from "../../../components/Snack/Snack";
import {Badge, CardContent, Divider, Fade, Slide, withStyles} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "react-loading-skeleton";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import MyAlert from "../../../components/MyAlert/MyAlert";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import {Helmet} from "react-helmet/es/Helmet";
import {FileStatus} from "react-filepond";
import {PermMediaRounded, WebRounded} from "@material-ui/icons";
import CardActionArea from "@material-ui/core/CardActionArea";
import PageTitle from "../../../components/PageTitle/PageTitle";

// Flag to check if it's a mobile screen
const isMobile = window.matchMedia("(max-width: 768px)").matches;




class MyRequests extends React.Component {

    state = {
        requests: [],
        waitingResponse: true,
        openDelete: false,
        selectedRequestId: '',
        selectedRequest: {}
    };

    // LIFECYCLES
    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getRequests();
        ReactPixel.trackCustom('MyRequests', {user: this.props.user.uuid});
    };

    // FETCH
    getRequests = () => {

        this.setState({
            waitingResponse: true
        });

        const entityId = this.props.user.uuid;

        fetch(apiBaseUrl + 'requests/listMyRequests', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({entityId: entityId})
        }).then(res => {
            if (!res.ok) throw Error(res.statusText);
            return res.json();
        })
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            requests: response.requests,
                            waitingResponse: false,
                        });
                    } else {
                        throw new Error(response.errorMessage);
                    }
                },
                (error) => {
                    throw new Error(error);
                }
            )
            .catch((fetchError) => {
                console.log('Error: ' + fetchError);
                this.setState({waitingResponse: false, isLoaded: true,});
                this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
            })
    };

    // HANDLERS
    handleClickRequest = async (request) => {
        await this.props.dispatch(setRequest(request));
        history.push('/mis-eventos/' + request.url + '/archivos');
    };
    handleDeleteRequest = () => {

        fetch(apiBaseUrl + 'requests/removeVideoRequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({requestId: this.state.selectedRequest.uuid})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        window.location.reload();
                    } else {
                        this.props.dispatch(showSnack('Error al borrar, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.props.dispatch(showSnack('Error al borrar, vuelva a intentarlo', snackTypes.error));
                }
            )

    };
    handleModalStatus = () => {
        this.setState({openDelete: !this.state.openDelete});
    };

    // RENDERS
    renderRequestActions = requestUuid => () => {
        // return (
        //     <IconButton aria-label="Settings"><SimpleMenu
        //         functionDelete={() => this.handleDeleteRequest(requestUuid)}
        //     /></IconButton>
        // );
    };

    /******* DESKTOP DESIGN ********/
    renderDesktopDesign = () => {
        return (
            <Table rowsPerPageOptions={[10, 50]}>
                {this.renderTableHead()}
                <Slide direction={'up'}
                       timeout={{enter: 500, exit: 0}}
                       duration={{enter: 3, exit: 0}}
                       in={this.state.waitingResponse}
                       mountOnEnter unmountOnExit>
                    {this.renderSkeletons()}
                </Slide>
                <Fade timeout={1500} in={!this.state.waitingResponse} mountOnEnter unmountOnExit>
                    {this.renderRequests()}
                </Fade>
            </Table>
        )
    };
    renderTableHead = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell align={'center'}>Portada</TableCell>
                    <TableCell align="center">Título</TableCell>
                    <TableCell align="center">Fecha limite</TableCell>
                    <TableCell align="center">Archivos subidos</TableCell>
                    <TableCell align="center"></TableCell>
                    {/* <TableCell align="right">Acciones</TableCell> */}
                </TableRow>
            </TableHead>
        )
    };
    renderRequests = () => {
        const requests = this.state.requests;

        //Seteamos el contenido pesimista
        let content = (
            <TableBody>
                <TableRow key={'noRequestsTableRow'}>
                    <TableCell width='100%' colSpan={4}>
                        Aún no has creado ningún evento.
                    </TableCell>
                </TableRow>
            </TableBody>
        );

        //Si existe contenido (eventos), cambiamos el content por la tabla con los retos
        if (requests.length > 0) {
            content = (
                <TableBody>
                    {requests.map((request) => {
                            let timeRemaining = moment(request.limitDate).diff(moment.now(), 'days');
                            let timeString = timeRemaining > 0 ? 'Quedan ' + timeRemaining + ' días' : 'Expirado';
                            return (
                                <TableRow key={request.uuid + 'TableRow'} onClick={() => this.handleClickRequest(request)}
                                          className={importedStyles.requestRow}>
                                    {/*<TableCell padding="checkbox">
                                <Checkbox
                                    //checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': request.title }}
                                />
                            </TableCell>*/}
                                    <TableCell component="th" scope="request" align={"center"}
                                               className={importedStyles.requestThumbnailCellContainer} width='35%'>
                                        <img className={importedStyles.requestThumbnail}
                                             onClick={() => this.handleClickRequest(request)}
                                             src={'https://drive.google.com/uc?export=view&id=' + request.driveId}
                                             alt={'requestPhoto'}/>
                                    </TableCell>
                                    <TableCell align="center" width={'15%'}>{request.title}</TableCell>
                                    <TableCell align="center">
                                        {request.limitDate ? moment(request.limitDate).format('L') : '-'}
                                        <br/>
                                        <i>{request.limitDate ? timeString : 'Sin limite'}</i>
                                    </TableCell>
                                    <TableCell align="center" width={'10%'}>{request.videos_count}</TableCell>
                                    <TableCell align="center" width={'25%'}>
                                        <div className={importedStyles.actionsContainer}>
                                            <Button className={importedStyles.contestButton} fullWidth
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        const win = window.open('/c/' + request.url, "_blank");
                                                        win.focus();
                                                    }}>Ver sitio web</Button>
                                            <Button className={importedStyles.videosButton} fullWidth
                                                    onClick={() => this.handleClickRequest(request)}>Ver archivos</Button>
                                        </div>
                                    </TableCell>
                                    {/*<TableCell align="right">
                                        <div className={importedStyles.actionsContainer}>
                                            <Tooltip title={'Ver Reto'} placement={'top'}>
                                                <IconButton onClick={() => this.handleClickRequest(request)}>
                                                    <EyeIcon/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={'Eliminar Reto'} placement={'bottom'}>
                                                <IconButton>
                                                    <TrashIcon style={{color: 'red'}} onClick={() => {
                                                        this.setState({selectedRequest: request});
                                                        this.handleModalStatus();
                                                    }}/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </TableCell>*/}
                                </TableRow>
                            )
                        }
                    )}
                </TableBody>
            )
        }

        return content;
    };
    renderSkeletons = () => {
        let skeletons = [];

        for (let i = 0; i < 5; i++) {
            skeletons.push(
                <TableRow key={'skeletonRow' + i}>
                    <TableCell align={"center"} width={'35%'}>
                        <Skeleton height={130} width={'100%'}/>
                    </TableCell>
                    <TableCell align={"center"} width={'15%'}>
                        <Skeleton height={10} width={'45%'}/>
                    </TableCell>
                    <TableCell align={"center"} width={'10%'}>
                        <Skeleton height={10} width={'35%'}/>
                    </TableCell>
                    <TableCell align={"center"} width={'25%'}>
                        <Skeleton height={10} width={'35%'}/>
                    </TableCell>
                    <TableCell align={"center"} width={'25%'}>
                        <Skeleton height={10} width={'35%'}/>
                        <Skeleton height={10} width={'35%'}/>
                    </TableCell>
                    {/*<TableCell align={"right"} style={{flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Skeleton circle height={30} width={30}/>
                            <Skeleton circle height={30} width={30}/>
                        </div>
                    </TableCell>*/}
                </TableRow>
            )
        }

        return (
            <TableBody>
                {skeletons}
            </TableBody>
        );
    };

    /******* MOBILE DESIGN ********/
    renderMobileSkeletons = () => {
        return (
            <React.Fragment>
                <Grid className={importedStyles.skeletonBox} key={'skeleton1'} item xs={12} md={4} lg={4} xl={3}>
                    <Skeleton height={'32rem'} width={'100%'} className={importedStyles.skeletonBody}/>
                </Grid>
                <Grid className={importedStyles.skeletonBox} key={'skeleton2'} item xs={12} md={4} lg={4} xl={3}>
                    <Skeleton height={'32rem'} width={'100%'} className={importedStyles.skeletonBody}/>
                </Grid>
                <Grid className={importedStyles.skeletonBox} key={'skeleton3'} item xs={12} md={4} lg={4} xl={3}>
                    <Skeleton height={'32rem'} width={'100%'} className={importedStyles.skeletonBody}/>
                </Grid>
                <Grid className={importedStyles.skeletonBox} key={'skeleton4'} item xs={12} md={4} lg={4} xl={3}>
                    <Skeleton height={'32rem'} width={'100%'} className={importedStyles.skeletonBody}/>
                </Grid>
            </React.Fragment>
        );
    };
    renderMobileRequests = () => {
        const requests = this.state.requests;
        if (requests.length <= 0) {
            return (
                <Grid item xs={12} className={importedStyles.gridItemCenteredContent}>
                    <p style={{margin: 20}}>Aún no tienes eventos, ¡sube el primero!</p>
                </Grid>
            );
        } else {
            let requestCards = [];
            Object.keys(requests).forEach((reqIndex) => {
                let request = requests[reqIndex];
                // let title = request.title.length > 60 ? request.title.slice(0, 60) + '...' : request.title;
                let title = request.admin_title;
                let timeRemaining = moment(request.limitDate).diff(moment.now(), 'days');
                // let remainingTimeText = timeRemaining > 0 ? ' (Quedan ' + timeRemaining + ' días)' : ' (Expirado)';
                let remainingTimeText = '';

                let timeInfo = request.limitDate ? moment(request.limitDate).format('DD/MM/Y') + remainingTimeText : 'Sin límite';

                requestCards.push(
                    <Grid className={importedStyles.gridItemCenteredContent} key={request.uuid} item xs={12} md={4} lg={4} xl={3}>
                        <Card className={importedStyles.eventCard}  onClick={() => this.handleClickRequest(request)}>
                            {/*<Badge className={importedStyles.mobileVideosCount} badgeContent={request.videos_count} overlap={"circle"}>*/}
                            {/*    <Avatar>*/}
                            {/*        <VideoIcon />*/}
                            {/*    </Avatar>*/}
                            {/*</Badge>*/}
                            {/*<Chip className={importedStyles.mobileVideosCount} label={request.videos_count + ' vídeos'}/>*/}
                                <CardMedia
                                    component="img"
                                    alt="event photo"
                                    id={'requestThumbnail' + request.uuid}
                                    image={'https://drive.google.com/uc?export=view&id=' + request.driveId}
                                    //onLoad={this.handleImageLoad}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "/img/png/thumbnail.png"
                                    }}
                                    title={request.admin_title}
                                    className={importedStyles.eventCardImage}
                                />
                                <CardContent className={importedStyles.requestCardContent}>
                                    <ListItem>
                                        <ListItemText classes={{primary: importedStyles.eventCardTitle}}
                                                      primary={title}/>
                                    </ListItem>
                                    <Divider variant={"middle"} style={{background: '#BDBDBD', marginTop: 10, marginBottom: 14}}/>

                                    <ListItem>
                                        <ListItemText secondary={'Límite para participar: ' + timeInfo}/>
                                    </ListItem>
                                    <ListItem style={{marginTop: -15}}>
                                        <ListItemText secondary={'Archivos subidos: ' + request.videos_count}/>
                                    </ListItem>
                                </CardContent>
                                <CardActions className={importedStyles.actionsContainer}>
                                    {/*<Badge classes={{*/}
                                    {/*    root: importedStyles.mobileVideosCountRoot,*/}
                                    {/*    badge: importedStyles.mobileVideosCountBadge*/}
                                    {/*}} showZero={true} overlap={'rectangle'}*/}
                                    {/*       badgeContent={request.videos_count}>*/}
                                    <Button
                                        className={importedStyles.videosButton}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            this.handleClickRequest(request)
                                        }}
                                    >
                                        {/*<PermMediaRounded style={{marginRight: 10}}/>*/}
                                        Archivos
                                    </Button>
                                    {/*</Badge>*/}

                                    <Button
                                        className={importedStyles.contestButton}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            const win = window.open('/c/' + request.url, "_blank");
                                            win.focus();
                                        }}
                                    >
                                        {/*<WebRounded style={{marginRight: 10}}/>*/}
                                        Sitio web
                                    </Button>

                                </CardActions>
                        </Card>
                    </Grid>
                );
            });
            return (
                <React.Fragment>
                    {requestCards}
                </React.Fragment>
            );
        }
    };



    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Mis Eventos</title>
                </Helmet>
                <NavBar/>
                <Snack/>
                <DesktopDrawer/>
                <MyAlert
                    functionOk={this.handleDeleteRequest}
                    title={"¿Eliminar evento?"}
                    okButton={'Eliminar'}
                    openModal={this.state.openDelete}
                    handleModalStatus={this.handleModalStatus}
                    subtitle={"Si aceptas eliminarás el evento '" + this.state.selectedRequest.title + "' para siempre."}
                />

                <div className={importedStyles.myRequests}>
                    <PageTitle title={'Mis Eventos'} subtitle={'Desde aquí puedes acceder a los sitios web de los eventos y a sus archivos.'} />

                    <Grid container className={importedStyles.gridContainer} spacing={isMobile ? 0 : 2}>
                        {/*{isMobile ? this.renderMobileDesign() : this.renderDesktopDesign()}*/}
                        <Fade direction={'top'} unmountOnExit mountOnEnter in={this.state.waitingResponse}>
                            {this.renderMobileSkeletons()}
                        </Fade>

                        <Fade unmountOnExit mountOnEnter in={!this.state.waitingResponse}>
                            {this.renderMobileRequests()}
                        </Fade>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(MyRequests);
