import {Dialog, DialogContent} from "@material-ui/core";
import importedStyles from "./IncompatibleBrowserModal.module.sass";
import ctaOpenInExternalBrowser from "../../images/ctaOpenInExternalBrowser.png";
import React from "react";
import PropTypes from "prop-types";

export class IncompatibleBrowserModal extends React.Component {

    state = {
    };

    componentDidMount() {

    }

    render() {
        return (
            <Dialog open={this.props.open} fullScreen={this.props.fullScreen} className={importedStyles.dialogRoot}>
                <DialogContent className={importedStyles.messageContent}>
                    <img src={ctaOpenInExternalBrowser} className={importedStyles.messageCtaImg} alt={'pulsa en los 3 botones para desplegar opciones'}/>
                    <div className={importedStyles.messageBox}>
                        Para poder participar debes abrir esta página desde un navegador, <br/> pulsando en los 3 puntos de arriba a la derecha.
                    </div>
                </DialogContent>
            </Dialog>
        );
    }


}

IncompatibleBrowserModal.propTypes = {
    open: PropTypes.bool,
    fullScreen: PropTypes.bool,
};

IncompatibleBrowserModal.defaultProps = {
    open: false,
    fullScreen: true,
};