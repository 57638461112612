import React, {useEffect, useReducer, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button/Button";
import SpainFlag from "../../icons/flag_spain.png";
import VlcFlag from "../../icons/flag_vlc.png";
import EnglishFlag from "../../icons/flag_english.png";
import {ArrowDropDownRounded, LanguageRounded} from "@material-ui/icons";
import GoogleTranslate from "../GoogleTranslate/GoogleTranslate";
import $ from "jquery";
import MenuItem from "@material-ui/core/MenuItem";
import {IconButton, Menu} from "@material-ui/core";
import {useUpdateEffect} from 'react-use';


export const GoogleTranslateSelector = (props) => {
    const classes = useStyles(props);

    const [currentLanguage, setCurrentLanguage] = useState('ca');

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        return () => {
            let translateFlag = localStorage.getItem(window.location.hostname+'SelectedTranslationFlag');

            // console.info(translateFlag)
            if (translateFlag && translateFlag !== currentLanguage){
                setCurrentLanguage(translateFlag);
            }
        };
    });

    useUpdateEffect(() => {

            localStorage.setItem(window.location.hostname+'SelectedTranslationFlag', currentLanguage);

    }, [currentLanguage]);



    const triggerEvent = (element, eventName) => {
        const event = new Event(eventName);
        element.dispatchEvent(event);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleChange = (value) => {
        // console.info('change language -> ', value)
        setCurrentLanguage(value); //set state

        setTimeout(()=>{
            $('.goog-te-combo').val(value);
            triggerEvent(document.querySelector('.goog-te-combo'), 'change');
            handleClose();//close menu
        }, 200)


    }

    const renderCurrentSelectedFlag = () => {
        switch (currentLanguage){
            case 'es':
                return <img src={SpainFlag} className={classes.miniFlag} alt={'español'}/>
            case 'ca':
                return <img src={VlcFlag} className={classes.miniFlag} alt={'valenciá'}/>
            case 'en':
                return <img src={EnglishFlag} className={classes.miniFlag} alt={'english'}/>
            default:
                return <img src={SpainFlag} className={classes.miniFlag} alt={'español'}/>
        }
    }


    return (
        <div>
            <IconButton aria-controls="google-translate-language-menu" aria-haspopup="true" onClick={handleClick} className={classes.button}  style={props.styles} >
                {renderCurrentSelectedFlag()}
            </IconButton>
            <Menu
                id="google-translate-language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem selected={currentLanguage === 'ca'} style={{padding: 10}}  class="notranslate" onClick={() => handleChange('ca')}>Valencià</MenuItem>
                <MenuItem selected={currentLanguage === 'es'} style={{padding: 10}}  class="notranslate" onClick={() => handleChange('es')}>Español</MenuItem>
                <MenuItem selected={currentLanguage === 'en'} style={{padding: 10}}  class="notranslate" onClick={() => handleChange('en')}>English</MenuItem>
                {/*<MenuItem style={{padding: 10}}  class="notranslate" onClick={() => handleChange('es')}><img src={SpainFlag} className={classes.flag} alt={'español'}/>Español</MenuItem>*/}
                {/*<MenuItem  class="notranslate" onClick={ () => handleChange('ca')}><img src={VlcFlag} className={classes.flag} alt={'valenciá'}/>Valencià</MenuItem>*/}
                {/*<MenuItem  class="notranslate" onClick={ () => handleChange('en')}><img src={EnglishFlag} className={classes.flag} alt={'english'}/>English</MenuItem>*/}
            </Menu>
            {/*<GoogleTranslate/>*/}
        </div>
    );

}

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1200,
        position: 'fixed',
        top: 0,
        left: 0,
        padding: '0.5rem'
    },
    flag: {
        width: 36,
        aspectRatio: '14/9',
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            width: 28
        }
    },
    miniFlag: {
        width: 24,
        aspectRatio: '14/9',
    },
    button: {
        background: '#1d343c',
        height: '3.35rem',
        border: '2px solid #ffff',
        width: '3.35rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            height: '3rem',
            width: '3rem',
        },
        '&:hover': {
            background: '#1d343c',
        },
    },
    dropdownIcon: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    languageIcon: {
        marginRight: 5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    }
}));

GoogleTranslateSelector.propTypes = {
    // image: PropTypes.string.isRequired,
    styles: PropTypes.object,
    // borderRadius: PropTypes.number,
    // position: PropTypes.string
};

GoogleTranslateSelector.defaultProps = {
    styles: {},
}
