// eslint-disable-next-line no-unused-vars
import React, {Component, useEffect} from 'react';
import './App.css';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Provider from "react-redux/es/components/Provider";
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from "./redux/store";
import history from "./helpers/history";
import Categories from "./modules/Categories/Categories";
import Profile from "./modules/Profile/Profile";
import UploadVideo from "./modules/User/UploadVideo/UploadVideo";
import Faqs from "./modules/FAQs/Faqs";
import ContactUs from "./modules/ContactUs/ContactUs";
import MyVideos from "./modules/User/MyVideos/MyVideos";
import Requests from "./modules/User/Requests/Requests";
import Downloads from "./modules/Entity/Downloads/Downloads";
import Favourites from "./modules/Entity/Favourites/Favourites";
import MyRequests from "./modules/Entity/MyRequests/MyRequests";
import NewRequest from "./modules/Entity/NewRequest/NewRequest";
import DetailVideo from "./modules/DetailVideo/DetailVideo";
import ListVideos from "./modules/ListVideos/ListVideos";
import IdeasView from "./modules/User/IdeasView/IdeasView";
import MailVerification from "./modules/MailVerification/MailVerification";
import ChangePassword from "./modules/ChangePassword/ChangePassword";
import RequestPasswordChange from "./modules/RequestPasswordChange/RequestPasswordChange";
import Terms from "./modules/Terms/Terms";
import RegisterModal from "./components/RegisterModal/RegisterModal";
import Snack from "./components/Snack/Snack";
import FeaturedVideos from "./modules/FeaturedVideos/FeaturedVideos";
import FirstLoginOnBoard from "./components/FirstLoginOnBoard/FirstLoginOnBoard";
import OnBoardingEntities from "./components/OnBoardingEntities/OnBoardingEntities";
import Concurso from "./modules/Concurso/Concurso";
import RequestTerms from "./modules/Concurso/RequestTerms/RequestTerms";
import {CookiesBanner} from "./components/CookiesBanner/CookiesBanner";
import {initializeAnalytics} from "./helpers/analytics";
import EntityLogin from "./modules/Entity/Login/EntityLogin";
import ConfidenceWall from "./modules/Entity/ConfidenceWall/ConfidenceWall";
import VideowallIframe from "./modules/Videowall/VideowallIframe/VideowallIframe";
import RequestFiles from "./modules/RequestFiles/RequestFiles";
import * as FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import {getTranslatedText} from "./helpers/language";
import Schedule from "./modules/Schedule/Schedule";
// import Schedule from "./modules/Schedule/Schedule";
// import TestIframe from "./modules/Videowall/Test/TestIframe";




function App() {

    initializeAnalytics();

    useEffect(  () => {
        var originalSetItem = localStorage.setItem;
        localStorage.setItem = function(){
            const customEvent = new Event("localStorageChange", {"bubbles":true, "cancelable":false});
            document.dispatchEvent(customEvent);
            originalSetItem.apply(this, arguments);
        }

        async function InitializeFingerprintSdk() {
            // Initialize an agent at application startup.
            window.fingerprint = await FingerprintJS.load({apiKey: 'YYhBl1uEHYzJ363o5o5J', region: 'eu', endpoint: 'https://fp.grabaloapp.com'});
        }
        InitializeFingerprintSdk();

        let userLanguage =  localStorage.getItem('grabalo-user-language');
        if(!userLanguage){
            localStorage.setItem('grabalo-user-language', 'esp');
        }

    }, []);

    function PrivateRoute({component: Component, ...rest}) {
        return (
            <Route {...rest} render={(routeProps) => (
                //localStorage.getItem('loggedOnGrabalo') ? <Component {...routeProps} /> : <Redirect to='/'/>
                <Component {...routeProps} />
            )}/>
        );
    }

    function ProtectedRoute({component: Component, ...rest}) {
        return (
            <Route {...rest} render={(routeProps) => (
                localStorage.getItem('loggedOnGrabalo') ? <Component {...routeProps} /> :
                    <RegisterModal open={true}/>
            )}/>
        );
    }

    function RedirectOldRegister({component: Component, ...rest}) {
        return (
            <Route {...rest} render={(routeProps) => (
                <Redirect to={'/?register=true'}/>
            )}/>
        );
    }


    let isIE = /*@cc_on!@*/false || !!document.documentMode;

    if (isIE) {
        return (<p>No ofrecemos soporte para Internet Explorer</p>);
    } else {
        return (
            <Provider store={store}>
                <PersistGate loading={<CircularProgress size={50}/>} persistor={persistor}>
                    <Router history={history}>
                        <Switch >
                            <React.Fragment>
                                <Route exact path="/" component={Categories}/>
                                {/*<Route exact path="/register" component={Register}>
                                        <Redirect to="/?register=true" />
                                    </Route>*/}
                                <RedirectOldRegister exact path="/register"/>
                                <PrivateRoute exact path="/categories" component={Categories}/>
                                <ProtectedRoute path='/profile' component={Profile}/>
                                <ProtectedRoute exact path="/uploadVideo" component={UploadVideo}/>
                                <PrivateRoute exact path="/FAQS" component={Faqs}/>
                                <ProtectedRoute exact path="/ideas" component={IdeasView}/>
                                <PrivateRoute exact path="/contact" component={ContactUs}/>
                                <ProtectedRoute exact path="/myVideos/:videoId?" component={MyVideos}/>
                                <ProtectedRoute exact path="/mis-archivos/:videoId?" component={MyVideos}/>
                                <PrivateRoute path="/detailVideo/:videoId" component={DetailVideo}/>
                                <PrivateRoute path="/video/:videoId" component={DetailVideo}/>
                                <PrivateRoute exact path="/requests/:videoId?" component={Requests}/>
                                <PrivateRoute exact path="/destacados/:videoId?" component={FeaturedVideos}/>

                                <PrivateRoute path="/detailRequest/:requestId" component={RequestFiles}/>
                                <PrivateRoute path="/mis-concursos/videos/:requestId/:videoId?" component={RequestFiles}/>
                                <PrivateRoute path="/mis-eventos/:requestId/archivos/:videoId?" component={RequestFiles}/>

                                {/* ----------- CONCURSO ----------- */}
                                <PrivateRoute path="/Concurso/:requestId/:videoId?" component={Concurso}/>
                                <PrivateRoute path="/concurso/:requestId/:videoId?" component={Concurso}/>
                                <PrivateRoute path="/c/:requestId/:videoId?" component={Concurso}/>

                                {/* ----------- AGENDA CULTURAL ----------- */}
                                <Route path="/agenda/:scheduleIdentifier/:eventIdentifier?" component={Schedule}/>



                                <PrivateRoute path="/terminos-condiciones/:requestUrlSlug/" component={RequestTerms}/>
                                <PrivateRoute exact path="/downloads/:videoId?" component={Downloads}/>
                                <PrivateRoute exact path="/mis-descargas/:videoId?" component={Downloads}/>
                                <PrivateRoute exact path="/favourites/:videoId?" component={Favourites}/>
                                <PrivateRoute exact path="/favoritos/:videoId?" component={Favourites}/>
                                <PrivateRoute exact path="/confidenceWall/:videoId?" component={ConfidenceWall}/>
                                <PrivateRoute exact path="/muro-de-la-confianza/:videoId?" component={ConfidenceWall}/>
                                <PrivateRoute exact path="/listVideos/:categoryId?/:videoId?" component={ListVideos}/>
                                <PrivateRoute exact path="/myRequests" component={MyRequests}/>
                                <PrivateRoute exact path="/mis-concursos" component={MyRequests}/>
                                <PrivateRoute exact path="/mis-eventos" component={MyRequests}/>
                                <PrivateRoute exact path="/newRequest" component={NewRequest}/>
                                <Route exact path="/mailConfirmation" component={MailVerification}/>
                                <Route exact path="/recuperar-password" component={RequestPasswordChange}/>
                                <Route exact path="/confirmar-cambio-password" component={ChangePassword}/>
                                <Route exact path="/requestPasswordChange" component={RequestPasswordChange}/>
                                <Route exact path="/changePassword" component={ChangePassword}/>
                                <Route exact path="/terms" component={Terms}/>
                                <Route exact path="/login" component={EntityLogin}/>

                                <Route exact path="/videowall-embed/:videowallUrlSlug/:videoId?" component={VideowallIframe}/>
                                <Route exact path="/confidenceWall-embed/:videowallUrlSlug/:videoId?" component={VideowallIframe}/>
                                {/*<Route exact path="/testIframe/:videowallUrlSlug/:videoId?" component={TestIframe}/>*/}

                                {/*COMPONENTS AND MODALS TO BE SHOWED AROUND THE ENTIRE APP*/}
                                {/*<EntitySnack/>*/}
                                <RegisterModal/>
                                <FirstLoginOnBoard/>
                                <OnBoardingEntities/>
                                <Snack/>
                                <CookiesBanner/>


                            </React.Fragment>
                        </Switch>

                    </Router>
                </PersistGate>
            </Provider>
        );
    }


}

export default App;
