import {combineReducers} from "redux";
import authReducer from '../../modules/Auth/authReducer';
import snackReducer from "../../components/Snack/SnackReducer";
import categoriesReducer from "../../modules/Categories/Redux/categoriesReducer";
import detailVideoReducer from "../../modules/DetailVideo/Redux/detailVideoReducer";
import requestFilesReducer from "../../modules/RequestFiles/Redux/requestFilesReducer";
import searchReducer from "../../modules/ListVideos/redux/searchReducer";
import SuggestedLoginModalReducer from "../../components/SuggestedLoginModal/Redux/SuggestedLoginModalReducer";
import RegisterModalReducer from "../../components/RegisterModal/Redux/RegisterModalReducer";
import FirstLoginOnBoardReducer from "../../components/FirstLoginOnBoard/Redux/FirstLoginOnBoardReducer";
import OnBoardingEntitiesReducer from "../../components/OnBoardingEntities/Redux/OnBoardingEntitiesReducer";
import ConcursoReducer from "../../modules/Concurso/Redux/ConcursoReducer";
import modalUploadVideoReducer from "../../modules/Concurso/ModalUploadVideo/Redux/ModalUploadVideoReducer";
import registerModalRequestReducer
    from "../../modules/Concurso/Components/RegisterModalRequest/Redux/RegisterModalRequestReducer";
import SuggestedLoginModalRequestReducer
    from "../../modules/Concurso/Components/SuggestedLoginModalRequest/Redux/SuggestedLoginModalRequestReducer";
import detailVideoModalReducer from "../../modules/DetailVideoModal/Redux/detailVideoModalReducer";
import ScheduleReducer from "../../modules/Schedule/Redux/ScheduleReducer";
import EventDetailReducer from "../../modules/Schedule/EventDetail/Redux/EventDetailReducer";

const rootReducer = combineReducers({
    authReducer,
    snackReducer,
    categoriesReducer,
    detailVideoReducer,
    requestFilesReducer,
    detailVideoModalReducer,
    EventDetailReducer,
    searchReducer,
    SuggestedLoginModalReducer,
    SuggestedLoginModalRequestReducer,
    modalUploadVideoReducer,
    RegisterModalReducer,
    FirstLoginOnBoardReducer,
    registerModalRequestReducer,
    OnBoardingEntitiesReducer,
    ConcursoReducer,
    ScheduleReducer
});

export default rootReducer;
