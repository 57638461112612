import {
    CLOSE_SUGGESTED_LOGIN_MODAL_REQUEST,
    OPEN_SUGGESTED_LOGIN_MODAL_REQUEST,
} from "../../../../../constants/action-types";


const initialState = {
    open: false,
    nextRoute: '.',
};

const suggestedLoginModalRequestReducer = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_SUGGESTED_LOGIN_MODAL_REQUEST:
            return {...state, open: true, nextRoute: action.nextRoute};
        case CLOSE_SUGGESTED_LOGIN_MODAL_REQUEST:
            return {...state, open: false, nextRoute: '.'};
        default:
            return state;
    }
};


export default suggestedLoginModalRequestReducer;