import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CardContent, CardHeader, Fade} from "@material-ui/core";
import {generatePath} from "react-router-dom";
import history from "../../../../helpers/history";
import DetailVideoModalIframe from "../DetailVideoModalIframe/DetailVideoModalIframe";
import {ReactComponent as PlayIcon} from "../../../../icons/play_circle_carlos.svg";
import {sendStat} from "../../../../helpers/stats/statsAPI";
import {InView} from "react-intersection-observer";
import LinearProgress from "@material-ui/core/LinearProgress";
import {ImageRounded} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
        card: {
            borderRadius: (props) => (props.borderRadius === null || props.borderRadius === undefined) ? 20 : props.borderRadius,
            // border: '1px solid lightgrey',
            "&:hover": {
                cursor: 'pointer',
            }
        },
        cardMediaWrapper: {
            position: 'relative',
        },
        cardMedia: {
            // height: '16rem',
            height: '29rem',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: '26rem',
            },
        },
        playIcon: {
            width: '5em',
            height: '5em',
        },
        mediaTypeImageIcon: {
            color: '#fff',
            width: '2rem',
            height: '2rem',
            position: 'absolute',
            bottom: 0,
            right: 0,
        },
        titleBar: {
            color: '#fff',
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        videoTitle: {
            color: '#fff',
        },
        icon: {
            color: 'white',
            fontSize: 32,
        },
        avatar: {
            height: '4rem',
            width: '4rem'
        },
        quoteIcon: {
            // maxWidth: '3.7rem',
            // maxHeight: '3.7rem',
            maxWidth: '1rem',
            maxHeight: '1rem',
            marginRight: '1.25rem',
            marginLeft: '0.25rem'
        },
        quoteIconClose: {
            maxWidth: '1rem',
            maxHeight: '1rem',
        },
        cardHeader: {
            //minHeight: '5rem',
        },
        cardTitle: {
            height: '1.55rem',
            overflow: 'hidden',
            fontSize: '1.2rem',
            fontWeight: '800'
        },
        cardSubtitle: {
            [theme.breakpoints.up('sm')]: {
                maxHeight: '1.35rem',
            },
            fontSize: '1rem',
            fontWeight: '400',
            color: '#000000',
        },
        cardText: {
            minHeight: '4rem',
            //padding: '24px 16px',
            fontFamily: 'Montserrat !important',
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '16px !important'
        },
        linearProgress: {
            position: 'absolute',
            top: 0,
            width: '100%',
            backgroundColor: '#BDBDBD',
        },
        linearProgressBar: {
            // backgroundColor: '#10212e'
            backgroundColor: '#00a3da'
        }
    }
));

export const IframeVideoCard = (props) => {

    let isMobile = window.matchMedia("(max-width: 768px)").matches;
    const classes = useStyles(props);
    const [openDetailVideoModal, setOpenDetailVideoModal] = useState(false);
    const [loadingAnimatedThumbnail, setLoadingAnimatedThumbnail] = useState(false);
    const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        setThumbnail(props.video.coverUrl);
    }, [])//did mount

    useEffect(() => {
        if (props.match.params.videoId === props.video.urlSlug) {
            setOpenDetailVideoModal(true);
        } else if (openDetailVideoModal) {
            setTimeout(() => {
                setOpenDetailVideoModal(false);
            }, isMobile ? 200 : 700); //set a 100-700ms to avoid flicker visual effect when this modal closes and next appears
        }
    }, [props.match]);

    let handlePrevVideo = () => {
        sendStat('videos_nav', null, props.wallUuid, props.video.uuid);


        let videowallUrlSlug = props.match.params.videowallUrlSlug;
        let params = {videowallUrlSlug: videowallUrlSlug, videoId: props.prevVideo.urlSlug};
        let newPath = generatePath(props.match.path, params);
        history.push(newPath);
    };

    let handleNextVideo = () => {
        sendStat('videos_nav', null, props.wallUuid, props.video.uuid);

        let videowallUrlSlug = props.match.params.videowallUrlSlug;
        let params = {videowallUrlSlug: videowallUrlSlug, videoId: props.nextVideo.urlSlug};
        let newPath = generatePath(props.match.path, params);
        history.push(newPath);
    };

    let onModalClose = (e) => {
        e.preventDefault();
        console.info('exit modal')
        setOpenDetailVideoModal(false);
        let videowallUrlSlug = props.match.params.videowallUrlSlug;
        let params = {videowallUrlSlug: videowallUrlSlug};
        let newPath = generatePath(props.match.path, params);
        sendStat('video_modal_closed', null, props.wallUuid, props.video.uuid);


        history.push(newPath);
    };

    let onCardClick = (video) => {
        sendStat('video_modal_opened', null, props.wallUuid, props.video.uuid);

        //setOpenDetailVideoModal(true);
        let videowallUrlSlug = props.match.params.videowallUrlSlug;
        let params = {videowallUrlSlug: videowallUrlSlug, videoId: video.urlSlug};
        let newPath = generatePath(props.match.path, params);
        history.push(newPath);
    };

    let setGifCover = () => {
        if (props.video.animated_thumbnail_url) {
            setLoadingAnimatedThumbnail(true);
            let gif = new Image();
            gif.src = props.video.animated_thumbnail_url;
            gif.addEventListener('load', () => {
                // the gif is already downloaded and loaded, now lets set it as default video cover
                setThumbnail(props.video.animated_thumbnail_url);
                setLoadingAnimatedThumbnail(false);
            });
        }
    }

    return (
        <React.Fragment>
            <InView
                delay={0}
                onChange={(inView, entry) => {
                    if (inView) {
                        setGifCover();
                    } else {
                        setLoadingAnimatedThumbnail(false);
                        setThumbnail(props.video.coverUrl);
                    }
                }}
                skip={!isMobile}
                threshold={1}
                style={{height: '100%', width: '100'}} //fix card not getting 100% height because of Inview wrapper div
            >
                <Card className={classes.card} elevation={0} onClick={() => onCardClick(props.video)}
                >
                    {/*<CardHeader*/}
                    {/*    // avatar={*/}
                    {/*    //     <Avatar src={PlayCircleOutlined} className={classes.avatar}/>*/}
                    {/*    // }*/}
                    {/*    title={props.video.pivot.title ? props.video.pivot.title : props.video.author}*/}
                    {/*    subheader={props.video.pivot.subtitle ? props.video.pivot.subtitle : 'Cliente destacado'}*/}
                    {/*    classes={{root: classes.cardHeader, title: classes.cardTitle, subheader: classes.cardSubtitle}}*/}
                    {/*/>*/}
                    <CardMedia
                        className={classes.cardMedia}
                        image={thumbnail}
                        onMouseEnter={setGifCover}
                        onMouseLeave={() => {
                            setLoadingAnimatedThumbnail(false);
                            setThumbnail(props.video.coverUrl);
                        }}
                    >
                        <Fade in={loadingAnimatedThumbnail} mountOnEnter unmountOnExit timeout={200}>
                            <LinearProgress size={120} classes={{
                                root: classes.linearProgress,
                                bar: classes.linearProgressBar
                            }}/>
                        </Fade>


                        {props.video.media_type === 'video' && <PlayIcon alt={'play-video'} className={classes.playIcon}/>}
                        {/*{props.video.media_type === 'image' && <ImageRounded className={classes.mediaTypeImageIcon}/>}*/}
                    </CardMedia>

                    {/*<CardContent className={classes.cardText}>*/}
                    {/*    “{props.video.pivot.quote}”*/}
                    {/*</CardContent>*/}
                </Card>

            </InView>

            <DetailVideoModalIframe
                open={openDetailVideoModal}
                onClose={onModalClose}
                video={props.video}
                prevVideo={props.prevVideo}
                nextVideo={props.nextVideo}
                handleNextVideo={handleNextVideo}
                handlePrevVideo={handlePrevVideo}
                ctaLink={props.ctaLink}
                ctaMessage={props.ctaMessage}
                wallUuid={props.wallUuid}
                borderRadius={props.borderRadius}
                ctaBorderRadius={props.ctaBorderRadius}
            />
        </React.Fragment>
    )
}
