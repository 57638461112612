import importedStyles from "./WhastappButton.module.sass";
import {ReactComponent as WhatsappIcon} from "../../icons/whatsappIcon.svg";

import Box from "@material-ui/core/Box";
import React from "react";
import {Fade, Hidden} from "@material-ui/core";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";

const WhatsappButton = (props) => {
    return (
        <Fade in={props.visible}>
            <a className={importedStyles.root} target={'_blank'} href={'https://wa.me/+34622393895'}>

                <WhatsappIcon className={importedStyles.icon} />
                <Hidden smDown>
                    <span className={importedStyles.title} >¿Necesitas <br/> ayuda?</span>

                </Hidden>
            </a>
        </Fade>
    );
};

WhatsappButton.propTypes = {
    visible: PropTypes.bool,
};

WhatsappButton.defaultProps = {
    visible: true,
}

export default WhatsappButton;
