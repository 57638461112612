import React, {useEffect} from "react";
import importedStyles from './SimpleStatsCard.module.sass';
import * as PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";


export const SimpleStatsCard = (props) => {

    // Did mount equivalent
    useEffect(() => {

    }, []);

    let isMobile = window.matchMedia("(max-width: 768px)").matches;
    //const classes = useStyles();


    return (
        <Tooltip
            disableHoverListener={!(props.tooltip && props.tooltip.length > 0)}
            title={props.tooltip}
            placement={'top'}
            arrow
        >
            <div className={importedStyles.root}>
                <div id={'contentWrapper'} className={importedStyles.contentWrapper}>
                    {props.icon && <div id={'iconContainer'} className={importedStyles.iconContainer}>
                        {props.icon}
                    </div>
                    }
                    {props.text &&
                    <div id={'textContainer'} className={importedStyles.textContainer}>
                        {props.text}
                    </div>
                    }
                    <div id={'valueContainer'} className={importedStyles.valueContainer}>
                        {props.value}
                    </div>
                    {props.chipText &&
                    <Tooltip
                        disableHoverListener={!(props.percentTooltip && props.percentTooltip.length > 0)}
                        title={props.percentTooltip}
                        arrow
                    >
                        <div id={'chipTextContainer'} className={importedStyles.chipTextContainer}>
                            {props.chipText}
                        </div>
                    </Tooltip>
                    }
                </div>
            </div>
        </Tooltip>
    )
}

SimpleStatsCard.propTypes = {
    icon: PropTypes.element,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    text: PropTypes.string,
    chipText: PropTypes.string,
    tooltip: PropTypes.string,
    percentTooltip: PropTypes.string,
};

SimpleStatsCard.defaultProps = {
    value: "--"
};
