import React from "react";
import ReactDOM from 'react-dom';
import connect from "react-redux/es/connect/connect";
import history from "../../helpers/history";
import importedStyles from './DetailVideoModal.module.sass';
import {
    ChevronLeftSharp,
    ChevronRightSharp,
    CloseSharp,
    EuroSymbol,
    FullscreenExitRounded,
    FullscreenRounded
} from '@material-ui/icons';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import DownloadIcon2 from '@material-ui/icons/SaveAltRounded';
import Vimeo from '@u-wave/react-vimeo';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import Skeleton from "react-loading-skeleton";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteForever from "@material-ui/icons/esm/DeleteForever";
import {deleteVideo} from "../../helpers/fetch/video";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snack from "../../components/Snack/Snack";
import {Badge, Container, Divider, Fade, Hidden, Tooltip, withStyles} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import Helmet from "react-helmet/es/Helmet";
import ShareModal from "../../components/ShareModal/ShareModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setCategoryActive} from "../Categories/Redux/categoriesActions";
import Avatar from "@material-ui/core/Avatar";
import {ReactComponent as WallIcon} from "../../icons/wallIcon.svg";
import {ReactComponent as WallOutlinedIcon} from "../../icons/wallOutlinedIcon.svg";
import PropTypes from "prop-types";
import FavIconRelleno from "@material-ui/icons/Favorite";
// import FavIcon from "@material-ui/icons/FavoriteBorderOutlined";
import {ReactComponent as FavIcon} from "../../icons/heart_v2.svg";
import {generatePath} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Grid from "@material-ui/core/Grid";
import {ReactComponent as NavigationArrow} from "../../icons/navigationArrow.svg";
import {primaryColor, redColor} from "../../styles/palette";
import moment from "moment";
import {toast} from "react-hot-toast";
import {ReactComponent as ShareArrow} from "../../icons/share_arrow_v2.svg";
import {sendAdInteraction} from "../../helpers/stats/statsAPI";
import AdWithSensorWrapper from "../../components/AdWithSensorWrapper/AdWithSensorWrapper";
import Interweave, {ALLOWED_TAG_LIST} from "interweave";
import {LanguageSelector} from "../../components/LanguageSelector/LanguageSelector";
import {getTranslatedText} from "../../helpers/language";
import {isMobile} from "react-device-detect";
import WhatsappButton from "../../components/WhastappButton/WhatsappButton";
import {setCurrentAdIndex} from "./Redux/detailVideoModalActions";
import detailVideoModalReducer from "./Redux/detailVideoModalReducer";
import Box from "@material-ui/core/Box";
import grabaloIcon from "../../images/grabalo-icon.png";
import Lottie from 'react-lottie-player';
import grabaloWave from "../../animations/grabalo-audio-wave.json";

//
const isMobileWidth = window.matchMedia("(max-width: 768px)").matches;

const CustomBadge = withStyles((theme) => ({
    badge: {
        // border: `2px solid #fff`,
        // padding: '0 4px',
        backgroundColor: primaryColor,
        color: '#fff'
    },
}))(Badge);

class DetailVideoModal extends React.Component {

    state = {
        bought: false,
        topAdBanner: null,
        firstOpen: true,
        openDelete: false,
        shareModal: false,
        openMobileAdPopup: false,
        videoLoading: true,
        waitingResponseFavorite: false,
        waitingResponsePurchase: true,
        purchaseConfirmationModal: false,
        freeVideoDownloadModal: false,
        freeDownloadOrPurchaseModal: false,
        openDownloadsModal: false,
        url360p: null,
        url480p: null,
        url720p: null,
        url1080p: null,
        url2k: null,
        url4k: null,
        fallbackUrl: null,
        freeDownloadUrl: '',
        fixUpdateFlag: false,
        isEntered: false,
        imageFullscreen: false,
        playingAudio: false,
    };

    forceRenderVimeo = () => {
        let removed = ReactDOM.unmountComponentAtNode(window.document.getElementById('vimeoPlayer'));

        /* The setTimeout with only 1ms is a provisional fix to render the new node after window dimensions gets established */
        // setTimeout(() => {
        //     ReactDOM.render(
        //         this.renderVimeoPlayer(),
        //         document.getElementById('vimeoPlayer')
        //     );
        // }, 1);
        if (this.state.firstOpen) {
            this.setState({firstOpen: false});
        }
    };

    retrieveFreshVideoExtraInfo = () => {
        if (this.props.logged) {
            // this.checkFavorite(this.props.video.uuid);
            if (parseInt(this.props.video.price) <= 0) {
                this.setState({bought: true, waitingResponsePurchase: false});
            } else {
                //this.checkBought(this.props.video.uuid);
            }
        } else {
            //Just in case none of previous checkers dispatch an update, set an arbitrary flag to get ComponentDidUpdate called (where magic begins)
            this.setState({fixUpdateFlag: true});
        }
    };


    componentDidMount() {


        //this.retrieveFreshVideoExtraInfo();

        // //Check for mobile pop-up ad cookies, if not present, open pop-up and set cookie
        // this.checkMobileAdCookie();

        if (!this.props.request || !this.props.request.ads || this.props.request.ads.length <= 0) {
            return false;
        }

        let ads = this.props.request.ads.filter(item => item.type === 'intersection-banner');

        if (!ads && ads.length <= 0) return false;

        let randomIndex = Math.floor(Math.random() * ads.length);

        let ad = ads[randomIndex];

        this.setState({topAdBanner: ad});


        // if (isMobileWidth){
        //     window.screen.orientation.lock("portrait");
        // }


    };

    handleAdMobilePopup = () => {

        if (!this.props.request || !this.props.request.ads || this.props.request.ads.length <= 0) {
            return false;
        }

        let ads = this.props.request.ads.filter(item => item.type === 'banner_vertical');

        if (ads.length <= 0) {
            this.setState({openMobileAdPopup: false});
            return false;
        }


        if (!this.state.openMobileAdPopup) {
            this.setState({openMobileAdPopup: true});
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // //Si venimos de la landing del concurso mostraremos el popup con anuncio, si es navegación entre archivos NO
        // if(prevProps.match.params.videoId === undefined && this.props.match.params.videoId !== undefined){
        //     console.info('prev: ', prevProps.match.params.videoId)
        //     console.info('current: ', this.props.match.params.videoId)
        //
        //     this.handleAdMobilePopup();
        // }

    }


    componentWillUnmount() {

    }


    //Requests

    //Pixel event
    setVideoPlayPixelEvent = () => {
        let videoId = this.props.match.params.videoId;
        ReactPixel.trackCustom('VideoPlayed', {videoId: videoId});
        ReactGA.ga('send', 'event', 'video', 'VideoPlayed');
    };

    //Checkers

    // Handles
    handlePurchase = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        let amount = 5;

        fetch(apiBaseUrl + 'purchases/createNewPurchase', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, amount: amount}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({bought: true});
                this.retrieveVideoQualityDownloadUrls();

                //close current modals and open download links modal
                this.handleFreeDownloadOrPurchaseModal();
                this.handleDownloadsModal();
                //
                this.props.dispatch(showSnack('Compra realizada con éxito, ya puedes descargar el contenido', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.props.dispatch(showSnack('Error al procesar la compra, por favor vuelva a intentarlo más tarde', snackTypes.error));
        });
    };

    handlePurchaseConfirmationModal = () => {
        this.setState({
            purchaseConfirmationModal: !this.state.purchaseConfirmationModal
        });
    };

    handleFreeDownloadOrPurchaseModal = () => {
        this.setState({freeDownloadOrPurchaseModal: !this.state.freeDownloadOrPurchaseModal});
    };

    handleFavorite = () => {
        this.props.setFavVideo();
    };

    handleLike = (e) => {
        this.props.setLikeVideo(e);
    };

    handleWall = () => {
        this.props.setIsOnWall();
    };

    handleModalStatus = () => {
        this.setState({openDelete: !this.state.openDelete});
    };

    handleDeleteVideo = () => {
        this.setState({openDelete: false});
        deleteVideo(this.props.video.uuid)
            .then(res => res.json())
            .then((response) => {
                    if (!response.error) {
                        history.goBack();
                    } else {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error)
                }
            ).catch(error => {
            console.log('Error: ' + error);
            this.props.dispatch(showSnack('Error al borrar, vuelva a intentarlo', snackTypes.error));
        });

    };

    handleTagClick = tag => {
        ReactPixel.trackCustom('clickEnTag', {tag: tag});
    };

    handleDownloadsModal = () => {
        this.setState({openDownloadsModal: !this.state.openDownloadsModal});
    };

    handleShare = (e) => {
        this.props.handleShare(e);
    };

    handleShareModalClose = () => {
        this.setState({shareModal: false});
    };

    //Renders
    renderPrice = () => {
        return (
            <Chip className={importedStyles.priceChip}
                  deleteIcon={<EuroSymbol/>}
                  onDelete={() => {
                  }}
                  variant="outlined"
                  size={window.matchMedia("(max-width: 768px)").matches && 'small'}
                  classes={{
                      deleteIcon: importedStyles.euroIcon
                  }}
                  label={this.props.video.price}
            />
        )
    };
    renderPriceDesktop = () => {
        return (
            <Chip className={importedStyles.priceChip}
                  deleteIcon={<EuroSymbol/>}
                  onDelete={() => {
                  }}
                  variant="outlined"
                  classes={{
                      deleteIcon: importedStyles.euroIcon
                  }}
                  label={this.props.video.price}
            />
        )
    };

    renderVimeoPlayerDesktop = () => {

        return (
            <div className={importedStyles.vimeoDesktopWrapper}>
                <Fade in={this.state.videoLoading} timeout={300} unmountOnExit mountOnEnter>
                    <CircularProgress size={70} className={importedStyles.vimeoSpinner}/>
                </Fade>
                <Fade in={true} timeout={600} mountOnEnter unmountOnExit>
                    <Vimeo
                        id={this.props.video.vimeoId}
                        video={this.props.video.vimeoId}
                        className={importedStyles.iframeVimeoDesktop}
                        showByline={false}
                        showTitle={false}
                        controls={true}
                        pip={true}
                        onLoaded={() => this.setState({videoLoading: false})}
                        paused
                        onPlay={this.setVideoPlayPixelEvent}
                        responsive
                    />
                </Fade>
            </div>
        )
    };
    renderVimeoPlayer = () => {

        let dynamicStyles = this.state.videoLoading ? [importedStyles.iframeStyle, importedStyles.vimeoHidden] : importedStyles.iframeStyle;
        return (
            <React.Fragment>
                <div className={importedStyles.vimeoWrapper}>
                    <Fade in={this.state.videoLoading} timeout={300}>
                        <CircularProgress size={70} className={importedStyles.vimeoSpinner}/>
                    </Fade>
                    <Fade in={true} timeout={600} unmountOnExit>
                        <Vimeo
                            id={this.props.video.vimeoId}
                            video={this.props.video.vimeoId}
                            className={importedStyles.iframeStyle}
                            showByline={false}
                            showTitle={false}
                            responsive
                            controls
                            onLoaded={() => this.setState({videoLoading: false})}
                            paused
                            onPlay={this.setVideoPlayPixelEvent}
                        />
                    </Fade>
                </div>
            </React.Fragment>
        )
    };
    renderMainImage = () => {
        let image = this.props.video;
        return (
            <React.Fragment>
                <Fade in={true} timeout={600} unmountOnExit>
                    <div className={importedStyles.mainImageWrapper}>
                        <img
                            alt={image.title}
                            src={image.url}
                            className={importedStyles.mainImage}
                            onLoad={() => this.setState({videoLoading: false})}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/img/png/thumbnail.png"
                            }}
                        />
                        {/*{this.renderFullScreenToggleButton()}*/}

                        {/*<LazyLoadImage*/}
                        {/*    alt={image.title}*/}
                        {/*    effect={"blur"}*/}
                        {/*    src={image.url}*/}
                        {/*    className={importedStyles.mainImage}*/}
                        {/*    wrapperClassName={importedStyles.mainImageLazyWrapper}*/}
                        {/*    placeholderSrc={'/img/png/thumbnail.png'} //default image*/}
                        {/*    afterLoad={() => this.setState({videoLoading: false})}*/}
                        {/*/>*/}
                    </div>

                </Fade>
            </React.Fragment>
        )
    };

    renderImageFullscreenViewerModal = () => {
        let image = this.props.video;

        return (
            <Dialog open={this.state.imageFullscreen} fullWidth fullScreen
                    style={{display: 'flex', justifyContent: 'center', background: "transparent"}}>
                <div style={{position: 'relative'}}>
                    <IconButton style={{background: '#fff'}}>
                        <CloseIcon style={{position: 'absolute', right: 5, top: 5}}/>
                    </IconButton>
                    <img
                        alt={image.title}
                        src={image.url}
                        className={importedStyles.mainImageFullscreen}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/img/png/thumbnail.png"
                        }}
                    />
                </div>

            </Dialog>
        );
    };

    renderFullScreenToggleButton = () => {
        let onClickFunc = () => {
            this.setState((prevState, prevProps) => {
                return {
                    imageFullscreen: !prevState.imageFullscreen
                }
            })
        }


        return (
            <IconButton onClick={onClickFunc} className={importedStyles.fullscreenImageToggleButton}>
                {this.state.imageFullscreen ? <FullscreenExitRounded/> : <FullscreenRounded/>}
            </IconButton>
        )
    }

    renderTagsOld = () => {
        const tags = this.props.video.tags;
        let tagsView = [];

        Object.keys(tags).forEach((index) => {
            tagsView.push(
                <p key={index} className={importedStyles.tag} onClick={() => this.handleTagClick(tags[index].text)}>
                    {tags[index].text}
                </p>
            );
        });
        return (
            <React.Fragment>
                {/*<p className={importedStyles.tagsSectionTitle}>{tagsView.length > 0 && 'Hashtags'}</p>*/}
                <div className={importedStyles.tagsContainer}>
                    {tagsView}
                </div>
            </React.Fragment>
        )
    };

    renderTags = () => {
        let tags = this.props.video.tags;
        let chips = [];
        Object.keys(tags).forEach((key) => {
            chips.push(
                <Chip
                    className={importedStyles.tagChip}
                    avatar={<Avatar className={importedStyles.tagAvatar}>#</Avatar>}
                    label={tags[key].text}
                    color="primary"
                    variant="outlined"
                />
            );
        });
        return (
            <Container className={importedStyles.tagsContainer}>
                {chips}
            </Container>
        );
    };

    handleCategoryLinkClick = category => () => {
        this.props.dispatch(setCategoryActive(category));
        history.push('/listVideos/' + category.urlSlug);
    };

    renderCategories = () => {
        const categories = this.props.video.categories;
        let categoriesView = [];

        Object.keys(categories).forEach((index) => {
            categoriesView.push(
                <p key={index} className={importedStyles.category}
                   onClick={this.handleCategoryLinkClick(categories[index])}>
                    {categories[index].name}
                </p>
            );
        });
        return (
            <React.Fragment>
                <div className={importedStyles.categoriesContainer}>
                    {categoriesView}
                </div>
            </React.Fragment>
        )
    };

    displaySnackIOs = () => {
        this.props.dispatch(showSnack('Lo sentimos, no es posible descargar un vídeo desde este dispositivo. Por favor, inténtalo desde tu ordenador.', snackTypes.error));
    };

    retrieveVideoQualityDownloadUrls = async () => {

        this.setState({waitingForDownloadLinks: true});
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.vimeoId;

        try {
            let response = await fetch(apiBaseUrl + 'downloads/retrieveVideoDownload', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({videoId: videoId, entityId: entityId}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            let rrssBestResolution = {height: 0, width: 0};
            response.downloadLinks.forEach((videoQuality) => {
                if ((videoQuality.width === 720 && videoQuality.height === 1280) || (videoQuality.height === 720 && videoQuality.width === 1280)) {
                    rrssBestResolution = videoQuality;
                    this.setState({url720p: videoQuality.link});
                }
                if (videoQuality.quality === 'source') {
                    this.setState({fallbackUrl: videoQuality.link});
                }
                /*  switch (true) {
                      case videoQuality.height <= 360:
                          this.setState({url360p: videoQuality.link});
                          break;
                      case videoQuality.height <= 480:
                          this.setState({url480p: videoQuality.link});
                          break;
                      case videoQuality.height <= 720:
                          this.setState({url720p: videoQuality.link});
                          break;
                      case videoQuality.height <= 1080:
                          this.setState({url1080p: videoQuality.link});
                          break;
                      case videoQuality.height <= 1440:
                          this.setState({url2k: videoQuality.link});
                          break;
                      case videoQuality.height <= 2160:
                          this.setState({url4k: videoQuality.link});
                          break;

                      default:
                          break;
                  }*/

            });

            this.setState({waitingForDownloadLinks: false});

        } catch (error) {
            this.setState({waitingForDownloadLinks: false});
            console.error('Error: ', error);
        }
    };

    addDownloadRegister = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        ReactPixel.trackCustom('VideoDownload', {video: this.props.video.urlSlug});
        fetch(apiBaseUrl + 'downloads/addDownloadRegister', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, isMarkedVideo: false})

        }).then(res => res.json())
            .then((response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error);
                }
            ).catch(error => {
                console.log('Error: ' + error);
            }
        );
    }

    handleDownload = async (e) => {
        e.stopPropagation();

        if (this.props.video.media_type === 'video') {
            await this.retrieveVideoQualityDownloadUrls();
            this.addDownloadRegister();
            if (this.state.url720p) {
                this.handleDownloadsModal();
            } else {
                let win = window.open(this.state.fallbackUrl, "_blank");
                win.focus();
            }
        } else {
            await this.downloadImageToBrowser();
        }

    };

    downloadImageToBrowser = async () => {
        try {
            const image = await fetch(apiBaseUrl + 'images/downloadImage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({imageUrl: this.props.video.url})

            });
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);

            const link = document.createElement('a');
            link.href = imageURL;
            // link.target = '_blank';
            link.download = this.props.video.title;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.log('Error en descarga de imagen: ' + err);
            this.props.dispatch(showSnack('Error al generar tu descarga', snackTypes.error));

        }

    }


    // renderActionButton = () => {
    //     if (this.state.waitingForDownloadLinks) {
    //         return (
    //             <div className={importedStyles.floatingButtonContainer}>
    //                 <CircularProgress size={23.5}/>
    //             </div>
    //         );
    //     } else {
    //         let text = this.state.bought ? "Descargar vídeo" : "Descargar";
    //         let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    //
    //         return (
    //             <div className={importedStyles.floatingButtonContainer}>
    //                 <Fab
    //                     variant="extended"
    //                     size={"large"}
    //                     className={importedStyles.floatingButton}
    //                     // href={this.state.fallbackUrl}
    //                     onClick={iOS ? this.displaySnackIOs : this.handleDownload}
    //                 >{text}</Fab>
    //             </div>
    //         );
    //
    //     }
    //
    // };

    renderWallButton = () => {

        if (this.props.user && this.props.user.entity && this.props.user.permissions_level !== 'all-included') {
            return false;
        }
        if (this.state.waitingResponseFavorite) {
            return (
                <IconButton className={importedStyles.favIcon}>
                    <Skeleton width={25} height={25}/>
                </IconButton>
            );
        } else {
            if (this.props.isOnWall) {
                return (
                    <Tooltip title={'Quitar del muro de confianza'} placement={'bottom'}>
                        <IconButton onClick={this.handleWall} className={importedStyles.actionButtonFilled}
                                    style={{margin: '0rem 0. 0rem 0rem'}}>
                            <WallIcon
                                // fill={'#00a3da'}
                                style={{width: '1.67rem', height: '1.67rem', marginBottom: -3}}/>
                        </IconButton>
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={'Añadir al muro de confianza'} placement={'bottom'}>
                        <IconButton onClick={this.handleWall} className={importedStyles.actionButton}
                                    style={{margin: '0rem 0. 0rem 0rem'}}>
                            <WallOutlinedIcon
                                // fill={'#00a3da'}
                                style={{width: '1.67rem', height: '1.67rem', marginBottom: -3}}/>
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    };

    renderFavButton = () => {
        if (this.state.waitingResponseFavorite) {
            return (
                <IconButton className={importedStyles.favIcon}>
                    <Skeleton width={25} height={25}/>
                </IconButton>
            );
        } else {
            if (this.props.isFavorite) {
                return (
                    <Tooltip title={'Quitar de favoritos'} placement={'bottom'}>
                        <IconButton onClick={this.handleFavorite} className={importedStyles.actionButtonFilled}
                                    style={{margin: '0rem 0.5rem'}}>
                            <BookmarksIcon/>
                        </IconButton>
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={'Añadir a favoritos'} placement={'bottom'}>
                        <IconButton onClick={this.handleFavorite} className={importedStyles.actionButton}
                                    style={{margin: '0rem 0.5rem'}}>
                            <BookmarksOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    };

    renderDownloadVideoButton = () => {

        if (this.state.waitingForDownloadLinks) {
            return (
                <IconButton className={importedStyles.downloadIconSkeleton}>
                    <CircularProgress size={23.5}/>
                </IconButton>
            );
        } else {
            let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            return (
                <Tooltip title={'Descargar archivo'} placement={'bottom'}>
                    <IconButton
                        onClick={iOS ? this.displaySnackIOs : this.handleDownload}
                        className={importedStyles.actionButton}
                        style={{margin: '0rem 0.5rem'}}
                    >
                        {/*href={this.state.fallbackUrl} target={'_blank'}>*/}
                        <DownloadIcon2/>
                    </IconButton>
                </Tooltip>
            );

        }

    };

    renderLikeButton = () => {
        let disableButton = false;
        if (this.props.request.likeable_date_limit) {
            disableButton = moment(this.props.request.likeable_date_limit).isBefore(moment());
        }
        let disabledClickFunction = async (e) => {
            e.stopPropagation();
            let toastOptions = {
                id: "toast-vote-limit-date",
                style: {
                    color: '#fff',
                    backgroundColor: '#b94343',
                    fontWeight: 300,
                    padding: '8px 20px 8px 0px',
                    textAlign: 'center'
                },
                iconTheme: {
                    primary: '#fff',
                    secondary: '#b94343',
                },
                icon: '',
                position: "bottom-left",
                duration: 3000
            };

            if (isMobileWidth) {
                toastOptions.position = "bottom-center";
            }

            toast.error('Lo sentimos. Ya no se puede votar, se acabó el tiempo de las votaciones.', toastOptions);
        };


        return (
            <Tooltip title={this.props.isLiked ? 'Quitar voto' : 'Votar'} placement={'bottom'}
                     style={{margin: '0.5rem'}}>
                <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>

                    <IconButton
                        onClick={disableButton ? disabledClickFunction : this.handleLike}
                        disableRipple
                        className={this.props.isLiked ? importedStyles.actionButtonFilled : importedStyles.actionButton}
                    >
                        <div className={importedStyles.actionButtonLabelWrapper}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {this.props.isLiked ? <FavIconRelleno style={{color: redColor, fontSize: 25}}/> :
                                    <FavIcon style={{height: 20, width: 20, marginRight: 4}}/>}
                                <span className={importedStyles.actionButtonCounter}>{this.props.likesCount}</span>
                            </div>
                            <small className={importedStyles.actionButtonLabel}>votar</small>
                        </div>

                    </IconButton>


                </div>

            </Tooltip>
        );

    };
    renderShareButton = () => {
        return (
            <Tooltip title={'Compartir'} placement={'bottom'} style={{margin: '0.5rem'}}>
                <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>

                    <IconButton disableRipple onClick={this.handleShare} className={importedStyles.actionButton}>
                        {/*<ShareIcon/>*/}
                        <div className={importedStyles.actionButtonLabelWrapper}>
                            <ShareArrow
                                style={{fill: '#000', width: '1.3rem', height: '1.25rem', marginBottom: '0.2rem'}}/>
                            <small className={importedStyles.actionButtonLabel}>compartir</small>
                        </div>

                    </IconButton>

                </div>
            </Tooltip>
        );
    };

    renderDownloadsModal = () => {
        let fullScreen = window.matchMedia("(max-width: 768px)").matches;
        return (
            <Dialog
                open={this.state.openDownloadsModal}
                onClose={this.handleDownloadsModal}
                aria-labelledby="Download buttons modal"
                fullWidth
                maxWidth={"sm"}
                fullScreen={fullScreen}
            >
                <IconButton className={importedStyles.closeModalDownloadIcon} onClick={this.handleDownloadsModal}>
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    Descarga
                </DialogTitle>
                <DialogContent>
                    <div className={importedStyles.downloadsModalContent}>
                        {this.renderDownloadButtons()}
                    </div>
                </DialogContent>
            </Dialog>
        );
    };
    renderShareModal = () => {
        let mediaTypeTitle = this.props.video.media_type === 'video' ? 'este vídeo' : 'esta imagen';
        return (
            <ShareModal open={this.state.shareModal} onClose={this.handleShareModalClose}
                        shareClaim={'Mira ' + mediaTypeTitle + ' que he encontrado en Grábalo: '}/>
        );
    };


    renderDownloadButtons = () => {
        return (
            <React.Fragment>
                <Button key={'sourceQuality'} variant={"outlined"} href={this.state.fallbackUrl}
                        className={importedStyles.qualityDownloadButton}>
                    ORIGINAL (calidad total)<DownloadIcon className={importedStyles.downloadIcon}/>
                </Button>
                {
                    this.state.url720p !== null ?
                        <Button key={'720p'} variant={"outlined"} href={this.state.url720p}
                                className={importedStyles.qualityDownloadButton}>
                            RRSS (menor calidad) <DownloadIcon className={importedStyles.downloadIcon}/>
                        </Button>
                        :
                        this.state.waitingForDownloadLinks && <CircularProgress size={30} style={{marginLeft: '1rem'}}/>

                }

            </React.Fragment>
        );

    };

    renderDeleteButton = () => {
        if (this.props.video.userId === this.props.user.uuid) {
            return (
                <Tooltip title={'Eliminar archivo'} placement={"left"}>
                    <IconButton className={importedStyles.deleteIcon} onClick={() => {
                        this.handleModalStatus()
                    }}>
                        <DeleteForever style={{color: 'red'}}/>
                    </IconButton>
                </Tooltip>
            );
        }
    };

    renderHeader = () => {
        let video = this.props.video;
        return (
            <Helmet>
                <title>{video.title}</title>
                <meta property="og:title" content={video.title}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image:secure_url " content={video.coverUrl}/>
                <meta property="og:type" content="website"/>
            </Helmet>
        )
    };


    renderNavigation = () => {
        return (
            <React.Fragment>
                {this.props.prevVideo &&
                    <IconButton className={importedStyles.mobileNavigationButtonLeft}
                                onClick={this.handlePrevVideo}>
                        <NavigationArrow className={importedStyles.mobileNavigationIconLeft}/>
                    </IconButton>
                }
                {this.props.nextVideo &&
                    <IconButton className={importedStyles.mobileNavigationButtonRight}
                                onClick={this.handleNextVideo}>
                        <NavigationArrow className={importedStyles.mobileNavigationIconRight}/>
                    </IconButton>
                }
            </React.Fragment>
        )
    };

    handlePrevVideo = () => {
        //history.push(this.props.location.pathname + '/' + video.urlSlug + history.location.search);
        let requestId = this.props.match.params.requestId;
        let params = {videoId: this.props.prevVideo.urlSlug};

        if (requestId) {
            params = {requestId: requestId, videoId: this.props.prevVideo.urlSlug};
        }

        let newPath = generatePath(this.props.match.path, params);
        history.push(newPath);
    }

    handleNextVideo = () => {
        //history.push(this.props.location.pathname + '/' + video.urlSlug + history.location.search);

        // this.props.dispatch(setVideo(this.props.nextVideo));
        let requestId = this.props.match.params.requestId;
        let params = {videoId: this.props.nextVideo.urlSlug};

        if (requestId) {
            params = {requestId: requestId, videoId: this.props.nextVideo.urlSlug};
        }

        let newPath = generatePath(this.props.match.path, params);
        history.push(newPath);
    }

    renderActionButtons = () => {
        return (
            <div className={importedStyles.actionsBox}>
                {this.props.logged && this.props.user.entity && this.props.withConfidenceWall && this.renderWallButton()}
                {this.props.logged && this.props.user.entity && this.props.withFavorite && this.renderFavButton()}
                {this.props.logged && this.props.user.entity && this.props.withDownload && this.renderDownloadVideoButton()}
                {this.props.withLikes && this.renderLikeButton()}
                {this.props.withShare && this.renderShareButton()}
            </div>
        )
    }

    handleMobileAdPopup = () => {

    }

    setMobileAdCookie = () => {
        sessionStorage.setItem("mobileAdPopupShowed-ChallengeId:" + this.props.request.uuid, true);
    }

    checkMobileAdCookie = () => {
        if (!isMobileWidth) return false; //break execution if not mobile


        if (!this.props.request || !this.props.request.ads || this.props.request.ads.length <= 0) {
            return false;
        }

        let ads = this.props.request.ads.filter(item => item.type === 'banner_vertical');

        if (ads.length <= 0) {
            this.setState({openMobileAdPopup: false});
            return false;
        }

        if (sessionStorage.getItem("mobileAdPopupShowed-ChallengeId:" + this.props.request.uuid) === null) {
            this.setState({openMobileAdPopup: true});
            this.setMobileAdCookie();
        } else {
            this.setState({openMobileAdPopup: false});
        }
    }

    onAdInViewport = (isVisible, interactionType, adId) => {
        if (isVisible) {
            sendAdInteraction(interactionType, adId)
        }
    }

    renderAdBanner = () => {
        // if (!this.props.request || !this.props.request.ads || this.props.request.ads.length <= 0) {
        //     return false;
        // }
        //
        // let ads = this.props.request.ads.filter(item => item.type === 'file-detail-modal');
        //
        // if (!ads && ads.length <= 0) return false;
        //
        // let randomIndex = Math.floor(Math.random() * ads.length);
        //
        // let ad = ads[randomIndex];


        let ad = this.state.topAdBanner

        if (!this.state.topAdBanner) return false;


        return (
            <Fade in={true} timeout={500}>
                {/*<ReactVisibilitySensor onChange={isVisible => this.onAdInViewport(isVisible, 'view', ad.id)}>*/}
                <AdWithSensorWrapper adObject={ad}>
                    <div id={'patrocinador-evento-container' + this.props.video.uuid}
                         className={importedStyles.adTopContainer}>
                        <a href={ad.action_url} target={'_blank'} style={{marginBottom: -5}}
                           onClick={() => sendAdInteraction('click', ad.id)}>
                            <img src={ad.src_url} alt={'imagen-patrocinador'} className={importedStyles.adTopImg}
                                 onLoad={() => sendAdInteraction('view', ad.id)}
                                 style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                        </a>
                    </div>
                </AdWithSensorWrapper>

                {/*</ReactVisibilitySensor>*/}
            </Fade>
        );
    }

    shuffleArray = array => {

        let copyArray = [...array];
        for (let i = copyArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = copyArray[i];
            copyArray[i] = copyArray[j];
            copyArray[j] = temp;
        }
        return copyArray;
    }

    handlePopupAdClose = () => {
        this.setState({openMobileAdPopup: false});

        setTimeout(() => {
            let ads = this.props.request.ads.filter(item => item.type === 'banner_vertical');
            if ((this.props.currentAdIndex + 1) >= ads.length) {
                this.props.dispatch(setCurrentAdIndex(0));
            } else {
                this.props.dispatch(setCurrentAdIndex(this.props.currentAdIndex + 1));
            }
        }, 50)// just to prevent ad change visual bug when leaving

    }

    renderMobileAdPopupBanner = () => {


        if (!this.props.open || !this.props.request || !this.props.request.ads || this.props.request.ads.length <= 0) {
            return false;
        }

        let ads = this.props.popupAds;

        if (ads.length > 0) {

            let ad = ads[this.props.currentAdIndex];

            if (!ad) return false; //guard clause

            let content = (
                <img
                    src={ad.src_url}
                    // onLoad={() => sendAdInteraction('click', ad.id)}
                    alt={''}
                    style={{
                        borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 0 : ad.border_radius,
                        // width: '100%',
                        aspectRatio: '9/21',
                        maxHeight: '87vh',
                        marginBottom: -4
                    }}
                />
            );

            if (ad.action_url && ad.action_url.trim().length > 0) {
                content = (
                    <a href={ad.action_url} target={'_blank'} rel={'noopener noreferrer'}
                       className={importedStyles.mobileAdPopupBannerClickableWrapper}
                       onClick={() => sendAdInteraction('click', ad.id)}>
                        <img
                            src={ad.src_url}
                            // onLoad={() => sendAdInteraction('click', ad.id)}
                            alt={''}
                            style={{
                                borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 0 : ad.border_radius,
                                // width: '100%',
                                aspectRatio: '9/21',
                                maxHeight: '87vh',
                                marginBottom: -4
                            }}
                        />
                    </a>
                );
            }

            return (
                <React.Fragment>
                    <AdWithSensorWrapper adObject={ad}>
                        {content}
                    </AdWithSensorWrapper>
                </React.Fragment>
            )
        }


    };

    renderDescription = () => {
        let description = getTranslatedText(this.props.video.description);
        return (
            <Fade in={this.props.video && this.props.video.description} mountOnEnter unmountOnExit>
                <Interweave
                    allowList={ALLOWED_TAG_LIST.concat('iframe')}
                    content={description}
                    attributes={{className: importedStyles.descriptionContainer}}
                    containerTagName={'div'}
                />
            </Fade>
        )
    }

    getBackButtonTitle = () => {

        if (this.props.backButtonTitle) {
            return this.props.backButtonTitle;
        }

        return localStorage.getItem('grabalo-user-language') === 'vlc' ? 'Inici ' : 'Inicio ';
    }

    onAudioPlaying = () => {
        this.setState({playingAudio: true})
    }
    onAudioPause = () => {
        this.setState({playingAudio: false})
    }

    renderAudioPlayer = () => {
        if (this.state.videoLoading){
           this.setState({videoLoading: false})
        }

        return (
            <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: isMobile ? '#191616' : 'transparent'}}>
                <Box style={{borderRadius: 800, border: '2px solid #61a8d5', width: 'fit-content', marginBottom: isMobile ? '2rem' : '3rem', marginTop: isMobile ? '2rem' : 'unset'}}>
                    <Lottie
                        loop
                        animationData={grabaloWave}
                        play={this.state.playingAudio}
                        style={{height: 180}}
                    />
                </Box>

                <audio controls controlsList="nodownload" onPlay={this.onAudioPlaying} onPause={this.onAudioPause} style={{marginBottom: isMobile ? '2rem' : '0'}} onLoad={() => this.setState({videoLoading: false})}>
                    <source src={this.props.video.url} type="audio/mpeg"/>
                    {/*<source src={'https://www.fiftysounds.com/music/spirit-of-fire.mp3'} type="audio/mpeg"/>*/}
                    Tu navegador no soporta el elemento de audio.
                </audio>
            </Box>
        )
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                // maxWidth={"md"}
                scroll={"paper"}
                fullWidth
                // fullScreen={isMobileWidth}
                fullScreen={isMobileWidth || isMobile}
                TransitionProps={{
                    unmountOnExit: true, mountOnEnter: true,
                    onEntered: this.checkMobileAdCookie
                }}
                transitionDuration={{appear: 300, enter: 400, exit: 500}}
                // closeAfterTransition
                classes={{root: importedStyles.dialogRoot, paper: importedStyles.dialog}}
                key={'detailModal_' + this.props.video.uuid + localStorage.getItem('grabalo-user-language')}
            >
                <Fade in={this.props.open} unmountOnExit mountOnEnter>
                    <IconButton className={importedStyles.closeModalButton} onClick={this.props.onClose}>
                        <CloseSharp/>
                    </IconButton>
                </Fade>

                <Fade in={this.state.videoLoading} timeout={300} mountOnEnter unmountOnExit>
                    <LinearProgress size={120} classes={{
                        root: importedStyles.linearProgress,
                        bar: importedStyles.linearProgressBar
                    }}/>
                </Fade>


                <React.Fragment>
                    <Fade in={this.props.open} timeout={0}>
                        <div className={importedStyles.DetailVideo}>

                            <React.Fragment>
                                <Hidden smDown>
                                    <>
                                        <Fade
                                            in={this.props.request && this.props.request.multilanguage && this.props.withAds}
                                            unmountOnExit
                                            mountOnEnter>
                                            <LanguageSelector/>
                                        </Fade>

                                        <Grid
                                            container
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                minHeight: '80vh'
                                            }}
                                        >
                                            <Grid item xs={5} style={{
                                                background: '#000',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                {this.props.video.media_type === 'video' && this.renderVimeoPlayerDesktop()}
                                                {this.props.video.media_type === 'image' && this.renderMainImage()}
                                                {this.props.video.media_type === 'audio' && this.renderAudioPlayer()}

                                            </Grid>
                                            <Grid item xs={7} style={{display: 'flex', flexDirection: 'column'}}>
                                                {/*{this.props.withAds && this.renderAdBanner()}*/}

                                                <div className={importedStyles.bodyContainer}>
                                                    <div className={importedStyles.titleContainer}>
                                                    <span
                                                        className={importedStyles.videoTitle}>{this.props.video.title}</span>
                                                        {this.props.video.user &&
                                                            <p className={importedStyles.videoUserName}>{this.props.video.user.name}</p>}
                                                        {this.props.video.name &&
                                                            <p className={importedStyles.videoUserName}>{this.props.video.name}</p>}
                                                    </div>
                                                    {this.renderDescription()}

                                                </div>
                                                <Divider style={{
                                                    backgroundColor: '#BDBDBD',
                                                    height: 1,
                                                    marginBottom: '0.5rem',
                                                    marginTop: 'auto',
                                                    marginLeft: '1.25rem',
                                                    marginRight: '1.25rem'
                                                }} variant={"middle"}/>
                                                <div className={importedStyles.desktopActionsWrapper}>
                                                    {this.renderActionButtons()}
                                                    <div className={importedStyles.navigationBox}>
                                                        <Fade in={this.props.prevVideo}>
                                                            <IconButton
                                                                style={{
                                                                    background: '#b5b5b5',
                                                                    padding: 0,
                                                                    marginRight: 15
                                                                }}
                                                                onClick={this.handlePrevVideo}>
                                                                <ChevronLeftSharp
                                                                    style={{color: '#fff', fontSize: 42}}/>
                                                            </IconButton>
                                                        </Fade>
                                                        <Fade in={this.props.nextVideo}>
                                                            <IconButton style={{background: '#b5b5b5', padding: 0}}
                                                                        onClick={this.handleNextVideo}>
                                                                <ChevronRightSharp
                                                                    style={{color: '#fff', fontSize: 42}}/>
                                                            </IconButton>
                                                        </Fade>
                                                    </div>
                                                </div>


                                            </Grid>
                                        </Grid>
                                    </>
                                </Hidden>

                                <Hidden smUp>
                                <Fade
                                    in={isMobile}
                                    // in={false}
                                      unmountOnExit mountOnEnter>
                                    <div>
                                        <div className={importedStyles.navigationTopBar}>

                                            <IconButton
                                                style={{padding: 5}}
                                                onClick={this.props.onClose}
                                            >
                                                <ChevronLeftSharp style={{color: '#000', fontSize: 42}}/>
                                            </IconButton>
                                            <span onClick={this.props.onClose}
                                                  className={importedStyles.navigationTopBarTitle}>
                                           {/*{localStorage.getItem('grabalo-user-language') === 'vlc' ? 'Anar ' : 'Ir '} a la página principal*/}

                                                {this.getBackButtonTitle()}

                                            </span>
                                            <Fade
                                                in={this.props.request && this.props.request.multilanguage && this.props.withAds}
                                                unmountOnExit mountOnEnter>
                                                <LanguageSelector styles={{
                                                    position: 'fixed',
                                                    bottom: 5,
                                                    right: 5,
                                                    left: 'unset',
                                                    top: 'unset'
                                                }}/>
                                            </Fade>
                                        </div>
                                        {this.props.withAds && this.renderAdBanner()}
                                        {this.props.video.media_type === 'video' && this.renderVimeoPlayer()}
                                        {this.props.video.media_type === 'image' && this.renderMainImage()}
                                        {this.props.video.media_type === 'audio' && this.renderAudioPlayer()}

                                        <div className={importedStyles.bodyContainer}>
                                            <div className={importedStyles.desktopActionsWrapper}>
                                                {this.renderActionButtons()}
                                                <div className={importedStyles.navigationBox}>
                                                    <Fade in={this.props.prevVideo}>
                                                        <IconButton
                                                            style={{background: '#b5b5b5', padding: 0, marginRight: 15}}
                                                            onClick={this.handlePrevVideo}>
                                                            <ChevronLeftSharp style={{color: '#fff', fontSize: 42}}/>
                                                        </IconButton>
                                                    </Fade>
                                                    <Fade in={this.props.nextVideo}>
                                                        <IconButton style={{background: '#b5b5b5', padding: 0}}
                                                                    onClick={this.handleNextVideo}>
                                                            <ChevronRightSharp style={{color: '#fff', fontSize: 42}}/>
                                                        </IconButton>
                                                    </Fade>
                                                </div>
                                            </div>
                                            <div className={importedStyles.titleContainer}>
                                                <span
                                                    className={importedStyles.videoTitle}>{this.props.video.title}</span>
                                                {this.props.video.user &&
                                                    <p className={importedStyles.videoUserName}>{this.props.video.user.name}</p>}
                                                {this.props.video.name &&
                                                    <p className={importedStyles.videoUserName}>{this.props.video.name}</p>}
                                            </div>
                                            {this.renderDescription()}

                                        </div>
                                        {this.props.withAds && <WhatsappButton
                                            visible={this.props.request && this.props.request.showWhatsappButton}/>}
                                    </div>

                                </Fade>

                                </Hidden>

                            </React.Fragment>
                        </div>
                    </Fade>
                </React.Fragment>

                {/* MODALS, SNACKS AND ALERTS */}
                <React.Fragment>
                    {this.renderDownloadsModal()}
                    {this.renderShareModal()}
                    {this.renderImageFullscreenViewerModal()}
                    <Snack/>
                </React.Fragment>


                <Dialog
                    open={this.props.withAds && this.state.openMobileAdPopup}
                    // open={true}
                    onClose={this.handlePopupAdClose}
                    maxWidth={"md"}
                    scroll={"body"}
                    fullWidth
                    // fullScreen={isMobileWidth}
                    TransitionProps={{unmountOnExit: true, mountOnEnter: true}}
                    transitionDuration={{exit: 0}}
                    classes={{
                        root: importedStyles.adMobilePopupDialogRoot,
                        paper: importedStyles.adMobilePopupDialogPaper
                    }}

                >

                    <div className={importedStyles.adMobilePopupImageWrapper}>
                        <IconButton className={importedStyles.closeAdModalIcon}
                                    onClick={this.handlePopupAdClose}>
                            <CloseIcon/>
                        </IconButton>
                        {this.renderMobileAdPopupBanner()}
                    </div>

                </Dialog>

            </Dialog>
        );
    }
}

DetailVideoModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    video: PropTypes.object.isRequired,
    handleNextVideo: PropTypes.func,
    handlePrevVideo: PropTypes.func,
    open: PropTypes.bool,
    isOnWall: PropTypes.bool,
    isLiked: PropTypes.bool,
    isFavorite: PropTypes.bool,
    actualVideoIndex: PropTypes.number,
    likesCount: PropTypes.number,
    setIsOnWall: PropTypes.func,
    setFavVideo: PropTypes.func,
    setLikeVideo: PropTypes.func,
    withDownload: PropTypes.bool,
    withFavorite: PropTypes.bool,
    withLikes: PropTypes.bool,
    withShare: PropTypes.bool,
    withConfidenceWall: PropTypes.bool,
    withAds: PropTypes.bool,
    backButtonTitle: PropTypes.string,
};

DetailVideoModal.defaultProps = {
    likesCount: 0,
    withAds: false,
}

const mapStateToProps = ({authReducer, detailVideoModalReducer, ConcursoReducer}) => {
    return ({
        request: ConcursoReducer.request,
        user: authReducer.user,
        logged: authReducer.logged,
        videos: detailVideoModalReducer.videos,
        currentAdIndex: detailVideoModalReducer.currentAdIndex,
        popupAds: detailVideoModalReducer.popupAds,
    });
};
export default connect(mapStateToProps)(DetailVideoModal);
