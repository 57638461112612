import {SET_VIDEO} from "../../../constants/action-types";


const initialState = {
    video: {},
};

const detailVideoReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_VIDEO:
            return {...state, video: action.video};
        default:
            return state;
    }
};


export default detailVideoReducer;