import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import importedStyles from './Faqs.module.sass';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {ReactComponent as ExpandMoreIcon} from "../../icons/desplegar texto.svg";
import UploadVideoButton from "../../components/UploadVideoButton/UploadVideoButton";
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import SuggestedLoginModal from "../../components/SuggestedLoginModal/SuggestedLoginModal";
import FaqIllustration from "../../images/faq-illustration-min.png";
import FaqIllustrationWebp from "../../images/faq-illustration-min.webp";
import {isWebpSupported} from 'react-image-webp/dist/utils';
import {Helmet} from "react-helmet/es/Helmet";


class Faqs extends React.Component {

    state = {
        expanded: null,
    };

    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'smooth'});
        ReactPixel.trackCustom('FaqsView', {user: this.props.user ? this.props.user.name : 'no logueado'});
        ReactGA.pageview(window.location.pathname);
    };

    handleChange = panel => (event, expanded) => {
        if (expanded) {
            ReactPixel.trackCustom('FaqOpened', {user: this.props.user ? this.props.user.name : 'no logueado'});
        }
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    getFaqs = () => {
        if (this.props.user && this.props.user.entity) {
            //Faqs empresa
            return ['¿Cómo se gestionan los derechos de imagen de las personas que aparecen en los concursos?',
                '¿Los vídeos pasan algún control de calidad?',
                '¿Puedo utilizar los vídeos que suban a mi concurso para cualquier finalidad?',
                '¿Cómo se elige a la persona ganadora del concurso?',
                '¿Cómo evitamos que una persona vote más de una vez en un vídeo y manipule el resultado del concurso?\n',
                '¿Los vídeos tienen fecha de caducidad o puedo utilizarlos para siempre?'
            ];
        } else {
            //Faqs user
            return [
                '¿Cómo voy a cobrar el dinero ganado en la plataforma?',
                '¿Cuánto dinero voy a cobrar si una empresa compra alguno de mis vídeos?',
                '¿Quién puede ver y utilizar los vídeos que subo a mi perfil en la plataforma?',
                '¿Compartís los vídeos que subo a mi perfil en otras plataformas digitales?',
                '¿Los vídeos subidos a la plataforma tienen que superar algún filtro de calidad?',
                '¿Tienen límite de tiempo o megas los vídeos que subo a Grábalo?',
                '¿Puedo borrar o eliminar mis vídeos subidos a la plataforma por cualquier motivo?',
                '¿Qué diferencia hay si subo un vídeo desde el teléfono móvil o desde el ordenador?',
                '¿Puedo subir los vídeos que he subido a Grábalo también a redes sociales?',
                '¿Puedo tener problemas legales si aparecen otras personas en mi vídeo?',
                '¿Puedo subir vídeos que me han llegado por redes sociales o mensajería como WhatsApp?',
                '¿Cómo evitamos que puedan descargarse y utilizar mis vídeos sin pagar?',
            ];
        }
    };

    getAnswers = () => {
        if (this.props.user && this.props.user.entity) {
            //Answers empresa
            return ['Cuando una persona sube un vídeo al concurso, automáticamente está aceptando los términos y las condiciones del concurso reflejado en las bases legales. Esto significado que tendrás los derechos de imagen de las personas que aparecen en los vídeos y podrás utlizarlos en tus canales de comunicación.',
                'Excepto en el plan BÁSICO, todos los vídeos recibidos serán moderados por nuestro equipo bajo tus directrices. Una vez aceptado el vídeo, pasará a ser visible en el sitio web del concurso.',
                'Podrás utilizar los vídeos en los canales de comunicación que se hayan redactado en las bases legales del concurso. Habitualmente las empresas utilizan los vídeos en sus canales de comunicación digitales, su página web y redes sociales (Facebook, Instagram, Youtube, etc.).',
                'El concurso tiene un sistema de votaciones y éste nos permite, además de contabilizar los votos, darle viralidad al concurso, ya que las personas participantes comparten sus vídeos con sus amigos, amigas y familiares para conseguir votos e intentar ganar el concurso. De este modo, los vídeos subidos al concurso se comparten de forma orgánica con otras personas consiguiendo un mayor alcance :)',
                'Para evitar que una persona vote más de una vez, hay que hacer un registro rápido con Google o Facebook (un clic) para que el voto sea válido.',
                'No tienen fecha de caducidad, podrás utilizar los vídeos subidos a tu concurso para siempre.',
            ];
        } else {
            //Answers user
            return [
                'Los canales de pago que utilizamos son Bizum en España y Paypal para el resto del mundo. Cuando tu monedero virtual supere los 10 euros recibirás un email informativo y serás tu quién decida si quieres cobrarlos o esperar a tener una mayor cantidad de dinero.' +
                'Hasta que no llegues a los 10 euros no podrás sacar el dinero de la plataforma.',
                'Los precios variarán en función de qué uso haga la empresa que compra tu vídeo. Como autor del vídeo recibirás un porcentaje variable entre el 70% y 50% del precio de venta. ' +
                'Habitualmente, la cantidad que ganarás por venta suele estar entre 1 y 5 euros. ' +
                'Como se trata de un vídeo y éste es un archivo digital, tu vídeo puede venderse y cobrarse infinidad de veces, cuantas más veces se venda tu vídeo más dinero conseguirás.',
                'Los vídeos que subes a Grábalo no se comparten en otras plataformas a no ser que tu vídeo sea seleccionado como un vídeo ejemplar y éste pueda servir para inspirar al resto de la comunidad.' +
                'En el caso de que tu vídeo sea seleccionado como un vídeo inspirador para enseñar a otras personas a cómo grabar vídeos lo difundiremos en nuestros perfiles de redes sociales y en nuestra página web.',
                'Los vídeos que subes a Grábalo no se comparten en otras plataformas a no ser que tu vídeo sea seleccionado como un vídeo ejemplar ' +
                'y éste pueda servir para inspirar al resto de la comunidad. En el caso de que tu vídeo sea seleccionado como un vídeo inspirador para enseñar' +
                ' a otras personas a cómo grabar vídeos lo difundiremos en nuestros perfiles de redes sociales y en nuestra página web con una marca de agua ' +
                'de la plataforma e indicando tu nombre con el que te hayas registrado en Grábalo.',
                'Por supuesto, personas expertas serán las encargados de revisar todos los vídeos que se suben a la plataforma para asegurarnos de que éstos cumplen las exigencias técnicas de calidad' +
                ' y que además no vulneran los principios éticos y legales de la plataforma.',
                'No podrás subir vídeos que superen los 500 megas de capacidad, esto equivale a vídeos superiores a 4 minutos grabados en Full HD. Creemos que lo más habitual es grabar vídeos inferiores ' +
                'a esta cantidad de megas. En caso de que tengas un vídeo superior a 500 megas puedes ponerte en contacto por medio de la plataforma ' +
                'y te permitiremos subir tu vídeo.',
                'Por supuesto, en el momento que decidas eliminar algún vídeo de tu perfil, éstos dejarán de estar visibles en toda la plataforma y nunca más podrán comprarlos las empresas.',
                'No existe ninguna diferencia entre subir un vídeo desde el teléfono móvil o desde el ordenador, tienes la posibilidad de hacerlo desde cualquier dispositivo, utiliza el que sea más cómodo para ti. ',
                'Tienes total libertad para subir tus vídeos también en redes sociales pero debemos decirte que si subes tus vídeos en tus redes sociales es probable ' +
                'que algunas empresas puedan coger tu vídeo sin tu consentimiento y lo utilicen sin que seas consciente ya que legalmente pueden compartir tu vídeo en su página web.',
                'Cuando subes un vídeo a la plataforma estás afirmando que todas las personas que aparecen en el vídeo y se les reconoce te han dado su consentimiento para que lo puedas difundir.' +
                ' Seguro que estás pensando ¿y si grabo un vídeo en un concierto? ¿Es imposible que tenga el consentimiento de todas las personas que aparecen en el vídeo? Efectivamente, no tienes ' +
                'que tener el consentimiento de todas las personas que aparecen en el vídeo pero sí de aquellas que se les ve la cara y se les puede reconocer. Tenemos también otras excepciones como son los vídeos noticiables,' +
                ' en este caso pueden aparecer en el vídeo personas que no nos hayan dado su consentimiento porque prima el derecho a la información.\n',
                'Por supuesto que no. Cuando subes un vídeo a Grábalo estás afirmando que el vídeo lo has grabado tú y por lo tanto tu eres el autor del vídeo.',
                'Nos tomamos muy en serio los derechos de autor y por ello cuando subes el vídeo lo protegemos con una marca de agua para que nadie pueda descargarlo sin tu consentimiento y apropiarse la autoría, aprovechándose del vídeo sin que tú obtengas una recompensa.',

            ];
        }
    };

    renderFaqs = () => {
        const {expanded} = this.state;
        const faqs = this.getFaqs();
        const answers = this.getAnswers();
        let faqsView = [];

        for (let i in faqs) {
            faqsView.push(
                <ExpansionPanel className={importedStyles.panel} expanded={expanded === i}
                                onChange={this.handleChange(i)}>
                    <ExpansionPanelSummary className={importedStyles.panelSummary}
                                           expandIcon={<ExpandMoreIcon className={importedStyles.expandIcon}/>}>
                        <p className={importedStyles.panelSummaryText}>
                            {faqs[i]}
                        </p>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={importedStyles.panelDetails}>
                        <p className={importedStyles.panelDetailsTypography}>
                            {answers[i]}
                        </p>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        }

        return (
            <React.Fragment>
                {faqsView}
            </React.Fragment>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Faqs</title>
                </Helmet>
                <NavBar/>
                <UploadVideoButton/>
                <DesktopDrawer/>
                <div className={importedStyles.parentContainer}>
                    <div className={importedStyles.titleContainer}>
                        <h1>Preguntas frecuentes</h1>
                        <h3>Si estas respuestas no aclaran tus dudas, escríbenos.</h3>
                    </div>
                    {this.renderFaqs()}
                    <img
                        src={isWebpSupported() ? FaqIllustrationWebp : FaqIllustration}
                        className={importedStyles.faqIllustration}
                        alt={'faq background illustration'}
                    />

                </div>
                <SuggestedLoginModal/>

            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(Faqs);
