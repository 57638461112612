import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'whatwg-fetch';
const { detect } = require('detect-browser');
const browser = detect();

if (!('fetch' in window)) {
    console.warn('Fetch API not found on your browser, fallback to polyfill');
}

ReactDOM.render(<App/>, document.getElementById('root'));


if(browser.name === 'instagram' || browser.name === 'facebook'){
    // do not import serviceWorker as it causes app break
} else {
   // let serviceWorker = require('./serviceWorker');
   // serviceWorker.unregister();
   // serviceWorker.register();
}

