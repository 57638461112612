import React from 'react';
import importedStyles from "./Snack.module.sass";
import Snackbar from '@material-ui/core/Snackbar';
import connect from "react-redux/es/connect/connect";
import {showSnack} from './SnackActions';
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

class Snack extends React.Component {

    state = {
        snackData: {},
    };
    handleClose = () => {
        let snackData = this.props.snackData;
        //If there is no fixed parameter or its setted false, close snack. If set to true, only closed by action button
        if (!snackData.fixed) {
            this.props.dispatch(showSnack('', '', false));
        }
    };

    render() {
        const snackData = this.props.snackData;
        let style = importedStyles[snackData.snackType === '' ? snackData.oldSnackType : snackData.snackType];

        return (
        <Fade in={snackData.show}>
        <div className={importedStyles.snackContainer}>
                <Snackbar
                    className={style}
                    classes={{root: importedStyles.snackRoot}}
                    message={<span className={importedStyles.snackText}>{snackData.text}</span>}
                    open={snackData.show}
                    autoHideDuration={10000}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    onClose={this.handleClose}
                    action={
                        <Button
                            size={"small"}
                            key="close"
                            aria-label="Close"
                            className={importedStyles.snackButton}
                            variant={"outlined"}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.dispatch(showSnack('', '', false))
                            }}
                        >
                            Aceptar
                        </Button>
                    }
                />
            </div>
        </Fade>
        );
    }
}

const mapStateToProps = ({snackReducer}) => {
    return ({
        snackData: snackReducer.data,
    });
};

export default connect(mapStateToProps)(Snack);