import React from "react";
import importedStyles from './NavBar.module.sass';
import SwipeableDrawer from "@material-ui/core/es/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/MenuRounded';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import ProfileIcon from '@material-ui/icons/PermIdentityRounded';
import connect from "react-redux/es/connect/connect";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import history from "../../helpers/history";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {ReactComponent as WallOutlinedIcon} from "../../icons/wallOutlinedIcon.svg";
import {
    CloseRounded,
    EmojiEventsOutlined,
    ExitToApp,
    InputRounded,
    MailOutlined,
    PermIdentityOutlined,
    StarBorderSharp,
    VideoLibraryOutlined
} from '@material-ui/icons';
import {logout} from "../../modules/Auth/authActions";
import {Hidden} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";
import ReactPixel from "react-facebook-pixel";
import {pushRoute, throwLogin} from "../../helpers/routeMiddleware";
import ReactGA from "react-ga";
import {ReactComponent as GrabaloIcon} from "../../icons/logo_grabalo_texto_claro.svg";
import {removeAuthParams} from "../../helpers/urlParamsUtilities";
import DownloadIcon from "@material-ui/icons/SaveAltRounded";
import Fade from "@material-ui/core/Fade";


class NavBar extends React.Component {

    state = {
        openDrawer: false,
    };

    toggleDrawer = () => () => {
        this.setState({openDrawer: !this.state.openDrawer});
    };

    handleLogout = async () => {
        ReactPixel.trackCustom('ClosedSession');
        ReactGA.ga('send', 'event', 'click', 'ClosedSession');
        await this.props.dispatch(logout());
        //this.props.dispatch(showSnack('Tu sesión se ha cerrado correctamente', 'warning'));
        history.push('/login');
    };

    handleLogin = () => {
        throwLogin(window.location.pathname);
    };


    handleMenuClick = option => () => {
        ReactPixel.trackCustom('MenuOptionSelected', {option: option});
        history.push(option);
    };

    handleMenuClickRestricted = option => () => {
        ReactPixel.trackCustom('MenuOptionSelected', {option: option});
        pushRoute(option);
    };

    renderEntityMenu = () => {
        return (
            <React.Fragment>
                <ListItem
                    button
                    className={importedStyles.listItem}
                    classes={{selected: importedStyles.itemSelected}}
                    key={'mis-eventos'}
                    onClick={this.handleMenuClickRestricted('/mis-eventos')}
                    selected={window.location.pathname === '/myRequests' || window.location.pathname === '/mis-concursos' || window.location.pathname === '/mis-eventos'}
                >
                    <ListItemIcon> <StarBorderSharp  style={{fontSize: '2.3rem'}}/> </ListItemIcon>
                    <ListItemText primary={'Mis eventos'}/>
                </ListItem>



                <ListItem className={importedStyles.listItem}
                          classes={{selected: importedStyles.itemSelected}}
                          selected={window.location.pathname === '/favourites' || window.location.pathname === '/favoritos'}
                          button key={'favourites'}
                          onClick={this.handleMenuClickRestricted('/favoritos')}>
                    <ListItemIcon> <BookmarksOutlinedIcon/> </ListItemIcon>
                    <ListItemText primary={'Favoritos'}/>
                </ListItem>

                <Fade in={this.props.user && this.props.user.entity && this.props.user.permissions_level === 'all-included'} mountOnEnter unmountOnExit>
                    <ListItem className={importedStyles.listItem}
                              classes={{selected: importedStyles.itemSelected}}
                              selected={window.location.pathname === '/muro-de-la-confianza' || window.location.pathname === '/confidenceWall'}
                              button key={'confidenceWall'}
                              onClick={this.handleMenuClickRestricted('/muro-de-la-confianza')}>
                        <ListItemIcon>
                            <WallOutlinedIcon alt={'wallIcon'} heigth={32} width={32}
                                              fill={window.location.pathname === '/muro-de-la-confianza' ? '#00a3da' : '#000'}/>
                        </ListItemIcon>
                        <ListItemText primary={'Muro de favoritos'}/>
                    </ListItem>
                </Fade>


                <ListItem className={importedStyles.listItem}
                          classes={{selected: importedStyles.itemSelected}}
                          selected={window.location.pathname === '/downloads' || window.location.pathname === '/mis-descargas'}
                          button key={'activar'}
                          onClick={this.handleMenuClickRestricted('/mis-descargas')}
                >
                    <ListItemIcon> <DownloadIcon/> </ListItemIcon>
                    <ListItemText primary={'Archivos descargados'}/>
                </ListItem>
            </React.Fragment>
        )
    }

    renderNormalUserMenu = () => {
        return (
            <React.Fragment>
                <ListItem
                    className={importedStyles.listItem}
                    classes={{selected: importedStyles.itemSelected}}
                    selected={window.location.pathname === '/requests'} button key={'requests'}
                    onClick={this.handleMenuClick('/requests')}>
                    <ListItemIcon> <EmojiEventsOutlined/> </ListItemIcon>
                    <ListItemText primary={'Concursos - Eventos'}/>
                </ListItem>
                {/*<ListItem className={importedStyles.listItem}*/}
                {/*          classes={{selected: importedStyles.itemSelected}}*/}
                {/*          selected={window.location.pathname === '/categories'} button key={'categories'}*/}
                {/*          onClick={this.handleMenuClick('categories')}>*/}
                {/*    <ListItemIcon> <PaletteOutlined/> </ListItemIcon>*/}
                {/*    <ListItemText primary={'¿Necesitas inspiración?'}/>*/}
                {/*</ListItem>*/}
                <ListItem className={importedStyles.listItem}
                          classes={{selected: importedStyles.itemSelected}}
                          selected={window.location.pathname === '/myVideos' || window.location.pathname === '/mis-archivos'}
                          button key={'myVideos'}
                          onClick={this.handleMenuClickRestricted('/mis-archivos')}>
                    <ListItemIcon> <VideoLibraryOutlined/> </ListItemIcon>
                    <ListItemText primary={'Mis archivos'}/>
                </ListItem>
            </React.Fragment>
        )
    }

    renderDrawerContent = () => {


        let classname = importedStyles.drawerUserContent;

        if (this.props.user && this.props.user.entity) {
            classname = importedStyles.drawerEntityContent;
        }
        return (
            <div
                className={classname}
                //className={importedStyles.drawerContent}
            >
                <List className={importedStyles.list}>


                    {this.props.user && this.props.user.entity && this.renderEntityMenu()}
                    {this.props.user && !this.props.user.entity && this.renderNormalUserMenu()}
                    {/*<ListItem className={importedStyles.listItem}*/}
                    {/*          classes={{selected: importedStyles.itemSelected}}*/}
                    {/*          selected={window.location.pathname === '/FAQS'}*/}
                    {/*          button onClick={this.handleMenuClick('FAQS')}*/}
                    {/*          key={'faq'}>*/}
                    {/*    <ListItemIcon> <HelpOutline/> </ListItemIcon>*/}
                    {/*    <ListItemText primary={'Preguntas frecuentes'}/>*/}
                    {/*</ListItem>*/}
                    <ListItem className={importedStyles.listItem}
                              classes={{selected: importedStyles.itemSelected}}
                              selected={window.location.pathname === '/profile'} button key={'profile'}
                              onClick={this.handleMenuClickRestricted('/profile')}>
                        <ListItemIcon> <PermIdentityOutlined/> </ListItemIcon>
                        <ListItemText primary={'Perfil'}/>
                    </ListItem>
                    <ListItem className={importedStyles.listItem}
                              classes={{selected: importedStyles.itemSelected}}
                              selected={window.location.pathname === '/contact'}
                              button onClick={this.handleMenuClick('/contact')}
                              key={'contact'}>
                        <ListItemIcon> <MailOutlined/> </ListItemIcon>
                        <ListItemText primary={'Contacto'}/>
                    </ListItem>
                    <hr className={importedStyles.separator}/>
                    {this.props.logged &&
                    <ListItem className={importedStyles.listItem} button onClick={this.handleLogout}
                              key={'close'}>
                        <ListItemIcon> <ExitToApp/> </ListItemIcon>
                        <ListItemText primary={'Cerrar sesión'}/>
                    </ListItem>
                    }
                    {!this.props.logged &&
                    <ListItem className={importedStyles.loginButton} button onClick={this.handleLogin}
                              key={'login'}>
                        <ListItemIcon>
                            <InputRounded/>
                        </ListItemIcon>
                        <ListItemText primary={'Iniciar Sesión'}/>
                    </ListItem>
                    }
                </List>
            </div>
        );
    };

    renderUserActionButtons = () => {

        let actualLocation = window.location.pathname;
        let actions = (
            <React.Fragment>
                <Button onClick={() => history.push('/requests')} variant={"outlined"}
                        className={importedStyles.goToRequestsButton}>
                    Retos
                </Button>
                <Button
                    onClick={() => {
                        ReactPixel.trackCustom('UploadVideoButtonClickDesktop', {from: window.location.pathname});
                        pushRoute('/uploadVideo');
                    }}
                    className={importedStyles.uploadVideoButton}>
                    Subir Archivo
                </Button>
            </React.Fragment>
        );
        if (actualLocation === '/uploadVideo' || actualLocation === '/requests' || actualLocation.split('/')[1] === 'peticiones') {
            actions = (
                <React.Fragment>
                    <Button onClick={() => history.push('/requests')} variant={"outlined"}
                            className={importedStyles.goToRequestsButton}>
                        Retos
                    </Button>
                </React.Fragment>
            );
        }

        return actions;

    };

    renderEntityActionButtons = () => {
        return false;
        return (
            <a href={'https://form.typeform.com/to/UHyiTutJ'}
               target={'_blank'} style={{textDecoration: "none"}}>
                <Button
                    // onClick={() => pushRoute('/newRequest')}
                    className={importedStyles.uploadVideoButton}>
                    Crear concurso
                </Button>
            </a>
        );
    };

    renderEntityActionButtonMobile = () => {
        return false;
        return (
            <a href={'https://form.typeform.com/to/UHyiTutJ'}
               target={'_blank'} style={{textDecoration: "none"}}>
                <Button
                    // onClick={() => pushRoute('/newRequest')}
                    className={importedStyles.uploadVideoButtonMobile}>
                    Crear concurso
                </Button>
            </a>
        );

    };

    renderDrawerContentLanding = () => {
        return null;

    };


    render() {
        let navbarClass = importedStyles.anchorRightDrawerUser;

        if (this.props.user && this.props.user.entity) {
            navbarClass = importedStyles.anchorRightDrawerEntity;
        }

        return (
            <div className={importedStyles.Navbar}>
                <Hidden mdUp>
                    <SwipeableDrawer
                        anchor="right"
                        open={this.state.openDrawer}
                        onClose={this.toggleDrawer(false)}
                        onOpen={this.toggleDrawer(true)}
                        classes={{
                            paperAnchorRight: navbarClass
                        }}
                        className={importedStyles.drawer}
                        disableEnforceFocus
                    >
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={this.toggleDrawer()}
                            onKeyDown={this.toggleDrawer()}
                        >
                            {/*{this.props.logged ? this.renderDrawerContentLogged() : this.renderDrawerContentLanding()}*/}
                            {this.renderDrawerContent()}
                        </div>
                    </SwipeableDrawer>
                </Hidden>

                <GrabaloIcon className={importedStyles.logo}/>


                <Hidden mdUp>
                    {this.state.openDrawer ?
                        <IconButton
                            aria-label="Cerrar menu lateral"
                            onClick={this.toggleDrawer()}
                            className={importedStyles.closeDrawerButton}
                        >
                            <CloseRounded fontSize={'large'}/>
                        </IconButton>
                        :
                        <IconButton
                            aria-label="Abrir menu lateral"
                            onClick={this.toggleDrawer()}
                            className={importedStyles.menuButton}
                        >
                            <MenuIcon className={importedStyles.menuIcon}/>
                        </IconButton>
                    }
                </Hidden>
                <Hidden smDown>
                    <div id={'actionButtons'} className={importedStyles.actionButtonsContainer}>
                        {(this.props.user && this.props.user.entity) ? this.renderEntityActionButtons() : this.renderUserActionButtons()}
                        <IconButton className={importedStyles.profileIconButton}
                                    onClick={this.handleMenuClickRestricted('/profile')}>
                            <ProfileIcon className={importedStyles.profileIcon}/>
                        </IconButton>
                    </div>
                </Hidden>

            </div>
        );
    }
}

const mapStateToProps = ({authReducer, SuggestedLoginModalReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};

NavBar.propTypes = {
    searchFunction: PropTypes.func,
};

export default connect(mapStateToProps)((NavBar));
