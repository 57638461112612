import {
    CLOSE_REGISTER_REQUEST_MODAL,
    OPEN_REGISTER_REQUEST_MODAL,
} from "../../../../../constants/action-types";


const initialState = {
    open: false,
    nextRoute: '.',
};

const registerModalRequestReducer = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_REGISTER_REQUEST_MODAL:
            return {...state, open: true, nextRoute: action.nextRoute};
        case CLOSE_REGISTER_REQUEST_MODAL:
            return {...state, open: false, nextRoute: '.'};
        default:
            return state;
    }
};


export default registerModalRequestReducer;