import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import importedStyles from './FeaturedVideos.module.sass';
import history from "../../helpers/history";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import UploadVideoButton from "../../components/UploadVideoButton/UploadVideoButton";
import Skeleton from 'react-loading-skeleton';
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import Snack from "../../components/Snack/Snack";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackRounded} from "@material-ui/icons";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import SuggestedLoginModal from "../../components/SuggestedLoginModal/SuggestedLoginModal";
import UploadRetoButton from "../../components/UploadRetoButton/UploadRetoButton";
import {cleanTerm} from "../ListVideos/redux/searchActions";
import DetailVideoModal from "../DetailVideoModal/DetailVideoModal";
import Fade from "@material-ui/core/Fade";
import VideoCard from "../../components/VideoCardOld/VideoCardOld";
import {Switch} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import {Helmet} from "react-helmet/es/Helmet";
import {videosPerPage} from "../../constants/constants";


class FeaturedVideos extends React.Component {

    state = {
        videos: {},
        waitingResponse: true,
        orientation: null,
        order: null,
        openDetailVideoModal: false,
        selectedVideo: null,
        nextVideo: null,
        prevVideo: null,
        actualVideoIndex: null,
        testTitles: false,
        currentPage: 1,
    };

    componentDidMount = async () => {
        ReactPixel.trackCustom('VideosDestacados');
        ReactGA.pageview(window.location.pathname);
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getVideos();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.UrlParamsObserver(prevProps);
    }

    UrlParamsObserver = prevProps => {
        if (!this.props.match.params.videoId && prevProps.match.params.videoId) {
            this.setState({openDetailVideoModal: false, selectedVideo: null, actualVideoIndex: null});
        } else {
            if (this.props.match.params.videoId !== prevProps.match.params.videoId) {
                this.handleUrlVideoParam();
            }
        }
    };

    componentWillUnmount() {
        this.props.dispatch(cleanTerm());
    }

    handleUrlVideoParam = () => {
        let urlVideoId = this.props.match.params.videoId;
        if (urlVideoId) {
            let videosCollection = this.state.videos;
            let indexOfRequestedVideo = videosCollection.findIndex(item => item.urlSlug === urlVideoId);
            let video = videosCollection[indexOfRequestedVideo];
            this.setState({openDetailVideoModal: true, selectedVideo: video, actualVideoIndex: indexOfRequestedVideo});
        }
    };

    getVideos = () => {

        this.setState({
            waitingResponse: true
        });

        let bodyData = '';

        if (this.props.user){
            bodyData = JSON.stringify({userUuid: this.props.user.uuid, currentPage: this.state.currentPage});
            if (this.props.user.entity){
                bodyData = JSON.stringify({entityId: this.props.user.uuid, currentPage: this.state.currentPage});

            }
        }


        fetch(apiBaseUrl + 'videos/listFeaturedVideos', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: bodyData
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            videos: response.videos,
                            waitingResponse: false,
                            totalVideosCount: response.totalVideosCount
                        });
                        this.handleUrlVideoParam();
                    } else {
                        throw new Error(response.errorMessage);
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };

    checkIfVideoIsFavorite = (video) => {
        if (this.props.user && this.props.user.entity){
            return video.favourite_videos && video.favourite_videos.length > 0
        } else if (this.props.user) {
            return video.favourite_user_videos && video.favourite_user_videos.length > 0
        }
    };

    handleTest = () => {
        this.setState({testTitles: !this.state.testTitles});
    };
    handlePageChange = (event, page) => {
        this.setState({currentPage: page}, () => {
            this.getVideos();
        });
    };

    renderFreeTag = () => {
        return (
            <span className={importedStyles.freeTag}>GRATIS</span>
        )
    };
    renderSkeletonsOld = () => {

        let skeletons = [];

        for (let i = 0; i < 8; i++) {
            skeletons.push(
                <React.Fragment>
                    <Grid item xs={6} md={3} className={importedStyles.skeletonContainer}>
                        <Skeleton height={'100%'} width={'100%'}/>
                    </Grid>
                    <Grid item xs={6} md={3} className={importedStyles.skeletonContainer}>
                        <Skeleton count={5} height={10} width={'100%'}/>
                    </Grid>
                </React.Fragment>
            )
        }

        return (
            <Fade direction={'top'} unmountOnExit mountOnEnter in={this.state.waitingResponse}
                  timeout={400}>
                <Grid container spacing={6} style={{margin: -12}}>
                    {skeletons}
                </Grid>
            </Fade>
        );
    };
    renderSkeletons = () => {

        let skeletons = [];

        for (let i = 0; i < 9; i++) {
            skeletons.push(
                <Grid item xs={12} md={4} className={importedStyles.skeletonContainer}>
                    <Skeleton height={'100%'} width={'100%'}/>
                </Grid>
            )
        }

        return (
            <Fade direction={'top'} unmountOnExit mountOnEnter in={this.state.waitingResponse}
                  timeout={{enter: 400, exit: 20}}>
                <React.Fragment>
                    {skeletons}
                </React.Fragment>
            </Fade>
        );
    };
    renderVideos = () => {
        const videos = this.state.videos;
        let videoCards = [];

        let i = 1;
        Object.keys(videos).forEach((key) => {

            i < 10 && i++; //Increment counter if actual video is below the 10th first

            let authorName = '-';
            let video = videos[key];
            let tagsView = [];

            let videoIsFree = parseInt(video.price) <= 0;
            Object.keys(video.tags).forEach((indexTag) => {
                if (video.tags[indexTag].text.length < 15) {
                    tagsView.push(<p key={indexTag}>{videos[key].tags[indexTag].text}</p>);
                }
            });

            if (video.user && video.user.name) {
                let fragments = video.user.name.split(" ");
                if (fragments.length > 1) {
                    authorName = fragments[0] + ' ' + fragments[1].substr(0, 1) + '.';
                } else {
                    authorName = fragments[0]
                }
            }

            videoCards.push(
                <Fade unmountOnExit mountOnEnter in={!this.state.waitingResponse}
                      timeout={{enter: i * 200, exit: 0}}>
                    <Grid className={importedStyles.videoCard} key={key} item xs={12} md={4}>
                        <VideoCard
                            onClick={this.handleDetailVideoModal(video, key)}
                            video={video}
                            freeTag={videoIsFree}
                            tags={video.tags}
                            title={video.title}
                            author={authorName}
                            isFavorite={this.checkIfVideoIsFavorite(video)}
                            date={video.uploadDate}
                            image={video.coverUrl}
                            titleTruncation={1}
                            showTitle={this.state.testTitles}
                        />
                    </Grid>
                </Fade>
            )
        });


        if (videoCards.length === 0 && !this.state.waitingResponse) {
            return (
                <p style={{margin: 20}}>Oops, parece que nuestros servidores están teniendo un dia duro, prueba de nuevo
                    en unos minutos, gracias.</p>
            );
        } else {
            return videoCards;
        }
    };


    handleDetailVideoModal = (video, actualVideoIndex) => () => {
        ReactPixel.trackCustom('SelectVideo', {selectedVideo: video.urlSlug});
        this.setState({openDetailVideoModal: true, selectedVideo: video, actualVideoIndex: actualVideoIndex});
        history.push(this.props.location.pathname + '/' + video.urlSlug + history.location.search);
    };
    closeDetailVideoModal = () => {
        this.setState({openDetailVideoModal: false, selectedVideo: null, actualVideoIndex: null});
        history.replace('/destacados');
    };

    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
    };

    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
    };

    renderPagination = () => {
        let totalPages = parseInt(this.state.totalVideosCount / videosPerPage).toString();
        let isMobile = window.matchMedia("(max-width: 768px)").matches ? 'small' : 'medium';
      return (
          <Grid item xs={12} className={importedStyles.paginatorContainer}>
              <Pagination
                  count={totalPages}
                  page={this.state.currentPage} onChange={this.handlePageChange}
                  boundaryCount={1}
                  siblingCount={1}
                  size={isMobile}
                  showFirstButton
                  showLastButton
              />
          </Grid>
      )
    };


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Vídeos Destacados</title>
                </Helmet>
                <NavBar searchFunction={this.getVideos}/>
                <UploadVideoButton/>
                <DesktopDrawer/>
                <Snack/>
                <div className={importedStyles.listVideos}>
                    <div className={importedStyles.titleContainer}>
                        {/*<BackButton/>*/}
                        <IconButton className={importedStyles.backButton} onClick={() => history.push('/categories')}>
                            <ArrowBackRounded className={importedStyles.arrowBack}/>
                        </IconButton>
                        <h2 className={importedStyles.titleCategory}>Vídeos destacados</h2>
                        <FormControlLabel
                            style={{display: 'none'}}
                            control={
                                <Switch onChange={this.handleTest} checked={this.state.testTitles}/>
                            }
                            label={'Probar con titulos debajo'}
                        />
                    </div>

                    <Grid container spacing={6} className={importedStyles.videosContainer} direction={'row'}>
                        {this.state.waitingResponse && this.renderSkeletons()}
                        {this.state.videos && this.renderVideos()}
                        {this.props.logged && this.props.user.entity && this.renderPagination()}

                    </Grid>
                </div>
                <UploadRetoButton/>
                <SuggestedLoginModal/>
                {this.state.selectedVideo &&
                <DetailVideoModal
                    open={this.state.openDetailVideoModal}
                    onClose={this.closeDetailVideoModal}
                    video={this.state.selectedVideo}
                    actualVideoIndex={this.state.actualVideoIndex}
                    lastVideoIndex={this.state.videos.length - 1}
                    handlePrevVideo={this.handlePrevVideo}
                    handleNextVideo={this.handleNextVideo}
                />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, categoriesReducer, searchReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        category: categoriesReducer.categoryActive,
        term: searchReducer.term,
        orientation: searchReducer.orientation,
        order: searchReducer.order
    });
};
export default connect(mapStateToProps)(FeaturedVideos);
