import {SET_REQUEST, SET_UPLOAD_REQUEST, SET_VIDEO_TO_REQUEST_FLAG} from "../../../constants/action-types";

export const setRequest = request => {
    return ({
        type: SET_REQUEST,
        request: request
    });

};

export const setVideoToRequestFlag = status => {
    return ({
        type: SET_VIDEO_TO_REQUEST_FLAG,
        videoToRequestFlag: status
    });

};

export const setUploadRequest = uploadRequest => {
    return ({
        type: SET_UPLOAD_REQUEST,
        uploadRequest: uploadRequest
    });

};
