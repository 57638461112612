import {CLEAN_TERM, SET_ORDER, SET_ORIENTATION, SET_TERM} from "../../../constants/action-types";

export const setOrientation = orientation => {
    return ({
        type: SET_ORIENTATION,
        orientation: orientation
    });
};

export const setOrder = order => {
    return ({
        type: SET_ORDER,
        order: order
    });
};

export const setTerm = term => {
    return ({
        type: SET_TERM,
        term: term
    });
};
export const cleanTerm = () => {

    return ({
        type: CLEAN_TERM,
    });

};
