import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './UploadVideo.module.sass';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ChipInput from "../../../components/ChipInput/ChipInput";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import {ReactComponent as AddIcon} from '../../../icons/round-add_circle-24px.svg';
import InternetIcon from '../../../icons/uploadVideoHelpers/internet.svg';
import CameraIcon from '../../../icons/uploadVideoHelpers/camera.svg';
import BadLightIcon from '../../../icons/uploadVideoHelpers/badLight.svg';
import MoveIcon from '../../../icons/uploadVideoHelpers/mobileMovement.svg';
import MusicIcon from '../../../icons/uploadVideoHelpers/music.svg';
import ZoomIcon from '../../../icons/uploadVideoHelpers/zoom.svg';
import VideogameIcon from '../../../icons/uploadVideoHelpers/videogame.svg';
import RrssIcon from '../../../icons/uploadVideoHelpers/rrss.svg';
import SquareIcon from '../../../icons/uploadVideoHelpers/squareFormat.svg';
import RotationIcon from '../../../icons/uploadVideoHelpers/rotation.svg';
import history from "../../../helpers/history";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import {Close} from '@material-ui/icons';
import axios from 'axios';
// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import Snack from "../../../components/Snack/Snack";
import {setRequest, setVideoToRequestFlag} from "../../RequestFiles/Redux/requestFilesActions";
import {Dialog, DialogContent, DialogTitle, LinearProgress} from "@material-ui/core";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import Swal from 'sweetalert2';
import GettingMetadataAlert from "../../../components/GettingMetadataAlert/GettingMetadataAlert";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {Cookies} from "react-cookie";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import SnackHelper from "../../../components/SnackHelper/SnackHelper";
import {Helmet} from "react-helmet/es/Helmet";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

let isMobile = window.innerWidth <= 768;

class UploadVideo extends React.Component {

    state = {
        title: '',
        tags: [],
        helperModal: false,
        isToRequest: false,
        buyableByAll: false,
        request: this.props.request ? this.props.request : '-',
        requests: [],
        video: [],
        uploadProgress: 0,
        waitingResponse: false,
        gettingMetadataFromServer: false
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        ReactPixel.trackCustom('UploadVideoView', {user: this.props.user.uuid});
        ReactGA.pageview(window.location.pathname);
        this.setState({isToRequest: this.props.videoToRequestFlag});


        //If there is no requests coming from props, call server to get it
        if (this.state.requests.length < 1) {
            this.getRequests()
        }
    };

    getRequests = () => {

        this.setState({
            waitingResponse: true
        });

        fetch(apiBaseUrl + 'requests/getRequests', {
            method: 'GET',
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            requests: response.requests,
                            waitingResponse: false,
                        });
                    } else {
                        switch (response.errorType) {
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error al cargar, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            ).catch((error) => {
            this.setState({waitingResponse: false});
        })
    };

    componentWillUnmount() {
        this.props.dispatch(setVideoToRequestFlag(false));
        this.props.dispatch(setRequest(null));

    }

    handleInputChange = (e) => {
        let value = e.target.value;
        if (e.target.name === 'title') {
            value = value.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
        }
        this.setState({[e.target.name]: value});
    };
    handleRequestSelect = (e) => {
        let value = e.target.value;
        this.props.dispatch(setRequest(value));
        this.setState({[e.target.name]: value});
    };

    handleHelperModal = () => {
        this.setState({helperModal: !this.state.helperModal})
    };

    handleCheckChange = (e, checked) => {
        this.setState({[e.target.name]: checked});
    };

    handleUpload = () => {
        this.setState({
            waitingResponse: true
        });

        let title = this.state.title;
        let tags = JSON.stringify(this.state.tags);
        let userId = this.props.user.uuid;
        let video = this.state.video ? this.state.video : '';
        let form = new FormData();
        form.append('video', video);
        form.append('title', title);
        form.append('tags', tags);
        form.append('userId', userId);
        form.append('isToRequest', this.state.isToRequest);

        //Necesitamos negar la flag buyableByAll ya que ha cambiado su sentido completamente, ahora cuando esté a TRUE significa que NO se puede comprar por otras marcas
        if (this.state.isToRequest) {
            form.append('requestId', this.state.request.uuid);
            form.append('buyableByAll', this.state.request.isContentExclusive ? 'false' : !this.state.buyableByAll);
        } else {
            form.append('buyableByAll', !this.state.buyableByAll);
        }

        let config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({uploadProgress: percentCompleted});
            }
        };

        axios.post(apiBaseUrl + 'videos/insertNewVideo', form, config).then((response) => {
            if (!response.error) {
                ReactPixel.trackCustom('UploadVideoSuccess', {title: title});
                ReactGA.ga('send', 'event', 'video', 'UploadVideoSuccess');
                this.setState({
                    title: '',
                    tags: [],
                    waitingResponse: false,
                    uploadProgress: 0
                });
                if (this.state.request.uuid) {
                    if (this.state.request.isContentExclusive || !this.state.request.needsModeration) {
                        this.props.dispatch(showSnack('¡Gracias por subir tu vídeo a este reto!', snackTypes.success));
                    }
                } else {
                    this.props.dispatch(showSnack('¡Gracias por subir tu vídeo! Antes de 48 horas será revisado y te enviaremos un correo informativo.', snackTypes.success));
                }

                history.push('/categories');
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            ReactPixel.trackCustom('UploadVideoError', {error: error});
            ReactGA.ga('send', 'event', 'video', 'UploadVideoError', 'Error: ' + error + '');

            this.setState({waitingResponse: false, isLoaded: true, uploadProgress: 0});
            this.props.dispatch(showSnack('Error al subir el vídeo, vuelva a intentarlo', snackTypes.error));
        });
    };

    renderRequestOptions = () => {
        let requestCards = [];
        requestCards.push(
            <MenuItem key={'0'} value={'-'}><em style={{color: "#000000"}}>* Elige en que reto quieres
                participar</em></MenuItem>
        );

        if (this.state.requests.length > 0) {
            Object.keys(this.state.requests).forEach((index) => {
                const request = this.state.requests[index];
                let title = request.title;
                if (request.title.length > 30 && window.matchMedia("(max-width: 768px)").matches) {
                    title = title.substr(0, 30) + '...';
                }
                requestCards.push(
                    <MenuItem key={request.uuid} value={request}>{title}</MenuItem>
                );
            });
        }

        return requestCards;
    };

    handleInitFilePond = () => {
        console.log('FilePond instance has initialised', this.pond);
    };

    renderProgressBar = () => {
        return (
            <div className={importedStyles.progressBarContainer}>
                <div className={importedStyles.progressBarMask}/>
                <LinearProgress
                    variant="determinate"
                    className={importedStyles.progressBar}
                    classes={{barColorPrimary: importedStyles.currentProgress}}
                    value={this.state.uploadProgress}
                />
                <span className={importedStyles.progressBarPercent}>{this.state.uploadProgress}%</span>
            </div>
        );
    };

    renderUploadButton = () => {
        return (
            <Button
                id={'uploadButton'}
                className={importedStyles.submitButton}
                fullWidth
                variant='contained'
                onClick={this.handleUpload}
                disabled={this.state.video.length === 0 || !this.state.title || this.state.tags.length < 3 || (this.state.isToRequest && !this.state.request.uuid)}
            >
                SUBIR VÍDEO

            </Button>
        );
    };

    getVideoDataFromServer = video => {

        this.setState({gettingMetadataFromServer: true});
        let form = new FormData();
        form.append('video', video);

        let config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({uploadProgress: percentCompleted});
            }
        };

        axios.post(apiBaseUrl + 'videos/checkVideoDimensions', form, config).then((response) => {
            if (!response.data.error) {
                if (response.data.width < 720 || response.data.height < 720) {
                    Swal.fire({
                        title: 'Oops! Tu vídeo tiene una calidad por debajo de la recomendada (720p).',
                        text: "Es posible que no sea el vídeo original, seguramente porque viene de una de tus redes sociales y el vídeo ha sufrido una alta compresión. Si aún tienes el original en tu galería nos encantaría recibirlo 😉",
                        icon: 'warning',
                        //showCancelButton: true,
                        //cancelButtonText: 'Continuar',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#e2e2e2',
                        confirmButtonText: 'Subir el original'
                    }).then((result) => {
                        this.pond.removeFile(); // delete filepond instance with bad video
                        if (result.value) {
                            this.pond.removeFiles()
                        }
                    });
                }
                this.setState({
                    gettingMetadataFromServer: false,
                    videoWidth: response.data.width,
                    videoHeigth: response.data.heigth
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.setState({uploadProgress: 0, gettingMetadataFromServer: false});
        });
    };

    // DEPRECATED ??
    onFilePondFileUpdate = fileItems => {

        if (fileItems.length > 0) {
            const video = fileItems.map(fileItem => fileItem.file);
            const acceptedFileTypes = ['video/mp4', 'video/x-matroska', 'video/x-flv', 'video/quicktime', 'video/x-msvideo', 'video/msvideo',
                'video/x-ms-wmv', 'video/x-ms-asf', 'video/avi', 'video/x-quicktime', 'video/ogg'];
            if (acceptedFileTypes.includes(video[0].type)) {
                this.setState({video: video});

                //get metaData
                const url = URL.createObjectURL(video[0]);
                const videoEl = document.createElement('video');

                videoEl.onloadedmetadata = evt => {
                    // Revoke when you don't need the url any more to release any reference
                    URL.revokeObjectURL(url);

                    if (videoEl.videoWidth <= 0 || videoEl.videoWidth === null) {
                        if (!this.state.gettingMetadataFromServer) {
                            this.getVideoDataFromServer(video[0]);
                        }
                    } else {
                        if (videoEl.videoWidth < 720 || videoEl.videoHeight < 720) {
                            this.pond.removeFile(); // delete filepond instance with bad video
                            Swal.fire({
                                title: 'Oops! Tu vídeo tiene una calidad por debajo de la recomendada (720p).',
                                text: "Es posible que no sea el vídeo original, seguramente porque viene de una de tus redes sociales y el vídeo ha sufrido una alta compresión. Si aún tienes el original en tu galería nos encantaría recibirlo 😉",
                                icon: 'warning',
                                //showCancelButton: true,
                                //cancelButtonText: 'Continuar',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#e2e2e2',
                                confirmButtonText: 'Subir el original'
                            }).then((result) => {
                                if (result.value) {
                                    this.pond.removeFiles()
                                }
                            })
                        }
                    }


                };
                videoEl.src = url;
                videoEl.load(); // fetches metadata
            } else {
                this.pond.removeFile();
                this.props.dispatch(showSnack('Lo sentimos, prueba con otro formato de vídeo', snackTypes.error));
            }
        }

    };

    onFileAdd = () => {
        const video = this.pond.getFile().file;
        const acceptedFileTypes = ['video/mp4', 'video/x-matroska', 'video/x-flv', 'video/quicktime', 'video/x-msvideo', 'video/msvideo',
            'video/x-ms-wmv', 'video/x-ms-asf', 'video/avi', 'video/x-quicktime', 'video/ogg'];
        if (acceptedFileTypes.includes(video.type)) {
            this.setState({video: video});

            //get metaData
            const url = URL.createObjectURL(video);
            const videoEl = document.createElement('video');

            videoEl.onloadedmetadata = evt => {
                // Revoke when you don't need the url any more to release any reference
                //URL.revokeObjectURL(url);

                if (videoEl.videoWidth <= 0 || videoEl.videoWidth === null) {
                    if (!this.state.gettingMetadataFromServer) {
                        this.getVideoDataFromServer(video);
                    }
                } else {
                    if (videoEl.videoWidth < 720 || videoEl.videoHeight < 720) {
                        this.pond.removeFile(); // delete filepond instance with bad video
                        Swal.fire({
                            title: 'Oops! Tu vídeo tiene una calidad por debajo de la recomendada (720p).',
                            text: "Es posible que no sea el vídeo original, seguramente porque viene de una de tus redes sociales y el vídeo ha sufrido una alta compresión. Si aún tienes el original en tu galería nos encantaría recibirlo 😉",
                            icon: 'warning',
                            //showCancelButton: true,
                            //cancelButtonText: 'Continuar',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#e2e2e2',
                            confirmButtonText: 'Subir el original'
                        }).then((result) => {
                            if (result.value) {
                                this.pond.removeFiles()
                            }
                        })
                    }
                }


            };
            videoEl.id = "videoPreview";
            videoEl.src = url;
            videoEl.controls = true;
            videoEl.style.width = '100%';
            videoEl.style.maxHeight = '25rem';
            videoEl.style.background = '#000000';

            const parentDiv = document.getElementById("uploadVideoContainer");
            parentDiv.appendChild(videoEl);

            videoEl.load(); // fetches metadata

        } else {
            this.pond.removeFile();
            this.props.dispatch(showSnack('Lo sentimos, prueba con otro formato de vídeo', snackTypes.error));
        }


    };

    onFileRemove = () => {
        const videoPreview = document.getElementById("videoPreview");
        videoPreview.parentElement.removeChild(videoPreview);
        this.setState({video: []});
    };

    renderBuyableByAllCheckbox = () => {
        if (this.state.request && !this.state.request.isContentExclusive) {
            return (
                <React.Fragment>
                    <FormControlLabel control={
                        <Checkbox
                            className={importedStyles.buyableForAllCheckbox}
                            onChange={this.handleCheckChange}
                            checked={this.state.buyableByAll}
                            name='buyableByAll'
                        />
                    } label={'No quiero que otras marcas puedan comprar mi vídeo'}
                    />
                    <FormHelperText>Marca esta casilla si no quieres que tu vídeo sea utilizado por más marcas aparte de
                        la actual retadora</FormHelperText>
                </React.Fragment>

            )
        }
    };

    renderRequestSelect = () => {
        return (
            <div>
                <Select value={this.state.request}
                        onChange={this.handleRequestSelect}
                        disabled={this.props.videoToRequestFlag}
                        displayEmpty
                        input={<Input fullWidth className={importedStyles.inputSelect} name="request"
                                      placeholder={'Reto'}/>}
                >
                    {this.renderRequestOptions()}
                </Select>
                {this.renderBuyableByAllCheckbox()}
            </div>

        );
    };

    renderForm = () => {

        let filepondPlaceholder = isMobile ? 'Pulsa aquí y busca el vídeo que quieres subir' : 'Arrastra o pulsa aquí y busca el vídeo que quieres subir';
        return (
            <React.Fragment>
                <div className={importedStyles.form}>
                    <div id={'uploadVideoContainer'}>
                        <AddIcon onClick={() => this.pond.browse()} className={importedStyles.addIcon}/>
                        <FilePond maxFileSize={'1024MB'}
                                  labelMaxFileSizeExceeded={'El vídeo es demasiado grande'}
                                  labelMaxFileSize={'No puede pesar más de 1Gb'}
                                  className={importedStyles.filepond}
                                  labelIdle={filepondPlaceholder}
                                  labelFileTypeNotAllowed={'Lo sentimos, prueba con otro archivo'}
                                  fileValidateTypeLabelExpectedTypes={'Lo sentimos, prueba con otro archivo'}
                                  acceptedFileTypes={['video/*']}
                                  ref={ref => this.pond = ref}
                                  oninit={this.handleInitFilePond}
                            //onupdatefiles={fileItems => this.onFilePondFileUpdate(fileItems)}
                                  onaddfile={this.onFileAdd}
                                  onremovefile={this.onFileRemove}
                        />
                    </div>
                    <p onClick={() => {
                        ReactPixel.trackCustom('openTipsUploadVideo');
                        ReactGA.ga('send', 'event', 'click', 'openTipsUploadVideo');
                        this.handleHelperModal()
                    }}
                       className={importedStyles.textHelper}>Ver consejos para triunfar</p>

                    <SnackHelper text={'Ejemplo: Mi perro jugando en el parque'}/>

                    <TextField
                        margin="normal"
                        id="title"
                        name="title"
                        label="* Título"
                        type="text"
                        inputProps={{maxLength: 100}}
                        value={this.state.title}
                        fullWidth
                        onChange={this.handleInputChange}
                        onKeyPress={(e) => {
                            if (e.which === 13) {
                                document.getElementById('uploadButton').click();
                            }
                        }}
                    />
                    <SnackHelper text={'Ejemplo: #perro #parque #diversión #mascota #animal #atardecer #paseo'}/>

                    <ChipInput
                        value={this.state.tags}
                        maxChipLength={90}
                        placeholder={'Añade almenos 3 hashtags, ¿Dónde lo grabaste? ¿Qué se ve en el vídeo? ¿Qué estado de ánimo te despierta? Cuantos más hashtags, más empresas verán tu vídeo.'}
                        helperText={'Separa los hashtags por comas'}
                        chipKeys={[',', '.', ' ']}
                        onChange={(chips) => this.setState({tags: chips})}
                    />
                    {/*<FormControlLabel
                        disabled={this.props.videoToRequestFlag}
                        control={
                            <Checkbox
                                classes={{disabled: importedStyles.disabledCheckbox}}
                                className={importedStyles.isToRequestCheckbox}
                                onChange={this.handleCheckChange}
                                checked={this.state.isToRequest}
                                disabled={this.props.videoToRequestFlag}
                                name='isToRequest'
                            />
                        }
                        label={'Mí vídeo es para un reto'}
                    />
                    {this.state.isToRequest && this.renderRequestSelect()}*/}
                </div>
                {this.state.waitingResponse ? this.renderProgressBar() : this.renderUploadButton()}
            </React.Fragment>

        );

    };

    renderHurrify1 = () => {
        let cookies = new Cookies();
        let hurrify1Cookie = cookies.get('hurrify1Cookie');
        let randomNumber = 0;

        if (hurrify1Cookie) {
            randomNumber = hurrify1Cookie;
        } else {
            randomNumber = Math.floor(Math.random() * (90 - 30) + 30);
            let expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 1000 * 60 * 60 * 24); //24h in miliseconds
            cookies.set('hurrify1Cookie', randomNumber, {path: '/', expires: expireDate});
        }

        return (<div className={importedStyles.hurrify1}>{randomNumber} personas ya han vendido sus vídeos hoy</div>);

    };

    renderHelperModal = () => {
        return (
            <div>
                <Dialog
                    maxWidth={"lg"}
                    fullWidth
                    fullScreen={isMobile}
                    scroll={"body"}
                    open={this.state.helperModal}
                >
                    <DialogContent className={importedStyles.helperModalContent}>
                        <DialogTitle className={importedStyles.helperModalTitle}>
                            Recomendaciones
                            <IconButton className={importedStyles.closeModalIcon} onClick={this.handleHelperModal}>
                                <Close/>
                            </IconButton>
                        </DialogTitle>
                        <Grid container className={importedStyles.helpersGrid}>
                            <Grid item xs={6} md={4} key={'internet'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={InternetIcon} alt={'internet icon'} width={70}/>
                                </div>
                                <p className={importedStyles.listText}>No aceptamos vídeos descargados de Internet, solo
                                    vídeos tuyos</p>
                            </Grid>
                            <Grid item key={'camera'} xs={6} md={4} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={CameraIcon} alt={'camera icon'} width={70}/>
                                </div>
                                <p className={importedStyles.listText}>No aceptamos vídeos que parezcan de bancos de
                                    vídeos
                                    profesionales</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'square'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={SquareIcon} alt={'sqr icon'} width={70}/>
                                </div>
                                <p className={importedStyles.listText}>No aceptamos vídeos en formatos cuadrados, solo
                                    en
                                    horizontal o vertical</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'videogame'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={VideogameIcon} alt={'videogame icon'} width={80}/>
                                </div>
                                <p className={importedStyles.listText}>No aceptamos grabaciones de pantalla
                                    (videojuegos,
                                    tv...)</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'light'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={BadLightIcon} alt={'badlight icon'} width={70}/>
                                </div>
                                <p className={importedStyles.listText}>Si el vídeo tiene poca o mala luz, la calidad de
                                    la
                                    imagen no será buena</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'music'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={MusicIcon} alt={'music icon'} width={70}/>
                                </div>
                                <p className={importedStyles.listText}>No aceptamos vídeos con música añadida en la
                                    edición
                                    del vídeo, queremos el sonido ambiente</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'zoom'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={ZoomIcon} alt={'zoom icon'} width={70}/>
                                </div>
                                <p className={importedStyles.listText}>Cuando hacemos mucho zoom, el vídeo se pixela y
                                    la
                                    imagen pierde calidad</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'move'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={MoveIcon} alt={'move icon'} width={70}/>
                                </div>
                                <p className={importedStyles.listText}>Si el móvil tiene mucho movimiento, el vídeo no
                                    se
                                    verá bien</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'rotation'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={RotationIcon} alt={'rotation icon'} width={80}/>
                                </div>
                                <p className={importedStyles.listText}>No cambies de horizontal a vertical en mitad del
                                    vídeo</p>
                            </Grid>
                            <Grid item xs={6} md={4} key={'rrss'} className={importedStyles.listItemHelper}>
                                <div className={importedStyles.listIcon}>
                                    <img src={RrssIcon} alt={'rrss icon'} width={80}/>
                                </div>
                                <p className={importedStyles.listText}>No aceptamos vídeos descargados de otras redes
                                    sociales, solo tus originales</p>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Subir vídeo</title>
                </Helmet>
                <NavBar/>
                <Snack/>
                <DesktopDrawer/>
                <div className={importedStyles.container}>
                    <div className={importedStyles.UploadVideo}>
                        {this.state.isToRequest && this.props.request && this.props.request.title &&
                        <h2 className={importedStyles.titleVideoRequest}>
                            Subiendo vídeo para el reto <b>{this.props.request.title}</b>
                        </h2>
                        }
                {/*        {this.renderHurrify1()}*/}

                        {(this.props.logged && !this.props.user.entity) && this.renderForm()}
                    </div>
                </div>
                {this.renderHelperModal()}
                <GettingMetadataAlert open={this.state.gettingMetadataFromServer}
                                      uploadProgress={this.state.uploadProgress}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, requestFilesReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        request: requestFilesReducer.request,
        videoToRequestFlag: requestFilesReducer.videoToRequestFlag,
    });
};
export default connect(mapStateToProps)(UploadVideo);
