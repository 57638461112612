import React from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import Button from "@material-ui/core/Button/Button";
import importedStyles from './VideoSoldAlert.module.sass';
import connect from "react-redux/es/connect/connect";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import Lottie from "react-lottie";
import videoSoldCongrats from "../../animations/videoSoldCongrats"
import trophyAnimation2 from "../../animations/trofeo2"
import {Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";

class VideoSoldAlert extends React.Component {

    state = {
        showModal: false,
        currentStep: 1
    };


    componentDidMount() {
        if (this.props.logged) {
            this.checkShowModalVideoSold();
        }

    }

    handleModalClose = () => {
        this.setState({showModal: false});
        this.disableShowModalVideoSold(); //After notify user, set showModalVideoSold property to 0/false on DB
    };

    checkShowModalVideoSold = () => {
        fetch(apiBaseUrl + 'users/checkShowModalVideoSold', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userUuid: this.props.user.uuid}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                if (response.showModalVideoSold) {
                    this.setState({showModal: true});
                }

            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
        });
    };
    disableShowModalVideoSold = () => {
        fetch(apiBaseUrl + 'users/disableShowModalVideoSold', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userUuid: this.props.user.uuid}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (response.error) {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error(error);
        });
    };
    renderAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: videoSoldCongrats,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    speed={0.5}
            />
        )
    };
    renderTrophyAnimation = () => {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: trophyAnimation2,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    speed={0.5}
            />
        )
    };

    handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'web.dev',
                text: 'Check out web.dev.',
                url: 'https://web.dev/',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            alert('no share');
        }
    };

    handleNextStep = () => {
        this.setState({currentStep: 2});

        let element = document.createElement('a');
        element.setAttribute('href', './grabaloVideoVendido.png');
        element.setAttribute('download', 'grabaloVideoVendido.png');

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    renderFirstStep = () => {
        return (
            <DialogContent className={importedStyles.dialogContent}>
                <DialogContentText>
                    {this.renderAnimation()}

                    <Typography variant={"h4"} className={importedStyles.congratulations}>¡ENHORABUENA!</Typography>
                    <Typography variant={"h6"} className={importedStyles.selledVideoCopy}>Has vendido un
                        vídeo</Typography>
                    <ul>
                        <li>Pulsa el botón 'VER LOGRO'</li>
                        <li>Descarga la imagen o haz una captura</li>
                        <li>Compártelo en tus RRSS</li>
                        <li>Enseña al mundo tu logro</li>
                    </ul>
                </DialogContentText>

                <DialogActions>
                    <Button variant={"contained"} className={importedStyles.showLogroButton} onClick={this.handleNextStep}>
                        VER LOGRO
                    </Button>
                </DialogActions>
            </DialogContent>
        )
    };

    renderSecondStep = () => {
        return (
            <DialogContent className={importedStyles.dialogContent}>
                <DialogContentText>
                    <img
                        className={importedStyles.trophyImage}
                        src={'./grabaloVideoVendido.png'}
                        alt={'Imagen logro conseguido venta'}
                    />
                </DialogContentText>

            </DialogContent>
        )
    };

    renderContent = () => {
        switch (this.state.currentStep) {
            case 1:
                return this.renderFirstStep();
            case 2:
                return this.renderSecondStep();
            default:
                break;
        }
    };

    render() {
        let fullScreen = window.matchMedia("(max-width: 768px)").matches;
        return (
            <Dialog
                open={this.state.showModal}
                //open={true}
                onClose={this.handleModalClose}
                aria-labelledby="Video sold alert"
                fullScreen={fullScreen}

            >
                <IconButton className={importedStyles.closeModalIcon} onClick={this.handleModalClose}>
                    <CloseIcon/>
                </IconButton>

                {this.renderContent()}
            </Dialog>
        );

    }
}


const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(VideoSoldAlert);