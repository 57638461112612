import React, {useEffect, useReducer, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {sendAdInteraction, sendScheduleAdInteraction} from "../../helpers/stats/statsAPI";
import ReactVisibilitySensor from "react-visibility-sensor";
import {useParams} from "react-router-dom";
import {Fade} from "@material-ui/core";


export const AdBanner = (props) => {
    const classes = useStyles(props);

    const [alreadyShowed, setAlreadyShowed] = useState(false);
    const [ads, setAds] = useState([]);
    const [currentAd, setCurrentAd] = useState({});
    const [currentAdIndex, setCurrentAdIndex] = useState(0);


    const useInterval = (callback, delay) => {
        const savedCallback = React.useRef();

        React.useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        React.useEffect(() => {
            const tick = () => {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    };

    useInterval(() => {
        setCurrentAdIndex(currentAdIndex => currentAdIndex + 1);
    }, 10000);



    useEffect(() => {
        if (currentAdIndex >= props.ads.length){
            setCurrentAdIndex(0);
        } else {
            setCurrentAd(ads[currentAdIndex]);
        }

    }, [currentAdIndex, ads])


    useEffect(() => {
        setAds(props.ads);
    }, [props.ads])

    const onAdInViewport = (isVisible, interactionType, adId) => {
        if (isVisible) {
            setAlreadyShowed(true);
            // sendAdInteraction(interactionType, adId)
        }
    };

    const handleAdInteraction = (type) => {
        if (props.host === 'challenge'){
            sendAdInteraction(type, currentAd.id)
        }

        if (props.host === 'schedule'){
            sendScheduleAdInteraction(type, currentAd.id)
        }
    }



    const renderContent = () => {

        if (!currentAd) return false; //guard clause

        let content = (
            <div className={classes.mainImageWrapper}>
                <img
                    onLoad={() => sendAdInteraction('view', currentAd.id)}
                    src={currentAd.src_url}
                    alt={''}
                    style={{
                        borderRadius: (currentAd.border_radius === null || currentAd.border_radius === undefined) ? 0 : currentAd.border_radius,
                        width: '100%',
                        aspectRatio: '9/21',
                        boxShadow: 'rgb(0 0 0) 18px 18px 1px 0px'
                    }}
                />
            </div>

        );

        if (currentAd.action_url && currentAd.action_url.trim().length > 0) {
            content = (
                <a href={currentAd.action_url} target={'_blank'} rel={'noreferrer'}
                   className={classes.mainImageClickableWrapper}
                   // style={{paddingRight: (props.position === 'right' && floatingOnModal) && 0}}
                   onClick={() => sendAdInteraction('click', currentAd.id)}
                >
                    <img
                        onLoad={() => sendAdInteraction('view', currentAd.id)}
                        src={currentAd.src_url}
                        alt={''}
                        style={{
                            borderRadius: (currentAd.border_radius === null || currentAd.border_radius === undefined) ? 0 : currentAd.border_radius,
                            width: '100%',
                            aspectRatio: '9/21',
                            boxShadow: 'rgb(0 0 0) 18px 18px 1px 0px'
                        }}
                    />
                </a>
            );
        }

        return (

            <ReactVisibilitySensor onChange={isVisible => onAdInViewport(isVisible, 'view', currentAd.id)}
                                   // active={!alreadyShowed}
            >
                <Fade key={'fadeBanner'+currentAd.id} in={true} unmountOnExit mountOnEnter timeout={{ enter: 800, exit: 600}}>
                    {content}
                </Fade>
            </ReactVisibilitySensor>
        )
    }





    return (
        <React.Fragment>
            {renderContent()}
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {},
    mainImageClickableWrapper: {
        zIndex: 1800,
        position: 'fixed',
        top: '50%',
        transform: 'translate(0, -50%)',
        display: 'flex',
        justifyContent: 'center',
        padding: '2rem',
        paddingRight: (props) => props.position === 'right' ? '2rem' : '1.25rem',
        paddingLeft: (props) => props.position === 'left' ? '2rem' : '1.25rem',
        [theme.breakpoints.up('lg')]: {
            // padding: '1.25rem 13vw 1rem 13vw',
            maxWidth: '12.33333%',

        },
    },
    mainImageWrapper: {
        zIndex: 1800,
        position: 'fixed',
        top: '50%',
        transform: 'translate(0, -50%)',
        display: 'flex',
        justifyContent: 'center',
        padding: '2rem',
        paddingRight: (props) => props.position === 'right' ? '2rem' : '1.25rem',
        paddingLeft: (props) => props.position === 'left' ? '2rem' : '1.25rem',
        [theme.breakpoints.up('lg')]: {
            // padding: '1.25rem 13vw 1rem 13vw',
            maxWidth: '12.33333%',
        },
    }
}));

AdBanner.propTypes = {
    // image: PropTypes.string.isRequired,
    image: PropTypes.string,
    href: PropTypes.string,
    borderRadius: PropTypes.number,
    position: PropTypes.string,
    host: PropTypes.string
};

AdBanner.defaultProps = {
    host: 'challenge',
}
