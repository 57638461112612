import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import history from "../../helpers/history";
import importedStyles from './DetailVideo.module.sass';
import {Bookmark, BookmarkBorder, EuroSymbol, RoomOutlined} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ShopIcon from '@material-ui/icons/ShoppingCartRounded';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import Vimeo from '@u-wave/react-vimeo';
import Fab from "@material-ui/core/Fab/Fab";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import UploadVideoButton from "../../components/UploadVideoButton/UploadVideoButton";
import Skeleton from "react-loading-skeleton";
import ShareIcon from "@material-ui/icons/ShareRounded";
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BackButton from "../../components/BackButton/BackButton";
import DeleteForever from "@material-ui/icons/esm/DeleteForever";
import {deleteVideo} from "../../helpers/fetch/video";
import MyAlert from "../../components/MyAlert/MyAlert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snack from "../../components/Snack/Snack";
import {Tooltip} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {setVideo} from "./Redux/detailVideoActions";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import SuggestedLoginModal from "../../components/SuggestedLoginModal/SuggestedLoginModal";
import Helmet from "react-helmet/es/Helmet";
import ShareModal from "../../components/ShareModal/ShareModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setCategoryActive} from "../Categories/Redux/categoriesActions";

class DetailVideo extends React.Component {
    state = {
        ifFavorite: false,
        bought: false,
        openDelete: false,
        shareModal: false,
        waitingResponseFavorite: true,
        waitingResponsePurchase: true,
        purchaseConfirmationModal: false,
        freeVideoDownloadModal: false,
        freeDownloadOrPurchaseModal: false,
        url360p: null,
        url480p: null,
        url720p: null,
        url1080p: null,
        url2k: null,
        url4k: null,
        urlMarked360p: null,
        urlMarked480p: null,
        urlMarked720p: null,
        urlMarked1080p: null,
        urlMarked2k: null,
        urlMarked4k: null,
        fallbackUrl: null,
        fallbackUrlMarked: null,
        freeDownloadUrl: ''
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        const {videoId} = this.props.match.params;
        if (this.props.video.uuid !== videoId) {
            this.getVideoData();
        }

        ReactPixel.trackCustom('DetailVideo', {selectedVideo: videoId});
        ReactGA.pageview(window.location.pathname);

    };

    //Requests
    getVideoData = () => {
        this.setState({
            waitingResponse: true
        });

        const {videoId} = this.props.match.params;
        let userUuid = this.props.user ? this.props.user.uuid : null;


        fetch(apiBaseUrl + 'videos/getVideo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, userUuid: userUuid})
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.props.dispatch(setVideo(response.video));
                        if (this.props.logged) {
                            this.checkFavorite(response.video.uuid);
                            if (parseInt(response.video.price) <= 0){
                                this.setState({bought: true,  waitingResponsePurchase: false});
                                this.retrieveVideoQualityDownloadUrls();
                            } else {
                                this.checkBought(response.video.uuid);
                            }
                        }
                    } else {
                        this.setState({waitingResponse: false});
                        this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };
    retrieveFreeVideoDownloadUrl = () => {
        let videoMarkedId = this.props.video.vimeoMarkedId;

        fetch(apiBaseUrl + 'downloads/retrieveFreeVideoDownload', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoMarkedId: videoMarkedId}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({freeDownloadUrl: response.downloadUrl});
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            console.error('Error: ', error);
        });
    };
    retrieveVideoQualityDownloadUrls = () => {

        this.setState({waitingForDownloadLinks: true});
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.vimeoId;
        let videoMarkedId = this.props.video.vimeoMarkedId;

        fetch(apiBaseUrl + 'downloads/retrieveVideoDownload', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, videoMarkedId: videoMarkedId, entityId: entityId}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);

            let rrssBestResolution = {height: 0, width: 0};
            if (!response.error) {
                response.downloadLinks.forEach((videoQuality) => {
                    if ((videoQuality.width === 720 && videoQuality.height === 1280) || (videoQuality.height === 720 && videoQuality.width === 1280)) {
                        rrssBestResolution = videoQuality;
                        this.setState({url720p: videoQuality.link});
                    }
                    if (videoQuality.quality === 'source') {
                        this.setState({fallbackUrl: videoQuality.link});
                    }
                  /*  switch (true) {
                        case videoQuality.height <= 360:
                            this.setState({url360p: videoQuality.link});
                            break;
                        case videoQuality.height <= 480:
                            this.setState({url480p: videoQuality.link});
                            break;
                        case videoQuality.height <= 720:
                            this.setState({url720p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1080:
                            this.setState({url1080p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1440:
                            this.setState({url2k: videoQuality.link});
                            break;
                        case videoQuality.height <= 2160:
                            this.setState({url4k: videoQuality.link});
                            break;

                        default:
                            break;
                    }*/
                });


                /*response.downloadMarkedLinks.forEach((videoQuality) => {
                    if (videoQuality.quality === 'source') {
                        this.setState({fallbackUrlMarked: videoQuality.link});
                    }
                    switch (true) {
                        case videoQuality.height <= 360:
                            this.setState({urlMarked360p: videoQuality.link});
                            break;
                        case videoQuality.height <= 480:
                            this.setState({urlMarked480p: videoQuality.link});
                            break;
                        case videoQuality.height <= 720:
                            this.setState({urlMarked720p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1080:
                            this.setState({urlMarked1080p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1440:
                            this.setState({urlMarked2k: videoQuality.link});
                            break;
                        case videoQuality.height <= 2160:
                            this.setState({urlMarked4k: videoQuality.link});
                            break;

                        default:
                            if (videoQuality.quality === 'source') {
                                this.setState({fallbackMarkedUrl: videoQuality.link});
                            }
                            break;
                    }
                });*/
                this.setState({waitingForDownloadLinks: false});

            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            this.setState({waitingForDownloadLinks: false});
            console.error('Error: ', error);
        });
    };

    //Pixel event
    setVideoPlayPixelEvent = () => {
        let videoId = this.props.match.params ? this.props.match.params : this.props.video.uuid;
        ReactPixel.trackCustom('VideoPlayed', {videoId: videoId});
        ReactGA.ga('send', 'event', 'video', 'VideoPlayed' );
    };

    //Checkers
    checkFavorite = videoUuid => {

        this.setState({
            waitingResponseFavorite: true
        });

        let entityId = this.props.user.uuid;

        fetch(apiBaseUrl + 'favorites/checkFavorite', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoUuid, entityId: entityId})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            isFavorite: response.favorite,
                            waitingResponseFavorite: false,
                        });
                    } else {
                        this.setState({waitingResponse: false});
                        this.props.dispatch(showSnack('Error al enviar el mensaje, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };
    checkBought = videoUuid => {

        this.setState({
            waitingResponsePurchase: true
        });

        let entityId = this.props.user.uuid;

        fetch(apiBaseUrl + 'purchases/checkPurchase', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoUuid, entityId: entityId})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            bought: response.purchased,
                            waitingResponsePurchase: false,
                        });
                        //If video is already bought then retrieve all versions download links
                        if (response.purchased) {
                            this.retrieveVideoQualityDownloadUrls();
                        } else {
                            this.retrieveFreeVideoDownloadUrl();
                        }

                    } else {
                        this.props.dispatch(showSnack('Error al enviar el mensaje, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };

    // Handles
    handlePurchase = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        let amount = 5;

        fetch(apiBaseUrl + 'purchases/createNewPurchase', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, amount: amount}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({bought: true});
                this.retrieveVideoQualityDownloadUrls();

                //close current modals and open download links modal
                this.handleFreeDownloadOrPurchaseModal();
                this.handleDownloadsModal();
                //
                this.props.dispatch(showSnack('Compra realizada con éxito, ya puedes descargar el contenido', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.props.dispatch(showSnack('Error al procesar la compra, por favor vuelva a intentarlo más tarde', snackTypes.error));
        });
    };
    handlePurchaseConfirmationModal = () => {
        this.setState({
            purchaseConfirmationModal: !this.state.purchaseConfirmationModal
        });
    };
    handleFreeDownloadOrPurchaseModal = () => {
        this.setState({freeDownloadOrPurchaseModal: !this.state.freeDownloadOrPurchaseModal});

    };
    handleFavorite = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;

        const call = this.state.isFavorite ? 'favorites/removeFavorite' : 'favorites/addFavorite';

        this.setState({
            isFavorite: !this.state.isFavorite,
        });

        fetch(apiBaseUrl + call, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                    } else {
                        // Revert if fails
                        this.setState({
                            isFavorite: !this.state.isFavorite,
                        });
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        isFavorite: !this.state.isFavorite,
                    });
                }
            )
    };
    handleModalStatus = () => {
        this.setState({openDelete: !this.state.openDelete});
    };
    handleDeleteVideo = () => {
        this.setState({openDelete: false});
        deleteVideo(this.props.video.uuid)
            .then(res => res.json())
            .then((response) => {
                    if (!response.error) {
                        history.goBack();
                    } else {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error)
                }
            ).catch(error => {
            console.log('Error: ' + error);
            this.props.dispatch(showSnack('Error al borrar, vuelva a intentarlo', snackTypes.error));
        });

    };
    handleDownload = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        ReactPixel.trackCustom('VideoDownload', {video: videoId});
        fetch(apiBaseUrl + 'downloads/addDownloadRegister', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, isMarkedVideo: false})

        }).then(res => res.json())
            .then((response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error)
                }
            ).catch(error => {
                console.log('Error: ' + error);
            }
        );
    };
    handleMarkedDownload = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        ReactPixel.trackCustom('MarkedVideoDownload', {video: videoId});
        fetch(apiBaseUrl + 'downloads/addDownloadRegister', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, isMarkedVideo: true})

        }).then(res => res.json())
            .then((response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error)
                }
            ).catch(error => {
                console.log('Error: ' + error);
            }
        );
    };
    handleTagClick = tag => {
        ReactPixel.trackCustom('clickEnTag', {tag: tag});
    };
    handleDownloadsModal = () => {
        this.setState({openDownloadsModal: !this.state.openDownloadsModal});
    };
    handleShare = () => {
        this.setState({shareModal: true});
    };
    handleShareModalClose = () => {
        this.setState({shareModal: false});
    };

    //Renders
    renderPrice = () => {
        return (
            <React.Fragment>
                <Chip className={importedStyles.priceChip}
                      deleteIcon={<EuroSymbol/>}
                      onDelete={() => {
                      }}
                      variant="outlined"
                      size={window.matchMedia("(max-width: 768px)").matches && 'small'}
                      classes={{
                          deleteIcon: importedStyles.euroIcon
                      }}
                      label={this.props.video.price}
                />
            </React.Fragment>

        )
    };
    renderView = () => {
        const video = this.props.video;
        if (Object.keys(video).length === 0) {
            return (
                <p className={importedStyles.errorText}>Ha ocurrido un error a la hora de cargar el vídeo.</p>
            )
        }
        return (
            <React.Fragment>
                <div className={importedStyles.headerContainer}>
                    <BackButton className={importedStyles.arrowBack} prevRoute={'/listVideos'}/>
                    {video.location ? <div className={importedStyles.locationContainer}>
                            <RoomOutlined/>
                            <p>{video.location}</p>
                        </div>
                        :
                        <div style={{height: 50}}/>
                    }
                </div>
                <Vimeo
                    video={this.props.video.vimeoMarkedId}
                    className={importedStyles.iframeStyle}
                    showByline={false}
                    showTitle={false}
                    paused
                    onPlay={this.setVideoPlayPixelEvent}
                />
                <div className={importedStyles.bodyContainer}>
                    <div className={importedStyles.titleContainer}>
                        <span className={importedStyles.videoTitle}>{video.title}</span>
                        <div className={importedStyles.videoActions}>
                            {this.props.logged && this.props.user.entity && this.renderActionButtonDesktop()}
                            {this.props.logged && this.props.user.entity && this.renderPrice()}
                            {this.props.logged && this.props.user.entity && this.renderFavButton()}
                            {this.props.logged && !this.props.user.entity && this.renderDeleteButton()}
                        </div>
                    </div>
                    {this.props.video.categories && this.renderCategories()}
                    {this.props.video.tags && this.renderTags()}
                    {/*{(this.props.user.entity && this.state.bought) && this.renderDownloadButtons()}*/}
                </div>
                {this.renderShareButton()}
                {this.props.logged && this.props.user.entity && this.renderActionButton()}

            </React.Fragment>
        );
    };
    renderFavButton = () => {
        if (this.state.waitingResponseFavorite) {
            return (
                <Skeleton width={50} height={50}/>
            );
        } else {
            if (this.state.isFavorite) {
                return (
                    <Tooltip title={'Quitar de favoritos'} placement={'bottom'}>
                        <IconButton onClick={this.handleFavorite}>
                            <Bookmark fontSize={'large'}/>
                        </IconButton>
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title={'Añadir a favoritos'} placement={'bottom'}>
                        <IconButton onClick={this.handleFavorite}>
                            <BookmarkBorder fontSize={'large'}/>
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    };

    renderTags = () => {
        const tags = this.props.video.tags;
        let tagsView = [];

        Object.keys(tags).forEach((index) => {
            tagsView.push(
                <p key={index} className={importedStyles.tag} onClick={()=>this.handleTagClick(tags[index].text)}>
                    {tags[index].text}
                </p>
            );
        });
        return (
            <React.Fragment>
                {/*<p className={importedStyles.tagsSectionTitle}>{tagsView.length > 0 && 'Hashtags'}</p>*/}
                <div className={importedStyles.tagsContainer}>
                    {tagsView}
                </div>
            </React.Fragment>
        )
    };

    renderCategories = () => {
        const categories = this.props.video.categories;
        let categoriesView = [];

        Object.keys(categories).forEach((index) => {
            categoriesView.push(
                <p key={index} className={importedStyles.category} onClick={()=>{
                    this.props.dispatch(setCategoryActive(categories[index]));
                    history.push('/listVideos/'+categories[index].urlSlug);
                }}>
                    {categories[index].name}
                </p>
            );
        });
        return (
            <React.Fragment>
                <div className={importedStyles.categoriesContainer}>
                    {categoriesView}
                </div>
            </React.Fragment>
        )
    };

    displaySnackIOs = () => {
        this.props.dispatch(showSnack('Lo sentimos, no es posible descargar un vídeo desde este dispositivo. Por favor, inténtalo desde tu ordenador.', snackTypes.error));
    };

    renderActionButton = () => {
        if (this.state.waitingResponsePurchase) {
            return (
                <div className={importedStyles.floatingButtonContainer}>
                    <Skeleton height={60}/>
                </div>
            );
        } else {
            let text = this.state.bought ? "Descargar vídeo" : "Descargar";
            let functionButton = this.handleFreeDownloadOrPurchaseModal;
            if (this.state.bought) {
                functionButton = this.handleDownloadsModal;
            }
            const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (iOS) {
                functionButton = this.displaySnackIOs;
            }

            return (
                <div className={importedStyles.floatingButtonContainer}>
                    <Fab
                        variant="extended"
                        size={"large"}
                        className={importedStyles.floatingButton}
                        onClick={functionButton}
                    >{text}</Fab>
                </div>
            );

        }

    };

    renderActionButtonDesktop = () => {

        if (this.state.waitingResponsePurchase) {
            return (
                <div>
                    <Skeleton height={60}/>
                </div>
            );
        } else {
            let text = this.state.bought ? "Descargar vídeo" : "Descargar";

            let functionButton = this.handleFreeDownloadOrPurchaseModal;
            if (this.state.bought) {
                functionButton = this.handleDownloadsModal;
            }

            return (
                <Button variant={"contained"} size={"small"} onClick={functionButton}
                        className={importedStyles.desktopActionButton}>
                    {text}
                </Button>
            );

        }

    };

    renderDownloadsModal = () => {
        let fullScreen = window.matchMedia("(max-width: 768px)").matches;
        return (
            <Dialog
                open={this.state.openDownloadsModal}
                onClose={this.handleDownloadsModal}
                aria-labelledby="Download buttons modal"
                fullWidth
                maxWidth={"md"}
                fullScreen={fullScreen}
            >
                <IconButton className={importedStyles.closeModalIcon} onClick={this.handleDownloadsModal}>
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    Descarga
                </DialogTitle>
                <DialogContent>
                        <div className={importedStyles.downloadsModalContent}>
                            {this.renderDownloadButtons()}
                        </div>
                    {!this.state.bought &&
                    <React.Fragment>
                        <DialogContentText className={importedStyles.downloadClaim}>Con marca de agua:</DialogContentText>
                        <div className={importedStyles.downloadsModalContent}>
                            {this.renderMarkedDownloadButtons()}
                        </div>
                    </React.Fragment>
                    }
                </DialogContent>
            </Dialog>
        );
    };

    renderDownloadButtons = () => {
        let qualities = ['360p', '480p', '720p', '1080p', '2k', '4k'];
        let downloadButtons = [];

        qualities.forEach(quality => {
            if (this.state['url' + quality] !== null) {
                downloadButtons.push(
                    <Button key={quality} variant={"outlined"} href={this.state['url' + quality]}
                            onClick={this.handleDownload}
                            className={importedStyles.qualityDownloadButton}>
                        {quality} <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                );
            }
            /* else if(this.state.waitingForDownloadLink2s){
                downloadButtons.push(
                    <Button key={quality} disabled variant={"outlined"} className={importedStyles.qualityDownloadButton}>
                        <CircularProgress size={15}/>
                    </Button>
                );
            }*/
        });

        if (downloadButtons.length > 0) {
            return (
                <React.Fragment>
                    <Button key={'sourceQuality'} variant={"outlined"} href={this.state.fallbackUrl}
                            className={importedStyles.qualityDownloadButton}>
                        Original <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                    {downloadButtons}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Button key={'sourceQuality'} variant={"outlined"} href={this.state.fallbackUrl}
                            className={importedStyles.qualityDownloadButton}>
                        Original <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                    {this.state.waitingForDownloadLinks && <CircularProgress size={30} style={{marginLeft: '1rem'}}/>}
                </React.Fragment>
            );
        }
    };

    renderMarkedDownloadButtons = () => {
        let qualities = this.state.bought ? ['360p', '480p', '720p', '1080p', '2k', '4k'] : ['360p'];
        let downloadButtons = [];

        qualities.forEach(quality => {

            if (this.state['urlMarked' + quality] !== null) {
                downloadButtons.push(
                    <Button key={quality} variant={"outlined"} href={this.state['urlMarked' + quality]}
                            onClick={this.handleMarkedDownload}
                            className={importedStyles.qualityDownloadButton}>
                        {quality} <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                );
            }
        });

        if (downloadButtons.length > 0) {
            return (
                <React.Fragment>
                    <Button key={'sourceQualityMarked'} variant={"outlined"} href={this.state.fallbackUrlMarked}
                            className={importedStyles.qualityDownloadButton}>
                        Original <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                    {downloadButtons}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Button key={'sourceQualityMarked'} variant={"outlined"} href={this.state.fallbackUrlMarked}
                            className={importedStyles.qualityDownloadButton}>
                        Original <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                </React.Fragment>
            );
        }
    };

    renderDeleteButton = () => {
        if (this.props.video.userId === this.props.user.uuid) {
            return (
                <Tooltip title={'Eliminar video'} placement={"left"}>
                    <IconButton className={importedStyles.deleteIcon} onClick={() => {
                        this.handleModalStatus()
                    }}>
                        <DeleteForever/>
                    </IconButton>
                </Tooltip>
            );
        }
    };

    renderHeader = () => {
        let video = this.props.video;
        return (
            <Helmet>
                <title>{video.title}</title>
                <meta property="og:title" content={video.title}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image:secure_url " content={video.coverUrl}/>
                <meta property="og:type" content="website"/>
            </Helmet>
        )
    };

    renderShareButton = () => {
        return (
            <div className={importedStyles.shareButtonBox}>
                <Tooltip title={'Compartir vídeo'} placement={'bottom'}>
                    <Button onClick={this.handleShare} fullWidth variant={"outlined"}
                            className={importedStyles.shareButton}>
                        <ShareIcon fontSize={'large'}/> Compartir
                    </Button>
                </Tooltip>
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.renderHeader()}
                <NavBar/>
                <UploadVideoButton/>
                <DesktopDrawer/>
                <SuggestedLoginModal/>
                <Snack/>
                <ShareModal open={this.state.shareModal} onClose={this.handleShareModalClose}
                            shareClaim={'Mira que vídeo he encontrado en Grábalo: '}/>
                <div className={importedStyles.DetailVideo}>
                    {this.renderView()}
                </div>
                {this.state.bought && this.renderDownloadsModal()}
                <MyAlert
                    functionOk={this.handleDeleteVideo}
                    title={"¿Eliminar vídeo?"}
                    openModal={this.state.openDelete}
                    handleModalStatus={this.handleModalStatus}
                    subtitle={"Si aceptas eliminarás el vídeo para siempre."}
                />
                {/* <MyAlert
                    functionOk={this.handlePurchase}
                    handleModalStatus={this.handlePurchaseConfirmationModal}
                    openModal={this.state.purchaseConfirmationModal}
                    title={'Confirmar compra de vídeo'}
                    additionalText={'Los cargos se incluirán en la próxima factura mensual, te la enviaremos el último día del mes.'}
                    subtitle={
                        <p>¿Seguro que desea descargar este vídeo?
                            <br/>
                            <br/>
                            Los planes de precios son los siguientes:
                            <br/>
                            <br/>
                            Descarga con marca de agua y baja calidad: gratis
                            <br/>
                            <br/>
                            Descarga un sólo vídeo: 10€
                            <br/>
                            <br/>
                            Descarga en un mismo día más de 10 vídeos: 5€ la unidad
                            <br/>
                            <br/>
                            Descarga una recomendación de tu empresa: 50€
                        </p>}
                />*/}

                <Dialog
                    open={this.state.freeDownloadOrPurchaseModal}
                    onClose={this.handleFreeDownloadOrPurchaseModal}
                    aria-labelledby="confirm buy or free download modal"
                    fullScreen={window.matchMedia("(max-width: 768px)").matches}
                >
                    <IconButton className={importedStyles.closeModalIcon}
                                onClick={this.handleFreeDownloadOrPurchaseModal}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle>Descargar Vídeo</DialogTitle>
                    <DialogContent className={importedStyles.freeDownloadOrPurchaseContent}>
                        <DialogContentText className={importedStyles.freeDownloadOrPurchaseText}>
                            Te ofrecemos una versión gratuita del video en baja resolución y con nuestra marca de agua,
                            cómpralo para obtener la máxima calidad sin marcas.
                        </DialogContentText>
                        <div className={importedStyles.purchaseConfirmationActionsContainer}>
                            <Fab
                                variant={"extended"}
                                className={importedStyles.freeDownloadButton}
                                href={this.state.freeDownloadUrl}
                                onClick={this.handleMarkedDownload}
                            >
                                <DownloadIcon/> Descarga gratuita
                            </Fab>
                            <Fab variant={"extended"} className={importedStyles.buyVideoButton}
                                 onClick={this.handlePurchaseConfirmationModal}>
                                <ShopIcon/> Comprar vídeo
                            </Fab>
                        </div>
                    </DialogContent>
                </Dialog>

                <MyAlert
                    functionOk={this.handlePurchase}
                    title={"¿Seguro que quieres comprar este vídeo?"}
                    openModal={this.state.purchaseConfirmationModal}
                    handleModalStatus={this.handlePurchaseConfirmationModal}
                    subtitle={
                        <React.Fragment>
                            <p>Si aceptas se cargarán {this.props.video.price}€ en tu cuenta.</p><br/>
                            <Typography variant={'caption'}>Todas tus compras se facturan a fin de mes.</Typography>
                        </React.Fragment>

                    }
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, detailVideoReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        video: detailVideoReducer.video,
    });
};
export default connect(mapStateToProps)(DetailVideo);
