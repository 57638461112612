import React, {useEffect, useState} from "react";
import importedStyles from './Schedule.module.sass';
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {Divider, Hidden, IconButton, ListItemSecondaryAction, Slide, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snack from "../../components/Snack/Snack";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Fade from "@material-ui/core/Fade";
import {Helmet} from "react-helmet/es/Helmet";
import {useParams} from "react-router-dom";
import NoVideosMessage from "../../components/NoVideosMessage/NoVideosMessage";
import {isMobile} from "react-device-detect";
import {toast, Toaster} from "react-hot-toast";
import {ReactComponent as ShareArrow} from "../../icons/share_arrow.svg";
import ShareModal from "../../components/ShareModal/ShareModal";
import {AdBanner} from "../../components/AdBanner/AdBanner";
import {sendScheduleAdInteraction} from "../../helpers/stats/statsAPI";
import AdWithSensorWrapper from "../../components/AdWithSensorWrapper/AdWithSensorWrapper";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import {setPopupAds} from "../DetailVideoModal/Redux/detailVideoModalActions";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentAdIndex, setSchedule} from "./Redux/ScheduleActions";
import {
    ArrowRightRounded,
    CloseRounded,
    ExpandMoreRounded,
    RadioButtonChecked,
    RadioButtonUnchecked
} from "@material-ui/icons";
import EventCard from "./EventCard/EventCard";
import Button from "@material-ui/core/Button/Button";
import Drawer from "react-bottom-drawer";
import Chip from "@material-ui/core/Chip";
import EventDetail from "./EventDetail/EventDetail";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import NoResultsMessage from "../../components/NoResultsMessage/NoResultsMessage";
import FeaturedEventCard from "./FeaturedEventCard/FeaturedEventCard";
import {ArrowDown, ArrowRight} from 'tabler-icons-react';
import emptyBoxAnimation from "../../animations/empty-box-animation.json";
import Lottie from "react-lottie";
import FeaturedEventDetail from "./FeaturedEventDetail/FeaturedEventDetail";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Schedule = () => {
    const {scheduleIdentifier} = useParams();

    const schedule = useSelector((state) => state.ScheduleReducer.schedule);
    const dispatch = useDispatch();
    const grabaloDriveUrl = 'https://drive.google.com/uc?export=view&id=';

    const [navigatorTabIcon, setNavigatorTabIcon] = useState('');
    const [navigatorTabTitle, setNavigatorTabTitle] = useState('');
    const [waitingData, setWaitingData] = useState(true);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [gradientStyles, setGradientStyles] = useState({});
    const [headerCoverImage, setHeaderCoverImage] = useState({});


    const [openDateOptions, setOpenDateOptions] = useState(false);
    const [openCategoriesOptions, setOpenCategoriesOptions] = useState(false);
    const [openOrderOptions, setOpenOrderOptions] = useState(false);


    const dateOptions = [
        {id: 'all', name: 'Todas'},
        {id: 'today', name: 'Hoy'},
        {id: 'tomorrow', name: 'Mañana'},
        {id: 'this-week', name: 'Esta semana'},
        {id: 'this-weekend', name: 'Este fin de semana'},
        {id: 'this-month', name: 'Este mes'},
        {id: 'next-month', name: 'Próximo mes'},
    ];

    const categories = [
        {id: 1, name: 'Conciertos'},
        {id: 2, name: 'Teatro'},
        {id: 3, name: 'Deportes'},
        {id: 4, name: 'Gastronomía'},
        {id: 5, name: 'Tauromaquia'},
        {id: 6, name: 'Entierros'},
        {id: 7, name: 'Danza'},
        {id: 8, name: 'Talleres'},
        {id: 9, name: 'Conferencias'},
        {id: 10, name: 'Charlas'},
        {id: 11, name: 'Cursos'},
        {id: 12, name: 'Infantil'},
        {id: 13, name: 'Cine'},
        {id: 14, name: 'Visitas guiadas'},
        {id: 15, name: 'Jóvenes (menores de 18)'},
        {id: 16, name: 'Fiestas patronales'},
        {id: 17, name: 'Días festivos'},
        {id: 18, name: 'Ferias'},
        {id: 19, name: 'Educativos'},
        {id: 20, name: 'Visitas'},
        {id: 21, name: 'Senderismo'},
    ];

    const orderByOptions = [
        {id: 'popular', name: 'Más populares ❤️'},
        {id: 'my-likes', name: "Mis ❤️ Me gusta"},
        {id: 'date-asc', name: 'Fechas más próximas'},
        {id: 'date-desc', name: 'Fecha más lejanas'},
    ];


    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedDate, setSelectedDate] = useState({id: 'all', name: 'Todas'});


    const [eventsRaw, setEventsRaw] = useState([]);
    const [featuredEvents, setFeaturedEvents] = useState([]);
    const [events, setEvents] = useState([]);

    const [cardAdsArray, setCardAdsArray] = useState([]);
    const [adsArray, setAdsArray] = useState([]);

    const [orderBy, setOrderBy] = useState({id: 'date-asc', name: 'Fecha: ascendente'});


    useEffect(() => {
        dispatch(setCurrentAdIndex(0));
        getScheduleData();

    }, [scheduleIdentifier]);


    useEffect(() => {

        if (schedule) {

            setEventsRaw(schedule.events)
            setEvents(schedule.events)

            generateAdsLogic();
        }


    }, [schedule]);

    useEffect(() => {

        let filtered = [...eventsRaw].filter((event) => !!event.featured === true);

        if (filtered.length !== featuredEvents.length) {
            setFeaturedEvents(filtered)
        }

    }, [eventsRaw])


    useEffect(() => {

        if (eventsRaw && eventsRaw.length > 0) {
            let arr = [...eventsRaw];

            if (selectedCategories.length > 0) {
                arr = arr.filter((item) => selectedCategories.some((k) => k.name === item.category));
            }


            arr = filterByDate(arr);

            setEvents(orderEvents(arr));
        }


    }, [selectedCategories, selectedDate, eventsRaw]);


    const filterByDate = (arr) => {
        switch (selectedDate.id) {
            case 'today':
                arr = arr.filter((item) => moment(item.date).isSame(moment(), 'day'));
                break;
            case 'tomorrow':
                arr = arr.filter((item) => moment(item.date).isSame(moment().add(1, 'day'), 'day'));
                break;
            case 'this-week':
                arr = arr.filter((item) => moment(item.date).isSame(moment(), 'week'));
                break;
            case 'this-weekend':

                arr = arr.filter((item) => {
                    let day = moment(item.date).day();
                    return (day === 6 || day === 0) && moment(item.date).isSame(moment(), 'week')
                });
                break;
            case 'this-month':
                arr = arr.filter((item) => moment(item.date).isSame(moment(), 'month'));
                break;
            case 'next-month':
                arr = arr.filter((item) => moment(item.date).isSame(moment().add(1, 'month'), 'month'));
                break;
            default:
                // do nothing
                break;

        }

        return arr;
    }

    useEffect(() => {
        setEvents(orderEvents([...events]));
    }, [orderBy]);


    const orderEvents = (events) => {
        let arr = [...events];

        switch (orderBy.id) {
            case 'popular':
                arr = arr.sort((a, b) => b.likes - a.likes);
                break;
            case 'my-likes':
                arr = arr.sort((a, b) => (localStorage.getItem('likeEvent' + b.id) ? 1 : 0) - (localStorage.getItem('likeEvent' + a.id) ? 1 : 0));
                break;
            case 'date-asc':
                arr = arr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                break;
            case 'date-desc':
                arr = arr.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                break;
            default:
                // do nothing
                break;
        }

        return arr;
    }


    /* ---- FETCH DATA ---- */
    const getScheduleData = async () => {

        setWaitingData(true);

        try {
            let response = await fetch(apiBaseUrl + 'schedule', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({scheduleIdentifier: scheduleIdentifier}),
            })

            if (!response.ok) {
                const message = `An error has occured at fetch call: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            dispatch(setSchedule(response.schedule))

        } catch (error) {
            toast.error('No hemos podido cargar los datos que necesitas');
            console.error('Error in getScheduleData(): ', error);
        } finally {
            setWaitingData(false);
        }


    }
    /* ------------ */

    const generateAdsLogic = () => {
        let ads = [];
        let cardAds = [];
        if (schedule && schedule.ads && schedule.ads.length >= 0) {

            //get only needed ads types
            ads = schedule.ads.filter(item => item.type === 'intersection-banner');
            cardAds = schedule.ads.filter(item => item.type === 'mobile-card');

            let verticalBanners = shuffleArray(schedule.ads.filter(item => item.type === 'banner_vertical'));


            dispatch(setPopupAds(verticalBanners));

            if (ads || ads.length >= 0) {
                ads = shuffleArray(ads)
                // adsArray.sort(() => 0.5 - Math.random())
            }

            if (cardAds || cardAds.length >= 0) {
                cardAds = shuffleArray(cardAds)
                // cardAdsArray.sort(() => 0.5 - Math.random())
            }

            setAdsArray(ads);
            setCardAdsArray(cardAds)
        }

    }

    const handleShare = () => {
        if (isMobile && navigator.share) {
            navigator
                .share({
                    //title: document.title,
                    //text: '',
                    //url: window.location + '/' + this.props.video.urlSlug
                    url: window.location.href
                })
                .then(() => {
                    console.log('Compartido con éxito! 🎉')
                })
                .catch(err => {
                    console.error(err)
                });
        } else {
            setShareModalOpen(false)
        }
    }

    /* ------------ UTILS ------------ */

    const shuffleArray = array => {

        let copyArray = [...array];
        for (let i = copyArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = copyArray[i];
            copyArray[i] = copyArray[j];
            copyArray[j] = temp;
        }
        return copyArray;
    }

    const renderHeaderMeta = () => {
        return (
            <Helmet>
                <meta name="theme-color" content="#44444"/>
                <title>{navigatorTabTitle}</title>

                {!navigatorTabIcon &&
                    <link id="favicon" rel="icon" type="image/x-icon" href={'/globe-24.ico'}/>}
                {navigatorTabIcon &&
                    <link id="favicon" rel="icon" type="image/png" sizes="16x16" href={navigatorTabIcon}/>}
                {navigatorTabIcon &&
                    <link id="favicon32" rel="icon" type="image/png" sizes="32x32" href={navigatorTabIcon}/>}
                {navigatorTabIcon &&
                    <link id="favicon48" rel="icon" type="image/png" sizes="48x48" href={navigatorTabIcon}/>}

            </Helmet>
        );
    };

    const getIntervalIndexes = (lowEnd, highEnd) => {
        let list = [];
        for (let i = lowEnd; i <= highEnd; i++) {
            list.push(i);
        }
        return list;
    }
    /* ---------------------------------*/

    /* ------------ RENDER METHODS ------------ */

    // FEATURED EVENTS CARDS
    const renderFeaturedEvents = () => {

        return (
            <Box className={importedStyles.featuredEventsContainer} style={{}}>
                {featuredEvents.map((item, index) => {

                    let fadeTime = index > 5 ? 1500 : index * 300;

                    return (
                        <Fade in={true} unmountOnExit mountOnEnter timeout={fadeTime}>

                            <FeaturedEventCard event={item}/>

                        </Fade>
                    );

                })}
            </Box>
        );

    };

    // EVENT CARDS
    const renderEventItems = () => {


        let filesCards = events.map((item, index) => {

            let fadeTime = index > 5 ? 1500 : index * 300;

            return (
                <Fade in={true} unmountOnExit mountOnEnter timeout={fadeTime}>
                    <Grid key={'gridEventItem' + item.id} item xs={12} sm={6} md={4} xl={3}
                          className={importedStyles.gridEventItem}>
                        <EventCard event={item}/>
                    </Grid>
                </Fade>
            );

        });


        // CARD AD BANNERS
        if (cardAdsArray && cardAdsArray.length > 0) {
            let k = 0;
            let currentAd = cardAdsArray[k];
            let pos = 0;
            let cardBannerinterval = 4;

            while (pos < filesCards.length) {

                if (pos === 0) {
                    pos += cardBannerinterval - 1;
                } else {
                    pos += cardBannerinterval;

                }

                currentAd = cardAdsArray[k];

                if (currentAd && currentAd.src_url) {
                    filesCards.splice(pos, 0, renderIntersectionAdCard(currentAd));
                }

                if (k < cardAdsArray.length - 1) {
                    k++;
                } else {
                    k = 0;
                }

            }


        }


        // INTERSECTION BANNERS
        if (adsArray && adsArray.length > 0) {
            const getCols = () => {

                if (window.matchMedia("(min-width: 601px) and (max-width: 959px) ").matches) return 1;
                if (window.matchMedia("(min-width: 960px) and (max-width: 1920px) ").matches) return 2;
                if (window.matchMedia("(min-width: 1920px)").matches) return 3;

                // if (isLg) return 3;
                // if (isXl) return 4;

                return 2;

            }

            const getInterval = () => {

                if (window.matchMedia("(min-width: 601px) and (max-width: 959px) ").matches) return 2;
                if (window.matchMedia("(min-width: 960px) and (max-width: 1920px) ").matches) return 3;
                if (window.matchMedia("(min-width: 1920px)").matches) return 4;


                return 3;

            }

            let adIndex = 0;
            filesCards = filesCards.flatMap((item, index) => {

                let interval = isMobile ? 1 : getInterval();


                if ((index + 1) % interval === 0) {

                    if (!adsArray || adsArray.length <= 0) {
                        return false;
                    }

                    if (adIndex >= adsArray.length) {
                        adIndex = 0;
                    }


                    // eslint-disable-next-line no-extend-native
                    Array.prototype.readItem = function (index) {
                        return this[index % this.length];
                    }
                    let intervalIndexes = getIntervalIndexes(index, index + getCols());


                    let selectedAds = intervalIndexes.map(i => adsArray.readItem(i));

                    let adCard = renderIntersectionAdBanner(selectedAds);

                    if (isMobile) {
                        let ad = adsArray[adIndex];
                        adCard = renderIntersectionAdBannerMobile(ad);
                    }
                    adIndex = adIndex + interval;


                    return [item, adCard]
                } else {
                    return item;
                }
            });


            // Añadimos todos los banners publicitarios al final de todos los archivos, para que siempre se muestren todos aún cuando no exista contenido suficiente

            // eliminamos la ultima linea de anuncios para no duplicar...
            filesCards.pop();

            let endAds = [];
            for (let i = 0; i < adsArray.length; i++) {
                let item = renderIntersectionAdBannerRAW(adsArray[i]);
                endAds.push(item);
            }


            let groupedEndAds = [];
            let pos = 0;
            let interval = 3;
            let endCut = interval;

            while (pos < (endAds.length + interval)) {
                if (pos + interval > endAds.length) {
                    endCut = endAds.length;
                }


                groupedEndAds.push(
                    <Grid container spacing={4} item xs={12} className={importedStyles.endBannersWrapperItem}
                          style={{marginTop: pos > 0 ? 16 : 0, justifyContent: 'center'}}>
                        {endAds.slice(pos, endCut)}
                    </Grid>
                )

                pos += interval;
                endCut += interval;
            }


            filesCards.push(...groupedEndAds);

            // END de 'Añadimos todos los banners publicitarios al final[...]'

        }


        return (
            <Grid container spacing={4} className={importedStyles.uploadedVideosContainer} key={'filesGridContainer'}>
                {filesCards}
            </Grid>
        );

    };

    // ADS
    const renderIntersectionAdCard = (ad) => {

        let isMobileScreen = window.matchMedia("(max-width: 768px)").matches;

        let content = (
            <AdWithSensorWrapper adObject={ad} host={'schedule'}>
                <Card style={{
                    height: '100%',
                    // borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 18 : ad.border_radius,
                    borderRadius: 18,
                    minHeight: isMobileScreen ? '31rem' : null,
                }}>
                    <CardActionArea style={{height: '100%'}}>
                        <CardMedia
                            image={ad.src_url}
                            style={{height: '100%', aspectRatio: 9 / 9}}
                        />
                    </CardActionArea>

                </Card>

            </AdWithSensorWrapper>
        );

        if (ad.action_url && ad.action_url.trim().length > 0) {
            content = (
                <AdWithSensorWrapper adObject={ad} host={'schedule'}>
                    <Card style={{
                        height: '100%',
                        // borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 18 : ad.border_radius,
                        borderRadius: 18,
                        minHeight: isMobileScreen ? '31rem' : null,

                    }}>
                        <CardActionArea href={ad.action_url} target={'_blank'}
                                        onClick={() => sendScheduleAdInteraction('click', ad.id)}
                                        style={{height: '100%'}}>
                            <CardMedia
                                image={ad.src_url}
                                style={{height: '100%', aspectRatio: 9 / 9}}
                            />
                        </CardActionArea>

                    </Card>

                </AdWithSensorWrapper>
            );
        }
        return (
            <Grid
                key={'gridPubliDesktopAdCard' + ad.id}
                item xs={12} sm={6} md={4} xl={3}>
                {content}
            </Grid>
        )

    };
    const renderIntersectionAdBanner = (ads) => {

        let items = ads.map((ad) => {
            let content = (
                <AdWithSensorWrapper adObject={ad} host={'schedule'}>
                    <img src={ad.src_url} alt={'imagen-patrocinador'}
                        // onLoad={() => sendScheduleAdInteraction('view', ad.id)}
                         className={importedStyles.desktopBannerIntersection}
                         style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                </AdWithSensorWrapper>
            );

            if (ad.action_url && ad.action_url.trim().length > 0) {
                content = (
                    <AdWithSensorWrapper adObject={ad} host={'schedule'}>

                        <a href={ad.action_url} target={'_blank'}
                           onClick={() => sendScheduleAdInteraction('click', ad.id)}>
                            <img src={ad.src_url} alt={'imagen-patrocinador'}
                                // onLoad={() => sendScheduleAdInteraction('view', ad.id)}
                                 className={importedStyles.desktopBannerIntersection}
                                 style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                        </a>
                    </AdWithSensorWrapper>
                );
            }
            return (
                <Grid key={'gridPubliDesktopItem' + ad.id} item xs={12} sm={6} md={4} xl={3}>
                    {content}
                </Grid>
            )

        });

        return (
            <Hidden xsDown>
                <Grid container spacing={4} item xs={12}
                      style={{padding: '6px 0 2px', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                    {items}
                </Grid>
            </Hidden>
        );
    };
    const renderIntersectionAdBannerMobile = (ad) => {

        let content = (
            <img src={ad.src_url} alt={'imagen-patrocinador'}
                 onLoad={() => sendScheduleAdInteraction('view', ad.id)}
                 className={importedStyles.mobileBannerIntersection}
                 style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
        );

        if (ad.action_url && ad.action_url.trim().length > 0) {
            content = (
                <a href={ad.action_url} target={'_blank'} onClick={() => sendScheduleAdInteraction('click', ad.id)}>
                    <img src={ad.src_url} alt={'imagen-patrocinador'}
                        // onLoad={() => sendScheduleAdInteraction('view', ad.id)}
                         className={importedStyles.mobileBannerIntersection}
                         style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                </a>
            );
        }


        return (
            <Hidden smUp>
                <Grid key={'gridPubliMobileItem' + ad.id} item xs={12} sm={6} md={4} xl={3}
                      className={importedStyles.gridPubliMobileItem}>
                    <AdWithSensorWrapper adObject={ad} host={'schedule'}>
                        {content}
                    </AdWithSensorWrapper>
                </Grid>
            </Hidden>
        );
    }
    const renderIntersectionAdBannerRAW = (ad) => {

        let content = (
            <AdWithSensorWrapper adObject={ad} host={'schedule'}>
                <img src={ad.src_url} alt={'imagen-patrocinador'}
                    // onLoad={() => sendScheduleAdInteraction('view', ad.id)}
                     className={importedStyles.rawBannerIntersection}
                     style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
            </AdWithSensorWrapper>
        );

        if (ad.action_url && ad.action_url.trim().length > 0) {
            content = (
                <AdWithSensorWrapper adObject={ad} host={'schedule'}>

                    <a href={ad.action_url} target={'_blank'} onClick={() => sendScheduleAdInteraction('click', ad.id)}>
                        <img src={ad.src_url} alt={'imagen-patrocinador'}
                            // onLoad={() => sendScheduleAdInteraction('view', ad.id)}
                             className={importedStyles.rawBannerIntersection}
                             style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                    </a>
                </AdWithSensorWrapper>
            );
        }
        return (
            <Grid key={'gridPubliDesktopItemEnd' + ad.id} className={importedStyles.rawBannerIntersectionItem} item
                  xs={12} sm={6} md={4} xl={3}>
                {content}
            </Grid>
        )

    }
    const renderAdBanner = (side) => {

        if (!schedule || !schedule.ads || schedule.ads.length <= 0) {
            return false;
        }

        let ads = schedule.ads.filter(item => item.type === 'banner_vertical');
        let middleIndex = Math.ceil(ads.length / 2);

        let leftAds = ads.splice(0, middleIndex);
        let rightAds = ads.splice(-middleIndex);


        if (rightAds.length <= 1) {
            rightAds.push(leftAds[0]);
            // rightAds = rightAds.reverse();
        }


        if (side === 'left') {
            ads = leftAds;
            // ads = this.shuffleArray(ads);
            // ads = this.shuffleArray(ads);
        }

        if (side === 'right') {
            ads = rightAds;
            // ads = ads.reverse();
            // ads = this.shuffleArray(ads);
            // ads = this.shuffleArray(ads).reverse();

        }

        if (ads.length > 0) {
            return (
                <AdBanner
                    ads={ads}
                    host={'schedule'}
                    // value={ad}
                    // image={ad.src_url}
                    // href={ad.action_url}
                    // borderRadius={ad.border_radius}
                    position={side}
                />
            )
        }


    };

    const renderEmptyBoxAnimation = () => {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: emptyBoxAnimation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    speed={1}
            />
        )
    };

    /* --------------------------------------- */


    const handleCategorySelection = (category) => () => {

        let index = selectedCategories.findIndex(x => x.id === category.id);
        let selected = [...selectedCategories];

        // If already selected, remove it from selected ones
        if (index !== -1) {
            selected.splice(index, 1);
        }

        // If not selected already, add current category to selected ones
        if (index === -1) {
            selected.push(category)
        }

        setSelectedCategories(selected);
        setOpenCategoriesOptions(false);

    }
    const handleOrderbySelection = (value) => () => {
        setOrderBy(value);
        setOpenOrderOptions(false);
    }
    const handleDateSelection = (value) => () => {
        setSelectedDate(value);
        setOpenDateOptions(false);
    }

    // Guard Clause
    if (!waitingData && (schedule === undefined || schedule === null || Object.keys(schedule).length === 0)) {
        return (
            <Fade in={true}
                  unmountOnExit mountOnEnter>
                <Box>
                    <p className={importedStyles.errorText}>Ha ocurrido un error a la hora de cargar el
                        contenido o la página que buscas ya no existe.</p>
                </Box>

            </Fade>
        )
    }

// MAIN RENDER
    return (
        <React.Fragment>
            {renderHeaderMeta()}
            <Fade in={waitingData} mountOnEnter unmountOnExit>
                <Box>
                    <LinearProgress variant={"indeterminate"} color={"primary"}/>
                </Box>
            </Fade>


            <div className={importedStyles.gradientWrapper} style={gradientStyles}>

                <Grid container className={importedStyles.mainGrid}>
                    <Hidden smDown>

                        <Grid item xs={0} md={1} style={{display: 'flex', justifyContent: 'center'}}>
                            {renderAdBanner('left')}
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} md={9}>
                        <div className={importedStyles.DetailRoot}>


                            {/* ---------- MAIN VIEW ---------- */}

                            <Fade in={schedule && schedule.id} unmountOnExit mountOnEnter>

                                <Box>
                                    <div id={'headerImgWrapper'} style={{position: 'relative'}}>
                                        <div id={'headerImg'} style={{
                                            position: 'relative',
                                            paddingTop: '2rem',
                                            minHeight: '9.25rem',
                                            backgroundImage: 'url("https://grabalo-thumbnails-1.s3.eu-west-3.amazonaws.com/sparkles-background-grabalo.jpeg")',
                                            backgroundSize: 'cover',
                                            objectFit: 'cover',
                                            borderRadius: '0 0 100% 100%/0 0 24% 24%',
                                            filter: 'brightness(70%)'
                                        }}/>
                                        <Typography
                                            variant={"h4"}
                                            style={{
                                                fontSize: '1.75rem',
                                                // lineHeight: '1.2rem',
                                                textAlign: "center",
                                                color: '#fff',
                                                position: 'absolute',
                                                left: '50%',
                                                top: '42%',
                                                fontWeight: 500,
                                                width: '95%',
                                                transform: 'translate(-50%,-50%)'
                                            }}>
                                            {schedule.title}
                                        </Typography>
                                        <Fade in={schedule && schedule.showShareLpButton} mountOnEnter
                                              unmountOnExit>
                                            <IconButton
                                                className={importedStyles.shareArrowIconContainer}
                                                onClick={handleShare}>
                                                <ShareArrow className={importedStyles.shareArrowIcon}/>
                                            </IconButton>
                                        </Fade>


                                    </div>
                                    <Avatar
                                        src={schedule.avatar}
                                        // src={'https://drive.google.com/file/d/1CWAluE9EHMbH6psmZ05mwWZIQxiGm5nz/view?usp=sharing'}
                                        className={importedStyles.entityAvatar}
                                    />
                                    <div className={importedStyles.contentBox}>


                                        <Box
                                            style={{
                                                display: 'flex',
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                position: 'sticky',
                                                background: '#06232c',
                                                top: -1,
                                                marginLeft: -1,
                                                paddingTop: '0.75rem',
                                                paddingBottom: '0.75rem',
                                                zIndex: 10
                                            }}>

                                            <Button style={{
                                                background: '#1d343c',
                                                borderRadius: 455,
                                                textTransform: "unset",
                                                color: '#fff',
                                                fontSize: 16,
                                                fontWeight: 400,
                                                paddingLeft: '1.25rem',
                                                paddingRight: '0.75rem',
                                                margin: '0.25rem',
                                                lineHeight: "unset"
                                            }} onClick={() => setOpenDateOptions(true)}>
                                                Fecha <ExpandMoreRounded
                                                style={{
                                                    color: '#fff',
                                                    width: '2rem',
                                                    height: '2rem'
                                                }}/>

                                            </Button>

                                            <Button style={{
                                                background: '#1d343c',
                                                borderRadius: 455,
                                                fontWeight: 400,
                                                textTransform: "unset",
                                                color: '#fff',
                                                fontSize: 16,
                                                paddingLeft: '1.25rem',
                                                paddingRight: '0.75rem',
                                                margin: '0.25rem',
                                                lineHeight: "unset"
                                            }} onClick={() => setOpenCategoriesOptions(true)}>
                                                {/*<Typography>Fecha</Typography> <ExpandMoreRounded style={{color: '#fff', marginLeft: 5}}/>*/}
                                                Categoría <ExpandMoreRounded
                                                style={{
                                                    color: '#fff',
                                                    width: '2rem',
                                                    height: '2rem'
                                                }}/>

                                            </Button>

                                            <Button style={{
                                                background: '#1d343c',
                                                borderRadius: 455,
                                                textTransform: "unset",
                                                color: '#fff',
                                                fontSize: 16,
                                                fontWeight: 400,
                                                paddingLeft: '1.25rem',
                                                paddingRight: '0.75rem',
                                                margin: '0.25rem',
                                                lineHeight: "unset"
                                            }} onClick={() => setOpenOrderOptions(true)}>
                                                Ordenar <ExpandMoreRounded
                                                style={{
                                                    color: '#fff',
                                                    width: '2rem',
                                                    height: '2rem'
                                                }}/>

                                            </Button>

                                        </Box>

                                        <Fade in={selectedCategories.length > 0 || selectedDate.id !== "all"} mountOnEnter unmountOnExit>
                                            <Box style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'flex-start',
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                marginBottom: '1.75rem',
                                                marginTop: '0.5rem'
                                            }}>
                                                <Fade in={selectedDate.id !== "all"}
                                                      mountOnEnter unmountOnExit>
                                                    <Chip label={selectedDate.name} variant="outlined"
                                                          onDelete={handleDateSelection({
                                                              id: 'all',
                                                              name: 'Todas'
                                                          })}
                                                          deleteIcon={<CloseRounded
                                                              style={{color: '#000'}}/>}
                                                          style={{
                                                              margin: '0.25rem',
                                                              border: 'none',
                                                              background: '#e9e9e9',
                                                              fontWeight: 600
                                                          }}/>
                                                </Fade>
                                                {selectedCategories.map((category) => {
                                                    return (
                                                        <Chip label={category.name} variant="outlined"
                                                              onDelete={handleCategorySelection(category)}
                                                              deleteIcon={<CloseRounded
                                                                  style={{color: '#000'}}/>}
                                                              style={{
                                                                  margin: '0.25rem',
                                                                  border: 'none',
                                                                  background: '#e9e9e9',
                                                                  fontWeight: 600
                                                              }}/>
                                                    );
                                                })}
                                            </Box>
                                        </Fade>


                                        <Fade in={schedule} unmountOnExit mountOnEnter>
                                            <Box className={importedStyles.eventsContainer} style={{flexDirection: (selectedCategories.length > 0 || selectedDate.id !== 'all') ? 'column-reverse' : 'column'}}>

                                                <Fade in={!waitingData && featuredEvents.length > 0} unmountOnExit
                                                      mountOnEnter>
                                                    <Box>
                                                        <Typography variant={"h5"} style={{
                                                            display: 'flex',
                                                            color: '#fff',
                                                            marginBottom: '1rem',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            Planes destacados <ArrowRight size={28} color={'#fff'}/>
                                                        </Typography>
                                                        {renderFeaturedEvents()}
                                                    </Box>

                                                </Fade>




                                                <Fade in={!waitingData} unmountOnExit mountOnEnter>
                                                    <Box style={{marginBottom: '2rem'}}>
                                                        <Typography variant={"h5"} style={{
                                                            display: 'flex',
                                                            color: '#fff',
                                                            marginBottom: '1rem',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            Todos los planes y eventos <ArrowDown size={28}
                                                                                                  color={'#fff'}/>
                                                        </Typography>


                                                        {renderEventItems()}

                                                        <Fade in={events.length <= 0}
                                                              unmountOnExit mountOnEnter>
                                                            <Box style={{marginTop: '1rem'}}>
                                                                {/*<NoResultsMessage/>*/}
                                                                {renderEmptyBoxAnimation()}
                                                                <Typography variant={'subtitle2'} style={{textAlign: 'center', color: '#fff', marginTop: '1rem'}}>
                                                                    No hay planes con estas características, borra los filtros y empieza otra búsqueda.
                                                                </Typography>
                                                            </Box>
                                                        </Fade>
                                                    </Box>
                                                </Fade>

                                            </Box>
                                        </Fade>

                                    </div>
                                </Box>


                            </Fade>


                            {/* --------------------------------*/}


                            {/* ---------------- FOOTER ---------------- */}
                            <Divider variant={"fullWidth"} className={importedStyles.footerDivider}/>
                            <Box className={importedStyles.footer}>
                                <a target={'_blank'} href={'https://bit.ly/3A6vPyY'}>
                                    <img src={'/img/footerMadeLoveGrabaloWhite.png'}
                                         className={importedStyles.footerImage}
                                         alt={'made with love by Grabalo'}/>
                                </a>
                                <small className={importedStyles.footerLawContainer} style={{color: '#fff'}}>
                                    <a href={'https://grabaloapp.com/politica-de-privacidad'} target={'_blank'}
                                       style={{color: '#fff'}}>Politica
                                        de
                                        privacidad</a>
                                    <a href={'https://grabaloapp.com/aviso-legal'} target={'_blank'}
                                       style={{marginRight: 10, marginLeft: 10, color: '#fff'}}>Aviso legal</a>
                                    <a href={'https://grabaloapp.com/politica-de-cookies'} target={'_blank'}
                                       style={{color: '#fff'}}>Politica de
                                        cookies</a>
                                </small>

                            </Box>
                            {/* ----------------------------------------*/}

                        </div>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={0} md={1} style={{display: 'flex', justifyContent: 'center'}}>
                            {renderAdBanner('right')}
                        </Grid>
                    </Hidden>
                </Grid>


            </div>


            {/*-------------------------------------------------------------------*/
            }


            <Drawer
                duration={250}
                // hideScrollbars
                isVisible={openDateOptions}
                onClose={() => setOpenDateOptions(false)}
                className={importedStyles.optionsDrawer}
            >
                <Typography variant={"h5"}
                            style={{padding: '0.5rem 0', fontWeight: 600, marginLeft: 14}}>Fecha</Typography>
                <List>
                    {dateOptions.map((value) => (
                        <>
                            <ListItem key={'dateOption-' + value.id} button onClick={handleDateSelection(value)}>
                                <ListItemText primary={value.name}/>
                                <ListItemSecondaryAction style={{display: 'flex'}}>
                                    {selectedDate.id === value.id ?
                                        <RadioButtonChecked onClick={handleDateSelection(value)}/> :
                                        <RadioButtonUnchecked onClick={handleDateSelection(value)}/>}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider/>
                        </>
                    ))}

                </List>
            </Drawer>

            <Drawer
                duration={250}
                // hideScrollbars
                isVisible={openOrderOptions}
                onClose={() => setOpenOrderOptions(false)}
                className={importedStyles.optionsDrawer}
            >
                <Typography variant={"h5"} style={{padding: '0.5rem 0', fontWeight: 600, marginLeft: 14}}>Ordenar
                    por</Typography>
                <List>
                    {orderByOptions.map((value) => (
                        <>
                            <ListItem key={'orderByOption-' + value.id} button onClick={handleOrderbySelection(value)}>
                                <ListItemText primary={value.name}/>
                                <ListItemSecondaryAction style={{display: 'flex'}}>
                                    {orderBy.id === value.id ?
                                        <RadioButtonChecked onClick={handleOrderbySelection(value)}/> :
                                        <RadioButtonUnchecked onClick={handleOrderbySelection(value)}/>}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider/>
                        </>
                    ))}

                </List>
            </Drawer>

            <Drawer
                duration={250}
                // hideScrollbars
                isVisible={openCategoriesOptions}
                onClose={() => setOpenCategoriesOptions(false)}
                className={importedStyles.optionsDrawer}
            >
                <Typography variant={"h5"}
                            style={{padding: '0.5rem 0', fontWeight: 600, marginLeft: 14}}>Categorías</Typography>
                <Divider sx={{width: '1rem'}} />
                <List>
                    {categories.map((category) => (
                        <>
                            <ListItem key={'categoryOption-' + category.id} button
                                      onClick={handleCategorySelection(category)}>
                                <ListItemText primary={category.name}/>
                                <ListItemSecondaryAction style={{display: 'flex'}}>
                                    {selectedCategories.findIndex(x => x.id === category.id) !== -1 ?
                                        <RadioButtonChecked onClick={handleCategorySelection(category)}/> :
                                        <RadioButtonUnchecked onClick={handleCategorySelection(category)}/>}
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider/>
                        </>
                    ))}
                    <Divider/>
                </List>
            </Drawer>


            {/************** MODALS - ALERTS - SNACKS **************/
            }
            <Snack/>
            <ShareModal open={shareModalOpen} onClose={handleShare} shareClaim={''}/>
            <Toaster/>
            <EventDetail events={events} />
            <FeaturedEventDetail events={featuredEvents} />
            {/************** ----------------- **************/}

        </React.Fragment>
    );

}

export default Schedule;
