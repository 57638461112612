import React from "react";
import importedStyles from './RequestTerms.module.sass';
import {closeRegisterModal} from "../../../components/RegisterModal/Redux/RegisterModalActions";
import connect from "react-redux/es/connect/connect";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Markup from "interweave";
import Box from "@material-ui/core/Box";
import {Helmet} from "react-helmet/es/Helmet";

class RequestTerms extends React.Component {

    state = {
        expanded: null,
        terms: '-----',
        requestTitle: '-----'
    };

    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (this.props.registerModalOpened) {
            this.props.dispatch(closeRegisterModal());
            setTimeout(() => {
                document.getElementById('root').style.filter = 'unset';
            }, 500)
        }

        const {requestUrlSlug} = this.props.match.params;

        this.getTerms();

        //Analytics//
        ReactPixel.trackCustom('NuevoMvpRetos', {requestUrlSlug: requestUrlSlug ? requestUrlSlug : this.props.request.url});
        ReactGA.pageview(window.location.pathname);
        //---//

    }

    getTerms() {
        this.setState({
            waitingResponse: true
        });

        const {requestUrlSlug} = this.props.match.params;

        fetch(apiBaseUrl + 'requests/getRequestTerms', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({requestUrlSlug: requestUrlSlug})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({terms: response.terms, requestTitle: response.requestTitle});
                    } else {
                        this.setState({waitingResponse: false});
                        this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Terminos y condiciones para {this.state.requestTitle}</title>
                </Helmet>

                <div className={importedStyles.rootContainer}>
                    {/*<h2>Terminos y condiciones para:</h2>
                <h4><i>"{this.state.requestTitle}"</i></h4>*/}
                    <Box className={importedStyles.legalContent}>
                        <Markup content={this.state.terms}/>
                    </Box>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, RegisterModalReducer}) => {
    return ({
        registerModalOpened: RegisterModalReducer.open,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(RequestTerms);
