import React from 'react';
import * as PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import importedStyles from './MyAlert.module.sass';

class MyAlert extends React.Component {

    state = {};

    handleOk = () => {
        this.props.functionOk();
        this.props.handleModalStatus();
    };

    render() {

        return (
            <Dialog
                open={this.props.openModal}
                onClose={this.handleModalStatus}
                aria-labelledby="custom alert"
                className={importedStyles.myAlert}
            >
                <DialogTitle className={importedStyles.title} id={this.props.title}>{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        className={importedStyles.subtitle}
                        id={this.props.subtitle}>
                        {this.props.subtitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={importedStyles.actionsContainer}>
                    <Button
                        onClick={this.props.handleModalStatus}
                        className={importedStyles.cancelButton}>
                        CANCELAR
                    </Button>
                    <Button
                        className={importedStyles.submitButton}
                        variant={'contained'}
                        onClick={this.handleOk}>
                        {this.props.okButton ? this.props.okButton : 'ACEPTAR'}
                    </Button>
                </DialogActions>
                <p className={importedStyles.additionalText}>{this.props.additionalText}</p>
            </Dialog>
        );

    }
}
MyAlert.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.object.isRequired,
    okButton: PropTypes.string,
    additionalText: PropTypes.string,
    functionOk: PropTypes.func.isRequired,
    handleModalStatus: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
};


export default MyAlert;