import {SET_CURRENT_AD_INDEX, SET_POPUP_ADS, SET_SCHEDULE} from "../../../constants/action-types";


const initialState = {
    schedule: {},
    popupAds: [],
    currentAdIndex: 0,
};

const ScheduleReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_SCHEDULE:
            return {...state, schedule: action.schedule};
        case SET_POPUP_ADS:
            return {...state, popupAds: action.data};
        case SET_CURRENT_AD_INDEX:
            return {...state, currentAdIndex: action.index};

        default:
            return state;
    }
};


export default ScheduleReducer;
