import {SHOW_SNACK} from "../../constants/action-types";


const initialState = {
    data : {
        show: false,
        fixed: false
    },
};

const snackReducer = (state = initialState, action) => {

    switch (action.type) {
        case SHOW_SNACK:
            return { ...state, data: {show: action.show, fixed: action.fixed, text: action.text, snackType: action.snackType, oldSnackType: state.data.snackType}};
        default:
            return state;
    }
};


export default snackReducer;