import React from "react";
import importedStyles from './UploadVideoButton.module.sass';
import connect from "react-redux/es/connect/connect";
import {setRequest} from "../../modules/RequestFiles/Redux/requestFilesActions";
import Fab from "@material-ui/core/Fab";
import ReactPixel from "react-facebook-pixel";
import {pushRoute} from "../../helpers/routeMiddleware";


class UploadVideoButton extends React.Component {

    state = {
        windowInitialHeight: 0,
        keyboardOnScreen: false,
    };

    componentDidMount() {
        this.setState({windowInitialHeight: window.innerHeight});
        window.addEventListener('resize', this.hideButtonOnKeyboardShowed);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hideButtonOnKeyboardShowed);
    }

    hideButtonOnKeyboardShowed = () => {
        let actualHeight = window.innerHeight;

        if ((this.state.windowInitialHeight * 0.7) > actualHeight) {//if the current viewport height is smaller than 70% of the original size, it means that the keyboard is up
            this.setState({keyboardOnScreen: true});
        } else if ((this.state.windowInitialHeight * 0.7) < actualHeight) {//if the current viewport height is larger than 70% of the original size, it means that the keyboard is down
            this.setState({keyboardOnScreen: false});
        }
    };

    handleClick = () => {
        ReactPixel.trackCustom('UploadVideoButtonClickMobile', {from: window.location.pathname});
        this.props.dispatch(setRequest(null));
        pushRoute('/uploadVideo');
    };

    render() {
        if (!this.props.logged || (this.props.user && !this.props.user.entity && !this.state.keyboardOnScreen)) {
            return (
                <div className={importedStyles.floatingButtonContainer}>
                    <Fab
                        variant="extended"
                        size={"large"} className={importedStyles.UploadVideoButton} onClick={this.handleClick}>
                        PARTICIPAR
                    </Fab>
                </div>
            );
        }
        return <div/>;
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)((UploadVideoButton));
