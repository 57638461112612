import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import importedStyles from './ListVideos.module.sass';
import history from "../../helpers/history";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import {setVideo} from "../DetailVideo/Redux/detailVideoActions";
import UploadVideoButton from "../../components/UploadVideoButton/UploadVideoButton";
import Skeleton from 'react-loading-skeleton';
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import Snack from "../../components/Snack/Snack";
import {cleanTerm, setOrder, setOrientation, setTerm} from "./redux/searchActions";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackRounded} from "@material-ui/icons";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import Select from "react-select";
import SuggestedLoginModal from "../../components/SuggestedLoginModal/SuggestedLoginModal";
import {setCategoryActive} from "../Categories/Redux/categoriesActions";
import DetailVideoModal from "../DetailVideoModal/DetailVideoModal";
import Fade from "@material-ui/core/Fade";
import VideoCard from "../../components/VideoCardOld/VideoCardOld";
import Pagination from "@material-ui/lab/Pagination";
import {Helmet} from "react-helmet/es/Helmet";
import {videosPerPage} from "../../constants/constants";

const orientationOptions = [
    {value: 'vertical', label: 'Vertical'},
    {value: 'horizontal', label: 'Horizontal'},
];
const orderOptions = [
    {value: 'precioAsc', label: 'Ascendente'},
    {value: 'precioDesc', label: 'Descendente'},
];

class ListVideos extends React.Component {

    state = {
        videos: [],
        waitingResponse: true,
        orientation: null,
        order: null,
        openDetailVideoModal: false,
        selectedVideo: null,
        actualVideoIndex: null,
        currentPage: 1,
        totalVideosCount: 0
    };

    componentDidMount = async () => {
        ReactPixel.trackCustom('ListCategoryVideos', {category: this.props.category ? this.props.category.uuid : ''});
        ReactGA.pageview(window.location.pathname);
        window.scrollTo({top: 0, behavior: 'smooth'});


        // HANDLE PARAMS AND FILTERS FROM SEARCH //
        const params = window.location.search.substr(1).split('&');
        if (params) {
            if (params[0].split('=')[0] === 'search') {
                await this.props.dispatch(setTerm(decodeURI(params[0].split('=')[1])));
            }
        }

        if (this.props.orientation && this.props.order) {
            this.setState({
                orientation: JSON.parse(this.props.orientation),
                order: JSON.parse(this.props.order)
            }, () => {
                this.getVideos();
            }); //Set order(orientation) provided by props if exists
        } else if (this.props.orientation) {
            this.setState({orientation: JSON.parse(this.props.orientation),}, () => {
                this.getVideos();
            });
        } else if (this.props.order) {
            this.setState({order: JSON.parse(this.props.order)}, () => {
                this.getVideos();
            });
        } else {
            this.getVideos();
        }
        //---------------------------------------//

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.UrlParamsObserver(prevProps);
    }


    componentWillUnmount() {
        //Clean Url 'term' params
        this.props.dispatch(cleanTerm());
    }


    getVideos = () => {

        this.setState({
            waitingResponse: true
        });

        let userUuid = this.props.user ? this.props.user.uuid : null;
        let categoryId = this.props.category ? this.props.category.uuid : '';

        if (!this.props.category || (this.props.category && this.props.category.urlSlug !== this.props.match.params.categoryId)) {
            categoryId = this.props.match.params.categoryId;
        }
        let term = this.props.term;
        let orientation = this.state.orientation ? this.state.orientation.value : null;
        let order = this.state.order ? this.state.order.value : null;
        let isEntity = this.props.user ? this.props.user.entity : false;
        let currentPage = this.state.currentPage;


        fetch(apiBaseUrl + 'videos/listVideos', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({categoryId, userUuid, term, orientation, order, isEntity, currentPage})
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        if (this.props.category && this.props.category.uuid !== categoryId) {
                            this.props.dispatch(setCategoryActive(response.category));
                        }

                        this.setState({
                            videos: response.videos,
                            waitingResponse: false,
                            totalVideosCount: response.totalVideosCount
                        });
                        this.handleUrlVideoParam();
                    } else {
                        console.log('Error: ' + response.errorMessage);
                        this.setState({
                            waitingResponse: false,
                            isLoaded: true,
                        });
                        this.props.dispatch(showSnack('Error al cargar los datos, vuelve a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelve a intentarlo', snackTypes.error));
                }
            )
    };


    handlePageChange = (event, page) => {
        this.setState({currentPage: page}, () => {
            this.getVideos();
        });
    };
    renderPagination = () => {
        let totalPages = parseInt(this.state.totalVideosCount / videosPerPage).toString();
        let isMobile = window.matchMedia("(max-width: 768px)").matches ? 'small' : 'medium';
        return (
            <Grid item xs={12} className={importedStyles.paginatorContainer}>
                <Pagination
                    count={totalPages}
                    page={this.state.currentPage} onChange={this.handlePageChange}
                    boundaryCount={1}
                    siblingCount={1}
                    size={isMobile}
                    showFirstButton
                    showLastButton
                />
            </Grid>
        )
    };



    handleOrderFilterChange = (selected) => {
        this.props.dispatch(setOrder(JSON.stringify(selected)));
        this.setState({order: selected}, function () {
            this.getVideos();
        });
    };
    handleOrientationFilterChange = (selected) => {
        this.props.dispatch(setOrientation(JSON.stringify(selected)));
        this.setState({orientation: selected}, function () {
            this.getVideos();
        });
    };

    renderOrientationOptions = () => {
        let orderCards = [];

        if (orientationOptions) {
            orientationOptions.forEach((order) => {
                orderCards.push(
                    {value: order.value, label: order.label}
                );
            });
        }

        return orderCards;
    };
    renderOrderOptions = () => {
        let orderCards = [];

        if (orderOptions) {
            orderOptions.forEach((order) => {
                orderCards.push(
                    {value: order.value, label: order.label}
                );
            });
        }

        return orderCards;
    };

    renderSkeletons = () => {

        let skeletons = [];

        for (let i = 0; i < 9; i++) {
            skeletons.push(
                <Grid item xs={12} md={4} className={importedStyles.skeletonContainer}>
                    <Skeleton height={'100%'} width={'100%'}/>
                </Grid>
            )
        }

        return (
            <Fade direction={'top'} unmountOnExit mountOnEnter in={this.state.waitingResponse}
                  timeout={{enter: 400, exit: 0}}>
                <React.Fragment>
                    {skeletons}
                </React.Fragment>
            </Fade>
        );
    };

    checkIfVideoIsFavorite = (video) => {
        if (this.props.user && this.props.user.entity){
            return video.favourite_videos && video.favourite_videos.length > 0
        } else if (this.props.user) {
            return video.favourite_user_videos && video.favourite_user_videos.length > 0
        }
    };

    renderVideos = () => {
        const videos = this.state.videos;
        let videoCards = [];

        let i = 1;
        Object.keys(videos).forEach((key) => {

            i++; //Increment counter if actual video is below the 10th first

            let authorName = '-';
            let video = videos[key];
            let tagsView = [];

            let videoIsFree = parseInt(video.price) <= 0;
            Object.keys(video.tags).forEach((indexTag) => {
                if (video.tags[indexTag].text.length < 15) {
                    tagsView.push(<p key={indexTag}>{videos[key].tags[indexTag].text}</p>);
                }
            });

            if (video.user && video.user.name) {
                let fragments = video.user.name.split(" ");
                if (fragments.length > 1) {
                    authorName = fragments[0] + ' ' + fragments[1].substr(0, 1) + '.';
                } else {
                    authorName = fragments[0]
                }
            }

            videoCards.push(
                <Fade unmountOnExit mountOnEnter in={!this.state.waitingResponse}
                      timeout={{enter: i * 200, exit: 0}}>
                    <Grid className={importedStyles.videoCard} key={key} item xs={12} md={4}>
                        <VideoCard
                            onClick={this.handleDetailVideoModal(video, key)}
                            video={video}
                            freeTag={videoIsFree}
                            tags={video.tags}
                            title={video.title}
                            author={authorName}
                            isFavorite={this.checkIfVideoIsFavorite(video)}
                            date={video.uploadDate}
                            image={video.coverUrl}
                            titleTruncation={1}
                            showTitle={this.state.testTitles}
                        />
                    </Grid>
                </Fade>
            )
        });


        if (videoCards.length === 0 && !this.state.waitingResponse) {
            return (
                <p style={{margin: 20}}>Oops, parece que nuestros servidores están teniendo un dia duro, prueba de nuevo
                    en unos minutos, gracias.</p>
            );
        } else {
            return videoCards;
        }
    };
    renderFreeTag = () => {
        return (
            <span className={importedStyles.freeTag}>GRATIS</span>
        )
    };
    renderFiltersSelector = () => {
        return (
            <div className={importedStyles.orderContainer}>
                <div className={importedStyles.selectParentContainer}>
                    <label>Orientación</label>
                    <Select
                        value={this.state.orientation}
                        isSearchable={false}
                        isClearable
                        onChange={this.handleOrientationFilterChange}
                        className={importedStyles.selectContainer}
                        options={this.renderOrientationOptions()}
                        placeholder={'----'}
                    />
                </div>
                {/*<Divider variant={"middle"} orientation={'vertical'} style={{transform: 'rotate(90deg)', width: '2rem', margin: 0}}/>*/}
                <div className={importedStyles.selectParentContainer}>
                    <label>Precio </label>
                    <Select
                        value={this.state.order}
                        isSearchable={false}
                        isClearable
                        onChange={this.handleOrderFilterChange}
                        className={importedStyles.selectContainer}
                        options={this.renderOrderOptions()}
                        placeholder={'----'}
                    />
                </div>
            </div>
        )
    };


    UrlParamsObserver = prevProps => {
        //If category changes, call getVideos to refresh view
        if (this.props.match.params.categoryId !== prevProps.match.params.categoryId) {
            this.getVideos();
        }

        //If we dont have videoId now but have it in prevProps, means we must close modal (this code is to handle native navigation behavior)
        if (!this.props.match.params.videoId && prevProps.match.params.videoId) {
            this.setState({openDetailVideoModal: false, selectedVideo: null, actualVideoIndex: null});
        } else {
            //If actual videoId differs from previous one, must handle it
            if (this.props.match.params.videoId !== prevProps.match.params.videoId) {
                this.handleUrlVideoParam();
            }
        }
    };
    handleUrlVideoParam = () => {
        let urlVideoId = this.props.match.params.videoId;
        if (urlVideoId) {
            let videosCollection = this.state.videos;
            let indexOfRequestedVideo = videosCollection.findIndex(item => item.urlSlug === urlVideoId);
            let video = videosCollection[indexOfRequestedVideo];
            this.setState({openDetailVideoModal: true, selectedVideo: video, actualVideoIndex: indexOfRequestedVideo});
            ReactPixel.trackCustom('SelectVideo', {selectedVideo: video.urlSlug});
            this.props.dispatch(setVideo(video));
        }
    };
    handleDetailVideoModal = (video, actualVideoIndex) => () => {
        ReactPixel.trackCustom('SelectVideo', {selectedVideo: video.urlSlug});
        this.setState({openDetailVideoModal: true, selectedVideo: video, actualVideoIndex: actualVideoIndex});
        history.push(this.props.location.pathname + '/' + video.urlSlug + history.location.search);
    };
    closeDetailVideoModal = () => {
        this.setState({openDetailVideoModal: false, selectedVideo: null, actualVideoIndex: null});
        history.replace('/listVideos/' + this.props.match.params.categoryId + history.location.search);
    };
    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        ReactPixel.trackCustom('SelectVideo', {selectedVideo: nextVideo.urlSlug});

    };
    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        ReactPixel.trackCustom('SelectVideo', {selectedVideo: nextVideo.urlSlug});
    };


    render() {
        const params = window.location.search.substr(1).split('&');

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Listado vídeos</title>
                </Helmet>
                <NavBar searchFunction={this.getVideos}/>
                <UploadVideoButton/>
                <DesktopDrawer/>
                <Snack/>
                <div className={importedStyles.listVideos}>
                    <div className={importedStyles.titleContainer}>
                        {/*<BackButton/>*/}

                        <IconButton className={importedStyles.backButton}
                                    onClick={() => history.push('/categories')}>
                            <ArrowBackRounded className={importedStyles.arrowBack}/>
                        </IconButton>
                        <h2 className={importedStyles.titleCategory}>{this.props.category.name}</h2>

                        {this.props.user && this.props.user.entity ? this.renderFiltersSelector() :
                            <p className={importedStyles.top10Text}>Top 10 vídeos</p>}

                    </div>
                    <Grid container spacing={6} className={importedStyles.videosContainer} direction={'row'}>
                        {this.state.waitingResponse && this.renderSkeletons()}
                        {this.state.videos && this.renderVideos()}
                        {this.props.logged && this.props.user.entity && this.renderPagination()}
                    </Grid>
                </div>
                {this.state.selectedVideo &&
                <DetailVideoModal open={this.state.openDetailVideoModal} onClose={this.closeDetailVideoModal}
                                  video={this.state.selectedVideo} actualVideoIndex={this.state.actualVideoIndex}
                                  lastVideoIndex={this.state.videos.length - 1} handlePrevVideo={this.handlePrevVideo}
                                  handleNextVideo={this.handleNextVideo}/>}
                <SuggestedLoginModal/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, categoriesReducer, searchReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        category: categoriesReducer.categoryActive,
        term: searchReducer.term,
        orientation: searchReducer.orientation,
        order: searchReducer.order
    });
};
export default connect(mapStateToProps)(ListVideos);
