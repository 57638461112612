import React, {useState} from "react";
import PropTypes from "prop-types";
import importedStyles from "./DownloadAllFilesButton.module.sass";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {primaryColor, secondaryColor} from "../../styles/palette";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DownloadIcon from "@material-ui/icons/SaveAltRounded";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {showSnack, snackTypes} from "../Snack/SnackActions";
import {useDispatch} from "react-redux";
import {RefreshRounded} from "@material-ui/icons";

const DownloadAllFilesButton = (props) => {

    const [waitingResponse, setWaitingResponse] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    const dispatch = useDispatch();

    const sendDownloadPetition = async () => {
        try{
            setWaitingResponse(true);

            let response = await fetch(apiBaseUrl + 'downloads/requestAllFilesDownload', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({from: props.from, user_uuid: props.user.uuid}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            dispatch(showSnack('Hemos recibido tu petición de descarga', snackTypes.success));

            setOpenConfirmationModal(false);

            setWaitingResponse(false);

        } catch (error){
            setWaitingResponse(false);
            dispatch(showSnack('No hemos podido tramitar tu petición, intentalo de nuevo más tarde, gracias.', snackTypes.error));
            console.error('Error: ', error);
        }
    }


    return (
        <div style={props.style} className={props.className} id={'downloadAllFilesButtonWrapper'+props.key}>

            <Button disabled={waitingResponse} fullWidth={props.fullWidth} className={importedStyles.button} onClick={()=>setOpenConfirmationModal(true)}>
                {!waitingResponse && <DownloadIcon style={{color: secondaryColor, marginRight: 10}}/>}
                {waitingResponse && <CircularProgress size={20} style={{color: secondaryColor, marginRight: 10}}/>}
                Descargar todos los archivos
            </Button>

            {/*------------ CONFIRMATION MODAL -------------------*/}
            <Dialog open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)} classes={{paper: importedStyles.dialog}}>
                <IconButton className={importedStyles.closeModalIcon} onClick={() => setOpenConfirmationModal(false)}>
                    <CloseIcon/>
                </IconButton>

                <DialogContent className={importedStyles.dialogContent}>
                    <h4 style={{fontSize: '1.1rem', marginBottom: '0.75rem'}}>Descarga</h4>
                    <Typography  style={{fontWeight: 400, fontSize: '0.95rem'}}>
                        Descargar todos los archivos a la vez requiere un tiempo prolongado de espera.
                        Pincha en el siguiente botón y recibirás en tu email el enlace de descarga.
                        Si quieres descargar archivos inmediatamente, puedes hacerlo uno a uno.
                    </Typography>
                </DialogContent>
                <DialogActions style={{paddingTop: '0.75rem'}}>
                    <Button variant={"text"} onClick={sendDownloadPetition} classes={{label: importedStyles.dialogCtaLabel}} disabled={waitingResponse} style={{}}>
                        {!waitingResponse && <DownloadIcon style={{color: secondaryColor, marginRight: 5}}/>}
                        {waitingResponse && <CircularProgress size={20} style={{color: secondaryColor, marginRight: 5}}/>}
                        Descargar todos los archivos
                    </Button>
                </DialogActions>

            </Dialog>
            {/*-------------------------------------------------------*/}
        </div>
    )
}

DownloadAllFilesButton.propTypes = {
    from: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    fullWidth: PropTypes.bool,
};

DownloadAllFilesButton.defaultProps = {
    fullWidth: false,
};
export default (DownloadAllFilesButton);
