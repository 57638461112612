import importedStyles from "./FeaturedEventCard.module.sass";
import CardMedia from "@material-ui/core/CardMedia";
import {CardContent, Divider, Fade, ListItem, ListItemText, Typography} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {
    CalendarTodayRounded, EventRounded,
    FavoriteBorderOutlined,
    FavoriteOutlined,
    LocationOnRounded,
    ScheduleRounded, StarRounded
} from "@material-ui/icons";
import moment from "moment";
import Button from "@material-ui/core/Button/Button";
import {ReactComponent as ShareArrow} from "../../../icons/share_arrow.svg";
import {generatePath, useRouteMatch} from "react-router-dom";
import history from "../../../helpers/history";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import ShareModal from "../../../components/ShareModal/ShareModal";
import {redColor} from "../../../styles/palette";
import {CalendarEvent, Clock} from "tabler-icons-react";


const FeaturedEventCard = (props) => {

    const match = useRouteMatch();
    const schedule = useSelector((state) => state.ScheduleReducer.schedule);

    // STATE
    const [event, setEvent] = useState({});
    const [liked, setLiked] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    useEffect(() => {
        setEvent(props.event);
    }, [props.event])

    useEffect(() => {


        let likedCookie = localStorage.getItem('likeEvent' + event.id);
        if (likedCookie) {
            setLiked(true)
        } else {
            localStorage.removeItem('likeEvent' + event.id);
            setLiked(false);
        }


    }, [event]);

    const handleLike = (e) => {
        e.stopPropagation();

        let eventCopy = {...event};
        if (!liked) {
            localStorage.setItem('likeEvent' + event.id, 'true');
            eventCopy.likes = parseInt(eventCopy.likes) + 1;
        }

        if (liked) {
            localStorage.removeItem('likeEvent' + event.id);
            eventCopy.likes = parseInt(eventCopy.likes) - 1;
        }

        localStorage.setItem('eventLikesCount' + event.id, eventCopy.likes);
        window.dispatchEvent(new Event("storage"));

        setEvent(eventCopy);
        setLiked(!liked);
    }

    useEffect(() => {
        function checkLikesData() {

            let likedCookie = localStorage.getItem('likeEvent' + event.id);
            if (likedCookie) {
                setLiked(true)
            } else {
                localStorage.removeItem('likeEvent' + event.id);
                setLiked(false);
            }

            let likesCounter = localStorage.getItem('eventLikesCount' + event.id);

            if (likesCounter && parseInt(likesCounter) !== event.likes) {
                let eventCopy = {...event};
                eventCopy.likes = likesCounter;
                setEvent(eventCopy);
            }
        }

        window.addEventListener('storage', checkLikesData);

        // checkLikesData();

        return () => {
            window.removeEventListener('storage', checkLikesData)
        }
    }, [event])


    // RENDERS


    // HANDLERS

    const handleEventCardClick = () => {
        let newPath = generatePath(match.path, {scheduleIdentifier: schedule.url, eventIdentifier: event.url});
        history.push(newPath + '?featured=true');
    }

    const handleShare = (e) => {
        e.stopPropagation();
        if (isMobile && navigator.share) {

            navigator
                .share({
                    url: window.location.origin + generatePath(match.path, {
                        scheduleIdentifier: schedule.url,
                        eventIdentifier: event.url
                    })
                })
                .then(() => {
                    console.log('Compartido con éxito! 🎉')
                })
                .catch(err => {
                    console.error(err)
                });
        } else {
            setShareModalOpen(true);
        }
    }

    // MAIN RENDER
    return (
        <Card className={importedStyles.root} elevation={0} key={'eventCard' + props.id} onClick={handleEventCardClick}>

            <CardMedia
                className={importedStyles.mainImage}
                image={event.main_image}
                title={event.title}
            >
                <StarRounded  style={{position: 'absolute', left: '0.5rem', top: '0.5rem', color: '#ffa639', background: '#06232c9e', borderRadius: 6}}/>

            </CardMedia>

            <CardContent style={{padding: '0.5rem 0rem', minHeight: '4.5rem'}}>
                <ListItem>
                    <ListItemText primary={event.title} classes={{primary: importedStyles.title}}/>
                </ListItem>

                <ListItem style={{marginTop: -15, alignItems: 'center'}}>
                    <CalendarEvent strokeWidth={1.3}/>
                    <ListItemText primary={<Typography style={{
                        marginLeft: 5,
                        marginTop: 4,
                        textTransform: 'uppercase',
                        fontWeight: 400
                    }}>
                        {moment(event.date).format('ddd DD/MM/YY')}
                    </Typography>}/>
                </ListItem>


                <ListItem style={{marginTop: -15, alignItems: 'center', paddingTop: 0}}>
                    <Clock strokeWidth={1.3}/>
                    <ListItemText primary={  <Typography style={{marginLeft: 5, marginTop: 4, fontWeight: 400}}>
                        {moment(event.date).format('HH:mm')}h
                    </Typography>}/>
                </ListItem>

            </CardContent>

            <ShareModal open={shareModalOpen} onClose={() => setShareModalOpen(false)}/>
        </Card>
    )
}

FeaturedEventCard.propTypes = {
    event: PropTypes.object.isRequired,
};
FeaturedEventCard.defaultProps = {
    //
};


export default FeaturedEventCard;
