import React from 'react';
import importedStyles from './CategoryCard.module.sass';
import * as PropTypes from "prop-types";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Skeleton from "react-loading-skeleton";

class CategoryCard extends React.Component {
    state = {
        imageLoaded: false,
    };

    handleImageLoad = () => {
        this.setState({imageLoaded: true});
        document.getElementById('categoryImage' + this.props.id).style.visibility = 'visible';
        document.getElementById('categoryImage' + this.props.id).style.height = '100%';
        document.getElementById('categoryImage' + this.props.id).style.opacity = '1';
    };

    render() {
        return (
            <Card onClick={this.props.functionClick} className={importedStyles.categoryCard} elevation={4} key={this.props.key}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt="category photo"
                        id={'categoryImage' + this.props.id}
                        image={'https://drive.google.com/uc?export=view&id=' + this.props.driveId}
                        onLoad={this.handleImageLoad}
                        onError={(e)=>{e.target.onerror = null; e.target.src="/img/png/thumbnail.png"}}
                        title={this.props.name}
                        className={importedStyles.categoryImage}
                    />
                    {!this.state.imageLoaded &&
                    <CardMedia
                        component="div"
                        title={this.props.name}
                    ><Skeleton height={'26vh'}/></CardMedia>
                    }
                    <CardContent className={importedStyles.cardContent}>
                        <Typography className={importedStyles.categoryTitle}>
                            {this.props.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}
CategoryCard.propsType = {
    functionClick: PropTypes.func.isRequired,
    driveId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default CategoryCard;

