import history from "./history";
import {store} from "../redux/store";
import {
    closeSuggestedLoginModal,
    openSuggestedLoginModal
} from "../components/SuggestedLoginModal/Redux/SuggestedLoginModalActions";
import {closeRegisterModal, openRegisterModal} from "../components/RegisterModal/Redux/RegisterModalActions";


function getSafeValues(fn, defaultVal) {
    try {
        fn();
        return {error: false};
    } catch (e) {
        return {error: true};
    }
}

export async function pushRoute(route) {
    let getUserUuid = getSafeValues(() => store.getState().authReducer.user.uuid);

    if (localStorage.getItem('loggedOnGrabalo') && !getUserUuid.error) {
        store.dispatch(closeRegisterModal());
        document.getElementById('root').style.filter = 'unset';
        history.push(route);
    } else {
        await localStorage.removeItem('loggedOnGrabalo');
        document.getElementById('root').style.filter = 'blur(8px)';
        store.dispatch(openRegisterModal(route));
    }
}

export async function throwLogin(nextRoute) {
    let getUserUuid = getSafeValues(() => store.getState().authReducer.user.uuid);

    if (localStorage.getItem('loggedOnGrabalo') && !getUserUuid.error) {
        document.getElementById('root').style.filter = 'unset';
        store.dispatch(closeSuggestedLoginModal());
        history.push(nextRoute);

    } else {
        await localStorage.removeItem('loggedOnGrabalo');
        document.getElementById('root').style.filter = 'blur(8px)';
        store.dispatch(openSuggestedLoginModal(nextRoute));
    }
}
