import React from "react";
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import importedStyles from './VideoCardOld.module.sass';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/es/Card/Card";
import DownloadIcon from '@material-ui/icons/GetAppRounded';
import FavIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavIconRelleno from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/ShareRounded';
import ShopIcon from '@material-ui/icons/ShoppingCartRounded';
import ReactPixel from "react-facebook-pixel";
import {Fade, LinearProgress} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "react-loading-skeleton";
import IconButton from "@material-ui/core/IconButton";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Truncate from "react-truncate";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import {showSnack, snackTypes} from "../Snack/SnackActions";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import ShareModal from "../ShareModal/ShareModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Fab from "@material-ui/core/Fab";
import MyAlert from "../MyAlert/MyAlert";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";


// Flag to check if it's a mobile screen
const isMobile = window.matchMedia("(max-width: 768px)").matches;


class VideoCardOld extends React.Component {

    state = {
        expand: false,
        bought: this.props.video.purchases && this.props.video.purchases.length > 0,
        isFavorite: false,
        firstOpen: true,
        shareModal: false,
        videoLoading: false,
        waitingResponseFavorite: true,
        waitingResponsePurchase: true,
        purchaseConfirmationModal: false,
        freeVideoDownloadModal: false,
        freeDownloadOrPurchaseModal: false,
        url360p: null,
        url480p: null,
        url720p: null,
        url1080p: null,
        url2k: null,
        url4k: null,
        urlMarked360p: null,
        urlMarked480p: null,
        urlMarked720p: null,
        urlMarked1080p: null,
        urlMarked2k: null,
        urlMarked4k: null,
        fallbackUrl: null,
        fallbackUrlMarked: null,
        freeDownloadUrl: '',
        checkedBuy: false
    };


// LIFECYCLES
    componentDidMount = () => {
        this.setState({isFavorite: this.props.isFavorite});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

///////////////////////

    retrieveFreeVideoDownloadUrl = async () => {
        let videoMarkedId = this.props.video.vimeoMarkedId;

        await fetch(apiBaseUrl + 'downloads/retrieveFreeVideoDownload', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoMarkedId: videoMarkedId}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({freeDownloadUrl: response.downloadUrl});
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            console.error('Error: ', error);
        });

        //After fetch...
        this.setState({downloadLinksAlreadyRetrieved: true});
    };
    retrieveVideoQualityDownloadUrls = async () => {

        this.setState({waitingForDownloadLinks: true});
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.vimeoId;
        let videoMarkedId = this.props.video.vimeoMarkedId;

        await fetch(apiBaseUrl + 'downloads/retrieveVideoDownload', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, videoMarkedId: videoMarkedId, entityId: entityId}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                response.downloadLinks.forEach((videoQuality) => {
                    if (videoQuality.quality === 'source') {
                        this.setState({fallbackUrl: videoQuality.link});
                    }
                    switch (true) {
                        case videoQuality.height <= 360:
                            this.setState({url360p: videoQuality.link});
                            break;
                        case videoQuality.height <= 480:
                            this.setState({url480p: videoQuality.link});
                            break;
                        case videoQuality.height <= 720:
                            this.setState({url720p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1080:
                            this.setState({url1080p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1440:
                            this.setState({url2k: videoQuality.link});
                            break;
                        case videoQuality.height <= 2160:
                            this.setState({url4k: videoQuality.link});
                            break;

                        default:
                            break;
                    }
                });
                response.downloadMarkedLinks.forEach((videoQuality) => {
                    if (videoQuality.quality === 'source') {
                        this.setState({fallbackUrlMarked: videoQuality.link});
                    }
                    switch (true) {
                        case videoQuality.height <= 360:
                            this.setState({urlMarked360p: videoQuality.link});
                            break;
                        case videoQuality.height <= 480:
                            this.setState({urlMarked480p: videoQuality.link});
                            break;
                        case videoQuality.height <= 720:
                            this.setState({urlMarked720p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1080:
                            this.setState({urlMarked1080p: videoQuality.link});
                            break;
                        case videoQuality.height <= 1440:
                            this.setState({urlMarked2k: videoQuality.link});
                            break;
                        case videoQuality.height <= 2160:
                            this.setState({urlMarked4k: videoQuality.link});
                            break;

                        default:
                            if (videoQuality.quality === 'source') {
                                this.setState({fallbackMarkedUrl: videoQuality.link});
                            }
                            break;
                    }
                });
                this.setState({waitingForDownloadLinks: false});
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({waitingForDownloadLinks: false});
            console.error('Error: ', error);
        });

        this.setState({downloadLinksAlreadyRetrieved: true});
    };


// Handles
    handlePurchase = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;

        fetch(apiBaseUrl + 'purchases/createNewPurchase', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({bought: true});
                this.retrieveVideoQualityDownloadUrls();

                //close current modals and open download links modal
                this.handleFreeDownloadOrPurchaseModal();
                this.handleDownloadsModal();
                //
                this.props.dispatch(showSnack('Compra realizada con éxito, ya puedes descargar el contenido', snackTypes.success));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.props.dispatch(showSnack('Error al procesar la compra, por favor vuelve a intentarlo más tarde', snackTypes.error));
        });
    };
    handlePurchaseConfirmationModal = () => {
        this.setState({
            purchaseConfirmationModal: !this.state.purchaseConfirmationModal
        });
    };
    handleFreeDownloadOrPurchaseModal = () => {
        this.setState({freeDownloadOrPurchaseModal: !this.state.freeDownloadOrPurchaseModal});
    };
    handleFavorite = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;

        let apiCall = this.state.isFavorite ? 'favorites/removeFavorite' : 'favorites/addFavorite';
        let data = JSON.stringify({videoId: videoId, entityId: entityId});

        if (!this.props.user.entity){
            apiCall = 'user/' + apiCall;
            data = JSON.stringify({videoId: videoId, userId: this.props.user.uuid});
        }

        this.setState({
            isFavorite: !this.state.isFavorite,
        });

        fetch(apiBaseUrl + apiCall, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: data
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                    } else {
                        // Revert if fails
                        this.setState({
                            isFavorite: !this.state.isFavorite,
                        });
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        isFavorite: !this.state.isFavorite,
                    });
                }
            )
    };
    handleDownload = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        ReactPixel.trackCustom('VideoDownload', {video: videoId});
        fetch(apiBaseUrl + 'downloads/addDownloadRegister', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, isMarkedVideo: false})

        }).then(res => res.json())
            .then((response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error)
                }
            ).catch(error => {
                console.log('Error: ' + error);
            }
        );
    };
    handleMarkedDownload = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        ReactPixel.trackCustom('MarkedVideoDownload', {video: videoId});
        fetch(apiBaseUrl + 'downloads/addDownloadRegister', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, isMarkedVideo: true})

        }).then(res => res.json())
            .then((response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error)
                }
            ).catch(error => {
                console.log('Error: ' + error);
            }
        );
    };
    handleDownloadsModal = () => {
        this.setState({openDownloadsModal: !this.state.openDownloadsModal});
    };
    handleShare = (e) => {
        e.stopPropagation();
        this.setState({shareModal: true});
    };
    handleShareModalClose = () => {
        this.setState({shareModal: false});
    };
///////////////////


// HANDLERS
    handleExpansion = (e) => {
        e.stopPropagation();
        this.setState((prevState, prevProps) => {
            return {
                expand: !prevState.expand
            }
        })
    };


// RENDERS
    renderDownloadButton = () => {

        let text = this.state.bought ? "Descargar vídeo" : "Descargar";

        let functionButton = async (e) => {
            e.stopPropagation();
            if (!this.state.downloadLinksAlreadyRetrieved) {
                this.state.bought ? this.retrieveVideoQualityDownloadUrls() : this.retrieveFreeVideoDownloadUrl();
            }
            this.state.bought ? this.handleDownloadsModal() : this.handleFreeDownloadOrPurchaseModal();
        };

        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (iOS) functionButton = this.displaySnackIOs;

        return (
            <IconButton className={importedStyles.actionButton} onClick={functionButton}>
                <DownloadIcon style={{color: '#ffffff', fontSize: 18}}/>
            </IconButton>
        )

    };
    renderSkeleton = () => {
        return (
            <Fade unmountOnExit mountOnEnter in={this.props.showSkeleton} timeout={200}>
                <Grid className={importedStyles.skeletonBox} key={'skeleton1'} item xs={12} md={4}>
                    <Skeleton width={'100%'} className={importedStyles.skeleton}/>
                </Grid>
                <Grid className={importedStyles.skeletonBox} key={'skeleton2'} item xs={12} md={4}>
                    <Skeleton width={'100%'}/>
                </Grid>
                <Grid className={importedStyles.skeletonBox} key={'skeleton3'} item xs={12} md={4}>
                    <Skeleton width={'100%'}/>
                </Grid>
            </Fade>
        );
    };
    renderTags = () => {
        let tags = this.props.tags;
        let chips = [];
        Object.keys(tags).forEach((key) => {
            chips.push(
                <Chip
                    className={importedStyles.tagChip}
                    avatar={<Avatar className={importedStyles.tagAvatar}>#</Avatar>}
                    label={tags[key].text}
                    color="primary"
                    variant="outlined"
                />
            );
        });
        return (
            <React.Fragment>
                {chips}
            </React.Fragment>
        );
    };

    renderShareButton = () => {
        return (
            <IconButton className={importedStyles.actionButton} onClick={this.handleShare}>
                <ShareIcon/>
            </IconButton>
        )
    };
    renderFavButton = () => {

        let clickFunction = async (e) => {
            e.stopPropagation();
            await this.handleFavorite();
        };

        let icon = this.state.isFavorite ? <FavIconRelleno/> : <FavIcon/>;

        return (
            <IconButton className={importedStyles.actionButton} onClick={clickFunction}>
                {icon}
            </IconButton>
        )

    };


    renderDownloadsModal = () => {
        let fullScreen = window.matchMedia("(max-width: 768px)").matches;

        return (
            <Dialog
                open={this.state.openDownloadsModal}
                onClose={this.handleDownloadsModal}
                aria-labelledby="Download buttons modal"
                classes={{paper: importedStyles.downloadsModalRoot}}
                fullWidth
                //maxWidth={"md"}
                //fullScreen={fullScreen}
                onEnter={() => console.log('enter')}
                onEntering={() => console.log('enter')}
            >
                {/*<IconButton className={importedStyles.closeModalDownloadIcon} onClick={this.handleDownloadsModal}>
                    <CloseIcon/>
                </IconButton>*/}
                <DialogTitle>
                    Descarga
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={importedStyles.downloadsModalContent}>
                        {this.renderDownloadButtons()}
                    </DialogContentText>
                    {!this.state.bought &&
                    <React.Fragment>
                        <DialogContentText className={importedStyles.downloadClaim}>Con marca de
                            agua:</DialogContentText>
                        <div className={importedStyles.downloadsModalContent}>
                            {this.renderMarkedDownloadButtons()}
                        </div>
                    </React.Fragment>
                    }
                </DialogContent>
            </Dialog>
        );
    };
    renderDownloadButtons = () => {
        let qualities = ['4k', '2k', '1080p', '720p', '480p', '360p'];
        let downloadButtons = [];

        qualities.forEach(quality => {
            if (this.state['url' + quality] !== null) {
                downloadButtons.push(
                    <Button key={quality} variant={"outlined"} href={this.state['url' + quality]}
                            onClick={this.handleDownload}
                            className={importedStyles.qualityDownloadButton}>
                        {quality} <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                );
            }
            /* else if(this.state.waitingForDownloadLink2s){
                downloadButtons.push(
                    <Button key={quality} disabled variant={"outlined"} className={importedStyles.qualityDownloadButton}>
                        <CircularProgress size={15}/>
                    </Button>
                );
            }*/
        });

        return (
            <React.Fragment>
                <Fade direction={'top'} in={true} timeout={300}>
                    <Button key={'sourceQuality'} variant={"outlined"} href={this.state.fallbackUrl}
                            className={importedStyles.qualityDownloadButton}>
                        Original <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                </Fade>
                <Fade direction={'top'} in={downloadButtons.length > 0} timeout={300}>
                    <React.Fragment>
                        {downloadButtons}
                    </React.Fragment>
                </Fade>
                <Fade in={this.state.waitingForDownloadLinks} timeout={600} unmountOnExit>
                    <div className={importedStyles.chargingLinksBox}>
                        Comprobando calidades disponibles...
                        <LinearProgress
                            classes={{
                                colorPrimary: importedStyles.progressBarBackground,
                                barColorPrimary: importedStyles.progressBar
                            }}
                        />
                    </div>
                </Fade>
            </React.Fragment>
        );

    };
    renderMarkedDownloadButtons = () => {
        let qualities = this.state.bought ? ['4k', '2k', '1080p', '720p', '480p', '360p'] : ['360p'];
        let downloadButtons = [];

        qualities.forEach(quality => {

            if (this.state['urlMarked' + quality] !== null) {
                downloadButtons.push(
                    <Button key={quality} variant={"outlined"} href={this.state['urlMarked' + quality]}
                            fullWidth
                            onClick={this.handleMarkedDownload}
                            className={importedStyles.qualityDownloadButton}>
                        {quality} <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                );
            }
        });

        if (downloadButtons.length > 0) {
            return (
                <React.Fragment>
                    <Button key={'sourceQualityMarked'} variant={"outlined"} href={this.state.fallbackUrlMarked}
                            fullWidth className={importedStyles.qualityDownloadButton}>
                        Original <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                    {downloadButtons}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Button key={'sourceQualityMarked'} variant={"outlined"} href={this.state.fallbackUrlMarked}
                            fullWidth
                            className={importedStyles.qualityDownloadButton}>
                        Original <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                </React.Fragment>
            );
        }
    };

    render() {

        return (
            <React.Fragment>
                <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
                    <Card className={importedStyles.card} elevation={0}>
                        <CardActionArea onClick={this.props.onClick}>
                            <CardMedia
                                component="img"
                                alt="video photo"
                                id={'videoThumbnail' + this.props.video.uuid}
                                image={this.props.image}
                                //onLoad={this.handleImageLoad}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/img/png/thumbnail.png"
                                }}
                                title={this.props.title}
                                className={importedStyles.videoCover}
                            />
                            <div className={importedStyles.actionsContainer}>
                                {(!this.props.logged || !this.props.user.entity) && this.renderShareButton()}
                                {this.props.logged && this.props.user.entity && this.renderDownloadButton()}
                                {this.props.logged && this.renderFavButton()}
                            </div>

                            <div className={importedStyles.mobileVideoInfo}>
                                <ListItem>
                                    <ListItemText
                                        classes={{
                                            primary: importedStyles.videoTitle,
                                            secondary: importedStyles.videoAuthor
                                        }}
                                        primary={<Truncate
                                            lines={this.props.titleTruncation}>{this.props.title}</Truncate>}
                                        secondary={this.props.author}
                                    />
                                </ListItem>
                                {this.props.showDownloadButton && this.renderDownloadButton()}
                                {/*<IconButton
                                onClick={this.handleExpansion}
                                className={importedStyles.expansionIcon}
                                aria-label="show more info"
                            >
                                {this.state.expand ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                {this.state.expand ?
                                    <span style={{fontSize: 14, display: 'flex', alignItems: 'center'}}>DETALLES <ExpandLessIcon/></span>
                                    :
                                    <span style={{fontSize: 14, display: 'flex', alignItems: 'center'}}>DETALLES <ExpandMoreIcon/></span>
                                }
                            </IconButton>*/}
                            </div>
                        </CardActionArea>

                        {/*COLLAPSED MORE INFO AREA*/}
                        {/* <Collapse in={this.state.expand} timeout="auto" unmountOnExit>
                        <CardContent className={importedStyles.collapsedInfo}>
                            <ListItem disableGutters style={{paddingLeft: '0.45rem'}}>
                                <ListItemIcon style={{minWidth: 0, paddingRight: '0.25rem'}}>
                                    <UserIcon style={{color: '#00a3da'}}/>
                                </ListItemIcon>
                                <ListItemText style={{color: '#00a3da'}} primary={this.props.author}/>
                            </ListItem>
                            {this.renderTags()}
                        </CardContent>
                    </Collapse>*/}
                    </Card>
                    <Fade in={this.props.showTitle} timeout={400} unmountOnExit mountOnEnter>
                        <List className={importedStyles.extraInfo}>
                            <ListItem disableGutters>
                                <ListItemText
                                    classes={{
                                        primary: importedStyles.videoTitle,
                                        secondary: importedStyles.videoAuthor
                                    }}
                                    primary={<Truncate lines={this.props.titleTruncation}>{this.props.title}</Truncate>}
                                    secondary={this.props.author}
                                />
                                {/* <ListItemSecondaryAction>
                            {!this.props.user.entity &&
                            <IconButton className={importedStyles.favButton}>
                                <ShareIcon style={{color: '#fff', fontSize: 18}}
                                           onClick={() => console.log('DOWNLOAD')}/>
                            </IconButton>
                            }
                            <IconButton className={importedStyles.favButton}>
                                {this.props.isFavorite ?
                                    <FavIconRelleno style={{color: '#10212e', fontSize: 18}}
                                                    onClick={() => console.log('DOWNLOAD')}/>
                                    :
                                    <FavIcon style={{color: '#ffffff', fontSize: 18}}
                                             onClick={() => console.log('DOWNLOAD')}/>
                                }
                            </IconButton>
                            {this.props.user.entity &&
                            <IconButton className={importedStyles.downloadButton}>
                                <DownloadIcon style={{color: '#ffffff', fontSize: 18}}
                                              onClick={() => console.log('DOWNLOAD')}/>
                            </IconButton>
                            }
                        </ListItemSecondaryAction>*/}
                            </ListItem>

                        </List>
                    </Fade>
                </div>
                {/* MODALS, SNACKS AND ALERTS */}
                <React.Fragment>
                    {this.state.bought && this.renderDownloadsModal()}
                    <ShareModal open={this.state.shareModal} onClose={this.handleShareModalClose}
                                url={window.location + '/' + this.props.video.urlSlug}
                                shareClaim={'Mira este vídeo que he encontrado en Grábalo: '}/>
                    <Dialog
                        open={this.state.freeDownloadOrPurchaseModal}
                        onClose={this.handleFreeDownloadOrPurchaseModal}
                        aria-labelledby="confirm buy or free download modal"
                        fullScreen={window.matchMedia("(max-width: 768px)").matches}
                    >
                        {/*  <div className={importedStyles.dialogHeader}>
                            <IconButton className={importedStyles.closeModalIcon}
                                        onClick={this.handleFreeDownloadOrPurchaseModal}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                        */}
                        <DialogTitle>Descargar Vídeo</DialogTitle>
                        <DialogContent className={importedStyles.freeDownloadOrPurchaseContent}>
                            <DialogContentText className={importedStyles.freeDownloadOrPurchaseText}>
                                Te ofrecemos una versión gratuita del video en baja resolución y con nuestra marca de
                                agua,
                                cómpralo para obtener la máxima calidad sin marcas.
                            </DialogContentText>
                            <div className={importedStyles.purchaseConfirmationActionsContainer}>
                                <Fab
                                    variant={"extended"}
                                    className={importedStyles.freeDownloadButton}
                                    href={this.state.freeDownloadUrl}
                                    onClick={this.handleMarkedDownload}
                                >
                                    <DownloadIcon/> Descarga gratuita
                                </Fab>
                                <Fab variant={"extended"} className={importedStyles.buyVideoButton}
                                     onClick={this.handlePurchaseConfirmationModal}>
                                    <ShopIcon/> Comprar vídeo
                                </Fab>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <MyAlert
                        functionOk={this.handlePurchase}
                        title={"¿Seguro que quieres comprar este vídeo?"}
                        openModal={this.state.purchaseConfirmationModal}
                        handleModalStatus={this.handlePurchaseConfirmationModal}
                        subtitle={
                            <React.Fragment>
                                <p>Si aceptas se cargarán {this.props.video.price}€ en tu cuenta.</p><br/>
                                <Typography variant={'caption'}>Todas tus compras se facturan a fin de
                                    mes.</Typography>
                            </React.Fragment>

                        }
                    />
                </React.Fragment>
                {/* END......... MODALS, SNACKS AND ALERTS */}
            </React.Fragment>
        );
    }
}

VideoCardOld.propTypes = {
    onClick: PropTypes.func.isRequired,
    video: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    titleTruncation: PropTypes.number.isRequired,
    date: PropTypes.string,
    tags: PropTypes.array,
    author: PropTypes.string,
    showDownloadButton: PropTypes.bool.isRequired,
    downloadUrl: PropTypes.string,
    freeTag: PropTypes.bool.isRequired,
};
VideoCardOld.defaultProps = {
    onClick: () => console.log('Debes proporcionar una función onClick!'),
    titleTruncation: 1,
    showDownloadButton: false,
    freeTag: false,
};

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(VideoCardOld);