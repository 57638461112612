import {noCookiesRoutes} from "../constants/constants";

export const isNoCookiesView = () => {
    let currentLocation = window.location.href;
    let isNoCookiesView = false;

    if (noCookiesRoutes.some(v => currentLocation.includes(v))) {
        isNoCookiesView = true;
    }

    return isNoCookiesView;
}
