import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './Requests.module.sass';
import history from "../../../helpers/history";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import {setRequest} from "../../RequestFiles/Redux/requestFilesActions";
import UploadVideoButton from "../../../components/UploadVideoButton/UploadVideoButton";
import {Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "react-loading-skeleton";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import moment from "moment";
import Card from "@material-ui/core/Card";
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga'
import SuggestedLoginModal from "../../../components/SuggestedLoginModal/SuggestedLoginModal";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import UploadRetoButton from "../../../components/UploadRetoButton/UploadRetoButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {Helmet} from "react-helmet/es/Helmet";

class Requests extends React.Component {

    state = {
        requests: [],
        waitingResponse: true,
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getRequests();
        ReactPixel.trackCustom('VideoRequestsView', {user: this.props.user ? this.props.user.name : 'no logueado'});
        ReactGA.pageview(window.location.pathname);
    };

    getRequests = () => {

        this.setState({
            waitingResponse: true
        });

        fetch(apiBaseUrl + 'requests/getRequests', {
            method: 'GET',
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            requests: response.requests,
                            waitingResponse: false,
                        });
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingResponse: false});
                                break;
                            case 'badPassword':
                                this.setState({authPasswordError: true, waitingResponse: false});
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error al cargar, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            ).catch((error) => {
            this.setState({waitingResponse: false});
        })
    };

    handleClickRequest = (request) => () => {
        ReactPixel.trackCustom('SelectVideoRequest', {request: request.uuid});
        this.props.dispatch(setRequest(request));
        history.push('/peticiones/' + request.url + history.location.search);
    };

    renderRequests = () => {
        const requests = this.state.requests;
        if (requests.length === 0) {
            return (
                <Grid item xs={12} className={importedStyles.gridItemCenteredContent}>
                    <p style={{margin: 20}}>Aún no tienes retos, ¡sube el primero!</p>
                </Grid>
            );
        } else {
            let requestCards = [];
            Object.keys(requests).forEach((reqIndex) => {
                let request = requests[reqIndex];
                let timeRemaining = moment(request.limitDate).diff(moment.now(), 'days');
                let timeString = 'días';
                if (timeRemaining <= 0){
                    timeRemaining = moment(request.limitDate).diff(moment.now(), 'hours');
                    timeString = 'horas'
                }


                let remainingTimeText = timeRemaining > 0 ? ' (Quedan ' + timeRemaining + ' ' + timeString +')' : ' (Expirado)';

                let timeInfo = request.limitDate ? moment(request.limitDate).format('L') + remainingTimeText : '---';

                requestCards.push(
                    <Grid className={importedStyles.gridItemCenteredContent} key={request.uuid} item xs={12} md={4}>
                        <Card className={importedStyles.requestCard} elevation={6}>
                            <CardActionArea onClick={this.handleClickRequest(request)}>
                                <CardMedia
                                    component="img"
                                    alt="request photo"
                                    id={'requestThumbnail' + request.uuid}
                                    image={'https://drive.google.com/uc?export=view&id=' + request.driveId}
                                    //onLoad={this.handleImageLoad}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "/img/png/thumbnail.png"
                                    }}
                                    title={request.name}
                                    className={importedStyles.coverImg}
                                />
                                {/*!this.state.imageLoaded &&
                                <CardMedia
                                    component="div"
                                    title={request.name}
                                ><Skeleton height={'26vh'}/></CardMedia>
                                */}
                                <CardContent className={importedStyles.requestCardContent}>
                                    <ListItem>
                                        <ListItemText primary={request.title} secondary={'Cierra el ' + timeInfo}/>
                                    </ListItem>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            });
            return requestCards;
        }
    };

    renderRequestsOld = () => {
        const requests = this.state.requests;
        let requestCards = [];

        Object.keys(requests).forEach((reqIndex) => {
            let backgroundUrl = requests[reqIndex].driveId ? 'https://drive.google.com/uc?export=view&id=' + requests[reqIndex].driveId : 'http://primerobahia.com/wp-content/uploads/2018/12/despedida-de-soltero-y-soltera-en-Albacete.jpg';

            requestCards.push(
                <Grid className={importedStyles.requestParentBox} key={reqIndex} item xs={12} sm={8} md={6} lg={4}>
                    <Card className={importedStyles.requestCard}>
                        <CardActionArea onClick={this.handleClickRequest(requests[reqIndex])}>
                            <CardMedia image={backgroundUrl} className={importedStyles.requestImg}/>
                            <CardContent
                                classes={{
                                    root: importedStyles.requestCardContainer
                                }}
                            >
                                {/*<Truncate lines={2} className={importedStyles.title}>*/}
                                <Typography variant={"subtitle1"} className={importedStyles.title}>
                                    {requests[reqIndex].title}
                                </Typography>
                                    <div className={importedStyles.limitDateTextContainer}>
                                        <Typography style={{textAlign: 'right'}}
                                            variant={"body1"}>Quedan {moment(requests[reqIndex].limitDate).diff(moment.now(), 'days')} días </Typography>
                                        <Typography variant={"caption"} className={importedStyles.limitDateSecondaryText}>{moment(requests[reqIndex].limitDate).format('L')}</Typography>
                                    </div>
                            </CardContent>
                        </CardActionArea>

                        {/*<CardContent className={importedStyles.requestCardContent}>
                            <Truncate lines={2} className={importedStyles.title}>
                                {requests[reqIndex].title}
                            </Truncate>
                            <div className={importedStyles.limitDate}>
                                <ListItemIcon><CalendarIcon/></ListItemIcon>
                                <div className={importedStyles.limitDateTextContainer}>
                                    <Typography
                                        variant={"body1"}>{moment(requests[reqIndex].limitDate).format('L')}</Typography>
                                    <Typography variant={"body2"} className={importedStyles.limitDateSecondaryText}>Fecha
                                        límite</Typography>
                                </div>
                            </div>
                        </CardContent>*/}

                       {/* <CardActions className={importedStyles.requestActionButtonBox}>
                            <Button
                                fullWidth
                                className={importedStyles.actionButton}
                                onClick={this.handleClickRequest(requests[reqIndex])}
                            >
                                VER DEMANDA
                            </Button>
                        </CardActions>*/}
                    </Card>
                </Grid>
            );


        });
        if (requestCards.length === 0) {
            return (
                <div className={importedStyles.noRequestsContainer}>
                    <Typography gutterBottom style={{textAlign: 'center'}} >
                        Por el momento no se necesita ningún vídeo, pero ¡puedes subir los que quieras a
                        tu perfil!
                    </Typography>
                </div>
            );
        } else {
            return requestCards;
        }
    };

    renderSkeletons = () => {
        return (
            <React.Fragment>
                <Grid className={importedStyles.skeletonBox} key={0} item xs={12} sm={8} md={4}>
                    <Skeleton height={'100%'} width={'100%'}/>
                </Grid>
                <Grid className={importedStyles.skeletonBox} key={1} item xs={12} sm={8} md={4}>
                    <Skeleton height={'100%'} width={'100%'}/>
                </Grid>
                <Grid className={importedStyles.skeletonBox} key={2} item xs={12} sm={8} md={4}>
                    <Skeleton height={'100%'} width={'100%'}/>
                </Grid>
            </React.Fragment>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Concursos</title>
                </Helmet>
                <NavBar/>
                <DesktopDrawer/>
                <Grid container className={importedStyles.requestsRoot}>
                    <Grid item xs={12} className={importedStyles.sectionTitle}>
                        <h2>Retos</h2>
                    </Grid>
                    <Grid container className={importedStyles.gridContainer}>
                        {this.state.waitingResponse ? this.renderSkeletons() : this.renderRequests()}
                    </Grid>
                </Grid>
                <UploadRetoButton/>
                <UploadVideoButton/>
                <SuggestedLoginModal/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(Requests);
