import React from "react";
import connect from "react-redux/es/connect/connect";
import TextField from "@material-ui/core/TextField";
import {CircularProgress, Dialog, DialogContent, DialogTitle, Select} from "@material-ui/core";
import importedStyles from "./RegisterModal.module.sass";
import {showSnack, snackTypes} from "../Snack/SnackActions";
import ReactPixel from "react-facebook-pixel";
import Button from "@material-ui/core/Button";
import history from "../../helpers/history";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {closeRegisterModal} from "./Redux/RegisterModalActions";
import Snack from "../Snack/Snack";
import {openSuggestedLoginModal} from "../SuggestedLoginModal/Redux/SuggestedLoginModalActions";
import ReactGA from "react-ga";
import GoogleLogin from "react-google-login";
import * as qs from "query-string";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import GoogleIcon from "../../icons/google-icon.png";
import FacebookIcon from "../../icons/facebook-icon.png";
import Divider from "@material-ui/core/Divider";
import {login} from "../../modules/Auth/authActions";
import {openFirstLoginModal} from "../FirstLoginOnBoard/Redux/FirstLoginOnBoardActions";
import icon1 from "../../icons/videolovers.svg"
import icon2 from "../../icons/empresas.svg"
import icon3 from "../../icons/ONG.svg"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import baseUrl from "../../helpers/baseUrl";

/* END OF IMPORTS*/

const isMobile = window.matchMedia("(max-width: 768px)").matches;


class RegisterModal extends React.Component {

    state = {
        name: '',
        contactName: '',
        email: '',
        password: '',
        checkTerms: false,
        viralCode: '',
        selectedTab: 0,
        actualStep: 1,
        registeringWithSocial: false,
        rol: ''
    };

    /* LIFECYCLES */
    componentDidMount() {
        if (this.props.open) {
            document.getElementById('root').style.filter = 'blur(8px)';
            this.handleParamsActions();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.open) {
            document.getElementById('root').style.filter = 'blur(8px)';
        }
        if (this.props.open && !prevProps.open) {
            this.handleParamsActions()
        }
    }

    componentWillUnmount() {
        document.getElementById('root').style.filter = 'unset';
        this.setState({rol: '', actualStep: 0})
    }

    /*HANDLERS*/
    handleParamsActions = () => {
        const params = qs.parse(window.location.search);
        if (params.registerEntity) {
            this.setState({rol: 'entity', actualStep: 2});
        }
        if (params.viralCode) {
            this.setState({viralCode: params.viralCode, rol: 'user', actualStep: 2})
        }
        ReactPixel.trackCustom('ModalRegistroAbierto');
        ReactGA.ga('send', 'event', 'click', 'ModalRegistroAbierto');
    };
    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
    handleNextStep = selectedRol => () => {
        this.setState({rol: selectedRol, actualStep: 2});
    };
    handleCheckChange = (e, checked) => {
        this.setState({[e.target.name]: checked});
    };
    handleEmailInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value, authEmailError: !this.checkValidEmail(e.target.value)});
    };
    handleModalClose = () => {
        this.props.dispatch(closeRegisterModal());
        this.setState({rol: '', actualStep: 1});
        document.getElementById('root').style.filter = 'unset';
    };
    handleChangeTab = (val, e) => {
        this.setState({selectedTab: e})
    };
    handleRolSelect = (e) => {
        this.setState({[e.target.name]: e.target.value,})
    };
    checkValidEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    /* API CALLS */
    handleRegister = () => {

        this.setState({
            authPasswordError: false,
            authEmailError: false,
            waitingResponse: true
        });

        let name = this.state.name;
        let contactName = this.state.contactName;
        let email = this.state.email;
        let password = this.state.password;
        let role = this.state.rol;
        let viralCode = this.state.viralCode;

        fetch(apiBaseUrl + 'auth/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, email, password, role, contactName, viralCode})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            name: '',
                            email: '',
                            password: '',
                            viralCode: '',
                            waitingResponse: false,
                            waitingRegisterResponse: false
                        });
                        ReactPixel.trackCustom('RegistradoConExito');
                        ReactGA.ga('send', 'event', 'register', 'RegistradoConExito', role);
                        this.props.dispatch(showSnack('¡Genial! Revisa el correo que te hemos enviado para activar tu cuenta, échale un vistazo a la bandeja de spam si no lo encuentras', snackTypes.success, true, true));
                        document.getElementById('root').style.filter = 'unset'; //unset backdrop blur
                        this.props.dispatch(closeRegisterModal());
                        this.props.dispatch(openSuggestedLoginModal());
                        history.push('/');
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingResponse: false});
                                this.props.dispatch(showSnack('El correo ya está en uso o no es válido', snackTypes.error));
                                break;
                            case 'viralCodeInvalid':
                                this.setState({viralCodeError: true, waitingResponse: false});
                                this.props.dispatch(showSnack('El código amigo no es válido o ha caducado, revísalo e inténtalo de nuevo', snackTypes.error));
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error en el registro, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                        ReactPixel.trackCustom('ErrorEnRegistro', {error: response.errorType});
                        ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + response.errorType + '');
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + error + '');
                    ReactPixel.trackCustom('ErrorEnRegistro', {error: error});
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                }
            )
    };
    registerWithSocialAccount = (name, email, authToken, platform) => {
        this.setState({
            authPasswordError: false,
            authEmailError: false,
            waitingResponse: true,
            registeringWithSocial: platform,
        });

        fetch(apiBaseUrl + 'auth/registerWithSocialAccount', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                authToken: authToken,
                authProvider: platform,
                viralCode: this.state.viralCode
            })

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            waitingResponse: false,
                            waitingRegisterResponse: false,
                            registeringWithSocial: false
                        });
                        ReactPixel.trackCustom('RegistradoConExito');
                        ReactGA.ga('send', 'event', 'register', 'RegistradoConExito', 'user');
                        this.props.dispatch(login(response.user));
                        this.props.dispatch(openFirstLoginModal());
                        this.props.dispatch(closeRegisterModal());
                        document.getElementById('root').style.filter = 'unset'; //unset backdrop blur
                        history.push('/');
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({
                                    waitingResponse: false,
                                    registeringWithSocial: false
                                });
                                this.props.dispatch(showSnack('Ya existe una cuenta con el email proporcionado', snackTypes.error));
                                break;
                            default:
                                this.setState({waitingResponse: false, registeringWithSocial: false});
                                this.props.dispatch(showSnack('Error en el registro, vuelve a intentarlo', snackTypes.error));
                                break;
                        }
                        ReactPixel.trackCustom('ErrorEnRegistro', {error: response.errorType});
                        ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + response.errorType + '');
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        registeringWithSocial: false,
                        isLoaded: true,
                        error
                    });
                    ReactGA.ga('send', 'event', 'register', 'ErrorEnRegistro', 'Error:' + error + '');
                    ReactPixel.trackCustom('ErrorEnRegistro', {error: error});
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                }
            )
    };
    handleLogin = () => {
        this.props.dispatch(closeRegisterModal());
        this.props.dispatch(openSuggestedLoginModal())
    };
    handleGoogleResponse = (response) => {
        //console.log('Response google: ', response);
        let name = response.profileObj.name;
        let email = response.profileObj.email;
        let authToken = response.googleId;

        this.registerWithSocialAccount(name, email, authToken, 'google');
    };
    handleGoogleFailure = (response) => {
        this.props.dispatch(showSnack('Error en el registro con Google, vuelve a intentarlo', snackTypes.error));
        console.error('Auth response FAILED google: ', response);
    };
    handleFBResponse = (response) => {
        let name = response.name;
        let email = response.email;
        let authToken = response.userID;

        this.registerWithSocialAccount(name, email, authToken, 'facebook');

    };

    /* RENDERS */
    renderSocialRegisterButtons = () => {
        if (this.state.rol === 'user') {
            return (
                <React.Fragment>
                    <div className={importedStyles.socialLoginContainer}>
                        <GoogleLogin
                            render={renderProps => (
                                <Button variant={'contained'} fullWidth onClick={renderProps.onClick}
                                        disabled={this.state.registeringWithSocial === 'google'}
                                        className={importedStyles.googleButton}>
                                    <div className={importedStyles.socialLoginPlatformIconContainer}>
                                        <img src={GoogleIcon} alt={'google icon'}
                                             className={importedStyles.socialLoginPlatformIcon}/>
                                    </div>
                                    <span
                                        className={importedStyles.socialLoginPlatformName}>Regístrate con Google</span>
                                    {this.state.registeringWithSocial === 'google' &&
                                        <CircularProgress className={importedStyles.waitingSocialLoginProgress}
                                                          size={25}/>}
                                </Button>
                            )}
                            clientId="421190726876-g6j9ppjlort8actathh13k1pnt572iii.apps.googleusercontent.com"
                            onSuccess={this.handleGoogleResponse}
                            onFailure={this.handleGoogleFailure}
                            cookiePolicy={'single_host_origin'}
                        />
                        <FacebookLogin
                            appId={"772508366890225"}
                            autoLoad={true}
                            disableMobileRedirect={false}
                            isMobile={isMobile}
                            redirectUri={baseUrl}
                            fields="name,email,picture"
                            render={renderProps => (
                                <Button variant={'contained'} fullWidth onClick={renderProps.onClick}
                                        disabled={this.state.registeringWithSocial === 'facebook'}
                                        className={importedStyles.facebookButton}>
                                    <div className={importedStyles.socialLoginPlatformIconContainer}>
                                        <img src={FacebookIcon} alt={'fb icon'}
                                             className={importedStyles.socialLoginPlatformIcon}/>
                                    </div>
                                    <span
                                        className={importedStyles.socialLoginPlatformName}>Regístrate con Facebook</span>
                                    {this.state.registeringWithSocial === 'facebook' &&
                                        <CircularProgress className={importedStyles.waitingSocialLoginProgress}
                                                          size={25}/>}
                                </Button>
                            )}
                            callback={this.handleFBResponse}
                        />
                    </div>
                    <div className={importedStyles.insetFormDivider}>
                        <span className={importedStyles.dividerSection}/> <span
                        className={importedStyles.dividerText}> o con </span> <span
                        className={importedStyles.dividerSection}/>
                    </div>
                </React.Fragment>
            )
        }
    };
    renderStepContent = () => {
        switch (parseInt(this.state.actualStep)) {
            case 1:
                return this.renderFirstStep();
            case 2:
                return this.renderSecondStep();
            default:
                return null;
        }
    };
    renderFirstStep = () => {
        return (
            <React.Fragment>
                <h4 className={importedStyles.firstStepHeadCopy}>¡Hola! <br/> Elige una opción para empezar:</h4>
                <Button variant={'contained'} fullWidth className={importedStyles.roleButton}
                        onClick={this.handleNextStep('user')}>
                    <span className={importedStyles.roleButtonIconFirst}><img src={icon1} alt={'icon1'}/></span>
                    <span className={importedStyles.roleButtonText}>Grabo vídeos y quiero ganar dinero y premios</span>
                </Button>
                <Button variant={'contained'} fullWidth className={importedStyles.roleButton}
                        onClick={this.handleNextStep('entity')}>
                    <span className={importedStyles.roleButtonIcon}><img src={icon2} alt={'icon2'}/></span>
                    <span
                        className={importedStyles.roleButtonText}>Soy una empresa y quiero comunicar con vídeos reales</span>
                </Button>
                <Button variant={'contained'} fullWidth className={importedStyles.roleButton}
                        onClick={this.handleNextStep('ong')}>
                    <span className={importedStyles.roleButtonIcon}><img src={icon3} alt={'icon3'}/></span>
                    <span
                        className={importedStyles.roleButtonText}>Soy una ONG y necesito vídeos para comunicar mejor</span>
                </Button>
            </React.Fragment>
        );
    };
    renderSecondStep = () => {
        return (
            <React.Fragment>
                <FormControl style={{minWidth: '100%'}}>
                    <InputLabel htmlFor="userRol">* Elige tu rol en Grábalo</InputLabel>
                    <Select
                        className={importedStyles.rolSelector}
                        value={this.state.rol}
                        autoWidth
                        displayEmpty
                        onChange={this.handleRolSelect}
                        inputProps={{name: 'rol', id: 'rolSelect'}}
                    >
                        <MenuItem className={importedStyles.rolOption} value={'user'}>Grabo vídeos y quiero ganar dinero
                            y premios</MenuItem>
                        <Divider variant={"middle"}/>
                        <MenuItem className={importedStyles.rolOption} value={'entity'}>Soy una empresa y quiero
                            comunicar con vídeos reales</MenuItem>
                        <Divider variant={"middle"}/>
                        <MenuItem className={importedStyles.rolOption} value={'ong'}>Soy una ONG y necesito vídeos para
                            comunicar mejor</MenuItem>
                    </Select>
                </FormControl>
                {this.renderSocialRegisterButtons()}

                <TextField
                    margin={"dense"}
                    id="name"
                    name="name"
                    label={this.state.rol === 'user' ? '* Nombre' : '* Nombre de la organización'}
                    type="text"
                    inputProps={{maxLength: 50}}
                    value={this.state.name}
                    fullWidth
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />
                <TextField
                    margin={"dense"}
                    id="email"
                    name="email"
                    label="* Correo"
                    type="text"
                    inputProps={{maxLength: 50}}
                    value={this.state.email}
                    error={this.state.authEmailError}
                    helperText={this.state.authEmailError && 'Debes introducir un correo válido'}
                    fullWidth
                    onChange={this.handleEmailInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />
                <TextField
                    margin={"dense"}
                    id="password"
                    name="password"
                    label='* Contraseña'
                    type="password"
                    inputProps={{maxLength: 50}}
                    value={this.state.password}
                    fullWidth
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />
                {/*{this.state.rol === 'user' && <TextField
                    margin={"dense"}
                    id="viralCode"
                    name="viralCode"
                    label='Código amigo'
                    helperText={'Si no tienes un código amigo, déjalo en blanco'}
                    type="text"
                    error={this.state.viralCodeError}
                    inputProps={{maxLength: 30}}
                    value={this.state.viralCode}
                    fullWidth
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />}*/}
                {this.state.rol !== 'user' && <TextField
                    margin={"dense"}
                    id="contactName"
                    name="contactName"
                    label="* Tu nombre"
                    type="text"
                    inputProps={{maxLength: 50}}
                    value={this.state.contactName}
                    fullWidth
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('registerButton').click();
                        }
                    }}
                />}
                <div className={importedStyles.infoLOPDContainer}>
                    <h3>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS</h3>
                    <p>
                        RESPONSABLE: Graba tu talento, SLU cuyos datos de contacto son:
                        <br/>
                        email: info@grabaloapp.com
                        <br/>
                        teléfono: 622393895
                        <br/>
                        <br/>
                        FINALIDAD:
                        Gestión del contacto a petición del usuario y envíos comerciales respecto de las
                        promociones y servicios de Graba tu talento,
                        SLU o de terceras empresas colaboradoras, pertenecientes a los sectores determinados
                        en
                        la Política de Privacidad,
                        previo consentimiento del usuario si ello resulta legalmente exigible y ha sido
                        solicitado.
                        <br/>
                        <br/>

                        LEGITIMACIÓN:
                        Ejecución de contrato, participación activa, peticiones y consentimiento.
                        <br/>
                        <br/>

                        DESTINATARIOS:
                        Encargados de Tratamiento dentro y fuera de la UE y/o, en caso de haberse solicitado
                        y
                        otorgado consentimiento,
                        a empresas responsables de algunos de los servicios ofrecidos en el sitio web.
                        Puede consultarlos a través de la URL indicada en la Política de Privacidad.
                        <br/>
                        <br/>

                        DERECHOS:
                        Acceder, rectificar, portar y suprimir los datos, así como otros derechos, tal y
                        como se
                        explica en la información adicional.
                        <br/>
                        <br/>

                        INFORMACIÓN ADICIONAL:
                        Puede consultar la información adicional y detallada sobre cómo tratamos sus datos
                        en
                        la <a rel='noopener noreferrer' target='_blank' href={'/terms#privacy'}>política de
                        privacidad</a>.
                    </p>

                </div>
                <div className={importedStyles.termsCheck}>
                    <Checkbox
                        style={{color: '#00a3da'}}
                        onChange={this.handleCheckChange}
                        checked={this.state.checkTerms}
                        name='checkTerms'
                    />
                    <p className={importedStyles.linkToTerms}>
                        He leído y acepto la <a rel='noopener noreferrer' target='_blank'
                                                href={'https://grabaloapp.com/politica-de-privacidad'}>política de privacidad</a>, la
                        <a rel='noopener noreferrer' target='_blank' href={'https://grabaloapp.com/politica-de-cookies'}> política de
                            cookies</a> y
                        <a rel='noopener noreferrer' target='_blank' href={'https://grabaloapp.com/aviso-legal'}> las
                            condiciones
                            de uso</a> del sitio web y <b>soy mayor de edad</b>.
                    </p>
                </div>
            </React.Fragment>
        )
    };


    render() {
        const fullscreen = window.matchMedia("(max-width: 768px)").matches;
        let disabledQueryEntity = (this.state.waitingResponse || !this.state.contactName || !this.state.name
            || !this.state.email || this.state.authEmailError || !this.state.password || !this.state.checkTerms || !this.state.rol);
        let disabledQuery = (this.state.waitingResponse || !this.state.name || !this.state.email || this.state.authEmailError ||
            !this.state.password || !this.state.checkTerms || !this.state.rol);

        return (
            <React.Fragment>
                <Dialog
                    open={this.props.open}
                    maxWidth={'xs'}
                    onClose={this.handleModalClose}
                    fullWidth={true}
                    fullScreen={fullscreen}
                    scroll={"body"}
                    classes={{paper: importedStyles.dialogMain, paperFullScreen: importedStyles.dialogMobileFullScreen}}
                    BackdropProps={{className: importedStyles.dialogBackdrop}}
                >
                    <IconButton className={importedStyles.closeModalIcon} onClick={this.handleModalClose}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle>{this.state.actualStep === 1 && 'Regístrate'}</DialogTitle>
                    <DialogContent className={importedStyles.dialogContent}
                                   classes={{root: importedStyles.dialogRootFix}}>
                        <form className={importedStyles.RegisterFormCardContent}>
                            {this.renderStepContent()}
                        </form>
                        <div className={importedStyles.dialogActions}>
                            {this.state.actualStep === 2 &&
                                <div className={importedStyles.actionsContainer}>
                                    <Button
                                        id={'registerButton'}
                                        fullWidth
                                        className={importedStyles.submitButton}
                                        variant='contained'
                                        disabled={this.state.rol === 'entity' ? disabledQueryEntity : disabledQuery}
                                        onClick={this.handleRegister}
                                    >
                                        Regístrate {this.state.waitingResponse &&
                                        <CircularProgress className={importedStyles.progressCircle}/>}
                                    </Button>
                                </div>
                            }
                            <div className={importedStyles.footerTextContainer}>
                                <p>¿Ya tienes cuenta?
                                    <span className={importedStyles.newPasswordLink}
                                          onClick={this.handleLogin}>Accede</span>
                                </p>
                            </div>
                        </div>
                        <Snack style={{zIndex: '8000'}}/>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

}


const mapStateToProps = ({RegisterModalReducer}) => {
    return ({
        open: RegisterModalReducer.open,
        nextRoute: RegisterModalReducer.nextRoute,
    });
};
export default connect(mapStateToProps)(RegisterModal);
