
import React, {useEffect, useState} from "react";
import connect from "react-redux/es/connect/connect";
import history from "../../helpers/history";
import importedStyles from './Concurso.module.sass';
import {AddRounded, Favorite, Room, WatchLater} from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/CheckCircleRounded'
import ShowMoreIcon from '@material-ui/icons/ExpandMoreRounded'
import ShowLessIcon from '@material-ui/icons/ExpandLessRounded'
import SendIcon from '@material-ui/icons/SendRounded'
import Button from "@material-ui/core/Button/Button";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {Chip, Divider, Hidden, IconButton, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyAlert from "../../components/MyAlert/MyAlert";
import Snack from "../../components/Snack/Snack";
import {setRequest, setVideoToRequestFlag} from "./Redux/ConcursoActions";
import moment from "moment";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Fab from "@material-ui/core/Fab";
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Pagination from "@material-ui/lab/Pagination";
import Fade from "@material-ui/core/Fade";
import baseUrl from "../../helpers/baseUrl";
import Interweave, {ALLOWED_TAG_LIST} from "interweave";
import VideoCard from "../../components/VideoCard/VideoCard";
import RegisterModalRequest from "./Components/RegisterModalRequest/RegisterModalRequest";
import {openRegisterRequestModal} from "./Components/RegisterModalRequest/Redux/RegisterModalRequestActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Helmet} from "react-helmet/es/Helmet";
import SuggestedLoginModalRequest from "./Components/SuggestedLoginModalRequest/SuggestedLoginModalRequest";
import {
    openSuggestedLoginModalRequest
} from "./Components/SuggestedLoginModalRequest/Redux/SuggestedLoginModalRequestActions";
import {logout} from "../Auth/authActions";
import {generatePath} from "react-router-dom";
import NoVideosMessage from "../../components/NoVideosMessage/NoVideosMessage";
import {videosPerPage} from "../../constants/constants";
import clip from "text-clipper";
import {isMobile} from "react-device-detect";
import UploadFilesModal from "../../components/UploadFilesModal/UploadFilesModal";
import {Toaster} from "react-hot-toast";
import clsx from "clsx";
import {throttle} from "lodash";
import DialogActions from "@material-ui/core/DialogActions";
import {secondaryColor} from "../../styles/palette";
import {ReactComponent as ShareArrow} from "../../icons/share_arrow.svg";
import ShareModal from "../../components/ShareModal/ShareModal";
import {FilesGrid} from "../../components/FilesGrid/FilesGrid";
import {AdBanner} from "../../components/AdBanner/AdBanner";
import CountdownTimer from "react-component-countdown-timer";
import "react-component-countdown-timer/lib/styles.css";
import {sendAdInteraction} from "../../helpers/stats/statsAPI";
import AdWithSensorWrapper from "../../components/AdWithSensorWrapper/AdWithSensorWrapper";
import {LanguageSelector} from "../../components/LanguageSelector/LanguageSelector";
import {getTranslatedText} from "../../helpers/language";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import WhatsappButton from "../../components/WhastappButton/WhatsappButton";
import {setCurrentAdIndex, setPopupAds} from "../DetailVideoModal/Redux/detailVideoModalActions";


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Fade in={value === index} timeout={0} >
            <div
                role="tabpanel"
                // hidden={value !== index}
                // style={{ display: value === index ? 'block' : 'none' }}

                id={`challenge-tabpanel-${index}`}
                aria-labelledby={`challenge-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3} style={{paddingBottom: 2}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        </Fade>

    );
}

class Concurso extends React.Component {


    state = {
        navigatorTabTitle: 'Grábalo: sube tu vídeo y participa',
        navigatorTabIcon: null,
        modal: false,
        expired: false,
        checkingExpired: true,
        gettingVideos: false,
        currentTab: 0,
        currentAdIndex: 0,
        adsArray: [],
        leftBanners: [],
        rightBanners: [],
        currentSidesBannersIndex: 0,
        currentLeftBanner: null,
        currentRightBanner: 0,
        cardAdsArray: [],
        videos: [],
        videosOrderedByDate: [],
        videosOrderedByVotes: [],
        totalVideosCount: 0,
        currentPage: 1,
        requestUploadedVideos: [],
        alreadyUploadedUserVideos: [],
        videoUuid: '',
        actualVideoIndex: null,
        videoToShow: null,
        selectedVideo: null,
        selectedVideoUuid: '',
        videoGallery: false,
        windowInitialHeight: 0,
        keyboardOnScreen: false,
        confirmationAssignModal: false,
        sendingVideo: false,
        waitingResponse: true,
        shareModal: false,
        shouldRenderVideos: false,
        openDetailVideoModal: false,
        showMoreDescription: false,
        showPremio: true,
        showConsejos: false,
        openUploadFilesModal: false,
        filesFilter: 'recientes',
        gradientStyles: {},
        viewType: 'cards',
        changeBannersIntervalId: 0,
    };

    translateTexts = () => {

        this.forceUpdate();

    }

    componentDidMount = async () => {


        window.scrollTo({top: 0, behavior: 'smooth'});

        const {requestId} = this.props.match.params;
        this.props.dispatch(setCurrentAdIndex(0));


        await this.getRequestData(); // Await untill we get request data an populate redux with it, if we don't wait, redux can be empty when validating the following getRequestVideos conditional

        if (this.props.request && this.props.request.isOpen) {
            await this.getRequestUploadedVideos();

        }
        if (this.props.request) {
            setTimeout(() => {
                let defaultLanguage = this.props.request.defaultLanguage;
                let languageCookie = localStorage.getItem('grabalo-user-language');
                if (defaultLanguage) {
                    localStorage.setItem('grabalo-user-language', defaultLanguage);
                }
            }, 500)
        }

        window.addEventListener("localStorageChange", this.translateTexts);

        //Check si el concurso ha expirado
        await this.checkExpired();

        //Si el concurso no tiene los videos visibles, mostramos la descripción al completo
        if (this.props.request && !this.props.request.isOpen) {
            this.setState({showMoreDescription: true});
        }
        let adsArray = [];
        let cardAdsArray = [];
        if (this.props.request && this.props.request.ads && this.props.request.ads.length >= 0) {
            //get only needed types of ads

            adsArray = this.props.request.ads.filter(item => item.type === 'intersection-banner');
            cardAdsArray = this.props.request.ads.filter(item => item.type === 'mobile-card');

            let verticalBanners = this.shuffleArray(this.props.request.ads.filter(item => item.type === 'banner_vertical'));
            this.props.dispatch(setPopupAds(verticalBanners));

            if (adsArray || adsArray.length >= 0) {
                adsArray = this.shuffleArray(adsArray)
                // adsArray.sort(() => 0.5 - Math.random())
            }

            if (cardAdsArray || cardAdsArray.length >= 0) {
                cardAdsArray = this.shuffleArray(cardAdsArray)
                // cardAdsArray.sort(() => 0.5 - Math.random())
            }
            this.setState({adsArray: adsArray, repeatedAds: adsArray, cardAdsArray: cardAdsArray})
        }


        this.setState({windowInitialHeight: window.innerHeight});
        window.addEventListener('resize', throttle(() => {
            this.hideButtonOnKeyboardShowed();
        }, 300), {passive: true});
        window.addEventListener('resize', throttle(() => {
            this.onHeaderImageResize();
        }, 300), {passive: true});
        // window.addEventListener('resize', this.onHeaderImageResize);

        //Analytics
        ReactPixel.trackCustom('NuevaVistaRetosMvp', {requestId: requestId ? requestId : this.props.request.uuid});
        ReactPixel.trackCustom('LandingConcurso', {requestId: requestId ? requestId : this.props.request.uuid});
        ReactGA.pageview(window.location.pathname);


        //---- CHANGE BANNERS IMG INTERVAL ---//

        /*this.bannersInterval = setInterval(() => {
            const state = this.state;
            let k = state.currentSidesBannersIndex;

            if (k > state.leftBanners.length-1){
                k = 0
            }

            let leftAd = state.leftBanners[k];
            let rightAd = state.rightBanners[k];

            console.info(state.leftBanners)
            console.info(k)
            this.setState({
                currentLeftBanner: leftAd,
                currentRightBanner: rightAd,
                currentSidesBannersIndex: k++
            })


        }, 5000);*/


        //-------//
    };


    async componentDidUpdate(prevProps, prevState, snapshot) {
        // const urlObserver = await this.UrlParamsObserver(prevProps);

        let loggedStatusChange = !prevProps.logged && this.props.logged;
        let requestUrlChanged = this.props.match.params.requestId !== prevProps.match.params.requestId;
        // let filesFilterChanged = this.state.filesFilter !== prevState.filesFilter;


        if (requestUrlChanged || loggedStatusChange) {
            this.getRequestUploadedVideos();
            await this.checkExpired()
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hideButtonOnKeyboardShowed);
        window.removeEventListener('resize', this.onHeaderImageResize);
        window.removeEventListener("localStorageChange", this.translateTexts);
        // clearInterval(this.bannersInterval)
    }


    hideButtonOnKeyboardShowed = () => {
        let actualHeight = window.innerHeight;

        if ((this.state.windowInitialHeight * 0.7) > actualHeight) {//if the current viewport height is smaller than 70% of the original size, it means that the keyboard is up
            this.setState({keyboardOnScreen: true});
        } else if ((this.state.windowInitialHeight * 0.7) < actualHeight) {//if the current viewport height is larger than 70% of the original size, it means that the keyboard is down
            this.setState({keyboardOnScreen: false});
        }
    };

    changeNavigatorTabTitle = (newTabTitle) => {
        if (newTabTitle && newTabTitle.trim().length > 0) this.setState({navigatorTabTitle: newTabTitle});
    }

    changeNavigatorTabIcon = (newIconRef) => {
        if (newIconRef && newIconRef.trim().length > 0) {
            // let src = 'https://drive.google.com/uc?export=view&id=' + newIconRef;
            let src = 'https://drive.google.com/thumbnail?id=' + newIconRef + '&sz=w1000';
            this.setState({navigatorTabIcon: src});
        }
    }

    async getRequestData() {
        this.setState({
            waitingResponse: true
        });

        const {requestId} = this.props.match.params;
        const userUuid = this.props.user ? this.props.user.uuid : null;

        await fetch(apiBaseUrl + 'requests/getUniqueRequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({requestId, userUuid})

        }).then(res => res.json())
            .then(async (response) => {
                    this.setState({waitingResponse: false});
                    if (!response.error && response.request) {
                        await this.props.dispatch(setRequest(response.request));
                        this.changeNavigatorTabTitle(response.request.name);
                        this.changeNavigatorTabIcon(response.request.avatar);
                    } else {
                        this.props.dispatch(setRequest({}));
                        this.props.dispatch(showSnack('Error al cargar los datos, vuelve a intentarlo en unos minutos', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.props.dispatch(setRequest({}));
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelve a intentarlo en unos minutos', snackTypes.error));
                }
            )
    };

    sortVideos = (videos) => {

        let byDate = [...videos].sort((a, b) => {
            return new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime()
            // console.info('Date video A: ', moment(a.uploadDate))
            // console.info('Date video B: ', moment(b.uploadDate))
            // console.info('Check: ', moment(b.uploadDate).isAfter(moment(a.uploadDate)))
            //
            // return moment(b.uploadDate).isAfter(moment(a.uploadDate))
        });
        let byVotes = [...videos].sort((a, b) => {
            return b.pivot.likes - a.pivot.likes
        });


        this.setState({videosOrderedByVotes: byVotes, videosOrderedByDate: byDate})
    }

    getRequestUploadedVideos = () => {

        this.setState({gettingVideos: true});
        const {requestId} = this.props.match.params;

        //let currentPage = parseInt(this.state.currentPage) - 1; //fix offset for backend consistency (must start at 0, not 1, but started in 1 for visual pagination purposes)

        fetch(apiBaseUrl + 'requests/listRequestVideos', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({requestId}),
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({
                    shouldRenderVideos: true,
                    requestUploadedVideos: response.videos,
                    totalVideosCount: response.totalVideosCount,
                    gettingVideos: false
                });
                this.sortVideos(response.videos);
                this.handleUrlVideoParam();
            } else {
                throw new Error(response.errorMessage);
            }

        }).catch((error) => {
            this.setState({
                shouldRenderVideos: false,
                gettingVideos: false
            });
            console.error('Error: ', error);
        });
    };

    handlePageChange = (event, page) => {
        let requestId = this.props.match.params.requestId;
        let videoId = this.props.match.params.videoId;

        //Change url page param
        let path = generatePath(this.props.match.path, {requestId, videoId});
        this.props.history.replace(path);

        //Set
        this.setState({currentPage: page}, () => {
            this.getRequestUploadedVideos();
        });
    };
    renderPagination = () => {
        let totalPages = Math.ceil(parseFloat(this.state.totalVideosCount / videosPerPage)).toString(); //rounding up to next integer

        let isMobile = window.matchMedia("(max-width: 768px)").matches ? 'small' : 'medium';
        return (
            <Fade in={this.state.totalVideosCount > 9}>
                <Box className={importedStyles.paginationContainer}>
                    <Pagination
                        count={totalPages}
                        page={this.state.currentPage} onChange={this.handlePageChange}
                        boundaryCount={1}
                        siblingCount={1}
                        size={isMobile}
                        showFirstButton
                        showLastButton
                    />
                </Box>
            </Fade>
        )
    };
    handleModalStatus = () => {
        this.setState({modal: !this.state.modal});
    };

    handleVideoSelection = (videoData) => () => {
        this.setState({selectedVideo: videoData, selectedVideoUuid: videoData.uuid});
    };

    handleAssignConfirmationModal = () => {
        this.setState({confirmationAssignModal: !this.state.confirmationAssignModal})
    };


    handleShare = () => {
        if (isMobile && navigator.share) {
            navigator
                .share({
                    //title: document.title,
                    //text: '',
                    //url: window.location + '/' + this.props.video.urlSlug
                    url: window.location.href
                })
                .then(() => {
                    console.log('Compartido con éxito! 🎉')
                })
                .catch(err => {
                    console.error(err)
                });
        } else {
            this.setState((prevState, prevProps) => {
                return {
                    shareModal: !prevState.shareModal
                }
            });
        }
    }
//////////////////
    UrlParamsObserver = async prevProps => {

        //If we dont have videoId now but have it in prevProps, means we must close modal (this code is to handle native navigation behavior)
        if (!this.props.match.params.videoId && prevProps.match.params.videoId) {
            this.setState({openDetailVideoModal: false, selectedVideo: null, actualVideoIndex: null});
        } else {
            //If actual videoId differs from previous one, must handle it
            if (this.props.match.params.videoId !== prevProps.match.params.videoId) {
                this.handleUrlVideoParam();
            }
        }
    };
    handleUrlVideoParam = () => {
        let urlVideoId = this.props.match.params.videoId;
        if (urlVideoId) {
            let videosCollection = this.state.requestUploadedVideos;
            let indexOfRequestedVideo = videosCollection.findIndex(item => item.urlSlug === urlVideoId);
            let video = videosCollection[indexOfRequestedVideo];
            this.setState({openDetailVideoModal: true, videoToShow: video, actualVideoIndex: indexOfRequestedVideo});
        }
    };
    handleDetailVideoModal = (video, actualVideoIndex) => () => {
        this.setState({openDetailVideoModal: true, videoToShow: video, actualVideoIndex: actualVideoIndex});

        let requestId = this.props.match.params.requestId;
        //let page = this.state.currentPage;
        let videoUrl = video.urlSlug;

        //Change url page param
        let newPath = generatePath(this.props.match.path, {requestId: requestId, videoId: videoUrl});
        history.push(newPath);

    };

    handleVideoIndex = (actualVideoIndex) => () => {
        this.setState({actualVideoIndex: actualVideoIndex});
    };
    closeDetailVideoModal = () => {
        this.setState({openDetailVideoModal: false, videoToShow: null, actualVideoIndex: null});

        //Change url page param
        let requestId = this.props.match.params.requestId;

        let newPath = generatePath(this.props.match.path, {requestId: requestId});
        history.push(newPath);
    };
    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.requestUploadedVideos[nextIndex];
        history.push(nextVideo.urlSlug);
        this.setState({videoToShow: nextVideo, actualVideoIndex: nextIndex});
    };
    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.requestUploadedVideos[nextIndex];
        history.push(nextVideo.urlSlug);
        this.setState({videoToShow: nextVideo, actualVideoIndex: nextIndex});
    };

///////////////////////////
    toggleDrawer = (toggle) => {
        if (!this.props.logged) {
            this.props.dispatch(openSuggestedLoginModalRequest(window.location.pathname))
        } else {
            if (toggle) {
                ReactPixel.trackCustom('ClickOnParticipate', {
                    user: this.props.user.uuid,
                    request: this.props.request.uuid
                });
            }

            this.setState({videoGallery: toggle});
        }
    };

    updateVideoVotes = async (numLikes) => {
        let newVideos = [...this.state.requestUploadedVideos];
        let newVideo = {...this.state.requestUploadedVideos[this.state.actualVideoIndex]};
        // newVideo.votes_on_request = newVotes;
        newVideo.pivot.likes = numLikes;
        newVideos[this.state.actualVideoIndex] = newVideo;
        await this.setState({requestUploadedVideos: newVideos})
    };

    renderLocation = (location) => {
        return (
            <div className={importedStyles.locationContainer}>
                <Room/>
                <p>{location}</p>
            </div>
        )
    };

    checkExpired = () => {
        let hasExpired = false;

        if (this.props.request && this.props.request.limitDate) {
            hasExpired = moment(this.props.request.limitDate).isBefore(moment());
        }
        this.setState({checkingExpired: hasExpired, expired: hasExpired});
    }

    getRemainingTime = () => {
        if (this.props.request.limitDate) {
            let request = this.props.request;
            let expired = moment(request.limitDate).isBefore(moment());
            return expired ? '[Cerrado]' : 'Se cierra en ' + moment.duration(moment(request.limitDate).diff(moment())).humanize();
        } else {
            return '';
        }

    }
    renderPrivateVideosMessage = () => {
        return (
            <Box className={importedStyles.videosContainer}>
                <small style={{color: '#797979'}}>Los archivos de este evento son privados y no se mostrarán</small>
            </Box>
        )
    }

    renderChallengeDescription = () => {
        let request = this.props.request;
        let description = request.description.split('<a').join('<a target="_blank"');

        return (
            <div className={importedStyles.requestDescriptionContainer} id={''}>
                <Interweave
                    allowList={ALLOWED_TAG_LIST.concat('iframe')}
                    content={description}
                    attributes={{className: importedStyles.HtmlParserContent}}
                    containerTagName={'div'}
                />

                {/* <Markup content={request.description}/>*/}

                {request.showLegalTerms && this.renderLegalTermsLink()}
                <Button className={importedStyles.descriptionExpansorButton} onClick={this.handleDescriptionExpansor}>Leer
                    menos <ShowLessIcon/></Button>
            </div>
        )
    }

    renderLegalTermsLink = () => {
        let request = this.props.request;
        return (
            <a href={baseUrl + 'terminos-condiciones/' + request.url} target={'_blank'}
               className={importedStyles.requestTermsLink}>
                Bases legales
            </a>
        )
    }


    renderShortChallengeDescription = () => {
        let request = this.props.request;
        let description = request.description.split('<a').join('<a target="_blank"');

        description = clip(description, 780, {html: true, maxLines: 5});


        return (
            <div className={importedStyles.requestDescriptionContainer}>
                {/*<Dotdotdot clamp={3} className="three-line-text-clamp">*/}
                <Interweave
                    allowList={ALLOWED_TAG_LIST.concat('iframe')}
                    content={description}
                    attributes={{className: importedStyles.HtmlParserContentShort}}
                    containerTagName={'div'}
                    style={{webkitBoxOrient: 'vertical'}}
                />
                {/*</Dotdotdot>*/}
                {/*<Markup content={description} containerTagName={'div'}/>*/}
                <Button className={importedStyles.descriptionExpansorButton} onClick={this.handleDescriptionExpansor}>Leer
                    más <ShowMoreIcon/></Button>
            </div>
        );
    }


    renderRewardDescription = () => {
        let request = this.props.request;
        let description = request.rewardDescription && request.rewardDescription.length > 0 ? request.rewardDescription : 'No se ha proporcionado una descripción del premio';

        return description.split('<a').join('<a target="_blank"')

    }

    renderAdvicesTabContent = () => {

        let content = 'No se ha proporcionado una descripción con consejos';

        if (this.props.request && this.props.request.advices && this.props.request.advices.length > 0) {
            // content = this.props.request.advices;
            content = this.props.request.advices.split('<a').join('<a target="_blank"');

        }

        return (
            <Interweave
                allowList={ALLOWED_TAG_LIST.concat('iframe')}
                content={content}
                attributes={{className: importedStyles.HtmlParserContentAdvices}}
                containerTagName={'div'}
            />
        )

    }


    tabProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    onTabChipClick = (e) => {
        setTimeout(() => {
            e.target.scrollIntoView({behavior: "smooth", inline: "center", block: 'nearest'});
            // console.info('click chip')

        }, 450)

    }
    renderTabsChips = () => {
        const rawData = this.props.request.chips;

        const sortedArray = rawData.sort((a, b) => a.order - b.order); // b - a for reverse sort

        let chips = [];

        for (let i = 0; i < sortedArray.length; i++) {
            let item = sortedArray[i];
            if (!item.deleted) {
                chips.push(
                    <Tab
                        classes={{root: importedStyles.tabButton, selected: importedStyles.tabButtonSelected}}
                        textColor={'primary'}
                        label={getTranslatedText(item.title, false)}
                        value={i}
                        onClick={this.onTabChipClick}
                        {...this.tabProps(i)}
                    />
                )
            }
        }

        return chips; // return array of React components
    }



    renderTabsContent = () => {


        const rawData = this.props.request.chips;

        const sortedArray = rawData.sort((a, b) => a.order - b.order); // b - a for reverse sort

        let tabsContent = [];

        console.info('render tabv')


        for (let i = 0; i < sortedArray.length; i++) {
            let item = sortedArray[i];

            if (!item.deleted) {

                let content = getTranslatedText(item.content);
                content = content.split('<a').join('<a target="_blank"');



                if (this.props.request.isOpen) {
                    //Recortamos el contenido si esta tab/chip es 'collapsable', tiene los videos visibles (de lo contrario mostramos el contenido completo) y su estado dinamico [TabContentCollapsed+i] está como undefined o true
                    if (item.collapsable && (this.state['TabContentCollapsed-' + i] === undefined || this.state['TabContentCollapsed-' + i])) {
                        content = clip('<div>' + content + '</div>', 780, {html: true, maxLines: 5});
                    }
                }


                tabsContent.push(

                    <TabPanel key={`challenge-tabpanel-${i}`} value={this.state.currentTab} index={i} className={importedStyles.tabPanel}>
                        <div className={importedStyles.requestDescriptionContainer} id={'TabPanelBox-' + i}>
                            <Interweave
                                allowList={ALLOWED_TAG_LIST.concat('iframe')}
                                content={content}
                                attributes={{className: importedStyles.HtmlParserContent}}
                                containerTagName={'div'}
                            />
                            {item.showLegalTermsLink && this.renderLegalTermsLink()}
                            {item.collapsable && this.props.request.isOpen && this.renderCollapsableButtons(i)}

                        </div>
                    </TabPanel>
                );


            }
        }

        return tabsContent; // return array of React components
    }

    handleCollapsableStates = (index) => {

        if (this.state['TabContentCollapsed-' + index] === undefined) {
            this.setState({['TabContentCollapsed-' + index]: false});
        } else {
            this.setState((prevState, prevProps) => {
                return {
                    ['TabContentCollapsed-' + index]: !prevState['TabContentCollapsed-' + index]
                }
            })
        }


    }

    renderCollapsableButtons = (index) => {

        let buttonContent = <>Leer menos <ShowLessIcon/></>;

        if (this.state['TabContentCollapsed-' + index] || this.state['TabContentCollapsed-' + index] === undefined) {
            buttonContent = <>Leer más <ShowMoreIcon/></>;
        }

        return (
            <Button className={importedStyles.descriptionExpansorButton}
                    onClick={() => this.handleCollapsableStates(index)}>
                {buttonContent}
            </Button>
        )
    }


    getRecentsFilterLabel = () => {
        if (this.props.request && this.props.request.uuid === 'a70ab48c-7075-40fe-8208-eca2262177e6') {
            return localStorage.getItem('grabalo-user-language') === 'vlc' ? 'Ordre de projecció' : 'Orden de proyección';
        }
        return localStorage.getItem('grabalo-user-language') === 'vlc' ? 'Més recents' : 'Más recientes';
    }

    getVotesFilterLabel = () => {
        if (this.props.request && this.props.request.uuid === 'a70ab48c-7075-40fe-8208-eca2262177e6') {
            return localStorage.getItem('grabalo-user-language') === 'vlc' ? 'Curts més votats' : 'Cortos más votados';
        }

        return localStorage.getItem('grabalo-user-language') === 'vlc' ? 'Més votats' : 'Más votados';
    }

    renderView = () => {
        const request = this.props.request;
        if (request === undefined || request === null || Object.keys(request).length === 0) {
            return (
                <p className={importedStyles.errorText}>Ha ocurrido un error a la hora de cargar o el evento que buscas
                    ya no existe.</p>
            )
        }

        function a11yProps(index) {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        }


        function TabPanel(props) {
            const {children, value, index, ...other} = props;


            return (
                <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`challenge-tabpanel-${index}`}
                    aria-labelledby={`challenge-tab-${index}`}
                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            <Typography>{children}</Typography>
                        </Box>
                    )}
                </div>
            );
        }


        let isMobile = window.matchMedia("(max-width: 768px)").matches;

        // const grabaloDriveUrl = 'https://drive.google.com/uc?export=view&id=';
        const grabaloDriveUrl = 'https://drive.google.com/thumbnail?id=';
        let coverImage = 'https://grabalo-thumbnails.s3.eu-west-3.amazonaws.com/de-default-event-image-ec3d'; //fallback cover img

        if (isMobile && request.mobileHeaderImg) {
            coverImage = grabaloDriveUrl + request.mobileHeaderImg + '&sz=w1000';
        }

        if ((!isMobile && request.driveId) || (isMobile && !request.mobileHeaderImg)) {
            coverImage = grabaloDriveUrl + request.driveId + '&sz=w1000';
        }

        return (
            <React.Fragment>
                <div>
                    <div id={'headerImgWrapper'} style={{position: 'relative'}}>
                        {this.renderCountdownClock()}

                        <Fade in={this.props.request && this.props.request.showShareLpButton} mountOnEnter
                              unmountOnExit>
                            <IconButton
                                style={{}}
                                className={importedStyles.shareArrowIconContainer}
                                onClick={this.handleShare}>
                                <ShareArrow className={importedStyles.shareArrowIcon}/>
                            </IconButton>
                        </Fade>

                        <img
                            src={coverImage}
                            // src={'https://i.ibb.co/6mmbgS8/ESCRITORIO-Cabecera-Jezabel-y-Toni.jpg'}
                            id={'headerImg'}
                            alt={request.title}
                            className={importedStyles.headImage}
                            onLoad={this.onHeaderImageResize}
                            onresize={this.onHeaderImageResize}
                        />
                    </div>

                    <List disablePadding>
                        <ListItem className={importedStyles.requestFirstInfoItem}>
                            <ListItemAvatar className={importedStyles.entityAvatarWrapper}>
                                <Avatar
                                    src={grabaloDriveUrl + request.avatar}
                                    className={importedStyles.entityAvatar}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                className={importedStyles.requestTitleAndTime}
                                classes={{
                                    primary: importedStyles.requestTitle,
                                    secondary: importedStyles.requestSubtitle
                                }}
                                primary={getTranslatedText(request.title, false)}
                                // secondary={this.getRemainingTime()}
                            />
                        </ListItem>
                    </List>
                </div>

                <div className={importedStyles.contentBox}>

                    <Tabs
                        value={this.state.currentTab}
                        onChange={(event, newTab) => {
                            this.setState({currentTab: newTab})
                            // console.info('NEW -> ', newTab)
                        }}
                        textColor="primary"
                        aria-label="sections"
                        variant={'scrollable'}
                        classes={{flexContainer: importedStyles.fixTabsFlex}}
                        // variant={isMobile ? 'scrollable' : 'fullWidth'}
                        // scrollButtons={isMobile && "on"}
                        className={importedStyles.tabsContainer}
                        TabIndicatorProps={{style: {backgroundColor: "transparent", height: '0'}}}
                    >
                        {request.chips && this.renderTabsChips()}

                    </Tabs>

                    {request.chips && this.renderTabsContent()}


                    <Fade in={this.props.request && this.props.request.isOpen}
                          unmountOnExit mountOnEnter>
                        <Box className={importedStyles.videosContainer}>

                            <div className={importedStyles.mainSelectorsContainer}>
                                <Fade in={this.props.request && this.props.request.like_and_share}
                                      unmountOnExit mountOnEnter>
                                    <Box className={importedStyles.filtersBox}>
                                        {/*      <h6>Ordenar por:</h6>*/}
                                        <Chip
                                            icon={this.props.request && this.props.request.uuid === 'a70ab48c-7075-40fe-8208-eca2262177e6' ? null :
                                                <WatchLater/>}
                                            label={this.getRecentsFilterLabel()}
                                            onClick={() => {
                                                this.setState({filesFilter: 'recientes'});
                                            }}
                                            classes={{
                                                // icon: importedStyles.filterChipIcon,
                                                icon: this.state.filesFilter === 'recientes' ? importedStyles.filterChipIconSelected : importedStyles.filterChipIcon,
                                                root: clsx(importedStyles.filterChip, this.state.filesFilter === 'recientes' && importedStyles.filterChipSelected),
                                                label: importedStyles.filterChipLabel

                                            }}
                                        />
                                        <Chip
                                            icon={this.props.request && this.props.request.uuid === 'a70ab48c-7075-40fe-8208-eca2262177e6' ? null :
                                                <Favorite/>}
                                            label={this.getVotesFilterLabel()}
                                            onClick={() => {
                                                this.setState({filesFilter: 'likes'})
                                            }}
                                            classes={{
                                                icon: this.state.filesFilter === 'likes' ? importedStyles.filterChipIconSelected : importedStyles.filterChipIcon,
                                                root: clsx(importedStyles.filterChip, this.state.filesFilter === 'likes' && importedStyles.filterChipSelected),
                                                label: importedStyles.filterChipLabel
                                            }}
                                        />
                                    </Box>
                                </Fade>
                                {/*<Box className={importedStyles.filesViewTypeSelectorContainer}>
                                    <h6>Tipo de vista:</h6>

                                    <Chip
                                        icon={<TableChart/>}
                                        // icon={<ViewColumnRounded/>}
                                        label="Cards"
                                        onClick={() => {
                                            this.setState({viewType: 'cards'});
                                        }}
                                        classes={{
                                            icon: importedStyles.filterChipIcon,
                                            root: clsx(importedStyles.filterChip, this.state.viewType === 'cards' && importedStyles.filterChipSelected),
                                            label: importedStyles.filterChipLabel

                                        }}
                                    />
                                    <Chip
                                        icon={<ViewComfy/>}
                                        // icon={<GridOn/>}
                                        label="Grid"
                                        onClick={() => {
                                            this.setState({viewType: 'grid'})
                                        }}
                                        classes={{
                                            icon: importedStyles.filterChipIcon,
                                            root: clsx(importedStyles.filterChip, this.state.viewType === 'grid' && importedStyles.filterChipSelected),
                                            label: importedStyles.filterChipLabel
                                        }}
                                    />
                                </Box>*/}


                            </div>


                            <Fade in={this.state.gettingVideos} unmountOnExit>
                                <span style={{color: '#797979', display: 'flex', alignItems: 'center'}}>
                                    <CircularProgress size={20} style={{marginRight: 10}}/>
                                    Cargando archivos
                                </span>
                            </Fade>
                            <Fade in={!this.state.gettingVideos && this.state.requestUploadedVideos.length <= 0}
                                  unmountOnExit mountOnEnter>
                                <NoVideosMessage/>
                            </Fade>
                            <Fade in={!this.state.gettingVideos}
                                  unmountOnExit mountOnEnter>
                                {this.renderFilesView()}
                            </Fade>
                        </Box>
                    </Fade>
                    {/*{this.renderPagination()}*/}

                </div>
            </React.Fragment>
        );
    };

    renderFilesView = () => {
        switch (this.state.viewType) {
            case 'cards':
                return this.renderFilesCards();
            case 'grid':
                return this.renderFilesGrid();
            default:
                return this.renderFilesCards();
        }
    }

    renderFilesGrid = () => {
        let files = [];

        switch (this.state.filesFilter) {
            case 'recientes':
                files = this.state.videosOrderedByDate;
                break;
            case 'likes':
                files = this.state.videosOrderedByVotes;
                break;
            default:
                files = this.state.requestUploadedVideos;
                break;
        }

        return (
            <FilesGrid data={files}/>
        )
    }

    handleParticipateButtonClick = () => {
        this.setState({openUploadFilesModal: true});
        // if (this.state.openDetailVideoModal)
        //     this.closeDetailVideoModal();
        // this.props.dispatch(openUploadVideoModal());
        // ReactGA.ga('send', 'event', 'click', 'ClickOnParticipate');
        ReactGA.event({
            category: 'click',
            action: 'ClickOnParticipate',
            label: this.props.request ? this.props.request.uuid : 'notRequest'
        });
    };

    renderParticipateButton = () => {
        // Si hay user logueado y es una empresa, no mostramos nada
        if (this.props.user && this.props.user.entity) {
            return false;
        }


        // Si el concurso ha caducado y tiene la flag canParticipateWhenTimeExpired a false, no mostramos nada
        if (this.props.request && this.props.request.canParticipateWhenTimeExpired === false && this.state.expired) {
            return false;
        }


        return (
            <div className={importedStyles.videoActionsButtons}>
                <Button variant={'contained'} className={importedStyles.mainActionButton}
                        onClick={this.handleParticipateButtonClick}>
                    <AddRounded width={'0.9em'} height={'0.9em'}
                                style={{marginRight: 5, marginLeft: -5, width: '0.9em', height: '0.9em'}}/> Participar
                </Button>
            </div>

        );
    };
    renderParticipateButtonDesktop = () => {
        return (
            <div className={importedStyles.videoActionsButtonsDesktop}>
                <Fab variant={'extended'} size={"large"} className={importedStyles.mainActionButtonDesktop}
                     onClick={this.handleParticipateButtonClick}>
                    Quiero participar
                </Fab>
            </div>

        );
    };

    renderSelectedTileOverlay = () => {
        return (
            <React.Fragment>
                <div className={importedStyles.tileSelectedOverlay}>
                    <CheckIcon className={importedStyles.checkIcon}/>
                </div>
            </React.Fragment>
        );
    };

    renderSendVideoButton = () => {
        if (this.state.selectedVideo) {
            return (
                <Button
                    variant={"contained"}
                    size={"small"}
                    disabled={this.state.sendingVideo}
                    onClick={this.handleAssignConfirmationModal}
                    className={importedStyles.sendVideoButton}
                >
                    ENVIAR VIDEO <SendIcon className={importedStyles.sendIcon}/>
                    {this.state.sendingVideo && <CircularProgress size={24} className={importedStyles.progressCircle}/>}
                </Button>
            );
        }
    };

    renderAssignConfirmationModal = () => {
        return (
            <MyAlert
                functionOk={this.handleAssignSelectedVideo}
                handleModalStatus={this.handleAssignConfirmationModal}
                openModal={this.state.confirmationAssignModal}
                title={'Participar'}
                subtitle={
                    <p>Tu archivo
                        <i> "{this.state.selectedVideo.title}" </i>
                        será enviado para participar en el proceso de selección.
                    </p>}
            />
        );
    };

    handleUploadVideoButtonClick = async () => {
        await this.props.dispatch(setVideoToRequestFlag(true));
        await this.props.dispatch(setRequest(this.props.request));
        history.push('/uploadVideo');
    };

    renderUploadedVideoCard = (videoData, index) => {
        let videos = this.state.requestUploadedVideos;

        switch (this.state.filesFilter) {
            case 'recientes':
                videos = this.state.videosOrderedByDate;
                break;
            case 'likes':
                videos = this.state.videosOrderedByVotes;
                break;
            default:
                //do nothing
                break;
        }
        let index2 = parseInt(index);

        let prevVideo = index2 > 0 && videos[index2 - 1];
        let nextVideo = index2 < videos.length && videos[index2 + 1];


        let trimmedTitle = videoData.title.length > 70 ? videoData.title.substring(0, 70) + '...' : videoData.title;
        let fadeTime = index > 5 ? 1500 : index * 300;

        let withLikes = false;
        if (this.props.request && this.props.request.like_and_share) {
            withLikes = true;
        }

        // console.log('Limite ->',this.props.request.likeable_limit_date)
        // console.log('Ahora ->', moment.now())
        // console.log('Caducado? -> ', moment(this.props.request.likeable_limit_date).isBefore(moment.now()))

        return (
            <Fade in={true} unmountOnExit mountOnEnter timeout={fadeTime}>
                <Grid key={'gridFileItem' + videoData.uuid} item xs={12} sm={6} md={4} xl={3} className={importedStyles.gridFileItem}>
                    <VideoCard
                        video={videoData}
                        mediaType={videoData.media_type}
                        title={videoData.title}
                        author={videoData.user ? videoData.user.name : videoData.name}
                        image={videoData.coverUrl}
                        withLikes={withLikes}
                        withShare={!!(this.props.request && this.props.request.like_and_share)}
                        location={this.props.location}
                        match={this.props.match}
                        prevVideo={prevVideo}
                        nextVideo={nextVideo}
                        alternativeActionsStyle={true}
                        withAds={true}
                    />


                </Grid>
            </Fade>
        );
    };

    getInterval = (lowEnd, highEnd) => {
        let list = [];
        for (let i = lowEnd; i <= highEnd; i++) {
            list.push(i);
        }
        return list;
    }


    renderFilesCards = () => {

        let videos = this.state.requestUploadedVideos;

        switch (this.state.filesFilter) {
            case 'recientes':
                videos = this.state.videosOrderedByDate;
                break;
            case 'likes':
                videos = this.state.videosOrderedByVotes;
                break;
            default:
                //do nothing
                break;
        }

        let filesCards = videos.map((video, index) => {
            return this.renderUploadedVideoCard(video, index);
        });


        // CARD AD BANNERS

        let cardAdsArray = this.state.cardAdsArray;

        if (cardAdsArray && cardAdsArray.length > 0){
            let k = 0;
            let currentAd = cardAdsArray[k];
            let pos = 0;
            let cardBannerinterval = 4;

            while (pos < filesCards.length) {

                if (pos === 0){
                    pos += cardBannerinterval-1;
                } else {
                    pos += cardBannerinterval;

                }

                currentAd = cardAdsArray[k];

                if (currentAd && currentAd.src_url) {
                    filesCards.splice(pos, 0, this.renderIntersectionAdCard(currentAd));
                }

                if (k < cardAdsArray.length - 1){
                    k++;
                } else {
                    k = 0;
                }

            }


        }


        // INTERSECTION BANNERS
        let adsArray = this.state.adsArray;
        if (adsArray && adsArray.length > 0) {
            const getCols = () => {

                if (window.matchMedia("(min-width: 601px) and (max-width: 959px) ").matches) return 1;
                if (window.matchMedia("(min-width: 960px) and (max-width: 1920px) ").matches) return 2;
                if (window.matchMedia("(min-width: 1920px)").matches) return 3;

                // if (isLg) return 3;
                // if (isXl) return 4;

                return 2;

            }

            const getInterval = () => {

                if (window.matchMedia("(min-width: 601px) and (max-width: 959px) ").matches) return 2;
                if (window.matchMedia("(min-width: 960px) and (max-width: 1920px) ").matches) return 3;
                if (window.matchMedia("(min-width: 1920px)").matches) return 4;


                return 3;

            }

            let adIndex = this.state.currentAdIndex;
            filesCards = filesCards.flatMap((item, index) => {

                let interval = isMobile ? 1 : getInterval();


                if ((index + 1) % interval === 0) {

                    if (!adsArray || adsArray.length <= 0) {
                        return false;
                    }

                    if (adIndex >= adsArray.length) {
                        adIndex = 0;
                    }


                    // eslint-disable-next-line no-extend-native
                    Array.prototype.readItem = function (index) {
                        return this[index % this.length];
                    }
                    let intervalIndexes = this.getInterval(index, index + getCols());

                    // console.info('interval => ', intervalIndexes);

                    let selectedAds = intervalIndexes.map(i => adsArray.readItem(i));

                    let adCard = this.renderIntersectionAdBanner(selectedAds);
                    // let adCard = this.renderIntersectionAdCard(selectedAds);

                    if (isMobile) {
                        let ad = adsArray[adIndex];
                        adCard = this.renderIntersectionAdBannerMobile(ad);
                    }
                    adIndex = adIndex + interval;


                    return [item, adCard]
                } else {
                    return item;
                }
            });


            // Añadimos todos los banners publicitarios al final de todos los archivos, para que siempre se muestren todos aún cuando no exista contenido suficiente

            // eliminamos la ultima linea de anuncios para no duplicar...
            // filesCards.pop();

            let endAds = [];
            for(let i = 0; i < adsArray.length; i++){
                let item = this.renderIntersectionAdBannerRAW(adsArray[i]);
                endAds.push(item);
            }


            let groupedEndAds = [];
            let pos = 0;
            let interval = 3;
            let endCut = interval;

            while (pos < (endAds.length+interval)) {
                if (pos+interval > endAds.length){
                    endCut = endAds.length;
                }


                groupedEndAds.push(
                    <Grid container spacing={4} item xs={12} className={importedStyles.endBannersWrapperItem} style={{marginTop: pos > 0 ? 16 : 0, justifyContent: 'center'}}>
                        {endAds.slice(pos, endCut)}
                    </Grid>
                )

                pos += interval;
                endCut += interval;
            }



            filesCards.push(...groupedEndAds);

            // END de 'Añadimos todos los banners publicitarios al final[...]'

        }



        return (
            <Grid container spacing={4} className={importedStyles.uploadedVideosContainer} key={'filesGridContainer'}>
                {filesCards}
            </Grid>
        );

    };


    renderIntersectionAdCard = (ad) => {

        let isMobileScreen = window.matchMedia("(max-width: 768px)").matches;

            let content = (
                <AdWithSensorWrapper adObject={ad} host={'challenge'}>
                    <Card style={{
                        height: '100%',
                        // borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 18 : ad.border_radius,
                        borderRadius: 18,
                        minHeight: isMobileScreen ? '31rem' : null,
                    }}>
                        <CardActionArea style={{height: '100%'}}>
                            <CardMedia
                                image={ad.src_url}
                                style={{height: '100%', aspectRatio: 9/9}}
                            />
                        </CardActionArea>

                    </Card>

                </AdWithSensorWrapper>
            );

            if (ad.action_url && ad.action_url.trim().length > 0) {
                content = (
                    <AdWithSensorWrapper adObject={ad} host={'challenge'}>
                        <Card style={{
                            height: '100%',
                            // borderRadius: (ad.border_radius === null || ad.border_radius === undefined) ? 18 : ad.border_radius,
                            borderRadius: 18,
                            minHeight: isMobileScreen ? '31rem' : null,

                        }}>
                            <CardActionArea href={ad.action_url} target={'_blank'} onClick={() => sendAdInteraction('click', ad.id)} style={{height: '100%'}}>
                                <CardMedia
                                    image={ad.src_url}
                                    style={{height: '100%', aspectRatio: 9/9}}
                                />
                            </CardActionArea>

                        </Card>

                    </AdWithSensorWrapper>
                );
            }
            return (
                <Grid
                    key={'gridPubliDesktopAdCard' + ad.id}
                    item xs={12} sm={6} md={4} xl={3}>
                    {content}
                </Grid>
            )

    };


    renderIntersectionAdBanner = (ads) => {

        // let ad = this.props.request.ads.find(item => item.type === 'mobile-card');

        let items = ads.map((ad) => {
            let content = (
                <AdWithSensorWrapper adObject={ad} host={'challenge'}>
                    <img src={ad.src_url} alt={'imagen-patrocinador'}
                        // onLoad={() => sendAdInteraction('view', ad.id)}
                         className={importedStyles.desktopBannerIntersection}
                         style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                </AdWithSensorWrapper>
            );

            if (ad.action_url && ad.action_url.trim().length > 0) {
                content = (
                    <AdWithSensorWrapper adObject={ad} host={'challenge'}>

                        <a href={ad.action_url} target={'_blank'} onClick={() => sendAdInteraction('click', ad.id)}>
                            <img src={ad.src_url} alt={'imagen-patrocinador'}
                                // onLoad={() => sendAdInteraction('view', ad.id)}
                                 className={importedStyles.desktopBannerIntersection}
                                 style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                        </a>
                    </AdWithSensorWrapper>
                );
            }
            return (
                <Grid key={'gridPubliDesktopItem' + ad.id} item xs={12} sm={6} md={4} xl={3}>
                    {content}
                </Grid>
            )

        });

        //

        return (
            <Hidden xsDown>
                <Grid container spacing={4} item xs={12} style={{padding: '6px 0 2px', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                    {items}
                </Grid>
            </Hidden>
        );
    };
    renderIntersectionAdBannerMobile = (ad) => {

        let content = (
            <img src={ad.src_url} alt={'imagen-patrocinador'}
                 onLoad={() => sendAdInteraction('view', ad.id)}
                 className={importedStyles.mobileBannerIntersection}
                 style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
        );

        if (ad.action_url && ad.action_url.trim().length > 0) {
            content = (
                <a href={ad.action_url} target={'_blank'} onClick={() => sendAdInteraction('click', ad.id)}>
                    <img src={ad.src_url} alt={'imagen-patrocinador'}
                        // onLoad={() => sendAdInteraction('view', ad.id)}
                         className={importedStyles.mobileBannerIntersection}
                         style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                </a>
            );
        }


        return (
            <Hidden smUp>
                <Grid key={'gridPubliMobileItem' + ad.id} item xs={12} sm={6} md={4} xl={3}
                      className={importedStyles.gridPubliMobileItem}>
                    <AdWithSensorWrapper adObject={ad} host={'challenge'}>
                        {content}
                    </AdWithSensorWrapper>
                </Grid>
            </Hidden>
        );
    }

    renderIntersectionAdBannerRAW = (ad) => {

        let content = (
            <AdWithSensorWrapper adObject={ad} host={'challenge'}>
                <img src={ad.src_url} alt={'imagen-patrocinador'}
                    // onLoad={() => sendAdInteraction('view', ad.id)}
                     className={importedStyles.rawBannerIntersection}
                     style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
            </AdWithSensorWrapper>
        );

        if (ad.action_url && ad.action_url.trim().length > 0) {
            content = (
                <AdWithSensorWrapper adObject={ad} host={'challenge'}>

                    <a href={ad.action_url} target={'_blank'} onClick={() => sendAdInteraction('click', ad.id)}>
                        <img src={ad.src_url} alt={'imagen-patrocinador'}
                            // onLoad={() => sendAdInteraction('view', ad.id)}
                             className={importedStyles.rawBannerIntersection}
                             style={{borderRadius: ad.border_radius ? ad.border_radius : 0}}/>
                    </a>
                </AdWithSensorWrapper>
            );
        }
        return (
            <Grid key={'gridPubliDesktopItemEnd' + ad.id} className={importedStyles.rawBannerIntersectionItem} item xs={12} sm={6} md={4} xl={3}>
                {content}
            </Grid>
        )

    }

    renderThankYouModal = () => {

        let moderationMessage = '';

        if (this.props.request && this.props.request.needsModeration) {
            moderationMessage = 'En breve los revisaremos y te llegará un email informativo.';
        }

        return (
            <Dialog onClose={() => this.setState({thankYouModalOpen: false})}
                    aria-labelledby="mensaje-gracias-por-participar"
                // open={true}
                    open={this.state.thankYouModalOpen}
                // maxWidth={"sm"}
                // fullWidth
                    classes={{paper: importedStyles.thanksModalPaper}}
            >
                <DialogContent>
                    <h4 style={{marginBottom: '0.75rem', fontSize: '1.1rem'}}>¡Enhorabuena!</h4>
                    <Typography
                        variant={"body2"}
                        style={{fontWeight: 500}}
                    >
                        Tus archivos y/o texto se han subido con éxito. Gracias por participar. {moderationMessage}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} style={{color: secondaryColor, fontWeight: 400}}
                            onClick={() => this.setState({thankYouModalOpen: false})}>
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    renderHeaderMeta = () => {
        return (
            <Helmet>
                <meta name="theme-color" content="#44444"/>
                <title>{this.state.navigatorTabTitle}</title>

                {!this.state.navigatorTabIcon &&
                    <link id="favicon" rel="icon" type="image/x-icon" href={'/globe-24.ico'}/>}
                {this.state.navigatorTabIcon &&
                    <link id="favicon" rel="icon" type="image/png" sizes="16x16" href={this.state.navigatorTabIcon}/>}
                {this.state.navigatorTabIcon &&
                    <link id="favicon32" rel="icon" type="image/png" sizes="32x32" href={this.state.navigatorTabIcon}/>}
                {this.state.navigatorTabIcon &&
                    <link id="favicon48" rel="icon" type="image/png" sizes="48x48" href={this.state.navigatorTabIcon}/>}


                {/*              {this.state.navigatorTabIcon && <meta property="og:image" content={this.state.navigatorTabIcon}/>}
                <meta property="og:title" content={this.props.request && this.props.request.title}/>
                <meta property="og:description" content={this.props.request && this.props.request.title}/>

                <meta name="description"
                      content={this.props.request && this.props.request.title}/>
                <meta name="keywords" content="video, participa, challenge, concurso"/>
                */}
            </Helmet>
        );
    };

    getGradientStyle = () => {
        let img = document.getElementById('headerImg');

        if (img) {
            let width = img.clientWidth;
            let imgHeight = img.clientHeight;
            return {
                backgroundImage: 'linear-gradient(180deg, ' + '#FBAB7E' + ' 0%, #ffffff 100%)',
                height: imgHeight
            }
        }

    }

    onHeaderImageResize = () => {
        let headerImg = document.getElementById('headerImg');

        if (this.props.request && this.props.request.headerGradientStart && headerImg) {
            // if (img){
            let imgHeight = headerImg.clientHeight;
            this.setState({
                gradientStyles: {
                    backgroundImage: 'linear-gradient(180deg, ' + this.props.request.headerGradientStart + ' 0%, #ffffff 100%)',
                    height: imgHeight,
                }
            });

        }

    }


    shuffleArray = array => {

        let copyArray = [...array];
        for (let i = copyArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = copyArray[i];
            copyArray[i] = copyArray[j];
            copyArray[j] = temp;
        }
        return copyArray;
    }
    renderAdBanner = (side) => {

        if (!this.props.request || !this.props.request.ads || this.props.request.ads.length <= 0) {
            return false;
        }

        let ads = this.props.request.ads.filter(item => item.type === 'banner_vertical');
        let middleIndex = Math.ceil(ads.length / 2);

        let leftAds = ads.splice(0, middleIndex);
        let rightAds = ads.splice(-middleIndex);



        if (rightAds.length <= 1){
            rightAds.push(leftAds[0]);
            // rightAds = rightAds.reverse();
        }




        if (side === 'left') {
            ads = leftAds;
            // ads = this.shuffleArray(ads);
            // ads = this.shuffleArray(ads);
        }

        if (side === 'right') {
            ads = rightAds;
            // ads = ads.reverse();
            // ads = this.shuffleArray(ads);
            // ads = this.shuffleArray(ads).reverse();

        }

        if (ads.length > 0) {
            return (
                <AdBanner
                    ads={ads}
                    // value={ad}
                    // image={ad.src_url}
                    // href={ad.action_url}
                    // borderRadius={ad.border_radius}
                    position={side}
                />
            )
        }


    };

    renderCountdownClock = () => {

        if (this.props.request && this.props.request.countdownLimitDate) {

            let now = moment();
            let limitDate = moment(this.props.request.countdownLimitDate);
            let remainingTime = limitDate.diff(now, 'seconds')


            return (
                <div id={'remainingTimeWrapper'} className={importedStyles.remainingTimeWrapper}>

                    <CountdownTimer
                        id={'concurso-countdown-timer-2'}
                        count={remainingTime}
                        border showTitle responsive noPoints
                        color="#000"
                        backgroundColor="#fff"
                        dayTitle={'días'}
                        hourTitle={'horas'}
                        minuteTitle={'minutos'}
                        secondTitle={'segundos'}
                    />
                </div>
            )
        }

    }

    render() {
        return (
            <React.Fragment>
                {this.renderHeaderMeta()}


                <div className={importedStyles.gradientWrapper} style={this.state.gradientStyles}>
                    <Fade in={this.props.request && this.props.request.multilanguage} unmountOnExit mountOnEnter>

                        <LanguageSelector/>
                    </Fade>



                    <Grid container className={importedStyles.mainGrid}>
                        <Hidden smDown>

                            <Grid item xs={0} md={1} style={{display: 'flex', justifyContent: 'center'}}>
                                {this.renderAdBanner('left')}
                            </Grid>
                        </Hidden>

                        <Grid item xs={12} md={9}>
                            <div className={importedStyles.DetailRequest}>
                                {this.renderView()}
                                <Divider className={importedStyles.footerDivider}/>
                                <Box className={importedStyles.footer}>
                                    <a target={'_blank'} href={'https://bit.ly/3A6vPyY'}>
                                        <img src={'/img/footerMadeLoveGrabalo.png'}
                                             className={importedStyles.footerImage}
                                             alt={'made with love by Grabalo'}/>
                                    </a>
                                    <small className={importedStyles.footerLawContainer} style={{color: '#1c1c1c'}}>
                                        <a href={'https://grabaloapp.com/politica-de-privacidad'} target={'_blank'}>Politica de
                                            privacidad</a>
                                        <a href={'https://grabaloapp.com/aviso-legal'} target={'_blank'}
                                           style={{marginRight: 10, marginLeft: 10}}>Aviso legal</a>
                                        <a href={'https://grabaloapp.com/politica-de-cookies'} target={'_blank'}>Politica de cookies</a>
                                    </small>
                                    {(this.props.user && this.props.user.uuid) &&
                                        <React.Fragment>
                                            <small className={importedStyles.footerSession}>Has iniciado sesión
                                                como <i><b>{this.props.user.name}</b></i></small>
                                            <small className={importedStyles.footerCloseSession} onClick={async () => {
                                                await this.props.dispatch(logout());
                                            }}>Cerrar sesión</small>
                                        </React.Fragment>
                                    }
                                </Box>
                            </div>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={0} md={1} style={{display: 'flex', justifyContent: 'center'}}>
                                {this.renderAdBanner('right')}
                            </Grid>
                        </Hidden>
                    </Grid>

                </div>


                {this.renderParticipateButton()}


                {/************** MODALS - ALERTS - SNACKS **************/}


                {this.renderThankYouModal()}
                <Snack/>
                <SuggestedLoginModalRequest openThankYouModalFunction={() => this.setState({thankYouModalOpen: true})}
                                            openRegisterModal={openRegisterRequestModal}/>
                <RegisterModalRequest openThankYouModalFunction={() => this.setState({thankYouModalOpen: true})}/>
                <UploadFilesModal openThankYouModalFunction={() => this.setState({thankYouModalOpen: true})}
                                  open={this.state.openUploadFilesModal}
                                  onClose={() => this.setState({openUploadFilesModal: false})}/>
                {/*<Toaster containerStyle={*/}
                {/*    !isMobile ? {top: '50%'} : {}*/}
                {/*}/> */}

                <ShareModal open={this.state.shareModal} onClose={this.handleShare} shareClaim={''}/>
                <Toaster/>
                {/************** ----------------- **************/}

                    <WhatsappButton visible={this.props.request && this.props.request.showWhatsappButton}/>




            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, ConcursoReducer, detailVideoModalReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        request: ConcursoReducer.request,
    });
};
export default connect(mapStateToProps)(Concurso);
