export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER = "UPDATE_USER";

export const SHOW_SNACK = "SHOW_SNACK";

export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORY_ACTIVE = "SET_CATEGORY_ACTIVE";

export const SET_VIDEO = "SET_VIDEO";
export const SET_VIDEOS = "SET_VIDEOS";

export const SET_POPUP_ADS = "SET_POPUP_ADS";
export const SET_INDEX_VIDEO_SELECTED = "SET_INDEX_VIDEO_SELECTED";
export const SET_CURRENT_AD_INDEX = "SET_CURRENT_AD_INDEX";

export const SET_ORIENTATION = "SET_ORIENTATION";
export const SET_ORDER = "SET_ORDER";
export const SET_TERM = "SET_TERM";
export const CLEAN_TERM = "CLEAN_TERM";


export const SET_REQUEST = "SET_REQUEST";
export const SET_VIDEO_TO_REQUEST_FLAG = "SET_VIDEO_TO_REQUEST_FLAG";
export const SET_UPLOAD_REQUEST = "SET_UPLOAD_REQUEST";

export const CLOSE_SUGGESTED_LOGIN_MODAL = "CLOSE_SUGGESTED_LOGIN_MODAL";
export const OPEN_SUGGESTED_LOGIN_MODAL = "OPEN_SUGGESTED_LOGIN_MODAL";

export const CLOSE_SUGGESTED_LOGIN_MODAL_REQUEST = "CLOSE_SUGGESTED_LOGIN_MODAL_REQUEST";
export const OPEN_SUGGESTED_LOGIN_MODAL_REQUEST = "OPEN_SUGGESTED_LOGIN_MODAL_REQUEST";

export const CLOSE_REGISTER_REQUEST_MODAL = "CLOSE_REGISTER_REQUEST_MODAL";
export const OPEN_REGISTER_REQUEST_MODAL = "OPEN_REGISTER_REQUEST_MODAL";


export const CLOSE_UPLOAD_VIDEO_MODAL = "CLOSE_UPLOAD_VIDEO_MODAL";
export const OPEN_UPLOAD_VIDEO_MODAL = "OPEN_UPLOAD_VIDEO_MODAL";

export const CLOSE_REGISTER_MODAL = "CLOSE_REGISTER_MODAL";
export const OPEN_REGISTER_MODAL = "OPEN_REGISTER_MODAL";

export const OPEN_FIRST_LOGIN_MODAL = "OPEN_FIRST_LOGIN_MODAL";
export const CLOSE_FIRST_LOGIN_MODAL = "CLOSE_FIRST_LOGIN_MODAL";

export const OPEN_ENTITIES_ONBOARDING = "OPEN_ENTITIES_ONBOARDING";
export const CLOSE_ENTITIES_ONBOARDING = "CLOSE_ENTITIES_ONBOARDING";


//SCHEDULE
export const SET_SCHEDULE = "SET_SCHEDULE";

