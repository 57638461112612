import {OPEN_REGISTER_MODAL, CLOSE_REGISTER_MODAL} from "../../../constants/action-types";

export const openRegisterModal = nextRoute => {
    return ({
        type: OPEN_REGISTER_MODAL,
        nextRoute: nextRoute
    });

};

export const closeRegisterModal = () => {
    return ({
        type: CLOSE_REGISTER_MODAL
    });

};
