import React from "react";
import connect from "react-redux/es/connect/connect";
import {Dialog, DialogContent} from "@material-ui/core";
import importedStyles from "./ShareModal.module.sass";
import ReactPixel from "react-facebook-pixel";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopyRounded";
import IconButton from "@material-ui/core/IconButton";
import {Facebook, Linkedin, Mail, Pinterest, Telegram, Twitter, Whatsapp} from 'react-social-sharing';
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {showSnack} from "../Snack/SnackActions";
import Divider from "@material-ui/core/Divider";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactGA from "react-ga";
import copy from "copy-to-clipboard";
import Button from "@material-ui/core/Button/Button";
import {primaryColor, secondaryColor} from "../../styles/palette";


class ShareModal extends React.Component {

    state = {};

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            ReactPixel.trackCustom('ModalCompartirAbierto');
            ReactGA.ga('send', 'event', 'click', 'ModalCompartirAbierto');
        }
    }

    copyToClipBoard = () => {
        copy(this.props.url ? this.props.url : window.location.href);
        this.props.dispatch(showSnack('¡Link copiado con éxito!', 'success'));
    };


    render() {
        return (

                <Dialog
                    open={this.props.open}
                    fullWidth maxWidth={'md'} onClose={this.props.onClose} scroll={"body"}
                    classes={{
                        paper: importedStyles.dialogMain,
                    }}
                >
                    <IconButton className={importedStyles.closeModalIcon} onClick={this.props.onClose}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle id="share-dialog-title" disableTypography={true}  className={importedStyles.dialogTitle}>Compartir</DialogTitle>
                    <DialogContent
                        className={importedStyles.dialogContent}
                        classes={{
                            root: importedStyles.dialogRootFix
                        }}
                    >
                        <Grid container className={importedStyles.socialIconsContainer} onClick={() => {
                            console.log('click to share');
                            ReactGA.event({
                                category: 'share',
                                action: 'shareViaModal',
                            })
                        }} spacing={2}>
                            <Grid item xs={3} md={1} className={importedStyles.socialIconBox}>
                                <Whatsapp solid small message={this.props.shareClaim}
                                          className={importedStyles.socialIcon}
                                          link={this.props.url ? this.props.url : window.location.href}/>
                                <span className={importedStyles.socialIconName}>Whatsapp</span>
                            </Grid>
                            <Grid item xs={3} md={1} className={importedStyles.socialIconBox}>
                                <Facebook solid small message={this.props.shareClaim}
                                          className={importedStyles.socialIcon}
                                          link={this.props.url ? this.props.url : window.location.href}/>
                                <span className={importedStyles.socialIconName}>Facebook</span>

                            </Grid>
                            <Grid item xs={3} md={1} className={importedStyles.socialIconBox}>
                                <Telegram solid small message={this.props.shareClaim}
                                          className={importedStyles.socialIcon}
                                          link={this.props.url ? this.props.url : window.location.href}/>
                                <span className={importedStyles.socialIconName}>Telegram</span>

                            </Grid>
                            <Grid item xs={3} md={1} className={importedStyles.socialIconBox}>
                                <Twitter solid small message={this.props.shareClaim}
                                         className={importedStyles.socialIcon}
                                         link={this.props.url ? this.props.url : window.location.href}/>
                                <span className={importedStyles.socialIconName}>Twitter</span>

                            </Grid>
                            <Grid item xs={3} md={1} className={importedStyles.socialIconBox}>
                                <Linkedin solid small message={this.props.shareClaim}
                                          className={importedStyles.socialIcon}
                                          link={this.props.url ? this.props.url : window.location.href}/>
                                <span className={importedStyles.socialIconName}>Linkedin</span>

                            </Grid>
                            <Grid item xs={3} md={1} className={importedStyles.socialIconBox}>
                                <Pinterest solid small message={this.props.shareClaim}
                                           className={importedStyles.socialIcon}
                                           link={this.props.url ? this.props.url : window.location.href}/>
                                <span className={importedStyles.socialIconName}>Pinterest</span>

                            </Grid>
                            <Grid item xs={3} md={1} className={importedStyles.socialIconBox}>
                                <Mail solid small message={this.props.shareClaim}
                                      className={importedStyles.socialIcon}
                                      link={this.props.url ? this.props.url : window.location.href}/>
                                <span className={importedStyles.socialIconName}>Email</span>

                            </Grid>
                        </Grid>

                        <div className={importedStyles.linkBox}>
                            <OutlinedInput
                                label={'Link directo'}
                                variant="filled"
                                fullWidth
                                readOnly
                                disabled
                                style={{backgroundColor: '#f9f9f9', color: '#000000'}}
                                value={this.props.url ? this.props.url : window.location.href}
                                labelWidth={0}
                                endAdornment={<Button onClick={this.copyToClipBoard} className={importedStyles.copyLinkButton} >Copiar</Button>}
                            />
                            {/*<div className={importedStyles.copyIconContainer}>*/}
                            {/*    <IconButton*/}
                            {/*        key="copy"*/}
                            {/*        aria-label="copyCode"*/}
                            {/*        color="inherit"*/}
                            {/*        onClick={this.copyToClipBoard}*/}
                            {/*        style={{backgroundColor: '#00a3da'}}*/}
                            {/*    >*/}
                            {/*        <FileCopyIcon className={importedStyles.fileCopyIcon} onClick={copy}/>*/}
                            {/*    </IconButton>*/}
                            {/*    <span>Copiar</span>*/}
                            {/*</div>*/}
                        </div>
                        {/*<Divider variant={"middle"} style={{width: '100%'}}/>*/}

                    </DialogContent>
                </Dialog>

        );
    }

}

const mapStateToProps = ({authReducer}) => {
    return ({
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(ShareModal);
