import {OPEN_REGISTER_REQUEST_MODAL, CLOSE_REGISTER_REQUEST_MODAL} from "../../../../../constants/action-types";

export const openRegisterRequestModal = nextRoute => {
    return ({
        type: OPEN_REGISTER_REQUEST_MODAL,
        nextRoute: nextRoute
    });

};

export const closeRegisterRequestModal = () => {
    return ({
        type: CLOSE_REGISTER_REQUEST_MODAL
    });

};
