import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './Downloads.module.sass';
import history from "../../../helpers/history";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import Snack from "../../../components/Snack/Snack";
import {Fade} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import ReactPixel from "react-facebook-pixel";
import {setVideo} from "../../DetailVideo/Redux/detailVideoActions";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import NoVideosMessage from "../../../components/NoVideosMessage/NoVideosMessage";
import {Helmet} from "react-helmet/es/Helmet";
import VideoCard from "../../../components/VideoCard/VideoCard";
import {isVideoFavorite} from "../../Concurso/helpers";
import {videosPerPage} from "../../../constants/constants";
import PageTitle from "../../../components/PageTitle/PageTitle";

// Flag to check if it's a mobile screen
const isMobile = window.matchMedia("(max-width: 768px)").matches;


class Downloads extends React.Component {

    state = {
        videos: [],
        selectedVideo: null,
        actualVideoIndex: null,
        waitingResponse: true,
        nextVideo: null,
        openDetailVideoModal: false,
        currentPage: 1,
    };


    // LIFECYCLES
    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getDownloads();
        ReactPixel.trackCustom('MyDownloads', {user: this.props.user.uuid});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    // FETCH
    getDownloads = () => {

        this.setState({
            waitingResponse: true
        });

        let entityId = this.props.user.uuid;

        fetch(apiBaseUrl + 'downloads/listMyDownloads', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({entityId: entityId, currentPage: this.state.currentPage, perPage: this.getFilesPerPage()})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            videos: response.videos,
                            totalDownloadsCount: response.totalDownloadsCount,
                            waitingResponse: false,
                        });
                    } else {
                        this.setState({waitingResponse: false});
                        this.props.dispatch(showSnack('Error al cargar los datos', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };

    // HANDLERS
    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        this.props.dispatch(setVideo(nextVideo));
        ReactPixel.trackCustom('ClickOnVideoFromDownloads', {video: nextVideo.uuid});
    };
    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        this.props.dispatch(setVideo(nextVideo));
        ReactPixel.trackCustom('ClickOnVideoFromDownloads', {video: nextVideo.uuid});
    };

    // RENDERS
    renderSkeletons = () => {

        let skeletons = [];

        for (let i = 0; i < 6; i++) {
            skeletons.push(
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}  className={importedStyles.skeletonContainer}>
                    <Skeleton className={importedStyles.skeletonBox} height={'100%'} width={'100%'}/>
                </Grid>
            )
        }

        return (
            <Fade direction={'top'} unmountOnExit mountOnEnter in={this.state.waitingResponse}
                  timeout={{enter: 400, exit: 200}}>
                <React.Fragment>
                    {skeletons}
                </React.Fragment>
            </Fade>
        );
    };
    renderMedia = () => {
        const videos = this.state.videos;
        if (!videos || videos.length <= 0) {
            return (
                <Grid item xs={12} className={importedStyles.gridItemCenteredContent}>
                    <NoVideosMessage/>
                </Grid>
            );
        } else {
            let videoCards = [];
            Object.keys(videos).forEach((key) => {

                let index = parseInt(key);
                let prevVideo = index > 0 && videos[index-1];
                let nextVideo = index < videos.length && videos[index+1];

                let video = videos[index];
                if (video){
                    // let title = video.title.length > 35 ? video.title.substr(0, 35) + '...' : video.title;
                    let author = video.user ? video.user.name : video.name;

                    videoCards.push(
                        <Grid className={importedStyles.gridItemCenteredContent} key={key} item xs={12} sm={6} md={3} lg={3} xl={2}>
                            <VideoCard
                                isFavorite={isVideoFavorite(video)}
                                video={video}
                                title={video.title}
                                author={author}
                                // onClick={this.handleDetailVideoModal(video, index)}
                                image={video.coverUrl}
                                withLikes={false}
                                withShare={false}
                                withFavorite={true}
                                withDownload={true}
                                withConfidenceWall={true}
                                location={this.props.location}
                                match={this.props.match}
                                prevVideo={prevVideo}
                                nextVideo={nextVideo}

                            />
                        </Grid>
                    );
                }

            });
            return videoCards;
        }
    };

    handlePageChange = (event, page) => {
        this.setState({currentPage: page}, () => {
            this.getDownloads();
        });
    };

    getFilesPerPage = () => {
        let perPage = 20;
        let deviceWidth = window.screen.width;


        if (deviceWidth < 960) { perPage = 26 } else
        if (deviceWidth < 1280) { perPage = 28 } else
        if (deviceWidth < 1920) { perPage = 36 }

        return perPage;
    }

    renderPagination = () => {

        let totalPages = Math.ceil(this.state.totalDownloadsCount / this.getFilesPerPage()).toString();
        let isMobile = window.matchMedia("(max-width: 768px)").matches ? 'small' : 'medium';
        return (
            <Grid item xs={12} className={importedStyles.paginatorContainer}>
                <Pagination
                    count={totalPages}
                    page={this.state.currentPage} onChange={this.handlePageChange}
                    boundaryCount={1}
                    siblingCount={1}
                    size={isMobile}
                    showFirstButton
                    showLastButton
                />
            </Grid>
        )
    };

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Descargas</title>
                </Helmet>
                <NavBar/>
                <Snack/>
                <DesktopDrawer/>

                <div className={importedStyles.myDownloads}>
                    <PageTitle title={'Archivos descargados'} subtitle={'Visualiza todos los archivos que has descargado individualmente. Vuelve a descargarlos siempre que quieras.'} />

                    <Grid container className={importedStyles.gridContainer} spacing={isMobile ? 0 : 2}>

                            {this.state.waitingResponse && this.renderSkeletons()}
                            {!this.state.waitingResponse && this.renderMedia()}

                        {this.renderPagination()}
                    </Grid>
                </div>
                {/*{this.state.selectedVideo &&*/}
                {/*<DetailVideoModal open={this.state.openDetailVideoModal} onClose={this.closeDetailVideoModal}*/}
                {/*                  video={this.state.selectedVideo} actualVideoIndex={this.state.actualVideoIndex}*/}
                {/*                  lastVideoIndex={this.state.videos.length - 1} handlePrevVideo={this.handlePrevVideo}*/}
                {/*                  handleNextVideo={this.handleNextVideo}/>}*/}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(Downloads);
