import React from 'react';
import * as PropTypes from "prop-types";
import importedStyles from './SnackHelper.module.sass';

class SnackHelper extends React.Component {

    state = {
        visible: true,
    };

    render() {
        let styleAnimation = this.state.visible ? importedStyles.containerIn: importedStyles.containerOut;
            return (
                <div
                     onClick={() => this.setState({visible: false})}
                     className={[styleAnimation, importedStyles.container].join(' ')}>
                    <p className={importedStyles.text}>{this.props.text}</p>
                    <div className={importedStyles.arrowDown}/>
                </div>
            );
    }
}

SnackHelper.propTypes = {
    text: PropTypes.string.isRequired,
};


export default SnackHelper;