import {SHOW_SNACK} from "../../constants/action-types";

export const showSnack = (text, snackType, show = true, fixed) => {

    return ({
        type: SHOW_SNACK,
        text: text,
        snackType: snackType,
        show: show,
        fixed: fixed ? fixed : false,
    });

};

export const snackTypes = {
    success: "success",
    warning: "warning",
    error: "error",
};
