import React, {useEffect, useReducer, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button/Button";
import {Menu, MenuItem} from "@material-ui/core";
import SpainFlag from "../../icons/flag_spain.png";
import VlcFlag from "../../icons/flag_vlc.png";
import {ArrowDropDownRounded, LanguageRounded} from "@material-ui/icons";


export const LanguageSelector = (props) => {
    const classes = useStyles(props);

    const [currentLanguage, setCurrentLanguage] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCurrentLanguageItemChange = () => {

        setTimeout(()=>{
            let userLanguage = localStorage.getItem('grabalo-user-language');

            if (userLanguage){
                setCurrentLanguage(userLanguage);
            } else {
                setCurrentLanguage('esp');
            }
        }, 500)

    }

    useEffect(() => {

        let userLanguage = localStorage.getItem('grabalo-user-language');

        if (userLanguage){
            setCurrentLanguage(userLanguage);
        } else {
            setCurrentLanguage('esp');
        }
        window.addEventListener("localStorageChange", handleCurrentLanguageItemChange);


        return () => {
            window.removeEventListener("localStorageChange", handleCurrentLanguageItemChange);
        };

    }, []);





    const handleChange = (value) => {
        setCurrentLanguage(value); //set state
        localStorage.setItem('grabalo-user-language', value); //store language 'cookie' to be used later
        handleClose();//close menu
    }


    return (
        <div className={classes.root} style={props.styles}>
            <Button size={window.matchMedia("(max-width: 768px)").matches ? 'small' : "medium"} aria-controls="language-menu" aria-haspopup="true" onClick={handleClick} className={classes.button}>
                <LanguageRounded className={classes.languageIcon} /> Idioma <ArrowDropDownRounded className={classes.dropdownIcon}/>
                {/*{currentLanguage === 'vlc' ? <img src={VlcFlag} className={classes.flag} alt={''}/> : <img src={SpainFlag} className={classes.flag} style={{marginRight: "unset"}} alt={''}/>}*/}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleChange('esp')}><img src={SpainFlag} className={classes.flag} alt={''}/>Español</MenuItem>
                <MenuItem onClick={ () => handleChange('vlc')}><img src={VlcFlag} className={classes.flag} alt={''}/>Valencià</MenuItem>
            </Menu>
        </div>
    );

}

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1200,
        position: 'fixed',
        top: 0,
        left: 0
    },
    flag: {
        width: 36,
        aspectRatio: '14/9',
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            width: 28
        }
    },
    button: {
        background: '#fff',
        margin: '0.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    dropdownIcon: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    languageIcon: {
        marginRight: 5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    }
}));

LanguageSelector.propTypes = {
    // image: PropTypes.string.isRequired,
    styles: PropTypes.object,
    // borderRadius: PropTypes.number,
    // position: PropTypes.string
};

LanguageSelector.defaultProps = {
    styles: {},
}
