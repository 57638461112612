import {
    SET_CURRENT_AD_INDEX,
    SET_INDEX_VIDEO_SELECTED,
    SET_POPUP_ADS,
    SET_VIDEO,
    SET_VIDEOS
} from "../../../constants/action-types";


const initialState = {
    video: {},
    videos: [],
    indexVideoSelected: 0,
    currentAdIndex: 0,
    popupAds: [],
};

const detailVideoModalReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_VIDEO:
            return {...state, video: action.video};
        case SET_VIDEOS:
            return {...state, videos: action.videos};
        case SET_INDEX_VIDEO_SELECTED:
            return {...state, indexVideoSelected: action.index};
        case SET_POPUP_ADS:
            return {...state, popupAds: action.data};
        case SET_CURRENT_AD_INDEX:
            return {...state, currentAdIndex: action.index};
        default:
            return state;
    }
};


export default detailVideoModalReducer;
