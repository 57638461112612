import React, {useEffect, useState} from "react";
import {SimpleStatsCard} from "../Components/SimpleStatsCard/SimpleStatsCard";
import MetricsIcon from "@material-ui/icons/BarChartRounded";
import BubbleIcon from "@material-ui/icons/BubbleChartRounded";
// import PlayIcon from "@material-ui/icons/PlayCircleFilledRounded";
// import PlayVideoIcon from "@material-ui/icons/PlayArrowRounded";
// import EyeIcon from "@material-ui/icons/VisibilityRounded";
// import TimeIcon from "@material-ui/icons/Timer";
// import CtaClickIcon from "@material-ui/icons/TouchApp";
// import CloseIcon from "@material-ui/icons/Close";
// import NavNext from "@material-ui/icons/NavigateNextRounded";
// import NavBefore from "@material-ui/icons/NavigateBeforeRounded";
// import NavNext2 from "@material-ui/icons/LastPageRounded";
// import NavBefore2 from "@material-ui/icons/FirstPageRounded";
// import OpenModalIcon from "@material-ui/icons/OpenInBrowserRounded";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import Grid from "@material-ui/core/Grid";
import {DataGrid, esES, GridToolbar,} from "@material-ui/data-grid";
import * as PropTypes from "prop-types";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import apiBaseUrl from "../../../../helpers/apiBaseUrl";
import {makeStyles} from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import loadingStatsAnimation from "../../../../animations/loadingStats.json";
import Lottie from "react-lottie";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import moment from "moment";

const isMobile = window.matchMedia("(max-width: 768px)").matches;

const useStyles = makeStyles((theme) => ({
    chartDataSelectionButton: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.25rem 0.5rem',
        margin: '0.5rem',
        color: '#BDBDBD',
        borderRadius: 8,
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#cfeeff'
        }
    },
    chartDataSelectionButtonSelected: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.25rem 0.5rem',
        fontWeight: "bold",
        margin: '0.5rem',
        color: '#00a3da',
        borderRadius: 8,
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#cfeeff'
        }
    },
    areaChartActionButtons: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexWrap: "wrap"
    }
}));


export const ConfidenceWallStats = (props) => {


    const [statsCounts, setStatsCounts] = useState({
        'play': 0,
        'view': 0,
        'timer': '11:41 min',
        'video_modal_closed': 0,
        'video_modal_opened': 0,
        'cta_click': 0,
        'global_clicks': 0,
        'external_navs': 0,
        'videos_navs': 0,
    });
    const [refinedVideosStats, setRefinedVideosStats] = useState([]);
    const [loadingStats, setLoadingStats] = useState(true);
    const [loadingVideoStats, setLoadingVideoStats] = useState(false);
    const [selectedAreaChartData, setSelectedAreaChartData] = useState('impresiones');
    const [areaChartData, setAreaChartData] = useState([]);
    const [fromDate, setFromDate] = useState(new Date().setDate(new Date().getDate() - 7));
    const [toDate, setToDate] = useState(new Date());

    const [refinedChartStats, setRefinedChartStats] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        getDateFilteredStats().then(r => {
        });
    }, [props.stats, fromDate, toDate]);

    useEffect(() => {
        refineVideoStats();
    }, [props.videos, fromDate, toDate]);


    const refineGeneralStats = () => {
        let stats = props.stats;

        if (stats && stats.length > 0) {
            let clone = Object.assign({}, statsCounts);

            for (let i = 0; i < stats.length; i++) {
                switch (stats[i].type) {
                    case 'play':
                        clone.plays++
                        break;
                    case 'view':
                        clone.views++
                        break;
                    case 'global_click':
                        clone.global_clicks++
                        break;
                    case 'external_nav':
                        clone.external_navs++
                        break;
                    case 'videos_nav':
                        clone.videos_navs++
                        break;
                    case 'timer':
                        clone.timer++
                        break;
                    case 'cta_click':
                        clone.cta_clicks++
                        break;
                    case 'video_modal_opened':
                        clone.videoModalOpens++
                        break;
                    case 'video_modal_closed':
                        clone.videoModalCloses++
                        break;

                    default:
                        // do nothing
                        break;
                }
                setStatsCounts(clone);
            }
        }

    }

    const refineVideoStats = () => {
        setLoadingVideoStats(true);

        let videos = props.videos;
        let refinedVideos = [];

        Object.values(videos).forEach((value, index) => {
            let stats = value.videowall_stats;

            if (stats && stats.length > 0) {
                let filteredByDate = stats.filter((item) => {
                    return new Date(item.created_on).getTime() >= new Date(fromDate).getTime() &&
                        new Date(item.created_on) <= new Date(toDate).getTime();
                });

                let result = filteredByDate.reduce((r, e) => {
                    let k = `${e.type}`;
                    if (!r[k]) r[k] = 1;
                    else r[k] += 1;
                    return r;
                }, {});
                let title = value.pivot.title;
                refinedVideos.push({id: index, title: title, ...result});
            }
        });
        setRefinedVideosStats(refinedVideos);
        setLoadingVideoStats(false);
    };
    const renderTopMedia = () => {
        const columns = [
            {
                field: 'title',
                headerName: 'Archivo',
                flex: 1,
            },
            {
                field: 'play',
                headerName: 'Reproducciones',
                flex: 1,
                valueGetter: (params) => params.value ? params.value : '0'
            },
            {
                field: 'cta_click',
                headerName: 'Clicks CTA',
                flex: 1,
                valueGetter: (params) => params.value ? params.value : '0'
            },
            {
                field: 'video_modal_opened',
                headerName: 'Clicks en miniatura',
                type: 'number',
                flex: 1,
                valueGetter: (params) => params.value ? params.value : '0'
            },
            // {
            //     field: 'video_modal_closed',
            //     headerName: 'Cierres detalle video',
            //     type: 'number',
            //     flex: 1,
            //     valueGetter: (params) => params.value ? params.value : '0'
            // },
        ];

        function CustomToolbar() {
            return (
                // <GridToolbarContainer>
                //     <GridToolbarColumnsButton style={{color: '#00a3da'}} />
                //     <GridToolbarFilterButton style={{color: '#00a3da'}}/>
                // </GridToolbarContainer>
                <GridToolbar style={{color: '#00a3da', padding: '0.25rem 0.25rem 0.25rem 0.75rem'}}
                             id={'data-grid-toolbar'}/>
            );
        }

        return (
            <DataGrid
                id={'confidenceWallVideosDataGrid'}
                loading={loadingVideoStats}
                rows={refinedVideosStats}
                columns={columns}
                autoHeight
                autoPageSize
                pageSize={10}
                disableDensitySelector
                //localeText={GridLocaleES}
                // localeText={esES.components.MuiDataGrid.localeText}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}

                components={{
                    Toolbar: CustomToolbar
                }}
                componentsProps={{
                    panel: {color: '00a3da'}
                }}
            />
        )
    };

    useEffect(() => {
        switch (selectedAreaChartData) {
            case "impresiones":
                setAreaChartData(refinedChartStats['view']);
                break;
            case "plays":
                setAreaChartData(refinedChartStats['play']);
                break;
            case "cta_clicks":
                setAreaChartData(refinedChartStats['cta_click']);
                break;
            case "video_modal_opened":
                setAreaChartData(refinedChartStats['video_modal_opened']);
                break;
            default:
                // do nothing
                break;
        }
    }, [selectedAreaChartData, refinedChartStats])

    let getPercent = x => {
        x = parseInt(x);
        let percent = (x / parseInt(statsCounts.view)) * 100;
        return percent.toFixed(2) + '%';
    };

    const renderAreaChart = () => {

        return (
            <ResponsiveContainer key={selectedAreaChartData + '-container'}>
                <AreaChart
                    data={areaChartData}
                    margin={{top: 10, right: 20, left: 0, bottom: 0,}}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="2%" stopColor="#00a3da" stopOpacity={0.8}/>
                            <stop offset="97%" stopColor="#00a3da" stopOpacity={0.15}/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="date"/>
                    <YAxis/>
                    <Tooltip/>
                    <Area type="monotone" dataKey="count" stroke="#00a3da"
                          dot={{stroke: '#10212e', strokeWidth: 4, r: 2, fill: '#10212e'}}
                        // fill="#cfeeff"
                          fill="url(#colorUv)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        )
    };

    let getDateFilteredStats = async () => {
        try {
            setLoadingStats(true);

            let response = await fetch(apiBaseUrl + 'videowall/stats/filterByDate', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    videowallUuid: props.wall.uuid,
                    fromDate: moment(fromDate).format('DD-MM-YYYY'),
                    toDate: moment(toDate).format('DD-MM-YYYY'),
                }),
            })

            if (!response.ok) {
                const message = `An error has occured when fetching stats: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            await setStatsCounts(response.counts);
            await setRefinedChartStats(response.formattedStats);
            setLoadingStats(false);

        } catch (error) {
            setLoadingStats(false);

            console.error('Error: ', error);
        }
    };

    const renderAreaChartSelectionButton = (dataType, text) => {
        return (
            <div
                className={selectedAreaChartData === dataType ? classes.chartDataSelectionButtonSelected : classes.chartDataSelectionButton}
                onClick={() => setSelectedAreaChartData(dataType)}
            >
                <DotIcon style={{fontSize: 12, marginRight: 5}}/> {text}
            </div>
        )
    }

    const renderLoadingStatsAnimation = () => {

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadingStatsAnimation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={600}
                    width={600}
                    speed={1}
            />
        )
    }

    const handleFromDateChange = (date) => {
        // console.log(input)
        setFromDate(date);
    }

    const handleToDateChange = (date) => {
        // console.log(input)
        setToDate(date);
    }

    return (
        <React.Fragment>
            <Grid container spacing={4} justify={'center'}>

                <Grid item xs={12} style={{display: 'flex', alignItems: 'center', paddingTop: 0, paddingBottom: 0}}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'} moment={moment}>

                        <KeyboardDatePicker
                            disableToolbar
                            showTodayButton
                            format="DD/MM/YYYY"
                            variant={isMobile ? "dialog" : ""}
                            margin="normal"
                            id="date-picker-inline"
                            label="Desde"
                            value={fromDate}
                            maxDate={toDate}
                            okLabel={'Aceptar'}
                            cancelLabel={'Cancelar'}
                            todayLabel={'Hoy'}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            disableToolbar
                            showTodayButton
                            style={{marginLeft: 50}}
                            format="DD/MM/YYYY"
                            variant={isMobile ? "dialog" : ""}
                            margin="normal"
                            id="date-picker-inline"
                            label="Hasta"
                            value={toDate}
                            minDate={fromDate}
                            okLabel={'Aceptar'}
                            cancelLabel={'Cancelar'}
                            todayLabel={'Hoy'}
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>


                <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                    <MetricsIcon style={{fontSize: 32, marginRight: 10}}/> <h3>Métricas generales</h3>
                </Grid>

                <Fade in={loadingStats} unmountOnExit timeout={{enter: 0, exit: 200}}>
                    <Grid item xs={12} style={{marginTop: '-7rem'}}>
                        {renderLoadingStatsAnimation()}
                    </Grid>
                </Fade>
                <Fade in={!loadingStats} mountOnEnter timeout={1000}>
                    <Grid item xs={6} sm={4} md={3} lg={2}>

                        <SimpleStatsCard
                            // icon={<EyeIcon style={{fontSize: 32}}/>}
                            value={statsCounts.view}
                            text={'Impresiones'}
                            chipText={'100%'}
                            tooltip={'Veces que tu muro se ha mostrado a tus usuarios'}
                        />
                    </Grid>

                </Fade>
                <Fade in={!loadingStats} timeout={1000}>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <SimpleStatsCard
                            //icon={<OpenModalIcon style={{fontSize: 32}}/>}
                            value={statsCounts.video_modal_opened}
                            text={'Clicks en miniaturas'}
                            chipText={getPercent(statsCounts.video_modal_opened)}
                            tooltip={'Click en la miniatura del archivo, no confundir con reproducciones/visualizaciones'}
                            percentTooltip={'Porcentaje de clicks basado en el numero de impresiones'}
                        />
                    </Grid>

                </Fade>
                <Fade in={!loadingStats} timeout={1000}>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <SimpleStatsCard
                            // icon={<PlayIcon style={{fontSize: 32}}/>}
                            value={statsCounts.play}
                            text={'Reproducciones'}
                            chipText={getPercent(statsCounts.play)}
                            tooltip={'Clicks en el play del video'}
                            percentTooltip={'Porcentaje de clicks basado en el numero de impresiones'}
                        />
                    </Grid>

                </Fade>
                <Fade in={!loadingStats} timeout={1000}>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <SimpleStatsCard
                            // icon={<CtaClickIcon style={{fontSize: 32}}/>}
                            value={statsCounts.cta_click}
                            text={'Clicks CTA'}
                            chipText={getPercent(statsCounts.cta_click)}
                            percentTooltip={'Porcentaje de clicks basado en el numero de impresiones'}
                        />
                    </Grid>
                </Fade>


                {/*<Grid item xs={6} sm={4} md={3}  lg={2}>*/}
                {/*    <SimpleStatsCard*/}
                {/*        // icon={<CtaClickIcon style={{fontSize: 32}}/>}*/}
                {/*        value={statsCounts.global_clicks}*/}
                {/*        text={'Interacciones'}*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={6} sm={4} md={3}  lg={2}>*/}
                {/*    <SimpleStatsCard icon={<TimeIcon style={{fontSize: 32}}/>} value={statsCounts.timer}*/}
                {/*                     text={'Tiempo medio de interacción'}/>*/}
                {/*</Grid>*/}


                {/*<Grid item xs={6} sm={4} md={3}  lg={2}>*/}
                {/*    <SimpleStatsCard*/}
                {/*        //icon={<> <NavBefore style={{fontSize: 32}}/><NavNext style={{fontSize: 32}}/></>}*/}
                {/*        value={statsCounts.external_navs}*/}
                {/*        text={'Clicks navegación externa'}/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={6} sm={4} md={3}  lg={2}>*/}
                {/*    <SimpleStatsCard*/}
                {/*        //icon={<> <NavBefore2 style={{fontSize: 32}}/><NavNext2 style={{fontSize: 32}}/></>}*/}
                {/*        value={statsCounts.videos_navs}*/}
                {/*        text={'Clicks navegación vídeos'}/>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={6} sm={4} md={3}  lg={2}>*/}
                {/*    <SimpleStatsCard icon={<CloseIcon style={{fontSize: 32}}/>} value={statsCounts.videoModalCloses}*/}
                {/*                     text={'Cierres modal detalle vídeos'}/>*/}
                {/*</Grid>*/}
                <Fade in={!loadingStats} timeout={1000}>

                    <Grid item xs={12} style={{minHeight: '25rem', display: 'flex', flexDirection: 'column'}}>
                        {renderAreaChart()}
                        <div className={classes.areaChartActionButtons}>
                            {renderAreaChartSelectionButton('impresiones', 'Impresiones')}
                            {renderAreaChartSelectionButton('video_modal_opened', 'Clicks en miniatura')}
                            {renderAreaChartSelectionButton('plays', 'Reproducciones')}
                            {renderAreaChartSelectionButton('cta_clicks', 'Clicks CTA')}
                        </div>
                    </Grid>
                </Fade>


                <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                    <BubbleIcon style={{fontSize: 32, marginRight: 10}}/> <h3>Métricas por archivo</h3>
                </Grid>
                <Grid item xs={12}>
                    {renderTopMedia()}
                </Grid>
            </Grid>


        </React.Fragment>
    )
}

ConfidenceWallStats.propTypes = {
    videos: PropTypes.array,
    stats: PropTypes.array,
};
