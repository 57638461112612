import React from "react";
import importedStyles from './Terms.module.sass';
import {closeRegisterModal} from "../../components/RegisterModal/Redux/RegisterModalActions";
import connect from "react-redux/es/connect/connect";
import {Helmet} from "react-helmet/es/Helmet";

class Terms extends React.Component {

    state = {
        expanded: null,
    };

    componentDidMount() {
        //window.scrollTo({top: 0, behavior: 'smooth'});
        if (this.props.registerModalOpened) {
            this.props.dispatch(closeRegisterModal());
            setTimeout(() => {
                document.getElementById('root').style.filter = 'unset';
            }, 500)
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Legal</title>
                </Helmet>
                <div className={importedStyles.terms}>
                    <div id={'conditions'}>
                        <p dir="ltr"><span>AVISO LEGAL Y CONDICIONES GENERALES DE USO DEL SITIO WEB</span></p>
                        <p dir="ltr"><span>https://www.grabaloapp.com</span></p>
                        <p dir="ltr"><span><br/></span><span>1. INFORMACI&Oacute;N GENERAL&nbsp;</span></p>
                        <p dir="ltr"><span>La titularidad de este sitio web, https://www.grabaloapp.com, (en adelante, Sitio Web) la ostenta: GRABA TU TALENTO, S.L.U., provista de NIF: B-44528362 e inscrita en el Registro Mercantil de Castell&oacute;n con los siguientes datos registrales: </span><span>Tomo 1796, Folio 60, Inscripci&oacute;n 1, Hoja CS42415</span><span> y cuyos datos de contacto son:</span>
                        </p>
                        <p dir="ltr">
                            <span>Direcci&oacute;n: Avda. Castell&oacute;n n&ordm; 2, 1&ordm; Nules (Castell&oacute;n)</span><span><br/></span><span>Email de contacto: </span><a
                            href="mailto:info@grabaloapp.com"><span>info@grabaloapp.com</span></a><span>&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>2. T&Eacute;RMINOS Y CONDICIONES GENERALES DE USO&nbsp;</span></p>
                        <p dir="ltr"><span>El objeto de las condiciones: El Sitio Web</span></p>
                        <p dir="ltr"><span>El objeto de las presentes Condiciones Generales de Uso (en adelante, Condiciones) es regular el acceso y la utilizaci&oacute;n del Sitio Web. A los efectos de las presentes Condiciones se entender&aacute; como Sitio Web: la apariencia externa de los interfaces de pantalla, tanto de forma est&aacute;tica como de forma din&aacute;mica, es decir, el &aacute;rbol de navegaci&oacute;n; y todos los elementos integrados tanto en los interfaces de pantalla como en el &aacute;rbol de navegaci&oacute;n (en adelante, Contenidos) y todos aquellos servicios o recursos en l&iacute;nea que en su caso ofrezca a los Usuarios (en adelante, Servicios).</span>
                        </p>
                        <p dir="ltr"><span>GRABALO se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentaci&oacute;n y configuraci&oacute;n del Sitio Web y de los Contenidos y Servicios que en &eacute;l pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento GRABALO pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.</span>
                        </p>
                        <p dir="ltr"><span>El acceso al Sitio Web por el Usuario tiene car&aacute;cter libre y, por regla general, es gratuito sin que el Usuario tenga que proporcionar una contraprestaci&oacute;n para poder disfrutar de ello, salvo en lo relativo al coste de conexi&oacute;n a trav&eacute;s de la red de telecomunicaciones suministrada por el proveedor de acceso que hubiere contratado el Usuario.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>La utilizaci&oacute;n de alguno de los Contenidos o Servicios del Sitio Web podr&aacute; hacerse mediante la suscripci&oacute;n o registro previo del Usuario, los cuales quedar&aacute;n debidamente identificados.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Usuario</span></p>
                        <p dir="ltr">
                            <span>El acceso, la navegaci&oacute;n y uso del Sitio Web confiere la condici&oacute;n de Usuario, por lo que se aceptan, desde que se inicia la navegaci&oacute;n por el Sitio Web, todas las Condiciones aqu&iacute; establecidas, as&iacute; como sus ulteriores modificaciones, sin perjuicio de la aplicaci&oacute;n de la correspondiente normativa legal de obligado cumplimiento seg&uacute;n el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas cada vez que visite el Sitio Web.</span>
                        </p>
                        <p dir="ltr"><span>El Sitio Web de GRABALO proporciona gran diversidad de informaci&oacute;n, servicios y datos. El Usuario asume su responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extender&aacute; a:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Un uso de la informaci&oacute;n, Contenidos y/o Servicios y datos ofrecidos por GRABALO sin que sea contrario a lo dispuesto por las presentes Condiciones, la Ley, la moral o el orden p&uacute;blico, o que de cualquier otro modo puedan suponer lesi&oacute;n de los derechos de terceros o del mismo funcionamiento del Sitio Web.&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>La veracidad y licitud de las informaciones aportadas por el Usuario en los formularios extendidos por GRABALO para el acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario notificar&aacute; de forma inmediata a GRABALO acerca de cualquier hecho que permita el uso indebido de la informaci&oacute;n registrada en dichos formularios, tales como, pero no s&oacute;lo, el robo, extrav&iacute;o, o el acceso no autorizado a identificadores y/o contrase&ntilde;as, con el fin de proceder a su inmediata cancelaci&oacute;n.&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>El mero acceso a este Sitio Web no supone entablar ning&uacute;n tipo de relaci&oacute;n de car&aacute;cter comercial entre GRABALO y el Usuario.</span>
                        </p>
                        <p dir="ltr">
                            <span>El Usuario declara ser mayor de edad y disponer de la capacidad jur&iacute;dica suficiente para vincularse por las presentes Condiciones. Por lo tanto, este Sitio Web de GRABALO no se dirige a menores de edad. GRABALO declina cualquier responsabilidad por el incumplimiento de este requisito.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Sitio Web est&aacute; dirigido principalmente a Usuarios residentes en Espa&ntilde;a. GRABALO no asegura que el Sitio Web cumpla con legislaciones de otros pa&iacute;ses, ya sea total o parcialmente. Si el Usuario reside o tiene su domiciliado en otro lugar y decide acceder y/o navegar en el Sitio Web lo har&aacute; bajo su propia responsabilidad, deber&aacute; asegurarse de que tal acceso y navegaci&oacute;n cumple con la legislaci&oacute;n local que le es aplicable, no asumiendo GRABALO responsabilidad alguna que se pueda derivar de dicho acceso.</span>
                        </p>
                        <p dir="ltr"><span>Normas de publicaci&oacute;n de comentarios</span></p>
                        <p dir="ltr"><span>GRABALO se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean discriminatorios, xen&oacute;fobos, racistas, pornogr&aacute;ficos, spamming, que atenten contra la juventud o la infancia, el orden o la seguridad p&uacute;blica o que, a su juicio, no resultaran adecuados para su publicaci&oacute;n.</span>
                        </p>
                        <p dir="ltr"><span>En cualquier caso, GRABALO no ser&aacute; responsable de las opiniones vertidas por los Usuarios a trav&eacute;s de comentarios u otras herramientas de blogging o de participaci&oacute;n que pueda ofrecer el Sitio Web.</span>
                        </p>
                        <p dir="ltr"><span>A este respecto, el Usuario garantiza que (i) el contenido publicado es original y este es el creador de dicho contenido; (ii) la publicaci&oacute;n y la utilizaci&oacute;n de dicho contenido no infringe, malversa ni vulnera los derechos de terceros, incluidos, sin limitaci&oacute;n, los derechos de privacidad, de publicidad, de autor, de marca comercial u otros derechos de propiedad intelectual; (iii) en su caso, ha abonado todos los derechos de autor, las tasas y cualquier otra suma adeudada como consecuencia del contenido publicado en la Plataforma o a trav&eacute;s de ella, y (iv) tiene el derecho y la capacidad legal de cumplir estos t&eacute;rminos y condiciones bajo la jurisdicci&oacute;n aplicable.</span>
                        </p>
                        <p><span><span>&nbsp;</span></span></p>
                        <p dir="ltr">
                            <span>3. ACCESO Y NAVEGACI&Oacute;N EN EL SITIO WEB: EXCLUSI&Oacute;N DE GARANT&Iacute;AS Y RESPONSABILIDAD</span>
                        </p>
                        <p dir="ltr"><span><br/></span><span>GRABALO no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios. GRABALO har&aacute; todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que est&eacute; libre de error.</span>
                        </p>
                        <p dir="ltr"><span>Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a trav&eacute;s de este Sitio Web est&eacute; libre de error o cause un da&ntilde;o al sistema inform&aacute;tico (software y hardware) del Usuario. En ning&uacute;n caso GRABALO ser&aacute; responsable por las p&eacute;rdidas, da&ntilde;os o perjuicios de cualquier tipo que surjan por el acceso, navegaci&oacute;n y el uso del Sitio Web, incluy&eacute;ndose, pero no limit&aacute;ndose, a los ocasionados a los sistemas inform&aacute;ticos o los provocados por la introducci&oacute;n de virus.</span>
                        </p>
                        <p dir="ltr"><span>GRABALO tampoco se hace responsable de los da&ntilde;os que pudiesen ocasionarse a los usuarios por un uso inadecuado de este Sitio Web. En particular, no se hace responsable en modo alguno de las ca&iacute;das, interrupciones, falta o defecto de las telecomunicaciones que pudieran ocurrir.</span>
                        </p>
                        <p dir="ltr"><span>4. POL&Iacute;TICA DE ENLACES&nbsp;</span></p>
                        <p dir="ltr"><span><br/></span><span>Se informa que el Sitio Web de GRABALO pone o puede poner a disposici&oacute;n de los Usuarios medios de enlace (como, entre otros, links, banners, botones), directorios y motores de b&uacute;squeda que permiten a los Usuarios acceder a sitios web pertenecientes y/o gestionados por terceros.</span>
                        </p>
                        <p dir="ltr">
                            <span>La instalaci&oacute;n de estos enlaces, directorios y motores de b&uacute;squeda en el Sitio Web tiene por objeto facilitar a los Usuarios la b&uacute;squeda de y acceso a la informaci&oacute;n disponible en Internet, sin que pueda considerarse una sugerencia, recomendaci&oacute;n o invitaci&oacute;n para la visita de los mismos.</span>
                        </p>
                        <p dir="ltr"><span>GRABALO no ofrece ni comercializa por s&iacute; ni por medio de terceros los productos y/o servicios disponibles en dichos sitios enlazados.</span>
                        </p>
                        <p dir="ltr"><span>Asimismo, tampoco garantizar&aacute; la disponibilidad t&eacute;cnica, exactitud, veracidad, validez o legalidad de sitios ajenos a su propiedad a los que se pueda acceder por medio de los enlaces.</span>
                        </p>
                        <p dir="ltr"><span>GRABALO en ning&uacute;n caso revisar&aacute; o controlar&aacute; el contenido de otros sitios web, as&iacute; como tampoco aprueba, examina ni hace propios los productos y servicios, contenidos, archivos y cualquier otro material existente en los referidos sitios enlazados.</span>
                        </p>
                        <p dir="ltr"><span>GRABALO no asume ninguna responsabilidad por los da&ntilde;os y perjuicios que pudieran producirse por el acceso, uso, calidad o licitud de los contenidos, comunicaciones, opiniones, productos y servicios de los sitios web no gestionados por GRABALO y que sean enlazados en este Sitio Web.</span>
                        </p>
                        <p dir="ltr">
                            <span>El Usuario o tercero que realice un hiperv&iacute;nculo desde una p&aacute;gina web de otro, distinto, sitio web al Sitio Web de GRABALO deber&aacute; saber que:</span>
                        </p>
                        <p dir="ltr"><span>No se permite la reproducci&oacute;n &mdash;total o parcialmente&mdash; de ninguno de los Contenidos y/o Servicios del Sitio Web sin autorizaci&oacute;n expresa de GRABALO.</span>
                        </p>
                        <p dir="ltr"><span>No se permite tampoco ninguna manifestaci&oacute;n falsa, inexacta o incorrecta sobre el Sitio Web de GRABALO, ni sobre los Contenidos y/o Servicios del mismo.</span>
                        </p>
                        <p dir="ltr"><span>A excepci&oacute;n del hiperv&iacute;nculo, el sitio web en el que se establezca dicho hiperenlace no contendr&aacute; ning&uacute;n elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento jur&iacute;dico espa&ntilde;ol, salvo autorizaci&oacute;n expresa de GRABALO.</span>
                        </p>
                        <p dir="ltr"><span>El establecimiento del hiperv&iacute;nculo no implicar&aacute; la existencia de relaciones entre GRABALO y el titular del sitio web desde el cual se realice, ni el conocimiento y aceptaci&oacute;n de GRABALO de los contenidos, servicios y/o actividades ofrecidos en dicho sitio web, y viceversa.</span>
                        </p>
                        <p dir="ltr"><span><br/></span><span>5. PROPIEDAD INTELECTUAL E INDUSTRIAL</span></p>
                        <p dir="ltr"><span><br/></span><span>GRABALO, por s&iacute; o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, as&iacute; como de los elementos contenidos en el mismo (a t&iacute;tulo enunciativo y no exhaustivo, im&aacute;genes, sonido, audio, v&iacute;deo, software o textos, marcas o logotipos, combinaciones de colores, estructura y dise&ntilde;o, selecci&oacute;n de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Ser&aacute;n, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jur&iacute;dico espa&ntilde;ol, si&eacute;ndoles aplicables tanto la normativa espa&ntilde;ola y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por Espa&ntilde;a.</span>
                        </p>
                        <p dir="ltr"><span>Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducci&oacute;n, la distribuci&oacute;n y la comunicaci&oacute;n p&uacute;blica, incluida su modalidad de puesta a disposici&oacute;n, de la totalidad o parte de los contenidos de esta p&aacute;gina web, con fines comerciales, en cualquier soporte y por cualquier medio t&eacute;cnico, sin la autorizaci&oacute;n de GRABALO.</span>
                        </p>
                        <p dir="ltr"><span>El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial de GRABALO. Podr&aacute; visualizar los elementos del Sitio Web o incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte f&iacute;sico siempre y cuando sea, exclusivamente, para su uso personal. El Usuario, sin embargo, no podr&aacute; suprimir, alterar, o manipular cualquier dispositivo de protecci&oacute;n o sistema de seguridad que estuviera instalado en el Sitio Web.</span>
                        </p>
                        <p dir="ltr"><span>En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una violaci&oacute;n de los derechos de protecci&oacute;n de la propiedad intelectual, deber&aacute; comunicarlo inmediatamente a GRABALO a trav&eacute;s de los datos de contacto del apartado de INFORMACI&Oacute;N GENERAL de este Aviso Legal y Condiciones Generales de Uso.</span>
                        </p>
                        <p dir="ltr"><span><br/></span><span>6. ACCIONES LEGALES, LEGISLACI&Oacute;N APLICABLE Y JURISDICCI&Oacute;N</span>
                        </p>
                        <p dir="ltr"><span>GRABALO se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilizaci&oacute;n indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.</span>
                        </p>
                        <p dir="ltr"><span>La relaci&oacute;n entre el Usuario y GRABALO se regir&aacute; por la normativa vigente y de aplicaci&oacute;n en el territorio espa&ntilde;ol. De surgir cualquier controversia en relaci&oacute;n con la interpretaci&oacute;n y/o a la aplicaci&oacute;n de estas Condiciones las partes someter&aacute;n sus conflictos a la jurisdicci&oacute;n ordinaria someti&eacute;ndose a los jueces y tribunales que correspondan conforme a derecho.</span>
                        </p>
                        <p><span><span>&nbsp;</span></span></p>
                        <p dir="ltr"><span>CONDICIONES GENERALES DE REGISTRO Y LICENCIA DE USO (USUARIOS/AS)</span></p>
                        <ol>
                            <li dir="ltr">
                                <h2 dir="ltr"><span>ASPECTOS GENERALES</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Las presentes Condiciones Generales de Uso, (&ldquo;</span><span>Condiciones Generales</span><span>&rdquo;) constituyen el marco regulador entre el/la Usuario/a y GRABA TU TALENTO, S.L.U.&nbsp; (&ldquo;</span><span>GR&Aacute;BALO</span><span>&rdquo;) para el acceso y proceso de subida, descarga, oferta y demanda de Contenidos.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Las Condiciones Generales constituyen, en su conjunto, el acuerdo aplicable entre GR&Aacute;BALO y el Usuario (en lo sucesivo el &ldquo;</span><span>Acuerdo</span><span>&rdquo;).</span>
                        </p>
                        <p dir="ltr">
                            <span>Se considerar&aacute; Usuario/a de GR&Aacute;BALO (&ldquo;</span><span>Usuario/a</span><span>&rdquo;) la persona f&iacute;sica que cree una cuenta a trav&eacute;s del presente de la Plataforma de GR&Aacute;BALO: </span><a
                            href="https://www.grabaloapp.com"><span>https://www.grabaloapp.com</span></a><span>&nbsp; (&ldquo;</span><span>Sitio Web</span><span>&rdquo;) o a trav&eacute;s de una aplicaci&oacute;n m&oacute;vil (la &ldquo;</span><span>App</span><span>&rdquo;) y suba su propio contenido a los mismos, de conformidad con el presente Acuerdo. En el momento en el que el Usuario se registra en el presente Sitio Web y sube contenido, acepta y se somete expresamente a las cl&aacute;usulas especificadas a continuaci&oacute;n, as&iacute; como a las Condiciones de Acceso y uso de nuestro Sitio Web y la </span><a
                            href="https://grabaloapp.com/politica-de-privacidad"><span>Pol&iacute;tica de Privacidad</span></a><span>, que ser&aacute;n complementarias a las presentes Condiciones Generales y formar&aacute;n parte del Acuerdo.</span>
                        </p>
                        <p dir="ltr"><span>El Usuario reconoce y declara ser mayor de edad y disponer de capacidad legal suficiente para obligarse contractualmente.</span>
                        </p>
                        <p dir="ltr"><span>En lo sucesivo, GR&Aacute;BALO y el Usuario, ser&aacute;n denominados conjuntamente como las &ldquo;Partes&rdquo; e individual e indistintamente como la &ldquo;Parte&rdquo;.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Usuario dispondr&aacute; de acceso permanente a las presentes Condiciones Generales.</span>
                        </p>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="2">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>DESCRIPCI&Oacute;N DE LOS SERVICIOS</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO, a trav&eacute;s del presente Sitio Web y la App ofrece a sus Usuarios/as una plataforma digital (la &ldquo;</span><span>Plataforma</span><span>&rdquo;) dirigida al proceso de subida, descarga, oferta y demanda de videos. El/La Usuario/a tendr&aacute; la posibilidad de subir o cargar contenido multimedia (videos, material de audio, texto, fotograf&iacute;as o gr&aacute;ficos), (el &ldquo;</span><span>Contenido</span><span>&rdquo;), en la Plataforma y participar en concursos para conseguir premios. La subida se podr&aacute; realizar utilizando herramientas internas de GR&Aacute;BALO u otras herramientas autorizadas por GR&Aacute;BALO y el Contenido servir&aacute; para la utilizaci&oacute;n por este o por terceros de acuerdo con la licencia concedida a trav&eacute;s del presente Acuerdo, a cambio de la obtenci&oacute;n de otras recompensas o beneficios.</span>
                        </p>
                        <p dir="ltr">
                            <span>La subida de Contenido se puede realizar de las siguientes maneras, aunque GR&Aacute;BALO se reserva el derecho de introducir otras modalidades de servicio:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Las Marcas proponen un reto a trav&eacute;s de GRABALO y el/la Usuario/a participa creando un Contenido espec&iacute;fico, en base a unos requisitos espec&iacute;ficos indicados en el propio reto.</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">
                            <span>Ambas partes acuerdan que el Contenido cargado por el/la usuario/a estar&aacute; sujeto a las presentes Condiciones Generales.</span>
                        </p>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="3">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>REQUISITOS PREVIOS DEL USUARIO PARA EL REGISTRO Y UTILIZACI&Oacute;N DE LOS SERVICIOS</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>S&oacute;lo podr&aacute;n registrarse en el Sitio Web y la App para utilizar la Plataforma las personas f&iacute;sicas mayores de edad y con suficiente capacidad legal para contratar. GR&Aacute;BALO tendr&aacute; derecho a denegar los Servicios, o a rescindir inmediatamente el Acuerdo con un Usuario:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Si este no cumple con los requisitos de edad o no tiene la capacidad jur&iacute;dica suficiente para contratar.&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Si este ha vulnerado las condiciones del Acuerdo con anterioridad.</span></p>
                            </li>
                        </ol>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Si se tienen sospechas de que el Uso de la Plataforma es una pr&aacute;ctica dirigida a analizar las&nbsp;actividades de GRABALO como competencia de otra entidad.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Adem&aacute;s, para poder utilizar el Sitio Web y subir Contenido, el Usuario debe: (a) obtener un usuario debidamente validado; (b) tener acceso a la conexi&oacute;n a Internet que resulte necesaria, que ser&aacute; responsabilidad del/de la Usuario/a.&nbsp;</span>
                        </p>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="4">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>PROCESO DE UTILIZACI&Oacute;N DE LOS SERVICIOS&nbsp;</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El proceso para el registro de los usuarios y utilizaci&oacute;n de los servicios a trav&eacute;s de la Plataforma de GR&Aacute;BALO constar&aacute;, de manera general, de los siguientes pasos:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>A trav&eacute;s del Sitio Web&nbsp;</span><a
                                    href="https://www.grabaloapp.com"><span>https://www.grabaloapp.com</span></a><span> o la App de GR&Aacute;BALO, el/la usuario/a deber&aacute; crear una cuenta, registr&aacute;ndose en el apartado correspondiente para poder subir su Contenido o votar el contenido de otras personas.&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Durante el proceso de registro, GR&Aacute;BALO solicitar&aacute; determinados datos personales para tramitar el alta del/de la Usuario/a, que ser&aacute;n tratados de conformidad con la </span><a
                                    href="https://grabaloapp.com/politica-de-privacidad"><span>Pol&iacute;tica de Privacidad</span></a><span>. Asimismo, deber&aacute; elegir sus credenciales de acceso y aceptar las presentes condiciones de utilizaci&oacute;n de los Servicios.&nbsp;</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO ofrece la posibilidad de registrarse a trav&eacute;s de redes sociales o plataformas de terceros, tal como se explica en la </span><a
                            href="https://grabaloapp.com/politica-de-privacidad"><span>Pol&iacute;tica de Privacidad.</span></a>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>Para validar las credenciales de acceso, el Usuario recibir&aacute;, en el correo indicado durante el proceso de registro, un enlace para validar la direcci&oacute;n de correo electr&oacute;nico si se registra con su email. Si el Usuario se registra con el bot&oacute;n de Google o Facebook no tendr&aacute; que validar su cuenta.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El registro del Usuario/a deber&aacute; realizarse como paso previo e indispensable a la subida de Contenido o la votaci&oacute;n del v&iacute;deo.</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <p dir="ltr"><span>Una vez finalizado el registro, el Usuario podr&aacute; subir sus contenidos o votar los v&iacute;deos de otras personas, y realizar cualquier otra acci&oacute;n que est&eacute; disponible.</span>
                                </p>
                            </li>
                        </ol>
                        <p><span><span>&nbsp;</span></span></p>
                        <p dir="ltr"><span>Al introducir sus datos, el Usuario declara que toda la informaci&oacute;n proporcionada es verdadera, completa, precisa, exhaustiva y actualizada; en caso de que GR&Aacute;BALO tenga dudas acerca de la veracidad de la informaci&oacute;n facilitada por el Usuario, podr&aacute; suspender o cerrar inmediatamente su cuenta, seg&uacute;n su criterio y sin previo aviso.</span>
                        </p>
                        <p dir="ltr"><span>El Usuario ser&aacute; el responsable de guardar de forma segura y confidencial las credenciales de acceso (usuario y contrase&ntilde;a): sin permitir que terceras personas usen su cuenta; sin obtener beneficios econ&oacute;micos en el uso de su cuenta o de su divulgaci&oacute;n a terceros. Asimismo, deber&aacute; comunicar a GR&Aacute;BALO a trav&eacute;s de la direcci&oacute;n electr&oacute;nica de contacto, cualquier incidencia relacionada con la seguridad de la cuenta y/o contrase&ntilde;a.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Usuario deber&aacute; mantener actualizada su informaci&oacute;n y los datos de contacto. Todas las transacciones llevadas a cabo antes de la actualizaci&oacute;n de los datos personales se basar&aacute;n en la informaci&oacute;n facilitada hasta ese momento. GR&Aacute;BALO no responde por las p&eacute;rdidas, retrasos, gastos o da&ntilde;os debidos a su falta de autenticidad o actualizaci&oacute;n.</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO no ser&aacute; responsable por los da&ntilde;os y perjuicios ocasionados por el uso indebido por parte del Usuario registrado de sus credenciales de acceso o de la informaci&oacute;n suministrada a GR&Aacute;BALO.</span>
                        </p>
                        <ol start="5">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>LICENCIA DE USO DEL CONTENIDO DEL USUARIO</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El/La Usuario/a es responsable exclusivo del Contenido que sube a la Plataforma de GRABALO. Si no desea otorgar a GR&Aacute;BALO los permisos establecidos en esta secci&oacute;n, no deber&aacute; cargar ni compartir su Contenido con GR&Aacute;BALO.</span>
                        </p>
                        <p dir="ltr">
                            <span>El/La Usuario/a, al subir el Contenido al Sitio Web, garantiza que:&nbsp;</span></p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>tiene todos los derechos necesarios para subir el Contenido al Sitio Web y conceder las licencias del presente Acuerdo;&nbsp;</span>
                                </p>
                            </li>
                        </ol>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>GR&Aacute;BALO no deber&aacute; obtener licencias de terceros ni pagar regal&iacute;as a ning&uacute;n tercero con respecto a la subida y utilizaci&oacute;n de los Contenidos;&nbsp;</span>
                                </p>
                            </li>
                        </ol>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>el Contenido no infringe derechos de ning&uacute;n tercero, incluyendo derechos de propiedad intelectual y derechos de privacidad.</span>
                                </p>
                            </li>
                        </ol>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Licencia otorgada a GR&Aacute;BALO</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Al subir el Contenido al Sitio Web, el Usuario/a otorga una licencia de explotaci&oacute;n y uso sobre el mismo, permanente, irrevocable, en exclusiva, para todo el &aacute;mbito mundial, libre de regal&iacute;as, con derecho a sublicenciar a terceros, lo que habilita a GR&Aacute;BALO a utilizar, alojar, almacenar, reproducir, descargar, copiar, modificar o adaptar, remezclar, transformar el Contenido, crear obras derivadas del mismo, distribuir y comunicar p&uacute;blicamente el Contenido (en parte o en su totalidad) para cualquier prop&oacute;sito, tanto comercial como no comercial, a trav&eacute;s de todos los medios y soportes conocidos en la actualidad o creados en el futuro (incluyendo internet, redes sociales, televisi&oacute;n, prensa y correos electr&oacute;nicos) y permitir que terceros realicen lo anterior.</span>
                        </p>
                        <p dir="ltr"><span>Ni GR&Aacute;BALO ni las Marcas o dem&aacute;s Usuarios/as de la Plataforma deber&aacute;n incluir cr&eacute;ditos o atribuci&oacute;n al utilizar el Contenido.</span>
                        </p>
                        <p dir="ltr"><span>Retribuci&oacute;n por la licencia</span><span>: La retribuci&oacute;n o compensaci&oacute;n por la concesi&oacute;n de la licencia ser&aacute; la indicada en la secci&oacute;n 8.</span>
                        </p>
                        <p dir="ltr">
                            <span>Duraci&oacute;n de la licencia</span><span>: La licencia concedida a GR&Aacute;BALO mediante la presente cl&aacute;usula sobre el Contenido subido a la Plataforma ser&aacute; ilimitada en el tiempo. Cuando el usuario elimine el Contenido de la Plataforma o se d&eacute; de baja de GR&Aacute;BALO, las sublicencias concedidas por GR&Aacute;BALO a las Marcas sobre el Contenido seguir&aacute;n vigentes seg&uacute;n las condiciones que se hayan pactado con las Marcas, pero no se podr&aacute;n otorgar nuevas sublicencias sobre dichos Contenidos. GR&Aacute;BALO podr&aacute; conservar el Contenido con fines legales de archivo durante los plazos legales aplicables.</span><span>&nbsp;</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Utilizaci&oacute;n del Contenido por GR&Aacute;BALO</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El/La Usuario/a autoriza a GR&Aacute;BALO a utilizar los Contenidos como parte de sus propias actividades internas, actividades promocionales, de marketing y/o publicidad. El Usuario/a acepta no percibir remuneraci&oacute;n o recompensa alguna por el uso de sus Contenidos para estos fines.</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>Utilizaci&oacute;n del Contenido por las Marcas (sublicencia)</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>La licencia otorgada a GR&Aacute;BALO permite a este conceder sublicencias de explotaci&oacute;n sobre el Contenido para que las Marcas que lo adquieran puedan reproducirlo, utilizarlo, descargarlo, copiarlo, modificarlo o adaptarlo y comunicarlo al p&uacute;blico y realizar sobre este cualquier tipo de explotaci&oacute;n a trav&eacute;s de cualquier formato o soporte, utilizando cualquier medio disponible (web, redes sociales, televisi&oacute;n, prensa, publicidad en internet, etc.), para fines internos y/o comerciales, siempre que se le d&eacute; la atribuci&oacute;n o retribuci&oacute;n / recompensa que se le indique en el momento de la subida de los v&iacute;deos al/a la Usuario/a.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO autorizar&aacute; a las Marcas, mediante contrato, a utilizar los Contenidos en base a una sublicencia de uso. GRABALO podr&aacute; incluir, para cada Marca, limitaciones, obligaciones y diferentes tipos de licencias relacionados con los Contenidos a su propio criterio (por ejemplo, las sublicencias podr&aacute;n ser exclusivas o no exclusivas; limitadas a un territorio; o limitadas temporalmente). Dichas Marcas, no obstante, no estar&aacute;n habilitadas para sublicenciar el Contenido a otros terceros, salvo en lo necesario para permitir el uso final del Contenido por parte de las Marcas (por ejemplo, empleados o subcontratistas).</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO puede pedir a las Marcas contraprestaci&oacute;n por la sublicencia del Contenido, a su propio criterio. La recompensa indicada en la secci&oacute;n 5.1., cuando corresponda, se recibir&aacute; por el/la Usuario/a cuando dicho Contenido sea efectivamente adquirido por las Marcas, sin que el/la Usuario/a pueda reclamar ning&uacute;n importe adicional por la sublicencia de su Contenido.</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO incluir&aacute; disposiciones en todas las sublicencias con las Marcas que concedan los derechos de explotaci&oacute;n establecidos en el presente Acuerdo, con las limitaciones indicadas.</span>
                        </p>
                        <p dir="ltr">
                            <span>GR&Aacute;BALO no promete ni garantiza que el Contenido subido ser&aacute; adquirido y utilizado por alguna de las Marcas.</span>
                        </p>
                        <p dir="ltr"><span>En este sentido, GR&Aacute;BALO se reserva el derecho a suprimir Contenido subido en el Sitio Web cuando estos no hayan sido adquiridos o utilizados por GR&Aacute;BALO o las Marcas durante un periodo de tiempo razonable.</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>Utilizaci&oacute;n del Contenido por los/as Usuarios/as</span></p>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>Nada en estas condiciones de servicio impedir&aacute; o intentar&aacute; evitar que el/la Usuario/a use su propio Contenido o el Contenido de terceros a los que pueda tener acceso a trav&eacute;s de la Plataforma para fines personales y no comerciales. El contenido se podr&aacute; compartir desde la propia Plataforma.</span>
                        </p>
                        <p dir="ltr"><span>En este sentido, el/la Usuario autoriza a los dem&aacute;s usuarios/as de la Plataforma a poder compartir su Contenido para fines personales no comerciales.</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO no ser&aacute; responsable de los actos u omisiones de los/as Usuarios/as en la Plataforma, incluyendo cualquier mal uso o abuso de cualquier Contenido que se suba a la Plataforma por parte del resto de Usuarios/as.&nbsp;</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>RESTRICCIONES SOBRE EL CONTENIDO</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Al enviar/cargar cualquier&nbsp;Contenido, el/la Usuario/a debe respetar las normas indicadas en la presente secci&oacute;n.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El/La Usuario/a se compromete a subir solo Contenido que:</span></p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Haya sido fruto de su propio trabajo original o en relaci&oacute;n con los cuales se hayan obtenido todos los derechos necesarios para otorgar la licencia indicada en la secci&oacute;n 5 anterior;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No haya sido producido en el &aacute;mbito de su trabajo o empleo para otra persona u organizaci&oacute;n;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Respete los par&aacute;metros, est&aacute;ndares y requisitos de calidad indicados por GR&Aacute;BALO.&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No est&eacute; sujeto a ning&uacute;n otro contrato, acuerdo o licencia. Es decir, no se otorgar&aacute; ni intentar&aacute; otorgar ning&uacute;n derecho sobre el Contenido a terceros. En este sentido, el Contenido no podr&aacute; ser cargado o subido en otros sitios web o aplicaciones que impliquen conceder licencias que sean conflictivas con la licencia otorgada a GR&Aacute;BALO (por ejemplo, bancos de material multimedia o similares).&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No viole ninguna ley o c&oacute;digo &eacute;tico aplicable.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>No sea difamatorio u obsceno, no contenga contenido pornogr&aacute;fico, o sea de cualquier otro modo il&iacute;cito o inmoral, ni promueva lo anterior;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No haya sido grabado o capturado a trav&eacute;s de medios ocultos, ilegales o cualquier otro m&eacute;todo que viole la protecci&oacute;n de datos, o los derechos de intimidad de otra persona u organizaci&oacute;n;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Cuando incluya im&aacute;genes de ni&ntilde;os menores de edad o personas con discapacidad las im&aacute;genes se hayan obtenido con el consentimiento expreso y por escrito de los titulares de la patria potestad o, en su caso, tutores legales conforme lo exige la ley;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Cuando incluya im&aacute;genes y/u otra informaci&oacute;n personal perteneciente a terceros, se hayan obtenido los permisos pertinentes para licenciar el Contenido de acuerdo con las presentes Condiciones Generales;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No publicite o promueva productos o servicios de cualquier tipo, salvo que GR&Aacute;BALO o las Marcas lo indiquen expresamente para un concurso.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No contengan c&oacute;digos de programaci&oacute;n da&ntilde;inos, virus, gusanos, troyanos o cualquier otro software malicioso o da&ntilde;ino para los sistemas de GR&Aacute;BALO o los de sus proveedores de servicios;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No contengan mecanismos o elementos que interfieren o prevengan el uso, edici&oacute;n o copia de los Contenidos.</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>GR&Aacute;BALO podr&aacute; revisar los Contenidos subidos al Sitio Web de forma previa a su inclusi&oacute;n en la Plataforma, aunque no tendr&aacute; la obligaci&oacute;n de hacerlo. GR&Aacute;BALO se reserva el derecho de suprimir o modificar cualquier Contenido que, a su propio criterio, no alcance la calidad suficiente o indicada, los requisitos indicados (en su caso), infrinja o pueda infringir estas Condiciones Generales o considere que son inapropiados sin necesidad de aviso previo.</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Notificaci&oacute;n de Contenido contrario a las presentes Condiciones Generales</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Si detecta cualquier Contenido disponible a trav&eacute;s del presente Sitio Web que infringe los derechos de propiedad intelectual, o infringe de cualquier otra manera las presentes Condiciones Generales, puede notificarlo a GR&Aacute;BALO a trav&eacute;s de la siguiente direcci&oacute;n de correo electr&oacute;nico: </span><a
                            href="mailto:info@grabaloapp.com"><span>info@grabaloapp.com</span></a><span>.&nbsp;</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>CESI&Oacute;N DE DERECHOS DE IMAGEN</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Mediante la aceptaci&oacute;n del presente Acuerdo, el/la Usuario/a autoriza a GR&Aacute;BALO a utilizar su imagen y voz que aparezcan en sus Contenidos en las condiciones y durante el plazo establecido en la licencia del presente Acuerdo. Se permite la cesi&oacute;n de los derechos de imagen a las Marcas a las que GR&Aacute;BALO pueda sublicenciar el Contenido.</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO y las Marcas podr&aacute;n utilizar los videos en los que aparezca su propia imagen y voz para los fines indicados en la licencia (por ejemplo, publicarlos en la p&aacute;gina web corporativa, en sus diferentes perfiles de redes sociales y en otros medios corporativos online y offline). Los v&iacute;deos, por ello, podr&aacute;n ser reproducidos y distribuidos a trav&eacute;s de diversos medios, incluyendo internet, televisi&oacute;n, comunicados de prensa, correos electr&oacute;nicos, etc. y pueden estar sujetos a modificaciones o ediciones. Los fines de la reproducci&oacute;n y publicaci&oacute;n de las im&aacute;genes estar&aacute;n relacionados con la publicidad, el marketing y la promoci&oacute;n de GR&Aacute;BALO y/o las Marcas y sus servicios.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>El/La Usuario/a cede los derechos de imagen/voz del Contenido para el mismo &aacute;mbito territorial y duraci&oacute;n que el concedido en la licencia (con car&aacute;cter indefinido y sin limitaci&oacute;n geogr&aacute;fica alguna). Todo el material se conservar&aacute; por GRABALO mientras sea relevante para sus fines o durante el tiempo en el que est&eacute; registrado/a en la Plataforma y despu&eacute;s de ese tiempo ser&aacute; bloqueado y suprimido seg&uacute;n los plazos legales de conservaci&oacute;n que sean aplicables.</span>
                        </p>
                        <p dir="ltr"><span>La remuneraci&oacute;n que reciba el usuario por la cesi&oacute;n de los derechos de imagen estar&aacute; incluida en la que se haya establecido por la licencia del Contenido.</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <h2 dir="ltr">
                                    <span>RECOMPENSAS; MONEDERO VIRTUAL; FORMA Y CONDICIONES DE PAGO&nbsp;</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>El importe de las recompensas que se deba pagar al Usuario/a por la utilizaci&oacute;n del Contenido puede variar, dependiendo de las caracter&iacute;sticas del Contenido y las condiciones de los acuerdos entre GR&Aacute;BALO y las Marcas.</span>
                        </p>
                        <p dir="ltr">
                            <span>En general, el/la Usuario/a recibir&aacute;, como recompensa, una comisi&oacute;n del cincuenta por ciento (50%) sobre el precio que pague una Marca por la adquisici&oacute;n de una sublicencia del Contenido. El precio recibido por GR&Aacute;BALO por cada sublicencia ser&aacute;, como m&aacute;ximo, de diez (10) euros, por lo que el/la usuario/a podr&aacute; recibir una comisi&oacute;n de, como m&aacute;ximo, 5 euros por cada sublicencia que conceda GR&Aacute;BALO a una Marca.</span>
                        </p>
                        <p dir="ltr"><span>No obstante lo anterior, podr&aacute; indicarse una recompensa diferente a la indicada en el p&aacute;rrafo anterior, en cuyo caso, quedar&aacute; indicado expresamente en el reto o categor&iacute;a correspondiente.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO se reserva el derecho a modificar las cantidades por las recompensas, tarifas y listas de precios, cuando as&iacute; lo considere conveniente. En todo caso, lo anterior no ser&aacute; de aplicaci&oacute;n para aquellos servicios contratados con anterioridad a la modificaci&oacute;n.</span>
                        </p>
                        <p dir="ltr"><span>Las comisiones se ir&aacute;n acumulando en el monedero virtual disponible en el perfil del/de la Usuario/a, donde se indicar&aacute; el saldo acumulado hasta la fecha (el &ldquo;</span><span>Monedero Virtual</span><span>&rdquo;). Los pagos de recompensas ser&aacute;n retenidos por GR&Aacute;BALO en el monedero virtual hasta que se efect&uacute;e una solicitud de pago, siempre y cuando el saldo acumulado en el monedero virtual, aplicando las deducciones e impuestos correspondientes, llegue al importe m&iacute;nimo establecido.</span>
                        </p>
                        <p dir="ltr">
                            <span>Los pagos de las recompensas se realizar&aacute;n por Bizum o Paypal en Espa&ntilde;a y Paypal para el resto del mundo. El/La Usuario/a deber&aacute; tener una cuenta activa en Paypal o en los medios que se puedan poner a disposici&oacute;n del/de la Usuario/a para recibir los pagos.&nbsp;</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Gastos e impuestos aplicables</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El/la Usuario/a se har&aacute; cargo de cualquier comisi&oacute;n o gasto de servicio que se aplique por parte de los medios de pago seleccionados, que ser&aacute;n descontados de su recompensa.</span>
                        </p>
                        <p dir="ltr">
                            <span>En este sentido, el Usuario reconoce y acepta que GR&Aacute;BALO podr&iacute;a estar obligado a retener impuestos de las recompensas del Usuario/a, dependiendo de las leyes aplicables. Al pago de las recompensas se le deducir&aacute;n los impuestos y los gastos aplicables.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El/La Usuario/a deber&aacute; cumplir con las obligaciones fiscales que puedan derivarse de la recepci&oacute;n de recompensas, siendo de su cargo cuantos gastos e impuestos graven el cobro de las mismas.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>El/La Usuario/a utilizar&aacute; sus propios medios para la realizaci&oacute;n de su Contenido. En consecuencia, el/la Usuario/a correr&aacute; con todos los gastos que se generen en el desarrollo de su Contenido, sin que pueda imputarse ninguna responsabilidad ni gasto a GR&Aacute;BALO respecto con la creaci&oacute;n del Contenido.</span>
                        </p>
                        <ol start="5">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>RETOS, CONCURSOS, SORTEOS Y PROMOCIONES</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO, nuestras Marcas y/o socios publicitarios pueden organizar actividades promocionales en el Sitio Web, tales como retos, concursos y otras promociones (las "</span><span>Promociones</span><span>"). Cada una de estas Promociones podr&aacute; estar sujeta a condiciones especiales adicionales (las &ldquo;</span><span>Condiciones Especiales</span><span>&rdquo;), que ser&aacute;n publicadas en nuestro Sitio Web o notificadas al/a la Usuario/a de otra manera.</span>
                        </p>
                        <p dir="ltr"><span>Las Condiciones Especiales formar&aacute;n parte integrante de estas Condiciones Generales. En caso de contradicci&oacute;n entre las Condiciones Generales y las Condiciones Especiales de alguna de las Promociones, prevalecer&aacute;n estas &uacute;ltimas.</span>
                        </p>
                        <p dir="ltr"><span>Las recompensas y premios relativos a cada una de las Promociones son intransferibles y, cuando estos no sean dinerarios, no podr&aacute;n intercambiarse por dinero en efectivo u otros productos o servicios.</span>
                        </p>
                        <p dir="ltr">
                            <span>Se indicar&aacute; al/a la Usuario/a cuando el Contenido subido a una Promoci&oacute;n sea con car&aacute;cter exclusivo, por lo que el Contenido subido para dicha Promoci&oacute;n solo se utilizar&aacute; por la Marca indicada para los fines indicados en la Promoci&oacute;n en concreto. En el resto de los casos, su Contenido podr&aacute; ser sublicenciado posteriormente a otras Marcas.</span>
                        </p>
                        <ol start="6">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>SISTEMA DE REFERIDOS</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO dispone de un programa de referidos mediante el cual el/la Usuario/a recibe recompensas u otros beneficios cuando un tercero (el &ldquo;</span><span>usuario referido</span><span>&rdquo;) se registra en el Sitio Web o App de GR&Aacute;BALO a trav&eacute;s de un c&oacute;digo &uacute;nico del Usuario/a. El funcionamiento del Sistema de Referidos se indica en el siguiente enlace. Las condiciones aplicables para dicho sistema ser&aacute;n las vigentes en el momento de utilizaci&oacute;n del c&oacute;digo &uacute;nico por parte del usuario referido.&nbsp;&nbsp;</span>
                        </p>
                        <ol start="7">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>DURACI&Oacute;N DEL ACUERDO</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El presente Acuerdo entra en vigor en la fecha de registro del Usuario en el Sitio Web (fecha de aceptaci&oacute;n de las presentes Condiciones Generales) y tiene una duraci&oacute;n indefinida, estando vigentes mientras el/la Usuario/a est&eacute; dado de alta como Usuario/a registrado/a en el Sitio Web o App. No obstante, cualquiera de las Partes podr&aacute; resolver el contrato en cualquier momento tal como se indica en la secci&oacute;n 14.</span>
                        </p>
                        <ol start="8">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>CONDICIONES DE USO DE LA PLATAFORMA</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El Usuario, respecto de los contenidos del Sitio Web, App o la Plataforma y los Contenidos subidos por terceros, los c&oacute;digos inform&aacute;ticos de los elementos que componen los mismos y el propio Sitio Web, App y Plataforma (los &ldquo;</span><span>Elementos</span><span>&rdquo;), no puede hacer (incluyendo alentar, facilitar o hacer que cualquier otra parte haga) lo siguiente:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Hacer un uso de los Elementos con fines comerciales o p&uacute;blicos;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Hacer un uso de los Elementos para cualquier fin ilegal;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>copiar/reproducir, prestar, vender, difundir, distribuir, editar o transferir o adaptar los Elementos;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>eludir, modificar, eliminar, alterar o manipular de alguna otra manera cualquier seguridad, cifrado u otra tecnolog&iacute;a o programa que forme parte de los Elementos;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>realizar cualquier tipo de acci&oacute;n contraria a las Condiciones de Uso y Navegaci&oacute;n (incluida la introducci&oacute;n de virus u otro Software malicioso, producci&oacute;n intencionada de una sobrecarga de los servidores u otras acciones que impidan la utilizaci&oacute;n normal de la Plataforma o sean susceptibles de provocar da&ntilde;os en los sistemas inform&aacute;ticos, del proveedor de acceso, sus proveedores o terceros usuarios de la red Internet);&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>suplantar a otros Usuarios utilizando sus claves de registro o crear cuentas falsas para acceder a los distintos servicios y/o contenidos de la Plataforma;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>realizar un uso de los Elementos de tal manera que pueda interferir, entorpecer, afectar negativamente, o impida a los dem&aacute;s Usuarios disfrutar plenamente de los servicios ofrecidos a trav&eacute;s de la Plataforma, o que pudiera da&ntilde;ar, deshabilitar, sobrecargar o perjudicar el funcionamiento de los Elementos.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>de cualquier otro modo usar los Elementos vulnerando las leyes sobre derechos de autor u otras leyes.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>En definitiva, queda prohibido cualquier acceso o utilizaci&oacute;n de los Elementos que sea contrario a lo previsto en esta cl&aacute;usula y, en especial, aquellos que puedan resultar ilegales, abusivos, fraudulentos o que puedan perjudicar los derechos de GR&Aacute;BALO, de los dem&aacute;s Usuarios y/o de terceras personas.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Usuario acepta y asume toda responsabilidad que pueda derivarse de cualquier actividad que ocurra en la misma bajo su nombre de usuario y contrase&ntilde;a.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Cualquier uso del Servicio en vulneraci&oacute;n de esta cl&aacute;usula se considerar&aacute; como un incumplimiento material del Acuerdo que permitir&aacute; a GR&Aacute;BALO resolver el Acuerdo con efecto inmediato, pudiendo cancelar o bloquear la cuenta del Usuario, as&iacute; como tomar aquellas medidas que resulten pertinentes para evitar un incumplimiento reiterado de las presentes estipulaciones.</span>
                        </p>
                        <ol start="9">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>BAJA DE GR&Aacute;BALO; INCUMPLIMIENTO DE LAS CONDICIONES</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El Usuario puede darse de baja de la Plataforma en cualquier momento, enviando una solicitud al correo electr&oacute;nico </span><a
                            href="mailto:info@grabaloapp.com"><span>info@grabaloapp.com</span></a><span>. Una vez confirmada la recepci&oacute;n de la solicitud, la petici&oacute;n ser&aacute; procesada en un plazo aproximado de 72 horas h&aacute;biles. En caso de baja (cierre de la cuenta), se perder&aacute; la informaci&oacute;n y datos que pudiese tener en la misma, as&iacute; como el derecho a utilizar cualesquiera beneficios o servicios activos a la fecha de la baja.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El/La Usuario/a es consciente de que la eliminaci&oacute;n de la App en su dispositivo no supone la baja en GR&Aacute;BALO, por lo que GR&Aacute;BALO podr&aacute; seguir disponiendo de su Contenido de conformidad con el presente Acuerdo.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Usuario deber&aacute; solicitar el pago de las recompensas acumuladas en su monedero virtual, que se pagar&aacute;n de conformidad con la secci&oacute;n siempre y cuando se lleguen a los m&iacute;nimos indicados. Se perder&aacute;n las recompensas pendientes de pago en el caso de no reclamar su pago en un plazo de un (1) mes desde la baja efectiva.</span>
                        </p>
                        <p dir="ltr"><span>En caso de baja del servicio:</span></p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Todos los Contenidos subidos ser&aacute;n eliminados de la Plataforma en un plazo razonable;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>El Contenido sobre el que ya se hayan concedido sublicencias a terceros, seguir&aacute; regulado por la presente licencia y la sublicencia se regir&aacute; por lo dispuesto en el Contrato entre GR&Aacute;BALO y la Marca que lo haya adquirido;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Quedar&aacute; sin efecto la licencia sobre Contenidos que no hayan sido&nbsp; sublicenciados, de manera que GR&Aacute;BALO no conceder&aacute; sublicencias sobre este Contenido del/de la Usuario/a a partir de la baja efectiva.</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">
                            <span>Al finalizar el Acuerdo, seguir&aacute;n vigentes, adem&aacute;s de los t&eacute;rminos de la licencia concedida para aquellos Contenidos ya sublicenciados, y aquellas estipulaciones que, por raz&oacute;n de su naturaleza deban&nbsp;continuar en vigor tras la resoluci&oacute;n del presente Acuerdo.</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Expulsi&oacute;n de los Servicios</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>En caso de incumplimiento o inobservancia total o parcial de las obligaciones o normas del presente Acuerdo por parte del/de la Usuario/a, o por cualquier otra causa justificada, GR&Aacute;BALO podr&aacute; bloquear, suspender, limitar o cancelar el acceso de dicho/a Usuario/a a todos o algunos de los servicios de la Plataforma, sin necesidad de notificaci&oacute;n previa, sin que el Usuario tenga derecho a compensaci&oacute;n alguna y sin perjuicio de que GR&Aacute;BALO pueda reclamar las indemnizaciones por da&ntilde;os y perjuicios que procedan.</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO se reserva el derecho de eliminar, suprimir, bloquear o modificar cualquier Contenido en cualquier momento y por cualquier raz&oacute;n y sin previo aviso, incluyendo cuando se le notifique que este infringe derechos de propiedad intelectual de otra persona, cuando este sea defectuoso, de mala calidad, o cuando a criterio exclusivo de GR&Aacute;BALO se considera que este ha infringido las presentes Condiciones Generales.</span>
                        </p>
                        <p dir="ltr"><span>Asimismo, en caso de infracci&oacute;n de las presentes Condiciones Generales, GR&Aacute;BALO podr&aacute; retener cualquier comisi&oacute;n debida por dichos Contenidos, independientemente de las acciones y reclamaciones de da&ntilde;os y perjuicios que pueda reclamar GR&Aacute;BALO con posterioridad por las v&iacute;as pertinentes.</span>
                        </p>
                        <ol start="10">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>PROPIEDAD INTELECTUAL</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO es el titular de todos los derechos de propiedad intelectual o industrial, o cuenta con autorizaci&oacute;n suficiente, sobre el Sitio Web, App y todos sus contenidos, incluyendo, sin car&aacute;cter limitativo, la Plataforma, todos los contenidos incluidos como parte de la Plataforma,&nbsp;marcas, c&oacute;digo fuente y objeto, dise&ntilde;os, interfaces, textos, im&aacute;genes, v&iacute;deos o cualquier otro material, as&iacute; como la arquitectura, presentaci&oacute;n, disposici&oacute;n y clasificaci&oacute;n de los contenidos de la Plataforma.</span>
                        </p>
                        <p dir="ltr"><span>No obstante, el/La usuario/a mantiene la titularidad sobre los Contenidos subidos a la Plataforma, como autor de estos, aunque sujetos a la licencia de uso concedida a GR&Aacute;BALO mediante las presentes Condiciones Generales.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>En este sentido, cuando el Usuario se registra en el Sitio Web o App, GR&Aacute;BALO no confiere a este ning&uacute;n derecho de explotaci&oacute;n sobre la totalidad o parte de estos, reserv&aacute;ndose GR&Aacute;BALO todos estos derechos.&nbsp;</span>
                        </p>
                        <ol start="11">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>PROTECCI&Oacute;N DE DATOS</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Cualquier dato de car&aacute;cter personal que los Usuarios faciliten durante el registro en el Sitio Web o la App y el uso de la Plataforma ser&aacute; tratado conforme a nuestra </span><a
                            href="https://grabaloapp.com/politica-de-privacidad"><span>Pol&iacute;tica de Privacidad</span></a><span> y, en el caso de las cookies, la </span><a
                            href="https://misitio.grabaloapp.com/terms/#cookies"><span>Pol&iacute;tica de Cookies</span></a><span>.</span>
                        </p>
                        <ol start="12">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>LIMITACI&Oacute;N DE LA RESPONSABILIDAD</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El uso de la Plataforma y la subida de Contenidos en este ser&aacute; bajo el propio riesgo y cuenta del/de la Usuario/a. Este/a ser&aacute; el/la &uacute;nico/a responsable de las decisiones y uso que haga de la informaci&oacute;n proporcionada en el Sitio Web/App y el Contenido incluido en la Plataforma. GR&Aacute;BALO no ofrece ninguna garant&iacute;a de obtenci&oacute;n de resultados espec&iacute;ficos por el uso de la Plataforma y la subida de Contenido en la misma.</span>
                        </p>
                        <p dir="ltr"><span>El/La Usuario/a acepta y reconoce que, tanto GR&Aacute;BALO como las Marcas que adquieran el Contenido para utilizarlo de conformidad con la licencia recogida en el presente Acuerdo, no ser&aacute;n responsable por ning&uacute;n da&ntilde;o directo o indirecto que sea resultado de la utilizaci&oacute;n o de la inhabilidad de utilizar cualquier Contenido disponible en la Plataforma.</span><span>El/La Usuario/a ser&aacute; el &uacute;nico responsable frente a cualquier reclamaci&oacute;n o acci&oacute;n legal, judicial o extrajudicial iniciada por terceros perjudicados directamente por el Contenido del usuario ante Juzgados u otros organismos, asumiendo &eacute;ste todos los gastos, costes e indemnizaciones que, en su caso, pueda asumir GR&Aacute;BALO si la reclamaci&oacute;n es dirigida contra &eacute;sta.</span>
                        </p>
                        <p dir="ltr"><span>El/La Usuario/a asume plena responsabilidad por cada acci&oacute;n realizada en la Plataforma a trav&eacute;s de su cuenta.</span>
                        </p>
                        <p dir="ltr"><span>​GR&Aacute;BALO no es creador, propietario, ni respalda ning&uacute;n Contenido ni ninguna opini&oacute;n, recomendaci&oacute;n o consejo expresado en los mismos, y, en consecuencia, renuncia expresamente a toda responsabilidad en relaci&oacute;n con cualquier Contenido subido y&nbsp;publicado en la Plataforma por los/as Usuarios/as.&nbsp;&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO no garantiza que la disponibilidad de los Contenidos o la Plataforma sea continua e ininterrumpida, por circunstancias originadas por problemas en la red de Internet, aver&iacute;as en los dispositivos inform&aacute;ticos y otras circunstancias imprevisibles. Por ello, el/la Usuario/a acepta soportar dentro de unos l&iacute;mites razonables estas circunstancias, y renuncia expresamente a reclamar a GR&Aacute;BALO cualquier responsabilidad contractual o extracontractual por los posibles fallos, errores y uso de estos.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>La Plataforma y los Contenidos se proporcionan &ldquo;tal cual&rdquo; y &ldquo;seg&uacute;n disponibilidad&rdquo;. GR&Aacute;BALO no formula ninguna declaraci&oacute;n ni garant&iacute;a sobre la idoneidad, fiabilidad, disponibilidad, exactitud, puntualidad, seguridad, ausencia de errores o precisi&oacute;n de estos. Asimismo, en la medida permitida por la ley, no se ofrecen cualesquiera garant&iacute;as (expresas o impl&iacute;citas), incluidas las garant&iacute;as de comerciabilidad, idoneidad para un prop&oacute;sito en particular, t&iacute;tulo o no infracci&oacute;n.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El/La Usuario/a acepta defender, indemnizar y eximir de toda responsabilidad a GR&Aacute;BALO, sus afiliados, Clientes, empleados, agentes o colaboradores frente a cualquier demanda, da&ntilde;o, reclamaci&oacute;n, sanci&oacute;n o responsabilidad que pudieran surgir de, o estar relacionados con el contenido de su Contenido, su conducta, el uso o incapacidad de uso de la Plataforma, su incumplimiento o falta de cumplimiento de las presentes Condiciones Generales o de cualquier declaraci&oacute;n o garant&iacute;a incluida en el presente documento, el uso no autorizado del Contenido de terceros disponibles en la Plataforma o la violaci&oacute;n de cualquier derecho de un tercero.</span>
                        </p>
                        <p dir="ltr"><span>La responsabilidad de las Partes estar&aacute; limitada a los da&ntilde;os directos (con exclusi&oacute;n, por tanto, del lucro cesante, de la p&eacute;rdida de negocio o del da&ntilde;o reputacional) efectivamente causados a la otra Parte. No obstante, nada de lo dispuesto en el presente Acuerdo excluir&aacute; o limitar&aacute; la responsabilidad que no pueda ser excluida o limitada en virtud de la legislaci&oacute;n aplicable.</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Fuerza mayor</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO no ser&aacute; responsable por ning&uacute;n incumplimiento o retraso en el cumplimiento de alguna de las obligaciones del presente Acuerdo, cuya causa se deba a acontecimientos que est&eacute;n fuera de su control razonable ("Causa de Fuerza Mayor&rdquo;). Se entender&aacute; que las obligaciones derivadas del Acuerdo quedar&aacute;n suspendidas durante el per&iacute;odo en que la Causa de Fuerza Mayor contin&uacute;e, y se dispondr&aacute; de una ampliaci&oacute;n en el plazo para cumplir dichas obligaciones por un periodo de tiempo igual al que dure la Causa de Fuerza Mayor. GR&Aacute;BALO pondr&aacute; todos los medios razonables para que finalice la Causa de Fuerza Mayor o para encontrar una soluci&oacute;n que permita cumplir las obligaciones en virtud del Acuerdo a pesar de la Causa de Fuerza Mayor.</span>
                        </p>
                        <ol start="13">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>NOTIFICACIONES</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Toda comunicaci&oacute;n entre las Partes se llevar&aacute; a cabo mediante correo electr&oacute;nico a las personas y direcciones de contacto indicadas por las Partes o por cualquier medio del cual se tenga constancia de su recepci&oacute;n a las personas y domicilios indicados.</span>
                        </p>
                        <ol start="14">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>MODIFICACIONES DE LAS CONDICIONES GENERALES</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO se reserva el derecho de modificar, en cualquier momento y sin previo aviso, las presentes Condiciones Generales. Dichas modificaciones entrar&aacute;n en vigor en la fecha de su publicaci&oacute;n en el Sitio Web o, en su caso, en la fecha que le sea indicada. Si no desea seguir utilizando el presente Sitio Web y los Servicios conforme a la nueva versi&oacute;n del Acuerdo, puede resolverlo poni&eacute;ndose en contacto con GR&Aacute;BALO.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO, le notificar&aacute; cualquier cambio, aunque el Usuario acepta la obligaci&oacute;n de revisar las condiciones peri&oacute;dicamente para estar al corriente de dichas modificaciones. En todo caso, el uso continuado de la Plataforma y subida de Contenido para GR&Aacute;BALO supondr&aacute; la aceptaci&oacute;n de las modificaciones del Acuerdo.</span>
                        </p>
                        <p><span><span><br/><br/></span></span></p>
                        <ol start="15">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>MISCELÁNEA&nbsp;</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Si cualquier cl&aacute;usula de las presentes Condiciones Generales fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia tan s&oacute;lo afectará a dicha disposici&oacute;n o a la parte de esta que resulte nula o ineficaz, subsistiendo el Acuerdo en todo lo dem&aacute;s, teni&eacute;ndose tal disposici&oacute;n, o la parte de la misma que resultase afectada, por no puesta.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El/La Usuario/a reconoce haber le&iacute;do, entendido y estar conforme con las presentes Condiciones Generales, por lo que afirma que son suficientes para la exclusi&oacute;n del error en el consentimiento del presente Acuerdo y, por lo tanto, las acepta integra y expresamente.</span>
                        </p>
                        <ol start="16">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>LEY APLICABLE Y JURISDICCIÓN</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>El presente Acuerdo y su ejecuci&oacute;n quedan sometidos a la legislaci&oacute;n espa&ntilde;ola.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Si surgiese cualquier controversia sobre la interpretaci&oacute;n o aplicaci&oacute;n de las presentes condiciones contractuales, las Partes negociar&aacute;n de buena fe para intentar resolver tal discrepancia o reclamaci&oacute;n. No obstante, para el supuesto de que la discrepancia o la reclamaci&oacute;n no se resolviera las Partes se someter&aacute;n a los juzgados o tribunales que resultaran competentes en cada caso.</span>
                        </p>
                        <p dir="ltr"><span>En casos en los que la normativa no prevea someterse a un fuero imperativo, GR&Aacute;BALO y el Usuario someten la decisi&oacute;n del asunto planteado a los Juzgados y Tribunales de Castell&oacute;n.</span>
                        </p>
                        <p dir="ltr"><span>Asimismo, cuando corresponda, la Comisi&oacute;n Europea ofrece una plataforma para la resoluci&oacute;n alternativa de conflictos, a la que cualquier consumidor puede acceder en el siguiente enlace:</span>
                        </p>
                        <p dir="ltr"><a
                            href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=ES"><span>https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=ES</span><span><br/></span></a>
                        </p>
                        <p dir="ltr"><span>Para cualquier duda o consulta, puede dirigirse a </span><a
                            href="mailto:info@grabaloapp.com"><span>info@grabaloapp.com</span></a><span>.&nbsp;</span>
                        </p>
                        <p><span><span><br/><br/></span></span></p>
                        <p dir="ltr"><span>CONDICIONES GENERALES DE SUBLICENCIA DE USO (ENTIDADES)</span></p>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol>
                            <li dir="ltr">
                                <h2 dir="ltr"><span>ASPECTOS GENERALES</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Las presentes Condiciones Generales de Uso, (&ldquo;</span><span>Condiciones Generales</span><span>&rdquo;) constituyen el marco regulador entre el Licenciatario y GRABA TU TALENTO, S.L.U.&nbsp; (&ldquo;</span><span>GR&Aacute;BALO</span><span>&rdquo; o el &ldquo;</span><span>Licenciante</span><span>&rdquo;), para la utilizaci&oacute;n de sus Servicios (los &ldquo;</span><span>Servicios</span><span>&rdquo;), descritos en la secci&oacute;n 2.</span>
                        </p>
                        <p dir="ltr">
                            <span>Se considerar&aacute; Licenciatario (el &ldquo;</span><span>Licenciatario</span><span>&rdquo;) la persona f&iacute;sica o jur&iacute;dica que cree una cuenta a trav&eacute;s del presente del Sitio Web de GR&Aacute;BALO: https://www.grabaloapp.com (&ldquo;</span><span>Sitio Web</span><span>&rdquo;) o la Aplicaci&oacute;n m&oacute;vil (la App) y adquiera videos u otros contenidos multimedia creados por los/las Usuarios/as de GR&Aacute;BALO de conformidad con el presente Acuerdo. Ser&aacute;n Usuarios/as de GR&Aacute;BALO las personas f&iacute;sicas que creen y suban su propio Contenido al Sitio Web para ponerlo a disposici&oacute;n de GR&Aacute;BALO y/o de los Licenciatarios (los o las &ldquo;</span><span>Usuarios/as</span><span>&rdquo;).&nbsp;&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>En el momento en el que el Licenciatario se registra en el presente Sitio Web o App y adquiere contenido, acepta y se somete expresamente a las cl&aacute;usulas especificadas a continuaci&oacute;n, as&iacute; como a las Condiciones de Acceso y uso&nbsp; y la </span><a
                            href="https://grabaloapp.com/politica-de-privacidad"><span>Pol&iacute;tica de Privacidad</span></a><span>, que constituyen, en su conjunto, el acuerdo aplicable entre GR&Aacute;BALO y el Licenciatario (en lo sucesivo el &ldquo;</span><span>Acuerdo</span><span>&rdquo;).</span>
                        </p>
                        <p dir="ltr"><span>El Licenciatario reconoce disponer de capacidad legal suficiente para obligarse contractualmente.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>En lo sucesivo, GR&Aacute;BALO y el Licenciatario, ser&aacute;n denominados conjuntamente como las &ldquo;Partes&rdquo; e individual e indistintamente como la &ldquo;Parte&rdquo;.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Licenciatario dispondr&aacute; acceso permanente a las presentes Condiciones Generales.</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>DESCRIPCI&Oacute;N DE LOS SERVICIOS</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO, a trav&eacute;s del presente Sitio Web o App ofrece una plataforma digital (la &ldquo;</span><span>Plataforma</span><span>&rdquo;) dirigida al proceso de subida, descarga, oferta y demanda de videos creados por los propios Usuarios de GR&Aacute;BALO. A trav&eacute;s de dicha plataforma, los Licenciatarios pueden adquirir contenido multimedia (videos, material de audio, texto, fotograf&iacute;as o gr&aacute;ficos), (el &ldquo;</span><span>Contenido</span><span>&rdquo;), para potenciar sus estrategias de marketing y publicidad. La adquisici&oacute;n del Contenido se podr&aacute; realizar utilizando herramientas internas de GR&Aacute;BALO u otras herramientas autorizadas por GR&Aacute;BALO y su utilizaci&oacute;n se realizar&aacute; de acuerdo con la sublicencia concedida a trav&eacute;s del presente Acuerdo.</span>
                        </p>
                        <p dir="ltr">
                            <span>La adquisici&oacute;n de Contenido se puede realizar mediante la contrataci&oacute;n de un Plan de Servicios. El Licenciatario propone un reto a trav&eacute;s de GR&Aacute;BALO y el/la Usuario/a participa creando un Contenido espec&iacute;fico para dicho reto, en base a unos requisitos espec&iacute;ficos indicados en el propio reto. La contrataci&oacute;n de un Plan dar&aacute; derecho al Licenciatario a publicar retos dentro de la Plataforma de GR&Aacute;BALO con las limitaciones que se indiquen para cada Plan.</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <h2 dir="ltr">
                                    <span>REQUISITOS PREVIOS DEL LICENCIATARIO PARA EL REGISTRO Y UTILIZACI&Oacute;N DE LOS SERVICIOS</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>S&oacute;lo podr&aacute;n registrarse en el presente Sitio Web o App las personas f&iacute;sicas mayores de edad y con suficiente capacidad legal para contratar. En caso de que la persona f&iacute;sica represente a una persona jur&iacute;dica, el firmante deber&aacute;&nbsp;tener poderes suficientes&nbsp;para vincular a la entidad que represente con los t&eacute;rminos de este Acuerdo.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO tendr&aacute; derecho a denegar los Servicios, o a rescindir inmediatamente el Acuerdo con un Usuario/Licenciatario:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Si este no cumple con los requisitos de edad o no tiene la capacidad jur&iacute;dica suficiente para contratar por s&iacute; mismo o en nombre de la entidad a que represente.&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Si este ha vulnerado las condiciones del Acuerdo con anterioridad.</span></p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Si se tienen sospechas fundadas de que el uso de la Plataforma es una pr&aacute;ctica dirigida a analizar las&nbsp;actividades de GRABALO como competencia de otra entidad.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Adem&aacute;s, para poder utilizar la Plataforma, el Licenciatario debe: (a) obtener un usuario debidamente validado; (b) tener acceso a la conexi&oacute;n a Internet que resulte necesaria, que ser&aacute; responsabilidad del Licenciatario.&nbsp;</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>LICENCIA OTORGADA AL LICENCIATARIO</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El Licenciatario podr&aacute; acceder y utilizar la Plataforma y los Servicios de conformidad con el presente Acuerdo.</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO es el titular de todos los derechos de propiedad industrial e intelectual del Sitio Web, la App y de los Servicios o licenciatario leg&iacute;timo de los mismos. El uso de los Contenidos por parte del Licenciatario estar&aacute; limitado a la sublicencia de uso recogida en el presente Acuerdo.</span>
                        </p>
                        <p dir="ltr"><span>En relaci&oacute;n con el Contenido que se le permite descargar y utilizar de acuerdo con el Plan contratado, GR&Aacute;BALO otorga al Licenciatario una sublicencia limitada, no exclusiva, irrevocable, perpetua, intransferible y no sublicenciable, para reproducir, descargar, alojar, almacenar</span><span>,</span><span> copiar, modificar, adaptar o transformar, distribuir, crear obras derivadas, utilizar el Contenido, distribuir&nbsp; y comunicarlo al p&uacute;blico (en parte o en su totalidad) en los medios y/o soportes indicados en la secci&oacute;n 4.2., incluso con fines comerciales, sin necesidad de atribuci&oacute;n al autor del Contenido o a GR&Aacute;BALO. La utilizaci&oacute;n del Contenido estar&aacute; limitada por las restricciones indicadas en la secci&oacute;n 4.2.</span>
                        </p>
                        <p dir="ltr"><span>La sublicencia concedida al Licenciatario es para uso del Contenido en todo el &aacute;mbito mundial e ilimitada en el tiempo.</span>
                        </p>
                        <p dir="ltr">
                            <span>La retribuci&oacute;n por la sublicencia ser&aacute; la indicada en la secci&oacute;n 5. La adquisici&oacute;n de&nbsp;la sublicencia y posibilidad de utilizar el Contenido en base a la misma estar&aacute; supeditada&nbsp;al pago a GR&Aacute;BALO de las cantidades acordadas.</span>
                        </p>
                        <p dir="ltr"><span>La presente licencia, no obstante, no da derecho al Licenciatario a:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Utilizar fotogramas del Contenido para hacer packaging, merchandising, vallas publicitarias, material publicitario impreso o similares.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Reunir, compilar o de otra forma agrupar Contenido de GR&Aacute;BALO para replicar un servicio similar o competidor;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Sublicenciar el Contenido a terceros, salvo en lo necesario para permitir al Licenciatario el uso final del Contenido (por ejemplo, uso del Contenido por parte de empleados o subcontratistas).</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>Todos los derechos de propiedad intelectual&nbsp; e industrial del Contenido no previstos en la presente cl&aacute;usula quedar&aacute;n reservados a GR&Aacute;BALO y/o el autor del Contenido.</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Representaciones y garant&iacute;as</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Los/Las Usuarios/as que suben Contenido al Sitio Web representan y garantizan que (i) tiene todos los derechos necesarios para subir el Contenido a la Plataforma y conceder las licencias del presente Acuerdo; (ii) GR&Aacute;BALO o el Licenciatario no necesitan obtener licencias de terceros o pagar regal&iacute;as a ning&uacute;n tercero con respecto a la subida y utilizaci&oacute;n de los Contenidos; (iii) el Contenido no infringe derechos de ning&uacute;n tercero, incluyendo derechos de propiedad intelectual y la protecci&oacute;n de datos.</span>
                        </p>
                        <p dir="ltr"><span>No obstante lo anterior, GR&Aacute;BALO no puede controlar ni monitorear exhaustivamente todo el Contenido subido al Sitio Web. Por lo tanto, no hace ninguna representaci&oacute;n o garant&iacute;a del cumplimiento de las garant&iacute;as representadas por los/las Usuarios/as. Asimismo, GR&Aacute;BALO no es responsable de los actos u omisiones de los/as Usuarios/as, incluyendo cualquier mal uso o abuso de cualquier Contenido disponible en la Plataforma.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Igualmente, aunque GR&Aacute;BALO realiza esfuerzos razonables para categorizar, catalogar, incluir </span><span>hashtags, </span><span>y titular correctamente los Contenidos, GR&Aacute;BALO no garantiza la exactitud de tal informaci&oacute;n, as&iacute; como tampoco de cualquier elemento proporcionado o incluido en el Contenido.</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>Restricciones sobre el uso del Contenido</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El Licenciatario es responsable exclusivo del uso que realiza del Contenido disponible en la Plataforma.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Contenido se podr&aacute; utilizar, de acuerdo con los t&eacute;rminos de la sublicencia, independientemente o combinado con otros elementos, modificado o no, en los soportes o medios siguientes: sitios web; redes sociales; banners publicitarios; software (para PC o dispositivos m&oacute;viles); aplicaciones m&oacute;vil; e-mails; presentaciones (PowerPoint, Flash, etc.); cualquier tipo de plantillas; pel&iacute;culas, v&iacute;deos y anuncios televisivos; logotipos no oficiales; art&iacute;culos para reventa y distribuci&oacute;n gratuita; publicaciones: peri&oacute;dicos, revistas, etc.; art&iacute;culos y publicaciones en blogs o sitios web, uso no comercial relacionado con eventos; presentaciones no comerciales, programas de TV; pel&iacute;culas documentales; noticias; uso personal.</span>
                        </p>
                        <p dir="ltr"><span>No se podr&aacute;: Utilizar fotogramas del Contenido para hacer packaging, merchandising, vallas publicitarias, material publicitario impreso o similares.</span>
                        </p>
                        <p dir="ltr"><span>En este sentido, </span><span>el Licenciatario se compromete a utilizar el Contenido de forma que:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>No transferir&aacute;, revender&aacute;, alquilar&aacute;, donar&aacute;, conceder&aacute; una sublicencia, ceder&aacute; de cualquier modo el Contenido o derechos sobre este a terceros, salvo en lo permitido por la sublicencia. En este sentido, no est&aacute; permitido subir o cargar el Contenido en bancos de material multimedia o plataformas similares;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No utilizar&aacute; el Contenido para ninguna actividad que compita con la actividad de GR&Aacute;BALO;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No utilizar&aacute; el Contenido de modo que se violen los derechos de propiedad intelectual del mismo o de terceros;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No utilizar&aacute; el Contenido para crear material ni para representar a cualquier persona representada en el Contenido de forma difamatoria u obscena, il&iacute;cita o inmoral o&nbsp;en entornos que pudieran ser considerados ofensivos o poco favorecedores para esa persona, ni promover&aacute; lo anterior;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>Se incluye en la prohibici&oacute;n anterior representar a las personas que aparezcan en el Contenido: a) en situaciones o contextos relacionados con la pornograf&iacute;a, lugares de ocio para adultos, servicios de acompa&ntilde;antes, servicios de citas o similares; b) en relaci&oacute;n con la promoci&oacute;n de productos y servicios, salvo que el Contenido se haya realizado concretamente para dicha finalidad en relaci&oacute;n con productos y servicios del Licenciatario; c) en relaci&oacute;n con la promoci&oacute;n, publicidad o apoyo de cualquier partido pol&iacute;tico, candidato a elecciones, o en relaci&oacute;n con cualquier o ideolog&iacute;a pol&iacute;tica o religiosa; d) en relaci&oacute;n con medicamentos o enfermedades de cualquier tipo; o e) en relaci&oacute;n con actividades delictivas;</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>No utilizar&aacute; el Contenido para crear material que pueda ser considerado publicidad enga&ntilde;osa o competencia desleal;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>No utilizar&aacute; el Contenido o los Servicios y/o el Sitio Web para almacenamiento, publicaci&oacute;n y/o transmisi&oacute;n de "correos basura", cadenas de correos, correos electr&oacute;nicos masivos no solicitados, etc. (spam), o para cualquier otro prop&oacute;sito similar;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>No declarar&aacute; falsamente ser el autor original del Contenido.</span></p>
                            </li>
                        </ul>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>Notificaci&oacute;n de Contenido contrario a las presentes Condiciones Generales</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Si detecta cualquier Contenido disponible a trav&eacute;s del presente Sitio Web infringe los derechos de propiedad intelectual, o infringe de cualquier otra manera las presentes Condiciones Generales, puede notificarlo a GR&Aacute;BALO a trav&eacute;s de la siguiente direcci&oacute;n de correo electr&oacute;nico: </span><a
                            href="mailto:info@grabaloapp.com"><span>info@grabaloapp.com</span></a><span> .</span></p>
                        <ol start="5">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>FORMA Y CONDICIONES DE PAGO&nbsp;</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El Licenciatario abonar&aacute; a GR&Aacute;BALO:</span></p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>En el caso de contratar un Plan, las cantidades indicadas en el momento de la contrataci&oacute;n del Plan seleccionado.&nbsp;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Por la adquisici&oacute;n de una sublicencia de Contenido, las cantidades que se indiquen por GR&Aacute;BALO en el momento de adquirir dicho Contenido.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Las cantidades indicadas en el Sitio Web est&aacute;n sujetas al Impuesto del Valor A&ntilde;adido (IVA) que NO EST&Aacute; INCLUIDO en los precios.</span>
                        </p>
                        <p dir="ltr">
                            <span>De manera previa a la confirmaci&oacute;n y antes de proceder al pago, GR&Aacute;BALO indicar&aacute; el precio final que incluir&aacute; el IVA (Impuesto sobre el Valor A&ntilde;adido), los gastos de gesti&oacute;n y dem&aacute;s gastos que, en su caso, procedan.</span>
                        </p>
                        <p dir="ltr">
                            <span>No se incluye todo aquello que no aparezca especificado en la descripci&oacute;n como incluido.</span>
                        </p>
                        <p dir="ltr"><span>En este sentido, los pagos ser&aacute;n siempre previos al inicio de la relaci&oacute;n contractual o suministro del Contenido, por lo que GR&Aacute;BALO se reserva el derecho de a suspender temporalmente o cancelar definitivamente la contrataci&oacute;n ante cualquier incidencia en el cobro de las cantidades pactadas.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Todos los gastos e impuestos que se originen como consecuencia de la formalizaci&oacute;n, cumplimiento o extinci&oacute;n del presente Acuerdo y de las obligaciones que de &eacute;l se deriven ser&aacute;n de cargo del Licenciatario.</span>
                        </p>
                        <h3 dir="ltr"><span>5.1. Forma y m&eacute;todos de pago</span></h3>
                        <p dir="ltr"><span>GR&Aacute;BALO emitir&aacute; una factura por el valor del Plan contratado y/o por cada sublicencia adquirida. Los pagos se podr&aacute;n realizarse a trav&eacute;s de transferencia bancaria al n&uacute;mero de cuenta indicado en la factura, u otros medios que se pongan a su disposici&oacute;n a trav&eacute;s de la propia Plataforma.</span>
                        </p>
                        <p dir="ltr"><span>Se pondr&aacute; a disposici&oacute;n de los/as Usuarios/as la posibilidad de descargar las facturas en formato PDF&nbsp;de sus descargas de Contenido en una zona habilitada para tal fin en el perfil del Licenciatario dentro de la Plataforma o, en su defecto, estas ser&aacute;n remitidas a la direcci&oacute;n electr&oacute;nica indicada por el Licenciatario en el momento de la contrataci&oacute;n.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El CLIENTE es responsable de la confirmaci&oacute;n de la recepci&oacute;n de las notificaciones y de poner en nuestro conocimiento cualquier modificaci&oacute;n sobre sus datos, quedando GR&Aacute;BALO exonerado de cualquier responsabilidad derivada por esta circunstancia.</span>
                        </p>
                        <ol start="6">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>DURACI&Oacute;N DEL ACUERDO</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El presente Acuerdo entra en vigor en la fecha de alta en el Sitio Web o App (fecha de aceptaci&oacute;n de las presentes Condiciones Generales) y tendr&aacute; una duraci&oacute;n indefinida, mientras est&eacute; registrado en la Plataforma. No obstante, cualquiera de las Partes podr&aacute; resolver el Acuerdo en cualquier momento tal como se indica en la secci&oacute;n 8.</span>
                        </p>
                        <ol start="7">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>CONDICIONES DE USO DEL SITIO WEB Y CONTENIDOS DE TERCEROS</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>El Licenciatario, respecto con los contenidos del Sitio Web, los c&oacute;digos inform&aacute;ticos de los elementos que componen los mismos y el propio Sitio Web (los &ldquo;</span><span>Elementos</span><span>&rdquo;), no puede hacer (incluyendo alentar, facilitar o hacer que cualquier otra parte haga) lo siguiente:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Hacer un uso de los Elementos para cualquier fin ilegal;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>copiar/reproducir, prestar, vender, difundir, distribuir, editar o transferir o adaptar los Elementos;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>eludir, modificar, eliminar, alterar o manipular de alguna otra manera cualquier seguridad, cifrado u otra tecnolog&iacute;a o programa que forme parte de los Elementos;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>realizar cualquier tipo de acci&oacute;n contraria a las Condiciones de Uso y Navegaci&oacute;n del Sitio Web (incluida la introducci&oacute;n de virus u otro Software malicioso, producci&oacute;n intencionada de una sobrecarga de los servidores u otras acciones que impidan la utilizaci&oacute;n normal del Sitio Web o sean susceptibles de provocar da&ntilde;os en los sistemas inform&aacute;ticos, del proveedor de acceso, sus proveedores o terceros usuarios de la red Internet);&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>suplantar a otros Usuarios utilizando sus claves de registro o crear cuentas falsas para acceder a los distintos servicios y/o contenidos del Sitio Web;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>realizar un uso de los Elementos de tal manera que pueda interferir, entorpecer, afectar negativamente, o impida a los dem&aacute;s usuarios disfrutar plenamente de los servicios, o que pudiera da&ntilde;ar, deshabilitar, sobrecargar o perjudicar el funcionamiento de los servicios.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>de cualquier otro modo usar los Elementos vulnerando las leyes sobre derechos de autor u otras leyes.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>En definitiva, queda prohibido cualquier acceso o utilizaci&oacute;n de los Elementos que sea contrario a lo previsto en esta cl&aacute;usula y, en especial, aquellos que puedan resultar ilegales, abusivos, fraudulentos o que puedan perjudicar los derechos de GR&Aacute;BALO, de los dem&aacute;s Usuarios y/o de terceras personas.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Licenciatario acepta y asume toda responsabilidad que pueda derivarse de cualquier actividad que ocurra en la misma bajo su nombre de usuario y contrase&ntilde;a.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Cualquier uso de los Elementos en vulneraci&oacute;n de esta cl&aacute;usula se considerar&aacute; como un incumplimiento material del Acuerdo que permitir&aacute; a GR&Aacute;BALO resolver el Acuerdo con efecto inmediato, pudiendo cancelar o bloquear la cuenta del Licenciatario, as&iacute; como tomar aquellas medidas que resulten pertinentes para evitar un incumplimiento reiterado de las presentes estipulaciones.</span>
                        </p>
                        <ol start="8">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>TERMINACI&Oacute;N DEL ACUERDO; BAJA DE LOS SERVICIOS; INCUMPLIMIENTO DE LAS CONDICIONES</span>
                                </h2>
                            </li>
                            <ol>
                                <li dir="ltr">
                                    <p dir="ltr"><span>Terminaci&oacute;n del Acuerdo (baja de los Servicios)</span></p>
                                </li>
                            </ol>
                        </ol>
                        <p dir="ltr"><span>El Acuerdo podr&aacute; resolverse en cualquier momento, por parte del Licenciatario enviando un correo electr&oacute;nico a la direcci&oacute;n </span><a
                            href="mailto:info@grabaloapp.com"><span>info@grabaloapp.com</span></a><span>, y por las siguientes causas:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Por mutuo acuerdo de las Partes recogido por escrito.</span></p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Cuando medie incumplimiento grave de las obligaciones pactadas.</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Se considerar&aacute; incumplimiento grave aqu&eacute;l que, habiendo sido previamente comunicado de forma fehaciente por la parte cumplidora a la incumplidora, no haya sido subsanado o no se hayan adoptado las medidas tendentes a obtener dicha subsanaci&oacute;n en el plazo de los quince (15) d&iacute;as siguientes a la recepci&oacute;n de la comunicaci&oacute;n.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>En caso de incumplimiento o inobservancia total o parcial de las obligaciones o normas de las presentes Condiciones Generales por parte del Licenciatario GR&Aacute;BALO podr&aacute; bloquear, suspender, limitar o cancelar el acceso del Licenciatario infractor a todos o algunos de los Servicios, sin necesidad de notificaci&oacute;n previa, sin que el Licenciatario tenga derecho a compensaci&oacute;n alguna y sin perjuicio de que GR&Aacute;BALO pueda reclamar las indemnizaciones por da&ntilde;os y perjuicios que procedan.</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>La extinci&oacute;n de la personalidad jur&iacute;dica de cualquiera de las Partes o la suspensi&oacute;n de pagos, quiebra, concurso o embargo de bienes de alguna de ellas.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Si GR&Aacute;BALO detectara cualquier conducta o actividad ilegal o contraria a la sublicencia del Contenido, sin necesidad de previo aviso.&nbsp;</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Al finalizar el Acuerdo, seguir&aacute;n vigentes los t&eacute;rminos de la sublicencia concedida y aquellas estipulaciones que, por raz&oacute;n de su naturaleza deban&nbsp;continuar en vigor tras la resoluci&oacute;n del presente Acuerdo.</span>
                        </p>
                        <ol start="9">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>PROPIEDAD INTELECTUAL</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO es el titular de todos los derechos de propiedad intelectual o industrial, o cuenta con autorizaci&oacute;n suficiente, sobre el Sitio Web y todos sus contenidos, incluyendo, sin car&aacute;cter limitativo, todos los Contenidos incluidos como parte de los Servicios,&nbsp;marcas, c&oacute;digo fuente y objeto, dise&ntilde;os, interfaces, textos, im&aacute;genes, v&iacute;deos o cualquier otro material, as&iacute; como la arquitectura, presentaci&oacute;n, disposici&oacute;n y clasificaci&oacute;n de los contenidos del Sitio Web.</span>
                        </p>
                        <p dir="ltr"><span>En este sentido, cuando el Licenciatario se registra y tiene acceso a la Plataforma y dem&aacute;s elementos que componen los Servicios, GR&Aacute;BALO no confiere a este ning&uacute;n derecho de explotaci&oacute;n sobre la totalidad o parte del mismo, reserv&aacute;ndose GR&Aacute;BALO todos estos derechos. Para poder utilizar los Contenidos disponibles en la Plataforma, el Licenciatario deber&aacute; adquirir la pertinente sublicencia, que regular&aacute; el uso que el Licenciatario podr&aacute; hacer de los Contenidos.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Los/Las Usuarios mantendr&aacute;n la propiedad de sus Contenidos como autores de los mismos, sin perjuicio de la cesi&oacute;n de los derechos de explotaci&oacute;n a favor de GR&Aacute;BALO y, una vez adquirida la sublicencia, a favor del Licenciatario.</span>
                        </p>
                        <ol start="10">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>PROTECCI&Oacute;N DE DATOS</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Cualquier dato de car&aacute;cter personal que se faciliten durante el uso de la Plataforma ser&aacute; tratado conforme a nuestra </span><a
                            href="https://grabaloapp.com/politica-de-privacidad"><span>Pol&iacute;tica de Privacidad</span></a><span> y, en el caso de las cookies, la </span><a
                            href="https://misitio.grabaloapp.com/terms/#cookies"><span>Pol&iacute;tica de Cookies.</span></a>
                        </p>
                        <p dir="ltr"><span>10.1 Cesi&oacute;n de Datos en caso de Retos y otras Promociones:</span></p>
                        <p dir="ltr"><span>GRABALO realizar&aacute; la comunicaci&oacute;n de datos de los participantes de los retos y dem&aacute;s Promociones, cuando sea aplicable, al Licenciatario, ya sea de personas que han subido sus v&iacute;deos para participar en el concurso como si son personas que se han registrado para votar por alg&uacute;n v&iacute;deo. A tales efectos, GRABALO ceder&aacute; dichos datos en base a la relaci&oacute;n contractual existente entre GR&Aacute;BALO y el usuario que participa en los retos para que el Licenciatario pueda contactar con las personas ganadoras del reto y para informar de promociones de sus productos o servicios. GR&Aacute;BALO cumplir&aacute; con la obligaci&oacute;n de informar a los interesados sobre el tratamiento de sus datos y, en particular, sobre la cesi&oacute;n de datos objeto de esta cl&aacute;usula.</span>
                        </p>
                        <p dir="ltr"><span>El Licenciatario, una vez reciba los datos personales de los usuarios ganadores, se compromete expresamente a cumplir de manera &iacute;ntegra con sus obligaciones impuestas por la normativa de protecci&oacute;n de datos, incluido, cuando sea aplicable, la de suministrar la informaci&oacute;n de privacidad a los mismos.</span>
                        </p>
                        <p dir="ltr"><span>GRABALO notificar&aacute; inmediatamente al Licenciatario si recibe alguna solicitud de un interesado para ejercer los siguientes derechos del interesado: acceso, rectificaci&oacute;n, oposici&oacute;n, supresi&oacute;n, limitaci&oacute;n del tratamiento y portabilidad de los datos.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>En particular, GRABALO se compromete a informar al Licenciatario, en un plazo m&aacute;ximo de 24 horas desde que tuvo conocimiento sobre el ejercicio del derecho correspondiente por parte del interesado, de aquellos interesados que hayan ejercido sus derechos, afectando a la cesi&oacute;n o revocado el consentimiento otorgado inicialmente para la cesi&oacute;n de sus datos.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Licenciatario se compromete a utilizar los datos personales de los ganadores para las finalidades indicadas.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Cada una de las Partes ser&aacute; responsable de las consecuencias que pudieran derivarse del incumplimiento de las obligaciones contra&iacute;das en virtud de esta cl&aacute;usula o las derivadas del incumplimiento de las obligaciones de cada uno que le son impuestas por la normativa de privacidad aplicable, debiendo asumir, en este supuesto, la reparaci&oacute;n de los da&ntilde;os y perjuicios que tal incumplimiento pudiera ocasionar a la otra parte.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>La parte incumplidora indemnizar&aacute; a la otra parte por la totalidad de los da&ntilde;os y perjuicios que aqu&eacute;lla le ocasione a &eacute;sta, derivados del incumplimiento del presente contrato.&nbsp;</span>
                        </p>
                        <ol start="11">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>LIMITACI&Oacute;N DE LA RESPONSABILIDAD</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO no ofrece ninguna garant&iacute;a de obtenci&oacute;n de resultados espec&iacute;ficos por el uso de la Plataforma y de los Contenidos disponibles en la misma. El Licenciatario asume todos los riesgos asociados con el uso del Contenido. El Licenciatario asume plena responsabilidad por cada acci&oacute;n realizada en el Sitio Web a trav&eacute;s de su cuenta.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>GR&Aacute;BALO solo responder&aacute; ante el Licenciatario, de los da&ntilde;os y perjuicios ocasionados por causas imputables GR&Aacute;BALO o a sus empleados como consecuencia de dolo o culpa grave.</span>
                        </p>
                        <p dir="ltr"><span>El Licenciatario acepta y reconoce que en ning&uacute;n caso GR&Aacute;BALO ser&aacute; responsable de los da&ntilde;os, directos o indirectos causados, o derivados, en todo o en parte, como consecuencia acciones u omisiones del Licenciatario dolosas o negligentes, no realizada conforme a los principios de la buena fe, por incumplimientos de las presentes Condiciones Generales, derivados del modo en que se haya utilizado el Contenido o, en definitiva, que se produzcan por causas que est&eacute;n fuera del control razonable de GR&Aacute;BALO.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>La responsabilidad de las Partes estar&aacute; limitada a los da&ntilde;os directos (con exclusi&oacute;n, por tanto, del lucro cesante, de la p&eacute;rdida de negocio o del da&ntilde;o reputacional) efectivamente causados a la otra Parte. No obstante, nada de lo dispuesto en el presente Acuerdo excluir&aacute; o limitar&aacute; la responsabilidad que no pueda ser excluida o limitada en virtud de la legislaci&oacute;n aplicable.</span>
                        </p>
                        <p dir="ltr">
                            <span>GR&Aacute;BALO perseguir&aacute; el incumplimiento del presente Acuerdo, as&iacute; como cualquier utilizaci&oacute;n indebida del Sitio web, reserv&aacute;ndose el derecho a ejercitar todas las acciones civiles y penales que le puedan corresponder.</span>
                        </p>
                        <p dir="ltr"><span>​GR&Aacute;BALO no es propietario, titular, ni respalda ning&uacute;n Contenido ni ninguna opini&oacute;n, recomendaci&oacute;n o consejo expresado en el mismo, y, por ende, renuncia expresamente a toda responsabilidad en relaci&oacute;n con cualquier Contenido subido, enviado y&nbsp;publicado en el Sitio Web.&nbsp;GR&Aacute;BALO no permite actividades que infrinjan los derechos de propiedad intelectual u otros derechos de terceros en su Sitio Web, aunque no puede controlar todo el Contenido subido ni cualquier intercambio de informaci&oacute;n realizado en el Sitio Web.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO no garantiza que la disponibilidad de los Servicios sea continua e ininterrumpida, por circunstancias originadas por problemas en la red de Internet, aver&iacute;as en los dispositivos inform&aacute;ticos y otras circunstancias imprevisibles. De manera que el Licenciatario acepta soportar dentro de unos l&iacute;mites razonables estas circunstancias, por lo que renuncia expresamente a reclamar a GR&Aacute;BALO cualquier responsabilidad contractual o extracontractual por los posibles fallos, errores y uso del servicio contratado.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>El Sitio Web y su contenido se proporcionan &ldquo;tal cual&rdquo; y &ldquo;seg&uacute;n disponibilidad&rdquo;. GR&Aacute;BALO no formula ninguna declaraci&oacute;n ni garant&iacute;a sobre la idoneidad, fiabilidad, disponibilidad, puntualidad, seguridad, ausencia de errores o precisi&oacute;n de los Servicios o su contenido. Asimismo, en la medida permitida por la ley, no se ofrecen cualesquiera garant&iacute;as (expresas o impl&iacute;citas), incluidas las garant&iacute;as de comerciabilidad, idoneidad para un prop&oacute;sito en particular, t&iacute;tulo o no infracci&oacute;n.&nbsp;</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Fuerza mayor</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Las Partes no ser&aacute;n responsables por ning&uacute;n incumplimiento o retraso en el cumplimiento de alguna de las obligaciones del presente Acuerdo, cuya causa se deba a acontecimientos que est&eacute;n fuera de su control razonable ("Causa de Fuerza Mayor&rdquo;). Se entender&aacute; que las obligaciones derivadas del Acuerdo quedar&aacute;n suspendidas durante el per&iacute;odo en que la Causa de Fuerza Mayor contin&uacute;e, y se dispondr&aacute; de una ampliaci&oacute;n en el plazo para cumplir dichas obligaciones por un periodo de tiempo igual al que dure la Causa de Fuerza Mayor. Las Partes pondr&aacute;n todos los medios razonables para que finalice la Causa de Fuerza Mayor o para encontrar una soluci&oacute;n que permita cumplir las obligaciones en virtud del Acuerdo a pesar de la existencia de la Causa de Fuerza Mayor.</span>
                        </p>
                        <ol start="12">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>NOTIFICACIONES</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Toda comunicaci&oacute;n entre las Partes se llevar&aacute; a cabo mediante correo electr&oacute;nico a las personas y direcciones de contacto indicadas por las Partes o por cualquier medio del cual se tenga constancia de su recepci&oacute;n a las personas y domicilios indicados.</span>
                        </p>
                        <ol start="13">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>MODIFICACIONES&nbsp;</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>GR&Aacute;BALO se reserva el derecho de modificar, en cualquier momento y sin previo aviso, las presentes Condiciones Generales. Dichas modificaciones entrar&aacute;n en vigor en la fecha de su publicaci&oacute;n en el Sitio Web o, en su caso, en la fecha que le sea indicada. Si no desea seguir utilizando el presente Sitio Web y los Servicios conforme a la nueva versi&oacute;n del Acuerdo, puede resolverlo poni&eacute;ndose en contacto con GR&Aacute;BALO.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>GR&Aacute;BALO, notificar&aacute; al Licenciatario de cualquier cambio, aunque el Licenciatario acepta la obligaci&oacute;n de revisar las condiciones peri&oacute;dicamente para estar al corriente de dichas modificaciones. En todo caso, el uso continuado del Sitio Web y adquisici&oacute;n de Contenido supondr&aacute; la aceptaci&oacute;n de las modificaciones del Acuerdo.</span>
                        </p>
                        <ol start="14">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>MISCELÁNEA&nbsp;</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Si cualquier cl&aacute;usula de las presentes Condiciones fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia tan s&oacute;lo afectará a dicha disposici&oacute;n o a la parte de esta que resulte nula o ineficaz, subsistiendo el Acuerdo en todo lo dem&aacute;s, teni&eacute;ndose tal disposici&oacute;n, o la parte de la misma que resultase afectada, por no puesta.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>El Licenciatario reconoce haber le&iacute;do, entendido y estar conforme con las presentes Condiciones, por lo que afirma que son suficientes para la exclusi&oacute;n del error en el consentimiento del presente Acuerdo y, por lo tanto, las acepta integra y expresamente.</span>
                        </p>
                        <ol start="15">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>LEY APLICABLE Y JURISDICCIÓN</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>El presente Acuerdo y su ejecuci&oacute;n queda sometido a la legislaci&oacute;n espa&ntilde;ola.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Si surgiese cualquier controversia sobre la interpretaci&oacute;n o aplicaci&oacute;n de las presentes condiciones contractuales, las partes negociar&aacute;n de buena fe para intentar resolver tal discrepancia o reclamaci&oacute;n. No obstante, para el supuesto de que la discrepancia o la reclamaci&oacute;n no se resolviera, las Partes, con renuncia expresa a cualquiera otra jurisdicci&oacute;n que pueda corresponderles y sin perjuicio de las normas imperativas sobre competencia judicial, someten la decisi&oacute;n del asunto planteado a los Juzgados y Tribunales de la ciudad de Castell&oacute;n.</span>
                        </p>
                    </div>
                    {/*==========================================*/}
                    <div id={'cookies'}>
                        <p dir="ltr"><h5>POL&Iacute;TICA DE COOKIES</h5></p>
                        <p dir="ltr"><span>Al igual que otros sitios web, https://www.grabaloapp.com (el &ldquo;Sitio Web&rdquo;) utiliza una tecnolog&iacute;a denominada cookies para recabar informaci&oacute;n acerca de su uso por parte de los usuarios. El responsable del tratamiento de esa informaci&oacute;n es GRABA TU TALENTO, S.L.U., con direcci&oacute;n en Avda. Castell&oacute;n n&ordm; 2, 1&ordm; Nules (Castell&oacute;n).</span>
                        </p>
                        <p dir="ltr"><span>Te informamos de que podemos utilizarlas en tu equipo a condici&oacute;n de que hayas dado tu consentimiento, salvo en los supuestos en los que sean necesarias para la navegaci&oacute;n por el Sitio Web.</span>
                        </p>
                        <p dir="ltr"><span>A tal efecto, te indicamos que la utilizaci&oacute;n de las diferentes cookies por nuestro Sitio Web nos permite estudiar tus h&aacute;bitos de navegaci&oacute;n y mostrarte contenido que puede ser de tu inter&eacute;s, recoger estad&iacute;sticas de uso para identificar potenciales mejoras del Sitio Web y errores del mismo, facilitar la navegaci&oacute;n a trav&eacute;s del Sitio Web y proporcionarte una mejor experiencia de usuario. En definitiva, las cookies nos permiten tener m&aacute;s informaci&oacute;n acerca de tus preferencias y personalizar nuestro Sitio Web de conformidad con tus intereses y los de los dem&aacute;s usuarios.</span>
                        </p>
                        <p dir="ltr"><span>&iquest;Qu&eacute; es una cookie?</span></p>
                        <p dir="ltr"><span>Una cookie es un archivo que se descarga en tu equipo terminal con la finalidad de almacenar datos que podr&aacute;n ser actualizados y recuperados por la entidad responsable de su instalaci&oacute;n. Salvo que hayas configurado tu navegador para deshabilitarlas, nuestro sistema las usar&aacute; en tanto en cuanto visites nuestro Sitio Web y hayas aceptado su instalaci&oacute;n.</span>
                        </p>
                        <p dir="ltr"><span>&iquest;Qui&eacute;n es el responsable?</span></p>
                        <p dir="ltr"><span>El responsable del tratamiento de esa informaci&oacute;n es GRABA TU TALENTO, S.L.U. (GRABALO), con direcci&oacute;n en Avda. Castell&oacute;n n&ordm; 2, 1&ordm; Nules (Castell&oacute;n).</span>
                        </p>
                        <p dir="ltr"><span>&iquest;Qu&eacute; tipo de cookies utilizamos en nuestro Sitio Web?</span>
                        </p>
                        <p dir="ltr"><span>En el Sitio Web utilizamos distintas categor&iacute;as de cookies, que pueden clasificarse seg&uacute;n su finalidad en las siguientes:</span>
                        </p>
                        <p dir="ltr">&nbsp;</p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>T&eacute;cnicas</span></p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>Estas cookies son las estrictamente necesarias para navegar por el Sitio Web y permiten funciones b&aacute;sicas como la navegaci&oacute;n de una secci&oacute;n a otra. Se encargan de aspectos t&eacute;cnicos como identificar perfiles o sesiones y gestionar preferencias de herramientas en el uso, interacci&oacute;n y localizaci&oacute;n del acceso a los contenidos.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Estas cookies no se pueden desactivar, ya que, de lo contrario, se ver&iacute;a comprometido el funcionamiento correcto del Sitio Web. Por lo general, solo se configuran en respuesta a acciones realizadas al solicitarnos servicios, como establecer tus preferencias en relaci&oacute;n con las propias cookies, iniciar sesi&oacute;n o completar formularios. En caso de bloquear su uso, algunas &aacute;reas o funcionalidades no funcionar&aacute;n. Estas cookies no almacenan ninguna informaci&oacute;n de identificaci&oacute;n personal.</span>
                        </p>
                        <p dir="ltr">
                            <span>Tambi&eacute;n pertenecen a esta categor&iacute;a, por su naturaleza t&eacute;cnica, aquellas cookies que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios incluido en el Sitio Web, sin que se recopile informaci&oacute;n de los usuarios con fines distintos, como puede ser personalizar ese contenido publicitario u otros contenidos.</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Estad&iacute;sticas</span></p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>Estas cookies recogen informaci&oacute;n relativa al uso del Sitio Web, como el tiempo de permanencia en el mismo, el n&uacute;mero de visitas o los contenidos visitados.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Dicha informaci&oacute;n nos permite comprender c&oacute;mo interact&uacute;an los usuarios con el Sitio Web, evaluar el rendimiento del sitio, qu&eacute; p&aacute;ginas o apartados del Sitio Web son los m&aacute;s o menos visitados, incluida la cuantificaci&oacute;n de los impactos de los anuncios introducidos en el Sitio Web. Esto nos permite poder solucionar errores, mejorar el dise&ntilde;o del Sitio Web o el contenido que ofrecemos. Si no permites usar esta cookie no sabremos cu&aacute;ndo visitaste el sitio y no podremos evaluar si funcion&oacute; correctamente.</span>
                        </p>
                        <p dir="ltr"><span>Google Analytics y Facebook Pixel:</span></p>
                        <p dir="ltr"><span>Concretamente, para analizar el uso de la p&aacute;gina web utilizamos Google Analytics, un servicio web de Google, Inc. ("Google") y Facebook Pixel, un servicio web de Facebook, Inc. ("Facebook").</span>
                        </p>
                        <p dir="ltr"><span>Google/Facebook utilizar&aacute; la informaci&oacute;n anal&iacute;tica para los fines mencionados anteriormente, as&iacute; como para la elaboraci&oacute;n de informes sobre la actividad del sitio web.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Usted puede rechazar el uso de cookies modificando la configuraci&oacute;n de su navegador, sin embargo, tenga en cuenta que, si lo hace, es posible que no pueda utilizar toda la funcionalidad de este sitio web.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Para obtener m&aacute;s informaci&oacute;n sobre Google Analytics, visite:</span></p>
                        <p dir="ltr"><a
                            href="https://www.google.com/privacypolicy.html"><span>https://www.google.com/privacypolicy.html</span></a><span>.</span><span>&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Para obtener m&aacute;s informaci&oacute;n sobre Facebook Pixel, visite:</span></p>
                        <p dir="ltr"><a
                            href="https://www.facebook.com/policies/cookies/"><span>https://www.facebook.com/policies/cookies/</span></a><span>.</span><span>&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Las cookies estad&iacute;sticas utilizadas por el Sitio Web son las siguientes:</span>
                        </p>
                        <div dir="ltr" align="left">
                            <table border="1">
                                <colgroup>
                                    <col/>
                                    <col/>
                                    <col/>
                                    <col/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <td>
                                        <p dir="ltr"><span>Nombre</span></p>
                                    </td>
                                    <td>
                                        <p dir="ltr"><span>Finalidad</span></p>
                                    </td>
                                    <td>
                                        <p dir="ltr"><span>Proveedor</span></p>
                                    </td>
                                    <td>
                                        <p dir="ltr"><span>Caducidad</span></p>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Google Analytics I&nbsp;</td>
                                    <td>Generar un identificador de usuario &uacute;nico, que es el que se utiliza para
                                        hacer recuento de cu&aacute;ntas veces visita el sitio un usuario,
                                        as&iacute; como la fecha de la primera y la &uacute;ltima vez que
                                        visit&oacute; la web.
                                    </td>
                                    <td>Google Inc. (Alphabet Inc.)</td>
                                    <td>No caduca</td>
                                </tr>
                                <tr>
                                    <td>Google Analytics II</td>
                                    <td>Registrar la fecha y hora de acceso a malquiera de las p&aacute;ginas del
                                        Sitio.
                                    </td>
                                    <td>Google Inc. (Alphabet Inc.)</td>
                                    <td>Temporal (duraci&oacute;n arbitraria proporcionada por el proveedor)</td>
                                </tr>
                                <tr>
                                    <td>Google Analytics III</td>
                                    <td>Comprobar la necesidad de mantener la sesi&oacute;n de un usuario abierta o
                                        crear una nueva
                                    </td>
                                    <td>Google Inc. (Alphabet Inc.)</td>
                                    <td>Temporal (duraci&oacute;n arbitraria proporcionada por el proveedor)</td>
                                </tr>
                                <tr>
                                    <td>Google Analytics IV</td>
                                    <td>Identificar la sesi&oacute;n del usuario, para recoger la ubicaci&oacute;n
                                        geogr&aacute;fica aproximada del ordenador que accede al Sitio con efectos
                                        estad&iacute;sticos.
                                    </td>
                                    <td>Google Inc. (Alphabet Inc.)</td>
                                    <td>Temporal (duraci&oacute;n arbitraria proporcionada por el proveedor)</td>
                                </tr>
                                <tr>
                                    <td>Google Analytics V</td>
                                    <td>Es una cookie opcional, solo se usa cuando a partir de los datos obtenidos de
                                        registro se quieren segmentar posteriormente datos demogr&aacute;ficos como el
                                        sexo o la edad de los visitantes. La informaci&oacute;n se obtiene a
                                        trav&eacute;s de la setVar () del c&oacute;digo de Google Analytics. Caduca a
                                        los 2 a&ntilde;os de la &uacute;ltima actualizaci&oacute;n
                                    </td>
                                    <td>Google Inc. (Alphabet Inc.)</td>
                                    <td>Temporal (duraci&oacute;n arbitraria proporcionada por el proveedor)</td>
                                </tr>
                                <tr>
                                    <td>Facebook Pixel</td>
                                    <td>Facebook pixel es una herramienta de análisis que nos ayuda a medir la eficacia
                                        de nuestra publicidad, elaborando informes de las acciones que los USUARIOS
                                        realizan cuando visitan LA WEB. La información recopilada se comparte con
                                        Facebook. Para más información sobre el uso que hace Facebook de esta
                                        información puede consultar aquí: https://www.facebook.com/policies/cookies/
                                    </td>
                                    <td>Facebook Inc.</td>
                                    <td>Sin caducidad</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Cookies de preferencias o de personalizaci&oacute;n:</span></p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>Las cookies funcionales permiten que el Sitio Web recuerde informaci&oacute;n del usuario para que este acceda al servicio con determinadas caracter&iacute;sticas que pueden diferenciar su experiencia de la de otros usuarios. Por ejemplo, su idioma preferido o la regi&oacute;n desde la que accede, el n&uacute;mero de resultados a mostrar cuando el usuario realiza una b&uacute;squeda, el aspecto o contenido del servicio en funci&oacute;n del tipo de dispositivo desde el cual el usuario accede al servicio.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Las cookies funcionales utilizadas por el Sitio Web son las siguientes:</span></p>
                        <div dir="ltr" align="left">
                            <table border="1">
                                <colgroup>
                                    <col/>
                                    <col/>
                                    <col/>
                                    <col/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <td>
                                        <p dir="ltr"><span>Nombre</span></p>
                                    </td>
                                    <td>
                                        <p dir="ltr"><span>Finalidad</span></p>
                                    </td>
                                    <td>
                                        <p dir="ltr"><span>Proveedor</span></p>
                                    </td>
                                    <td>
                                        <p dir="ltr"><span>Caducidad</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p dir="ltr">
                                            <span>loggedOnGrabalo</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p dir="ltr">
                                            <span>Control interno de la sesión del usuario en la plataforma</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p dir="ltr">
                                            <span>Propia</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p dir="ltr"><span>No caduca</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p dir="ltr">&nbsp;</p>
                        <p dir="ltr"><span>&iquest;C&oacute;mo se pueden deshabilitar las cookies?</span></p>
                        <p dir="ltr"><span>Cuando accedes por primera vez a nuestro Sitio Web, se te muestra una ventana en la que puedes indicar si aceptas las cookies o si deseas configurarlas o rechazarlas, salvo aquellas estrictamente necesarias. Asimismo, puedes retirar el consentimiento otorgado previamente en cualquier momento.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Si quisieses retirar en cualquier momento tu consentimiento relacionado con la Pol&iacute;tica de Cookies, tienes dos opciones: </span><span>(i) retirar tu consentimiento a trav&eacute;s de los botones habilitados al efecto en esta Pol&iacute;tica de cookies; o (ii) eliminar las cookies almacenadas en tu equipo a trav&eacute;s de los ajustes y configuraciones de tu navegador de Internet.</span>
                        </p>
                        <p dir="ltr"><span>Si aceptas cookies de terceros y posteriormente deseas eliminarlas puedes desactivarlas a trav&eacute;s de los diferentes navegadores o a trav&eacute;s del sistema habilitado por estos terceros.</span>
                        </p>
                        <p dir="ltr"><span>Igualmente, la pr&aacute;ctica mayor&iacute;a de los navegadores permiten advertir de la presencia de cookies o rechazarlas autom&aacute;ticamente. Si las rechazas podr&aacute;s seguir usando nuestro Sitio Web pero posiblemente experimentes dificultades en cuanto a la navegabilidad y operatividad del Sitio Web y no puedas acceder a determinadas funcionalidades. As&iacute;, la desactivaci&oacute;n puede provocar que el uso de algunos de los servicios del Sitio Web sea limitado y por tanto tu experiencia sea menos satisfactoria.</span>
                        </p>
                        <p dir="ltr"><span>Puedes ampliar la informaci&oacute;n sobre c&oacute;mo deshabilitar las cookies, dependiendo de tu navegador, en los siguientes enlaces:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Internet Explorer: </span><a
                                    href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"><span>https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</span></a><span>&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">&nbsp;</p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Firefox: </span><a
                                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"><span>https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</span></a><span>&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">&nbsp;</p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Chrome: </span><a
                                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=es"><span>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=es</span></a><span>&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">&nbsp;</p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Safari: </span><a
                                    href="https://support.apple.com/kb/ph21411?locale=es_ES"><span>https://support.apple.com/kb/ph21411?locale=es_ES</span><span><span><img
                                    src="https://lh4.googleusercontent.com/HAjqP-cXvbq7ktslqXc-VaHkF6IvTcJJW4w-fhJGmNx6TBbdbAjfLlFAFSjSGDRwevqw26dIh3i-BgYcQB-K--YwMrD1TPojeBQCI6Gl0ry6Uz83LuiA6_GxfPOdk66sMxVy-Mc"
                                    alt="Abre ventana nueva" width="12" height="12"/></span></span></a><span>&nbsp;; para iOS: &nbsp;</span><a
                                    href="https://support.apple.com/es-es/HT201265"><span>https://support.apple.com/es-es/HT201265</span></a><span>&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>Tambi&eacute;n puedes configurar tus consentimientos sobre el uso de cookies para publicidad comportamental accediendo a:</span>
                        </p>
                        <p dir="ltr"><span>&nbsp;</span><a
                            href="http://www.youronlinechoices.com/es/"><span>http://www.youronlinechoices.com/es/</span></a>
                        </p>
                        <p dir="ltr"><span>Modificaciones en esta pol&iacute;tica</span></p>
                        <p dir="ltr"><span>Es posible que actualicemos la Pol&iacute;tica de Cookies de nuestro Sitio Web peri&oacute;dicamente. Por ello, te recomendamos revisar esta pol&iacute;tica cada vez que accedas a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre c&oacute;mo y para qu&eacute; utilizamos las Cookies.</span>
                        </p>
                        <p dir="ltr"><span>Contacto</span></p>
                        <p dir="ltr"><span>Si tuvieras cualquier duda relacionada con el uso de cookies por nuestra parte, por favor, escribe un email a </span><a
                            href="mailto:info@grabaloapp.com"><span>info@grabaloapp.com</span></a><span> y te responderemos tan pronto como sea posible.</span><span><br/></span>
                        </p>
                    </div>


                    <div id={'privacy'}>

                        <h2 dir="ltr"><span>POL&Iacute;TICA DE PRIVACIDAD</span></h2>
                        <p dir="ltr"><span>En esta pol&iacute;tica encontrar&aacute;s toda la informaci&oacute;n relativa al tratamiento de tus datos personales y a los derechos que puedes ejercer para mantener el control sobre los mismos. Por ello, debes leerla con detenimiento antes de utilizar el presente Sitio Web. No dudes en consultarnos cualquier duda al respecto.</span>
                        </p>
                        <h2 dir="ltr"><span>PRINCIPALES CUESTIONES DEL TRATAMIENTO DE TUS DATOS PERSONALES</span></h2>
                        <p dir="ltr">
                            <span>Desde &laquo;GRABA TU TALENTO, S.L.U.&nbsp;&raquo; (en adelante &ldquo;</span><span>&laquo;GR&Aacute;BALO&raquo;</span><span>&rdquo;) necesitamos contar con los datos personales que te solicitamos durante tu navegaci&oacute;n y uso de la p&aacute;gina web: &laquo;Grabaloapp.com&raquo; (en adelante &ldquo;</span><span>el Sitio Web</span><span>&rdquo;), con el objetivo de:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Ofrecerte diferentes canales donde ponerte en contacto con nosotros, para solicitarnos informaci&oacute;n, resolver dudas, etc.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Administrar tu cuenta y el contenido que cargas o licencias desde el Sitio Web y dar acceso a tu cuenta a terceros para dar cumplimiento al contrato celebrado contigo.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Ofrecerte, en su caso, productos y servicios que puedan ser de tu inter&eacute;s.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Tambi&eacute;n puede que utilicemos tus datos para ayudar a mejorar el desarrollo, mantenimiento y usabilidad del Sitio Web.&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr">
                            <span>En todo caso, te garantizamos custodiar dicha informaci&oacute;n con las m&aacute;ximas garant&iacute;as de seguridad y de conformidad con la normativa vigente en materia de protecci&oacute;n de datos personales.</span>
                        </p>
                        <p dir="ltr"><span>En cualquier momento puedes ejercer los derechos que prev&eacute; la normativa de protecci&oacute;n de datos o plantearnos cualquier duda que tengas acerca del tratamiento de tus datos contactando con nosotros a trav&eacute;s del correo electr&oacute;nico &laquo;info@grabaloapp.com&raquo;.</span>
                        </p>
                        <h2 dir="ltr"><span>TEXTO &Iacute;NTEGRO DE LA POL&Iacute;TICA DE PRIVACIDAD&nbsp;</span></h2>
                        <ol>
                            <li dir="ltr">
                                <h2 dir="ltr"><span>&iquest;QUI&Eacute;N ES EL RESPONSABLE DEL TRATAMIENTO DE TUS DATOS PERSONALES?</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Tus datos personales ser&aacute;n tratados por la empresa &laquo;GRABA TU TALENTO, S.L.U.&nbsp;&raquo; (&ldquo;&laquo;</span><span>GR&Aacute;BALO</span><span>&raquo;&rdquo;), con NIF &laquo;B44528362&raquo; y cuyos datos de contacto son los siguientes:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Direcci&oacute;n: Avenida Castellón, 2, 1o - CP 12520, -Nules, Castellón.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Tel&eacute;fono de contacto: +34 622 393 895</span></p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Email de contacto: info@grabaloapp.com</span></p>
                            </li>
                        </ul>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="2">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>&iquest;QU&Eacute; REQUISITOS DEBES CUMPLIR PARA SUMINISTRARNOS TUS DATOS PERSONALES?</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>2.1. Edad m&iacute;nima</span><span>. Para suministrarnos tus datos personales, deber&aacute;s tener una edad m&iacute;nima de 18 a&ntilde;os, y/o en su caso, disponer de capacidad jur&iacute;dica suficiente para utilizar el presente Sitio Web.</span>
                        </p>
                        <p dir="ltr"><span>2.2. Veracidad</span><span>. Cuando nos proporcionas tus datos para utilizar nuestros servicios, garantizas que los datos e informaci&oacute;n facilitada es real, veraz, actualizada y adem&aacute;s te pertenece a ti y no a terceras personas.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Adem&aacute;s, deber&aacute;s notificarnos cualquier modificaci&oacute;n que se produzca en los datos facilitados, respondiendo en cualquier caso de la veracidad y exactitud de los datos suministrados en cada momento.&nbsp;</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>&iquest;QU&Eacute; TRATAMIENTOS DE DATOS REALIZAMOS A TRAV&Eacute;S DEL SITIO WEB Y CU&Aacute;LES SON SUS PRINCIPALES CARACTER&Iacute;STICAS?</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>A continuaci&oacute;n, te explicamos c&oacute;mo tratamos tu informaci&oacute;n personal y te proporcionamos, en detalle, toda la informaci&oacute;n relevante relativa a tu privacidad:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <h3 dir="ltr"><span>Navegaci&oacute;n y utilizaci&oacute;n de nuestro Sitio Web</span>
                                </h3>
                            </li>
                        </ol>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Qu&eacute; datos recogemos?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Informaci&oacute;n del uso del Sitio Web</span><span>: Cuando accedes al Sitio Web, aunque no te hayas registrado, se recopila informaci&oacute;n mediante cookies u otras herramientas de rastreo. Dichas herramientas son peque&ntilde;os ficheros que se descargan en tu dispositivo y recopilan datos sobre el uso que realizas del Sitio Web. Mediante dichas herramientas, podemos recopilar:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Informaci&oacute;n del dispositivo:&nbsp;Durante la utilizaci&oacute;n del Sitio Web, recopilamos informaci&oacute;n sobre el dispositivo m&oacute;vil desde el que accedes al Sitio Web. La informaci&oacute;n que obtenemos es el modelo del dispositivo, el sistema operativo y la versi&oacute;n, el identificador &uacute;nico del dispositivo y la red.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Informaci&oacute;n sobre tu ubicaci&oacute;n:&nbsp;Tambi&eacute;n obtenemos la direcci&oacute;n IP, zona horaria y cu&aacute;l es el proveedor de servicio m&oacute;vil, en su caso, lo que nos permite obtener una ubicaci&oacute;n general.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Informaci&oacute;n de la navegaci&oacute;n del/ de la usuari@:&nbsp;Recopilamos informaci&oacute;n sobre el uso que haces del Sitio Web. En concreto, la frecuencia de uso, los apartados que visitas, uso de funciones espec&iacute;ficas,&nbsp;etc.&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;les son las finalidades del tratamiento de tus datos personales?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Realizar an&aacute;lisis estad&iacute;sticos y de mejora del Sitio Web</span><span>: Toda la informaci&oacute;n recopilada a trav&eacute;s de las cookies y dem&aacute;s herramientas de rastreo nos ayudan a analizar, mantener y mejorar el Sitio Web, ya que a partir del an&aacute;lisis de la navegaci&oacute;n que hacen l@s usuari@s, podemos detectar errores del Sitio Web, aspectos a mejorar en el dise&ntilde;o o la necesidad de a&ntilde;adir nuevas funciones y servicios.</span>
                        </p>
                        <p dir="ltr"><span>Para m&aacute;s informaci&oacute;n, puedes visitar nuestra </span><a
                            href="https://misitio.grabaloapp.com/terms/#cookies"><span>Pol&iacute;tica de Cookies</span></a><span>.</span>
                        </p>
                        <p dir="ltr">
                            <span>Prevenci&oacute;n, detecci&oacute;n y enjuiciamiento de actividades il&iacute;citas o contrarias a las condiciones del servicio o que pongan en peligro la seguridad de la informaci&oacute;n o el Sitio Web</span><span>. &laquo;GR&Aacute;BALO&raquo; podr&aacute; procesar los datos para controlar y evitar cualquier forma de abuso de nuestros servicios, como por ejemplo actividades fraudulentas, ataques de denegaci&oacute;n de servicios, env&iacute;o de spam, accesos no autorizados, as&iacute; como cualquier otra pr&aacute;ctica que sea contraria a las Condiciones Generales y de Uso del Sitio Web o ponga en peligro la seguridad de la informaci&oacute;n o la propia integridad del Sitio Web.&nbsp;</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;l es la base de legitimaci&oacute;n que nos permite tratar tus datos? &iquest;Es obligatorio el suministro de estos datos?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>La informaci&oacute;n que recogemos a trav&eacute;s de las cookies y dem&aacute;s dispositivos de rastreo se basa en tu consentimiento, el cual te solicitamos cuando accedes al Sitio&nbsp; Web. Toda esta informaci&oacute;n la podremos utilizar, en base a nuestro inter&eacute;s leg&iacute;timo, para analizar y evaluar el uso que se realiza de nuestro Sitio Web y poder aplicar las mejoras necesarias en la misma.&nbsp;</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>&iquest;Cu&aacute;nto tiempo conservamos tu informaci&oacute;n?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>Los plazos de conservaci&oacute;n de la informaci&oacute;n recopilada a trav&eacute;s de las cookies dependen de la cookie espec&iacute;fica. Todos los plazos de conservaci&oacute;n de la informaci&oacute;n se especifican, para cada cookie, en nuestra </span><a
                            href="https://misitio.grabaloapp.com/terms/#cookies"><span>Pol&iacute;tica de Cookies.</span></a><span>&nbsp;</span>
                        </p>
                        <ol start="5">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Las cesiones de datos personales que se realizan mediante las cookies de terceros (cookies recabadas y gestionadas por un tercero) para la presente finalidad est&aacute;n especificadas en la </span><a
                            href="https://misitio.grabaloapp.com/terms/#cookies"><span>Pol&iacute;tica de Cookies.</span></a><span> Adem&aacute;s, podemos recurrir a los servicios de proveedores de servicios, quienes tendr&aacute;n un acceso limitado a los datos y estar&aacute;n ligados a un deber de confidencialidad (para m&aacute;s informaci&oacute;n sobre c&oacute;mo act&uacute;an nuestros proveedores de servicios, ver el punto </span><span>5. &iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span><span> &gt; </span><span>Proveedores de Servicios</span><span>).</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <h3 dir="ltr"><span>Administraci&oacute;n de tu cuenta y contenido, acceso de terceros a los videos y pago de los servicios.</span>
                                </h3>
                            </li>
                        </ol>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Qu&eacute; datos recogemos?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Datos facilitados por ti </span><span>al darte de alta en la plataforma del Sitio Web para configurar tu cuenta y datos que te solicitaremos para poder cumplir con la relaci&oacute;n contractual:&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>- Para poder ejecutar el contrato que nos une contigo utilizamos los datos de contacto que incluyes en el formulario (nombre o seud&oacute;nimo, direcci&oacute;n de correo electr&oacute;nico y contrase&ntilde;a). Adem&aacute;s, existe la opci&oacute;n de agregar tu n&uacute;mero de tel&eacute;fono una vez que hayas configurado tu cuenta.</span>
                        </p>
                        <p dir="ltr">
                            <span>- Datos de perfil&nbsp;que incluyen nombre o seud&oacute;nimo, direcci&oacute;n de correo, n&uacute;mero de tel&eacute;fono si lo has a&ntilde;adido e importe del monedero virtual.</span>
                        </p>
                        <p dir="ltr"><span>- Datos financieros y DNI: una vez alcances el l&iacute;mite indicado para el monedero virtual, te solicitaremos la informaci&oacute;n necesaria para realizar la facturaci&oacute;n y pago, que incluir&aacute;, DNI o NIE, detalle de la cuenta bancaria o de la tarjeta bancaria, para poder lleva a cabo la contraprestaci&oacute;n econ&oacute;mica derivada de la relaci&oacute;n contractual.</span>
                        </p>
                        <p dir="ltr"><span>- Videos, im&aacute;genes y voz, as&iacute; como la informaci&oacute;n personal a la que se haga referencia en ellos, propias o de terceros, que cuelgas en tu cuenta objeto del contrato suscrito con nosotros.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Registro medio redes sociales o plataformas externas</span><span>. Tambi&eacute;n te damos la opci&oacute;n de registrarte o iniciar sesi&oacute;n a trav&eacute;s una red social o plataforma externa como Facebook o Google. En ese caso, tus credenciales de inicio de sesi&oacute;n en dichos servicios, as&iacute; como tu email, se importar&aacute;n a GRABALO. Al elegir esta opci&oacute;n, la red social o Google podr&aacute; enviarnos cierta informaci&oacute;n adicional incluida en tu perfil p&uacute;blico (nombre, sexo, edad o la foto de perfil), de acuerdo con las condiciones de uso de la red social correspondiente, que te recomendamos que leas con detenimiento. As&iacute; mismo, la utilizaci&oacute;n de esta funcionalidad puede implicar que facilites a estas plataformas determinada informaci&oacute;n sobre tu actividad en GRABALO.</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;les son las finalidades del tratamiento de tus datos personales?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>- </span><span>Administrar tu cuenta y el contenido que cargas o licencias desde el Sitio Web</span><span>: de forma que podamos interactuar contigo.</span>
                        </p>
                        <p dir="ltr"><span>- </span><span>Gestionar y ejecutar la relaci&oacute;n contractual que nos une: </span><span>realizamos distintas gestiones necesarias para ejecutar el contrato suscrito contigo, de forma que por ejemplo podamos contactar contigo par ayudarte a editar mejor una historia, para verificar la informaci&oacute;n y contenido que nos has proporcionado, para temas de facturaci&oacute;n y realizaci&oacute;n de pagos y cobros.</span>
                        </p>
                        <p dir="ltr"><span>- </span><span>Visualizaci&oacute;n y descarga de tus videos por parte de terceros</span><span>: en virtud de la relaci&oacute;n contractual que tienes con nosotros, los terceros que pagan la tarifa especificada acceden a tus videos e im&aacute;genes.&nbsp;</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;l es la base de legitimaci&oacute;n que nos permite tratar tus datos? &iquest;Es obligatorio el suministro de estos datos?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Ejecuci&oacute;n contractual.</span><span> Los datos suministrados para las finalidades anteriores se tratar&aacute;n en base a la ejecuci&oacute;n del contrato que tienes suscrito con nosotros, en virtud del cual t&uacute; descargas videos e im&aacute;genes en la cuenta que tienes en nuestro Sitio Web, y terceros que han sublicenciado tus derechos, acceden y descargan los citados videos e im&aacute;genes.&nbsp;</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>&iquest;Cu&aacute;nto tiempo conservamos tu informaci&oacute;n?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Toda tu informaci&oacute;n personal la guardaremos durante el tiempo en el que se mantenga vigente nuestra relaci&oacute;n contractual. Una vez finalizada esta, &laquo;GR&Aacute;BALO&raquo; conservar&aacute;, bloqueada, dicha informaci&oacute;n durante los plazos previstos en la legislaci&oacute;n para atender eventuales responsabilidades y para demostrar el cumplimiento de nuestras obligaciones.</span>
                        </p>
                        <ol start="5">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>A los efectos de poder ejecutar el contrato suscrito contigo, damos acceso a los videos e im&aacute;genes que has descargado en tu cuenta, a los terceros que han obtenido una subliciencia de los mismos.&nbsp;&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>As&iacute; mismo, tambi&eacute;n recurrimos para almacenar los v&iacute;deos e im&aacute;genes a proveedores de servicios de hosting (Vimeo y Amazon Web Services), quienes tendr&aacute;n un acceso limitado a los datos y estar&aacute;n ligados a un deber de confidencialidad (para m&aacute;s informaci&oacute;n sobre c&oacute;mo act&uacute;an nuestros proveedores de servicios, ver el punto</span><span> 5. &iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span><span> &gt; </span><span>Proveedores de Servicios</span><span>).</span>
                        </p>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="3">
                            <li dir="ltr">
                                <h3 dir="ltr"><span>Contacto con nosotros a trav&eacute;s de nuestros canales (formulario de contacto, correo electr&oacute;nico, etc.)</span>
                                </h3>
                            </li>
                        </ol>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Qu&eacute; datos recogemos?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Informaci&oacute;n identificativa y de contacto</span><span>. Recogemos la informaci&oacute;n que nos facilites a trav&eacute;s de los medios que ponemos a tu disposici&oacute;n en el presente Sitio Web. En este sentido, trataremos tus datos identificativos (nombre y apellidos si los incluyes) y la direcci&oacute;n de correo electr&oacute;nico, as&iacute; como cualquier otra que incluyas en las comunicaciones que nos remitas.</span>
                        </p>
                        <p dir="ltr"><span>Informaci&oacute;n curricular, acad&eacute;mica y profesional</span><span>. En el caso que nos remitas tu Curr&iacute;culum V&iacute;tae (CV) podremos tratar la informaci&oacute;n relativa a tus estudios, formaci&oacute;n, experiencia acad&eacute;mica y aquella que hayas incluido.</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;les son las finalidades del tratamiento de tus datos personales?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Contestar a tus solicitudes</span><span>. La principal finalidad del tratamiento de estos datos ser&aacute; la de contestar tus solicitudes, resolver tus dudas y/o suministrarte la informaci&oacute;n requerida, as&iacute; como, en su caso, hacer el seguimiento de las mismas.</span>
                        </p>
                        <p dir="ltr"><span>Participar en nuestros procesos de selecci&oacute;n</span><span>. En el caso de que nos env&iacute;es tu CV, trataremos tu informaci&oacute;n personal para iniciar, en su caso, un proceso de selecci&oacute;n para una eventual contrataci&oacute;n y hacer el seguimiento del mismo.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Mejorar el servicio de atenci&oacute;n al cliente</span><span>. Toda la informaci&oacute;n derivada de las dudas, consultas, y el asesoramiento ofrecido a los interesados, as&iacute; como la forma en la que se resuelven las peticiones nos permite conocer c&oacute;mo prestamos nuestro propio&nbsp;servicio de atenci&oacute;n al cliente, permiti&eacute;ndonos mejorar la calidad del mismo. Toda la informaci&oacute;n recabada, finalizado el tiempo de conservaci&oacute;n indicado en el punto d) se anonimiza, solo conserv&aacute;ndose a efectos estad&iacute;sticos, no pudi&eacute;ndose identificar a los interesados.</span>
                        </p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;l es la base de legitimaci&oacute;n que nos permite tratar tus datos? &iquest;Es obligatorio el suministro de estos datos?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Consentimiento</span><span>. Los datos suministrados para las finalidades anteriores se tratar&aacute;n en base a tu consentimiento, otorgado cuando, de manera voluntaria, te pones en contacto con nosotros a trav&eacute;s de los medios puestos a tu disposici&oacute;n para, o bien solicitarnos informaci&oacute;n o remitirnos otras comunicaciones o para remitirnos tu CV para participar en nuestros procesos de selecci&oacute;n.</span>
                        </p>
                        <p dir="ltr"><span>Inter&eacute;s leg&iacute;timo</span><span>. Toda la informaci&oacute;n recabada por el departamento de atenci&oacute;n al cliente nos servir&aacute; para mejora en la calidad del&nbsp;servicio&nbsp;de atenci&oacute;n al cliente prestado, en base a nuestro inter&eacute;s leg&iacute;timo.&nbsp;</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>&iquest;Cu&aacute;nto tiempo conservamos tu informaci&oacute;n?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Toda tu informaci&oacute;n personal la trataremos durante el tiempo en el que se est&eacute;n tramitando tus solicitudes y durante 6 meses para hacer el seguimiento de las mismas. Una vez finalizado este periodo, &laquo;GR&Aacute;BALO&raquo; conservar&aacute;, bloqueada, dicha informaci&oacute;n durante los plazos previstos en la legislaci&oacute;n para atender eventuales responsabilidades y para demostrar el cumplimiento de nuestras obligaciones. Todo ello, salvo que inicies un nuevo tratamiento. A partir de este momento, &laquo;GR&Aacute;BALO&raquo; solo tratar&aacute; la informaci&oacute;n de manera anonimizada, de manera que no ser&aacute; posible vincular la informaci&oacute;n estad&iacute;stica con l@s usuari@ concret@s a quien hace referencia.</span>
                        </p>
                        <p dir="ltr"><span>En el caso de tu CV, trataremos tu informaci&oacute;n personal durante el proceso de selecci&oacute;n, en su caso, y/o, cuando no exista un proceso de selecci&oacute;n abierto, durante 1 a&ntilde;o como m&aacute;ximo para posibles futuros procesos de selecci&oacute;n que puedan encajar con tu perfil profesional o los intereses que hayas indicado.</span>
                        </p>
                        <ol start="5">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>No realizamos ninguna cesi&oacute;n adicional para llevar a cabo este tratamiento que aquellas indicadas, con car&aacute;cter general, en el punto </span><span>5. &iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <h3 dir="ltr"><span>Inscripci&oacute;n a nuestra newsletter y dem&aacute;s informaciones comerciales&nbsp;</span>
                                </h3>
                            </li>
                        </ol>
                        <ol start="6">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Qu&eacute; datos recogemos?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Datos de contacto</span><span>. Para el env&iacute;o de informaci&oacute;n comercial por medios electr&oacute;nicos, utilizamos los datos de contacto que incluyes en el formulario correspondiente para la inscripci&oacute;n en la newsletter o aquellos datos de contacto (correo electr&oacute;nico o tel&eacute;fono m&oacute;vil) de que disponemos en virtud de nuestra relaci&oacute;n contractual, en su caso.</span>
                        </p>
                        <ol start="7">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;les son las finalidades del tratamiento de tus datos personales?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr">
                            <span>Env&iacute;o de publicaciones de inter&eacute;s y comunicaciones comerciales</span><span>: Si mantienes una relaci&oacute;n contractual con nosotros o te has suscrito a la recepci&oacute;n de informaci&oacute;n comercial, podemos enviarte, a trav&eacute;s de medios electr&oacute;nicos, comunicaciones sobre productos y servicios relacionados con el objeto del Sitio Web propios de &laquo;GR&Aacute;BALO&raquo;.</span>
                        </p>
                        <p dir="ltr"><span>Dichas comunicaciones no vulnerar&aacute;n, en ning&uacute;n caso, tus derechos a la privacidad de tus datos o a tu intimidad, y no ser&aacute;n intrusivas en t&eacute;rminos de periodicidad.</span>
                        </p>
                        <ol start="8">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;Cu&aacute;l es la base de legitimaci&oacute;n que nos permite tratar tus datos? &iquest;Es obligatorio el suministro de estos datos?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>El env&iacute;o de comunicaciones comerciales se realiza:</span></p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>En base a nuestra relaci&oacute;n contractual previa; o</span></p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>en base a tu consentimiento, solicitado en el momento de tu suscripci&oacute;n en nuestra lista de distribuci&oacute;n o marcando una casilla correspondiente en otros formularios.&nbsp;</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><span>Si no deseas recibir este tipo de comunicaciones puedes no dar tu consentimiento y/o retirarlo en cualquier momento siguiendo las instrucciones indicadas en cada e-mail comercial o envi&aacute;ndonos un correo electr&oacute;nico a &laquo;info@grabaloapp.com&raquo; indicando tu deseo de dejar de recibir dichas comunicaciones.&nbsp;&nbsp;</span>
                        </p>
                        <ol start="9">
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>&iquest;Cu&aacute;nto tiempo conservamos tu informaci&oacute;n?</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Toda tu informaci&oacute;n personal la guardaremos durante el tiempo en el que se mantenga vigente nuestra relaci&oacute;n contractual. Una vez finalizada esta, &laquo;GR&Aacute;BALO&raquo; conservar&aacute;, bloqueada, dicha informaci&oacute;n durante los plazos previstos en la legislaci&oacute;n para atender eventuales responsabilidades y para demostrar el cumplimiento de nuestras obligaciones.</span>
                        </p>
                        <p dir="ltr"><span>En el caso de que el env&iacute;o de las comunicaciones comerciales se base en tu consentimiento, la informaci&oacute;n la mantendremos durante el tiempo en el que est&eacute;s dad@ de alta en nuestra lista de distribuci&oacute;n de este tipo de informaci&oacute;n y, una vez te des de baja, se mantendr&aacute; bloqueada durante los plazos legales previstos para atender eventuales responsabilidades.</span>
                        </p>
                        <ol start="10">
                            <li dir="ltr">
                                <p dir="ltr"><span>&iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>No existen cesiones concretas de tus datos personales para cumplir con la finalidad indicada en este apartado. No obstante, podemos recurrir a los servicios de proveedores de servicios de mail, marketing u otros proveedores de servicios de publicidad y marketing, quienes tendr&aacute;n un acceso limitado a los datos y estar&aacute;n ligados a un deber de confidencialidad (para m&aacute;s informaci&oacute;n sobre c&oacute;mo act&uacute;an nuestros proveedores de servicios, ver el punto </span><span>5. &iquest;A qui&eacute;n cedemos tu informaci&oacute;n personal?</span><span> &gt; </span><span>Proveedores de Servicios</span><span>).</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <h3 dir="ltr"><span>Perfiles de &laquo;GR&Aacute;BALO&raquo; en redes sociales.</span>
                                </h3>
                            </li>
                        </ol>
                        <p dir="ltr"><span>&laquo;GR&Aacute;BALO&raquo; cuenta con perfil en las principales redes sociales, tales como</span><span>&nbsp;Facebook, Twitter, Instagram, Linkedin o YouTube</span><span>.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Cuando te haces seguidor de alguna de nuestras p&aacute;ginas en las redes sociales, el tratamiento de los datos, se regir&aacute; por las condiciones de uso, pol&iacute;ticas de privacidad y normativas de acceso que pertenezcan a la red social correspondiente y aceptadas previamente por el usuario.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>&laquo;GR&Aacute;BALO&raquo;, en este sentido, tratar&aacute; tus datos con las finalidades de administrar correctamente su presencia en la red social, inform&aacute;ndote de actividades, productos o servicios, as&iacute; como para cualquier otra finalidad que las normativas de las redes sociales permitan.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Ten en cuenta que nosotros no tenemos influencia sobre la informaci&oacute;n que la red social recoge o c&oacute;mo la procesa, por lo que te recomendamos que te mantengas informado del prop&oacute;sito y alcance de la recogida de informaci&oacute;n que se realiza a trav&eacute;s de dichas redes sociales.</span>
                        </p>
                        <ol start="5">
                            <li dir="ltr">
                                <h2 dir="ltr">
                                    <span>&iquest;A QUI&Eacute;N CEDEMOS TU INFORMACI&Oacute;N PERSONAL?</span></h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Por lo general, desde &laquo;GR&Aacute;BALO&raquo; no comunicaremos tus datos a terceros. No obstante, adem&aacute;s de las cesiones que te indicamos espec&iacute;ficamente en el apartado en que te explicamos las caracter&iacute;sticas de las diferentes operaciones (punto 3), te informamos de las comunicaciones que podemos realizar, con car&aacute;cter general, y que afectan a todos los tratamientos anteriores y su base legitimadora.</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Prestadores de servicios</span><span> esenciales para ejecutar el servicio que te ofrecemos, (por ejemplo, empresas de alojamiento inform&aacute;tico o plataformas de env&iacute;o de las comunicaciones comerciales). No obstante lo anterior, estas entidades han firmado los correspondientes acuerdos de confidencialidad y solo tratar&aacute;n tus datos seg&uacute;n nuestras instrucciones, no pudiendo utilizarlos para finalidades propias o aparte del servicio que nos prestan.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Transferencias internacionales.</span><span> Algunos de nuestros prestadores de servicios est&aacute;n ubicados en Estados Unidos u otros pa&iacute;ses ubicados fuera del Espacio Econ&oacute;mico Europeo. Los servicios de estos proveedores implican la existencia de una transferencia internacional de tus datos personales, lo que podr&iacute;a suponer un menor grado de protecci&oacute;n que el recogido en la normativa europea. No obstante, todos los proveedores de este tipo est&aacute;n adheridos al marco del Privacy Shield (</span><a
                            href="https://www.privacyshield.gov/welcome"><span>https://www.privacyshield.gov/welcome</span></a><span>), acuerdo firmado entre Estados Unidos y la Uni&oacute;n Europea por el cual las entidades americanas adheridas al programa, garantizan que aplican los mismos est&aacute;ndares de protecci&oacute;n de datos que las empresas europeas o, en su caso, se han suscrito las correspondientes cl&aacute;usulas contractuales tipo aprobadas por la Comisi&oacute;n Europea. Por ello, la utilizaci&oacute;n de estos proveedores no da lugar a&nbsp;un menor grado de protecci&oacute;n de tus datos personales que el que tendr&iacute;a la utilizaci&oacute;n de proveedores ubicados en la Uni&oacute;n Europea.</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>Organismos P&uacute;blicos</span><span>. Podremos revelar a las autoridades p&uacute;blicas competentes los datos y cualquier otra informaci&oacute;n que est&eacute; en nuestro poder o que sea accesible a trav&eacute;s de nuestros sistemas cuando exista una obligaci&oacute;n legal para ello, as&iacute; como cuando sea requerida, por ejemplo, cuando la finalidad sea la de prevenir o perseguir abusos de los servicios o actividades fraudulentas a trav&eacute;s de nuestro Sitio Web o p&aacute;gina web. En estos casos, los datos de car&aacute;cter personal que nos facilites se conservar&iacute;an y pondr&iacute;an a disposici&oacute;n de las autoridades administrativas o judiciales.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>En caso de una operaci&oacute;n societaria:</span><span>&nbsp;En caso de una fusi&oacute;n, una adquisici&oacute;n, venta de la totalidad o parte de sus activos o cualquier otro tipo de operaci&oacute;n societaria en la que intervenga un tercero, podremos compartir, divulgar o transferir los datos de los usuarios a la entidad sucesora (incluso durante la fase previa a la operaci&oacute;n).</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>A terceros despu&eacute;s de una agregaci&oacute;n o anonimizaci&oacute;n:&nbsp;</span><span>podremos divulgar o utilizar datos agregados o anonimizados (es decir, los cuales no sean vinculables a una persona f&iacute;sica identificada o identificable) para cualquier finalidad.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>A terceros con el consentimiento del usuario u otra base leg&iacute;tima: </span><span>En el caso de que queramos compartir datos con terceros fuera del &aacute;mbito de esta Pol&iacute;tica de privacidad se solicitar&aacute;, en todo caso, su consentimiento o se informar&aacute; sobre la misma y su base de legitimaci&oacute;n.</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Asimismo, te informamos de que esta Pol&iacute;tica de Privacidad s&oacute;lo se refiere a la recogida, tratamiento y uso de informaci&oacute;n (relativa a datos personales) por nuestra parte a trav&eacute;s de la interacci&oacute;n que realizas con nuestro Sitio Web. El acceso a p&aacute;ginas Web de terceros a las que puedas acceder a trav&eacute;s de enlaces desde el Sitio Web tienen sus propias pol&iacute;ticas de privacidad sobre las que no tenemos ning&uacute;n control. Por ello, antes de facilitarles alguna informaci&oacute;n personal, te recomendamos que te informes sobre sus Pol&iacute;ticas de Privacidad.</span>
                        </p>
                        <ol start="6">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>&iquest;CU&Aacute;LES SON LOS DERECHOS QUE PUEDES EJERCER COMO INTERESAD@?</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Puedes ejercer los derechos que la ley te garantiza en relaci&oacute;n con el tratamiento de tus datos personales contactando con nosotros a trav&eacute;s del correo electr&oacute;nico &laquo;info@grabaloapp.com&raquo;.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Cualquier petici&oacute;n de derechos que recibamos la resolveremos lo antes posible y, en cualquier caso, dentro del plazo m&aacute;ximo de 1 mes desde que la recibamos. En algunos casos, ser&aacute; necesario solicitarte una copia de tu documento de identidad u otro documento identificativo si es necesario verificar tu identidad.</span>
                        </p>
                        <p dir="ltr">
                            <span>Los derechos que te corresponden como interesad@, son los siguientes:&nbsp;</span></p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho a retirar el consentimiento otorgado</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Puedes revocar tu consentimiento en relaci&oacute;n con todos los procesamientos basados en &eacute;l en cualquier momento. No obstante, la retirada del consentimiento no afectar&aacute; a la licitud del tratamiento basada en el consentimiento previo a su retirada.</span>
                        </p>
                        <ol start="2">
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho de acceso</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Tienes derecho a saber qu&eacute; datos est&aacute;n siendo objeto de tratamiento, si procede y, si es as&iacute;, obtener una copia de estos, as&iacute; como a obtener informaci&oacute;n relativa a:&nbsp;</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>el origen y los destinatarios de los datos;&nbsp;</span></p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>las finalidades para las que se tratan;&nbsp;</span></p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>si existe un proceso de toma de decisiones automatizado, incluida la elaboraci&oacute;n de perfiles;&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>el per&iacute;odo de retenci&oacute;n de los datos; y&nbsp;</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>los derechos previstos por la normativa.</span></p>
                            </li>
                        </ul>
                        <p><span><span>&nbsp;</span></span></p>
                        <ol start="3">
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho de rectificaci&oacute;n</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Tienes derecho a obtener la rectificaci&oacute;n de tus datos personales o a completarlos cuando est&aacute;n incompletos.</span>
                        </p>
                        <ol start="4">
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho de supresi&oacute;n</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Tienes derecho a solicitar la supresi&oacute;n de tus datos personales si ya no son necesarios para el prop&oacute;sito para el que fueron recopilados o, en su caso, si ya no estamos autorizados para tratarlos.</span>
                        </p>
                        <ol start="5">
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho a la portabilidad de datos</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Tienes derecho a solicitar la portabilidad de los datos en el caso de los tratamientos de tus datos que est&eacute;n basados en tu consentimiento o en la ejecuci&oacute;n de un contrato, siempre y cuando el procesamiento se haya realizado por medios automatizados. En caso de ejercicio de este derecho, recibir&aacute;s tus datos personales en un formato estructurado, de uso com&uacute;n y legible por cualquier dispositivo electr&oacute;nico. No obstante, tambi&eacute;n puedes solicitar, cuando sea posible, que tus datos sean transmitidos directamente a otra empresa.</span>
                        </p>
                        <ol start="6">
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho a la limitaci&oacute;n del tratamiento de tus datos personales</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Tienes derecho a la limitaci&oacute;n del procesamiento de tus datos en los siguientes casos:</span>
                        </p>
                        <ol>
                            <li dir="ltr">
                                <p dir="ltr"><span>Cuando hayas solicitado la rectificaci&oacute;n de tus datos personales durante el per&iacute;odo en el que verifiquemos la exactitud de los mismos.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Cuando consideres que no estamos autorizados a procesar tus datos. En ese caso, puedes solicitarnos que limitemos su uso en vez de solicitar su supresi&oacute;n.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>Cuando consideres que la ya no es necesario que sigamos tratando tus datos y quieras que los conservemos a los efectos del ejercicio o la defensa de reclamaciones.</span>
                                </p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr"><span>En los casos en los que exista un procesamiento basado en nuestro inter&eacute;s leg&iacute;timo y hayas ejercitado tu derecho de oposici&oacute;n al mismo, puedes pedirnos que limitemos el uso de tus datos durante la verificaci&oacute;n de la prevalencia de dichos intereses con respecto a los tuyos.</span>
                                </p>
                            </li>
                        </ol>
                        <ol start="7">
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho de oposici&oacute;n</span></p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Tienes derecho a oponerte en cualquier momento al procesamiento de tus datos personales en funci&oacute;n de nuestro inter&eacute;s leg&iacute;timo, incluida la elaboraci&oacute;n de perfiles.</span>
                        </p>
                        <p dir="ltr"><span>Baja de las comunicaciones comerciales</span><span>: Recuerda que en cualquier momento puedes oponerte a la recepci&oacute;n de este tipo de comunicaciones envi&aacute;ndonos un email a &laquo;info@grabaloapp.com&raquo;. Tambi&eacute;n podr&aacute;s excluirte de este servicio siguiendo las instrucciones que se indican en el pie del cuerpo de cada una de las comunicaciones electr&oacute;nicas que te enviemos.</span>
                        </p>
                        <ol start="8">
                            <li dir="ltr">
                                <p dir="ltr"><span>Derecho a presentar una reclamaci&oacute;n ante la Autoridad de Control</span>
                                </p>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Recuerda que, en cualquier momento, y en caso de que consideres que hemos vulnerado tu derecho a la protecci&oacute;n de tus datos, podr&aacute;s dirigirte en su defensa a la Autoridad de Control correspondiente, en caso de Espa&ntilde;a, la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos (</span><a
                            href="http://www.agpd.es"><span>www.agpd.es</span></a><span>).&nbsp;</span></p>
                        <ol start="7">
                            <li dir="ltr">
                                <h2 dir="ltr">
                                    <span>&iquest;C&Oacute;MO GARANTIZAMOS LA CONFIDENCIALIDAD DE TU INFORMACI&Oacute;N?</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>La seguridad de tus datos personales es una prioridad para nosotros. Por ello, &laquo;GR&Aacute;BALO&raquo; ha implantado todas las medidas de seguridad necesarias para garantizar un uso y tratamiento de los datos de car&aacute;cter personal facilitados por el/la usuari@ eficaz, salvaguardando la intimidad, privacidad, confidencialidad e integridad de los mismos y hace uso de los medios t&eacute;cnicos necesarios para evitar la alteraci&oacute;n, p&eacute;rdida, acceso no autorizado o tratamiento de sus datos, seg&uacute;n el estado de la tecnolog&iacute;a en cada momento.</span>
                        </p>
                        <p dir="ltr"><span>Consecuentemente, cumplimos con los est&aacute;ndares de seguridad recomendados para protegerlos. Sin embargo, es imposible garantizar de un modo total su seguridad por la propia naturaleza de internet y porque pueden existir actuaciones dolosas de terceros fuera de nuestro control.&nbsp;</span>
                        </p>
                        <p dir="ltr"><span>Nos comprometemos a actuar con rapidez y diligencia en caso de que la seguridad de los datos se vea en peligro o comprometida, y a informarte sobre ello en caso de que sea relevante.&nbsp;</span>
                        </p>
                        <ol start="8">
                            <li dir="ltr">
                                <h2 dir="ltr"><span>&iquest;QU&Eacute; NORMATIVA AFECTA A ESTA POL&Iacute;TICA DE PRIVACIDAD?</span>
                                </h2>
                            </li>
                        </ol>
                        <p dir="ltr"><span>Por si deseas obtener m&aacute;s informaci&oacute;n sobre la normativa que te asiste, protege y establece tus derechos, ponemos a tu disposici&oacute;n las leyes que han inspirado la presente pol&iacute;tica y son relevantes para ti:</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Reglamento General de Protecci&oacute;n de Datos UE 216/679&nbsp;</span></p>
                            </li>
                        </ul>
                        <p dir="ltr"><a
                            href="https://www.boe.es/buscar/doc.php?id=DOUE-L-2016-80807"><span>https://www.boe.es/buscar/doc.php?id=DOUE-L-2016-80807</span></a><span>&nbsp;&nbsp;</span>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">
                                    <span>Ley Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales.</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><a
                            href="https://www.boe.es/eli/es/lo/2018/12/05/3"><span>https://www.boe.es/eli/es/lo/2018/12/05/3</span></a>
                        </p>
                        <ul>
                            <li dir="ltr">
                                <p dir="ltr"><span>Ley 34/2002, de 11 de julio, de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico</span>
                                </p>
                            </li>
                        </ul>
                        <p dir="ltr"><a
                            href="http://www.boe.es/buscar/act.php?id=BOE-A-2002-13758"><span>http://www.boe.es/buscar/act.php?id=BOE-A-2002-13758</span></a>
                        </p>
                        <p dir="ltr">
                            <span>Desde &laquo;GR&Aacute;BALO&raquo; podemos modificar el contenido de la pol&iacute;tica de privacidad en cualquier momento seg&uacute;n las modificaciones legislativas, jurisprudenciales o de interpretaci&oacute;n de la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos que puedan producirse.&nbsp;</span>
                        </p>
                        <p dir="ltr">
                            <span>Por ello, te recomendamos que revises peri&oacute;dicamente esta Pol&iacute;tica de Privacidad para estar informada de c&oacute;mo se tratan y protegen tus datos personales, as&iacute; como de los derechos que te asisten.</span>
                        </p>
                        <p dir="ltr"><span>La presente Pol&iacute;tica de Privacidad ha sido modificada en fecha 20 de agosto de 2020.</span>
                        </p>
                        <p><span>&nbsp;</span></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, RegisterModalReducer}) => {
    return ({
        registerModalOpened: RegisterModalReducer.open,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(Terms);
