/*font-family*/
export const fontFamilyPrimary = 'HelveticaNeue, sans-serif';

/*font-size*/
export const  microFontSize= '0.1rem';
export const tinyFontSize = '0.8rem';
export const smallFontSize = '0.9rem';
export const mediumFontSize = '1rem';
export const largeFontSize =' 1.2rem';
export const xlFontSize = '1.4rem';
export const bigFontSize = '1.6rem';
export const titleFontSize = '2rem';
export const hugeFontSize = '2.8rem';

/*font-weight*/
export const lightFontWeight = 100;
export const regularFontWeight = 300;
export const mediumFontWeight = 400;
export const semiBoldFontWeight = 500;
export const boldFontWeight = 'bold';

/* font shadows for high contrast */
export const defaultContrastShadow = '0 0 5px #000000';
