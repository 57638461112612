import React from "react";
import connect from "react-redux/es/connect/connect";
import TextField from "@material-ui/core/TextField";
import {CircularProgress, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import importedStyles from "./SuggestedLoginModal.module.sass";
import {showSnack, snackTypes} from "../Snack/SnackActions";
import ReactPixel from "react-facebook-pixel";
import {loginRequest} from "../../helpers/fetch/auth";
import {login} from "../../modules/Auth/authActions";
import Typography from "@material-ui/core/Typography";
import {closeSuggestedLoginModal} from "./Redux/SuggestedLoginModalActions";
import Button from "@material-ui/core/Button";
import history from "../../helpers/history";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {throwLogin} from "../../helpers/routeMiddleware";
import {openRegisterModal} from "../RegisterModal/Redux/RegisterModalActions";
import {openFirstLoginModal} from "../FirstLoginOnBoard/Redux/FirstLoginOnBoardActions";
import Snack from "../Snack/Snack";
import GoogleLogin from "react-google-login";
import GoogleIcon from "../../icons/google-icon.png";
import FacebookIcon from "../../icons/facebook-icon.png";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import baseUrl from "../../helpers/baseUrl";
import {openEntitiesOnBoarding} from "../OnBoardingEntities/Redux/OnBoardingEntitiesActions";
import PropTypes from "prop-types";
import {sendPendingToAssociateMediaIds} from "../../modules/Concurso/helpers";


class SuggestedLoginModal extends React.Component {

    state = {
        email: '',
        password: '',
        authVerifiedError: false,
        authPasswordError: false,
        authEmailError: false,
        waitingResponse: false,
        waitingSocialLogin: false
    };

    /* LIFE CYCLES */
    componentDidMount() {
        if (this.props.open) {
            document.getElementById('root').style.filter = 'blur(8px)';
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open) {
            document.getElementById('root').style.filter = 'blur(8px)';
        }

        if (this.props.open && !prevProps.open) {
            ReactPixel.trackCustom('ModalLoginAbierto');
        }
    }

    componentWillUnmount() {
        document.getElementById('root').style.filter = 'unset';
    }

    /* HANDLERS */
    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
    handleRegisterModal = () => {
        this.props.dispatch(this.props.openRegisterModal ? this.props.openRegisterModal() : openRegisterModal());
        this.props.dispatch(closeSuggestedLoginModal());
    };
    handleModalClose = () => {
        this.props.dispatch(closeSuggestedLoginModal());
        document.getElementById('root').style.filter = 'unset';
    };
    handleGoogleResponse = (response) => {
        //console.log('Response google: ', response);
        let email = response.profileObj.email;
        let authToken = response.googleId;

        this.handleSocialLogin(email, authToken, 'google');

    };
    handleGoogleFailure = (response) => {
        this.props.dispatch(showSnack('Error en la conexión a Google, vuelve a intentarlo', snackTypes.error));
        console.error('Auth response FAILED google: ', response);
    };

    /* API CALLS */
    handleLogin = () => {

        this.setState({
            authPasswordError: false,
            authEmailError: false,
            authVerifiedError: false,
            waitingResponse: true
        });

        let email = this.state.email;
        let password = this.state.password;
        let videoUuidUploaded = localStorage.getItem('videoUuidUploaded');


        loginRequest(email, password, videoUuidUploaded).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        ReactPixel.trackCustom('LoginSuccess');
                        this.setState({
                            email: '',
                            password: '',
                            waitingResponse: false,
                            waitingRegisterResponse: false
                        });
                        this.props.dispatch(login(response.user));

                        sendPendingToAssociateMediaIds(response.user, this.props.openThankYouModalFunction);

                        this.props.dispatch(closeSuggestedLoginModal());
                        // if (videoUuidUploaded) {
                        //     localStorage.removeItem('videoUuidUploaded');
                        //     this.props.openThankYouModalFunction();
                        // } else {

                            if (response.user.entity) {
                                if (response.user.firstLogin) {
                                    this.props.dispatch(openEntitiesOnBoarding());
                                }
                                throwLogin('/categories');
                            } else {
                                let nextRoute = this.props.nextRoute;
                                if (response.user.firstLogin) {
                                    this.props.dispatch(openFirstLoginModal());
                                    //nextRoute = nextRoute + '?firstLogin=true';
                                }
                                throwLogin(nextRoute);
                            }

                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingResponse: false});
                                this.props.dispatch(showSnack('Error en la autenticación, no existe la cuenta', snackTypes.error));
                                break;
                            case 'badPassword':
                                this.setState({authPasswordError: true, waitingResponse: false});
                                this.props.dispatch(showSnack('Error en la autenticación, contraseña incorrecta', snackTypes.error));
                                break;
                            case 'notVerified':
                                this.setState({authVerifiedError: true, waitingResponse: false});
                                this.props.dispatch(showSnack('Error en la autenticación, cuenta no verificada', snackTypes.error));
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                        ReactPixel.trackCustom('LoginError', {error: response.errorType});
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                    ReactPixel.trackCustom('LoginError', {error: error});
                }
            );


    };
    handleSocialLogin = (email, password, provider) => {

        this.setState({
            waitingResponse: true,
            waitingSocialLogin: provider
        });

        let videoUuidUploaded = localStorage.getItem('videoUuidUploaded');

        loginRequest(email, password, videoUuidUploaded).then(res => res.json())
            .then(
                async (response) => {
                    if (!response.error) {
                        ReactPixel.trackCustom('LoginSuccess');
                        this.setState({
                            email: '',
                            password: '',
                            waitingResponse: false,
                            waitingSocialLogin: false
                        });
                        await this.props.dispatch(login(response.user));
                        this.props.dispatch(closeSuggestedLoginModal());

                        sendPendingToAssociateMediaIds(response.user, this.props.openThankYouModalFunction);
                        //
                        // if (videoUuidUploaded) {
                        //     localStorage.removeItem('videoUuidUploaded');
                        //     this.props.openThankYouModalFunction()
                        // } else {
                        //     //this.props.dispatch(showSnack('¡Bienvenido de nuevo!', 'success'));

                            if (response.user.entity) {
                                throwLogin('/categories');
                            } else {
                                let nextRoute = this.props.nextRoute;
                                if (response.user.firstLogin) {
                                    this.props.dispatch(openFirstLoginModal());
                                }
                                throwLogin(nextRoute);
                            }

                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({waitingSocialLogin: false, waitingResponse: false});
                                this.props.dispatch(showSnack('Error en la autenticación, no existe ninguna cuenta con las credenciales proporcionadas', snackTypes.error));
                                break;
                            default:
                                this.setState({waitingSocialLogin: false, waitingResponse: false});
                                this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                        ReactPixel.trackCustom('LoginError', {error: response.errorType});
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        waitingSocialLogin: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                    ReactPixel.trackCustom('LoginError', {error: error});
                }
            )
    };

    handleFBResponse = (response) => {
        let email = response.email;
        let authToken = response.userID;

        this.handleSocialLogin(email, authToken, 'facebook');

    };

    /* RENDERS */
    renderSocialLoginButtons = () => {
        return (
            <React.Fragment>
                <div className={importedStyles.socialLoginContainer}>
                    <GoogleLogin
                        render={renderProps => (
                            <Button variant={'contained'} fullWidth onClick={renderProps.onClick}
                                    disabled={this.state.waitingSocialLogin === 'google'}
                                    className={importedStyles.googleButton}>
                                <div className={importedStyles.socialLoginPlatformIconContainer}>
                                    <img src={GoogleIcon} alt={'google icon'}
                                         className={importedStyles.socialLoginPlatformIcon}/>
                                </div>
                                <span className={importedStyles.socialLoginPlatformName}>Accede con Google</span>
                                {this.state.waitingSocialLogin === 'google' &&
                                <CircularProgress className={importedStyles.waitingSocialLoginProgress} size={25}/>}
                            </Button>
                        )}
                        clientId="421190726876-g6j9ppjlort8actathh13k1pnt572iii.apps.googleusercontent.com"
                        onSuccess={this.handleGoogleResponse}
                        onFailure={this.handleGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                    <FacebookLogin
                        appId={"772508366890225"}
                        language={'es_ES'}
                        autoLoad={true}
                        disableMobileRedirect={true}
                        isMobile={false}
                        redirectUri={baseUrl}
                        fields="name,email,picture"
                        render={renderProps => (
                            <Button variant={'contained'} fullWidth onClick={renderProps.onClick}
                                    disabled={this.state.registeringWithSocial === 'facebook'}
                                    className={importedStyles.facebookButton}>
                                <div className={importedStyles.socialLoginPlatformIconContainer}>
                                    <img src={FacebookIcon} alt={'fb icon'}
                                         className={importedStyles.socialLoginPlatformIcon}/>
                                </div>
                                <span className={importedStyles.socialLoginPlatformName}>Accede con Facebook</span>
                                {this.state.registeringWithSocial === 'facebook' &&
                                <CircularProgress className={importedStyles.waitingSocialLoginProgress} size={25}/>}
                            </Button>
                        )}
                        callback={this.handleFBResponse}
                    />
                </div>
                <div className={importedStyles.insetFormDivider}>
                    <span className={importedStyles.dividerSection}/> <span
                    className={importedStyles.dividerText}> o con </span> <span
                    className={importedStyles.dividerSection}/>
                </div>
            </React.Fragment>
        )
    };
    renderAuthVerifiedErrorMessage = () => {
        return (
            <Typography variant={"caption"} className={importedStyles.authVerifiedErrorMessage}>
                Tu cuenta aún no ha sido verificada, te hemos enviado un email con el link de confirmación
            </Typography>
        );
    };

    render() {
        const fullscreen = window.matchMedia("(max-width: 768px)").matches;
        return (
            <React.Fragment>
                <Dialog open={this.props.open} maxWidth={'xs'} onClose={this.handleModalClose} fullWidth
                        fullScreen={fullscreen}
                        classes={{
                            paper: importedStyles.dialogMain,
                            paperFullScreen: importedStyles.dialogMobileFullScreen,
                        }}
                        BackdropProps={{
                            className: importedStyles.dialogBackdrop
                        }}
                >
                    <IconButton className={importedStyles.closeModalIcon} onClick={this.handleModalClose}>
                        <CloseIcon/>
                    </IconButton>
                    <DialogTitle>
                        Accede
                    </DialogTitle>
                    <DialogContent
                        className={importedStyles.dialogContent}
                        classes={{
                            root: importedStyles.dialogRootFix
                        }}
                    >
                        {/*<DialogContentText className={importedStyles.loginSubtitle}> </DialogContentText>*/}
                        <form className={importedStyles.form}>
                            {this.renderSocialLoginButtons()}
                            <TextField
                                margin="normal"
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                type="text"
                                inputProps={{maxLength: 50}}
                                value={this.state.email}
                                error={this.state.authEmailError}
                                helperText={this.state.authEmailError && 'Esta cuenta no existe, pulsa el botón "regístrate" y crea tu cuenta en unos segundos'}
                                fullWidth
                                onChange={this.handleInputChange}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        document.getElementById('loginButton').click();
                                    }
                                }}
                            />
                            <TextField
                                margin="normal"
                                id="password"
                                name="password"
                                label="Contraseña"
                                type="password"
                                inputProps={{maxLength: 50}}
                                value={this.state.password}
                                fullWidth
                                error={this.state.authPasswordError}
                                helperText={this.state.authPasswordError && 'La contraseña introducida no es correcta'}
                                onChange={this.handleInputChange}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        document.getElementById('loginButton').click();
                                    }
                                }}
                            />
                        </form>
                        {this.state.authVerifiedError && this.renderAuthVerifiedErrorMessage()}
                        <div className={importedStyles.dialogActions}>
                            <div className={importedStyles.actionsContainer}>
                                <Button
                                    id={'loginButton'}
                                    className={importedStyles.submitButton}
                                    variant='contained'
                                    disabled={this.state.waitingResponse || this.state.email.length <= 0 || this.state.password.length <= 0}
                                    onClick={this.handleLogin}
                                    fullWidth
                                >
                                    Acceder
                                    {this.state.waitingResponse &&
                                    <CircularProgress className={importedStyles.progressCircle}/>}
                                </Button>
                                {/*
                           <p>o</p>
                                <Button
                                    className={[importedStyles.submitButton, importedStyles.goToRegisterButton]}
                                    variant='contained'
                                    disabled={this.state.waitingResponse}
                                    onClick={this.handleRegisterModal}
                                    fullWidth
                                >
                                    REGÍSTRATE
                                </Button>
                            */}
                            </div>
                            <div className={importedStyles.footerTextContainer}>
                                <span>¿Has olvidado tu contraseña?
                                    <span className={importedStyles.newPasswordLink}
                                          onClick={() => history.push('/requestPasswordChange')}>Pide otra</span>
                                </span>

                                <span className={importedStyles.registerLinkLabel}>¿No tienes cuenta?
                                    <span className={importedStyles.registerLink}
                                          onClick={this.handleRegisterModal}>Regístrate</span>
                                </span>
                            </div>
                        </div>
                    </DialogContent>
                    <Snack style={{zIndex: '8000'}}/>
                </Dialog>
            </React.Fragment>
        );
    }

}

SuggestedLoginModal.propTypes = {
    openRegisterModal: PropTypes.func,
    openThankYouModalFunction: PropTypes.func,
};
const mapStateToProps = ({SuggestedLoginModalReducer}) => {
    return ({
        open: SuggestedLoginModalReducer.open,
        nextRoute: SuggestedLoginModalReducer.nextRoute,
        reloadNeeded: SuggestedLoginModalReducer.reload,
    });
};
export default connect(mapStateToProps)(SuggestedLoginModal);
