import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './MyVideos.module.sass';
import history from "../../../helpers/history";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import {setVideo} from "../../DetailVideo/Redux/detailVideoActions";
import UploadVideoButton from "../../../components/UploadVideoButton/UploadVideoButton";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import {GridList, GridListTile} from "@material-ui/core/index";
import {deleteVideo} from "../../../helpers/fetch/video";
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import DetailVideoModal from "../../DetailVideoModal/DetailVideoModal";
import IconButton from "@material-ui/core/IconButton";
import {InfoOutlined} from "@material-ui/icons";
import MyTooltip from "../../../components/MyTooltip";
import NoVideosMessage from "../../../components/NoVideosMessage/NoVideosMessage";
import {Helmet} from "react-helmet/es/Helmet";

class MyVideos extends React.Component {

    state = {
        videos: [],
        openDelete: false,
        videoIdDelete: '',
        selectedVideo: null,
        actualVideoIndex: null,
        openDetailVideoModal: false,
        openedTooltip: '',
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getMyVideos();
        ReactPixel.trackCustom('MyVideosView', {user: this.props.user.uuid});
        ReactGA.pageview(window.location.pathname);
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.UrlParamsObserver(prevProps);
    }

    UrlParamsObserver = prevProps => {
        //If we dont have videoId now but have it in prevProps, means we must close modal (this code is to handle native navigation behavior)
        if (!this.props.match.params.videoId && prevProps.match.params.videoId){
            this.setState({openDetailVideoModal: false, selectedVideo: null, actualVideoIndex: null});
        } else {
            //If actual videoId differs from previous one, must handle it
            if (this.props.match.params.videoId !== prevProps.match.params.videoId) {
                this.handleUrlVideoParam();
            }
        }
    };

    handleTooltipOpen = (tooltipId) => () => {
        this.setState({openedTooltip: tooltipId});
    };
    handleTooltipClose = () => {
        this.setState({openedTooltip: ''});
    };

    handleUrlVideoParam = () => {
        let urlVideoId = this.props.match.params.videoId;
        if (urlVideoId){
            let videosCollection = this.state.videos;
            let indexOfRequestedVideo = videosCollection.findIndex(item => item.urlSlug === urlVideoId);
            let video = videosCollection[indexOfRequestedVideo];
            this.setState({openDetailVideoModal: true, selectedVideo: video, actualVideoIndex: indexOfRequestedVideo});
            this.props.dispatch(setVideo(video));
        }
    };

    handleDetailVideoModal = (video, actualVideoIndex) => () => {
        ReactPixel.trackCustom('VideoSelectedOnMyVideos', {video: video.uuid});
        this.setState({openDetailVideoModal: true, selectedVideo: video, actualVideoIndex: actualVideoIndex});
        this.props.dispatch(setVideo(video));
        history.push(this.props.location.pathname + '/' + video.urlSlug + history.location.search);
    };
    closeDetailVideoModal = () => {
        this.setState({openDetailVideoModal: false, selectedVideo: null, actualVideoIndex: null});
        history.replace('/myVideos');
    };
    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        ReactPixel.trackCustom('VideoSelectedOnMyVideos', {video: nextVideo.uuid});

    };
    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        ReactPixel.trackCustom('VideoSelectedOnMyVideos', {video: nextVideo.uuid});
    };


    getMyVideos = () => {

        this.setState({
            waitingResponse: true
        });

        let uuid = this.props.user.uuid;

        fetch(apiBaseUrl + 'users/listUserVideos', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({userId: uuid})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            videos: response.videos,
                            waitingResponse: false,
                        });
                        this.handleUrlVideoParam()
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingResponse: false});
                                break;
                            case 'badPassword':
                                this.setState({authPasswordError: true, waitingResponse: false});
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error al enviar el mensaje, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };


    handleDeleteVideo = () => {
        this.setState({openDelete: false});
        deleteVideo(this.state.videoIdDelete)
            .then(res => res.json())
            .then((response) => {
                    if (!response.error) {
                        window.location.reload();
                    } else {
                        throw new Error(response.errorMessage)
                    }
                }, (error) => {
                    throw new Error(error)
                }
            ).catch(error => {
            console.log('Error: ' + error);
            this.props.dispatch(showSnack('Error al borrar, vuelva a intentarlo', snackTypes.error));
        });
    };

    handleModalStatus = () => {
        this.setState({openDelete: !this.state.openDelete});
    };

    renderCardVideo = (tileData, index) => {
        if (tileData.reviewed){
            return (
                <GridListTile key={tileData.uuid} className={importedStyles.videoTile}>
                    <div onClick={this.handleDetailVideoModal(tileData, index)} className={importedStyles.fixClickOnTile}/>
                    <img alt={'videoThumbnail'}
                         src={tileData.coverUrl}
                         onError={(e) => {
                             e.target.onerror = null;
                             e.target.src = "/img/png/thumbnail.png"
                         }}
                         className={importedStyles.thumbnail}/>
                    {/*Puede ser interesante tener este trozo de código para más adelante. No borrar*/}

                    {/* <GridListTileBar*/}
                    {/*    title={tileData.title}*/}
                    {/*    //subtitle={}*/}
                    {/*    actionIcon={*/}
                    {/*        <IconButton className={importedStyles.deleteIcon} onClick={() => {*/}
                    {/*            this.setState({videoIdDelete: tileData.uuid});*/}
                    {/*            this.handleModalStatus()*/}
                    {/*        }}>*/}
                    {/*            <DeleteForever/></IconButton>}*/}
                    {/*/>*/}
                </GridListTile>
            );
        } else {
            return (
                <GridListTile key={tileData.uuid} className={importedStyles.videoTile}>
                    <img alt={'thumbnail'}
                         src={'/img/png/thumbnailRevision.png'}
                         className={importedStyles.thumbnail}/>
                    <GridListTileBar title={<span style={{color: '#ffff'}}>{tileData.title}</span>} />
                </GridListTile>
            );
        }

    };

    renderVideosOld = () => {
        const videos = this.state.videos;
        if (videos.length > 0) {
            return (
                <Grid container className={importedStyles.uploadedVideosContainer}>
                    <GridList
                        cellHeight={180} spacing={window.matchMedia("(max-width: 1024px)").matches ? 2 : 12}
                        cols={3}
                        className={importedStyles.uploadedVideoGallery}
                    >
                        {videos.map((tileData, index) => this.renderCardVideo(tileData, index))}
                    </GridList>
                </Grid>
            )
        } else {
            return (
               <NoVideosMessage/>
            )
        }
    };
    // renderVideos = () => {
    //     const videos = this.state.videos;
    //     let videoCards = [];
    //
    //     let i = 1;
    //     Object.keys(videos).forEach((key) => {
    //
    //         i < 10 && i++; //Increment counter if actual video is below the 10th first
    //
    //         let authorName = '-';
    //         let video = videos[key];
    //         let tagsView = [];
    //
    //         let videoIsFree = parseInt(video.price) <= 0;
    //         Object.keys(video.tags).forEach((indexTag) => {
    //             if (video.tags[indexTag].text.length < 15) {
    //                 tagsView.push(<p key={indexTag}>{videos[key].tags[indexTag].text}</p>);
    //             }
    //         });
    //
    //         if (video.user && video.user.name) {
    //             let fragments = video.user.name.split(" ");
    //             if (fragments.length > 1) {
    //                 authorName = fragments[0] + ' ' + fragments[1].substr(0, 1) + '.';
    //             } else {
    //                 authorName = fragments[0]
    //             }
    //         }
    //
    //         videoCards.push(
    //             <Fade unmountOnExit mountOnEnter in={!this.state.waitingResponse}
    //                   timeout={i * 800 / 2}>
    //                 <Grid className={importedStyles.videoCard} key={key} item xs={12} md={4}>
    //                     <VideoCard
    //                         onClick={this.handleDetailVideoModal(video, key)}
    //                         video={video}
    //                         freeTag={videoIsFree}
    //                         tags={video.tags}
    //                         title={video.title}
    //                         author={authorName}
    //                         isFavorite={video.favourite_videos && video.favourite_videos.length > 0}
    //                         date={video.uploadDate}
    //                         image={video.coverUrl}
    //                         titleTruncation={1}
    //                         showTitle={this.state.testTitles}
    //                     />
    //                 </Grid>
    //             </Fade>
    //         )
    //     });
    //
    //
    //     if (videoCards.length === 0 && !this.state.waitingResponse) {
    //         return (
    //             <p style={{margin: 20}}>Oops, parece que nuestros servidores están teniendo un dia duro, prueba de nuevo
    //                 en unos minutos, gracias.</p>
    //         );
    //     } else {
    //         return videoCards;
    //     }
    // };


    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Mis archivos</title>
                </Helmet>
                <NavBar/>
                <UploadVideoButton/>
                <DesktopDrawer/>
                <Snack/>
                {/*Alert para borrar vídeo funcionamiento futuro*/}
                {/*<MyAlert*/}
                {/*    functionOk={this.handleDeleteVideo}*/}
                {/*    title={"¿Eliminar vídeo?"}*/}
                {/*    openModal={this.state.openDelete}*/}
                {/*    handleModalStatus={this.handleModalStatus}*/}
                {/*    subtitle={"Si aceptas eliminarás el vídeo para siempre."}*/}
                {/*/>*/}
                <div className={importedStyles.myVideos}>
                    {/*<div className={importedStyles.titleContainer}>*/}
                    <h2 className={importedStyles.title}>Mis archivos subidos
                    <MyTooltip
                        onClick={this.handleTooltipOpen('videos')}
                        onClose={this.handleTooltipClose}
                        onMouseEnter={this.handleTooltipOpen('videos')}
                        onMouseLeave={this.handleTooltipClose}
                        open={this.state.openedTooltip === 'videos'}
                        disableFocusListener
                        disableTouchListener
                        title="Aquí podrás ver los archivos que han sido aceptados y aquellos que aún no han sido revisados. Si subiste archivos y ya no aparecen en esta sección es porque no pasaron los filtros de calidad o no pudimos abrirlos, te deben de haber llegado unos emails con los motivos. Revisamos los archivos uno a uno, si no encuentras los emails visita la bandeja de spam, por favor. Cuando se rechazan los archivos automáticamente se borran de nuestra base de datos."
                    >
                        <IconButton className={importedStyles.iconInfoButton} aria-label="delete">
                            <InfoOutlined className={importedStyles.iconInfo}/>
                        </IconButton>
                    </MyTooltip>
                    </h2>
                    {/*</div>*/}
                    <Grid container spacing={1}>
                        {this.state.waitingResponse ?
                            /*<GridList
                                cellHeight={180} spacing={window.matchMedia("(max-width: 1024px)").matches ? 2 : 12}
                                cols={3}
                                className={importedStyles.uploadedVideoGallery}
                            >
                                <GridListTile key={0} className={importedStyles.videoTile}>
                                    <Skeleton height={'100%'} width={'100%'}/>
                                </GridListTile>
                                <GridListTile key={1} className={importedStyles.videoTile}>
                                    <Skeleton height={'100%'} width={'100%'}/>
                                </GridListTile>
                                <GridListTile key={2} className={importedStyles.videoTile}>
                                    <Skeleton height={'100%'} width={'100%'}/>
                                </GridListTile>
                            </GridList>*/
                            <CircularProgress size={65} className={importedStyles.waitingAnimation}/>
                            :
                            this.state.videos && this.renderVideosOld()}
                    </Grid>
                </div>
                {this.state.selectedVideo && <DetailVideoModal open={this.state.openDetailVideoModal} onClose={this.closeDetailVideoModal} video={this.state.selectedVideo} actualVideoIndex={this.state.actualVideoIndex} lastVideoIndex={this.state.videos.length - 1}  handlePrevVideo={this.handlePrevVideo} handleNextVideo={this.handleNextVideo}/>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(MyVideos);
