import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {useParams} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {createMuiTheme, Fade, responsiveFontSizes} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {IframeVideoCard} from "./IframeVideoCard/IframeVideoCard";
import ArrowBackIcon from '../../../icons/arrow_back_iframe.svg';
import ArrowForwardIcon from '../../../icons/arrow_forward_iframe.svg';
import ReactGA from "react-ga";
import TimeMonitor from "../../../helpers/TimeMonitor/TimeMonitor";
import {sendStat} from "../../../helpers/stats/statsAPI";
import {ReactComponent as VerifiedByGrabalo} from "../../../icons/with_grabalo_tech.svg";
import Box from "@material-ui/core/Box";
import {BallPulse} from 'react-pure-loaders';
import {FilesGrid} from "../../../components/FilesGrid/FilesGrid";


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: "transparent",
        paddingTop: '2rem',
        [theme.breakpoints.up('sm')]: {
            // padding: '1.25rem 13vw 1rem 13vw',
            paddingTop: '8.25rem',
        },
    },
    gridList: {
        padding: '1rem 3rem',
        flexWrap: 'nowrap',
        //width: 500,
        //height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    gridListTile: {
        borderRadius: 12
    },
    titleBar: {
        color: '#fff',
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    videoTitle: {
        color: '#fff',
    },
    icon: {
        color: 'white',
        fontSize: 32,
    },
    sliderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    watermarkContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '0.5rem',
        marginRight: 25,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginRight: 0
        },
    },

    navigationArrowContainer: {
        [theme.breakpoints.down('md')]: {
            padding: 0
        },
    },
    mobileNavigation: {
        display: 'flex',
        justifyContent: 'center'
    },
    navigationArrowContainerDisabled: {},
    navigationArrowDisabled: {
        color: '#5f5f5f',
        filter: 'opacity(0.5)',
        height: 'calc(1em + 2vw)',
        [theme.breakpoints.down('md')]: {
            height: '3rem'
        },
    },
    navigationArrow: {
        height: 'calc(1em + 2vw)',
        [theme.breakpoints.down('md')]: {
            height: '3rem'
        },
    },
    arrowPrevContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    arrowNextContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    filesGridContainer: {
        padding: 6,
        width: '100%'
    }
}));


export default function VideowallIframe(props) {
    //1 column if mobile, 2 if small desktop, 3 if desktop
    const layoutCols = window.matchMedia("(max-width: 768px)").matches ? 1 : window.matchMedia("(max-width: 1300px)").matches ? 2 : 3;
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    const classes = useStyles();

    const [wall, setWall] = useState([]);
    const [videos, setVideos] = useState([]);
    const [waitingWallData, setWaitingWallData] = useState(true);
    const [fetchError, setFetchError] = useState(false);
    const [hideNavs, setHideNavs] = useState(false);
    let {videowallUrlSlug} = useParams();

    //Carousel
    const [activeIndex, setActiveIndex] = useState(0);


    const slidePrev = () => {
        // ReactGA.event({
        //     category: 'MuroConfianza',
        //     action: 'Click navegación carousel izquierda',
        //     label: 'Click navegación izquierda muro "'+ wall.urlSlug +'"',
        //     value: wall.uuid,
        //     nonInteraction: true
        // });
        if (activeIndex >= 1) {
            setActiveIndex(activeIndex - layoutCols);
            sendStat('external_nav', null, wall.uuid, null);
        }
    }
    const slideNext = () => {
        // ReactGA.event({
        //     category: 'MuroConfianza',
        //     action: 'Click navegación carousel derecha',
        //     label: 'Click navegación derecha carousel muro "'+ wall.urlSlug +'"',
        //     value: wall.uuid,
        //     nonInteraction: true
        // });

        // if (activeIndex <= (videos.length) / layoutCols) {
        //
        // }
        if ((activeIndex + layoutCols) < videos.length) {
            sendStat('external_nav', null, wall.uuid, null);
            setActiveIndex(activeIndex + layoutCols);
        }
    }
    const syncActiveIndex = ({item}) => setActiveIndex(item);

    //// END CAROUSEL FUNCTIONS

    const getWall = async () => {
        try {
            setWaitingWallData(true);
            setFetchError(false);

            let response = await fetch(apiBaseUrl + 'videowall/get', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({urlSlug: videowallUrlSlug}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            setWall(response.data);
            setWaitingWallData(false);
            setFetchError(false);
            sendStat('view', null, response.data.uuid, null);

        } catch (error) {
            setWaitingWallData(false);
            setFetchError(true);
            //dispatch(showSnack('Error al cargar los datos del muro', 'error'));
            console.error('Error: ', error);
        }
    };

    // Component didMount
    useEffect(() => {
        const timeMonitor = new TimeMonitor();
        getWall().then(() => {
            // ReactGA.ga('send', 'event', 'muro', 'visita');
            // ReactGA.event({
            //     category: 'MuroConfianza',
            //     action: 'Visita/Impresión',
            //     label: 'Iframe muro "'+ wall.urlSlug +'", visita',
            //     value: wall.uuid,
            //     nonInteraction: true
            // });

        });


    }, [videowallUrlSlug]);

    useEffect(() => {
        wall.videos && setVideos(wall.videos);
    }, [wall]);

    useEffect(() => {
        if (props.match.params.videoId) {
            setHideNavs(true);
        } else if (hideNavs) {
            setHideNavs(false);
        }
    }, [props.match.params]);

    const responsiveLayoutOptions = {
        0: {items: 1},
        768: {items: 2},
        1024: {items: 4},
        1921: {items: 4},
        2200: {items: 5},
    };

    let handleTimeMonitorStop = () => {

        // ReactGA.event({
        //     category: 'MuroConfianza',
        //     action: 'Click en video o salida de iframe, medimos tiempo transcurrido',
        //     label: TimeMonitor.check(),
        //     value: wall.uuid,
        //     nonInteraction: true
        // });
    }


    let renderVideos = () => {
        return videos.map((video, index) => (
            <div data-value={index + 1}>
                <IframeVideoCard
                    video={video}
                    prevVideo={videos[index - 1]}
                    nextVideo={videos[index + 1]}
                    location={props.location}
                    match={props.match}
                    ctaMessage={wall.ctaMessage}
                    ctaLink={wall.ctaLink}
                    wallUuid={wall.uuid}
                    borderRadius={wall.borderRadius}
                    ctaBorderRadius={wall.ctaBorderRadius}
                    //onClick={handleTimeMonitorStop}
                />
            </div>

        ));
    };


    let renderCarousel = () => {
        return (
            <Grid container id={'sliderContainer'} className={classes.sliderContainer}>
                {/*{!isMobile && !hideNavs && renderPrevArrow()}*/}
                <Grid item xs={12} sm={12} className={classes.carouselWrapper}>
                    <AliceCarousel
                        mouseTracking
                        paddingLeft={isMobile ? 25 : 50}
                        paddingRight={isMobile ? 25 : 50}
                        animationDuration={800}
                        animationType={'slide'}
                        // activeIndex={activeIndex}
                        // onSlideChanged={syncActiveIndex}
                        items={renderVideos()}
                        disableDotsControls
                        // infinite
                        responsive={responsiveLayoutOptions}
                        // disableButtonsControls
                        renderPrevButton={({isDisabled}) => {
                            return (
                                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>

                                    <IconButton
                                        disabled={isDisabled}
                                        // onClick={slidePrev}
                                        className={classes.navigationArrowContainer}>
                                        <img src={ArrowBackIcon} alt={'video-anterior'}
                                             className={isDisabled ? classes.navigationArrowDisabled : classes.navigationArrow}/>
                                    </IconButton>
                                </Box>
                            )

                        }}
                        renderNextButton={({isDisabled}) => {
                            return (
                                <Box style={{display: 'flex', alignItems: 'center'}}>
                                    <IconButton
                                        disabled={isDisabled}
                                        // onClick={slideNext}
                                        className={classes.navigationArrowContainer}>
                                        <img src={ArrowForwardIcon} alt={'video-siguiente'}
                                             className={isDisabled ? classes.navigationArrowDisabled : classes.navigationArrow}/>
                                    </IconButton>
                                    {!isMobile && renderWatermark()}
                                </Box>

                            )
                        }}
                    />
                </Grid>
                {/*{!isMobile && !hideNavs && renderNextArrow()}*/}
                {/*{isMobile && !hideNavs && renderMobileNav()}*/}
                <Grid item xs={12} md={12} className={classes.watermarkContainer}>
                    {isMobile && renderWatermark()}
                </Grid>
            </Grid>

        );
    }

    let renderPrevArrow = () => {
        return (
            <Grid item sm={1} className={classes.arrowPrevContainer}>
                {activeIndex >= 1 &&
                    <IconButton onClick={slidePrev} className={classes.navigationArrowContainer}>
                        <img src={ArrowBackIcon} alt={'video-anterior'} className={classes.navigationArrow}/>
                    </IconButton>
                }
            </Grid>
        );
    }

    let renderNextArrow = () => {
        // console.log('layout -> ', layoutCols);
        // console.log('index -> ', activeIndex);
        // console.log('videos -> ', videos.length);
        // console.log('calculo -> ', (videos.length / layoutCols));
        // console.log('if -> ', (videos.length / layoutCols) > activeIndex);
        return (
            <Grid item sm={1} className={classes.arrowNextContainer}>
                {/*{(activeIndex * layoutCols) <= videos.length &&*/}
                {/*{(videos.length / layoutCols) > activeIndex &&*/}
                {(activeIndex + layoutCols) < videos.length &&
                    <IconButton onClick={slideNext} className={classes.navigationArrowContainer}>
                        <img src={ArrowForwardIcon} alt={'video-siguiente'}
                             className={classes.navigationArrow}/></IconButton>
                }
            </Grid>
        );
    }

    let renderMobileNav = () => {
        return (
            <Grid item xs={12} className={classes.mobileNavigation}>
                <IconButton onClick={slidePrev} disabled={activeIndex === 0}
                            classes={{disabled: classes.navigationArrowContainerDisabled}}>
                    <img src={ArrowBackIcon} alt={'video-anterior'}
                         className={activeIndex === 0 ? classes.navigationArrowDisabled : classes.navigationArrow}/>
                </IconButton>
                <IconButton onClick={slideNext} disabled={activeIndex >= videos.length - 1}>
                    <img src={ArrowForwardIcon} alt={'video-siguiente'}
                         className={activeIndex >= videos.length - 1 ? classes.navigationArrowDisabled : classes.navigationArrow}/>
                </IconButton>
            </Grid>
        );
    }

    let handleIframeGlobalClick = () => {
        ReactGA.event({
            category: 'MuroConfianza',
            action: 'Click iframe',
            label: 'Click en el iframe del muro "' + wall.urlSlug + '"',
            value: wall.uuid,
            nonInteraction: true
        });
        sendStat('global_click', null, wall.uuid, null);

    }


    let renderWatermark = () => {
        if (wall.withIframeWatermark) {
            return (
                <a href={'https://bit.ly/39K2XiF'} target={'_blank'}
                   style={{marginLeft: isMobile ? 0 : 'auto', marginRight: isMobile ? 0 : '2rem'}}>
                    <VerifiedByGrabalo width={isMobile ? '13rem' : '15rem'}/>
                </a>
            )
        }
    }



    return (
        <div className={classes.root}
             onClick={handleIframeGlobalClick}
             id={'grabaloVideowallIframe'+wall.uuid}
        >
            <Fade
                in={!waitingWallData && !fetchError && (isMobile ? wall.viewTypeMobile === 'carousel' : wall.viewType === 'carousel')}
                mountOnEnter unmountOnExit>
                {renderCarousel()}
            </Fade>

            <Fade
                in={!waitingWallData && !fetchError && (isMobile ? wall.viewTypeMobile === 'grid' : wall.viewType === 'grid')}
                mountOnEnter unmountOnExit>
                <Box className={classes.filesGridContainer}>
                    <FilesGrid
                        data={videos}
                        borderRadius={wall.borderRadius}
                        detailModalProps={{
                            ctaMessage: wall.ctaMessage,
                            ctaLink: wall.ctaLink,
                            wallUuid: wall.uuid,
                            borderRadius: wall.borderRadius,
                            ctaBorderRadius: wall.ctaBorderRadius
                        }}
                    />
                </Box>


            </Fade>
            <Fade in={!waitingWallData && !fetchError && wall.withIframeWatermark && wall.viewType === 'grid'} mountOnEnter unmountOnExit>
                <Box style={{marginTop: 15, marginLeft: 'auto', marginRight: isMobile ? 'auto' : 'unset'}}>
                    <br/>
                    <a href={'https://bit.ly/39K2XiF'} target={'_blank'}
                       style={{marginLeft: 'auto', marginRight: isMobile ? 'auto' : 16}}>
                        <VerifiedByGrabalo width={isMobile ? '13rem' : '15rem'}/>
                    </a>
                </Box>
            </Fade>

            <Fade in={waitingWallData} mountOnEnter unmountOnExit>
                <Box style={{marginTop: '13rem'}}><BallPulse loading={true} color={'#000'}/></Box>
            </Fade>

            <Fade in={fetchError} mountOnEnter unmountOnExit>
                <span>Oh oh, parece que no encontramos los archivos que buscabas...</span>
            </Fade>

        </div>
    );
}
