import React from "react";
import importedStyles from './UploadRetoButton.module.sass';
import connect from "react-redux/es/connect/connect";
import {setRequest} from "../../modules/RequestFiles/Redux/requestFilesActions";
import Fab from "@material-ui/core/Fab";
import ReactPixel from "react-facebook-pixel";
import {pushRoute} from "../../helpers/routeMiddleware";

class UploadRetoButton extends React.Component {

    state = {
        windowInitialHeight: 0,
        keyboardOnScreen: false,
    };

    componentDidMount() {
        this.setState({windowInitialHeight: window.innerHeight});
        window.addEventListener('resize', this.hideButtonOnKeyboardShowed);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hideButtonOnKeyboardShowed);
    }

    hideButtonOnKeyboardShowed = () => {
        let actualHeight = window.innerHeight;

        if ((this.state.windowInitialHeight * 0.7) > actualHeight) {//if the current viewport height is smaller than 70% of the original size, it means that the keyboard is up
            this.setState({keyboardOnScreen: true});
        } else if ((this.state.windowInitialHeight * 0.7) < actualHeight) {//if the current viewport height is larger than 70% of the original size, it means that the keyboard is down
            this.setState({keyboardOnScreen: false});
        }
    };

    handleClick = () => {
        ReactPixel.trackCustom('UploadRetoButtonClickMobile', {from: window.location.pathname});
        this.props.dispatch(setRequest(null));
        pushRoute('/newRequest');
    };


    render() {
        if (this.props.logged && (this.props.user && this.props.user.entity && !this.state.keyboardOnScreen)) {
            return (
                <React.Fragment>
                    <div className={importedStyles.floatingButtonContainer}>
                        <a href={'https://form.typeform.com/to/UHyiTutJ'}
                           target={'_blank'} style={{textDecoration: "none"}}>
                            <Fab
                                variant="extended"
                                size={"large"}
                                className={importedStyles.UploadRetoButton}
                                //onClick={this.openForm}
                            >
                                CREAR CONCURSO
                            </Fab>
                        </a>
                    </div>
                </React.Fragment>
            )
        }
        return <div/>;
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)((UploadRetoButton));
