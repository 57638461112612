import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import PropTypes from "prop-types";
import {primaryColor} from "../../styles/palette";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {ReactComponent as PlayIcon} from "../../icons/play_circle_carlos.svg";
import DetailVideoModal from "../../modules/DetailVideoModal/DetailVideoModal";
import DetailVideoModalIframe
    from "../../modules/Videowall/VideowallIframe/DetailVideoModalIframe/DetailVideoModalIframe";
import {sendStat} from "../../helpers/stats/statsAPI";
import {generatePath, useParams, useRouteMatch} from "react-router-dom";
import history from "../../helpers/history";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        paddingTop: 2,
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: 'transparent',
    },
    imageList: {
        width: '100%',
        transform: 'translateZ(0)',
        padding: '2px 14px',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    gridItemListItem: {
        // [theme.breakpoints.only('sm')]: {
        //     height: 180
        // },
        // [theme.breakpoints.only('md')]: {
        //     height: 280
        // },
        // [theme.breakpoints.only('lg')]: {
        //     height: 380
        // },
        borderRadius: (props) => (props.borderRadius === null || props.borderRadius === undefined) ? 0 : props.borderRadius,
        aspectRatio: '9/9'
    },
    gridItemListItemImg: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    mediaTypeIcon: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 200,
        width: '5em',
        height: '5em',
        // filter: 'drop-shadow(1px 2px 3px black)',
        filter: 'drop-shadow(1px 0px -1px black)',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            width: '5em',
            height: '5em',
        },
        // top: 5,
        // right: 5,
        // background: '#000',
        // padding: 4,
        // boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
    }
}));

export const FilesGrid = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();
    let { videowallUrlSlug, videoId } = useParams();
    let match = useRouteMatch();


    const [selectedItem, setSelectedItem] = useState({});


    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    useEffect(() => {
            let selectedItem = props.data.find((item) => {
                return item.urlSlug === videoId
            } );
            setSelectedItem(selectedItem);
    }, [match]);

    const getCols = () => {

        if (isSm) return 2;
        if (isMd) return 3;
        if (isLg) return 4;
        if (isXl) return 5;

        return 6;

    }

    const handleItemClick = (item) => () => {

        // setSelectedItem(item);
        let params = {videowallUrlSlug: videowallUrlSlug, videoId: item.urlSlug};
        let newPath = generatePath(match.path, params);
        history.push(newPath);
    }

    const handleDetailModalClose = (file) => () => {
        sendStat('video_modal_closed', null, props.detailModalProps.wallUuid, file.uuid);
        let params = {videowallUrlSlug: videowallUrlSlug};
        let newPath = generatePath(match.path, params);

        history.push(newPath);
    }


    let handleModalFileNavigation = (nextFile) => () => {
        sendStat('videos_nav', null, props.detailModalProps.wallUuid, nextFile.uuid);

        let params = {videowallUrlSlug: videowallUrlSlug, videoId: nextFile.urlSlug};
        let newPath = generatePath(match.path, params);

        history.push(newPath);
    };

    const renderItems = (item, index) => {

        let files = props.data;
        let index2 = parseInt(index);

        let prevVideo = index2 > 0 && files[index2 - 1];
        let nextVideo = index2 < files.length && files[index2 + 1];

        // eslint-disable-next-line react-hooks/rules-of-hooks


        return (
            <ImageListItem
                key={item.uuid}
                id={'gridItem-'+item.uuid}
                cols={1}
                classes={{
                    item: classes.gridItemListItem,
                    imgFullWidth: classes.gridItemListItemImg
                }}

       >
                    <img src={item.coverUrl} alt={item.title}  onClick={handleItemClick(item)}/>
                    {item.media_type === 'video' && <PlayIcon className={classes.mediaTypeIcon}  onClick={handleItemClick(item)}/>}

                <DetailVideoModalIframe
                    open={selectedItem && selectedItem.uuid === item.uuid}
                    onClose={handleDetailModalClose(item)}
                    video={item}
                    prevVideo={prevVideo}
                    nextVideo={nextVideo}
                    handlePrevVideo={handleModalFileNavigation(prevVideo)}
                    handleNextVideo={handleModalFileNavigation(nextVideo)}
                    ctaLink={props.detailModalProps.ctaLink}
                    ctaMessage={props.detailModalProps.ctaMessage}
                    wallUuid={props.detailModalProps.wallUuid}
                    borderRadius={props.detailModalProps.borderRadius}

                />

            </ImageListItem>
        )
    }

    return (
        <div className={classes.root}>
            <ImageList
                // rowHeight={getRowHeight()}
                rowHeight={'auto'}
                className={classes.imageList}
                cols={getCols()}
                gap={props.gap}
            >
                {props.data.map((item, index) => {return renderItems(item, index)})}
            </ImageList>

        </div>
    );
}

FilesGrid.propTypes = {
    data: PropTypes.array.isRequired,
    gap: PropTypes.number,
    detailModalProps: PropTypes.object,
};

FilesGrid.defaultProps = {
    data: [],
    borderRadius: 0,
    gap: 14,
}
