import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './Favourites.module.sass';
import history from "../../../helpers/history";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import Grid from "@material-ui/core/Grid";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import ReactPixel from "react-facebook-pixel";
import Fade from "@material-ui/core/Fade";
import Skeleton from "react-loading-skeleton";
import VideoCard from "../../../components/VideoCard/VideoCard";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import NoVideosMessage from "../../../components/NoVideosMessage/NoVideosMessage";
import {Helmet} from "react-helmet/es/Helmet";
import {videosPerPage} from "../../../constants/constants";
import PageTitle from "../../../components/PageTitle/PageTitle";
import DownloadAllFilesButton from "../../../components/DownloadAllFilesButton/DownloadAllFilesButton";
import Box from "@material-ui/core/Box";

const isMobile = window.matchMedia("(max-width: 768px)").matches;


class Favourites extends React.Component {

    state = {
        videos: [],
        selectedVideo: null,
        actualVideoIndex: null,
        openDetailVideoModal: false,
        currentPage: 1,
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getFavourites();
        ReactPixel.trackCustom('MyFavourites', {user: this.props.user.uuid});

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleNextVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        ReactPixel.trackCustom('SelectVideo', {selectedVideo: nextVideo.urlSlug});

    };
    handlePrevVideo = () => {
        let nextIndex = parseInt(this.state.actualVideoIndex) - 1;
        let nextVideo = this.state.videos[nextIndex];
        history.replace(nextVideo.urlSlug);
        this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});
        ReactPixel.trackCustom('SelectVideo', {selectedVideo: nextVideo.urlSlug});
    };

    updateVideoVotes = async (numLikes) => {
        let newVideos = [...this.state.videos];
        let newVideo = {...this.state.videos[this.state.actualVideoIndex]};
        // newVideo.votes_on_request = newVotes;
        newVideo.pivot.likes = numLikes;
        newVideos[this.state.actualVideoIndex] = newVideo;
        await this.setState({videos: newVideos})
    };


    getFavourites = () => {

        this.setState({
            waitingResponse: true
        });

        let entityId = this.props.user.uuid;

        fetch(apiBaseUrl + 'favorites/listFavorites', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({entityId: entityId, currentPage: this.state.currentPage})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({
                            videos: response.favorites,
                            totalVideosCount: response.totalVideosCount,
                            waitingResponse: false,
                        });
                    } else {
                        this.setState({waitingResponse: false});
                        this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al cargar los datos, vuelva a intentarlo', snackTypes.error));
                }
            )
    };

    renderSkeletons = () => {

        let skeletons = [];

        for (let i = 0; i < 6; i++) {
            skeletons.push(
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2} className={importedStyles.skeletonContainer}>
                    <Skeleton className={importedStyles.skeletonBox} height={'100%'} width={'100%'}/>
                </Grid>
            )
        }

        return (
            <Fade direction={'top'} unmountOnExit mountOnEnter in={this.state.waitingResponse}>
                <React.Fragment>
                    {skeletons}
                </React.Fragment>
            </Fade>
        );
    };

    renderVideos = () => {
        const videos = this.state.videos;
        let videoCards = [];

        let i = 1;
        Object.keys(videos).forEach((key) => {

            let index = parseInt(key);
            let prevVideo = index > 0 && videos[index-1];
            let nextVideo = index < videos.length && videos[index+1];

            i < 10 && i++; //Increment counter if actual video is below the 10th first

            let video = videos[key];
            let tagsView = [];

            let videoIsFree = parseInt(video.price) <= 0;
            Object.keys(video.tags).forEach((indexTag) => {
                if (video.tags[indexTag].text.length < 15) {
                    tagsView.push(<p key={indexTag}>{videos[key].tags[indexTag].text}</p>);
                }
            });


            videoCards.push(
                <Fade unmountOnExit mountOnEnter in={!this.state.waitingResponse}
                      timeout={{enter: i * 200, exit: 0}}>
                    <Grid className={importedStyles.videoCard} key={key} item xs={12} sm={6} md={3} lg={3} xl={2}>
                        <VideoCard
                            video={video}
                            title={video.title}
                            author={video.user ? video.user.name : video.name}
                            image={video.coverUrl}
                            withLikes={false}
                            withShare={false}
                            withFavorite={true}
                            withDownload={true}
                            withConfidenceWall={true}
                            location={this.props.location}
                            match={this.props.match}
                            prevVideo={prevVideo}
                            nextVideo={nextVideo}
                        />
                    </Grid>
                </Fade>
            )
        });


        if (videoCards.length === 0 && !this.state.waitingResponse) {
            return (
                <Grid className={importedStyles.noVideos} key={'no videos'} item xs={12}>
                    <NoVideosMessage/>
                </Grid>
            );
        } else {
            return videoCards;
        }
    };
    renderPagination = () => {
        let totalPages = parseInt(this.state.totalVideosCount / videosPerPage ).toString();
        let isMobile = window.matchMedia("(max-width: 768px)").matches ? 'small' : 'medium';
        return (
            <Grid item xs={12} className={importedStyles.paginatorContainer}>
                <Pagination
                    count={totalPages}
                    page={this.state.currentPage} onChange={this.handlePageChange}
                    boundaryCount={1}
                    siblingCount={1}
                    size={isMobile}
                    showFirstButton
                    showLastButton
                />
            </Grid>
        )
    };
    handlePageChange = (event, page) => {
        this.setState({currentPage: page}, () => {
            this.getFavourites();
        });
    };


    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Favoritos</title>
                </Helmet>
                <NavBar/>
                <DesktopDrawer/>
                <div className={importedStyles.favourites}>
                    <PageTitle title={'Favoritos'} subtitle={'Aquí encontrarás todos los archivos que has marcado como favoritos. Puedes descargarlos tantas veces como necesites.'} />
                    <Box className={importedStyles.actions}>
                        <DownloadAllFilesButton from={'Favoritos'} user={this.props.user} className={importedStyles.downloadAllFilesButton} key={0}  fullWidth={isMobile}/>
                    </Box>

                    <Grid container spacing={isMobile ? 0 : 2} className={importedStyles.videosContainer}>
                        {this.renderSkeletons()}
                        {this.state.videos && this.renderVideos()}
                        {this.renderPagination()}
                    </Grid>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(Favourites);
