import apiBaseUrl from "../../../helpers/apiBaseUrl";
import * as FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { DeviceUUID } from 'device-uuid';


export function deleteVideo(videoId) {
    return fetch(apiBaseUrl + 'videos/deleteVideo', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({videoId})
    });
}


export async function voteVideo(requestUuid, videoUuid) {

    let visitorFingerprint = localStorage.getItem("grabaloUserFp");

    if (!visitorFingerprint) {
        try {
            let result = await window.fingerprint.get();
            visitorFingerprint = result.visitorId;



            localStorage.setItem("grabaloUserFp", visitorFingerprint); //store fp in localstorage to avoid use more fp api request when no needed
        } catch (error) {
            switch (error.message) {
                case FingerprintJS.ERROR_GENERAL_SERVER_FAILURE:
                    console.error('Fingerprint error -> Unknown server error. Request id:', error.requestId)
                    break
                case FingerprintJS.ERROR_CLIENT_TIMEOUT:
                    console.log('Fingerprint error -> Identification time limit of 10 seconds is exceeded')
                    break
                default:
                    console.log('Fingerprint error -> Unknown/Other error');
                    break;
            }
            visitorFingerprint = new DeviceUUID().get();
        }
    }


    return fetch(apiBaseUrl + 'vote/create', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            requestUuid: requestUuid,
            fileUuid: videoUuid,
            visitorFingerprint: visitorFingerprint
        })
    })
}

export async function unvoteVideo(requestUuid, videoUuid) {

    let visitorFingerprint = localStorage.getItem("grabaloUserFp");

    if (!visitorFingerprint) {
        try {
            let result = await window.fingerprint.get();
            visitorFingerprint = result.visitorId;
            localStorage.setItem("grabaloUserFp", visitorFingerprint); //store fp in localstorage to avoid use more fp api request when no needed
        } catch (error) {
            switch (error.message) {
                case FingerprintJS.ERROR_GENERAL_SERVER_FAILURE:
                    console.error('Fingerprint error -> Unknown server error. Request id:', error.requestId)
                    break;
                case FingerprintJS.ERROR_CLIENT_TIMEOUT:
                    console.log('Fingerprint error -> Identification time limit of 10 seconds is exceeded')
                    break;
                default:
                    console.log('Fingerprint error -> Unknown/Other error')
                    break;
            }
            visitorFingerprint = new DeviceUUID().get();

        }
    }

    return fetch(apiBaseUrl + 'requests/deleteRequestVideoVote', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            requestUuid: requestUuid,
            videoUuid: videoUuid,
            visitorFingerprint: visitorFingerprint
        })
    })

}


export async function voteEvent(scheduleIdentifier, eventIdentifier) {

    let visitorFingerprint = localStorage.getItem("grabaloUserFp");

    if (!visitorFingerprint) {
        try {
            let result = await window.fingerprint.get();
            visitorFingerprint = result.visitorId;



            localStorage.setItem("grabaloUserFp", visitorFingerprint); //store fp in localstorage to avoid use more fp api request when no needed
        } catch (error) {
            switch (error.message) {
                case FingerprintJS.ERROR_GENERAL_SERVER_FAILURE:
                    console.error('Fingerprint error -> Unknown server error. Request id:', error.requestId)
                    break
                case FingerprintJS.ERROR_CLIENT_TIMEOUT:
                    console.log('Fingerprint error -> Identification time limit of 10 seconds is exceeded')
                    break
                default:
                    console.log('Fingerprint error -> Unknown/Other error');
                    break;
            }
            visitorFingerprint = new DeviceUUID().get();
        }
    }


    return fetch(apiBaseUrl + 'schedule/events/vote', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            eventId: eventIdentifier,
            scheduleId: scheduleIdentifier,
            visitorFingerprint: visitorFingerprint
        })
    })
}

export async function unvoteEvent(scheduleIdentifier, eventIdentifier) {

    let visitorFingerprint = localStorage.getItem("grabaloUserFp");

    if (!visitorFingerprint) {
        try {
            let result = await window.fingerprint.get();
            visitorFingerprint = result.visitorId;
            localStorage.setItem("grabaloUserFp", visitorFingerprint); //store fp in localstorage to avoid use more fp api request when no needed
        } catch (error) {
            switch (error.message) {
                case FingerprintJS.ERROR_GENERAL_SERVER_FAILURE:
                    console.error('Fingerprint error -> Unknown server error. Request id:', error.requestId)
                    break;
                case FingerprintJS.ERROR_CLIENT_TIMEOUT:
                    console.log('Fingerprint error -> Identification time limit of 10 seconds is exceeded')
                    break;
                default:
                    console.log('Fingerprint error -> Unknown/Other error')
                    break;
            }
            visitorFingerprint = new DeviceUUID().get();

        }
    }

    return fetch(apiBaseUrl + 'schedule/events/unvote', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            eventId: eventIdentifier,
            scheduleId: scheduleIdentifier,
            visitorFingerprint: visitorFingerprint
        })
    })

}
