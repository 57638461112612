import React, {useState} from "react";
import styles from './EntityLogin.module.sass';
import TextField from "@material-ui/core/TextField";
import {Avatar, CircularProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import history from "../../../helpers/history";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import grabaloIcon from '../../../images/grabalo-icon.png';
import {ReactComponent as GrabaloIcon} from "../../../icons/simbolo_grabalo_circulo_relleno.svg";

import {login} from "../../Auth/authActions";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import {useDispatch} from "react-redux";
import {Helmet} from "react-helmet/es/Helmet";

export default function EntityLogin() {

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [waitingLoginResponse, setWaitingLoginResponse] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async () => {

        setEmailError(false);
        setPasswordError(false);
        setWaitingLoginResponse(true);

        try{
            let response = await fetch(apiBaseUrl + 'auth/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email, password})
            });

            response = await response.json();

            setWaitingLoginResponse(false);

            if (!response.error && response.user){
                setEmailError(false);
                setPasswordError(false);
                await dispatch(login(response.user));
                history.push('/mis-eventos?business-login');
            }

            if (response.error){
                switch (response.errorType) {
                    case 'badEmail':
                        setEmailError(true);
                        dispatch(showSnack('Error en la autenticación, no existe esta cuenta', snackTypes.error));
                        break;
                    case 'badPassword':
                        setPasswordError(true);
                        dispatch(showSnack('Error en la autenticación, contraseña incorrecta', snackTypes.error));
                        break;
                    case 'notVerified':
                        dispatch(showSnack('Error en la autenticación, cuenta no verificada', snackTypes.error));
                        break;
                    default:
                        dispatch(showSnack('Error en la autenticación, vuelva a intentarlo', snackTypes.error));
                        break;
                }
            }
        } catch (error){
            setWaitingLoginResponse(false);
            dispatch(showSnack('Error inesperado, espere unos minutos y vuelva a intentarlo', snackTypes.error));
            console.error(error)
        }

    };


    // Render
    return (
        <React.Fragment>
            <Helmet>
                <title>Grábalo - LogIn</title>
            </Helmet>
            <div className={styles.root}>
                <div className={styles.formCard}>
                    {/*<Avatar src={grabaloIcon} className={styles.logo}/>*/}
                    <GrabaloIcon  className={styles.logo}/>
                    <div className={styles.formCardContent}>
                        <form className={styles.form}>
                            <TextField
                                margin="normal"
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                type="text"
                                inputProps={{maxLength: 50}}
                                value={email}
                                error={emailError}
                                helperText={emailError && 'No existe ninguna cuenta con este correo'}
                                fullWidth
                                onChange={handleEmailChange}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        document.getElementById('loginButton').click();
                                    }
                                }}
                            />
                            <TextField
                                margin="normal"
                                id="password"
                                name="password"
                                label="Contraseña"
                                type="password"
                                inputProps={{maxLength: 50}}
                                value={password}
                                fullWidth
                                error={passwordError}
                                helperText={passwordError && 'La contraseña introducida no es correcta'}
                                onChange={handlePasswordChange}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        document.getElementById('loginButton').click();
                                    }
                                }}
                            />
                        </form>
                        <Button
                            id={'loginButton'}
                            className={styles.loginButton}
                            variant='contained'
                            disabled={waitingLoginResponse || email.length <= 0 || password.length <= 0}
                            onClick={handleLogin}
                            fullWidth
                        >
                            Acceder
                            {waitingLoginResponse && <CircularProgress size={25} className={styles.circularProgress}/>}
                        </Button>

                        <div className={styles.footerTextContainer}>
                      <span>¿Has olvidado tu contraseña?
                          <span className={styles.newPasswordLink}
                                onClick={() => history.push('/recuperar-password')}>Pide otra</span>
                      </span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}



