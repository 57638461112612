import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import importedStyles from './ContactUs.module.sass';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import history from "../../helpers/history";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import UploadVideoButton from "../../components/UploadVideoButton/UploadVideoButton";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import Snack from "../../components/Snack/Snack";
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import SuggestedLoginModal from "../../components/SuggestedLoginModal/SuggestedLoginModal";
import ContactIllustration from "../../images/contact-illustration-min.png";
import ContactIllustrationWebp from "../../images/contact-illustration-min.webp";
import {isWebpSupported} from "react-image-webp/dist/utils";
import {Helmet} from "react-helmet/es/Helmet";
import PageTitle from "../../components/PageTitle/PageTitle";

class ContactUs extends React.Component {

    state = {
        subject: '',
        message: '',
        email: '',
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        ReactPixel.trackCustom('ContactUsView', {user: this.props.user ? this.props.user.uuid : 'no logueado'});
        ReactGA.pageview(window.location.pathname);
    };

    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    handleSendContact = () => {
        this.setState({
            waitingResponse: true
        });

        let subject = this.state.subject;
        let message = this.state.message;
        let uuid = this.props.user ? this.props.user.uuid : null;
        let email = this.state.email;

        fetch(apiBaseUrl + 'mail/contactUs', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({subject, message, uuid, email})

        }).then(res => res.json())
            .then(
                (response) => {
                    if (response.error === false) {
                        this.setState({
                            title: '',
                            message: '',
                            waitingResponse: false,
                        });
                        ReactPixel.trackCustom('ContactUsSended', {user: uuid});
                        this.props.dispatch(showSnack('Gracias por tu mensaje', snackTypes.success));
                        history.push('/categories');
                    } else {
                        switch (response.errorType) {
                            case 'badEmail':
                                this.setState({authEmailError: true, waitingResponse: false});
                                break;
                            case 'badPassword':
                                this.setState({authPasswordError: true, waitingResponse: false});
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error al enviar el mensaje, vuelva a intentarlo', snackTypes.error));
                                break;
                        }
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    this.props.dispatch(showSnack('Error al enviar el mensaje, vuelva a intentarlo', snackTypes.error));
                }
            )
    };

    renderEmailInput = () => {
        return(
            <TextField
                margin="normal"
                id="email"
                name="email"
                label="Correo"
                type="email"
                inputProps={{maxLength: 120}}
                value={this.state.email}
                fullWidth
                onChange={this.handleInputChange}
                onKeyPress={(e) => {
                    if (e.which === 13) {
                        document.getElementById('sendButton').click();
                    }
                }}
            />
        )
    };

    renderForm = () => {
        return (
            <React.Fragment>
                <div className={importedStyles.form}>
                    {!this.props.logged && this.renderEmailInput()}
                    <TextField
                        margin="normal"
                        id="subject"
                        name="subject"
                        label="Asunto"
                        type="text"
                        inputProps={{maxLength: 100}}
                        value={this.state.subject}
                        fullWidth
                        onChange={this.handleInputChange}
                        onKeyPress={(e) => {
                            if (e.which === 13) {
                                document.getElementById('sendButton').click();
                            }
                        }}
                    />
                    <TextField
                        margin="normal"
                        id="message"
                        name="message"
                        label="Mensaje"
                        type="text"
                        multiline
                        inputProps={{maxLength: 1000}}
                        value={this.state.message}
                        fullWidth
                        onChange={this.handleInputChange}
                        onKeyPress={(e) => {
                            if (e.which === 13) {
                                document.getElementById('sendButton').click();
                            }
                        }}
                    />

                    <Button
                        id={'sendButton'}
                        className={importedStyles.submitButton}
                        variant='contained'
                        //fullWidth
                        onClick={this.handleSendContact}
                        disabled={!this.state.message || !this.state.subject || this.state.waitingResponse || (!this.props.logged && !this.state.email)}
                    >
                        ENVIAR
                        {this.state.waitingResponse &&
                        <CircularProgress size={25} className={importedStyles.circularProgress}/>}
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Contacto</title>
                </Helmet>
                <NavBar/>
                <UploadVideoButton/>
                <Snack/>
                <DesktopDrawer/>
                <div className={importedStyles.ContactUs}>
                    <PageTitle title={'Contacto'} subtitle={'¿Cómo podemos echarte una mano?'} />

                    <div className={importedStyles.content}>
                        {this.renderForm()}
                        <img
                            src={isWebpSupported() ? ContactIllustrationWebp : ContactIllustration}
                            className={importedStyles.contactIllustration}
                            alt={'contact background illustration'}
                        />
                    </div>

                </div>
                <SuggestedLoginModal/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(ContactUs);
