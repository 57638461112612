import React from "react";
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import importedStyles from './VideoCard.module.sass';
import Card from "@material-ui/core/es/Card/Card";
import DownloadIcon from '@material-ui/icons/SaveAltRounded';
import DownloadIcon2 from '@material-ui/icons/CloudDownload';
import BookmarkIconFilled from '@material-ui/icons/BookmarksRounded';
import BookmarkIconOutlined from '@material-ui/icons/BookmarksOutlined';
import {Avatar, CardContent, CircularProgress, Divider, Fade, ListItem, ListItemText, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CardMedia from "@material-ui/core/CardMedia";
import {showSnack, snackTypes} from "../Snack/SnackActions";
import {ReactComponent as PlayIcon} from "../../icons/play_circle_carlos.svg";
import {ReactComponent as PlayIconNoCircle} from "../../icons/play_circle_no_border.svg";
import CardActions from "@material-ui/core/CardActions";
import {ReactComponent as LikeIcon} from "../../icons/heart_v2.svg";
// import LikeIcon from '@material-ui/icons/FavoriteBorderOutlined';
import LikeIconFilled from "@material-ui/icons/FavoriteRounded";
import ShareModal from "../ShareModal/ShareModal";
import {unvoteVideo, voteVideo} from "../../modules/Concurso/fetch/video";
import ReactGA from "react-ga";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import ReactPixel from "react-facebook-pixel";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/CloseRounded";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {ReactComponent as WallOutlinedIcon} from "../../icons/wallOutlinedIcon.svg";
import {ReactComponent as WallIconFilled} from "../../icons/wallIcon.svg";
import DetailVideoModal from "../../modules/DetailVideoModal/DetailVideoModal";
import history from "../../helpers/history";
import {isVideoFavorite, isVideoLiked} from "../../modules/Concurso/helpers";
import {isSafari} from 'react-device-detect';
import {generatePath} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import {InView} from "react-intersection-observer";
import moment from "moment";
import {toast} from "react-hot-toast";
import clsx from "clsx";
// import {ReactComponent as ShareArrow} from "../../icons/share_arrow_v2.svg";
import {ReactComponent as ShareArrow} from "../../icons/share_arrow.svg";
import {redColor} from "../../styles/palette";
import defaultAudioCoverImg from '../../icons/wave-sound-blue.png';

// Flag to check if it's a mobile screen
const isMobile = window.matchMedia("(max-width: 768px)").matches;



class VideoCard extends React.Component {

    state = {
        video: {},
        expand: false,
        likesCount: 0,
        isFavorite: false,
        isLiked: false,
        isOnWall: false,
        firstOpen: true,
        shareModal: false,
        urlShare: '',
        videoLoading: false,
        fallbackUrl: '',
        openDownloadsModal: false,
        waitingResponseFavorite: false,
        waitingForDownloadLinks: false,
        openDetailVideoModal: false,
        thumbnail: this.props.image,
        loadingAnimatedThumbnail: false,
    };


// LIFECYCLES
    componentDidMount = async () => {
        window.addEventListener("localStorageChange", this.forceComponentUpdate);
        let likes = this.props.video.pivot ? this.props.video.pivot.likes : 0;
        this.setState({
            video: this.props.video,
            likesCount: likes, isFavorite: isVideoFavorite(this.props.video),
            isOnWall: this.props.video.isOnWall,
            isLiked: this.props.request ? isVideoLiked(this.props.video, this.props.request.uuid) : false,
            openDetailVideoModal: this.props.openDetailVideoModal
        });

        // set default 'audio' cover image
        // if (this.props.video.media_type === 'audio') {
        //     this.setState({thumbnail: defaultAudioCoverImg});
        // }

        this.generateSharePath();
        this.handleUrlVideoParam();

        //this.props.withFavorite && this.checkVideoIsFavorite();
        //this.props.withDownload && await this.retrieveVideoQualityDownloadUrls();

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.videoId !== prevProps.match.params.videoId) { //If actual videoId differs from previous one, must handle it
            this.handleUrlVideoParam();
            this.generateSharePath();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("localStorageChange", this.forceComponentUpdate);
    }

///////////////////////

    checkVideoIsFavorite = () => {
        if (this.props.user && this.props.user.entity) {
            let isFavorite = this.props.video.favourite_videos && this.props.video.favourite_videos.length > 0;
            this.setState({isFavorite: isFavorite});
        }
    }

    handleUrlVideoParam = () => {
        // console.log(this.props.match.params.videoId);
        // console.log(this.props.video.urlSlug);
        // console.log('--------------------------------');

        if (this.props.match.params.videoId === this.props.video.urlSlug) {

            // let videosCollection = this.props.videos;
            // let indexOfRequestedVideo = videosCollection.findIndex(item => item.urlSlug === urlVideoId);
            // let video = videosCollection[indexOfRequestedVideo];
            this.setState({openDetailVideoModal: true});

            // this.props.dispatch(setVideo(video));
        } else if (this.state.openDetailVideoModal) {

            setTimeout(() => {
                this.setState({openDetailVideoModal: false});
            }, isMobile ? 200 : 700); //set a 100-700ms to avoid flicker visual effect when this modal closes and next appears
        }

    };

    retrieveVideoQualityDownloadUrls = async () => {

        this.setState({waitingForDownloadLinks: true});
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.vimeoId;

        try {
            let response = await fetch(apiBaseUrl + 'downloads/retrieveVideoDownload', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({videoId: videoId, entityId: entityId}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            let rrssBestResolution = {height: 0, width: 0};
            response.downloadLinks.forEach((videoQuality) => {
                if ((videoQuality.width === 720 && videoQuality.height === 1280) || (videoQuality.height === 720 && videoQuality.width === 1280)) {
                    rrssBestResolution = videoQuality;
                    this.setState({url720p: videoQuality.link});
                }
                if (videoQuality.quality === 'source') {
                    this.setState({fallbackUrl: videoQuality.link});
                }
                // switch (true) {
                //     case videoQuality.height <= 360:
                //         this.setState({url360p: videoQuality.link});
                //         break;
                //     case videoQuality.height <= 480:
                //         this.setState({url480p: videoQuality.link});
                //         break;
                //     case videoQuality.height <= 720:
                //         this.setState({url720p: videoQuality.link});
                //         break;
                //     case videoQuality.height <= 1080:
                //         this.setState({url1080p: videoQuality.link});
                //         break;
                //     case videoQuality.height <= 1440:
                //         this.setState({url2k: videoQuality.link});
                //         break;
                //     case videoQuality.height <= 2160:
                //         this.setState({url4k: videoQuality.link});
                //         break;
                //
                //     default:
                //         break;
                // }
            });

            this.setState({waitingForDownloadLinks: false});

        } catch (error) {
            this.setState({waitingForDownloadLinks: false});
            console.error('Error: ', error);
        }
    };
    addDownloadRegister = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;
        ReactPixel.trackCustom('VideoDownload', {video: this.props.video.urlSlug});
        fetch(apiBaseUrl + 'downloads/addDownloadRegister', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({videoId: videoId, entityId: entityId, isMarkedVideo: false})

        }).then(res => res.json())
            .then((response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    }
                }, (error) => {
                    throw new Error(error);
                }
            ).catch(error => {
                console.log('Error: ' + error);
            }
        );
    }


    downloadImageToBrowser = async () => {
        this.setState({waitingForDownloadLinks: true});
        try {
            const image = await fetch(apiBaseUrl + 'images/downloadImage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({imageUrl: this.props.video.url})

            });
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);

            const link = document.createElement('a');
            link.href = imageURL;
            // link.target = '_blank';
            link.download = this.props.video.title;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({waitingForDownloadLinks: false});

        } catch (err) {
            this.setState({waitingForDownloadLinks: false});
            console.log('Error en descarga de imagen: ' + err);
            this.props.dispatch(showSnack('Error al generar tu descarga', snackTypes.error));
        }

    }
    handleDownload = async (e) => {
        e.stopPropagation();
        this.addDownloadRegister();

        if (this.props.video.media_type === 'video') {
            await this.retrieveVideoQualityDownloadUrls();
            if (this.state.url720p || isSafari) {
                this.handleDownloadsModal();
            } else {
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = this.state.fallbackUrl;
                a.target = '_blank';
                a.download = this.props.video.title;
                a.click();
                document.body.removeChild(a);
                // let win = window.open(this.state.fallbackUrl, "_blank");
                // win.focus();
            }
        } else {
            await this.downloadImageToBrowser();
        }

    };


// Handles
    handleShare = (e) => {
        e.stopPropagation();

        ReactGA.event({
            category: 'click',
            action: 'shareIntention',
            label: this.props.video.uuid
        });

        if (isMobile && navigator.share) {
            navigator
                .share({
                    //title: document.title,
                    //text: '',
                    //url: window.location + '/' + this.props.video.urlSlug
                    url: this.state.urlShare
                })
                .then(() => {
                    ReactGA.event({
                        category: 'share',
                        action: 'shareViaMobile',
                        label: this.props.video.uuid
                    });
                    console.log('Compartido con éxito! 🎉')
                })
                .catch(err => {
                    ReactGA.event({
                        category: 'share',
                        action: 'errorShareMobile',
                        label: this.props.video.uuid
                    });
                    console.error(err)
                });
        } else {
            this.setState((prevState, prevProps) => {
                return {
                    shareModal: !prevState.shareModal
                }
            });
        }

    };

    handleDownloadsModal = () => {
        this.setState((prevState, prevProps) => {
            return {openDownloadsModal: !prevState.openDownloadsModal}
        });
    };

    closeDetailVideoModal = () => {
        let video = this.props.video;

        sessionStorage.removeItem("mobileAdPopupShowed-ChallengeId:" + this.props.request.uuid);

        this.setState({openDetailVideoModal: false});
        history.replace(this.props.location.pathname.replace('/' + video.urlSlug + history.location.search, ''));
    };
    handleDetailVideoModal = () => {
        this.setState({openDetailVideoModal: true});
        // ReactPixel.trackCustom('ClickOnVideoFromConfidenceWall', {video: this.props.video.uuid});
        history.push(this.props.location.pathname + '/' + this.props.video.urlSlug + history.location.search);

    };

    handleNextVideo = () => {
        // let nextIndex = parseInt(this.state.actualVideoIndex) + 1;
        // let nextVideo = this.state.videos[nextIndex];
        // this.setState({selectedVideo: nextVideo, actualVideoIndex: nextIndex});

        console.log(this.props.nextVideo);
        // use redux to get index and videosList
    };
    handlePrevVideo = () => {

    }

///////////////////

// RENDERS

    forceComponentUpdate = () => {
        this.forceUpdate();
    }

    renderCTAButton = () => {



        return (
            <Button
                className={clsx(importedStyles.showMoreBtn, (!this.props.withLikes && !this.props.withShare) && importedStyles.showMoreBtnFullwidth)}
                // className={importedStyles.showMoreBtn}
                // fullWidth={!this.props.withLikes && !this.props.withShare}
            >

                {localStorage.getItem('grabalo-user-language') === 'vlc' ? 'Vore' : 'Ver'}
            </Button>
        )

    };
    renderLikeActions = () => {

        let disableButton = false;
        if (this.props.request.likeable_date_limit) {
            // console.info(moment(this.props.request.likeable_date_limit))
            // console.info(moment())
            // console.info( moment(this.props.request.likeable_date_limit).isAfter(moment()))
            // console.info( '---------------------')
            disableButton = moment(this.props.request.likeable_date_limit).isBefore(moment());
        }

        let clickFunction = async (e) => {
            // this.props.updateIndexVideo();
            e.stopPropagation();
            this.state.isLiked ? await this.undoVote() : await this.voteVideo();
        };
        let disabledClickFunction = async (e) => {
            e.stopPropagation();
            let toastOptions = {
                id: "toast-vote-limit-date",
                style: {
                    color: '#fff',
                    backgroundColor: '#b94343',
                    fontWeight: 300,
                    padding: '8px 20px 8px 0px',
                    textAlign: 'center'
                },
                iconTheme: {
                    primary: '#fff',
                    secondary: '#b94343',
                },
                icon: '',
                position: "bottom-left",
                duration: 3000
            };

            if (isMobile) {
                toastOptions.position = "bottom-center";
            }

            toast.error('Lo sentimos. Ya no se puede votar, se acabó el tiempo de las votaciones.', toastOptions);
        };

        return (
            <Tooltip title={this.props.isLiked ? 'Quitar voto' : 'Votar'} placement={'top'}>

                <Button
                    disableRipple
                    className={importedStyles.alternativeLikeButton}
                    onClick={disableButton ? disabledClickFunction : clickFunction}
                    classes={{label: importedStyles.alternativeLikeButton}}
                >
                    <div className={importedStyles.actionButtonCounterWrapper}>
                        {this.state.isLiked ? <LikeIconFilled className={importedStyles.alternativeActionIcon}
                                                              style={{color: redColor, fontSize: 25}}/> :
                            <LikeIcon className={importedStyles.alternativeActionIcon}
                                      style={{height: 20, width: 20, marginRight: 2}}/>}

                        <small className={importedStyles.alternativeActionCounter}>{this.state.likesCount}</small>
                    </div>

                    <span className={importedStyles.actionTitle}>votar</span>

                </Button>
            </Tooltip>
        )

    };
    renderShareActions = () => {
        return (
            <Tooltip title={'Compartir'} placement={'top'}>

                <IconButton className={importedStyles.alternativeActionButton} onClick={this.handleShare} disableRipple>
                    {/*<ShareIcon className={importedStyles.alternativeActionIcon}/>*/}
                    <div className={importedStyles.shareIconWrapper}>

                        <ShareArrow className={importedStyles.shareIcon}/>
                        <span className={importedStyles.actionTitle}>compartir</span>
                    </div>


                    {/*  <small className={importedStyles.actionCounter}>{this.props.video.sharedCount || 0}</small>*/}
                </IconButton>
            </Tooltip>
        )
    };
    renderDownloadActions = () => {
        return (
            <Tooltip title={'Descargar'} placement={'top'}>

                <IconButton
                    disabled={this.state.waitingForDownloadLinks}
                    onClick={this.handleDownload}
                    // href={this.state.fallbackUrl}
                    // target={'_blank'}
                    className={importedStyles.actionButton}
                >
                    {this.state.waitingForDownloadLinks &&
                        <CircularProgress size={18} className={importedStyles.waitingLinkCircle}/>}
                    {!this.state.waitingForDownloadLinks &&
                        <DownloadIcon className={[importedStyles.actionIcon, importedStyles.primaryColor]}/>}
                </IconButton>
            </Tooltip>
        )
    };

    renderDownloadsModal = () => {
        let fullScreen = window.matchMedia("(max-width: 768px)").matches;
        return (
            <Dialog
                open={this.state.openDownloadsModal}
                onClose={this.handleDownloadsModal}
                aria-labelledby="Download buttons modal"
                fullWidth
                maxWidth={"sm"}
                fullScreen={fullScreen}
                classes={{paper: importedStyles.downloadLinksModalRoot}}
            >
                <IconButton className={importedStyles.closeModalDownloadIcon} onClick={this.handleDownloadsModal}>
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>
                    Descarga
                </DialogTitle>
                <DialogContent>
                    <div className={importedStyles.downloadsModalContent}>
                        {!isSafari && this.renderDownloadButtons()}
                        {isSafari && this.renderSafariDownloadButtons()}
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    renderDownloadButtons = () => {

        return (
            <React.Fragment>
                <Button key={'sourceQuality'} variant={"outlined"} href={this.state.fallbackUrl}
                        className={importedStyles.qualityDownloadButton}>
                    <DownloadIcon2 className={importedStyles.downloadIcon}/> Archivo original (calidad total)
                </Button>
                {this.state.url720p != null &&
                    <Button key={'720p'} variant={"outlined"} href={this.state.url720p}
                            className={importedStyles.qualityDownloadButton}>
                        <DownloadIcon2 className={importedStyles.downloadIcon}/> Archivo para RRSS (menor calidad)
                    </Button>
                }

                {this.state.waitingForDownloadLinks && <CircularProgress size={30} style={{marginLeft: '1rem'}}/>}
            </React.Fragment>
        );

    };
    renderSafariDownloadButtons = () => {
        return (
            <React.Fragment>
                <Button key={'sourceQuality'} variant={"outlined"} href={this.state.fallbackUrl}
                        className={importedStyles.qualityDownloadButton}>
                    Original<DownloadIcon className={importedStyles.downloadIcon}/>
                </Button>
                {this.state.url720p != null &&
                    <Button key={'720p'} variant={"outlined"} href={this.state.url720p}
                            className={importedStyles.qualityDownloadButton}>
                        RRSS (Menor calidad) <DownloadIcon className={importedStyles.downloadIcon}/>
                    </Button>
                }

                {this.state.waitingForDownloadLinks && <CircularProgress size={30} style={{marginLeft: '1rem'}}/>}
            </React.Fragment>
        );

    };

    /* ---- FAV SYSTEM ---- */
    favVideo = () => {
        let entityId = this.props.user.uuid;
        let videoId = this.props.video.uuid;

        let apiCall = this.state.isFavorite ? 'favorites/removeFavorite' : 'favorites/addFavorite';
        let action = this.state.isFavorite ? 'remove' : 'add';

        let data = JSON.stringify({videoId: videoId, entityId: entityId});


        this.setState((prevState, prevProps) => {
            return {isFavorite: !prevState.isFavorite}
        });

        fetch(apiBaseUrl + apiCall, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: data
        }).then(res => res.json())
            .then(
                (response) => {
                    if (response.error) throw new Error(response.errorMessage);
                    this.props.updateParentVideoFav && this.props.updateParentVideoFav(action);
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.props.dispatch(showSnack('Error al añadir a favoritos', snackTypes.error));

                    // Revert if fails
                    this.setState((prevState, prevProps) => {
                        return {isFavorite: !prevState.isFavorite}
                    });
                }
            )
            .catch((error) => {
                console.log('Error: ' + error);
                this.props.dispatch(showSnack('Error al añadir a favoritos', snackTypes.error));
                // Revert if fails
                this.setState((prevState, prevProps) => {
                    return {isFavorite: !prevState.isFavorite}
                });
            })
    };
    renderFavActions = () => {

        let clickFunction = async (e) => {
            // this.props.updateIndexVideo();
            e.stopPropagation();
            await this.favVideo();
        };

        return (
            <Tooltip title={'Favorito'} placement={'top'}>

                <IconButton
                    className={this.state.isFavorite ? importedStyles.actionButtonFilled : importedStyles.actionButton}
                    onClick={clickFunction}>
                    {this.state.isFavorite &&
                        <BookmarkIconFilled className={[importedStyles.actionIcon, importedStyles.primaryColor]}/>}
                    {!this.state.isFavorite &&
                        <BookmarkIconOutlined className={[importedStyles.actionIcon, importedStyles.primaryColor]}/>}
                </IconButton>
            </Tooltip>
        )

    };

    /* ---- END FAV SYSTEM ---- */

    /* ---- WALL SYSTEM ---- */
    toWallVideo = () => {
        let videoWallUuid = this.props.user.videowalls ? this.props.user.videowalls[0].uuid : '';

        let videoId = this.props.video.uuid;

        let apiCall = this.state.isOnWall ? 'videowall/detachVideo' : 'videowall/attachVideo';
        let action = this.state.isOnWall ? 'remove' : 'add';

        let data = JSON.stringify({videoUuid: videoId, videowallUuid: videoWallUuid});


        this.setState((prevState, prevProps) => {
            return {isOnWall: !prevState.isOnWall}
        });

        fetch(apiBaseUrl + apiCall, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: data
        }).then(res => res.json())
            .then(
                (response) => {
                    if (response.error) throw new Error(response.errorMessage);
                    // TODO do something?
                    // this.props.updateParentVideoFav && this.props.updateParentVideoFav(action);
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.props.dispatch(showSnack('Error al añadir a favoritos', snackTypes.error));

                    // Revert if fails
                    this.setState((prevState, prevProps) => {
                        return {isOnWall: !prevState.isOnWall}
                    });
                }
            )
            .catch((error) => {
                console.log('Error: ' + error);
                this.props.dispatch(showSnack('Error al añadir a favoritos', snackTypes.error));
                // Revert if fails
                this.setState((prevState, prevProps) => {
                    return {isOnWall: !prevState.isOnWall}
                });
            })
    };
    renderWallActions = () => {

        if (this.props.user && this.props.user.entity && this.props.user.permissions_level !== 'all-included') {
            return false;
        }


        let clickFunction = async (e) => {
            e.stopPropagation();
            await this.toWallVideo();
        };

        return (
            <Tooltip title={'Añadir al muro'} placement={'top'}>

                <IconButton
                    className={this.state.isOnWall ? importedStyles.actionButtonFilled : importedStyles.actionButton}
                    onClick={clickFunction}>
                    {!this.state.isOnWall && <WallOutlinedIcon width={26} className={importedStyles.primaryColor}/>}
                    {this.state.isOnWall && <WallIconFilled width={26} className={importedStyles.primaryColor}/>}
                </IconButton>
            </Tooltip>
        )

    };

    /* ---- END FAV SYSTEM ---- */

    /* ---- VOTES SYSTEM ---- */
    setLikeVideo = (e) => {
        e.stopPropagation();

        let disableButton = false;
        if (this.props.request.likeable_date_limit) {
            disableButton = moment(this.props.request.likeable_date_limit).isBefore(moment());
        }

        if (disableButton) {

            let toastOptions = {
                id: "toast-vote-limit-date",
                style: {
                    color: '#fff',
                    backgroundColor: '#D3505F',
                    fontWeight: 300
                },
                iconTheme: {
                    primary: '#fff',
                    secondary: '#D3505F',
                },
                position: "bottom-left",
                duration: 3000
            };

            if (isMobile) {
                toastOptions.position = "bottom-center";
            }

            toast.error('😔 Lo sentimos. Ya no se puede votar, se acabó el tiempo de las votaciones.', toastOptions);

        } else {
            this.state.isLiked ? this.undoVote() : this.voteVideo();
        }

    };


    voteVideo = () => {
        // if (!this.props.logged || !this.props.user) {
        //     //no user logged, trigger register modal open
        //     this.props.dispatch(openRegisterRequestModal(window.location.pathname));
        //     return false;
        // }

        this.setLiked();

        let requestUuid = this.props.request.uuid;
        // let userUuid = this.props.user.uuid;
        let videoUuid = this.props.video.uuid;
        let vote = {videoUuid, requestUuid};

        voteVideo(requestUuid, videoUuid).then(res => res.json())
            .then(
                (response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    } else {
                        localStorage.setItem("vote" + requestUuid + videoUuid, true);
                        this.setState({isLiked: true}); //Solo para disparar un update que actualice el likesCount

                        ReactGA.event({
                            category: 'vote',
                            action: 'vote',
                            label: requestUuid,
                            value: videoUuid
                        });
                    }

                },
                (error) => {
                    throw new Error(error);
                }
            )
            .catch((err) => {
                console.error('Error: ' + err);
                this.undoLike();
                this.setState({waitingResponse: false});
                this.props.dispatch(showSnack('Error al ejecutar tu voto', snackTypes.error));
            });

    };
    undoVote = () => {
        // if (!this.props.user && !this.props.user.uuid) {
        //     //no user logged, trigger register modal open
        //     this.props.dispatch(openRegisterRequestModal(window.location.pathname));
        //     return false;
        // }

        this.undoLike();

        let requestUuid = this.props.request.uuid;
        // let userUuid = this.props.user.uuid;
        let videoUuid = this.props.video.uuid;
        // let vote = {userUuid, videoUuid, requestUuid};

        unvoteVideo(requestUuid, videoUuid).then(res => res.json())
            .then(
                (response) => {
                    if (response.error) {
                        throw new Error(response.errorMessage);
                    }
                    ReactGA.event({
                        category: 'vote',
                        action: 'unVote',
                        label: requestUuid,
                        value: videoUuid
                    });
                    localStorage.removeItem("vote" + requestUuid + videoUuid);
                    this.setState({isLiked: false}); //Solo para disparar un update que actualice el likesCount

                },
                (error) => {
                    throw new Error(error);
                }
            ).catch((err) => {
            console.error('Error: ' + err);
            this.setState({
                waitingResponse: false,
                isLoaded: true,
                isLiked: true,
                err
            });
            this.setLiked();
            this.props.dispatch(showSnack('Error al ejecutar tu voto', snackTypes.error));
        });

    }

    setLiked = () => {
        this.setState((prevState, prevProps) => {
            return {isLiked: true, likesCount: parseInt(prevState.likesCount) + 1}
        });
    }
    undoLike = () => {
        this.setState((prevState, prevProps) => {
            return {isLiked: false, likesCount: parseInt(prevState.likesCount) - 1}
        });
    }

    /* ---- END VOTES SYSTEM ---- */

    generateSharePath = () => {
        let requestId = this.props.match.params.requestId;
        let videoId = this.props.video.urlSlug;
        let url = window.location.origin + generatePath(this.props.match.path, {requestId, videoId});
        this.setState({urlShare: url});
    }

    setGifCover = () => {
        if (this.state.video.animated_thumbnail_url) {
            this.setState({loadingAnimatedThumbnail: true})
            let gif = new Image();
            gif.src = this.state.video.animated_thumbnail_url;
            gif.addEventListener('load', () => {
                if (this.state.loadingAnimatedThumbnail) { //Check necesario para evitar comportamientos indeseados al finalizar el onload si ya hemos sacado el focus de este elemento
                    // the gif is already downloaded and loaded, now lets set it as default video cover
                    this.setState({thumbnail: this.state.video.animated_thumbnail_url, loadingAnimatedThumbnail: false})
                }
            });
        }
    }


    render() {
        // console.log(this.state.video.title + ' _visible? -> ', inView);

        // let trimmedTitle = this.props.title.length > 70 ? this.props.title.substring(0, 70) + '...' : this.props.title;
        return (
            <React.Fragment>
                <InView
                    delay={800}
                    onChange={(inView, entry) => {
                        // console.log('Inview "' + this.state.video.title + '":', inView)
                        if (inView) {
                            this.setGifCover();
                        } else {
                            this.setState({thumbnail: this.props.image, loadingAnimatedThumbnail: false});
                        }
                    }}
                    skip={!isMobile}
                    threshold={1}
                    style={{
                        height: '100%',
                        width: '100%'
                    }} //fix card not getting 100% height because of Inview wrapper div
                >

                    <Card className={importedStyles.videoCard} elevation={0}
                          onClick={this.handleDetailVideoModal}
                          key={'fileCard' + this.props.video.uuid}
                    >

                        <CardMedia
                            className={this.props.alternativeActionsStyle ? importedStyles.alternativeMediaCardCover : importedStyles.videoCardCover}
                            onMouseOver={this.setGifCover}
                            onMouseLeave={() => {
                                if (this.props.video.media_type !== 'audio'){
                                    this.setState({thumbnail: this.props.image, loadingAnimatedThumbnail: false});
                                }
                            }}
                            image={this.state.thumbnail}
                            title={this.props.videoTitle}
                        >
                            <Fade in={this.state.loadingAnimatedThumbnail} mountOnEnter unmountOnExit timeout={200}>
                                <LinearProgress size={120} classes={{
                                    root: importedStyles.linearProgress,
                                    bar: importedStyles.linearProgressBar
                                }}/>
                            </Fade>

                            {this.props.video.media_type === 'video' && <PlayIcon className={importedStyles.playIcon}/>}
                            {this.props.video.media_type === 'audio' && <PlayIcon className={importedStyles.playIconFloating}/>}


                            {this.props.video.media_type === 'audio' && <Avatar style={{padding: '1rem', width: '9rem', height: '9rem'}} src={defaultAudioCoverImg}/>}
                        </CardMedia>
                        <CardContent style={{padding: 0, minHeight: '7.5rem'}}>
                            <ListItem>
                                <ListItemText primary={this.props.title}
                                              classes={{primary: importedStyles.videoCardTitle}}/>
                            </ListItem>
                            <ListItem style={{marginTop: -15}}>
                                <ListItemText classes={{secondary: importedStyles.videoCardAuthor}} secondary={this.props.author ? this.props.author : ''} />
                            </ListItem>
                            {/*<span className={importedStyles.videoCardAuthor}>{this.props.author}</span>*/}

                        </CardContent>
                        <Fade in={this.props.withLikes && this.props.withShare} unmountOnExit mountOnEnter>
                            <Divider style={{backgroundColor: '#BDBDBD', height: 1}} variant={"middle"}/>
                        </Fade>
                        <Fade in={this.props.alternativeActionsStyle} unmountOnExit mountOnEnter>
                            <CardActions className={importedStyles.alternativeCardActions}>

                                {this.renderCTAButton()}


                                {/*// system of votes */}
                                {this.props.withLikes && this.renderLikeActions()}
                                {/*Share video*/}
                                {this.props.withShare && this.renderShareActions()}

                            </CardActions>
                        </Fade>

                        <Fade in={!this.props.alternativeActionsStyle} unmountOnExit mountOnEnter>
                            <CardActions className={importedStyles.videoCardActions}>
                                {/* <IconButton onClick={(e) => e.stopPropagation()}>
                                <EyeIcon className={importedStyles.actionIcon}/>
                                <small
                                    className={importedStyles.actionCounter}>{Math.floor(Math.random() * 100)}</small>
                            </IconButton>*/}

                                {/*// Mark to ConfidenceWall*/}
                                {this.props.withConfidenceWall && this.renderWallActions()}
                                {/*// Save as fav*/}
                                {this.props.withFavorite && this.renderFavActions()}
                                {/*Download Button*/}
                                {this.props.withDownload && this.renderDownloadActions()}
                                {/*// system of votes */}
                                {this.props.withLikes && this.renderLikeActions()}
                                {/*Share video*/}
                                {this.props.withShare && this.renderShareActions()}

                            </CardActions>
                        </Fade>
                    </Card>
                </InView>


                {/* MODALS, SNACKS AND ALERTS */}
                {this.renderDownloadsModal()}
                <ShareModal open={this.state.shareModal} onClose={this.handleShare}
                            url={this.state.urlShare}
                            shareClaim={''}/>
                <DetailVideoModal open={this.state.openDetailVideoModal} onClose={this.closeDetailVideoModal}
                                  video={this.state.video}
                                  likesCount={this.state.likesCount}
                                  mediaType={this.props.video.media_type}
                                  isFavorite={this.state.isFavorite}
                                  isOnWall={this.state.isOnWall}
                                  isLiked={this.state.isLiked}
                                  setIsOnWall={this.toWallVideo}
                                  setFavVideo={this.favVideo}
                                  setLikeVideo={this.setLikeVideo}
                                  withLikes={this.props.withLikes}
                                  withDownload={this.props.withDownload}
                                  withConfidenceWall={this.props.withConfidenceWall}
                                  withFavorite={this.props.withFavorite}
                                  withShare={this.props.withShare}
                                  handleShare={this.handleShare}
                                  prevVideo={this.props.prevVideo}
                                  nextVideo={this.props.nextVideo}
                                  location={this.props.location}
                                  match={this.props.match}
                                  withAds={this.props.withAds}
                                  backButtonTitle={this.props.detailModalBackButtonTitle}
                    //actualVideoIndex={this.state.actualVideoIndex}
                    // lastVideoIndex={this.state.videos.length - 1} handlePrevVideo={this.handlePrevVideo}
                    // handleNextVideo={this.handleNextVideo}
                />

            </React.Fragment>
        );
    }
}

/* REACT/REDUX COMPONENT DEFINITIONS AND EXPORTS */

VideoCard.propTypes = {
    onClick: PropTypes.func.isRequired,
    updateIndexVideo: PropTypes.func,
    updateParentVideoFav: PropTypes.func,
    openDetailVideoModal: PropTypes.bool,
    video: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    withFavorite: PropTypes.bool,
    withDownload: PropTypes.bool,
    withShare: PropTypes.bool,
    withLikes: PropTypes.bool,
    likeLimitDate: PropTypes.any,
    titleTruncation: PropTypes.number,
    date: PropTypes.string,
    tags: PropTypes.array,
    author: PropTypes.any,
    freeTag: PropTypes.bool.isRequired,
    alternativeActionsStyle: PropTypes.bool,
    withAds: PropTypes.bool,
    detailModalBackButtonTitle: PropTypes.string,
};
VideoCard.defaultProps = {
    onClick: () => console.log('Debes proporcionar una función onClick!'),
    titleTruncation: 1,
    withDownload: false,
    withFavorite: false,
    freeTag: false,
    withLikes: false,
    withShare: false,
    withConfidenceWall: false,
    mediaType: 'video',
    alternativeActionsStyle: false,
    withAds: false,
};

const mapStateToProps = ({authReducer, ConcursoReducer, detailVideoModalReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        request: ConcursoReducer.request,
        currentVideo: detailVideoModalReducer.video,
        videos: detailVideoModalReducer.videos,
        indexVideoSelected: detailVideoModalReducer.indexVideoSelected,
    });
};

export default connect(mapStateToProps)(VideoCard);
