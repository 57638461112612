import {SET_CURRENT_AD_INDEX, SET_POPUP_ADS} from "../../../../constants/action-types";


const initialState = {
    currentAdIndex: 0,
    popupAds: [],
};

const EventDetailReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_POPUP_ADS:
            return {...state, popupAds: action.data};
        case SET_CURRENT_AD_INDEX:
            return {...state, currentAdIndex: action.index};
        default:
            return state;
    }
};


export default EventDetailReducer;
