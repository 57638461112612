import {
    CLOSE_UPLOAD_VIDEO_MODAL,
    OPEN_UPLOAD_VIDEO_MODAL,
} from "../../../../constants/action-types";


const initialState = {
    open: false,
    nextRoute: '.',
};

const modalUploadVideoReducer = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_UPLOAD_VIDEO_MODAL:
            return {...state, open: true, nextRoute: action.nextRoute};
        case CLOSE_UPLOAD_VIDEO_MODAL:
            return {...state, open: false, nextRoute: '.'};
        default:
            return state;
    }
};


export default modalUploadVideoReducer;