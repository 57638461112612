import React, {useEffect, useState} from "react";
import importedStyles from './UploadFilesModalStyles.module.sass'
import connect from "react-redux/es/connect/connect";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../Snack/SnackActions";
import axios from 'axios';
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import audioFileIcon from "../../icons/audio_file.png";
// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import {
    Avatar,
    Dialog,
    DialogContent,
    Divider,
    Fade,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles, Slide, TextareaAutosize,
    Typography
} from "@material-ui/core";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import uploadFiles from "../../animations/uploadFilesMobile.json";
import selectFilesDesktop from "../../animations/seleccionar-archivo-escritorio.json";
import selectFilesMobile from "../../animations/seleccionar-archivo-mobile.json";
import checkboxArrowAnimation from "../../animations/flecha-senala-checkbox.json";
import uploadingAnimation from "../../animations/uploading.json";
import uploadSuccessfulAnimation from "../../animations/check-uploading.json";
import Lottie from "react-lottie";
import PropTypes from "prop-types";
import DialogActions from "@material-ui/core/DialogActions";
import {
    CheckBoxOutlineBlankSharp,
    CheckBoxSharp,
    RefreshRounded,
    Mic,
    Image as ImageIcon,
    Videocam,
    Camera
} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Lottie2 from 'react-lottie-player';
import {primaryColor, secondaryColor} from "../../styles/palette";
import {usePrevious} from "../../helpers/hooks";
import {toast} from "react-hot-toast";
import CardActionArea from "@material-ui/core/CardActionArea";


registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

let isMobile = window.innerWidth <= 768;


export const useStyles = makeStyles((theme) => ({

    textField: {
        color: primaryColor,
        '&:before': {
            borderColor: secondaryColor,
        },
        '&:after': {
            borderColor: secondaryColor,
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: secondaryColor,
        },
    },
    textFieldUnderline: {
        color: primaryColor,
        '&:before': {
            borderColor: secondaryColor,
        },
        '&:after': {
            borderColor: secondaryColor,
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: secondaryColor,
        },
    },
}));

const UploadFilesModal = (props) => {

    const isKeyboardOpen = useDetectKeyboardOpen();


    const [uploadType, setUploadType] = useState(null);

    const [fileProgress, setFileProgress] = useState({});
    const [mediaFiles, setMediaFiles] = useState([]);
    const [media_type, setMediaType] = useState('video');
    const [waitingResponse, setWaitingResponse] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const prevUploadingFiles = usePrevious(uploadingFiles);

    const [openFailedUploadsModal, setOpenFailedUploadsModal] = useState(false);
    const [failedUploads, setFailedUploads] = useState([]);


    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showPreviewsContainer, setShowPreviewsContainer] = useState(false);
    const [userName, setUserName] = useState('');
    const [contact, setContact] = useState('');
    const [requiredSteps, setRequiredSteps] = useState(['title', 'name', 'contact']);
    const [currentStep, setCurrentStep] = useState('uploadFiles');

    const customStyles = useStyles();


    /// END OF STATE

    let pond = null;

    useEffect(() => {

    }, [isKeyboardOpen])


    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (props.request) {
            setTitle(props.request.defaultMediaTitle ? props.request.defaultMediaTitle : '');
            if (props.request.canUploadOnlyText) {
                setCurrentStep('chooseUploadType');
            } else {
                setUploadType('multimedia');
            }
        }
    }, []);

    useEffect(() => {

        setShowPreviewsContainer(mediaFiles.length > 0);
    }, [mediaFiles.length]);


    useEffect(() => {

        if (props.request) {

            setTitle(props.request.defaultMediaTitle ? props.request.defaultMediaTitle : '');

            if (props.request.requiredUploadFields) {
                let requiredFields = Object.values(JSON.parse(props.request.requiredUploadFields));
                setRequiredSteps(requiredFields);
            }

            if (props.request.canUploadOnlyText) {
                setCurrentStep('chooseUploadType');
            } else {
                setUploadType('multimedia');
            }
        }


    }, [props.request]);

    useEffect(() => {

        // Si no se le va a pedir al user un titulo, establecemos que el titulo sea el defaultMediaTitle del concurso + su nombre
        if (requiredSteps.indexOf('title') === -1) {
            let defaultTitle = props.request.defaultMediaTitle ? props.request.defaultMediaTitle : '';
            setTitle(defaultTitle + ' de ' + userName);
        }

    }, [userName]);

    useEffect(() => {

        if (!props.open) {
            handleModalClose();
        }


    }, [props.open]);


    useEffect(() => {

        let progresses = {...fileProgress};
        // sum up all file progress percentages to calculate the overall progress
        let totalPercent = progresses ? Object.values(progresses).reduce((sum, num) => sum + num, 0) : 0
        // divide the total percentage by the number of files
        let percentCompleted = Math.round(totalPercent / Object.keys(progresses).length);

        if (percentCompleted > 0) {
            setUploadProgress(percentCompleted)
        }
    }, [fileProgress]);

    useEffect(() => {

        if (prevUploadingFiles === true && !uploadingFiles && failedUploads.length > 0) {
            setOpenFailedUploadsModal(true);
        }

        if (prevUploadingFiles === true && !uploadingFiles && failedUploads.length <= 0) {
            setOpenFailedUploadsModal(false);
            handleModalClose();
            props.openThankYouModalFunction();
        }


    }, [failedUploads, uploadingFiles])


    const handleTitleChange = (e) => {
        let value = e.target.value;
        if (e.target.name === 'title') {
            value = value.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
        }

        setTitle(value);
    };

    const handleDescriptionChange = (e) => {
        let value = e.target.value;
        value = value.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
        setDescription(value);
    };


    const handleModalClose = () => {

        if (props.request) {
            props.request.canUploadOnlyText ? setCurrentStep('chooseUploadType') : setCurrentStep('uploadFiles');
        }

        setOpenFailedUploadsModal(false);
        setMediaFiles([]);
        setFailedUploads([]);
        setFileProgress({});
        setUploadProgress(0);
        setContact('');
        setUserName('');
        setTitle(props.request ? props.request.defaultMediaTitle : '');
        setTermsAccepted(false);
        props.onClose();

        window.document.getElementById('root').style.filter = 'unset';
    };


    const beginUploads = async () => {

        setCurrentStep('uploading');
        setUploadingFiles(true);
        setUploadProgress(0);


        if (uploadType === 'text') {
            await handleOnlyTextUpload();
        }

        if (uploadType === 'multimedia') {
            let filesToUpload = [...mediaFiles];

            let initialProgresses = {};
            for (let i = 0; i < filesToUpload.length; i++) {
                initialProgresses[i] = 0;
            }

            setFileProgress((prevState) => {
                return {...prevState, ...initialProgresses}
            });

            for (let i = 0; i < filesToUpload.length; i++) {
                let item = filesToUpload[i];

                let uploaded = await handleUpload(item, i);
            }
        }

        setUploadingFiles(false);
    }


    const handleUpload = async (media, index) => {

        let video = media.file;
        let mediaType = media.media_type;

        setWaitingResponse(true);


        let formData = new FormData();
        formData.append('video', video);
        formData.append('media_type', mediaType);
        formData.append('title', title);
        formData.append('contact', contact);
        formData.append('description', description);
        formData.append('name', userName);
        formData.append('requestId', props.request.uuid);
        formData.append('version', 'v3');


        let apiEndpoint = 'videos/uploadVideo';

        if (mediaType === 'image') {
            apiEndpoint = 'images/uploadImage'
        }

        if (mediaType === 'audio') {
            apiEndpoint = 'audios/upload'
        }


        await axios.post(apiBaseUrl + apiEndpoint, formData, {
            onUploadProgress: function (progressEvent) {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);

                let progressesNew = {};
                // save the individual file's progress percentage in object
                progressesNew[index] = percent;

                setFileProgress((prevState) => {
                    return {...prevState, ...progressesNew}
                })

            }.bind(this)
        }).then((response) => {

            if (response.data && response.data.error) {
                throw new Error(response.data.errorMessage);
            }


            ReactPixel.trackCustom('UploadVideoSuccess', {title: title});
            // ReactGA.ga('send', 'event', 'video', 'UploadVideoSuccess');
            ReactGA.event({
                category: 'video',
                action: 'UploadVideoSuccess',
                label: props.request ? props.request.uuid : 'notRequest',
                value: title
            });

            //Eliminamos el archivo subido con exito de la cola
            let filteredArray = mediaFiles.filter((item) => item.id !== video.name);
            setMediaFiles([...filteredArray]);

        }).catch((error) => {
            console.error('Error: ', error);
            ReactPixel.trackCustom('UploadVideoError', {error: error});
            ReactGA.ga('send', 'event', 'video', 'UploadVideoError', 'Error: ' + error + '' + props.request.uuid);


            setFailedUploads((prevState) => {
                let newArray = [...prevState];
                newArray.push(media);
                return newArray
            })//metemos el archivo actual al array de subidas fallidas


            // props.dispatch(showSnack('Error al subir "' + video.name + '", vuelve a intentarlo por favor', snackTypes.error));
        }).finally(() => {
            setWaitingResponse(false);
        });
    };

    const handleOnlyTextUpload = async () => {

        setWaitingResponse(true);
        let formData = new FormData();
        formData.append('text', description);
        formData.append('title', title);
        formData.append('contact', contact);
        formData.append('name', userName);
        formData.append('requestId', props.request.uuid);


        await axios.post(apiBaseUrl + 'images/generateFromText', formData, {
            onUploadProgress: function (progressEvent) {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total);

                setUploadProgress(percent)

            }.bind(this)
        }).then((response) => {

            if (response.data && response.data.error) {
                throw new Error(response.data.errorMessage);
            }


            ReactPixel.trackCustom('UploadVideoSuccess', {title: title});
            // ReactGA.ga('send', 'event', 'video', 'UploadVideoSuccess');
            ReactGA.event({
                category: 'video',
                action: 'UploadVideoSuccess',
                label: props.request ? props.request.uuid : 'notRequest',
                value: title
            });

            setDescription('');
            setTitle('');
            setUserName('');
            setContact('');

        }).catch((error) => {
            console.error('Error: ', error);
            ReactPixel.trackCustom('UploadVideoError', {error: error});
            ReactGA.ga('send', 'event', 'video', 'UploadVideoError', 'Error: ' + error + '' + props.request.uuid);
        }).finally(() => {
            setWaitingResponse(false);
        });
    };

    const handleRetryUploads = () => {
        setMediaFiles(failedUploads); //set failed uploads as new files to upload
        setFailedUploads([]); //reset failed uploads
        beginUploads();
        setOpenFailedUploadsModal(false);
    };


    const handleInitFilePond = () => {
        // console.log('FilePond instance has initialised', pond);
    };

    const renderProgressBar = () => {
        return (
            <div className={importedStyles.progressBarContainer}>
                <div className={importedStyles.progressBarMask}/>
                <LinearProgress
                    variant="determinate"
                    className={importedStyles.progressBar}
                    classes={{barColorPrimary: importedStyles.currentProgress}}
                    value={uploadProgress}
                />
                <span className={importedStyles.progressBarPercent}>{uploadProgress}%</span>
            </div>
        );
    };

    const getDisabledParticipateButtonConditions = () => {
        let disabledFlag = !termsAccepted;
        if (uploadType === 'multimedia') {
            disabledFlag = disabledFlag + (mediaFiles.length === 0);
        }
        return disabledFlag;
    }

    const renderUploadButton = () => {
        return (
            <React.Fragment>
                <Fade in={uploadingFiles} unmountOnExit mountOnEnter>
                    {renderProgressBar()}
                </Fade>
                <Fade in={!uploadingFiles} unmountOnExit mountOnEnter>
                    <Button
                        id={'uploadButton'}
                        className={importedStyles.submitButton}
                        variant='contained'
                        onClick={beginUploads}
                        disabled={getDisabledParticipateButtonConditions()}
                    >
                        Participar
                    </Button>
                </Fade>
            </React.Fragment>

        );
    };


    const slugify = (text) => {
        return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    const onFileAdd = async (newFile) => {
        const media = newFile.file;


        const acceptedVideoFileTypes = ['video/mp4', 'video/x-matroska', 'video/x-flv', 'video/quicktime', 'video/x-msvideo', 'video/msvideo',
            'video/x-ms-wmv', 'video/x-ms-asf', 'video/avi', 'video/x-quicktime', 'video/ogg'];
        const acceptedImageFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const acceptedAudioFileTypes = ['audio/*',];

        let mediaPreviewElement = window.document.createElement('video');
        let mediaType = 'video';

        if (acceptedVideoFileTypes.includes(media.type)) {
            setMediaType('video');

            mediaPreviewElement.controls = true;
            mediaPreviewElement.style.width = '100%';
            mediaPreviewElement.style.borderRadius = '12px';
            mediaPreviewElement.style.maxHeight = '12rem';
            mediaPreviewElement.classList.add('videoPreviewItem');
            mediaPreviewElement.style.marginLeft = '1rem';
            mediaPreviewElement.style.marginRight = '1rem';
            mediaPreviewElement.style.background = '#000000';
            mediaPreviewElement.addEventListener('loadedmetadata', (event) => {
                if (event.target.videoHeight === 0) {
                    mediaPreviewElement.style.height = '2rem';
                    mediaPreviewElement.style.background = 'none';
                    mediaPreviewElement.style.margin = '0';

                    let toastOptions = {
                        id: "toast-media-codec-preview-error",
                        style: {
                            color: '#fff',
                            backgroundColor: '#b94343',
                            fontWeight: 300,
                            padding: '8px 20px 8px 0px',
                            textAlign: 'center'
                        },
                        iconTheme: {
                            primary: '#fff',
                            secondary: '#b94343',
                        },
                        icon: '',
                        position: "bottom-left",
                        duration: 3000
                    };

                    if (isMobile) {
                        toastOptions.position = "bottom-center";
                    }

                    toast.error('Tu archivo no se puede previsualizar, pero no te preocupes, lo recibiremos con normalidad.', toastOptions);

                }
            });

        } else if (acceptedImageFileTypes.includes(media.type)) {
            mediaType = 'image';
            setMediaType('image')
            mediaPreviewElement = window.document.createElement('img');
            mediaPreviewElement.classList.add('imagePreviewItem');
            mediaPreviewElement.style.borderRadius = '12px';
            mediaPreviewElement.style.maxHeight = '12rem';
            mediaPreviewElement.style.marginLeft = '1rem';
            mediaPreviewElement.style.marginRight = '1rem';
            mediaPreviewElement.style.objectFit = 'contain';

        } else if (media.type.includes('audio')) {
            mediaType = 'audio';
            setMediaType('audio');
            // mediaPreviewElement = window.document.createElement('audio');
            let container = window.document.createElement('div');
            let audioTitle = window.document.createElement('span');
            audioTitle.innerText = media.name.slice(0, 10) + '(...)';

            audioTitle.style.fontSize = 'flex';
            container.style.display = 'flex';
            container.style.flexDirection = 'column';
            container.style.alignItems = 'center';


            mediaPreviewElement = window.document.createElement('img');
            mediaPreviewElement.classList.add('audioPreviewItem');
            mediaPreviewElement.style.borderRadius = '12px';
            mediaPreviewElement.style.maxHeight = '12rem';
            mediaPreviewElement.style.marginLeft = '1rem';
            mediaPreviewElement.style.marginRight = '1rem';
            mediaPreviewElement.style.objectFit = 'contain';

            mediaPreviewElement.id = "mediaPreview" + slugify(media.name) + media.lastModified;
            mediaPreviewElement.src = audioFileIcon;

            const parentDiv = document.getElementById("mediaPreviewsContainer");

            container.appendChild(mediaPreviewElement);
            container.appendChild(audioTitle);

            parentDiv && parentDiv.appendChild(container);

        } else {
            pond.removeFile(newFile);
            setMediaType(mediaType)
            props.dispatch(showSnack('Lo sentimos, prueba con otro formato de archivo', snackTypes.error));
            return false; //break execution
        }

        // If everything ok till this line:

        let updatedMediaFiles = mediaFiles;
        updatedMediaFiles.push({id: media.name, file: media, media_type: mediaType});

        setMediaFiles([...updatedMediaFiles]);
        setMediaType(mediaType);


        if (mediaPreviewElement && mediaType !== 'audio') {

            const url = URL.createObjectURL(media);  //get metaData

            mediaPreviewElement.id = "mediaPreview" + slugify(media.name) + media.lastModified;
            mediaPreviewElement.src = url;

            const parentDiv = document.getElementById("mediaPreviewsContainer");

            parentDiv && parentDiv.appendChild(mediaPreviewElement);

            mediaType === 'video' && mediaPreviewElement.load(); // fetches metadata only if media type is video, doing this to an image may cause app to break
        }


    };

    const onFileRemove = (deletedFile) => {

        // console.log(deletedFile.file);
        let file = deletedFile.file;
        const mediaPreview = window.document.getElementById("mediaPreview" + slugify(file.name) + file.lastModified);
        mediaPreview && mediaPreview.remove();

        let filteredArray = mediaFiles.filter((item) => item.id !== file.name);

        setMediaType('video');
        setMediaFiles([...filteredArray])

    };


    const renderUploadCtaAnimation = () => {

        const videoPreviewElement = window.document.getElementById("videoPreview");

        if (videoPreviewElement) {
            return false; // break execution to avoid render animation when a file is already added
        }

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: isMobile ? selectFilesMobile : selectFilesDesktop,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    pond.browse();
                }}
                style={{marginTop: '-2rem'}}
            >
                <Lottie options={defaultOptions}
                        height={isMobile ? 200 : 175}
                        width={isMobile ? 200 : 175}
                        speed={1}
                        isStopped={false}
                        isClickToPauseDisabled={true}
                />
            </div>
        )
    };


    const renderUploadStep = () => {

        let filepondPlaceholder = isMobile ? 'Pulsa aquí y busca los archivos que quieres enviar' : 'Arrastra o pulsa aquí y busca los archivos que quieres enviar';

        return (
            <React.Fragment>
                <div className={importedStyles.form}>
                    <h2 className={importedStyles.stepTitleTop}>Selecciona los archivos de tu galería y participa</h2>
                    <div id={'uploadVideoContainer'}>
                        {renderUploadCtaAnimation()}
                        <FilePond maxFileSize={'1024MB'}
                                  allowMultiple={true}
                                  labelMaxFileSizeExceeded={'El archivo es demasiado grande'}
                                  labelMaxFileSize={'No puede pesar más de 1Gb'}
                                  className={importedStyles.filepond}
                                  labelIdle={filepondPlaceholder}
                                  labelFileTypeNotAllowed={'Lo sentimos, este archivo no es válido, prueba con otro'}
                                  fileValidateTypeLabelExpectedTypes={'Lo sentimos, este archivo no es válido, prueba con otro'}
                                  ref={ref => pond = ref}
                                  oninit={handleInitFilePond}
                                  onaddfile={(error, file) => onFileAdd(file)}
                                  onremovefile={(error, file) => onFileRemove(file)}
                        />
                        <Fade in={showPreviewsContainer}>
                            <div id={'mediaPreviewsContainer'} className={importedStyles.mediaPreviewsContainer}
                                 style={{justifyContent: mediaFiles.length === 1 ? 'center' : 'unset'}}>

                            </div>
                        </Fade>

                    </div>
                </div>
            </React.Fragment>

        );

    };


    const renderTermsAndConditionsStep = () => {
        return (
            <>
                <h2 className={importedStyles.stepTitle}>Acepta los términos y condiciones para poder participar </h2>
                <div className={importedStyles.infoLOPDContainer}>
                    <Box>
                        <h3 style={{marginTop: 0, marginBottom: 0}}>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS</h3>
                        <p>
                            <b>Responsable:</b> Graba tu talento SL, cuyos datos de contacto son:
                            <br/>
                            email: info@grabaloapp.com
                            <br/>
                            teléfono: 622393895
                            <br/>
                            <br/>
                            <b>Finalidad:</b> Llevar a cabo el buen funcionamiento de esta acción
                            <br/>
                            <br/>
                            <b>Legitimación:</b> Ejecución de contrato, participación activa, peticiones y
                            consentimiento.
                            <br/>
                            <br/>
                            <b>Destinatarios:</b> Prestadores de servicios tecnológicos, como encargados del
                            tratamiento, que seguirán siempre nuestras instrucciones.
                            <br/>
                            <br/>
                            <b>Derechos:</b> Puedes acceder, rectificar y suprimir los datos, entre otros dirigiéndote a
                            info@grabaloapp.com. Para más información consulta nuestra <a rel='noopener noreferrer'
                                                                                          target='_blank'
                                                                                          href={'/terms#privacy'}>política
                            de
                            privacidad</a>.
                            <br/>
                            <br/>
                        </p>
                    </Box>


                </div>
                <div className={importedStyles.termsCheck}>
                    <Box className={importedStyles.checkboxWrapper}>
                        <Checkbox
                            style={{color: '#00a3da', padding: 0}}
                            icon={<CheckBoxOutlineBlankSharp style={{width: '2.5em', height: '2.5em'}}/>}
                            checkedIcon={<CheckBoxSharp style={{width: '2.5em', height: '2.5em'}}/>}
                            onChange={() => setTermsAccepted(!termsAccepted)}
                            checked={termsAccepted}
                        />
                        <p className={importedStyles.linkToTerms}>
                            Soy mayor de edad, he leído y acepto la <a rel='noopener noreferrer' target='_blank'
                                                                       href={'https://grabaloapp.com/politica-de-privacidad'}>política
                            de privacidad</a> y
                            <a rel='noopener noreferrer' target='_blank'
                               href={'https://grabaloapp.com/aviso-legal'}> las condiciones de
                                uso.</a>
                        </p>

                    </Box>

                    {renderCheckboxAnimation()}
                </div>
            </>
        );
    }


    const renderUploadingStep = () => {
        return (
            <>
                <div style={{
                    background: '#e3f7ff',
                    borderRadius: 32,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '2rem'
                }}>
                    <Lottie2
                        loop
                        animationData={uploadingAnimation}
                        play
                        style={{width: 150, height: 150}}
                    />
                    <span style={{
                        marginTop: '1.15rem',
                        fontSize: '2.7rem',
                        fontWeight: 'bold',
                        color: '#00a3da'
                    }}>{uploadProgress}%</span>
                </div>

                <List style={{marginTop: '1.8rem'}}>
                    <ListItem>
                        <ListItemIcon>
                            <Lottie2
                                loop={false}
                                animationData={uploadSuccessfulAnimation}
                                play
                                style={{width: 95, height: 95}}
                                // onComplete={() => {setPlaySecondClaimStep(true)}}
                            />
                        </ListItemIcon>
                        <ListItemText primary={'No cierres esta ventana'}
                                      classes={{primary: importedStyles.uploadingListItemText}}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Lottie2
                                loop={false}
                                animationData={uploadSuccessfulAnimation}
                                play
                                style={{width: 95, height: 95}}
                            />
                        </ListItemIcon>
                        <ListItemText primary={'Estás a un paso de participar'}
                                      classes={{primary: importedStyles.uploadingListItemText}}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Lottie2
                                loop={uploadingFiles}
                                animationData={uploadSuccessfulAnimation}
                                play
                                speed={0.7}
                                segments={uploadingFiles && [0, 50]}
                                style={{width: 95, height: 95}}
                            />

                        </ListItemIcon>
                        <ListItemText primary={'Subiendo tus archivos'}
                                      classes={{primary: importedStyles.uploadingListItemText}}/>
                    </ListItem>

                </List>


                {/*<Fade in={uploadingFiles} mountOnEnter unmountOnExit>*/}
                {/*    {renderUploadingAnimation()}*/}
                {/*</Fade>*/}
                {/*<Fade in={!uploadingFiles} mountOnEnter unmountOnExit>*/}
                {/*    {renderUploadSuccessfulAnimation()}*/}
                {/*</Fade>*/}


            </>
        );
    }

    const renderCheckboxAnimation = () => {

        return (
            <div style={{transform: 'scaleY(-1)', marginLeft: '-7rem', marginTop: '-5rem'}}>
                <Lottie2
                    loop
                    animationData={checkboxArrowAnimation}
                    play
                    style={{width: '18rem', height: '18rem'}}
                />
            </div>
        )
    }

    const renderDescriptionStep = () => {
        let title = 'Escribe aquí tu texto';
        let subtitle = 'Copia y pega el texto desde tu editor de texto si te resulta más cómodo 😊';

        if (uploadType === 'multimedia' && props.request.descriptionStepTitle){
            title =  props.request.descriptionStepTitle;
        }

        if (uploadType === 'multimedia' && props.request.descriptionStepSubtitle){
            subtitle =  props.request.descriptionStepSubtitle;
        }

        //

        if (uploadType === 'text' && props.request.sinopsisStepTitle){
            title =  props.request.sinopsisStepTitle;
        }

        if (uploadType === 'text' && props.request.sinopsisStepSubtitle){
            subtitle =  props.request.sinopsisStepSubtitle;
        }



        if (uploadType === 'multimedia' && requiredSteps.indexOf('description') === -1) {
            handleNextStep();
            return false;
        }

        return (
            <>
                <h2 className={importedStyles.stepTitle}>{title}</h2>
                <span
                    className={importedStyles.stepSubtitle}>{subtitle}
                </span>

                <TextField
                    multiline
                    // minRows={4}
                    maxRows={15}
                    margin="normal"
                    id="description"
                    name="description"
                    label={"Tu texto aquí"}
                    type="text"
                    inputProps={{maxLength: 4999}}
                    className={customStyles.textField}
                    InputProps={{classes: {underline: customStyles.textFieldUnderline}}}
                    fullWidth
                    value={description}
                    onChange={handleDescriptionChange}
                />
            </>
        );
    }


    const renderTitleStep = () => {

        if (requiredSteps.indexOf('title') === -1) {
            handleNextStep();
            return false;
        }

        return (
            <>
                <h2 className={importedStyles.stepTitle}>{props.request && props.request.titleStepTitle ? props.request.titleStepTitle : 'Ponle un título'}</h2>
                <span
                    className={importedStyles.stepSubtitle}>{props.request && props.request.titleStepDescription ? props.request.titleStepDescription : 'Escribe un título para la obra de arte que vas a enviar y con la que vas a participar'}</span>
                <TextField
                    margin="normal"
                    id="title"
                    name="title"
                    label={"Tu respuesta aquí"}
                    type="text"
                    inputProps={{maxLength: 100}}
                    className={customStyles.textField}
                    InputLabelProps={{
                        style: {color: title && title.length > 0 ? secondaryColor : '#acb1b6'}
                    }}
                    InputProps={{classes: {underline: customStyles.textFieldUnderline}}}
                    value={title}
                    fullWidth
                    onChange={handleTitleChange}
                />
            </>
        );
    }

    const renderNameStep = () => {
        if (requiredSteps.indexOf('name') === -1) {
            handleNextStep();
            return false;
        }

        return (
            <>
                <h2 className={importedStyles.stepTitle}> {props.request && props.request.nameStepTitle ? props.request.nameStepTitle : '¿Cómo te llamas?'}</h2>
                <span
                    className={importedStyles.stepSubtitle}>{props.request && props.request.nameStepDescription ? props.request.nameStepDescription : 'Escribe un nombre y apellidos'}</span>
                <TextField
                    margin="normal"
                    id="name"
                    name="name"
                    label={"Tu respuesta aquí"}
                    type="text"
                    autoComplete
                    inputProps={{maxLength: 100}}
                    className={customStyles.textField}
                    InputLabelProps={{
                        style: {
                            color: userName.length > 0 ? secondaryColor : '#acb1b6',
                            '&.Mui-focused': {color: secondaryColor + '!important'}
                        }
                    }}
                    InputProps={{classes: {underline: customStyles.textFieldUnderline}}}
                    value={userName}
                    fullWidth
                    onChange={(e) => {
                        setUserName(e.target.value)
                    }}
                />
            </>
        );
    }

    const renderContactStep = () => {
        if (requiredSteps.indexOf('contact') === -1) {
            handleNextStep();
            return false;
        }

        return (
            <>
                <h2 className={importedStyles.stepTitle}> {props.request && props.request.contactStepTitle ? props.request.contactStepTitle : 'Déjanos un contacto'}</h2>
                <span
                    className={importedStyles.stepSubtitle}>{props.request && props.request.contactStepDescription ? props.request.contactStepDescription : 'Sólo contactaremos contigo si tu obra es la ganadora ¿cómo prefieres? ¿email o teléfono?'}</span>
                <TextField
                    margin="normal"
                    id="contact"
                    name="email"
                    label={"Tu respuesta aquí"}
                    autoComplete
                    type="text"
                    inputProps={{maxLength: 100}}
                    value={contact}
                    className={customStyles.textField}
                    InputLabelProps={{
                        style: {
                            color: contact.length > 0 ? secondaryColor : '#acb1b6',
                            '&.Mui-focused': {color: secondaryColor + '!important'}
                        }
                    }}
                    InputProps={{classes: {underline: customStyles.textFieldUnderline}}}
                    fullWidth
                    onChange={(e) => {
                        setContact(e.target.value)
                    }}
                />
            </>
        );
    }


    const renderStepContent = () => {
        switch (currentStep) {
            case 'title':
                return renderTitleStep();
            case 'name':
                return renderNameStep();
            case 'contact':
                return renderContactStep();
            case 'description':
                return renderDescriptionStep();
            case 'terms':
                return renderTermsAndConditionsStep();
            case 'uploading':
                return renderUploadingStep();
            case 'uploadFiles':
                return renderUploadStep();
            default:
                return renderTitleStep();
        }
    }


    const handleNextStep = () => {
        switch (currentStep) {

            case 'title':
                uploadType === 'text' ? setCurrentStep('name') : setCurrentStep('description');
                break;
            case 'description':
                uploadType === 'text' ? setCurrentStep('title') : setCurrentStep('name');
                break;
            case 'name':
                setCurrentStep('contact');
                break;
            case 'contact':
                setCurrentStep('terms');
                break;
            case 'uploadFiles':
                setCurrentStep('title');
                break;
            default:
                //
                break;

        }
    }


    const getDisabledButtonConditions = () => {
        switch (currentStep) {
            case 'title':
                return !title || title.length <= 0;
            case 'name':
                return !userName || userName.length <= 0;
            case 'contact':
                return !contact || contact.length <= 0;
            case 'description':
                return !description || description.length <= 0;
            case 'terms':
                return !termsAccepted;
            case 'uploadFiles':
                return mediaFiles.length <= 0;
            default:
                return true;
        }
    }

    const renderNextStepButton = () => {
        // isKeyboardOpen
        return (
            <React.Fragment>
                <Button
                    id={'nextStepButton'}
                    className={importedStyles.nextStepButton}
                    variant='contained'
                    onClick={handleNextStep}
                    disabled={getDisabledButtonConditions()}
                >
                    Siguiente
                </Button>
            </React.Fragment>
        )
    }

    const renderDialogActions = () => {
        if (currentStep === 'uploading') return false;
        if (currentStep === 'chooseUploadType') return false;
        return (
            <>
                {currentStep === 'terms' ? renderUploadButton() : renderNextStepButton()}
            </>
        );
    }

    const handleUploadTypeButtonClick = (type) => () => {

        if (type === 'text') {
            setUploadType('text');
            setCurrentStep('description');
        }

        if (type === 'multimedia') {
            setUploadType('multimedia');
            setCurrentStep('uploadFiles');
        }
    }


    return (
        <React.Fragment>
            <Dialog
                open={props.open && !openFailedUploadsModal}
                maxWidth={'sm'} onClose={handleModalClose} fullWidth
                fullScreen={isMobile}
                classes={{
                    paper: importedStyles.dialogMain,
                    paperFullScreen: importedStyles.dialogMobileFullScreen,
                }}
                BackdropProps={{
                    className: importedStyles.dialogBackdrop
                }}
            >
                <IconButton className={importedStyles.closeModalIcon} onClick={handleModalClose}>
                    <CloseIcon/>
                </IconButton>

                <Fade direction={'left'} in={currentStep !== 'chooseUploadType'} timeout={{enter: 400}} mountOnEnter
                      unmountOnExit>
                    <DialogContent
                        className={importedStyles.dialogContent}
                        classes={{
                            root: importedStyles.dialogRootFix
                        }}
                    >
                        <div className={importedStyles.container}>

                            {renderStepContent()}
                        </div>

                    </DialogContent>
                </Fade>

                <Fade direction={'left'} in={currentStep === 'chooseUploadType'} mountOnEnter unmountOnExit
                      timeout={{enter: 200, exit: 0}}>
                    <DialogContent
                        className={importedStyles.chooseUploadTypeDialogContent}
                    >
                        <CardActionArea onClick={handleUploadTypeButtonClick('multimedia')}
                                        style={{borderRadius: 24, marginBottom: '0.75rem'}}>
                            <Box className={importedStyles.chooseUploadTypeRootBox}>
                                <span className={importedStyles.chooseUploadTypeBoxTitle}>Quiero participar con fotos, audio o vídeo</span>
                                <Box className={importedStyles.chooseUploadTypeIconsBox}>
                                    <Avatar style={{background: '#f6e0e5', width: '7rem', height: '7rem', zIndex: 5}}>
                                        <Videocam style={{color: '#fb4f76', fontSize: '3rem'}}/>
                                    </Avatar>
                                    <Avatar style={{
                                        background: '#def8ec',
                                        width: '7rem',
                                        height: '7rem',
                                        marginLeft: '-1.75rem',
                                        zIndex: 4
                                    }}>
                                        <Mic style={{color: '#02ca6e', fontSize: '3rem'}}/>
                                    </Avatar>
                                    <Avatar style={{
                                        background: '#faf2d5',
                                        width: '7rem',
                                        height: '7rem',
                                        marginLeft: '-1.75rem',
                                        zIndex: 3
                                    }}>
                                        <ImageIcon style={{color: '#ffc600', fontSize: '3rem'}}/>
                                    </Avatar>
                                </Box>
                            </Box>
                        </CardActionArea>

                        <CardActionArea onClick={handleUploadTypeButtonClick('text')} style={{borderRadius: 24}}>
                            <Box className={importedStyles.chooseUploadTypeRootBox}>
                                <span
                                    className={importedStyles.chooseUploadTypeBoxTitle}>Quiero participar con un texto</span>
                                <Avatar className={importedStyles.textTypeIcon}>
                                    T
                                </Avatar>
                            </Box>
                        </CardActionArea>


                    </DialogContent>
                </Fade>


                <Fade in={currentStep !== 'uploading'}>
                    <Divider variant={"fullWidth"} style={{height: 2}}/>
                </Fade>

                <Fade in={currentStep !== 'chooseUploadType'} mountOnEnter unmountOnExit>

                    <DialogActions
                        style={{display: 'flex', justifyContent: 'center', marginTop: isKeyboardOpen ? '0' : 'auto'}}>

                        {renderDialogActions()}

                    </DialogActions>
                </Fade>
            </Dialog>


            <Dialog
                open={openFailedUploadsModal}
                classes={{paper: importedStyles.failedUploadsModalPaper}}
            >
                <DialogContent>
                    <h4 style={{fontSize: '1.1rem', marginBottom: '0.75rem', maxWidth: '25rem'}}>Uy, algunos de tus
                        archivos no se han subido con éxito.</h4>
                    <Typography variant={"body2"} style={{fontWeight: 500, maxWidth: '27rem'}}>
                        No te preocupes, no hará falta que selecciones de nuevo tus archivos.
                    </Typography>
                </DialogContent>
                <DialogActions style={{paddingTop: '0.75rem'}}>
                    <Button variant={"text"} onClick={handleRetryUploads}
                            style={{color: secondaryColor, display: 'flex', alignItems: 'flex-start', fontWeight: 400}}>
                        <RefreshRounded style={{marginRight: 5}}/>
                        RE-SUBIR LOS ARCHIVOS FALLIDOS
                    </Button>
                </DialogActions>
            </Dialog>


        </React.Fragment>
    );


};

UploadFilesModal.propTypes = {
    openThankYouModalFunction: PropTypes.func,
    onClose: PropTypes.func,
};

const mapStateToProps = ({modalUploadVideoReducer, ConcursoReducer, authReducer}) => {
    return ({
        nextRoute: modalUploadVideoReducer.nextRoute,
        reloadNeeded: modalUploadVideoReducer.reload,
        request: ConcursoReducer.request,
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(UploadFilesModal);
