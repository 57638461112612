import {CLOSE_REGISTER_MODAL, OPEN_REGISTER_MODAL,} from "../../../constants/action-types";


const initialState = {
    open: false,
    nextRoute: '.',
};

const registerModalReducer = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_REGISTER_MODAL:
            return {...state, open: true, nextRoute: action.nextRoute};
        case CLOSE_REGISTER_MODAL:
            return {...state, open: false, nextRoute: '.'};
        default:
            return state;
    }
};


export default registerModalReducer;