import React from 'react';
import {withStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const MyTooltip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: '0.875rem',
        padding: 10,
        backgroundColor: 'black'
    },
}))(Tooltip);

export default MyTooltip;