import React from "react";
import connect from "react-redux/es/connect/connect";
import history from "../../helpers/history";
import importedStyles from './MailVerification.module.sass';
import apiBaseUrl from "../../helpers/apiBaseUrl";
import * as qs from 'query-string';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Snack from "../../components/Snack/Snack";
import {Helmet} from "react-helmet/es/Helmet";

class MailVerification extends React.Component {
    state = {
        mailConfirmed: false,
        verificationError: false,
    };

    componentDidMount = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.verifyEmail();
    };

    //Checkers
    verifyEmail = () => {

        this.setState({waitingResponse: true});
        const params = qs.parse(this.props.location.search);
        let token = params.token;
        let isEntity = params.entity;

        fetch(apiBaseUrl + 'auth/verifyEmail', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: token, isEntity: isEntity})
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({mailConfirmed: true, waitingResponse: false});
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.setState({waitingResponse: false, verificationError: true});
            this.props.dispatch(showSnack('Error al verificar tu cuenta, por favor vuelve a intentarlo más tarde', snackTypes.error));
        });
    };


    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Confirmar cuenta</title>
                </Helmet>
                <div className={importedStyles.container}>
                    <div className={importedStyles.content}>
                        {this.state.mailConfirmed && <Typography variant={"h5"} style={{textAlign: 'center'}}>Tu cuenta ha sido confirmada, ahora puedes acceder a la plataforma</Typography>}
                        {!this.state.mailConfirmed && !this.state.verificationError && <Typography variant={"h5"}>Verificando email...</Typography>}
                        {this.state.verificationError && <Typography variant={"h5"} color={"secondary"}>Error al verificar email</Typography>}
                        {this.state.waitingResponse && <CircularProgress size={55} className={importedStyles.circularProgress}/>}
                        {this.state.mailConfirmed && <Button variant={"contained"} className={importedStyles.actionButton} onClick={()=>history.push('/')}>Acceder</Button>}
                    </div>
                </div>
                <Snack/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, detailVideoReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        video: detailVideoReducer.video,
    });
};
export default connect(mapStateToProps)(MailVerification);
