import {applyMiddleware, createStore} from 'redux';
import rootReducer from "../reducers";
import thunkMiddleware from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    (persistedReducer),
    applyMiddleware(thunkMiddleware)
);

// const store = createStore(persistedReducer, compose(applyMiddleware(thunkMiddleware), Reactotron.createEnhancer()));

let persistor = persistStore(store);

export {store, persistor};





