import {CLOSE_ENTITIES_ONBOARDING, OPEN_ENTITIES_ONBOARDING} from "../../../constants/action-types";

export const openEntitiesOnBoarding = () => {
    return ({
        type: OPEN_ENTITIES_ONBOARDING,
    });

};

export const closeEntitiesOnBoarding = () => {
    return ({
        type: CLOSE_ENTITIES_ONBOARDING
    });

};
