import importedStyles from "./EventCard.module.sass";
import CardMedia from "@material-ui/core/CardMedia";
import {CardContent, Divider, Fade, ListItem, ListItemText, Typography} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {
    CalendarTodayRounded, EventRounded,
    FavoriteBorderOutlined,
    FavoriteOutlined,
    LocationOnRounded,
    ScheduleRounded
} from "@material-ui/icons";
import moment from "moment";
import Button from "@material-ui/core/Button/Button";
import {ReactComponent as ShareArrow} from "../../../icons/share_arrow.svg";
import {generatePath, useRouteMatch} from "react-router-dom";
import history from "../../../helpers/history";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import ShareModal from "../../../components/ShareModal/ShareModal";
import {redColor} from "../../../styles/palette";


const EventCard = (props) => {

    const match = useRouteMatch();
    const schedule = useSelector((state) => state.ScheduleReducer.schedule);

    // STATE
    const [event, setEvent] = useState({});
    const [liked, setLiked] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    useEffect(() => {
        setEvent(props.event);
    }, [props.event])

    useEffect(() => {


        let likedCookie = localStorage.getItem('likeEvent' + event.id);
        if (likedCookie) {
            setLiked(true)
        } else {
            localStorage.removeItem('likeEvent' + event.id);
            setLiked(false);
        }


    }, [event]);

    const handleLike = (e) => {
        e.stopPropagation();

        let eventCopy = {...event};
        if (!liked) {
            localStorage.setItem('likeEvent' + event.id, 'true');
            eventCopy.likes = parseInt(eventCopy.likes) + 1;
        }

        if (liked) {
            localStorage.removeItem('likeEvent' + event.id);
            eventCopy.likes = parseInt(eventCopy.likes) - 1;
        }

        localStorage.setItem('eventLikesCount' + event.id, eventCopy.likes);
        window.dispatchEvent(new Event("storage"));

        setEvent(eventCopy);
        setLiked(!liked);
    }

    useEffect(() => {
        function checkLikesData() {

            let likedCookie = localStorage.getItem('likeEvent' + event.id);
            if (likedCookie) {
                setLiked(true)
            } else {
                localStorage.removeItem('likeEvent' + event.id);
                setLiked(false);
            }

            let likesCounter = localStorage.getItem('eventLikesCount' + event.id);

            if (likesCounter && parseInt(likesCounter) !== event.likes) {
                let eventCopy = {...event};
                eventCopy.likes = likesCounter;
                setEvent(eventCopy);
            }
        }

        window.addEventListener('storage', checkLikesData);

        // checkLikesData();

        return () => {
            window.removeEventListener('storage', checkLikesData)
        }
    }, [event])


    // RENDERS


    // HANDLERS

    const handleEventCardClick = () => {
        let newPath = generatePath(match.path, {scheduleIdentifier: schedule.url, eventIdentifier: event.url});
        history.push(newPath);
    }


    const handleShare = (e) => {
        e.stopPropagation();
        if (isMobile && navigator.share) {

            navigator
                .share({
                    url: window.location.origin + generatePath(match.path, {
                        scheduleIdentifier: schedule.url,
                        eventIdentifier: event.url
                    })
                })
                .then(() => {
                    console.log('Compartido con éxito! 🎉')
                })
                .catch(err => {
                    console.error(err)
                });
        } else {
            setShareModalOpen(true);
        }
    }

    // MAIN RENDER
    return (
        <Card className={importedStyles.root} elevation={0} key={'eventCard' + props.id} onClick={handleEventCardClick}>

            <CardMedia
                className={importedStyles.mainImage}
                image={event.main_image}
                title={event.title}
            >
                {/*<Fade in={event.hasOwnProperty('category') && event.category.length > 0} mountOnEnter unmountOnExit>*/}
                {/*    <div className={importedStyles.categoryChip}>*/}
                {/*        {event.category}*/}
                {/*    </div>*/}
                {/*</Fade>*/}
            </CardMedia>
            <Box style={{
                display: 'flex',
                alignItems: 'center',
                // background: '#003fff',
                background: '#67b8c1',
                borderRadius: 12,
                marginTop: '-1.35rem',
                marginLeft: 16,
                zIndex: 5,
                width: 'fit-content',
                padding: '0.5rem',
            }}>
                <EventRounded style={{color: '#fff'}}/>
                <Typography style={{
                    marginLeft: 5,
                    marginTop: 3,
                    textTransform: 'uppercase',
                    color: '#fff',
                    marginRight: 15,
                    fontWeight: 600
                }}>
                    {moment(event.date).format('ddd DD/MM/YY')}
                </Typography>

                <ScheduleRounded style={{color: '#fff'}}/>
                <Typography style={{marginLeft: 5, marginTop: 3, color: '#fff', fontWeight: 600}}>
                    {moment(event.date).format('HH:mm')}h
                </Typography>

            </Box>


            <CardContent style={{padding: 0, minHeight: '7.5rem'}}>
                <ListItem>
                    <ListItemText primary={event.title}
                                  classes={{primary: importedStyles.title}}/>
                </ListItem>
                <ListItem style={{marginTop: -15}}>
                    <ListItemText classes={{secondary: importedStyles.subtitle}}
                                  secondary={event.description ? event.description : ''}/>
                </ListItem>
                <Fade in={event.location} mountOnEnter unmountOnExit>
                    <ListItem style={{marginTop: -15}}>
                        <LocationOnRounded
                            style={{color: '#f00', marginLeft: -3, width: '1.75rem', height: '1.75rem'}}/>
                        <ListItemText primary={event.location}/>
                    </ListItem>
                </Fade>


            </CardContent>
            <Fade in={true} unmountOnExit mountOnEnter>
                <Divider style={{backgroundColor: '#BDBDBD', height: 1, marginTop: 12}} variant={"middle"}/>
            </Fade>

            <Fade in={true} unmountOnExit mountOnEnter>
                <CardActions className={importedStyles.actions}>

                    <Button style={{
                        background: '#101010',
                        borderRadius: 455,
                        textTransform: "unset",
                        color: '#fff',
                        fontSize: 18,
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem'
                    }}>
                        Més info
                    </Button>

                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 400,
                            fontSize: '1.5rem',
                        }} onClick={handleLike}>
                            {liked ? <FavoriteOutlined style={{fill: redColor,  strokeWidth: 1 }}/> :
                                <FavoriteBorderOutlined style={{fill: '#000',  strokeWidth: 1 }}/>} {event.likes}
                        </Box>
                        <Typography style={{fontSize: '0.7rem', fontWeight: 500}}>m' agrada</Typography>
                    </Box>

                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: '1s ease-in',
                        borderRadius: 355,
                        '&:hover': {
                            background: 'rgba(225,215,215,0.84)'
                        }
                    }} onClick={handleShare}>
                        <ShareArrow style={{fill: '#000', width: 25, height: 25, marginBottom: '0.1rem'}}/>
                        <Typography style={{fontSize: '0.7rem', fontWeight: 500}}>compartir</Typography>
                    </Box>

                </CardActions>
            </Fade>
            <ShareModal open={shareModalOpen} onClose={() => setShareModalOpen(false)}/>
        </Card>
    )
}

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
};
EventCard.defaultProps = {
    //
};


export default EventCard;
