import {
    CLOSE_SUGGESTED_LOGIN_MODAL,
    OPEN_SUGGESTED_LOGIN_MODAL,
} from "../../../constants/action-types";


const initialState = {
    open: false,
    nextRoute: '.',
};

const suggestedLoginModalReducer = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_SUGGESTED_LOGIN_MODAL:
            return {...state, open: true, nextRoute: action.nextRoute};
        case CLOSE_SUGGESTED_LOGIN_MODAL:
            return {...state, open: false, nextRoute: '.'};
        default:
            return state;
    }
};


export default suggestedLoginModalReducer;