import {SET_REQUEST, SET_UPLOAD_REQUEST, SET_VIDEO_TO_REQUEST_FLAG} from "../../../constants/action-types";


const initialState = {
    request: {},
};

const requestFilesReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_REQUEST:
            return {...state, request: action.request};
        case SET_VIDEO_TO_REQUEST_FLAG:
            return {...state, videoToRequestFlag: action.videoToRequestFlag};
        case SET_UPLOAD_REQUEST:
            return {...state, request: state.request, uploadRequest: action.uploadRequest};
        default:
            return state;
    }
};


export default requestFilesReducer;
