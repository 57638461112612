import {SET_CATEGORIES, SET_CATEGORY_ACTIVE} from "../../../constants/action-types";

export const setCategories = categories => {

    return ({
        type: SET_CATEGORIES,
        categories: categories
    });

};

export const setCategoryActive = category => {

    return ({
        type: SET_CATEGORY_ACTIVE,
        category: category
    });

};
