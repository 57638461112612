import React from "react";
import PropTypes from "prop-types";
import importedStyles from "./PageTitle.module.sass";

const PageTitle = (props) => {
    return (
        <div className={importedStyles.titleContainer}>
            <h1 className={importedStyles.title}>{props.title}</h1>
            <h3 className={importedStyles.subtitle}>{props.subtitle}</h3>
        </div>
    )
}

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.any,
};
export default (PageTitle);
