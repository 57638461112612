import {CLOSE_FIRST_LOGIN_MODAL, OPEN_FIRST_LOGIN_MODAL,} from "../../../constants/action-types";


const initialState = {
    open: false,
};

const firstLoginOnBoardReducer = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_FIRST_LOGIN_MODAL:
            return {...state, open: true};
        case CLOSE_FIRST_LOGIN_MODAL:
            return {...state, open: false};
        default:
            return state;
    }
};


export default firstLoginOnBoardReducer;