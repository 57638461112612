import history from "./history";
import * as qs from "query-string";
import {
    closeSuggestedLoginModal,
    openSuggestedLoginModal
} from "../components/SuggestedLoginModal/Redux/SuggestedLoginModalActions";
import {closeRegisterModal, openRegisterModal} from "../components/RegisterModal/Redux/RegisterModalActions";
import {store} from "../redux/store";


export const removeAuthParams = () => {
    let params = history.location.search;
    params = params.replace('?register=true', '');
    params = params.replace('?registerEntity=true', '');
    params = params.replace('?login=true', '');

    return params;
};

export const checkAuthParams = () => {
    const params = qs.parse(window.location.search);
    if (params.register || params.registerEntity) {
        store.dispatch(closeSuggestedLoginModal());
        store.dispatch(openRegisterModal());
    } else if (params.login) {
        store.dispatch(closeRegisterModal());
        store.dispatch(openSuggestedLoginModal());
    }
};