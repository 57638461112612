import apiBaseUrl from "../apiBaseUrl";


export function loginRequest(email, password, videoUuidUploaded){
    return fetch(apiBaseUrl + 'auth/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, password, videoUuidUploaded})
    });
}

export function ReloginRequest(uuid, isEntity){
    return fetch(apiBaseUrl + 'auth/reLogin', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({userUuid: uuid, isEntity})
    });
}

export function validatePromoCode(uuid, promoCode){
    return fetch(apiBaseUrl + 'auth/validatePromoCode', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({userUuid: uuid, promoCode: promoCode})
    });
}