import React from "react";
import Card from "@material-ui/core/Card";
import connect from "react-redux/es/connect/connect";
import Snack from "../../components/Snack/Snack";
import importedStyles from './RequestPasswordChange.module.sass';
import {Avatar} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import history from "../../helpers/history";
import ReactGA from "react-ga";
import grabaloIcon from "../../images/grabalo-icon.png";
import {Helmet} from "react-helmet/es/Helmet";

class RequestPasswordChange extends React.Component {

    state = {
        email: '',
        changeRequestConfirmed: false,
        waitingResponse: false,
    };

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        ReactGA.pageview(window.location.pathname);
    }

    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    requestPasswordChange = () => {
        this.setState({waitingResponse: true});
        let email = this.state.email;

        fetch(apiBaseUrl + 'auth/requestPasswordChange', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email})
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({waitingResponse: false, changeRequestConfirmed: true});
                this.props.dispatch(showSnack('Te hemos enviado un correo con las intrucciones para cambiar tu contraseña, échale un vistazo a la bandeja de spam si no lo encuentras', snackTypes.success));
            } else if (response.errorType === 'notFound') {
                this.setState({waitingResponse: false});
                this.props.dispatch(showSnack('El email introducido no existe en nuestro sistema', snackTypes.error));
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.setState({waitingResponse: false});
            this.props.dispatch(showSnack('Ha ocurrido un error, por favor vuelve a intentarlo más tarde', snackTypes.error));
        });
    };

    renderForm = () => {
        return (
            <div className={importedStyles.form}>
                <TextField
                    margin={"normal"}
                    id="email"
                    name="email"
                    label={'Correo electrónico'}
                    type="email"
                    inputProps={{maxLength: 50}}
                    value={this.state.email}
                    fullWidth
                    onChange={this.handleInputChange}
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            document.getElementById('submitButton').click();
                        }
                    }}
                />
            </div>
        )
    };
    renderConfirmationMessage = () => {
        return (
            <React.Fragment className={importedStyles.form}>
                <p className={importedStyles.confirmationMessage}>
                    Hemos recibido tu solicitud para el cambio de contraseña
                </p>
            </React.Fragment>
        )
    };
    renderReturnButton = () => {
        return (
            <Button fullWidth
                    onClick={() => history.push('/login')}
                    color={"primary"}
                    variant={"contained"}
                    className={importedStyles.actionButton}>
                Volver
            </Button>
        )
    };
    renderActionButton = () => {
        return (
            <Button
                id={'submitButton'}
                variant={'contained'}
                fullWidth
                color={"primary"}
                disabled={this.state.email.length <= 0 || this.state.waitingResponse}
                onClick={this.requestPasswordChange}
                className={importedStyles.actionButton}
            >
                Cambiar contraseña
                {this.state.waitingResponse &&
                <CircularProgress size={25} className={importedStyles.circularProgress}/>}
            </Button>
        )
    };


    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Cambio de contraseña</title>
                </Helmet>
                <div className={importedStyles.RequestPasswordChange}>
                    <Card className={importedStyles.card} elevation={0}>
                        <CardContent className={importedStyles.cardContent}>
                            <Avatar src={grabaloIcon} className={importedStyles.logo}/>
                            <p className={importedStyles.subtitle}>Escribe tu correo y te enviaremos un email con
                                las instrucciones</p>
                            <TextField
                                margin={"normal"}
                                id="email"
                                name="email"
                                label={'Correo electrónico'}
                                type="email"
                                inputProps={{maxLength: 50}}
                                value={this.state.email}
                                fullWidth
                                onChange={this.handleInputChange}
                                onKeyPress={(e) => {
                                    if (e.which === 13) {
                                        document.getElementById('submitButton').click();
                                    }
                                }}
                            />
                            {this.renderActionButton()}
                        </CardContent>
                    </Card>
                </div>
                <Snack/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({LandingReducer}) => {
    return ({});
};
export default connect(mapStateToProps)(RequestPasswordChange);
