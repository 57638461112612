export default class TimeMonitor
{
    constructor()
    {
        this.reset();
    }

    reset()
    {
        this.startTime = new Date().getTime();
        this.lastTime = this.startTime;
        this.nowTime = this.startTime;
    }

    static check()
    {
        this.nowTime = new Date().getTime();
        const elapsed = this.nowTime - this.lastTime;
        this.lastTime = this.nowTime;

        return elapsed;
    }

}
