import React from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import importedStyles from './OnBoardingEntities.module.sass';
import connect from "react-redux/es/connect/connect";
import Lottie from "react-lottie";
import uploadFiles from "../../animations/uploadFiles"
import {Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CamaraIcon from "@material-ui/icons/Videocam";
import IconButton from "@material-ui/core/IconButton";
import history from "../../helpers/history";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import ReactImageAppear from "react-image-appear";
import {closeEntitiesOnBoarding} from "./Redux/OnBoardingEntitiesActions";


class OnBoardingEntities extends React.Component {

    state = {
        actualStep: 1,
        testOpen: true,
        webpAllowed: true,
    };


    componentDidMount = async () => {
        if (!await this.supportsWebp()) {
            this.setState({webpAllowed: false});
        }
    };

    supportsWebp = async() => {
        if (!window.self.createImageBitmap) return false;
        const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
        const blob = await fetch(webpData).then(r => r.blob());
        return createImageBitmap(blob).then(() => true, () => false);
    };

    //What happens when onboarding gets *opened* (not when rendered/mounted)
    onDialogOpen = () => {
        ReactPixel.trackCustom('onBoardingEntitiesOpen');
        ReactGA.ga('send', 'event', 'onBoarding', 'onBoardingEntitiesOpen');
    };

    //When the modal is closed by the user intervention (click on close icon, not CTA)
    onDialogClose = () => {
        ReactPixel.trackCustom('onBoardingEntitiesClosed');
        ReactGA.ga('send', 'event', 'onBoarding', 'onBoardingEntitiesClosed');
        this.props.dispatch(closeEntitiesOnBoarding());
    };


    //Controls the behavior when user click de final action button when ends the onboarding
    onCTAClick = () => {
        ReactPixel.trackCustom('onBoardingEntitiesCTAClick');
        ReactGA.ga('send', 'event', 'onBoarding', 'onBoardingEntitiesCTAClick');
        this.props.dispatch(closeEntitiesOnBoarding());
    };

    onCTAShowVideosClick = async () => {
        ReactPixel.trackCustom('onBoardingEntitiesCTAShowVideosClick');
        ReactGA.ga('send', 'event', 'onBoarding', 'onBoardingEntitiesCTAShowVideosClick');
        await this.props.dispatch(closeEntitiesOnBoarding());
        history.push('/categories');
    };

    onCTAThrowChallengeClick = async () => {
        ReactPixel.trackCustom('onBoardingEntitiesCTAThrowChallengeClick');
        ReactGA.ga('send', 'event', 'onBoarding', 'onBoardingEntitiesCTAThrowChallengeClick');
        await this.props.dispatch(closeEntitiesOnBoarding());
        window.open('https://form.typeform.com/to/UHyiTutJ', '_blank');
    };


    //Handle passing to the next step
    handleNextStep = () => {
        this.setState({actualStep: this.state.actualStep + 1})
    };

    renderAnimation = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: uploadFiles,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
                    speed={0.5}
            />
        )
    };

    //Render the modal head IMG
    renderCover(src, name) {
        return (
            <div className={importedStyles.cover}>
                {/*<img src={src} alt={name} className={importedStyles.coverImg} />*/}
                <ReactImageAppear
                    key={name + (this.state.webpAllowed ? 'webp' : 'jpg')}
                    src={src + (this.state.webpAllowed ? '.webp' : '.jpg')}
                    alt={name}
                    //animation="blurIn"
                    loader={'img/loading.gif'}
                    // placeholderClass={importedStyles.coverImg}
                    // placeholder={'/img/png/thumbnail.png'}
                    animationDuration="300ms"
                    className={importedStyles.coverImg}
                />
                <div className={importedStyles.coverNameContainer}>
                    <CamaraIcon style={{
                        color: '#fff', filter: 'drop-shadow(1px 2px 3px black)'
                    }}/>
                    <p className={importedStyles.coverName}>{name}</p>
                </div>
            </div>
        )
    };

    //Change modal content background according to actual step
    getBackgroundColor = () => {
        switch (this.state.actualStep) {
            case 2:
                return importedStyles.backPrimaryColor;
            case 3:
                return importedStyles.backBlueColor;
            default:
                return null;
        }
    };

    //Control which content to render according to actual step
    renderDialogContent = () => {
        switch (this.state.actualStep) {
            case 1:
                return this.renderFirstStep();
            case 2:
                return this.renderSecondStep();
            case 3:
                return this.renderThirdStep();
            case 4:
                return this.renderFourthStep();
            default:
                return null;
        }
    };

    //Every step content render method
    renderFirstStep = () =>  {
        return (
            <>
                <DialogContent className={importedStyles.dialogContent}>
                    {this.renderCover('/img/onBoarding/onboard1small', 'Manel Carrera')}
                    <DialogContentText className={importedStyles.dialogContentText}>
                        <Typography className={importedStyles.congratulations}>¡Te damos la bienvenida a
                            Grábalo!</Typography>
                        <Typography className={importedStyles.selledVideoCopy}>
                            Conecta con tus usuarios/clientes y consigue vídeos de experiencias reales
                            sin esfuerzo para tus estrategias de marketing.
                        </Typography>
                    </DialogContentText>

                </DialogContent>
                <DialogActions className={importedStyles.dialogActions}>
                    <Button fullWidth className={[importedStyles.showLogroButton, importedStyles.backPrimaryColor]}
                            onClick={this.handleNextStep}>
                        Siguiente
                    </Button>
                </DialogActions>
            </>
        );
    };
    renderSecondStep = () => {
        return (
            <>
                <DialogContent className={[importedStyles.dialogContent, importedStyles.backPrimaryColor]}>
                    {this.renderCover('/img/onBoarding/onboard2small', 'Claraneb')}
                    <DialogContentText className={importedStyles.dialogContentText}>
                        <Typography className={[importedStyles.congratulations, importedStyles.whiteColor]}>
                            Lanza un reto y haz partícipe a tus usuarios/clientes
                        </Typography>
                        <Typography className={[importedStyles.selledVideoCopy, importedStyles.whiteColor]}>
                            Píde a tu audiencia que participe en el reto y consigue vídeos reales bajo demanda para aumentar tus conversiones.
                        </Typography>
                    </DialogContentText>

                </DialogContent>
                <DialogActions className={[importedStyles.dialogActions, importedStyles.backPrimaryColor]}>
                    <Button fullWidth className={[importedStyles.showLogroButton, importedStyles.primaryColor]}
                            onClick={this.handleNextStep}>
                        Continuar
                    </Button>
                </DialogActions>
            </>
        );
    };
    renderThirdStep = () => {
        return (
            <>
                <DialogContent className={[importedStyles.dialogContent, importedStyles.backBlueColor]}>
                    {this.renderCover('/img/onBoarding/onboard3small', 'Thomas Rich')}
                    <DialogContentText className={importedStyles.dialogContentText}>
                        <Typography className={[importedStyles.congratulations, importedStyles.whiteColor]}>
                            Aumenta el engagement con vídeos de la comunidad Grábalo
                        </Typography>
                        <Typography className={[importedStyles.selledVideoCopy, importedStyles.whiteColor]}>
                            Encuentra los vídeos que necesitas como si de un banco de vídeos tradicional se tratara.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={[importedStyles.dialogActions, importedStyles.backBlueColor]}>
                    <Button fullWidth className={[importedStyles.showLogroButton, importedStyles.blueColor]}
                            onClick={this.handleNextStep}>
                        Siguiente
                    </Button>
                </DialogActions>
            </>
        );
    };
    renderFourthStep = () => {
        return (
            <>
                <IconButton className={importedStyles.closeModalIcon} onClick={this.onDialogClose}>
                    <CloseIcon/>
                </IconButton>

                <DialogContent className={[importedStyles.dialogContent, importedStyles.backBlueColorLight]}>
                    {this.renderCover('/img/onBoarding/onboard4small', 'IsisFeliz3')}
                    <DialogContentText className={importedStyles.dialogContentText}>
                        <Typography className={importedStyles.congratulations}>
                            Venga, mejora tu marketing
                        </Typography>
                        <Typography className={importedStyles.selledVideoCopy}>
                            Ahora que ya sabes que puede hacer Grábalo por tu marca, ha llegado el momento de elegir:
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={[importedStyles.dialogActions, importedStyles.backBlueColorLight]}>
                    <Button fullWidth className={[importedStyles.showVideosButton]}
                            onClick={this.onCTAShowVideosClick}>
                        Ver vídeos
                    </Button>
                    <Button fullWidth className={[importedStyles.showLogroButton, importedStyles.backBlueColor4]}
                            onClick={this.onCTAThrowChallengeClick}>
                        Lanzar reto
                    </Button>
                </DialogActions>
            </>
        );
    };



    render() {
        //Gives a boolean to know if is a mobile or not, so we can make modal FullScreen
        let fullScreen = window.matchMedia("(max-width: 768px)").matches;

        //Little fix to add a variable background color by calling a function who returns different classnames for different backgrounds
        let rootClassnames = importedStyles.dialogRoot + ' ' + this.getBackgroundColor();

        return (
            <Dialog
                open={this.props.open}
                //open={this.state.testOpen}
                onEntered={this.onDialogOpen}
                disableBackdropClick
                scroll={"body"}
                maxWidth={"xs"}
                fullWidth
                aria-labelledby="first login entities onboard modal"
                style={{zIndex: 5000}}
                fullScreen={fullScreen}
                classes={{
                    paper: rootClassnames,
                    paperFullScreen: importedStyles.dialogMobileFullScreen,
                }}
                BackdropProps={{
                    className: importedStyles.dialogBackdrop
                }}
            >
                {this.renderDialogContent()}

            </Dialog>
        );

    }
}

const mapStateToProps = ({authReducer, OnBoardingEntitiesReducer}) => {
    return ({
        open: OnBoardingEntitiesReducer.open,
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(OnBoardingEntities);