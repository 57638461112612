import {SET_ORIENTATION, CLEAN_TERM, SET_TERM, SET_ORDER} from "../../../constants/action-types";


const initialState = {
    term: '',
    orientation: null,
    order: null,
};

const searchReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ORIENTATION:
            return {...state, orientation: action.orientation};
        case SET_ORDER:
            return {...state, order: action.order};
        case SET_TERM:
            return {...state, term: action.term};
        case CLEAN_TERM:
            return {...state, term: ''};
        default:
            return state;
    }
};


export default searchReducer;