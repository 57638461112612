import React from 'react';
import importedStyles from "./CookiesBanner.module.sass";
import {Cookies} from "react-cookie";
import {Button, Container, Divider, Typography, withStyles} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {isNoCookiesView} from "../../helpers/utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import {primaryColor} from "../../styles/palette";

const cookiesManager = new Cookies();

const CustomSwitch = withStyles({
    switchBase: {
        color: 'rgba(16,33,46,0.62)',
        '&$checked': {
            color: primaryColor,
        },
        '&$checked + $track': {
            backgroundColor: primaryColor,
        },
    },
    checked: {},
    track: {},
})(Switch);

export class CookiesBanner extends React.Component {

    state = {
        showBanner: true,
        openSettingsModal: false,
        cookies: ['essentialCookie', 'functionalCookie', 'marketingCookie', 'analyticsCookie'],
        essentialCookieSwitch: false,
        functionalCookieSwitch: false,
        marketingCookieSwitch: false,
        analyticsCookieSwitch: false,

    };

    componentDidMount() {
        let alreadyAcceptedSomeCookies = cookiesManager.get('essentialCookie');
        let noCookiesView = isNoCookiesView();

        if (alreadyAcceptedSomeCookies || noCookiesView) this.setState({showBanner: false});

        this.checkAllCookies();
    }



    checkAllCookies = () => {

        for (let i = 0; i < this.state.cookies.length; i++) {
            let cookieName = this.state.cookies[i];
            let cookieSwitchName = cookieName + 'Switch';
            let cookie = cookiesManager.get(cookieName);
            if (cookie) {
                this.setState({
                    [cookieSwitchName]: true
                });
                //cookieName === 'analitycsCookie' && initializeAnalytics();

            } else {
                this.setState({
                    [cookieSwitchName]: false
                });
            }
        }
    };

    setAcceptedCookie = (cookieName) => {
        let expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        cookiesManager.set(cookieName, true, {path: '/', expires: expireDate});
    };

    declineCookie = (cookieName) => {
        cookiesManager.remove(cookieName);
    };


    onCookieSwitchChange = (switchName) => () => {
        this.setState((prevState, prevProps) => {
            return {[switchName]: !prevState[switchName]}
        })
    }

    acceptAllCookies = () => {
        this.setAcceptedCookie('essentialCookie');
        this.setAcceptedCookie('marketingCookie');
        this.setAcceptedCookie('functionalCookie');
        this.setAcceptedCookie('analyticsCookie');
        this.toggleSettingsModal();
        this.setState({showBanner: false, openSettingsModal: false});
    };

    setUserSelectedCookies = () => {
        this.setAcceptedCookie('essentialCookie');
        this.state.marketingCookieSwitch ? this.setAcceptedCookie('marketingCookie') : this.declineCookie('marketingCookie');
        this.state.functionalCookieSwitch ? this.setAcceptedCookie('functionalCookie') : this.declineCookie('functionalCookie');
        this.state.analyticsCookieSwitch ? this.setAcceptedCookie('analyticsCookie') : this.declineCookie('analyticsCookie');
        this.toggleSettingsModal();
        this.setState({showBanner: false, openSettingsModal: false});
    }


    toggleSettingsModal = () => {
        this.setState((prevState, prevProps) => {
            return {
                openSettingsModal: !prevState.openSettingsModal
            }
        })
    }


    renderCookiesSettingsContent = () => {
        return (
            <React.Fragment>
                <div className={'cookieTypeContainer'}>
                    <Typography variant={"h6"} className={importedStyles.cookieTypeTitle}>
                        Cookies esenciales
                        <CustomSwitch
                            checked={true}
                            disabled={true}
                            name="checkedB"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Typography>
                    <p className={importedStyles.cookiesSectionDescription}>
                        Estas cookies permiten funciones básicas como la seguridad, la verificación de la identidad y la
                        administración de la red. Estas cookies no pueden ser desactivadas.
                    </p>

                </div>
                <Divider variant={'middle'} className={importedStyles.cookiesSectionDivider}/>

                <div className={'cookieTypeContainer'}>
                    <Typography variant={"h6"} className={importedStyles.cookieTypeTitle}>
                        Cookies de marketing
                        <CustomSwitch
                            checked={this.state.marketingCookieSwitch}
                            onChange={this.onCookieSwitchChange('marketingCookieSwitch')}
                            color="primary"
                            inputProps={{'aria-label': 'marketingCookieSwitch'}}
                        />
                    </Typography>
                    <p className={importedStyles.cookiesSectionDescription}>
                        Estas cookies se utilizan para hacer un seguimiento de la eficacia de la publicidad para
                        proporcionar un servicio más relevante y ofrecer mejores anuncios que se ajusten a tus
                        intereses.
                    </p>

                </div>
                <Divider variant={'middle'} className={importedStyles.cookiesSectionDivider}/>

                <div className={'cookieTypeContainer'}>
                    <Typography variant={"h6"} className={importedStyles.cookieTypeTitle}>
                        Cookies funcionales
                        <CustomSwitch
                            checked={this.state.functionalCookieSwitch}
                            onChange={this.onCookieSwitchChange('functionalCookieSwitch')}
                            name="functionalCookieSwitch"
                            inputProps={{'aria-label': 'functionalCookieSwitch'}}
                        />
                    </Typography>
                    <p className={importedStyles.cookiesSectionDescription}>
                        Estas cookies recopilan datos para recordar las elecciones que los usuarios hacen, para mejorar
                        y
                        ofrecer una experiencia más personalizada.
                    </p>

                </div>
                <Divider variant={'middle'} className={importedStyles.cookiesSectionDivider}/>

                <div className={'cookieTypeContainer'}>
                    <Typography variant={"h6"} className={importedStyles.cookieTypeTitle}>
                        Cookies de estadísticas
                        <CustomSwitch
                            checked={this.state.analyticsCookieSwitch}
                            onChange={this.onCookieSwitchChange('analyticsCookieSwitch')}
                            name="analyticsCookieSwitch"
                            inputProps={{'aria-label': 'analyticsCookieSwitch'}}
                        />
                    </Typography>
                    <p className={importedStyles.cookiesSectionDescription}>
                        Estas cookies nos ayudan a entender cómo los visitantes interactúan con nuestro sitio web,
                        descubren
                        errores y proporcionan mejores estadísticas generales.
                    </p>
                </div>
            </React.Fragment>


        )
    }

    renderBanner = () => {
        return (
            <div id={'cookiesBanner'} className={importedStyles.mainContainer}>
                <span className={importedStyles.bannerText}>
                Usamos cookies en nuestra página web para ver cómo interactúas con ella. Al aceptarlas, estás de acuerdo con nuestro uso de dichas cookies.
                    <a href={'https://grabaloapp.com/politica-de-privacidad'} target={'_blank'}>Ver Política de
                    Privacidad</a>
                </span>

                <div className={importedStyles.bannerActions}>
                    <Button onClick={this.toggleSettingsModal} className={importedStyles.cookiesSettingsButton}>
                        Opciones de cookies
                    </Button>
                    <Button onClick={this.acceptAllCookies} className={importedStyles.cookiesAcceptAllButton}>
                        Aceptar
                    </Button>
                </div>
            </div>
        );
    }


    render() {
        return (
            <React.Fragment>
                {this.state.showBanner && this.renderBanner()}
                {/*{this.renderBanner()}*/}
                <Dialog
                    className={importedStyles.cookiesSettingsModal}
                    open={this.state.openSettingsModal}
                    fullScreen={false}
                    onEnter={this.checkAllCookies}
                    fullWidth
                    classes={{paper: importedStyles.cookiesSettingsModalPaper}}
                    disableBackdropClick
                >
                    <DialogTitle disableTypography>
                        <span style={{fontWeight: 600, fontSize: '1.6rem'}}>Preferencias avanzadas de cookies</span>
                    </DialogTitle>
                    <DialogContent>
                        {this.renderCookiesSettingsContent()}
                    </DialogContent>
                    <DialogActions className={importedStyles.settingsModalActions}>
                        <Button  size={'small'} onClick={this.setUserSelectedCookies} className={importedStyles.savePreferencesCookiesButton}>
                            Guardar preferencias
                        </Button>
                        <Button className={importedStyles.settingsModalAcceptAllCookiesButton} variant={'contained'}
                                onClick={this.acceptAllCookies}>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
