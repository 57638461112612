import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../../components/NavBar/NavBar";
import importedStyles from './NewRequest.module.sass';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import apiBaseUrl from "../../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import moment from "moment";
import PlacesAutocomplete from 'react-places-autocomplete';
import history from "../../../helpers/history";
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import ReactGA from "react-ga";
import DesktopDrawer from "../../../components/DesktopDrawer/DesktopDrawer";
import BackButton from "../../../components/BackButton/BackButton";
import ReactPixel from "react-facebook-pixel";
import {Helmet} from "react-helmet/es/Helmet";

class NewRequest extends React.Component {

    state = {
        title: '',
        //description: '',
        location: '',
        province: '',
        limitDate: null,
    };

    componentDidMount = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        ReactPixel.trackCustom('NewRequestView', {user: this.props.user.uuid});
    };

    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    handleDateChange = (input) => {
        this.setState({limitDate: Date.parse(input._d)});
    };

    handleCityChange = city => {
        let comaPosition = city.indexOf(',');
        if (comaPosition !== -1) {
            city = city.substring(0, comaPosition);
        }
        this.setState({location: city});
    };

    handleProvinceChange = region => {
        let comaPosition = region.indexOf(',');
        if (comaPosition !== -1) {
            region = region.substring(0, comaPosition);
        }
        this.setState({province: region});
    };

    handleUpload = () => {
        this.setState({
            waitingResponse: true
        });

        let title = this.state.title;
        let description = this.state.description;
        let limitDate = this.state.limitDate;
        let location = this.state.location;
        let province = this.state.province;
        let entityId = this.props.user.uuid;


        fetch(apiBaseUrl + 'requests/createVideoRequest', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: title,
                description: description,
                limitDate: moment(limitDate).format('YYYY-MM-DD'),
                location: location,
                province: province,
                entityId: entityId
            })
        }).then(res => res.json())
            .then(
                (response) => {
                    if (!response.error) {
                        this.setState({waitingResponse: false});
                        ReactPixel.trackCustom('CreateNewRequestSuccess', {user: this.props.user.uuid});
                        ReactGA.ga('send', 'event', 'click', 'createNewRequestSuccess');
                        this.props.dispatch(showSnack('Reto enviado', snackTypes.success));
                        history.goBack();
                    } else {
                        switch (response.errorType) {
                            case 'alreadyExists':
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error, el titulo de tu reto ya está en uso.', snackTypes.error));
                                break;
                            default:
                                this.setState({waitingResponse: false});
                                this.props.dispatch(showSnack('Error al enviar el reto, vuelva a intentarlo', snackTypes.error));
                                ReactPixel.trackCustom('CreateNewRequestError', {error: response.errorType});
                                break;
                        }
                    }
                },
                (error) => {
                    console.log('Error: ' + error);
                    this.setState({
                        waitingResponse: false,
                        isLoaded: true,
                        error
                    });
                    ReactPixel.trackCustom('CreateNewRequestError', {error: error});
                    ReactGA.ga('send', 'event', 'click', 'createNewRequestError', 'Error:'+error+'');

                    this.props.dispatch(showSnack('Error al enviar el reto, vuelva a intentarlo', snackTypes.error));
                }
            )
    };
    renderAutocompleteInput = (value, label, handle, types) => {

        const searchOptions = {
            //componentRestrictions: {country: countryCode},
            types: types
        };

        return (
            <PlacesAutocomplete
                value={value}
                onChange={handle}
                searchOptions={searchOptions}
            >
                {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <React.Fragment>
                        <TextField
                            margin="normal"
                            label={label}
                            type="text"
                            type="text"
                            fullWidth
                            onKeyPress={(e) => {
                                if (e.which === 13) {
                                    document.getElementById('submitButton').click();
                                }
                            }}
                            {...getInputProps({
                                autoComplete: "nope"
                            })}
                        />

                        <div>
                            {loading && <div>Generando sugerencias...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? {backgroundColor: '#c4c4c4', cursor: 'pointer', marginBottom: 5}
                                    : {backgroundColor: '#ffffff', cursor: 'pointer', marginBottom: 5};
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >

                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
            </PlacesAutocomplete>
        )
    };

    renderForm = () => {
        return (
            <React.Fragment>
                <div className={importedStyles.form}>
                    <TextField
                        margin="normal"
                        id="title"
                        name="title"
                        label="* Título"
                        type="text"
                        inputProps={{maxLength: 150}}
                        value={this.state.title}
                        fullWidth
                        onChange={this.handleInputChange}
                        onKeyPress={(e) => {
                            if (e.which === 13) {
                                document.getElementById('submitButton').click();
                            }
                        }}
                    />
                    <TextField
                        margin="normal"
                        id="description"
                        name="description"
                        label="* Descripción"
                        type="text"
                        multiline
                        inputProps={{maxLength: 3000}}
                        value={this.state.description}
                        fullWidth
                        onChange={this.handleInputChange}
                    />
                    {this.renderAutocompleteInput(this.state.province, 'Provincia', this.handleProvinceChange, ["(regions)"])}
                    {this.renderAutocompleteInput(this.state.location, 'Ciudad', this.handleCityChange, ["(cities)"])}
                    {/*<MuiPickersUtilsProvider utils={MomentUtils} locale={'es'} moment={moment}>*/}
                    {/*    <KeyboardDatePicker*/}
                    {/*        margin="normal"*/}
                    {/*        id="limitDate"*/}
                    {/*        fullWidth*/}
                    {/*        disablePast*/}
                    {/*        label="* Fecha límite"*/}
                    {/*        value={this.state.limitDate}*/}
                    {/*        onChange={this.handleDateChange}*/}
                    {/*        KeyboardButtonProps={{*/}
                    {/*            'aria-label': 'Cambiar fecha limite',*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</MuiPickersUtilsProvider>*/}
                    <Button id={'submitButton'} className={importedStyles.submitButton}
                            fullWidth
                            variant='contained'
                            disabled={!this.state.title || !this.state.limitDate || this.state.waitingResponse}
                            onClick={this.handleUpload}>
                        CREAR CONCURSO
                        {this.state.waitingResponse &&
                        <CircularProgress size={25} className={importedStyles.circularProgress}/>}
                    </Button>

                </div>
            </React.Fragment>
        );

    };

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Nuevo Concurso</title>
                </Helmet>
                <NavBar/>
                <Snack/>
                <DesktopDrawer/>
                <div className={importedStyles.NewRequest}>
                    <div className={importedStyles.titleContainer}>
                        <BackButton className={importedStyles.arrowBack} prevRoute={'/mis-eventos'}/>
                        <h2 className={importedStyles.title}>Nuevo reto</h2>
                    </div>
                    <h3 className={importedStyles.subtitle}>¿Para qué necesitas vídeos? Cuéntanos.</h3>
                    {(this.props.logged && this.props.user.entity) && this.renderForm()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(NewRequest);
