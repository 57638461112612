import {
    SET_CURRENT_AD_INDEX,
    SET_INDEX_VIDEO_SELECTED,
    SET_POPUP_ADS,
    SET_VIDEO,
    SET_VIDEOS
} from "../../../constants/action-types";

export const setVideo = video => {
    return ({
        type: SET_VIDEO,
        video: video
    });

};
export const setVideos = videos => {
    return ({
        type: SET_VIDEOS,
        videos: videos
    });

};
export const setIndexVideoSelected = index => {
    return ({
        type: SET_INDEX_VIDEO_SELECTED,
        index: index
    });

};
export const setCurrentAdIndex = index => {
    return ({
        type: SET_CURRENT_AD_INDEX,
        index: index
    });

};
export const setPopupAds = ads => {
    return ({
        type: SET_POPUP_ADS,
        data: ads
    });

};
