import React from "react";
import Card from "@material-ui/core/Card";
import connect from "react-redux/es/connect/connect";
import Snack from "../../components/Snack/Snack";
import importedStyles from './ChangePassword.module.sass';
import {Avatar} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as qs from "query-string";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import history from "../../helpers/history";
import ReactGA from "react-ga";
import grabaloIcon from "../../images/grabalo-icon.png";
import {Helmet} from "react-helmet/es/Helmet";


class ChangePassword extends React.Component {

    state = {
        newPassword: '',
        newPassword2: '',
        waitingResponse: false,
        notEquals: false,
        changeConfirmed: false,
    };

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);

    }

    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changePassword = () => {

        this.setState({waitingResponse: true});
        const params = qs.parse(this.props.location.search);
        let token = params.token;
        let isEntity = params.entity;

        fetch(apiBaseUrl + 'auth/passwordChange', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: token, isEntity: isEntity, password: this.state.newPassword})
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.setState({waitingResponse: false, changeConfirmed: true});
                this.props.dispatch(showSnack('Contraseña cambiada con éxito', snackTypes.success));
                setTimeout(() => {
                    history.push('/login')
                }, 3500);
            } else {
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.setState({waitingResponse: false});
            this.props.dispatch(showSnack('Error al cambiar tu contraseña, por favor vuelve a intentarlo más tarde', snackTypes.error));
        });
    };

    checkNotEquals = () => {
        let notEquals = (this.state.newPassword.length > 0 || this.state.newPassword2.length > 0) && this.state.newPassword !== this.state.newPassword2;

       return notEquals;
    };

    renderContent = () => {
        return (
          <React.Fragment>
              <h3 className={importedStyles.subtitle}>Si combinas números, letras y símbolos estarás más protegido</h3>

                  <div className={importedStyles.form}>
                      <TextField
                          margin={"normal"}
                          id="newPassword"
                          name="newPassword"
                          label={'Nueva contraseña'}
                          type="password"
                          inputProps={{maxLength: 50}}
                          value={this.state.newPassword}
                          fullWidth
                          onChange={this.handleInputChange}
                          onKeyPress={(e) => {
                              if (e.which === 13) {
                                  document.getElementById('actionButton').click();
                              }
                          }}
                      />
                      <TextField
                          margin={"normal"}
                          id="newPassword2"
                          name="newPassword2"
                          label={'Repite la contraseña'}
                          type="password"
                          inputProps={{maxLength: 50}}
                          value={this.state.newPassword2}
                          fullWidth
                          error={this.checkNotEquals()}
                          onChange={this.handleInputChange}
                          helperText={this.checkNotEquals() === true && 'Las contraseñas no coinciden'}
                          onKeyPress={(e) => {
                              if (e.which === 13) {
                                  document.getElementById('actionButton').click();
                              }
                          }}
                      />
                  </div>

                    <Button
                      id={'actionButton'}
                      variant={'contained'}
                      color={"primary"}
                      fullWidth
                      disabled={this.state.newPassword.length <= 0 || this.state.newPassword2.length <= 0 || this.state.waitingResponse || this.checkNotEquals() === true}
                      onClick={this.changePassword}
                      className={importedStyles.actionButton}
                  >
                      Confirmar cambio
                      {this.state.waitingResponse && <CircularProgress size={25} className={importedStyles.circularProgress}/>}
                  </Button>

          </React.Fragment>
        );
    };
    renderConfirmationMessage= () => {
        return (
            <React.Fragment>
                <h1>¡A tus ordenes!</h1>
                <p style={{textAlign: 'center'}}>Hemos cambiado tu contraseña, en breve serás redirigido a la pantalla de acceso</p>
            </React.Fragment>
        );
    };

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo - Confirmar cambio contraseña</title>
                </Helmet>
                <div className={importedStyles.ChangePassword}>
                    <Card className={importedStyles.card}>
                        <CardContent className={importedStyles.cardContent}>
                            <Avatar src={grabaloIcon} className={importedStyles.logo}/>
                            {this.state.changeConfirmed ? this.renderConfirmationMessage() : this.renderContent()}
                        </CardContent>
                    </Card>
                </div>
                <Snack/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({LandingReducer}) => {
    return ({});
};
export default connect(mapStateToProps)(ChangePassword);
