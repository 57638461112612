function baseUrl() {

    /* Local DEV server */
   // return 'https://localhost:3000/';

    /* EXTERNAL servers */
     //return 'https://test.grabaloapp.com/'; //TEST
     return 'https://app.grabaloapp.com/'; //PROD v2
     //return 'https://misitio.grabaloapp.com/'; //PROD ALIAS

}

export default baseUrl();
