import apiBaseUrl from "../apiBaseUrl";

export async function sendStat(type, timer, videowallUuid, videoUuid) {
    try {
        let response = await fetch(apiBaseUrl + 'videowall/stats/create', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({type, timer, videowallUuid, videoUuid}),
        })

        if (!response.ok) {
            const message = `An error has occured when storing stat: ${response.status}`;
            throw new Error(message);
        }
        response = await response.json();

        if (response.error) throw new Error(response.errorMessage);

        console.log('stat stored succesfully')

    } catch (error) {
        console.log('stat store ERROR')

        // console.error('Error: ', error);
    }
}
export async function sendAdInteraction(type, adId) {
    try {
        let response = await fetch(apiBaseUrl + 'request-ads/analytics/create', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({adId: adId, type: type}),
        })

        if (!response.ok) {
            const message = `An error has occured when storing ad interaction: ${response.status}`;
            throw new Error(message);
        }
        response = await response.json();

        if (response.error) throw new Error(response.errorMessage);

        console.debug('ad interaction stored succesfully')

    } catch (error) {
        console.debug('ad interaction ERROR')

        // console.error('Error: ', error);
    }
}
export async function sendScheduleAdInteraction(type, adId) {
    try {
        let response = await fetch(apiBaseUrl + 'schedule-ads/analytics/create', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({adId: adId, type: type}),
        })

        if (!response.ok) {
            const message = `An error has occured when storing ad interaction: ${response.status}`;
            throw new Error(message);
        }
        response = await response.json();

        if (response.error) throw new Error(response.errorMessage);

        console.debug('ad interaction stored succesfully')

    } catch (error) {
        console.debug('ad interaction ERROR')

        // console.error('Error: ', error);
    }
}

