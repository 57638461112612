import React from "react";
import connect from "react-redux/es/connect/connect";
import NavBar from "../../components/NavBar/NavBar";
import importedStyles from './Categories.module.sass';
import CategoryCard from "./CategoryCard.js";
import apiBaseUrl from "../../helpers/apiBaseUrl";
import Grid from "@material-ui/core/Grid";
import {setCategories, setCategoryActive} from "./Redux/categoriesActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DesktopDrawer from "../../components/DesktopDrawer/DesktopDrawer";
import Snack from "../../components/Snack/Snack";
import UploadVideoButton from "../../components/UploadVideoButton/UploadVideoButton";
import ReactPixel from "react-facebook-pixel";
import history from "../../helpers/history";
import ReactGA from "react-ga";
import SuggestedLoginModal from "../../components/SuggestedLoginModal/SuggestedLoginModal";
import Helmet from "react-helmet/es/Helmet";
import VideoSoldAlert from "../../components/VideoSoldAlert/VideoSoldAlert";
import UploadRetoButton from "../../components/UploadRetoButton/UploadRetoButton";
import {checkAuthParams, removeAuthParams} from "../../helpers/urlParamsUtilities";


class Categories extends React.Component {

    state = {
        categories: {},
        category: '',
        term: '',
    };

    componentDidMount = () => {

        if (this.props.user && this.props.user.entity)
            history.push("/mis-eventos");

        checkAuthParams();

        window.scrollTo({top: 0, behavior: 'smooth'});
        this.getAllCategories();
        ReactPixel.trackCustom('CategoriesView');
        ReactPixel.pageView();
        ReactGA.pageview(window.location.pathname);
    };


    handleCategoryClick = category => () => {
        ReactPixel.trackCustom('CategorySelected', {category: category.name});
        ReactGA.ga('send', 'event', 'click', 'CategorySelected', category.name);
        this.props.dispatch(setCategoryActive(category));
        let params = removeAuthParams();
        history.push('/listVideos/' + category.urlSlug + params);
    };

    getAllCategories = () => {
        this.setState({loadingCategories: true});
        fetch(apiBaseUrl + 'categories/getCategories', {
            method: 'GET',
        }).then((responseJSON) => {
            return responseJSON.text();
        }).then((recurso) => {
            const response = JSON.parse(recurso);
            if (!response.error) {
                this.setState({
                    categories: response.categories,
                    loadingCategories: false
                });
                this.props.dispatch(setCategories(response.categories));
            } else {
                this.setState({loadingCategories: false});
                throw new Error(response.errorMessage);
            }
        }).catch((error) => {
            this.setState({loadingCategories: false});
            console.log(error);
        });
    };

    renderCategories = () => {
        const categories = this.state.categories;
        let categoryCards = [];

        categoryCards.push(
            <Grid item key={'retos'} xs={12} md={4} className={importedStyles.gridCardItem}>
                <CategoryCard
                    key={0}
                    id={0}
                    name={"Retos Activos"}
                    //driveId={"1cPQ2n26mZQMu-jFdNq0JlmE065p_OCVO"}
                    driveId={"1CmpBBEVOp-UtqPDkRIhgEs3d99M4cvhg"}
                    functionClick={() => history.push("/requests" + history.location.search)}
                />
            </Grid>);

        categoryCards.push(
            <Grid item key={'destacados'} xs={12} md={4}  className={importedStyles.gridCardItem}>
                <CategoryCard
                    key={'destacados'}
                    id={'destacados'}
                    name={"Destacados"}
                    driveId={"19YBL4BowUxy_3YgI5za2fAbGssB7grqE"}
                    functionClick={() => history.push("/destacados" + history.location.search)}
                />
            </Grid>
        );

        Object.keys(categories).forEach((category) => {
            categoryCards.push(
                <Grid item key={'gridItemCategory-' + category.uuid} xs={12} md={4}  className={importedStyles.gridCardItem}>
                    <CategoryCard
                        key={categories[category].uuid}
                        id={categories[category].uuid}
                        name={categories[category].name}
                        driveId={categories[category].driveId}
                        functionClick={this.handleCategoryClick(categories[category])}
                    />
                </Grid>)
        });
        if (categoryCards.length === 0) {
            return (
                <p style={{margin: 20}}>Oops, parece que no podemos recuperar nuestras categorias, intentalo de nuevo en unos minutos...</p>
            );
        } else {
            return categoryCards;
        }
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Grábalo: sube tu vídeo y participa</title>
                    <meta name="description" content="La plataforma definitiva para comprar y vender vídeos grabados con el teléfono móvil"/>
                    <meta name="keywords" content="video, dinero, ganar, subir, vídeos, móvil"/>
                </Helmet>
                <NavBar searchFunction={()=>console.log('Searching')}/>
                <Snack/>
                <div id={'categoriesRoot'} className={importedStyles.categoriesRoot}>
                    <DesktopDrawer/>
                    <Grid container className={importedStyles.categories} spacing={2}>
                        {this.state.loadingCategories ?
                            <CircularProgress className={importedStyles.circularProgress}/> : this.renderCategories()}
                    </Grid>
                </div>
                <SuggestedLoginModal/>
                <VideoSoldAlert/>
                <UploadVideoButton/>
                <UploadRetoButton/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({authReducer, categoriesReducer, SuggestedLoginModalReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        categoryActive: categoriesReducer.categoryActive,
    });
};
export default connect(mapStateToProps)(Categories);
