import {SET_CURRENT_AD_INDEX, SET_POPUP_ADS, SET_SCHEDULE} from "../../../constants/action-types";

export const setSchedule = value => {
    return ({
        type: SET_SCHEDULE,
        schedule: value
    });

};

export const setPopupAds = ads => {
    return ({
        type: SET_POPUP_ADS,
        data: ads
    });

};
export const setCurrentAdIndex = index => {
    return ({
        type: SET_CURRENT_AD_INDEX,
        index: index
    });

};


