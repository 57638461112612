import React from 'react';
import importedStyles from "./BackButton.module.sass";
import connect from "react-redux/es/connect/connect";
import IconButton from "@material-ui/core/IconButton";
import history from "../../helpers/history";
import {ArrowBackRounded} from "@material-ui/icons";


class BackButton extends React.Component {

    handleBack = () => {
        if (history.length > 2){
            history.goBack();
        } else {
            history.push(this.props.prevRoute);
        }
    };

    render() {
        return (
            <IconButton className={importedStyles.backButton} onClick={this.handleBack} >
                <ArrowBackRounded className={importedStyles.arrowBack}/>
            </IconButton>
        );
    }
}

const mapStateToProps = ({someReducer}) => {
    return ({});
};

export default connect(mapStateToProps)(BackButton);