function apiBaseUrl() {

    /* Production */
   return 'https://backend.grabaloapp.com/api/';

    /* Develop */
    // return 'http://localhost:8000/api/';

}

export default apiBaseUrl();
